<template>
  <div>
    <RfSequencePathsDisplayTable
      :paths="paths"
      v-on:delete="deletePath"
      v-on:updateOrder="updateOrder"
      :key="computeKey"
      :sequence="sequence"
    />
  </div>
</template>

<script>
import RfSequencePathsDisplayTable from "@/components/RfSequences/RfSequencePathsDisplayTable.vue";
import SequenceMixin from "@/utils/SequenceMixin";
import { cloneDeep } from "lodash-es";

export default {
  name: "RfSequencePaths",
  props: ["sequence"],
  components: {
    RfSequencePathsDisplayTable,
  },
  mixins: [SequenceMixin],
  data() {
    return {
      paths: cloneDeep(this.sequence.paths),
    };
  },
  methods: {
    deletePath(index) {
      this.paths.splice(index, 1);
    },
    fillModel(model) {
      model.paths = this.paths.map(item => {
        return {
          interaction_types: item.interaction_types,
          id: item.id,
          continue_interaction_types: item.continue_interaction_types,
        };
      });
    },
    updateOrder(paths) {
      this.paths = paths;
      this.$emit("savePaths");
    },
  },
  computed: {
    orderedPaths() {
      return this.paths;
    },
  },
  watch: {
    orderedPaths(to) {
      this.computeKey++;
      this.updateOrder(to);
    },
  },
};
</script>
