<template>
  <div class="show-interval-options">
    <h4>{{ labelName() }}</h4>
    <div class="interval-options-div">
      <div class="interval-options-opt1">
        <v-select
          :items="intervalOptions"
          v-model="intervalType"
          outlined
          dense
          hide-details
          background-color="#ffffff"
          class="interval-options-drpdwn"
        />
      </div>
      <div class="interval-options-time">
        <div class="show-interval-opt-time" v-if="intervalType === 'TIME'">
          <v-text-field
            outlined
            dense
            type="number"
            v-model="intervalNumber"
            hide-details
            background-color="#ffffff"
            class="show-interval-opt-num"
          />
          <v-select
            :items="timeUnits"
            v-model="intervalUnit"
            outlined
            required
            dense
            hide-details
            background-color="#ffffff"
            class="show-interval-opt-timeunits"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PromoInteractionConstants from "@/utils/constants/PromoInteractionConstants";

export default {
  name: "RfPromoInteractionInterval",
  props: ["action", "interactionType"],
  data() {
    return {
      intervalOptions: [
        { text: "Never", value: "INF" },
        { text: "On next visit", value: "VISIT" },
        { text: "Amount of time", value: "TIME" },
      ],
      intervalType: "INF",
      intervalNumber: 0,
      intervalUnit: "minutes",
      timeUnits: ["days", "hours", "minutes"],
      actionValue: this.action,
    };
  },
  watch: {
    intervalType() {
      this.setIntervalValue();
    },
    intervalNumber() {
      this.setIntervalValue();
    },
    intervalUnit() {
      this.setIntervalValue();
    },
    actionValue(to) {
      this.$emit("setIntervalAction", to, PromoInteractionConstants[this.interactionType].value);
    },
  },
  methods: {
    labelName() {
      return `Show prompt again after ${PromoInteractionConstants[this.interactionType].text}`;
    },
    setIntervalValue() {
      if (this.intervalType === "INF" || this.intervalType === "VISIT") {
        this.actionValue = this.intervalType;
      } else {
        const time = parseInt(this.intervalNumber) || 0;
        switch (this.intervalUnit) {
          case "days":
            this.actionValue = time * 1440;
            break;
          case "hours":
            this.actionValue = time * 60;
            break;
          case "minutes":
            this.actionValue = time;
        }
      }
    },
  },
  mounted() {
    const interval = this.action;
    if (interval === "INF" || interval === "VISIT") {
      this.intervalType = interval;
    } else {
      this.intervalType = "TIME";
      const time = parseInt(interval);
      if (time) {
        if (time % 1440 === 0) {
          this.intervalUnit = "days";
          this.intervalNumber = time / 1440;
        } else if (time % 60 === 0) {
          this.intervalUnit = "hours";
          this.intervalNumber = time / 60;
        } else {
          this.intervalUnit = "minutes";
          this.intervalNumber = time;
        }
      }
    }
  },
};
</script>
