<template>
  <v-dialog persistent v-model="promoCreateDialog" width="640" overlay-opacity="0.85">
    <v-card flat>
      <v-card-title class="headline">{{ createHeaderName }}</v-card-title>
      <v-card-text class="create-new-promo-dialog">
        <RfInfo :model="modelInfo" ref="systemInfo" />
        <div class="rf-section-title" v-if="showZoneSelection">
          <h4>Where do you want to show it?</h4>
          <div style="padding-bottom: 10px">Select a zone.</div>
          <v-select
            v-model="selectedZoneArea"
            :items="filteredPathGroups"
            item-text="name"
            data-cy="create-promo--zone-selector"
            item-value="id"
            dense
            outlined
            background-color="#ffffff"
            placeholder="Select a zone"
            :rules="[rules.required]"
          ></v-select>
        </div>
        <RfStageAndSegments
          ref="stageAndSegments"
          :pipelineStageId="pipelineStageId"
          :selectedSegments="selectedSegments"
          :pipelineId="pipelineId"
          :pipelineStageName="pipelineStageName"
        />
      </v-card-text>
      <v-card-actions class="modal-card-actions-div">
        <v-btn
          large
          depressed
          class="cancel-btn"
          outlined
          width="100px"
          @click="$emit('closeCreateNewPromoDialog')"
          >Close</v-btn
        >
        <v-btn
          large
          depressed
          color="success"
          :disabled="!systemInfo?.name || (showZoneSelection && !selectedZoneArea)"
          data-cy="create-new-promo-modal--submit"
          style="min-width: 200px"
          @click="showZoneSelection ? addPromoToPathGroup() : submitCreate()"
        >
          <v-icon left>save</v-icon>Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from "lodash-es";
import { mapActions, mapState } from "vuex";
import RfInfo from "@/components/RfCommonCards/RfInfo.vue";
import PromoTypeMixin from "@/utils/PromoTypeMixin";
import RecentActivityMixin from "@/utils/RecentActivityMixin";
import RfStageAndSegments from "@/components/RfPromotions/RfStageAndSegments.vue";
import { useToastsStore } from "@/pinia/toastsStore";
import { ref } from "vue";

export default {
  name: "RfCreatePromoModal",
  components: { RfInfo, RfStageAndSegments },
  setup: () => {
    const systemInfo = ref();

    return { toastsStore: useToastsStore(), systemInfo };
  },
  mixins: [PromoTypeMixin, RecentActivityMixin],
  props: [
    "promoCreateDialog",
    "promoDisplayType",
    "promoDeviceType",
    "promoCustomDeviceIds",
    "sequenceId",
    "selectedSegments",
    "pipelineStageId",
    "pipelineId",
    "pipelineStageName",
    "sequenceOrderModal",
  ],
  data() {
    return {
      selectedZoneArea: null,
      newPathGroupPromotionCreated: false,
      modelInfo: {},
      rules: {
        required: value => !!value || "Zone is required.",
      },
      updating: false,
      selectedStage: this.pipelineStageId,
    };
  },
  computed: {
    ...mapState({
      retentionModel: state => cloneDeep(state.tmp.retentionModel),
      newPathGroup: state => cloneDeep(state.tmp.pathGroup),
      pathGroups: state => state.apps.pathGroups,
      currPath: state => state.apps.currPath,
      currApp: state => state.apps.currApp,
    }),
    createHeaderName() {
      const retentionType = `${this.getPromoTypeWording(this.promoDisplayType)} Prompt`;
      const deviceType = `${this.getPromoDeviceTypeWording(this.promoDeviceType)}`;
      return `New ${retentionType} for ${deviceType}`;
    },
    showZoneSelection() {
      return ["horizontal", "vertical", "tile", "text"].indexOf(this.promoDisplayType) >= 0;
    },
    deviceTypeFilter() {
      if (this.promoDeviceType === null) {
        return () => true;
      }
      return item => item.device_type === this.promoDeviceType;
    },
    displayTypeFilter() {
      if (this.promoDisplayType === null) {
        return () => true;
      }
      return item => item.path_type === this.promoDisplayType;
    },
    filteredPathGroups() {
      return this.pathGroups
        .filter(this.deviceTypeFilter)
        .filter(this.displayTypeFilter)
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .map(item => ({ name: item.name || item.zone, id: item.id }));
    },
    appId() {
      return this.$route.params.aid;
    },
  },
  methods: {
    ...mapActions([
      "createPathGroup",
      "updatePathGroup",
      "getSequences",
      "getPathGroups",
      "getRetentionModel",
    ]),
    async submitCreate() {
      try {
        const newRetention = cloneDeep(this.retentionModel);
        newRetention.custom_device_ids = this.promoCustomDeviceIds;
        this.systemInfo.fillModel(newRetention);
        if (this.sequenceId) {
          newRetention.sequence_id = this.sequenceId;
          newRetention.sequence_order = this.sequenceOrderModal;
        }
        if (this.$refs.segments) {
          this.$refs.segments.fillModel(newRetention);
        }
        if (this.$refs.stageAndSegments) {
          this.$refs.stageAndSegments.fillStageTransitionModel(newRetention);
          this.$refs.stageAndSegments.fillSegmentModel(newRetention);
        }

        const newPathGroup = cloneDeep(this.newPathGroup);
        newPathGroup.paths = [{ ...newRetention }];
        newPathGroup.name = `${newRetention.name} Placement`;
        newPathGroup.device_type = newRetention.device_type;
        newPathGroup.path_type = this.promoDisplayType;

        this.$emit("closeCreateNewPromoDialog");
        this.$emit("closePromoDeviceTypeDialog");

        this.newPathGroupPromotionCreated = true;

        await this.createPathGroup({ appId: this.appId, model: newPathGroup });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    async addPromoToPathGroup() {
      try {
        this.updating = true;
        const createModel = this.pathGroups.find(item => item.id === this.selectedZoneArea);
        const pathGroup = cloneDeep(createModel);
        await this.getRetentionModel({
          appId: this.appId,
          pathType: this.promoDisplayType,
          deviceType: this.promoDeviceType,
          iab: pathGroup.settings.iab,
          customDeviceIds: this.promoCustomDeviceIds,
          sequenceId: this.sequenceId,
        });
        const newPath = {
          ...this.retentionModel,
          ...this.modelInfo,
          sequence_order: this.sequenceOrderModal,
          custom_device_ids: this.promoCustomDeviceIds,
        };
        this.systemInfo.fillModel(newPath);
        if (this.$refs.stageAndSegments) {
          this.$refs.stageAndSegments.fillStageTransitionModel(newPath);
          this.$refs.stageAndSegments.fillSegmentModel(newPath);
        }

        this.$emit("closeCreateNewPromoDialog");
        this.$emit("closePromoDeviceTypeDialog");

        pathGroup.paths = [...pathGroup.paths, newPath];
        await this.updatePathGroup({ appId: this.appId, model: pathGroup, isLoading: true });
        this.modelInfo = {};
        if (this.sequenceId) {
          this.getSequences({ appId: this.appId });
          this.getPathGroups({ appId: this.appId });
        }
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
        this.updating = false;
      }
    },
  },
  watch: {
    pathGroups(to) {
      if (this.newPathGroupPromotionCreated) {
        const group = to[to.length - 1];
        const path = group.paths[0];
        this.updatePathActivity(this.appId, path.id);
        this.newPathGroupPromotionCreated = false;
        if (this.sequenceId) {
          this.$router.push({
            path: `/apps/${this.appId}/experiences/${this.sequenceId}/promotions/${path.id}`,
          });
        } else if (this.pipelineId) {
          this.$router.push({
            path: `/apps/${this.appId}/pipelines/${this.pipelineId}/promotions/${path.id}`,
          });
        } else {
          this.$router.replace({ path: `/apps/${this.appId}/retentions/${path.id}` });
        }
        if (this.sequenceId) {
          this.getSequences({ appId: this.appId });
          this.getPathGroups({ appId: this.appId });
        }
      }
    },
    currPath(to) {
      if (to && this.updating) {
        this.updatePathActivity(this.appId, to.id);
        if (this.sequenceId) {
          this.$router.push({
            path: `/apps/${this.appId}/experiences/${this.sequenceId}/promotions/${to.id}`,
          });
        } else if (this.pipelineId) {
          this.$router.push({
            path: `/apps/${this.appId}/pipelines/${this.pipelineId}/promotions/${to.id}`,
          });
        } else {
          this.$router.push({
            path: `/apps/${this.appId}/retentions/${to.id}`,
          });
        }
      }
    },
    promoCreateDialog(v) {
      this.selectedZoneArea =
        v && this.filteredPathGroups?.[0] ? this.filteredPathGroups[0].id : null;
    },
  },
};
</script>
