<template>
  <div class="relative flex items-center">
    <slot></slot>
    <span
      ref="slider"
      class="absolute bottom-0 left-0 h-0.5 w-0 bg-blue-1 transition-all"
      :style="{ left: `${left}px`, width: `${width}px` }"
    ></span>
  </div>
</template>

<script setup>
import { nextTick, ref, useSlots, watch } from "vue";
import { useElementVisibility } from "@vueuse/core";

const slider = ref();
const left = ref(0);
const width = ref(0);
const slots = useSlots();
const props = defineProps(["active"]);
const targetIsVisible = useElementVisibility(slider);

const updateWidth = () => {
  const elements = slots.default();
  if (!elements?.length || !targetIsVisible.value) return;
  const offsetX = elements[0].elm.getBoundingClientRect().left;
  const elm = elements?.[props.active].elm.getBoundingClientRect();
  left.value = elm.left - offsetX;
  width.value = elm.width;
};
watch(targetIsVisible, () => setTimeout(updateWidth, 330), { flush: "post" });
watch(
  () => props.active,
  () => nextTick(updateWidth),
  { flush: "post" },
);
</script>
