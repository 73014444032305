<template>
  <v-layout row class="range-slider-input">
    <v-flex v-if="isNormalized" class="h-14">
      <v-range-slider
        hide-details
        v-model="values"
        :max="max"
        :min="min"
        :step="step"
        :disabled="disabled"
        @input="valueChanged"
        @change="valueChanged"
        ticks="always"
        tick-size="2"
        :tick-labels="['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']"
      ></v-range-slider>
    </v-flex>
    <v-flex v-else>
      <v-flex class="range-slider rf-range-slider">
        <v-text-field
          v-model="leftSlider"
          class="slider-input sinput-left mt-0"
          pattern="([0-9]+|Any)"
          hide-details
          single-line
          :disabled="disabled"
        />
        <v-text-field
          v-model="rightSlider"
          class="slider-input sinput-right mt-0"
          pattern="([0-9]+|Any)"
          hide-details
          single-line
          :disabled="disabled"
        />
        <v-range-slider
          hide-details
          v-model="formattedValue"
          class="pb-2"
          :max="max"
          :min="min"
          :step="step"
          :disabled="disabled"
          @input="valueChanged"
          @change="valueChanged"
        ></v-range-slider>
      </v-flex>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "RfRangeSlider",
  props: ["min", "max", "step", "disabled", "value", "isNormalized"],
  data() {
    return {
      values: this.value,
    };
  },
  watch: {
    value(v) {
      this.values = v;
    },
  },
  computed: {
    formattedValue: {
      get() {
        if (
          this.values &&
          !Number.isNaN(this.values[0]) &&
          !Number.isNaN(this.values[1]) &&
          this.values.length === 2
        ) {
          return [this.values[0], this.values[1]];
        }
        return [this.min, this.max];
      },
      set(value) {
        const [left, right] = value;
        const leftNum = parseInt(left);
        const rightNum = parseInt(right);
        this.values = [leftNum, rightNum];
      },
    },
    leftSlider: {
      get() {
        if (this.formattedValue[0] === this.min) {
          return "Any";
        }
        return this.formattedValue[0];
      },
      set(value) {
        const index = 0;
        const parsedVal = parseInt(value);
        const isNan = Number.isNaN(parsedVal);
        if (isNan && value !== "Any") {
          return;
        }
        if (parsedVal < this.min) {
          return;
        }

        const values = this.formattedValue.slice();
        if (value === "Any" || parsedVal < this.min) {
          values[index] = this.min;
        } else if (parsedVal >= this.max) {
          values[index] = this.max;
        } else {
          values[index] = parsedVal;
        }

        this.formattedValue = values;
        this.value[index] = values[index];
      },
    },
    rightSlider: {
      get() {
        if (this.formattedValue[1] === this.max) {
          return "Any";
        }
        return this.formattedValue[1];
      },
      set(value) {
        const index = 1;
        const parsedVal = parseInt(value);
        const isNan = Number.isNaN(parsedVal);
        if (isNan && value !== "Any") {
          return;
        }

        if (parsedVal > this.max) {
          return;
        }

        const values = this.formattedValue.slice();
        if (value === "Any" || parsedVal >= this.max) {
          values[index] = this.max;
        } else if (parsedVal <= this.min) {
          values[index] = this.min;
        } else {
          values[index] = parsedVal;
        }

        this.formattedValue = values;
        this.value[index] = values[index];
      },
    },
  },
  methods: {
    valueChanged(to) {
      this.$emit("input", to);
    },
  },
};
</script>
<style lang="scss">
.v-text-field.slider-input {
  width: 36px;
  display: flex;
  position: absolute;

  padding-top: 0px;

  &.v-text-field {
    input {
      padding: 4px 0;
      font-size: 14px;
    }
  }

  &.sinput-right.v-text-field input {
    text-align: right;
  }
}

.sinput-left {
  left: 0;
  top: 0;
  pointer-events: none;
}

.sinput-right {
  right: 0;
  top: 0;
  pointer-events: none;
}
.range-slider {
  position: relative;
  padding-top: 16px;
}

.rf-range-slider {
  .v-text-field > .v-input__control > .v-input__slot:before,
  .v-text-field > .v-input__control > .v-input__slot:after {
    width: 0px;
  }
  .v-input--range-slider {
    margin-top: 12px;
  }
}
</style>
