var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.disabled ? 'span' : 'button',_vm._g(_vm._b({tag:"component",staticClass:"rf-color-tag inline-flex flex-shrink-0 border border-solid border-transparent p-0.5",class:{
    '!border-dark-blue-1': _setup.props.selected,
    'rounded': !_setup.props.pill,
    'rounded-full': _setup.props.pill,
  },attrs:{"title":_vm.title}},'component',_vm.$attrs,false),_vm.disabled ? {} : _vm.$listeners),[_c('span',{staticClass:"m-auto h-full w-full overflow-hidden border border-solid border-transparent",class:{
      'rounded-sm': !_setup.props.pill,
      'rounded-full': _setup.props.pill,
      'px-3': !!(_vm.$slots.default || _vm.$scopedSlots.default) || !!_setup.props.title,
    },style:({ 'background-color': _setup.props.backgroundColor, 'border-color': _setup.borderColor })},[(_vm.$slots.default || _vm.$scopedSlots.default)?_vm._t("default"):[_vm._v(" "+_vm._s(_setup.props.title)+" ")]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }