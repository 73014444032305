<template>
  <div>
    <RfPromoDetailsInfoRow title="Status">
      <template v-slot:promodetail>
        <RfStateButtonsInterior :item="item" />
      </template>
    </RfPromoDetailsInfoRow>
  </div>
</template>

<script>
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfStateButtonsInterior from "@/components/RfPromotions/RfStateButtonsInterior.vue";

export default {
  name: "RfStateButtons",
  components: {
    RfPromoDetailsInfoRow,
    RfStateButtonsInterior,
  },
  props: ["item"],
};
</script>
