export const convertToDays = milliSeconds => {
  const days = Math.floor(milliSeconds / (86400 * 1000));
  milliSeconds -= days * (86400 * 1000);
  const hours = Math.floor(milliSeconds / (60 * 60 * 1000));
  milliSeconds -= hours * (60 * 60 * 1000);
  const minutes = Math.floor(milliSeconds / (60 * 1000));
  milliSeconds -= minutes * (60 * 1000);
  const seconds = Math.floor(milliSeconds / 1000);
  return { days, hours, minutes, seconds };
};
