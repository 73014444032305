<template>
  <div class="sgc-chips-wrap">
    <div class="sgc-chips-ctr">
      <v-chip
        class="autocmp-chip"
        small
        v-for="(item, index) in value"
        :key="index"
        :disabled="disabled"
        @click:close="removeOption(index)"
        close
        >{{ item }}</v-chip
      >
    </div>
    <v-autocomplete
      clearable
      hide-details
      hide-no-data
      autofocus
      ref="input"
      :disabled="disabled"
      v-model="tmp"
      :placeholder="title"
      :label="help"
      :id="id"
      :search-input.sync="search"
      class="sgc-autocomplete-location"
      :menu-props="{ maxHeight: 410, offsetY: true, minWidth: 400 }"
    />
  </div>
</template>

<script>
export default {
  name: "RfGooglePlaceAutoCompletion",
  props: ["value", "title", "help", "geoType", "hint", "disabled"],
  data() {
    return {
      autocompleteComponent: null,
      tmp: "",
      search: "",
      id: `rf-google-autocomplete-${this._uid}`,
    };
  },
  methods: {
    removeOption(index) {
      this.$emit(
        "input",
        this.value.filter((_, i) => i !== index),
      );
    },
  },
  mounted() {
    const input = document.querySelector(`#${this.id}`);
    this.autocompleteComponent = new window.google.maps.places.Autocomplete(input, {
      types: [`(${this.geoType})`],
    });
    this.autocompleteComponent.addListener("place_changed", () => {
      const place = this.autocompleteComponent.getPlace();
      if (place.place_id) {
        const newValues = Array.from(new Set([...this.value, place.name]));
        this.search = "";
        this.$emit("input", newValues);
        this.$refs.input?.$el.focus();
      }
      this.tmp = "";
    });
  },
};
</script>
