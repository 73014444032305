<template>
  <div>
    <span v-if="title" class="rf-text-input--title">
      {{ title }}
    </span>
    <component
      :is="autocomplete ? 'v-autocomplete' : 'v-select'"
      v-bind="$attrs"
      v-on="$listeners"
      append-icon="mdi-chevron-down"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>
    </component>
  </div>
</template>

<script>
export default {
  name: "PulseRfTextInput",
  props: { title: { type: String, default: "" }, autocomplete: { type: Boolean, default: false } },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__details {
  padding: 0 0.125rem !important;
}
::v-deep
  .v-input:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: rgba(158, 158, 158, 0.47) !important;
}

.rf-text-input--title {
  padding: 0 0.125rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: #383938;
}
</style>
