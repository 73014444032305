<template>
  <div :class="widgetParentClassName" :style="wrapperStyle">
    <div
      class="rf-widgetpromo-wrapper rfmodal-content rf-modal-content-wrapper"
      :style="widgetDimension"
      :class="widgetPositionClassName"
      v-if="isWidgetPromo"
    >
      <!-- note- in brig, widget position class name is a level higher -->
      <div class="rfmodal-wrapper inner-modal" :style="interstitialBackgroundImage" ref="rfdim">
        <RfRetentionMessage :actions="actions" :hideCloseButton="isInterstitial" />
      </div>
      <div :style="containerBackgroundImage" class="rfmodal-backgroundimage"></div>
    </div>
    <div class="rfmodal-content outer-modal" :style="modalWrapperStyle" v-else>
      <div class="rfmodal-wrapper inner-modal" :style="interstitialBackgroundImage" ref="rfdim">
        <RfRetentionMessage :actions="actions" :hideCloseButton="isInterstitial" />
      </div>
    </div>
    <span class="rfmodal-close" v-if="closeButtonEnabled && isInterstitial">
      <v-icon id="rfmodal-close-icon" :style="{ color: fillColor }">close</v-icon>
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RfRetentionMessage from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionMessage.vue";
import { bottomLeft, bottomRight, topLeft, topRight } from "@/utils/constants/PromptStyleConstants";
import PromoPreviewMixin from "@/utils/PromoPreviewMixin";
import { baseUrlReplacer } from "@/utils/BaseUrlReplacer";

export default {
  name: "RfRetentionInterstitialContent",
  props: ["actions", "deviceType", "model", "zoomStyle", "zoomValue"],
  mixins: [PromoPreviewMixin],
  components: { RfRetentionMessage },
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
    wrapperStyle() {
      return {
        "background-color": this.actions.rf_settings_bg_image_color,
      };
    },
    interstitialBackgroundImage() {
      const styles = {
        "background-color": this.actions.rf_settings_bg_image_color,
        "background-size": this.actions.rf_settings_tile_is_responsive,
      };
      if (this.actions.rf_settings_bg_image) {
        styles["background-image"] =
          `url(${baseUrlReplacer(this.currApp, this.actions.rf_settings_bg_image)})`;
      } else {
        styles["background-image"] = "none";
      }
      return styles;
    },
    containerBackgroundImage() {
      const styles = {
        "background-color": this.actions.rf_settings_bg_image_color,
      };
      if (this.actions.rf_settings_bg_image) {
        styles["background-image"] = `url(${this.actions.rf_settings_bg_image})`;
      } else {
        styles["background-image"] = "none";
      }
      return styles;
    },
    modalWrapperStyle() {
      return {
        width: this.actions.rf_settings_window_max_width,
        ...this.zoomStyle,
        ...Object.fromEntries(
          [topRight, topLeft, bottomRight, bottomLeft].map(({ value }) => [
            `border-${value}-radius`,
            this.actions[`rf_retention_border-${value}-radius`],
          ]),
        ),
        ["border-width"]: this.actions["rf_retention_border-width"],
        ["border-style"]: this.actions["rf_retention_border-style"],
        ["border-color"]: this.actions["rf_retention_border-color"],
        ["box-sizing"]: this.actions["rf_retention_box-sizing"],
        ["box-shadow"]: this.actions["rf_retention_box-shadow"],
      };
    },
    widgetPositionClassName() {
      return `widget-${this.actions.rf_widget_position}`;
    },
    widgetDimension() {
      return {
        "width": this.actions.rf_widget_width,
        "height": this.actions.rf_widget_height,
        "z-index": "3 !important",
      };
    },
    widgetParentClassName() {
      if (this.isWidgetPromo) {
        return "modal-overlay-style widget-rf-promo";
      }
      return `modal-overlay-style div--${this.actions.rf_settings_window_max_width} rfmodal rFastModalWrapper interstitialModal`;
    },
    overlayBackgroundColor() {
      return {
        background: this.actions.rf_settings_overlay_background,
      };
    },
    isWidgetPromo() {
      const pathType = this.model.path_type;
      return pathType === "widget";
    },
    isInterstitial() {
      return this.model.path_type === "interstitial";
    },
  },
};
</script>
