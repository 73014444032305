<template>
  <div :class="surveyWrapperClass" v-if="surveySelected">
    <div class="rf-radio-group">
      <div class="rf-radio-item" v-for="n in optionsTotal" :key="n" :style="surveyStyle">
        <input
          type="radio"
          :id="getValueByPosition(n)"
          name="survey"
          :style="radioBtnStyle"
          :value="getValueByPosition(n)"
          v-if="getValueByPosition(n)"
          @click="clickRadio($event)"
        />
        <label :for="getValueByPosition(n)" v-if="getLabelByPosition(n)">
          {{ getLabelByPosition(n) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import SurveyMixin from "@/utils/SurveyMixin";

export default {
  name: "RfPromoSurveyPreview",
  props: ["actions", "isMobile"],
  mixins: [SurveyMixin],
  data() {
    return {
      optionChosen: null,
    };
  },
  methods: {
    getValueByPosition(n) {
      return this.actions[`rf_retention_survey_option_${n}_value`];
    },
    getLabelByPosition(n) {
      return this.actions[`rf_retention_survey_option_${n}_label`];
    },
    clickRadio({ target }) {
      document.getElementsByName(target.name).forEach(el => {
        el.style.background = "initial";
      });
      const { checked } = target;
      if (checked) {
        target.style.background = this.actions.rf_settings_fill_color;
      }
    },
  },
  computed: {
    screenWiseTextSize() {
      return !this.isMobile
        ? this.actions.rf_retention_survey_options_font_size
        : this.actions.rf_settings_mobile_message_font_size;
    },
    surveyStyle() {
      return {
        "color": this.actions.rf_settings_fill_color,
        "font-size": this.screenWiseTextSize,
      };
    },
    radioBtnStyle() {
      return {
        "width": this.screenWiseTextSize,
        "height": this.screenWiseTextSize,
        "border-color": this.actions.rf_settings_fill_color,
        "background": "initial",
        "margin": 0,
        "margin-right": "0.8em",
      };
    },
    surveyWrapperClass() {
      return [
        {
          "survey-wrapper": true,
        },
        {
          "center-align-mode": this.actions.rf_retention_align === "center",
          "right-align-mode": this.actions.rf_retention_align === "right",
        },
      ];
    },
  },
};
</script>
