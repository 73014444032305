import * as Sentry from "@sentry/browser";
import ApiApps from "../apis/ApiApps";
import ApiSegments from "../apis/ApiSegments";
import ApiPaths from "../apis/ApiPaths";
import ApiPathGroups from "../apis/ApiPathGroups";
import ApiExperiments from "../apis/ApiExperiments";
import ApiPipelines from "../apis/ApiPipelines";
import { catchable } from "@/pinia/piniaUtils";

export default {
  state: {
    app: null,
    customConnectorAction: null,
    experiment: null,
    pathGroup: null,
    retentionModel: null,
    segment: null,
    uiML: {
      mlEnabled: false,
      mlTrait: "",
    },
    isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    pipeline: null,
    pipelineStage: null,
  },
  mutations: {
    mutateMlEnabled(state, mlEnabled) {
      state.uiML.mlEnabled = mlEnabled;
    },
    mutateMlTrait(state, mlTrait) {
      state.uiML.mlTrait = mlTrait;
    },
    mutateAppModel(state, app) {
      state.app = app;
    },
    mutatePathGroupModel(state, pathGroup) {
      state.pathGroup = pathGroup;
    },
    mutateSegmentModel(state, segment) {
      state.segment = segment;
    },
    mutateExperimentModel(state, experiment) {
      state.experiment = experiment;
    },
    mutateRetentionModel(state, path) {
      state.retentionModel = path;
    },
    mutateCustomConnectorActionModel(state, action) {
      state.customConnectorAction = action;
    },
    mutatePipelineModel(state, pipeline) {
      state.pipeline = pipeline;
    },
    mutatePipelineStageModel(state, stage) {
      state.pipelineStage = stage;
    },
  },
  actions: {
    setMlEnabled({ commit }, mlEnabled) {
      commit("mutateMlEnabled", mlEnabled);
    },
    setMlTrait({ commit }, mlTrait) {
      commit("mutateMlTrait", mlTrait);
    },
    async getAppModel({ commit, dispatch }) {
      dispatch("setLoading", true);
      try {
        commit("mutateAppModel", await ApiApps.getAppModel());
      } catch (error) {
        Sentry.captureException(error);
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getPathGroupModel({ commit }, { appId, pathType, deviceType }) {
      await catchable({
        t: async () =>
          commit(
            "mutatePathGroupModel",
            await ApiPathGroups.getPathGroupModel(
              appId,
              ["modal", "popup"].includes(pathType) ? "retention_modal" : pathType,
              deviceType,
            ),
          ),
        loadable: true,
        throwable: true,
      });
    },
    async getSegmentModel({ commit, dispatch }, appId) {
      dispatch("setLoading", true);
      try {
        commit("mutateSegmentModel", await ApiSegments.getSegmentModel(appId));
      } catch (error) {
        Sentry.captureException(error);
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getExperimentModel({ commit, dispatch }, pathId) {
      dispatch("setLoading", true);
      try {
        commit("mutateExperimentModel", await ApiExperiments.getExperimentModel(pathId));
      } catch (error) {
        Sentry.captureException(error);
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getRetentionModel(
      { commit },
      { appId, pathType, deviceType, iab, rfLitePathId, customDeviceIds = [], sequenceId },
    ) {
      await catchable({
        t: async () =>
          commit(
            "mutateRetentionModel",
            await ApiPaths.getRetentionModel(
              appId,
              ["modal", "popup"].includes(pathType) ? "retention_modal" : pathType,
              deviceType,
              iab,
              rfLitePathId,
              customDeviceIds,
              sequenceId,
            ),
          ),
        loadable: true,
        throwable: true,
      });
    },
    async newConnectorAction({ commit, dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        commit("mutateCustomConnectorActionModel", await ApiApps.newConnectorAction(appId));
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getPipelineModel({ commit, dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        commit("mutatePipelineModel", await ApiPipelines.getPipelineModel(appId));
      } catch (error) {
        Sentry.captureException(error);
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getPipelineStageModel({ commit, dispatch }, { appId, pipelineId }) {
      dispatch("setLoading", true);
      try {
        commit(
          "mutatePipelineStageModel",
          await ApiPipelines.getPipelineStageModel(appId, pipelineId),
        );
      } catch (error) {
        Sentry.captureException(error);
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
  },
};
