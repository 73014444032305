<template>
  <div class="show-interval-options">
    <h4>{{ title }}</h4>
    <div style="display: flex">
      <v-text-field
        outlined
        dense
        type="number"
        v-model="actions[actionName]"
        hide-details
        background-color="#ffffff"
        style="margin-right: 8px"
      />
      <v-text-field outlined dense v-model="fadeoutUnits" disabled hide-details />
    </div>
  </div>
</template>

<script>
export default {
  name: "RfFadeTimer",
  props: ["actions", "title", "actionName"],
  data() {
    return {
      fadeoutUnits: "seconds",
    };
  },
};
</script>
