<template>
  <RfRenameModal
    v-if="props.editItem"
    ref="renameModal"
    :title="`Edit prompt ${props.editItem.name}`"
    :name="props.editItem.name"
    :description="props.editItem.description"
    :onSubmit="onSubmitRename"
    @close="emit('update:editItem', null)"
  />
  <RfBaseModal
    v-else-if="props.deleteItem"
    ref="deleteModal"
    :message="`${deleteDialogText.body} This cannot be undone.`"
    width="580"
    error-text="Delete"
    @error="emit('delete')"
    @close="emit('update:deleteItem', null)"
  >
    <template #title>
      Are you sure you want to delete
      <span class="whitespace-pre font-bold"> {{ deleteDialogText.title }} </span> ?
    </template>
  </RfBaseModal>
  <RfBaseModal
    v-else-if="props.exportItem"
    ref="exportModal"
    message="Select from the following export formats"
    width="580"
    primary-text="Export"
    @secondary="emit('export', exportSelection)"
    @close="emit('update:exportItem', null)"
  >
    <template #title>
      Select the export format for
      <span class="whitespace-pre font-bold"> {{ exportDialogText.title }}</span
      >.
    </template>
    <template #body>
      <v-radio-group v-model="exportSelection">
        <v-radio label="Summary" value="summary"></v-radio>
        <v-radio label="Detail" value="detail"></v-radio>
      </v-radio-group>
    </template>
  </RfBaseModal>
  <RfClonePromptModal
    v-else-if="cloneItem"
    ref="cloneModal"
    :item="cloneItem"
    :isSuperAdmin="props.isSuperAdmin"
    :isCompanyAdmin="props.isCompanyAdmin"
    :currUser="props.currUser"
    :currApp="props.currApp"
    @update:item="v => emit('update:cloneItem', v)"
    @clone="(zone, targetApp, defaultCompany) => emit('clone', zone, targetApp, defaultCompany)"
  />
  <RfBaseModal
    v-else-if="statusUpdate.prompt"
    ref="statusModal"
    :title="`${StringUtils.capitalize(statusChangeDialogText.title)} Prompt`"
    width="580"
    :secondary-text="StringUtils.capitalize(statusChangeDialogText.title)"
    @secondary="updateStatus"
    @close="emit('update:statusUpdate', { status: null, prompt: null })"
  >
    <template #body>
      <span v-if="Array.isArray(statusUpdate.prompt)">
        Are you sure you want to {{ statusChangeDialogText.title }}
        <b>prompts({{ statusUpdate.prompt.length }})</b>?
        <b>These prompts({{ statusUpdate.prompt.length }})</b> will
        {{ statusChangeDialogText.body }}
      </span>
      <span v-else>
        Are you sure you want to {{ statusChangeDialogText.title }} this prompt? The prompt will
        {{ statusChangeDialogText.body }}
      </span>
      <RfStartStopDatePicker
        v-if="statusUpdate.status === StatusesSimplifiedConstants.ready.type"
        class="mt-4"
        ref="dateRangePicker"
        :dateRange="{
          startDate: dayjs(statusUpdate.prompt.start_date).utc().toISOString(),
          endDate: dayjs(statusUpdate.prompt.end_date).utc().toISOString(),
        }"
        :actions="statusUpdate.prompt.actions"
        :isPushNotification="isPushNotification(statusUpdate.prompt)"
      />
    </template>
  </RfBaseModal>
</template>

<script setup>
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import RfClonePromptModal from "@/blocks/RfModals/RfClonePromptModal.vue";
import RfRenameModal from "@/components/modals/RfRenameModal.vue";
import StringUtils from "@/utils/StringUtils";
import {
  SimplifyStatusesMapConstants,
  StatusesSimplifiedConstants,
} from "@/utils/constants/PromptStatusesConstants";
import getPromptStatus from "@/utils/prompts/getPromptStatus";
import dayjs from "dayjs";
import { computed, nextTick, ref, watchEffect } from "vue";
import { returnFutureEndDate } from "@/utils/TimeUtils";
import { isPushNotification } from "@/utils/prompts/promptHelpers";
import RfStartStopDatePicker from "@/components/RfStartStopDatePicker.vue";

const emit = defineEmits([
  "update:editItem",
  "update:deleteItem",
  "update:cloneItem",
  "update:statusUpdate",
  "clone",
  "status",
]);

const props = defineProps({
  editItem: { type: Object, default: null },
  deleteItem: { type: Array, default: null },
  exportItem: { type: Array, default: null },
  cloneItem: { type: Object, default: null },
  currApp: { type: Object, default: null },
  currUser: { type: Object, default: null },
  timezone: { type: String, default: null },
  statusUpdate: { type: Object, default: () => ({ status: null, prompt: null }) },
  pathGroups: { type: Array, default: null },
  onSubmitRename: { type: Function, default: () => {} },
  isSuperAdmin: { type: Boolean, default: false },
  isCompanyAdmin: { type: Boolean, default: false },
});

const renameModal = ref();
const deleteModal = ref();
const exportModal = ref();
const cloneModal = ref();
const statusModal = ref();
const dateRangePicker = ref();
const exportSelection = ref("summary");

const promptStatus = computed(() =>
  !props.statusUpdate.prompt
    ? null
    : getPromptStatus(
        Array.isArray(props.statusUpdate.prompt)
          ? props.statusUpdate.prompt[0]
          : props.statusUpdate.prompt,
        props.timezone,
      ).type,
);

const deleteDialogText = computed(() => {
  if (props.deleteItem.length > 1)
    return {
      title: `${props.deleteItem.length} prompts`,
      body: `Deleting this prompts(${props.deleteItem.length}) removes all information about these prompts(${props.deleteItem.length}).`,
    };
  return {
    title: props.deleteItem[0].name,
    body: "Deleting this prompt removes all information about this prompt.",
  };
});

const exportDialogText = computed(() => {
  if (props.exportItem.length > 1)
    return {
      title: `${props.exportItem.length} selected prompts`,
      body: `${props.exportItem.length} prompts will be exported with the selected option`,
    };

  return {
    title: props.exportItem[0].name,
    body: "The prompt will be exported with the selected option.",
  };
});

const simplifiedStatusUpdate = computed(
  () => SimplifyStatusesMapConstants[props.statusUpdate.status],
);

const statusChangeDialogText = computed(() => {
  if (simplifiedStatusUpdate.value.type === StatusesSimplifiedConstants.running.type)
    return {
      title: promptStatus.value === StatusesSimplifiedConstants.paused.type ? "resume" : "start",
      body: "run immediately.",
    };
  if (simplifiedStatusUpdate.value.type === StatusesSimplifiedConstants.paused.type)
    return { title: "pause", body: "stop running immediately." };
  if (simplifiedStatusUpdate.value.type === StatusesSimplifiedConstants.ended.type)
    return { title: "end", body: "stop running immediately." };
  if (simplifiedStatusUpdate.value.type === StatusesSimplifiedConstants.ready.type)
    return { title: "schedule", body: "run on start date." };
  return { title: "", body: "" };
});

const updateStatus = () => {
  const item = {
    is_enabled: [
      StatusesSimplifiedConstants.running.type,
      StatusesSimplifiedConstants.ready.type,
    ].includes(simplifiedStatusUpdate.value.type),
  };

  const runningPausedSwitch =
    (simplifiedStatusUpdate.value.type === StatusesSimplifiedConstants.running.type &&
      promptStatus.value !== StatusesSimplifiedConstants.paused.type) ||
    (simplifiedStatusUpdate.value.type === StatusesSimplifiedConstants.paused.type &&
      promptStatus.value === StatusesSimplifiedConstants.ended.type);
  if (runningPausedSwitch) {
    item.start_date = dayjs().format("YYYY-MM-DD");
    item.end_date = dayjs().add(1, "year").format("YYYY-MM-DD");
    item.is_expired = false;
  }
  if (simplifiedStatusUpdate.value.type === StatusesSimplifiedConstants.ended.type) {
    item.end_date = dayjs().subtract(1, "days").format("YYYY-MM-DD");
    item.is_expired = true;
  }

  if (simplifiedStatusUpdate.value.type === StatusesSimplifiedConstants.ready.type) {
    if (dateRangePicker.value) {
      const { fullStartDate, fullEndDate, frequency, localTime } = dateRangePicker.value;
      if (dayjs(fullStartDate) >= dayjs(fullEndDate))
        throw new Error("Start date must be before end date");

      item.start_date = fullStartDate;
      item.end_date = fullEndDate;
      if (frequency) item.actions.rf_pinpoint_campaign_frequency = frequency;
      if (localTime) item.actions.rf_pinpoint_campaign_local_time = localTime;
    }
  }

  const prompts = Array.isArray(props.statusUpdate.prompt)
    ? props.statusUpdate.prompt.map(({ id, end_date }) => ({
        ...item,
        id,
        end_date: runningPausedSwitch
          ? returnFutureEndDate(end_date, item.end_date)
          : item.end_date,
      }))
    : [
        {
          ...item,
          id: props.statusUpdate.prompt.id,
          end_date: runningPausedSwitch
            ? returnFutureEndDate(props.statusUpdate.prompt.end_date, item.end_date)
            : item.end_date,
        },
      ];

  emit("status", prompts);
};

const closeDeleteModal = () => deleteModal.value?.close();
const closeCloneModal = () => cloneModal.value?.close();
const closeExportModal = () => exportModal.value?.close();
const closeStatusModal = () => statusModal.value?.close();

watchEffect(() => {
  if (props.editItem) nextTick(() => renameModal.value?.show());
  else if (props.deleteItem) nextTick(() => deleteModal.value?.show());
  else if (props.exportItem) nextTick(() => exportModal.value?.show());
  else if (props.cloneItem) nextTick(() => cloneModal.value?.show());
  else if (props.statusUpdate) nextTick(() => statusModal.value?.show());
});

defineExpose({ closeDeleteModal, closeCloneModal, closeExportModal, closeStatusModal });
</script>
