<template>
  <div class="list-promo-details-row promo-detail-segments">
    <div class="list-promo-txt">
      {{ title }}
    </div>
    <div class="list-promo-detail-value">
      <slot name="promoinfo"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "RfPromoDetailsRow",
  props: ["title"],
};
</script>
