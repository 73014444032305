<template>
  <div class="rf-input inline-flex flex-col gap-1">
    <label v-if="title" class="text-body" :for="`input${_uid}`">
      {{ title }}
    </label>
    <span
      class="rf-input--input-wrapper bg-white-1 flex items-center overflow-hidden rounded"
      :class="inputClasses"
    >
      <slot name="append"></slot>
      <input
        :id="`input${_uid}`"
        v-model="localInput"
        ref="inputEl"
        :placeholder="props.placeholder"
        class="rf-input--input text-body bg-transparent flex-grow gap-2 placeholder:text-grey-3 focus:outline-none"
        v-bind="$attrs"
        v-on="{ ...$listeners, input: () => null }"
      />
      <slot name="prepend"></slot>
    </span>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const sizeClasses = {
  base: "rf-input--base",
};

const props = defineProps({
  value: { type: String, default: () => "" },
  placeholder: { type: String, default: "Input" },
  title: { type: String, default: "" },
  size: { type: String, default: "base", validator: v => ["base"].includes(v) },
});

const emit = defineEmits(["input"]);

const input = ref("");

const inputClasses = computed(() => `${sizeClasses[props.size]}`);
const localInput = computed({
  get: () => props.value || input.value,
  set: v => emit("input", (input.value = v)),
});
</script>

<style lang="scss" scoped>
.rf-input--input-wrapper {
  &.rf-input--base {
    min-height: 40px;
    min-width: 332px;
    padding: 7px 11px;
  }
}
</style>
