<template>
  <RfBaseModal
    ref="modalRef"
    title="Clone Prompt"
    width="580"
    secondary-text="Clone"
    :disabledPrimary="
      (isPlacementComputed &&
        !cloneItemZone &&
        currApp.id === cloneAppTarget.value &&
        'Zone is required') ||
      (!cloneAppTarget.value && 'App is required')
    "
    @secondary="
      emit('clone', cloneItemZone, cloneAppTarget, currUser.company_id === cloneCompanyTarget.value)
    "
    @close="emit('update:item', null), (cloneItemZone = null)"
  >
    <template #body>
      <div class="flex flex-col gap-4">
        <RfSelect
          v-if="isSuperAdmin"
          v-model="cloneCompanyTarget"
          :items="companies"
          dense
          outlined
          hide-details
          return-object
          title="Company"
          :rules="[value => !!value || 'Company is required.']"
        />
        <template v-if="showAppPicker">
          <RfSelect
            v-model="cloneAppTarget"
            :items="apps"
            dense
            outlined
            hide-details
            return-object
            title="App"
            :rules="[value => !!value || 'App is required.']"
          />
          <v-alert
            v-if="currApp.id !== cloneAppTarget.value"
            dense
            outlined
            type="warning"
            border="left"
          >
            Certain prompt properties including Segments, Actions, Triggers, Custom Goal, and others
            will not be cloned.
          </v-alert>
        </template>

        <RfSelect
          v-if="isPlacementComputed && currApp.id === cloneAppTarget.value"
          v-model="cloneItemZone"
          :items="filteredPathGroupItems"
          dense
          outlined
          title="Zone"
          :placeholder="filteredPathGroupItems?.[0]?.text"
          :rules="[value => !!value || 'Zone is required.']"
        />
        <div v-if="!showAppPicker && !isPlacementComputed">Do you want to clone this prompt?</div>
      </div>
    </template>
  </RfBaseModal>
</template>

<script setup>
import ApiApps from "@/apis/ApiApps";
import ApiCompanies from "@/apis/ApiCompanies";
import ApiPathGroups from "@/apis/ApiPathGroups";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import { catchable } from "@/pinia/piniaUtils";
import { isPlacement } from "@/utils/prompts/promptHelpers";
import { computed, ref, watch } from "vue";

const emit = defineEmits(["clone", "update:item"]);
const props = defineProps({
  item: { type: Object, default: null },
  isSuperAdmin: { type: Boolean, default: false },
  isCompanyAdmin: { type: Boolean, default: false },
  orgs: { type: Array, default: () => [] },
  currApp: { type: Object, default: () => ({}) },
  currUser: { type: Object, default: () => ({}) },
});

const apps = ref([]);
const companies = ref([]);
const pathGroups = ref([]);
const modalRef = ref();
const cloneItemZone = ref();
const cloneAppTarget = ref({});
const cloneCompanyTarget = ref({});

const isPlacementComputed = computed(() => isPlacement(props.item.path_type));
const showAppPicker = computed(
  () =>
    apps.value.length >
    (!cloneCompanyTarget.value.value || cloneCompanyTarget.value.value === props.currUser.company_id
      ? 1
      : 0),
);
const filteredPathGroupItems = computed(() =>
  pathGroups.value
    .filter(
      ({ path_type, device_type }) =>
        path_type === props.item.path_type && device_type === props.item.device_type,
    )
    .map(({ name, id }) => ({ text: name, value: id })),
);

const getApps = companyId =>
  catchable({
    t: async () => {
      const appsData = await ApiApps.getApps(companyId);
      apps.value = (
        props.isSuperAdmin || props.isCompanyAdmin
          ? appsData
          : appsData.filter(
              app =>
                props.currUser.apps.find(userApp => userApp.id === app.id)?.role !== "read_only",
            )
      ).map(el => ({ text: el.name, value: el.id }));
      if (showAppPicker.value)
        cloneAppTarget.value =
          apps.value.find(el => el.value === props.currApp?.id) || apps.value[0];
      else if (!props.isSuperAdmin && !props.isCompanyAdmin)
        cloneAppTarget.value = { value: props.currApp.id, text: props.currApp.name };
      else if (apps.value.length === 1 && apps.value[0].value === props.currApp.id)
        cloneAppTarget.value = { value: props.currApp.id, text: props.currApp.name };
      else cloneAppTarget.value = {};
    },
    loadable: true,
  });
const show = async () => {
  modalRef.value?.show();
  await Promise.all([
    ...(props.isSuperAdmin
      ? [
          catchable({
            t: async () =>
              (companies.value = (await ApiCompanies.getCompanies()).map(el => ({
                text: el.name,
                value: el.id,
              }))),
            loadable: true,
          }),
        ]
      : []),
    getApps(props.currUser.company_id),
  ]);
  if (props.isSuperAdmin)
    cloneCompanyTarget.value = companies.value.find(el => el.value === props.currUser.company_id);
};

const close = () => modalRef.value?.close();

watch(() => cloneCompanyTarget.value?.value, getApps);

watch(
  [() => isPlacementComputed.value, () => cloneAppTarget.value?.value],
  async () => {
    if (
      isPlacementComputed.value &&
      !pathGroups.value.length &&
      props.currApp.id === cloneAppTarget.value?.value
    )
      await catchable({
        t: async () =>
          (pathGroups.value = await ApiPathGroups.getPathGroups(cloneAppTarget.value.value)),
        loadable: true,
      });
  },
  { immediate: false },
);

defineExpose({ show, close });
</script>
