<template>
  <div style="min-width: 300px">
    <RfRangeSlider
      v-model="normalizedValueSet"
      :max="10"
      :min="0"
      :step="1"
      :isNormalized="model.is_normalized"
      :disabled="disabled"
      v-if="model.is_normalized"
    />
    <RfRangeSlider
      v-model="specialValueSet"
      :max="infMax"
      :min="infMin"
      :step="1"
      :isNormalized="model.is_normalized"
      :disabled="disabled || !hasMin || !hasMax"
      v-else
    />
  </div>
</template>
<script>
import RfRangeSlider from "@/components/RfRangeSlider.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "RfCustomControlsSlider",
  components: { RfRangeSlider },
  props: ["model", "value", "disabled"],
  data() {
    return {
      valueSet: cloneDeep(this.value),
    };
  },
  computed: {
    normalizedValueSet: {
      get() {
        return this.valueSet.length ? this.valueSet : [0, 10];
      },
      set(values) {
        this.valueSet = values;
      },
    },
    specialValueSet: {
      get() {
        if (!this.valueSet.length) {
          return [this.infMin, this.infMax];
        }
        const values = [...this.valueSet];
        if (values[0] === "-INF") {
          values[0] = this.infMin;
        }
        if (values[1] === "INF") {
          values[1] = this.infMax;
        }
        return values;
      },
      set(values) {
        if (values[0] === this.infMin) {
          values[0] = "-INF";
        }
        if (values[1] === this.infMax) {
          values[1] = "INF";
        }

        this.valueSet = values;
      },
    },
    orderedVals() {
      return (this.model.values || []).map(Number).sort((a, b) => a - b);
    },
    min() {
      return this.orderedVals[0];
    },
    max() {
      return this.orderedVals[this.orderedVals.length - 1];
    },
    hasMin() {
      return typeof this.min === "number";
    },
    hasMax() {
      return typeof this.max === "number";
    },
    averageStepDistance() {
      const totalRange = this.max - this.min;
      const range = totalRange.toString().length;
      const thousands = 4;
      if (range < thousands) {
        return 1;
      }
      return (range / 2) * 10;
    },
    infMin() {
      return (this.min || 0) - this.averageStepDistance;
    },
    infMax() {
      return (this.max || 0) + this.averageStepDistance;
    },
  },
  watch: {
    valueSet(to) {
      const vals = to.map(String);
      this.$emit("input", vals);
    },
  },
  methods: {},
};
</script>
