var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promo-design-group"},[_c('v-checkbox',{attrs:{"value":"true","label":"Enable countdown timer","hide-details":""},model:{value:(_vm.actions.rf_retention_countdown_enabled),callback:function ($$v) {_vm.$set(_vm.actions, "rf_retention_countdown_enabled", $$v)},expression:"actions.rf_retention_countdown_enabled"}}),_c('div',{staticClass:"mt-4 flex flex-col gap-4"},[_c('div',{staticClass:"promo-fonts-size-input"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","disabled":_vm.disabled,"min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"append-icon":"event","readonly":"","disabled":_vm.disabled,"outlined":"","dense":"","value":_vm.dayjs(_vm.endDate).format('MMM Do, YYYY'),"label":"End on","hide-details":""}},on))]}}]),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c('v-date-picker',{attrs:{"no-title":"","min":_vm.dayjs().format('YYYY-MM-DD')},on:{"input":function($event){_vm.menuEndDate = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('RfTimePicker',{ref:"endTimePicker",attrs:{"initTime":_vm.endTime,"disabled":_vm.disabled,"dense":""},on:{"updateTime":time => (_vm.endTime = _vm.StringUtils.hhmmBy30MinIntervalIndex(time))}})],1),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Caption","maxlength":"50","counter":"50","disabled":_vm.currentTranslations.hasOwnProperty('rf_retention_countdown_start_caption'),"placeholder":"Offer ends in"},model:{value:(
        _vm.currentTranslations.hasOwnProperty('rf_retention_countdown_start_caption')
          ? _vm.previewActions.rf_retention_countdown_start_caption
          : _vm.actions.rf_retention_countdown_start_caption
      ),callback:function ($$v) {_vm.$set(_vm.currentTranslations.hasOwnProperty('rf_retention_countdown_start_caption')
          ? _vm.previewActions.rf_retention_countdown_start_caption
          : _vm.actions, "rf_retention_countdown_start_caption", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n        currentTranslations.hasOwnProperty('rf_retention_countdown_start_caption')\n          ? previewActions.rf_retention_countdown_start_caption\n          : actions.rf_retention_countdown_start_caption\n      "}}),_c('div',{staticClass:"promo-window-size-inputs -mt-4"},[_c('v-select',{attrs:{"items":[
          { value: 'small', text: 'Small' },
          { value: 'medium', text: 'Medium' },
          { value: 'large', text: 'Large' },
        ],"label":"Size","outlined":"","required":"","dense":"","hide-details":""},model:{value:(_vm.actions.rf_retention_countdown_size),callback:function ($$v) {_vm.$set(_vm.actions, "rf_retention_countdown_size", $$v)},expression:"actions.rf_retention_countdown_size"}}),_c('v-select',{attrs:{"items":[
          { value: 'top', text: 'Top' },
          { value: 'bottom', text: 'Bottom' },
        ],"label":"Position","outlined":"","required":"","dense":"","hide-details":""},model:{value:(_vm.actions.rf_retention_countdown_position),callback:function ($$v) {_vm.$set(_vm.actions, "rf_retention_countdown_position", $$v)},expression:"actions.rf_retention_countdown_position"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }