<template>
  <RfRetentionInterstitialMobileDevice
    v-if="
      isMobile &&
      ['android_os', 'ios'].includes(model.device_type) &&
      model.path_type === 'interstitial'
    "
    :model="model"
    :actions="actions"
    :isNewDeviceEditor="isNewDeviceEditor"
  />

  <RfRetentionModalMobileContent
    :actions="actions"
    :model="model"
    v-else-if="deviceType === 'mobileWeb'"
    ref="rfmodalMobile"
  />
  <RfRetentionModalContent
    :actions="actions"
    :deviceType="deviceType"
    :model="model"
    :zoomStyle="zoomStyle"
    :isNewDeviceEditor="isNewDeviceEditor"
    :isMobile="deviceType === 'androidSquare'"
    ref="rfmodal"
    v-else
  />
</template>

<script>
import RfRetentionModalContent from "@/components/RfPathCreate/RfRetentionActions/RfRetentionModalContent.vue";
import RfRetentionModalMobileContent from "@/components/RfPathCreate/RfRetentionActions/RfRetentionModalMobileContent.vue";
import RfRetentionInterstitialMobileDevice from "./RfRetentionInterstitialMobileDevice.vue";

export default {
  name: "RfRetentionModal",
  props: {
    actions: Object,
    deviceType: String,
    model: Object,
    zoomStyle: Object,
    isNewDeviceEditor: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
  },
  components: {
    RfRetentionModalContent,
    RfRetentionModalMobileContent,
    RfRetentionInterstitialMobileDevice,
  },
};
</script>
