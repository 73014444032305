<template>
  <div class="w-full">
    <div class="flex">
      <v-tabs
        v-model="viewTabModal"
        slider-color="666666"
        dark
        centered
        icons-and-text
        height="50"
        background-color="#000"
      >
        <v-tabs-slider style="color: #2196f3"></v-tabs-slider>
        <v-tab href="#desktopViewTab" @click="$emit('clickTab', 'desktopViewTab')">Tablet</v-tab>
        <v-tab href="#mobilewebViewTab" @click="$emit('clickTab', 'mobilewebViewTab')"
          >Mobile</v-tab
        >
      </v-tabs>
      <div class="tab-selector">
        <v-select
          :items="[
            { text: 'Android Preview', value: 'android' },
            { text: 'iOS Preview', value: 'ios' },
          ]"
          v-model="previewDevice"
          width="400px"
          append-icon=""
          placeholder="Preview"
          dark
          persistent-placeholder
          :menu-props="{
            'offset-y': true,
            'bottom': true,
            'content-class': 'tab-selector-content',
            'tile': true,
            'dark': true,
            'origin': 'bottom center',
            'z-index': 3,
          }"
        >
          <template #selection>
            <span>Preview</span>
          </template>
          <template #item="{ item }">
            <div class="tab-selector-item">{{ item.text }}</div>
          </template>
        </v-select>
      </div>
    </div>
    <v-tabs-items v-model="viewTabModal">
      <v-tab-item
        :transition="false"
        :reverse-transition="false"
        value="desktopViewTab"
        class="flex overflow-auto"
      >
        <div class="flex items-center justify-center">
          <div
            class="relative inline-block w-136 overflow-hidden rounded-xl border-5 border-solid border-grey-1"
            :style="zoomStyle"
          >
            <img src="../../../assets/images/push_tablet.png" class="block" />

            <RfRetentionPushContent
              class="absolute left-1/2 w-2/3 -translate-x-1/2"
              :title="actions.rf_retention_title"
              :message="actions.rf_retention_message"
              :image="image"
              style="bottom: 4%"
            />
          </div>
        </div>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" value="mobilewebViewTab">
        <div class="flex items-center justify-center">
          <div
            class="relative inline-block overflow-hidden rounded-xl border-5 border-solid border-grey-1"
          >
            <img src="../../../assets/images/push_mobile.png" class="block" />
            <RfRetentionPushContent
              class="absolute left-1/2 w-11/12 -translate-x-1/2"
              :title="actions.rf_retention_title"
              :message="actions.rf_retention_message"
              :image="image"
              style="bottom: 3%"
            />
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <div class="preview-control-buttons" v-if="viewTabModal === 'desktopViewTab'">
      <v-btn small color="#f3f3f3" @click="zoomIn">+</v-btn>
      <v-btn class="reset-zoom-btn" small color="#f3f3f3" @click="resetZoom">{{
        `${zoomValue * 100}%`
      }}</v-btn>
      <v-btn small color="#f3f3f3" @click="zoomOut">-</v-btn>
    </div>
  </div>
</template>

<script>
import RfRetentionModal from "@/components/RfPathCreate/RfRetentionActions/RfRetentionModal.vue";
import ZoomPreviewMixin from "@/utils/ZoomPreviewMixin";
import RfRetentionPushContent from "./RfRetentionPushContent.vue";
import RfLanguagePreview from "../RfRetentionDevices/RfLanguagePreview.vue";

export default {
  name: "RfRetentionDeviceAndroidOs",
  props: ["actions", "viewTypeTab", "model"],
  components: { RfRetentionModal, RfRetentionPushContent, RfLanguagePreview },
  mixins: [ZoomPreviewMixin],
  data() {
    return { viewTabModal: this.viewTypeTab, previewDevice: "android" };
  },
  computed: {
    image() {
      return this.previewDevice === "ios"
        ? this.actions.rf_pinpoint_ios_image
        : this.actions.rf_settings_bg_image;
    },
  },
};
</script>
