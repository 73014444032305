import ConduitApiUsers from "../apis/ConduitApiUsers";
import ConduitApiActions from "../apis/ConduitApiActions";

export default {
  state: {},
  mutations: {},
  actions: {
    async conduitResetGoal({ dispatch }, { appId, endUserId }) {
      try {
        await ConduitApiUsers.resetGoal(appId, endUserId);
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async conduitOverrideActions({ dispatch }, { actionGroupId, sequenceId }) {
      try {
        return await ConduitApiActions.getOverrideActions({ actionGroupId, sequenceId });
      } catch (error) {
        dispatch("sendError", error);
      }
      return null;
    },
  },
};
