import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      appCustomFields: state => state.apps.segmentCustomFields || [],
      appSystemFields: state => state.apps.segmentSystemFields || [],
      mixinApp: state => state.apps.currApp,
    }),
  },
  methods: {
    ...mapActions(["getAppCustomFields"]),
  },
  mounted() {
    const app = this.app || this.currApp || this.mixinApp;
    const fieldType = this.fieldType || "custom";
    const fields = fieldType === "custom" ? this.appCustomFields : this.appSystemFields;
    if (!fields.length) {
      this.getAppCustomFields({ appId: app.id, fieldType });
    }
  },
};
