import { dateDisplay } from "./DateDisplayUtils";

export default {
  methods: {
    sequenceLink(item) {
      return { path: `/apps/${this.$route.params.aid}/experiences/${item.sequence_id}` };
    },
    pipelineLink(item) {
      return { path: `/apps/${this.$route.params.aid}/pipelines/${item.pipeline_id}` };
    },
    segmentLink(item) {
      return { path: `/apps/${this.$route.params.aid}/segments/${item.id}` };
    },
    scheduleDisplay(item) {
      return `${dateDisplay(item.start_date)} to ${dateDisplay(item.end_date)}`;
    },
    getItemUrl(item) {
      if (this.fromSequence && item.sequence_id) {
        return {
          path: `/apps/${this.$route.params.aid}/experiences/${item.sequence_id}/promotions/${item.id}`,
        };
      }
      return { path: `/apps/${this.$route.params.aid}/retentions/${item.id}` };
    },
  },
};
