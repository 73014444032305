import { serializeQuery } from "@/utils/UrlUtils";
import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiSegments {
  static getSegments(appId, params) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments${query}`);
  }

  static getSegment(appId, segId, params = {}) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments/${segId}${query}`);
  }

  static updateSegment(appId, segId, modelSegment) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments/${segId}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ segment: modelSegment }),
    });
  }

  static deleteSegment(appId, segId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments/${segId}`, {
      method: "DELETE",
    }).then(() => segId);
  }

  static setPrioritySegments(appId, segments) {
    const prioritizedSegments = segments.reduce((h, seg) => {
      return {
        ...h,
        [seg.id]: { priority: seg.priority },
      };
    }, {});
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments/set_priority`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ segments: prioritizedSegments }),
    });
  }

  static createSegment(appId, modelSegment) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ segment: modelSegment }),
    });
  }

  static getActivities(appId, segId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments/${segId}/activity`, {
      method: "GET",
    });
  }

  static getSegmentModel(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments/new`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
  }

  static bulkUpdate(appId, segments) {
    if (!segments?.length) throw new Error("Expected second parameter to be a non empty array");

    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments/bulk_update`, {
      method: "PUT",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({ segments }),
    });
  }

  static bulkDelete(appId, segments) {
    if (!segments?.length) throw new Error("Expected second parameter to be a non empty array");

    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/segments/bulk_delete`, {
      method: "DELETE",
      headers: { "Accept": "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({ segments }),
    });
  }
}
