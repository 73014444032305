<template>
  <RfSimpleCard v-if="pipelines.length">
    <RfHiddenPipelines :app="app" :pipelines="pipelines" />
  </RfSimpleCard>
</template>
<script>
import { mapActions, mapState } from "vuex";
import RfHiddenPipelines from "@/components/RfSettings/RfHiddenPipelines.vue";
import RfSimpleCard from "@/components/RfSimpleCard.vue";

export default {
  name: "RfPipelineSettings",
  props: ["app"],
  components: { RfHiddenPipelines, RfSimpleCard },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      pipelines: state =>
        state.apps.pipelines.filter(pipeline => pipeline.pipeline_type !== "custom"),
    }),
  },
  methods: {
    ...mapActions(["getPipelines"]),
  },
  async mounted() {
    await this.getPipelines({ appId: this.app.id });
  },
};
</script>
