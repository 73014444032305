import Vue from "vue";
import Vuex from "vuex";
import apps from "./vuex/VxApps";
import conduit from "./vuex/VxConduit";
import tmp from "./vuex/VxTmp";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: { apps, tmp, conduit },
  state: { lastError: null, loading: 0 },
  mutations: {
    mutateError(state, error) {
      console.log(error);
      state.lastError = error;
    },
    mutateLoading(state, loading) {
      if (loading) {
        state.loading++;
        state.lastError = null;
      } else {
        state.loading--;
      }
    },
  },
  actions: {
    sendError({ commit }, error) {
      commit("mutateError", error);
    },
    setLoading({ commit }, loading) {
      commit("mutateLoading", loading);
    },
  },
});
