import { render, staticRenderFns } from "./RfSelectionWithChips.vue?vue&type=template&id=5720b6ff"
import script from "./RfSelectionWithChips.vue?vue&type=script&lang=js"
export * from "./RfSelectionWithChips.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports