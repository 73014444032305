import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiJobs {
  static getJobs(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/jobs`);
  }

  static createJob(appId, jobParams) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/jobs`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ job: jobParams }),
    });
  }

  static downloadJobLink(appId, job) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/jobs/${job.id}/download_link`);
  }
}
