import { computed, ref } from "vue";

export const useTableSelect = () => {
  const selected = ref(new Map());

  const updateMap = (action, guide) => {
    const copy = new Map([...selected.value]);
    copy[action](guide.id, guide);
    selected.value = copy;
  };

  const resetSelected = () => (selected.value = new Map());
  const firstSelected = computed(() => [...selected.value][0][1]);
  const valuesSelected = computed(() => [...selected.value.values()]);
  const selectedValue = computed(() =>
    selected.value.size === 1 ? firstSelected.value : valuesSelected.value,
  );

  return { selected, updateMap, resetSelected, firstSelected, valuesSelected, selectedValue };
};
