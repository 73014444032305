export const DEFAULT_PROMO_DIMENSIONS = {
  horizontal: {
    "2x1": { width: "960px", height: "480px", m_width: "420px", m_height: "210px", iab: "2x1" },
    "4x1": { width: "960px", height: "240px", m_width: "420px", m_height: "105px", iab: "4x1" },
    "6x1": { width: "960px", height: "160px", m_width: "420px", m_height: "70px", iab: "6x1" },
    "8x1": { width: "960px", height: "120px", m_width: "420px", m_height: "52.5px", iab: "8x1" },
    "10x1": { width: "960px", height: "96px", m_width: "420px", m_height: "42px", iab: "10x1" },
    "custom": {
      width: "960px",
      height: "300px",
      m_width: "300px",
      m_height: "200px",
      iab: "custom",
    },
  },
  tile: {
    "1x1": { width: "500px", height: "500px", m_width: "300px", m_height: "300px", iab: "1x1" },
    "1x2": { width: "250px", height: "500px", m_width: "150px", m_height: "300px", iab: "1x2" },
    "custom": {
      width: "250px",
      height: "500px",
      m_width: "375px",
      m_height: "205px",
      iab: "custom",
    },
  },
  vertical: {
    "1x2": { width: "250px", height: "500px", m_width: "150px", m_height: "300px", iab: "1x2" },
    "1x3": { width: "166px", height: "500px", m_width: "100px", m_height: "300px", iab: "1x3" },
    "1x4": { width: "125px", height: "500px", m_width: "75px", m_height: "300px", iab: "1x4" },
    "custom": {
      width: "250px",
      height: "500px",
      m_width: "200px",
      m_height: "300px",
      iab: "custom",
    },
  },
  text: {
    "6x1": { width: "960px", height: "160px", m_width: "420px", m_height: "70px", iab: "6x1" },
    "8x1": { width: "960px", height: "120px", m_width: "420px", m_height: "52.5px", iab: "8x1" },
    "10x1": { width: "960px", height: "96px", m_width: "420px", m_height: "42px", iab: "10x1" },
    "custom": { width: "100%", height: "auto", m_width: "100%", m_height: "auto", iab: "custom" },
  },
};

export const getRatioOptionsByPromoType = type => {
  const promoTypes = {
    horizontal: [
      { text: "2x1", value: "2x1" },
      { text: "4x1", value: "4x1" },
      { text: "6x1", value: "6x1" },
      { text: "8x1", value: "8x1" },
      { text: "10x1", value: "10x1" },
    ],
    tile: [
      { text: "1x1", value: "1x1" },
      { text: "1x2", value: "1x2" },
    ],
    vertical: [
      { text: "1x2", value: "1x2" },
      { text: "1x3", value: "1x3" },
      { text: "1x4", value: "1x4" },
    ],
    text: [
      { text: "6x1", value: "6x1" },
      { text: "8x1", value: "8x1" },
      { text: "10x1", value: "10x1" },
    ],
  };
  if (promoTypes[type]) {
    return [...promoTypes[type], { text: "Custom", value: "custom" }];
  }
  return [];
};

export const getDefaultPromoRatio = () => {
  // const promoTypes = {
  //   horizontal: "4x1",
  //   tile: "1x2",
  //   vertical: "1x4",
  //   text: "10x1",
  // };
  // use custom until preview images are updated
  // return promoTypes[type];
  return "custom";
};

export const PROMO_WIDTH_MULTIPLIER = {
  "2x1": 2,
  "4x1": 4,
  "6x1": 6,
  "8x1": 8,
  "10x1": 10,
  "1x1": 1,
  "1x2": 0.5,
  "1x3": 0.33,
  "1x4": 0.25,
};

export const defaultPromoType = "horizontal";
