<template>
  <div
    class="rf-detail-content-wrapper company-settings-wrapper"
    style="margin-top: 1rem"
    v-if="companies.length"
  >
    <v-card>
      <v-tabs v-model="companySettingsTab" centered background-color="#082638" height="70" dark>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1">
          <v-icon left>fas fa-building</v-icon>
          Companies
        </v-tab>
        <v-tab href="#tab-2" v-if="isSuperAdminRoleMixin || isCompanyAdminMixin">
          <v-icon left>fas fa-users</v-icon>
          Users
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="companySettingsTab">
        <v-tab-item value="tab-1">
          <v-card flat style="padding: 1.5rem">
            <div class="settings-add-new-company">
              <h3 class="title">New Company</h3>
              <form v-on:submit.prevent="onSubmitCompany">
                <v-text-field
                  label="Company Name"
                  outlined
                  dense
                  hide-details
                  v-model="company.name"
                  background-color="#ffffff"
                  style="margin-bottom: 0.75rem"
                ></v-text-field>
                <v-text-field
                  label="Aws Marketplace Customer ID"
                  outlined
                  dense
                  hide-details
                  v-model="company.awsMarketplaceCustomerId"
                  background-color="#ffffff"
                  style="margin-bottom: 0.75rem"
                ></v-text-field>
                <v-checkbox
                  v-model="company.awsMarketplaceEnabled"
                  label="Aws Marketplace customer?"
                  :value="false"
                  hide-details
                  style="margin-bottom: 0.75rem"
                ></v-checkbox>
                <v-btn depressed color="success" type="submit" class="md-success">Submit</v-btn>
              </form>
            </div>
            <v-simple-table
              class="experiments-results-table experiment-stats-report-details-wrapper"
            >
              <thead>
                <tr>
                  <th class="col-exp-1">Company Name</th>
                  <th class="text-left">Timezone Offset</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in companies" :key="index">
                  <td style="width: 40%">
                    {{ item.name }}
                  </td>
                  <td>{{ item.timezone_offset ? item.timezone_offset : "N/A" }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2" v-if="isSuperAdminRoleMixin || isCompanyAdminMixin">
          <v-card flat style="padding: 1.5rem">
            <div style="width: 300px; margin: 0 auto">
              <v-select
                v-if="companies.length"
                hide-details
                outlined
                dense
                label="Select Company"
                v-model="currentCompany"
                :items="companies"
                clearable
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>
            </div>
            <RfManageUsers :currentCompany="currentCompany" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfManageUsers from "@/components/RfSettings/RfManageUsers.vue";
import RoleMixin from "@/utils/RoleMixin";

export default {
  name: "RfSettingsCompany",
  components: { RfManageUsers },
  mixins: [RoleMixin],
  data: () => ({
    companySettingsTab: null,
    currentCompany: null,
    company: { name: "", awsMarketplaceCustomerId: "", awsMarketplaceEnabled: false },
  }),
  computed: { ...mapState({ companies: state => state.apps.companies }) },
  methods: {
    onSubmitCompany() {
      if (this.company.name.length > 2) {
        const params = {
          companyName: this.company.name,
          awsMarketplaceCustomerId: this.company.awsMarketplaceCustomerId,
          awsMarketplaceEnabled: this.company.awsMarketplaceEnabled,
        };
        this.createCompany(params).then(() => {
          this.company.name = "";
          this.company.awsMarketplaceCustomerId = "";
          this.company.awsMarketplaceEnabled = false;
        });
      }
    },
    ...mapActions(["getCompanies", "createCompany"]),
  },
  mounted() {
    this.getCompanies();
  },
};
</script>

<style scoped>
.company-user-role {
  display: flex;
  flex-flow: row;
  padding-top: 1rem;
}
.company-user-role .v-input--radio-group {
  margin: 0 0 0 10px;
}
.settings-add-new-company {
  max-width: 400px;
  padding: 1rem;
  background: #eee;
  margin: 0 0 1rem 1.25rem;
}
.settings-add-new-company h3 {
  padding: 0;
  margin: 0 0 0.75rem;
  line-height: normal;
}
</style>
