<template>
  <component
    :is="disabled ? 'span' : 'button'"
    class="rf-color-tag inline-flex flex-shrink-0 border border-solid border-transparent p-0.5"
    :class="{
      '!border-dark-blue-1': props.selected,
      'rounded': !props.pill,
      'rounded-full': props.pill,
    }"
    :title="title"
    v-bind="$attrs"
    v-on="disabled ? {} : $listeners"
  >
    <span
      class="m-auto h-full w-full overflow-hidden border border-solid border-transparent"
      :class="{
        'rounded-sm': !props.pill,
        'rounded-full': props.pill,
        'px-3': !!($slots.default || $scopedSlots.default) || !!props.title,
      }"
      :style="{ 'background-color': props.backgroundColor, 'border-color': borderColor }"
    >
      <slot v-if="$slots.default || $scopedSlots.default" />
      <template v-else> {{ props.title }} </template>
    </span>
  </component>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  backgroundColor: { type: String, default: "" },
  selected: { type: Boolean, default: false },
  pill: { type: Boolean, default: false },
  title: { type: String, default: "" },
  disabled: { type: Boolean, default: false },
});

const borderColor = computed(() => {
  const bgColorUpperCase = props.backgroundColor.toUpperCase();
  if (bgColorUpperCase === "#F2F6F9") return "#EAF0F4";
  if (["#FFF", "#FFFFFF"].includes(bgColorUpperCase)) return "#D8E2EC";
});
</script>
