<template>
  <div
    class="rf-bulk-action-menu flex items-center gap-2 overflow-hidden rounded-t border-b border-solid border-background bg-white-1"
    data-cy="table-select"
  >
    <RfMenu ref="tableSelect" class="rf-bulk-action-menu--checkbox">
      <template #activator>
        <RfCheckbox
          data-cy="table-select--all"
          :data-cy-value="selected.size"
          :value="!!(selected.size && isAllSelected)"
          :intermediate="!!(selected.size && !isAllSelected)"
          @input="$emit(isAllSelected ? 'deselectAll' : 'selectAll')"
          @click.stop.native
        />
      </template>
      <template #menu>
        <div class="list flex w-36 flex-col gap-4 p-4">
          <button
            class="rounded p-2 text-left"
            data-cy="table-select--dropdown-all"
            :class="{ '!bg-blue-5 !text-blue-1': isAllSelected }"
            @click="() => ($emit('selectAll'), $refs.tableSelect.hide())"
          >
            All
          </button>
          <button
            data-cy="table-select--dropdown-enabled"
            class="rounded p-2 text-left"
            :class="{
              '!bg-blue-5 !text-blue-1':
                !!selectedStatuses[StatusesSimplifiedConstants.running.type] &&
                !selectedStatuses[StatusesSimplifiedConstants.paused.type] &&
                !selectedStatuses[StatusesSimplifiedConstants.ended.type] &&
                !selectedStatuses[StatusesSimplifiedConstants.limited.type],
            }"
            @click="
              () => (
                $emit('selectByPreset', StatusesSimplifiedConstants.running.type),
                $refs.tableSelect.hide()
              )
            "
          >
            {{ StatusesSimplifiedConstants.running.title }}
          </button>

          <button
            class="rounded p-2 text-left"
            data-cy="table-select--dropdown-paused"
            :class="{
              '!bg-blue-5 !text-blue-1':
                !selectedStatuses[StatusesSimplifiedConstants.running.type] &&
                !!selectedStatuses[StatusesSimplifiedConstants.paused.type] &&
                !selectedStatuses[StatusesSimplifiedConstants.ended.type] &&
                !selectedStatuses[StatusesSimplifiedConstants.limited.type],
            }"
            @click="
              () => (
                $emit('selectByPreset', StatusesSimplifiedConstants.paused.type),
                $refs.tableSelect.hide()
              )
            "
          >
            {{ StatusesSimplifiedConstants.paused.title }}
          </button>

          <button
            class="rounded p-2 text-left"
            data-cy="table-select--dropdown-disabled"
            :class="{
              '!bg-blue-5 !text-blue-1':
                !selectedStatuses[StatusesSimplifiedConstants.running.type] &&
                !selectedStatuses[StatusesSimplifiedConstants.paused.type] &&
                !!selectedStatuses[StatusesSimplifiedConstants.ended.type] &&
                !selectedStatuses[StatusesSimplifiedConstants.limited.type],
            }"
            @click="
              () => (
                $emit('selectByPreset', StatusesSimplifiedConstants.ended.type),
                $refs.tableSelect.hide()
              )
            "
          >
            {{ StatusesSimplifiedConstants.ended.title }}
          </button>

          <button
            class="rounded p-2 text-left"
            data-cy="table-select--dropdown-limited"
            :class="{
              '!bg-blue-5 !text-blue-1':
                !selectedStatuses[StatusesSimplifiedConstants.running.type] &&
                !selectedStatuses[StatusesSimplifiedConstants.paused.type] &&
                !selectedStatuses[StatusesSimplifiedConstants.ended.type] &&
                !!selectedStatuses[StatusesSimplifiedConstants.limited.type],
            }"
            @click="
              () => (
                $emit('selectByPreset', StatusesSimplifiedConstants.limited.type),
                $refs.tableSelect.hide()
              )
            "
          >
            {{ StatusesSimplifiedConstants.limited.title }}
          </button>
        </div>
      </template>
    </RfMenu>

    <Transition>
      <span
        v-if="selected.size"
        data-cy="table-select--actions"
        class="text-body mr-2 inline-flex items-center gap-2"
      >
        <RfBaseTag
          v-if="selected.size"
          class="!bg-input-background"
          :data-cy-value="selected.size"
          @clear="$emit('deselectAll')"
          data-cy="table-select--selected"
        >
          {{ selected.size }} selected
        </RfBaseTag>

        <RfButtonNew v-if="selected.size === 1" type="table" @click="$emit('edit')">
          <RfEditIcon class="!h-6 !w-6" />
          Edit
        </RfButtonNew>
        <RfButtonNew
          v-if="
            !selectedStatuses[StatusesSimplifiedConstants.running.type] &&
            (!!selectedStatuses[StatusesSimplifiedConstants.paused.type]
              ? !selectedStatuses[StatusesSimplifiedConstants.ended.type]
              : !!selectedStatuses[StatusesSimplifiedConstants.ended.type]) &&
            !selectedStatuses.disableStatusChange
          "
          type="table"
          @click="$emit('status', 'enabled')"
        >
          <RfPlayIcon class="!h-6 !w-6" />
          {{
            !!selectedStatuses[StatusesSimplifiedConstants.paused.type] &&
            !selectedStatuses[StatusesSimplifiedConstants.ended.type]
              ? "Resume"
              : "Start"
          }}
        </RfButtonNew>

        <RfButtonNew
          v-if="
            !!selectedStatuses[StatusesSimplifiedConstants.running.type] &&
            !selectedStatuses[StatusesSimplifiedConstants.paused.type] &&
            !selectedStatuses[StatusesSimplifiedConstants.ended.type] &&
            !selectedStatuses.disableStatusChange
          "
          type="table"
          @click="$emit('status', 'paused')"
        >
          <RfPauseIcon class="!h-6 !w-6" />
          Pause
        </RfButtonNew>

        <RfButtonNew
          v-if="
            !!selectedStatuses[StatusesSimplifiedConstants.running.type] &&
            !selectedStatuses[StatusesSimplifiedConstants.paused.type] &&
            !selectedStatuses[StatusesSimplifiedConstants.ended.type] &&
            !selectedStatuses.disableStatusChange
          "
          type="table"
          @click="$emit('status', 'disabled')"
        >
          <RfStopIcon class="!h-6 !w-6" />
          Stop
        </RfButtonNew>

        <RfButtonNew v-if="selected.size === 1" type="table" @click="$emit('clone')">
          <RfCloneIcon class="!h-6 !w-6" />
          Clone
        </RfButtonNew>

        <RfButtonNew type="table" @click="$emit('export')">
          <RfDownloadIcon class="!h-6 !w-6" />
          Export to CSV
        </RfButtonNew>

        <RfButtonNew type="table" @click="$emit('delete')">
          <RfDeleteIcon class="!h-6 !w-6" />
          Delete
        </RfButtonNew>
      </span>
    </Transition>
  </div>
</template>

<script setup>
import RfMenu from "@/components/menus/RfMenu.vue";
import RfCheckbox from "@/components/inputs/RfCheckbox.vue";
import {
  SimplifyStatusesMapConstants,
  StatusesSimplifiedConstants,
} from "@/utils/constants/PromptStatusesConstants";
import RfDeleteIcon from "@/components/icons/RfDeleteIcon.vue";
import RfCloneIcon from "@/components/icons/RfCloneIcon.vue";
import RfBaseTag from "@/components/tags/RfBaseTag.vue";
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import RfEditIcon from "@/components/icons/RfEditIcon.vue";
import RfPauseIcon from "@/components/icons/RfPauseIcon.vue";
import RfStopIcon from "@/components/icons/RfStopIcon.vue";
import getPromptStatus from "@/utils/prompts/getPromptStatus";
import RfPlayIcon from "@/components/icons/RfPlayIcon.vue";
import RfDownloadIcon from "@/components/icons/RfDownloadIcon.vue";
import { computed } from "vue";
import { isLatestExperimentActive } from "@/utils/prompts/promptHelpers";

const props = defineProps({
  selected: { type: Map, default: null },
  tableData: { type: Object, default: () => ({ page: 1, perPage: 10, totalCount: 10 }) },
  timezone: { type: String, default: "" },
});

const isAllSelected = computed(() => props.tableData.showing === props.selected.size);

const selectedStatuses = computed(() =>
  [...props.selected].reduce((acc, [_, prompt]) => {
    const status = SimplifyStatusesMapConstants[getPromptStatus(prompt, props.timezone).type].type;
    return {
      ...acc,
      [status]: +!!acc[status] + 1,
      disableStatusChange:
        acc.disableStatusChange ||
        !!prompt.sequence_id ||
        !!prompt.pipeline_id ||
        isLatestExperimentActive({ path: prompt }) ||
        status === SimplifyStatusesMapConstants.limited.type,
    };
  }, {}),
);
</script>

<style lang="scss" scoped>
::v-deep.rf-bulk-action-menu--checkbox {
  @apply h-full self-stretch;

  .rf-menu--button {
    @apply flex h-full gap-2 pl-4;
  }

  .rf-menu--icon-wrapper {
    @apply my-0 self-stretch pr-1;
  }

  .rf-menu--opened .rf-menu--icon-wrapper {
    @apply bg-blue-1 bg-opacity-10;
    .rf-icon {
      @apply fill-blue-1 #{!important};
    }
  }
}
</style>
