<template>
  <div class="inline-flex items-center gap-3">
    <label v-if="title" class="label text-buttons !text-grey-1" :for="`input${_uid}`">
      {{ title }}
    </label>
    <button
      :id="`input${_uid}`"
      class="button flex-shrink-0 cursor-pointer rounded-full bg-blue-3"
      :class="{ 'button--on !bg-blue-1': value }"
      role="switch"
      type="button"
      :aria-checked="value"
      @click="$emit('input', !value)"
    >
      <span
        class="knob block rounded-full bg-white-1"
        :class="{ 'knob--on ': value }"
        aria-hidden="true"
      >
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: [String, Boolean], default: false },
    value: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 38px;
  height: 25px;
  transition: background 100ms ease-in-out;

  &:focus {
    outline: none;
  }
}

.knob {
  width: 21px;
  height: 21px;
  transition: transform 300ms ease-in-out;
  transform: translateX(2px);
  box-shadow: 0px 3px 9px 0px rgba(92, 104, 124, 0.16);

  &--on {
    transform: translateX(calc(100% - 10.5px + 4px));
  }
}
</style>
