<template>
  <div class="promo-design-inputs">
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>Email Settings</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <v-text-field
              v-model.trim="actions.rf_email_subject"
              label="Email Subject"
              hide-details
              outlined
              dense
            />
            <RfInsertUserTrait
              :app="app"
              actionName="rf_email_subject"
              title="subject"
              :actions="actions"
            />
          </div>
          <div class="promo-fonts-size-input" style="padding: 15px 0 0">
            <v-text-field
              v-model.trim="actions.rf_widget_width"
              label="Email Container Width"
              hide-details
              outlined
              dense
            />
            <v-text-field
              v-model.trim="actions.rf_settings_title_padding_top"
              label="Container Margin Top"
              hide-details
              outlined
              dense
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Email Header</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsCompanyLogo :app="app" :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Hero Image</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionImageUpload :actions="actions" :model="model" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Message</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsTitleMessage :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>User Interaction</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionButtons :actions="actions" :model="model" :showCloseOptions="false" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Email Footer</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <v-text-field
            outlined
            dense
            label="Company Name & Address"
            v-model.trim="actions.rf_email_company_name"
            required
            hide-details
          />
          <div class="promo-fonts-size-input" style="padding: 15px 0">
            <v-text-field
              outlined
              dense
              hide-details
              label="Footer background"
              v-model.trim="actions.rf_email_footer_bgcolor"
            />
            <v-text-field
              outlined
              dense
              hide-details
              label="Font color"
              v-model.trim="actions.rf_email_footer_font_color"
            />
          </div>
          <div class="promo-design-group">
            <v-text-field
              outlined
              dense
              label="Privacy Policy Link"
              v-model.trim="actions.rf_settings_privacy_policy_link"
              placeholder="e.g.: http://company.com/pagename"
              hide-details
              style="margin-bottom: 14px"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Unsubscribe Link"
              v-model.trim="actions.rf_settings_tos_link"
              hide-details
              placeholder="e.g.: http://company.com/pagename"
            ></v-text-field>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Background & Content Colors</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="promo-fonts-size-input">
            <v-text-field
              outlined
              dense
              hide-details
              label="Body background color"
              v-model.trim="actions.rf_email_body_bgcolor"
            />
            <v-text-field
              outlined
              hide-details
              dense
              label="Container background color"
              v-model.trim="actions.rf_email_content_bgcolor"
            ></v-text-field>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>CSS</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfPromoCustomCSS :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import RfRetentionActionsTitleMessage from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsTitleMessage.vue";
import RfRetentionButtons from "@/components/RfPathCreate/RfRetentionActions/RfRetentionButtons.vue";
import RfRetentionImageUpload from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionImageUpload.vue";
import RfPromoCustomCSS from "@/components/RfPromotions/RfPromoCustomCSS.vue";
import RfInsertUserTrait from "@/components/RfPathCreate/RfRetentionActions/RfInsertUserTrait.vue";
import RfRetentionActionsLinks from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsLinks.vue";
import RfRetentionActionsCompanyLogo from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsCompanyLogo.vue";

export default {
  name: "RfRetentionActionsEmailPromo",
  props: ["actions", "model", "app"],
  components: {
    RfRetentionActionsTitleMessage,
    RfRetentionButtons,
    RfRetentionImageUpload,
    RfPromoCustomCSS,
    RfInsertUserTrait,
    RfRetentionActionsLinks,
    RfRetentionActionsCompanyLogo,
  },
  data() {
    return {
      getPromoPanel: [0],
    };
  },
  computed: {},
};
</script>
