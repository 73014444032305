<template>
  <div class="rf-guides rf-main-content-wrapper">
    <div v-if="showEmpty" class="absolute inset-0 left-16 z-0 flex h-full">
      <div class="align-center m-auto inline-flex flex-col gap-4 pt-10 text-center">
        <span class="text-heading-1 uppercase">No Guides Found</span>
        <span class="text-heading-2 !font-normal">Looks like you haven't created a guide yet.</span>
        <RfButtonNew
          text="+ New Guide"
          type="main"
          class="mt-2"
          :disabled="isDisabledRoleMixin"
          @click="guideModal.show()"
        />
      </div>
    </div>
    <template v-else>
      <div class="flex min-h-12 items-center justify-between gap-16">
        <div class="flex min-w-px flex-1 items-center gap-8">
          <h1 class="flex-shrink-0 text-xl font-medium uppercase not-italic">All guides</h1>
          <div
            class="flex flex-grow-0 items-center gap-4 overflow-y-hidden overflow-x-scroll pb-1 pt-2"
          >
            <template v-for="(filter, filterKey) in filters">
              <RfBaseTag
                v-for="(el, key) in filter"
                data-cy="guides-tag"
                @clear="removeFilter(filterKey, key), refetch()"
              >
                {{ el }}
              </RfBaseTag>
            </template>
          </div>
          <button
            v-if="filtersLength"
            data-cy="guides-tag--clear-all"
            class="text-body flex-shrink-0"
            @click="(filters = {}), refetch()"
          >
            Clear all filters
          </button>
        </div>
        <div class="flex flex-grow-0 items-center gap-4">
          <RfButtonInput
            v-model="searchInputs.inputs.value[GUIDES_SEARCH]"
            text="Search"
            placeholder="Search"
            :icon="RfSearchIcon"
            @input="refetch()"
          />
          <RfButtonNew
            text="+ New Guide"
            data-cy="guide--create-guide"
            type="main"
            :disabled="isDisabledRoleMixin"
            @click="guideModal.show()"
          />
        </div>
      </div>
      <RfTable
        :tableData="guidesStore.table"
        :loading="guidesStore.loading"
        :rowSkeleton="RfTableRowSkeleton"
        type="guides"
        @loadMore="refetch(true)"
      >
        <template #pretable>
          <span class="mb-0 mt-4 inline-flex items-stretch gap-4">
            <RfTableSelect
              :selected="selected"
              :tableData="guidesStore.table"
              :timezone="timezone"
              @status="
                status => (
                  (statusUpdate = { status, guide: selectedValue }),
                  $nextTick(() => $refs.statusModal.show())
                )
              "
              @delete="(deleteItem = valuesSelected), $nextTick(() => $refs.deleteModal.show())"
              @edit="guideModel.$assign(selectedValue), $nextTick(() => guideModal.show())"
              @clone="(cloneItem = selectedValue), $nextTick(() => $refs.cloneModal.show())"
              @export="exportMultipleGuidesToCSV([...selected.values()], date.preset?.value)"
              @selectAll="guidesStore.guides.forEach(v => updateMap('set', v))"
              @deselectAll="guidesStore.guides.forEach(v => updateMap('delete', v))"
              @selectByPreset="selectByPreset"
            />
          </span>
          <span class="my-4 inline-flex items-center gap-4">
            <RfDatePicker
              ref="datePicker"
              :min="
                currApp?.flags?.one_year_metrics
                  ? dayjs().subtract(1, 'year')
                  : dayjs().subtract(90, 'days')
              "
              :max="dayjs()"
              @input="v => ((date = v), refetch())"
            />
          </span>
        </template>
        <template #thead>
          <RfTableHeader
            :segments="segments"
            :filters.sync="filters"
            :sort="sort"
            @update:sort="v => ((sort = v), refetch())"
            @update:filters="refetch()"
          />
        </template>
        <template #tbody>
          <RfTableRow
            v-for="guide in guidesStore.guides"
            :key="guide.id"
            :guide="guide"
            :disabled="isDisabledRoleMixin"
            :timePeriod="date.preset?.value"
            :timezone="timezone"
            :selected="selected.has(guide.id)"
            @select="updateMap('set', guide)"
            @deselect="updateMap('delete', guide)"
            @edit="guideModel.$assign(guide), $nextTick(() => guideModal.show())"
            @delete="(deleteItem = [guide]), $nextTick(() => $refs.deleteModal.show())"
            @status="
              status => (
                (statusUpdate = { status, guide }), $nextTick(() => $refs.statusModal.show())
              )
            "
            @clone="(cloneItem = guide), $nextTick(() => $refs.cloneModal.show())"
          />
        </template>
      </RfTable>
    </template>
    <RfBaseModal
      v-if="cloneItem"
      ref="cloneModal"
      width="580"
      message="Do you want to clone this guide?"
      secondary-text="Clone"
      @secondary="submitClone"
      @close="cloneItem = null"
    >
      <template #title>
        Are you sure you want to clone
        <span class="whitespace-pre font-bold"> {{ cloneItem.name }} </span> ?
      </template>
    </RfBaseModal>
    <RfBaseModal
      v-if="deleteItem"
      ref="deleteModal"
      :message="`${deleteDialogText.body} This cannot be undone.`"
      width="580"
      error-text="Delete"
      @error="submitDelete"
      @close="deleteItem = null"
    >
      <template #title>
        Are you sure you want to delete
        <span class="whitespace-pre font-bold"> {{ deleteDialogText.title }} </span> ?
      </template>
    </RfBaseModal>
    <RfGuideModal
      ref="guideModal"
      :guide="guideModel"
      @reset="guideModel.$reset"
      :submitCallback="submitGuide"
    />
    <RfBaseModal
      v-if="statusUpdate.guide"
      ref="statusModal"
      :title="`${capitalize(statusChangeDialogText.title)} Guide`"
      width="580"
      :secondary-text="capitalize(statusChangeDialogText.title)"
      @secondary="submitStatusUpdate"
      @close="statusUpdate = { status: null, guide: null }"
    >
      <template #body>
        <span v-if="Array.isArray(statusUpdate.guide)">
          Are you sure you want to {{ statusChangeDialogText.title }}
          <b>guides({{ statusUpdate.guide.length }})</b>?
          <b>These guides({{ statusUpdate.guide.length }})</b> will
          {{ statusChangeDialogText.body }}
        </span>
        <span v-else>
          Are you sure you want to {{ statusChangeDialogText.title }} this guide? The guide will
          {{ statusChangeDialogText.body }}

          <RfStartStopDatePicker
            v-if="statusUpdate.status === StatusesSimplifiedConstants.ready.type"
            ref="dateRangePicker"
            class="mt-4"
            :dateRange="{
              startDate: dayjs(statusUpdate.guide.start_date).utc().toISOString(),
              endDate: dayjs(statusUpdate.guide.end_date).utc().toISOString(),
            }"
            :actions="statusUpdate.guide.actions"
          />
        </span>
      </template>
    </RfBaseModal>
  </div>
</template>

<script>
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import RfButtonInput from "@/components/inputs/RfButtonInput.vue";
import RoleMixin from "@/utils/RoleMixin";
import RfSearchIcon from "@/components/icons/RfSearchIcon.vue";
import RfRenameModal from "@/components/modals/RfRenameModal.vue";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import { reactive, ref, watch } from "vue";
import RfLinkedSegments from "@/components/RfPathCreate/RfLinkedSegments.vue";
import { GUIDE_TYPES } from "@/utils/constants/GuidesConstants";
import { mapActions, mapState } from "vuex";
import { debounce } from "@/utils/debounce";
import { useGuidesStore } from "@/pinia/guidesStore";
import MetricsUtils from "@/utils/MetricsUtils";
import RfDatePicker from "@/components/inputs/RfDatePicker.vue";
import RfTable from "@/blocks/RfTable/RfTable.vue";
import RfTableHeader from "@/blocks/RfGuides/RfTableHeader.vue";
import RfTableSelect from "@/blocks/RfPrompts/RfTableSelect.vue";
import RfTableRow from "@/blocks/RfGuides/RfTableRow.vue";
import StatusesConstants, {
  SimplifyStatusesMapConstants,
  StatusesSimplifiedConstants,
} from "@/utils/constants/PromptStatusesConstants";
import RfTableRowSkeleton from "@/blocks/RfGuides/RfTableRowSkeleton.vue";
import RfBaseTag from "@/components/tags/RfBaseTag.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import { getGuideLink } from "@/utils/getLink";
import StringUtils from "@/utils/StringUtils";
import getPromptStatus from "@/utils/prompts/getPromptStatus";
import { useTableFilter } from "@/utils/composables/useTableFilter";
import { useTableSelect } from "@/utils/composables/useTableSelect";
import { useTableSort } from "@/utils/composables/useTableSort";
import dayjs from "dayjs";
import { useToastsStore } from "@/pinia/toastsStore";
import RfGuideModal from "@/blocks/RfModals/RfGuideModal.vue";
import { returnFutureEndDate } from "@/utils/TimeUtils";
import { exportMultipleGuidesToCSV } from "@/utils/CsvUtils";
import RfStartStopDatePicker from "@/components/RfStartStopDatePicker.vue";
import { GUIDES_SEARCH, useSearctInputs } from "@/utils/composables/useSearctInputs";

const debounceTime = 1000;
export default {
  name: "RfGuides",
  setup() {
    const guidesStore = useGuidesStore();
    const showEmpty = ref(false);
    const date = ref({});
    const statusUpdate = ref({ status: null, guide: null });
    const { filters, removeFilter, filtersLength, filtersConverted } = useTableFilter();
    const { selected, updateMap, resetSelected, selectedValue, valuesSelected } = useTableSelect();
    const sort = useTableSort(["start_date", "end_date", "default"]);
    const deleteItem = ref(null);
    const cloneItem = ref(null);
    const searchInputs = useSearctInputs();
    const guideModal = ref();
    const dateRangePicker = ref();
    const guide = reactive({
      id: null,
      name: "",
      description: "",
      sequence_type: "web_client",
      segments: [],
      $reset: () => {
        guide.id = null;
        guide.name = guide.description = "";
        guide.sequence_type = "web_client";
        guide.segments = [];
      },
      $assign: v => {
        v.id && (guide.id = v.id);
        v.description && (guide.description = v.description);
        v.name && (guide.name = v.name);
        v.sequence_type && (guide.sequence_type = v.sequence_type);
        v.segments && (guide.segments = v.segments);
      },
    });
    watch(
      [
        filtersLength,
        () => guidesStore.guides.length,
        () => guidesStore.loading,
        () => searchInputs.inputs.value[GUIDES_SEARCH],
      ],
      debounce(([filtersLength, guidesLength, guidesLoading, searchInput]) => {
        showEmpty.value = !filtersLength && !guidesLength && !guidesLoading && !searchInput;
      }, debounceTime + 100),
    );

    return {
      showEmpty,
      guideModel: guide,
      date,
      filters,
      searchInputs,
      GUIDES_SEARCH,
      selected,
      RfSearchIcon,
      guidesStore,
      toastsStore: useToastsStore(),
      GUIDE_TYPES,
      capitalize: StringUtils.capitalize,
      RfTableRowSkeleton,
      deleteItem,
      cloneItem,
      statusUpdate,
      removeFilter,
      filtersLength,
      filtersConverted,
      selected,
      updateMap,
      resetSelected,
      selectedValue,
      valuesSelected,
      sort,
      dayjs,
      guideModal,
      exportMultipleGuidesToCSV,
      StatusesSimplifiedConstants,
      dateRangePicker,
    };
  },
  components: {
    RfButtonNew,
    RfButtonInput,
    RfRenameModal,
    RfLinkedSegments,
    RfDatePicker,
    RfTable,
    RfTableHeader,
    RfTableSelect,
    RfTableRow,
    RfBaseTag,
    RfBaseModal,
    RfGuideModal,
    RfStartStopDatePicker,
  },
  mixins: [RoleMixin, UserSettingsMixin],
  data() {
    return {
      getGuides: async (next = false) => {
        const statuses = this.filtersConverted.statuses?.string;
        const segments_ids = this.filtersConverted.segments?.string;
        const sequence_type = this.filtersConverted.sequence_type?.string;
        this.searchInputs.write();

        await this.guidesStore.getGuidesPaged(
          this.currApp.id,
          {
            metric_periods: [
              {
                period: this.date.preset.value,
                ...(this.date.preset.value === "custom" && {
                  date_range: [
                    this.date.startDate.format("YYYY-MM-DD"),
                    this.date.endDate.format("YYYY-MM-DD"),
                  ],
                }),
              },
            ],
            ...(this.searchInputs.inputs.value[GUIDES_SEARCH] && {
              name_search: this.searchInputs.inputs.value[GUIDES_SEARCH],
            }),
            ...(statuses && { statuses }),
            ...(segments_ids && { segments_ids }),
            ...(sequence_type && { sequence_type }),
            ...(this.sort.string && { order: this.sort.string }),
          },
          next,
        );

        await this.setUserSettings({
          guidesSort: this.sort.string,
          guidesStatusFilter: this.filtersConverted.statuses?.array,
          guidesSegmentFilter: this.filtersConverted.segments?.array,
          guidesType: this.filtersConverted.sequence_type?.array,
          ...(this.date.preset.value !== "custom"
            ? { guidesDateRange: this.date.preset.value }
            : {}),
        });
      },
      refetch: debounce(async (next = false) => {
        if (!next) {
          this.resetSelected();
          this.guidesStore.resetPage();
        }
        await this.getGuides(next);
      }, debounceTime),
    };
  },
  computed: {
    ...mapState({
      currApp: state => state.apps.currApp,
      segments: state => state.apps.segments.filter(s => !s.pipeline_stage_id),
    }),
    timezone() {
      return this.currApp.timezone_offset;
    },
    deleteDialogText() {
      if (this.deleteItem.length > 1)
        return {
          title: `${this.deleteItem.length} guides`,
          body: `Deleting this guides(${this.deleteItem.length}) removes all information about these guides(${this.deleteItem.length}).`,
        };
      return {
        title: this.deleteItem[0].name,
        body: "Deleting this guide removes all information about this guide.",
      };
    },
    guideStatus() {
      return !this.statusUpdate.guide
        ? null
        : SimplifyStatusesMapConstants[
            getPromptStatus(
              Array.isArray(this.statusUpdate.guide)
                ? this.statusUpdate.guide[0]
                : this.statusUpdate.guide,
              this.timezone,
            ).type
          ].type;
    },
    statusChangeDialogText() {
      if (this.statusUpdate.status === StatusesSimplifiedConstants.running.type)
        return {
          title: this.guideStatus === StatusesSimplifiedConstants.paused.type ? "resume" : "start",
          body: "run immediately.",
        };
      if (this.statusUpdate.status === StatusesSimplifiedConstants.paused.type)
        return { title: "pause", body: "run on the scheduled start date." };
      if (this.statusUpdate.status === StatusesSimplifiedConstants.ended.type)
        return { title: "end", body: "stop running immediately." };
      if (this.statusUpdate.status === StatusesSimplifiedConstants.ready.type)
        return { title: "schedule", body: "run on start date." };
      return { title: "", body: "" };
    },
  },
  methods: {
    ...mapActions(["createSequence", "getSegments", "cloneSequence"]),
    async submitGuide({ name, description, fillModel }) {
      fillModel?.(this.guideModel);
      const { id, sequence_type, segments } = this.guideModel;
      const guide = { name, description, sequence_type, segments };
      if (id) {
        await this.updateSegment([{ id, ...guide }]);
      } else {
        const { id } = await this.createSequence({ appId: this.currApp.id, newSequence: guide });
        this.$router.replace({ path: getGuideLink(id, this.currApp.id) });
      }

      this.guideModel.$reset();
      this.resetSelected();
    },
    submitClone() {
      this.cloneSequence({
        appId: this.currApp.id,
        sequenceId: this.cloneItem.id,
      }).then(({ id }) => this.$router.replace({ path: getGuideLink(id, this.currApp.id) }));
    },
    submitStatusUpdate() {
      const item = {
        is_enabled: [
          StatusesSimplifiedConstants.running.type,
          StatusesSimplifiedConstants.ready.type,
        ].includes(this.statusUpdate.status),
      };

      const runningPausedSwitch =
        (this.statusUpdate.status === StatusesSimplifiedConstants.running.type &&
          this.guideStatus !== StatusesSimplifiedConstants.paused.type) ||
        (this.statusUpdate.status === StatusesSimplifiedConstants.paused.type &&
          this.guideStatus === StatusesSimplifiedConstants.ended.type);

      if (runningPausedSwitch) {
        item.start_date = dayjs().format("YYYY-MM-DD");
        item.end_date = dayjs().add(1, "year").format("YYYY-MM-DD");
        item.is_expired = false;
      }
      if (this.statusUpdate.status === StatusesSimplifiedConstants.ended.type) {
        item.end_date = dayjs().subtract(1, "days").format("YYYY-MM-DD");
        item.is_expired = true;
      }

      if (this.statusUpdate.status === StatusesSimplifiedConstants.ready.type) {
        if (this.dateRangePicker) {
          const { fullStartDate, fullEndDate } = this.dateRangePicker;
          if (dayjs(fullStartDate) >= dayjs(fullEndDate))
            throw new Error("Start date must be before end date");

          item.start_date = fullStartDate;
          item.end_date = fullEndDate;
        }
      }

      const paths = Array.isArray(this.statusUpdate.guide)
        ? this.statusUpdate.guide.map(({ id, end_date }) => ({
            ...item,
            id,
            end_date: runningPausedSwitch
              ? returnFutureEndDate(end_date, item.end_date)
              : item.end_date,
          }))
        : [
            {
              ...item,
              id: this.statusUpdate.guide.id,
              end_date: runningPausedSwitch
                ? returnFutureEndDate(this.statusUpdate.guide.end_date, item.end_date)
                : item.end_date,
            },
          ];
      this.editGuide(paths)
        .then(() => {
          this.$refs.statusModal.close();
          this.toastsStore.create({ type: "success", body: "Status updated" });
        })
        .catch(_ => null);
    },
    selectByPreset(preset) {
      this.resetSelected();
      this.guidesStore.guides.forEach(guide => {
        SimplifyStatusesMapConstants[getPromptStatus(guide, this.timezone).type].type === preset &&
          this.updateMap("set", guide);
      });
    },
    updateSegment(guides) {
      this.editGuide(guides)
        .then(() => this.toastsStore.create({ type: "success", body: "Guide updated" }))
        .catch(() => null);
    },
    editGuide(guides) {
      this.resetSelected();
      return this.guidesStore.bulkUpdate(this.currApp.id, guides);
    },
    async submitDelete() {
      try {
        const body = `Guide${this.deleteItem.length > 1 ? "s" : ""} deleted`;
        await this.guidesStore.bulkDelete(this.currApp.id, this.deleteItem);
        this.$refs.deleteModal.close();
        this.resetSelected();

        const oldPage = this.guidesStore.table.page;
        const oldPerPage = this.guidesStore.table.perPage;
        this.guidesStore.table.page = 0;
        this.guidesStore.table.perPage = oldPage * oldPerPage;
        await this.refetch();
        this.toastsStore.create({ type: "success", body });
        this.guidesStore.table.page = oldPage;
        this.guidesStore.table.perPage = oldPerPage;
      } catch (e) {}
    },
  },
  async mounted() {
    this.sort = this.getUserSetting("guidesSort");

    !this.segments.length && (await this.getSegments({ appId: this.currApp.id }));

    this.filters = {
      statuses: Object.fromEntries(
        (this.getUserSetting("guidesStatusFilter") || []).map(el => [
          StatusesConstants[el].type,
          StatusesConstants[el].title,
        ]),
      ),
      sequence_type: Object.fromEntries(
        (this.getUserSetting("guidesType") || []).map(el => [
          GUIDE_TYPES[el].value,
          GUIDE_TYPES[el].label,
        ]),
      ),
      segments: Object.fromEntries(
        (this.getUserSetting("guidesSegmentFilter") || []).map(el => [
          el,
          this.segments.find(({ key }) => key === el).name,
        ]),
      ),
    };

    this.$nextTick(() => {
      const preset = MetricsUtils.All_Periods.find(
        ({ value }) =>
          value !== "since_start_date" && value === this.getUserSetting("guidesDateRange"),
      )?.value;

      this.$refs.datePicker?.setTimeFromPreset(preset || "last_seven_days");
    });
  },
};
</script>
