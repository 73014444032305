<template>
  <RfSimpleCard>
    <div class="settings-actions-tabs users-setting-tabs">
      <div :class="{ selectedTab: userTab === 'tab5' }">
        <button @click="userTab = 'tab5'">Pulse Users</button>
      </div>
      <div :class="{ selectedTab: userTab === 'tab4' }">
        <button @click="userTab = 'tab4'">Organizations</button>
      </div>
      <div :class="{ selectedTab: userTab === 'tab2' }">
        <button @click="userTab = 'tab2'">Test Users</button>
      </div>
      <div :class="{ selectedTab: userTab === 'tab3' }">
        <button @click="userTab = 'tab3'">User Lookup</button>
      </div>
      <div :class="{ selectedTab: userTab === 'tab1' }">
        <button @click="userTab = 'tab1'">Global Limits</button>
      </div>
    </div>
    <div class="user-setting-content">
      <div v-if="userTab === 'tab1'">
        <div class="global-holdout-setting">
          <h4>Set Global Holdout percentage</h4>
          <div class="hold-cap-div">
            Lowering the holdout percentage will cause existing holdout users to become eligible for
            prompts
          </div>
          <VueSlider
            style="margin: 40px 10px 50px 10px"
            v-model="pathFilter.global_holdout_percentage"
            :height="5"
            :max="100"
            :marks="globalHoldoutPercentages"
            :lazy="true"
            :disabled="isReadOnlyRoleMixin"
          />
        </div>
        <div class="global-frequency-cap-opt">
          <RfFrequencyCap v-if="currApp?.path_filter" :currApp="currApp" />
          <RfImpressionLimits v-if="pathFilter.impressions" v-model="pathFilter.impressions" />
        </div>
      </div>

      <div v-if="userTab === 'tab2'">
        <div class="add-testusers-div">
          <div class="add-testusers-label">Designate test user:</div>
          <div class="flex-grow-1 d-flex mt-7">
            <div class="add-testusers-id">
              <RfTextInput
                hide-details
                outlined
                dense
                placeholder="e.g. 123456789"
                title="User Id"
                v-model="newTestUserId"
              />
              <RfTextInput
                hide-details
                outlined
                dense
                placeholder="Description"
                title="Description"
                v-model="newTestUserDesc"
              />
            </div>
            <RfButton
              icon="add"
              button-text="Add"
              type="submit"
              color="accent"
              class="mt-5"
              :disabled="isDisabledRoleMixin || (!newTestUserId && 'Missing test user ID')"
              @click="createTestUser"
            />
          </div>
        </div>
        <v-simple-table class="rf-settings-table-data">
          <template v-slot:default>
            <thead>
              <tr>
                <th style="min-width: 200px" class="text-left">User Id</th>
                <th style="min-width: 200px" class="text-left">Description</th>
                <th class="text-left">Reset Clicks</th>
                <th class="text-left">Impersonate</th>
                <th class="text-left">Remove Test User Designation</th>
              </tr>
            </thead>
            <tbody v-if="testUsers && testUsers.length > 0">
              <tr v-for="(item, index) in testUsers" :key="index">
                <td>{{ item.user_uid }}</td>
                <td>{{ item.metadata.description }}</td>
                <td>
                  <RfButton
                    icon="autorenew"
                    color="#AFBABF"
                    :disabled="isDisabledRoleMixin"
                    @click="onResetGoals(item)"
                  />
                </td>
                <td>
                  <v-btn depressed color="info" @click="clickLive(item)" small>
                    <v-icon size=" 15" left>open_in_new</v-icon>Live Preview
                  </v-btn>
                </td>
                <td>
                  <RfButton
                    icon="clear"
                    color="error"
                    :disabled="isDisabledRoleMixin"
                    @click="onDeleteTestUser(item)"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4">
                  <div class="settings-no-data-message">Nothing to show.</div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-if="userTab === 'tab3'">
        <div class="fup-text">Look up a user's traits and prompts.</div>
        <div class="fetch-user-promo-ct">
          <RfTextInput
            hide-details
            outlined
            class="flex-grow-1"
            dense
            placeholder="e.g. 123456789"
            title="User Id"
            v-model="getActionsUserId"
            background-color="#fff"
          />
          <v-btn
            depressed
            type="submit"
            color="accent"
            class="mt-5"
            @click="getActionsForEndUser"
            :disabled="!getActionsUserId"
          >
            View
          </v-btn>
          <v-btn
            depressed
            type="submit"
            color="error"
            class="mt-5"
            @click="clickLive"
            :disabled="!getActionsUserId"
          >
            Impersonate
          </v-btn>
        </div>
      </div>
      <div v-if="userTab === 'tab4'">
        <div class="add-user-header-div">
          <div class="add-user-title">
            <h4>Organizations</h4>
          </div>
          <RfButton
            icon="add"
            button-text="Add organization"
            color="success"
            class="px-7"
            :disabled="isDisabledExtendedRoleMixin"
            @click="openAddOrgModal"
          />
        </div>
        <v-simple-table class="rf-settings-table-data">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left" v-if="isSuperAdminRoleMixin">Key</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in orgs" :key="index">
                <td>{{ item.name }}</td>
                <td v-if="isSuperAdminRoleMixin">
                  {{ item.key }}
                </td>
                <td>
                  <div class="settings-button-wrapper">
                    <template v-if="isAdminRoleMixin && item.key !== 'default'">
                      <RfButton
                        icon="edit"
                        color="accent"
                        :disabled="isDisabledExtendedRoleMixin"
                        @click="onEditOrg(item)"
                      />
                      <RfButton
                        icon="clear"
                        color="error"
                        :disabled="isDisabledExtendedRoleMixin"
                        @click="showConfirmDeleteOrgDialog(item.id)"
                      />
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-if="userTab === 'tab5'">
        <div class="add-user-header-div">
          <div class="add-user-title">
            <h4>Users Manager</h4>
          </div>
          <RfButton
            icon="add"
            button-text="Add user"
            color="success"
            class="px-7"
            :disabled="isDisabledExtendedRoleMixin"
            @click="openAddUserModal"
          />
        </div>
        <v-simple-table class="rf-settings-table-data">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Email</th>
                <th class="text-left">Status</th>
                <th class="text-left">Role</th>
                <th
                  v-if="currApp?.company_sso_enabled || currentCompany?.sso_properties"
                  class="text-left"
                >
                  IDP
                </th>
                <th style="width: 100px"></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in currUsers" :key="item.id">
                <td>{{ item.first_name }} {{ item.last_name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.invitation_accepted ? "Active" : "Invited" }}</td>
                <td>
                  <div>{{ displayRole(item) }}</div>
                  <div v-if="isAdminingCompany">
                    Allowed apps: {{ displayAllowedAppsText(item) }}
                  </div>
                </td>
                <td v-if="currApp?.company_sso_enabled || currentCompany?.sso_properties">
                  {{ item.sso_idp }}
                </td>
                <td>
                  <v-btn
                    v-if="isSuperAdminRoleMixin"
                    small
                    depressed
                    color="info"
                    @click="impersonate(item)"
                    class="impersonate"
                    >Login as user</v-btn
                  >
                </td>
                <td>
                  <div class="settings-button-wrapper">
                    <template v-if="isAdminRoleMixin">
                      <RfButton
                        icon="edit"
                        color="accent"
                        :disabled="!canModifyUserRoleMixin(item.role) && 'Insufficient Role'"
                        @click="onEditUser(item)"
                      />
                      <RfButton
                        icon="clear"
                        color="error"
                        :disabled="!canModifyUserRoleMixin(item.role) && 'Insufficient Role'"
                        @click="showConfirmDeleteDialog(item.id)"
                      />
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <v-dialog v-model="showDeleteOrgDialog" persistent max-width="600" width="550">
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <v-card-text>
          Deleting an organization cannot be undone. Existing prompts, segments, users, and all
          other items will be re-assigned to the selected organization.
          <RfSelect
            dense
            outlined
            title="Organizations"
            name="orgs"
            class="mt-5"
            hide-details
            v-model="reassignedOrgId"
            :items="assignableOrgs"
          />
        </v-card-text>

        <v-card-actions class="modal-card-actions-div">
          <v-btn class="cancel-btn px-7" outlined @click="closeDeleteOrgDialog()">Cancel</v-btn>
          <v-btn color="success" class="px-10" :disabled="!reassignedOrgId" @click="onDeleteOrg()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAddEditOrgDialog" persistent max-width="600" width="550">
      <v-card tag="form" v-on:submit.prevent="onSubmitOrg">
        <v-card-title class="headline">{{
          org.id ? "Edit Organization" : "Add Organization"
        }}</v-card-title>
        <v-card-text>
          <div class="add-users-form-div">
            <RfTextInput
              placeholder="Marketing"
              outlined
              dense
              title="Name"
              persistent-hint
              hint="Specify an organization name role."
              v-model="org.name"
            ></RfTextInput>
          </div>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn depressed class="cancel-btn px-7" outlined @click="showAddEditOrgDialog = false">
            Cancel
          </v-btn>
          <v-btn depressed v-if="org.id" type="submit" color="success" class="px-10">
            <v-icon left>update</v-icon>Update Organization
          </v-btn>
          <v-btn depressed v-else type="submit" color="success" class="px-10">
            <v-icon left>add</v-icon>Add Org
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteUserDialog" persistent max-width="600" width="550">
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <v-card-text>Deleting a user cannot be undone.</v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn class="cancel-btn px-7" outlined @click="closeDeleteDialog()">Cancel</v-btn>
          <v-btn color="error" @click="onDeleteUser()" class="px-10">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAddEditUserDialog" persistent max-width="600" width="550">
      <v-card tag="form" v-on:submit.prevent="onSubmitUser">
        <v-card-title class="headline">{{ user.id ? "Edit User" : "Add User" }}</v-card-title>

        <v-card-text>
          <div class="helper-text">
            Specify user's name, email, and role. Refer to the
            <a target="_blank" href="https://help.redfast.com/docs/pulse-users">documentation</a> for additional
            information.
            <v-alert type="error" v-if="isAdminingCompany">
              Only create Super Admins using this view since organizations cannot be set. All of the
              customer's users should be created in the settings section after logging into the
              correct app</v-alert
            >
          </div>
          <div class="add-users-form-div">
            <RfTextInput
              placeholder="John"
              outlined
              dense
              title="First Name"
              v-model="user.first_name"
            />
            <RfTextInput
              placeholder="Doe"
              outlined
              dense
              title="Last Name"
              v-model="user.last_name"
            />
            <RfTextInput
              outlined
              dense
              type="email"
              title="Email"
              v-model="user.email"
              placeholder="name@emailaddress.com"
            />

            <RfSelect
              dense
              outlined
              title="Departments"
              multiple
              name="departments"
              v-model="user.departments"
              :items="selectableDepartments"
              v-if="isSuperAdminRoleMixin"
            />
            <div class="app-user-role-row">
              <template v-if="isSuperAdminRoleMixin || isAdminingCompany">
                <RfSelect
                  v-if="appUsers[currApp?.id]"
                  v-model="appUsers[currApp.id].org_id"
                  dense
                  outlined
                  title="Organizations"
                  name="orgs"
                  :items="orgsSelection"
                ></RfSelect>
                <div class="company-user-role">
                  <label>Company Role</label>
                  <v-radio-group hide-details row v-model="user.role">
                    <v-radio label="Pending" value="pending"></v-radio>
                    <v-radio label="Member" value="member"></v-radio>
                    <v-radio label="Admin" value="admin"></v-radio>
                  </v-radio-group>
                </div>
                <div v-if="user.role === 'member'">
                  <div class="company-user-role" v-for="{ name, id } in apps" :key="id">
                    <label>{{ name }} App Role</label>
                    <v-radio-group v-if="appUsers[id]" hide-details row v-model="appUsers[id].role">
                      <v-radio label="None" value=""></v-radio>
                      <v-radio
                        v-for="(value, key) in appRoles"
                        :key="key"
                        :label="value"
                        :value="key"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </template>
              <div v-else-if="user.role === 'member' && appUsers[currApp?.id]">
                <v-radio-group v-model="appUsers[currApp.id].role" row class="thirdparty-radio">
                  <v-radio
                    v-for="(value, key) in appRoles"
                    :key="key"
                    :label="value"
                    :value="key"
                  ></v-radio>
                </v-radio-group>
                <RfSelect
                  v-model="appUsers[currApp.id].org_id"
                  dense
                  outlined
                  title="Organizations"
                  name="orgs"
                  :items="orgsSelection"
                ></RfSelect>
              </div>
              <v-checkbox
                v-model="isCorrect"
                label="Are you sure this information is correct?"
                :value="false"
                hide-details
              />
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn depressed class="cancel-btn px-7" outlined @click="showAddEditUserDialog = false">
            Cancel
          </v-btn>
          <v-btn depressed :disabled="!isCorrect" class="px-7" type="submit" color="success">
            <template v-if="user.id"> <v-icon left>update</v-icon>Update User </template>
            <template v-else> <v-icon left>add</v-icon>Add User </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="actionsUserDataDialog" max-width="1400">
      <v-card>
        <v-card-title class="headline"> User data for {{ getActionsUserId }} </v-card-title>
        <v-card-text class="promotion-priority-div">
          <div class="get-actions-user-data">
            <pre v-if="getActionsUserData">{{ getActionsUserData }}</pre>
          </div>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            depressed
            class="cancel-btn"
            outlined
            @click.stop="actionsUserDataDialog = false"
            style="width: 100px"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RfDomainChooserWrapper
      :app="currApp"
      :visible="livePreviewShow"
      v-on:open="openLivePreview"
      v-on:close="closeLivePreview"
    />
  </RfSimpleCard>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { mapActions, mapState } from "vuex";
import LiveMixin from "@/utils/LiveMixin";
import RfDomainChooserWrapper from "@/components/RfCommonCards/RfDomainChooserWrapper.vue";
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfFrequencyCap from "@/views/retentions/RfFrequencyCap.vue";
import RfImpressionLimits from "@/views/retentions/RfImpressionLimits.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import ApiApps from "@/apis/ApiApps";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfManageUsers",
  setup: () => ({ toastsStore: useToastsStore() }),
  props: ["currentCompany", "currApp"],
  components: {
    VueSlider,
    RfDomainChooserWrapper,
    RfFrequencyCap,
    RfImpressionLimits,
    RfSimpleCard,
    RfTextInput,
    RfSelect,
    RfButton,
  },
  mixins: [LiveMixin, RoleMixin],
  data() {
    return {
      user: {
        id: "",
        email: "",
        role: "member",
        departments: [],
        first_name: "",
        last_name: "",
      },
      org: {
        id: "",
        name: "",
        key: "",
      },
      appUsers: {},
      currentApps: null,
      newTestUserId: null,
      newTestUserDesc: null,
      showAddEditUserDialog: false,
      showDeleteUserDialog: false,
      userToDelete: null,
      showAddEditOrgDialog: false,
      showDeleteOrgDialog: false,
      orgToDelete: null,
      getActionsUserId: null,
      actionsUserDataDialog: false,
      globalHoldoutPercentages: new Array(21).fill().map((_, index) => index * 5),
      selectableDepartments: [
        { value: "sales", text: "Sales" },
        { value: "marketing", text: "Marketing" },
        { value: "support", text: "Support" },
      ],
      impersonateId: null,
      reassignedOrgId: null,
      userTab: "tab5",
      livePreviewShow: false,
      isCorrect: false,
    };
  },
  computed: {
    ...mapState({
      users: state => state.apps.users,
      currUser: state => state.apps.currUser,
      testUsers: state => state.apps.currentEndUserResults,
      orgs: state => state.apps.orgs,
      currUsers(state) {
        const res = state.apps.users || [];
        return this.currApp
          ? res.filter(
              user => user.role === "admin" || user.apps.find(app => app.id === this.currApp.id),
            )
          : res;
      },
      apps(state) {
        return this.currentApps || state.apps.apps;
      },
      getActionsUserData: state => state.apps.currEndUserActions,
    }),
    assignableOrgs() {
      return this.orgs
        .filter(item => item.id !== this.orgToDelete)
        .map(item => {
          return { value: item.id, text: item.name };
        });
    },
    isAdminingCompany() {
      return !this.currApp;
    },
    pathFilter() {
      return this.currApp?.path_filter || {};
    },
    orgsSelection() {
      return this.orgs.map(item => ({ value: item.id, text: item.name }));
    },
  },
  watch: {
    "currentCompany.id": {
      handler() {
        this.getUsers(this.currentCompany?.id);

        ApiApps.getApps(this.currentCompany?.id).then(apps => {
          this.currentApps = apps;
          Object.keys(this.appUsers).forEach(k => {
            this.$delete(this.appUsers, k);
          });
          (apps || []).forEach(app => {
            this.$set(this.appUsers, app.id, { role: "" });
          });
        });
      },
      immediate: true,
    },
    "currApp"(to) {
      if (to) {
        this.getOrgs(to.id);
      }
    },
    "userTab": {
      handler(tab) {
        this.$emit("tabChange", tab);
      },
      immediate: true,
    },
  },
  methods: {
    impersonate(user) {
      this.impersonateUser({ userId: user.id });
    },
    displayRole(user) {
      if (user.role === "admin") return "company_admin";
      if (this.isAdminingCompany || user.role === "super_admin") return user.role;
      return `app_${user.apps.find(app => app.id === this.currApp.id).role}`;
    },
    openAddOrgModal() {
      this.showAddEditOrgDialog = true;
      this.resetOrgForm();
    },
    resetOrgForm() {
      this.org.id = "";
      this.org.name = "";
      this.org.key = "";
    },
    openAddUserModal() {
      this.showAddEditUserDialog = true;
      this.resetUserForm();
    },
    resetUserForm() {
      this.user.id = "";
      this.user.first_name = "";
      this.user.last_name = "";
      this.user.email = "";
      this.user.role = "member";
      this.user.departments = [];
      if (this.orgs) {
        const org = this.orgs.find(({ key }) => key === "default");
        this.apps.forEach(app => {
          this.$set(this.appUsers, app.id, {
            role: "",
            org_id: this.currApp?.id === app.id && org?.id ? org.id : null,
          });
        });
      }
    },
    onEditOrg(org) {
      this.showAddEditOrgDialog = true;
      this.org.id = org.id;
      this.org.name = org.name;
      this.org.key = org.key;
    },
    onEditUser(user) {
      this.showAddEditUserDialog = true;
      this.user.id = user.id;
      this.user.email = user.email;
      this.user.first_name = user.first_name;
      this.user.last_name = user.last_name;
      this.user.role = user.role;
      this.user.departments = user.departments;
      if (user.apps.length)
        user.apps.forEach(
          item => (this.appUsers[item.id] = { role: item.role, org_id: item.org_id }),
        );
      else
        Object.keys(this.appUsers).forEach(k => {
          this.appUsers[k] = { role: "", org_id: null };
        });
    },
    displayAllowedAppsText(user) {
      if (user.role === "super_admin" || user.role === "admin") return "All";
      if (user.apps && user.apps.length)
        return user.apps.map(item => `${item.name} - ${item.role}`).join(", ");

      return "None";
    },
    async onDeleteOrg() {
      const id = this.orgToDelete;
      const params = {
        orgId: id,
        appId: this.currApp.id,
        newOrgId: this.reassignedOrgId,
      };
      const oldOrg = this.orgs.find(item => item.id === id);
      const newOrg = this.orgs.find(item => item.id === this.reassignedOrgId);
      await this.deleteOrg(params);
      this.toastsStore.create({
        type: "success",
        body: `${oldOrg.name} has been deleted and all items have been moved to ${newOrg.name}`,
      });
      this.closeDeleteOrgDialog();
    },
    closeDeleteOrgDialog() {
      this.orgToDelete = null;
      this.reassignedOrgId = null;
      this.showDeleteOrgDialog = false;
    },
    showConfirmDeleteOrgDialog(id) {
      this.orgToDelete = id;
      this.showDeleteOrgDialog = true;
    },
    async onSubmitOrg() {
      this.showAddEditOrgDialog = false;
      const { name } = this.org;
      if (name.length) {
        if (this.org.id) {
          await this.updateOrg({ appId: this.currApp.id, org: this.org });
          this.toastsStore.create({
            type: "success",
            body: `The ${name} organization has been updated`,
          });
          this.resetOrgForm();
        } else {
          await this.createOrg({ appId: this.currApp.id, name });
          this.toastsStore.create({
            type: "success",
            body: `The ${name} organization has been created`,
          });
          this.resetOrgForm();
        }
      }
    },
    onDeleteUser() {
      const id = this.userToDelete;
      const params = { userId: id, isAdminingCompany: this.isAdminingCompany };
      if (this.currApp) {
        params.appId = this.currApp.id;
      }
      this.deleteUser(params).then(() => {
        console.log("deleted user");
      });
      this.closeDeleteDialog();
    },
    onSubmitUser() {
      if (!this.isCorrect) return;
      const appUsers = [];
      this.showAddEditUserDialog = false;

      if (this.user.role === "member") {
        Object.keys(this.appUsers).forEach(k => {
          if (this.appUsers[k]?.role) {
            appUsers.push({
              app_id: k,
              role: this.appUsers[k].role,
              ...(this.appUsers[k]?.org_id && { org_id: this.appUsers[k].org_id }),
            });
          }
        });
      }

      this.user.apps = appUsers;
      const { first_name, last_name, email } = this.user;
      if (first_name.length && last_name.length && email.length) {
        if (this.user.id.length) {
          this.updateUser({
            user: this.user,
            companyId: this.currentCompany?.id,
            updatePermissions: true,
          }).then(() => {
            this.resetUserForm();
          });
        } else {
          this.inviteUser({
            user: this.user,
            companyId: this.currentCompany?.id,
          }).then(() => {
            this.resetUserForm();
          });
        }
      }
    },
    async onDeleteTestUser(user) {
      await this.deleteEndUser({
        appId: this.currApp.id,
        endUserId: user.id,
      });
      this.getTestUsers();
    },
    async onResetGoals(user) {
      await this.conduitResetGoal({
        appId: this.currApp.id,
        endUserId: user.user_uid,
      });
      this.toastsStore.create({
        type: "success",
        body: `Goal reset successfully for user ${user.user_uid}`,
      });
    },
    getTestUsers() {
      if (this.currApp) {
        this.searchEndUsers({
          query: "label=rf_test_user&value=true",
          appId: this.currApp.id,
        });
      }
    },

    async createTestUser() {
      const userIds = this.testUsers.map(user => user.user_uid);
      if (userIds.indexOf(this.newTestUserId) >= 0) {
        this.toastsStore.create({ type: "error", body: "Duplicate test user!" });
      } else {
        await this.createEndUser({
          appId: this.currApp.id,
          traits: {
            user_uid: this.newTestUserId,
            label: "rf_test_user",
            value: "true",
            metadata: {
              user_uid: this.newTestUserId,
              label: "description",
              value: this.newTestUserDesc,
            },
          },
        });
        this.getTestUsers();
        this.newTestUserId = null;
        this.newTestUserDesc = null;
      }
    },
    showConfirmDeleteDialog(id) {
      this.userToDelete = id;
      this.showDeleteUserDialog = true;
    },
    closeDeleteDialog() {
      this.userToDelete = null;
      this.showDeleteUserDialog = false;
    },
    async getActionsForEndUser() {
      await this.getEndUserActions({
        appId: this.currApp.id,
        userId: this.getActionsUserId,
      });
      this.actionsUserDataDialog = true;
    },
    clickLive(user) {
      console.log("click live");
      this.impersonateId = (user && user.user_uid) || this.getActionsUserId;
      if (this.currApp.domain_aliases && this.currApp.domain_aliases.length) {
        this.livePreviewShow = true;
      } else {
        this.openLivePreview(this.currApp.domain);
      }
    },
    openLivePreview(domain) {
      this.openLiveUrl(this.impersonateId, domain);
      this.closeLivePreview();
    },
    closeLivePreview() {
      this.livePreviewShow = false;
      this.impersonateId = null;
    },
    ...mapActions([
      "getUsers",
      "inviteUser",
      "deleteUser",
      "updateUser",
      "searchEndUsers",
      "deleteEndUser",
      "createEndUser",
      "conduitResetGoal",
      "getEndUserActions",
      "impersonateUser",
      "getOrgs",
      "getOrg",
      "createOrg",
      "updateOrg",
      "deleteOrg",
    ]),
  },
  mounted() {
    this.getTestUsers();
  },
};
</script>
<style>
.impersonate {
  margin-right: 10px;
}
.helper-text {
  padding: 0px 24px 10px;
}
.livepreview-domains .v-input--radio-group {
  margin-top: 0;
}
.add-testusers-id {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-right: 20px;
  margin-top: -2px;
}
.add-testusers-id > div {
  flex: 1;
}
</style>

<style lang="scss" scoped>
.helper-text {
  padding-left: 0;
}
</style>
