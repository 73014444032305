import Vue from "vue";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import VueGoogleCharts from "vue-google-charts";
import VeeValidate, { Validator } from "vee-validate";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/scss/redfast-stylesheet.scss";
import { createPinia, PiniaVuePlugin } from "pinia";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import isToday from "dayjs/plugin/isToday";
import dayjs from "dayjs";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isToday);

Validator.extend("domainList", {
  getMessage: () => "Your domain list has invalid characters.",
  validate: value => {
    if (value) {
      const values = value.split(",");
      const testedValues = values.filter(item =>
        /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.?[a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.?[a-zA-Z]*\:?[0-9]*$/.test(
          item.split(" ").join(""),
        ),
      );
      return values.length === testedValues.length;
    }
    return true;
  },
});

Vue.config.productionTip = false;
Vue.use(VueMoment, { moment });
Vue.use(VueGoogleCharts);
Vue.use(VeeValidate);
Vue.use(PiniaVuePlugin);
if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://ad6c538a212940d3803350d9e81e7ebf@sentry.io/1452606",
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

if (window.location.pathname === "/") {
  const authToken = new URLSearchParams(window.location.search).get("auth_token");
  if (authToken?.length) localStorage.setItem("id_token", authToken);
}

store.dispatch("getUser");

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  pinia: createPinia(),
}).$mount("#app");
