<template>
  <div class="rf-prompts rf-main-content-wrapper">
    <div v-if="showEmpty" class="absolute inset-0 left-16 z-0 flex h-full">
      <div class="align-center m-auto inline-flex flex-col gap-4 pt-10 text-center">
        <span class="text-heading-1 uppercase">No Prompts Found</span>
        <span class="text-heading-2 !font-normal">
          Looks like you haven't created a prompt yet.
        </span>
        <RfButtonNew
          text="+ New Prompt"
          data-cy="prompts-table--create-prompt"
          class="mt-2"
          :disabled="isDisabledRoleMixin"
          @click="showPromoDeviceTypeDialog"
        />
      </div>
    </div>
    <template v-else>
      <div class="flex min-h-12 items-center justify-between gap-16">
        <div class="flex min-w-px flex-1 items-center gap-4">
          <h1 class="flex-shrink-0 text-xl font-medium uppercase not-italic">All prompts</h1>
          <div
            class="flex flex-grow-0 items-center gap-4 overflow-y-hidden overflow-x-scroll pb-1 pt-2"
          >
            <template v-for="(filter, filterKey) in filters">
              <RfBaseTag
                v-for="(el, key) in filter"
                @clear="removeFilter(filterKey, key), refetch({ tags: true })"
              >
                {{ el }}
              </RfBaseTag>
            </template>
          </div>
          <button
            v-if="filtersLength"
            class="text-body flex-shrink-0"
            @click="(filters = {}), refetch({ tags: true })"
          >
            Clear all filters ({{ filtersLength }})
          </button>
        </div>
        <div class="flex flex-grow-0 items-center gap-4">
          <RfButtonInput
            v-model="searchInputs.inputs.value[PROMPTS_SEARCH]"
            text="Search"
            placeholder="Search"
            :icon="RfSearchIcon"
            @input="refetch({ tags: true })"
          />
          <RfButtonNew
            class="flex-shrink-0"
            data-cy="prompts-table--create-prompt"
            text="+ New Prompt"
            :disabled="isDisabledRoleMixin"
            @click="showPromoDeviceTypeDialog"
          />
        </div>
      </div>
      <Transition mode="out-in">
        <RfAccordionCard
          v-if="isTagsSelected"
          title="Performance for selected tags"
          class="mt-3 py-3"
          @hook:mounted="getTagsMetricsData"
        >
          <RfChartsExtended :data="tagData" :skeleton="tagLoading">
            <span class="text-body my-auto inline-flex pl-7">
              *Push, Email, and Invisible metrics are excluded
            </span>
          </RfChartsExtended>
        </RfAccordionCard>
      </Transition>
      <RfTable
        :tableData="promptsStore.table"
        :loading="promptsStore.loading"
        :rowSkeleton="RfTableRowSkeleton"
        :rowSkeletonProps="{ performanceCols }"
        type="prompts"
        @loadMore="refetch({ next: true })"
      >
        <template #pretable>
          <span class="mb-0 mt-4 inline-flex items-stretch gap-4">
            <RfTableSelect
              :selected="selected"
              :tableData="promptsStore.table"
              :timezone="timezone"
              @status="status => (statusUpdate = { status, prompt: selectedValue })"
              @delete="deleteItem = valuesSelected"
              @edit="editItem = selectedValue"
              @clone="cloneItem = selectedValue"
              @export="exportItem = valuesSelected"
              @selectAll="promptsStore.prompts.forEach(v => updateMap('set', v))"
              @deselectAll="promptsStore.prompts.forEach(v => updateMap('delete', v))"
              @sewlectByPreset="selectByPreset"
            />
          </span>
          <span class="my-4 inline-flex items-center gap-4">
            <RfSwitch
              v-if="isGuidesActive"
              v-model="showGuidePrompts"
              title="Show guide prompts"
              @input="v => (setUserSetting('promptsInheritanceFilter', v), refetch({ tags: true }))"
            />
            <RfSwitch
              v-model="performanceCols"
              title="Show performance"
              @input="v => setUserSetting('promptsTableShowPerformance', v)"
            />
            <RfDatePicker
              ref="datePicker"
              :min="
                currApp?.flags?.one_year_metrics
                  ? dayjs().subtract(1, 'year')
                  : dayjs().subtract(90, 'days')
              "
              :max="dayjs()"
              @input="v => ((date = v), refetch({ tags: true }))"
            />
          </span>
        </template>
        <template #thead>
          <RfTableHeader
            :appId="currApp.id"
            :segments="segments"
            :tags="tagsStore.tags"
            :performanceCols="performanceCols"
            :filters.sync="filters"
            :getAppDevices="getAppDevices"
            :sort="sort"
            @update:sort="v => ((sort = v), refetch())"
            @update:filters="refetch({ tags: true })"
          />
        </template>
        <template #tbody>
          <RfTableRow
            v-for="prompt in promptsStore.prompts"
            :key="prompt.id"
            :prompt="prompt"
            :disabled="isDisabledRoleMixin"
            :timePeriod="date.preset?.value"
            :timezone="timezone"
            :performanceCols="performanceCols"
            :customDevicesEnabled="currApp.flags?.custom_devices"
            :selected="selected.has(prompt.id)"
            :isTagsSelected="isTagsSelected"
            @select="updateMap('set', prompt)"
            @deselect="updateMap('delete', prompt)"
            @edit="editItem = prompt"
            @delete="deleteItem = [prompt]"
            @status="status => (statusUpdate = { status, prompt })"
            @clone="cloneItem = prompt"
          />
        </template>
      </RfTable>
    </template>
    <RfPromotionCreator
      :pathGroups="pathGroups"
      :promoDeviceTypeDialog="promoDeviceTypeDialog"
      v-on:closePromoDeviceTypeDialog="closePromoDeviceTypeDialog"
    />
    <RfPromptModals
      ref="modals"
      :editItem.sync="editItem"
      :deleteItem.sync="deleteItem"
      :cloneItem.sync="cloneItem"
      :exportItem.sync="exportItem"
      :statusUpdate.sync="statusUpdate"
      :timezone="timezone"
      :currApp="currApp"
      :currUser="currUser"
      :isSuperAdmin="isSuperAdminRoleMixin"
      :isCompanyAdmin="isCompanyAdminMixin"
      :onSubmitRename="v => updatePrompt([{ ...v, id: editItem.id }])"
      @delete="submitDelete"
      @export="submitExport"
      @clone="submitClone"
      @status="submitStatusUpdate"
    />
  </div>
</template>

<script>
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import RfButtonInput from "@/components/inputs/RfButtonInput.vue";
import RfDatePicker from "@/components/inputs/RfDatePicker.vue";
import { mapActions, mapState } from "vuex";
import RoleMixin from "@/utils/RoleMixin";
import RfPromotionCreator from "@/components/RfPromotions/RfPromotionCreator.vue";
import RfSearchIcon from "@/components/icons/RfSearchIcon.vue";
import RfTable from "@/blocks/RfTable/RfTable.vue";
import RfTableHeader from "@/blocks/RfPrompts/RfTableHeader.vue";
import RfTableRow from "@/blocks/RfPrompts/RfTableRow.vue";
import RfTableRowSkeleton from "@/blocks/RfPrompts/RfTableRowSkeleton.vue";
import RfTableSelect from "@/blocks/RfPrompts/RfTableSelect.vue";
import { getAllDevices } from "@/utils/getDeviceName";
import { usePromptsStore } from "@/pinia/promptsStore";
import { debounce } from "@/utils/debounce";
import RfBaseTag from "@/components/tags/RfBaseTag.vue";
import RfSwitch from "@/components/inputs/RfSwitch.vue";
import RfRenameModal from "@/components/modals/RfRenameModal.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import getPromptStatus from "@/utils/prompts/getPromptStatus";
import dayjs from "dayjs";
import { isPlacement } from "@/utils/prompts/promptHelpers";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import MetricsUtils from "@/utils/MetricsUtils";
import StatusesConstants, {
  SimplifyStatusesMapConstants,
} from "@/utils/constants/PromptStatusesConstants";
import { getAllPrompts } from "@/utils/getDeviceName";
import { useTableFilter } from "@/utils/composables/useTableFilter";
import { useTableSelect } from "@/utils/composables/useTableSelect";
import { useTableSort } from "@/utils/composables/useTableSort";
import { useToastsStore } from "@/pinia/toastsStore";
import { ref, watch } from "vue";
import RfPromptModals from "@/blocks/RfModals/RfPromptModals.vue";
import { exportMultiplePromptToCsv } from "@/utils/CsvUtils";
import { getPromptLink } from "@/utils/getLink";
import { PROMPT_INHERITANCE_CONSTANTS } from "@/utils/constants/PromptInheritanceConstants";
import { useTagsStore } from "@/pinia/tagsStore";
import RfAccordionCard from "@/components/cards/RfAccordionCard.vue";
import RfChartsExtended from "@/blocks/RfCharts/RfChartsExtended.vue";
import ApiMetrics from "@/apis/ApiMetrics";
import { PROMPTS_SEARCH, useSearctInputs } from "@/utils/composables/useSearctInputs";
import { chartColors } from "@/utils/constants/ColorsConstants";
import { percentage } from "@/utils/metricsHelpers";

const constructPercentage = (numerator = [], denominator = []) => {
  const res = [];
  for (let i = 0; i < numerator?.length; i++) {
    res.push({
      key: numerator[i][0],
      value: { label: percentage(numerator[i][1], denominator[i][1]), raw: 0 },
    });
  }
  return res;
};

const debounceTime = 1000;
export default {
  name: "RfPrompts",
  setup() {
    const tagData = ref([]);
    const tagLoading = ref(true);
    const promptsStore = usePromptsStore();
    const { filters, removeFilter, filtersLength, filtersConverted } = useTableFilter();
    const { selected, updateMap, resetSelected, selectedValue, valuesSelected } = useTableSelect();
    const sort = useTableSort(["start_date", "end_date", "default"]);
    const searchInputs = useSearctInputs();
    const showEmpty = ref(false);
    const tagsStore = useTagsStore();

    watch(
      [
        filtersLength,
        () => promptsStore.prompts.length,
        () => promptsStore.loading,
        () => searchInputs.inputs.value[PROMPTS_SEARCH],
      ],
      debounce(([filtersLength, guidesLength, guidesLoading, searchInput]) => {
        showEmpty.value = !filtersLength && !guidesLength && !guidesLoading && !searchInput;
      }, debounceTime + 100),
    );

    return {
      tagData,
      tagLoading,
      RfSearchIcon,
      promptsStore,
      toastsStore: useToastsStore(),
      isPlacement,
      filters,
      removeFilter,
      filtersConverted,
      filtersLength,
      selected,
      updateMap,
      resetSelected,
      selectedValue,
      valuesSelected,
      sort,
      searchInputs,
      PROMPTS_SEARCH,
      showEmpty,
      RfTableRowSkeleton,
      dayjs,
      exportMultiplePromptToCsv,
      PROMPT_INHERITANCE_CONSTANTS,
      tagsStore,
    };
  },
  components: {
    RfButtonNew,
    RfButtonInput,
    RfPromotionCreator,
    RfTable,
    RfTableRow,
    RfBaseTag,
    RfDatePicker,
    RfRenameModal,
    RfBaseModal,
    RfSwitch,
    RfTableSelect,
    RfTableHeader,
    RfPromptModals,
    RfAccordionCard,
    RfChartsExtended,
  },
  mixins: [RoleMixin, UserSettingsMixin],
  data() {
    return {
      promoDeviceTypeDialog: false,
      date: {},
      editItem: null,
      deleteItem: null,
      cloneItem: null,
      exportItem: null,
      statusUpdate: { status: null, prompt: null },
      performanceCols: true,
      showGuidePrompts: false,
      getTagsMetricsData: async () => {
        if (!this.date.preset) return;
        this.tagLoading = true;
        this.tagData = [];
        const tag_ids = this.filtersConverted.tags?.array;
        const promptIds = this.promptsStore.prompts.map(el => el.id);

        const data = await ApiMetrics.getTagsMetrics(this.currApp.id, {
          path_ids: promptIds,
          metric_periods: [
            {
              period: this.date.preset.value,
              ...(this.date.preset.value === "custom" && {
                date_range: [
                  this.date.startDate.format("YYYY-MM-DD"),
                  this.date.endDate.format("YYYY-MM-DD"),
                ],
              }),
            },
          ],
          ...(tag_ids?.length && { tag_ids }),
        });

        const dataObj = data?.["raw-mixed-tags"]?.[this.date.preset.value]?.agg_data;
        if (!dataObj) return;

        const colors = [...chartColors];
        const chartData = [];

        const goalData = {};
        const uimpressionData = {};
        const impressionData = {};
        const customGoalData = {};

        for (const property in dataObj) {
          uimpressionData[property] = dataObj[property]?.uimpression || 0;
          impressionData[property] = dataObj[property]?.impression || 0;
          goalData[property] = dataObj[property]?.goal || 0;
          customGoalData[property] = dataObj[property]?.custom_goals_seen || 0;
        }

        const totalGoals = Object.values(goalData).reduce((a, b) => a + b, 0);
        const totalImpressions = Object.values(impressionData).reduce((a, b) => a + b, 0);

        chartData.push({
          label: "Users",
          data: uimpressionData,
          backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
          order: 1,
          totals: {
            label: "Total users",
            value: Object.values(uimpressionData).reduce((a, b) => a + b, 0),
          },
        });
        chartData.push({
          label: "Impressions",
          data: impressionData,
          backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
          order: 1,
          totals: { label: "Total impressions", value: totalImpressions },
        });

        const color = colors.splice(~~(Math.random() * colors.length - 1), 1)[0];
        const totalCTR = percentage(totalGoals, totalImpressions, "new");
        chartData.push({
          label: "CTR",
          data: constructPercentage(Object.entries(goalData), Object.entries(impressionData)),
          backgroundColor: color,
          borderColor: color,
          type: "line",
          order: 0,
          parsing: { xAxisKey: "key", yAxisKey: "value.raw" },
          props: { type: "percentage" },
          totals: { text: totalCTR.label, value: totalCTR.value },
        });

        chartData.push({
          label: "Clicks",
          data: goalData,
          backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
          order: 1,
          totals: { label: "Total clicks", value: totalGoals },
        });

        chartData.push({
          label: "Custom goal",
          data: customGoalData,
          backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
          order: 1,
          totals: { value: Object.values(customGoalData).reduce((a, b) => a + b, 0) },
        });
        this.tagData = chartData;
        this.tagLoading = false;
      },
      getPrompts: async ({ next = false, tags = false } = { next: false, tags: false }) => {
        if (tags) {
          this.tagLoading = true;
          this.tagData = [];
        }
        const statuses = this.filtersConverted.statuses?.string;
        const device_types = this.filtersConverted.devices?.string;
        const path_types = this.filtersConverted.prompts?.string;
        const segments_ids = this.filtersConverted.segments?.string;
        const tag_ids = this.filtersConverted.tags?.string;
        this.searchInputs.write();

        await this.promptsStore.getPromptsPaged(
          this.currApp.id,
          {
            metric_periods: [
              {
                period: this.date.preset.value,
                ...(this.date.preset.value === "custom" && {
                  date_range: [
                    this.date.startDate.format("YYYY-MM-DD"),
                    this.date.endDate.format("YYYY-MM-DD"),
                  ],
                }),
              },
            ],
            include_sequence_items: this.showGuidePrompts,
            ...(this.searchInputs.inputs.value[PROMPTS_SEARCH] && {
              name_search: this.searchInputs.inputs.value[PROMPTS_SEARCH],
            }),
            ...(statuses && { statuses }),
            ...(device_types && { device_types }),
            ...(path_types && { path_types }),
            ...(segments_ids && { segments_ids }),
            ...(tag_ids && { tag_ids }),
            ...(this.sort.string && { order: this.sort.string }),
          },
          next,
        );

        if (tags) this.getTagsMetricsData();

        await this.setUserSettings({
          promptsSort: this.sort.string,
          promptsStatusFilter: this.filtersConverted.statuses?.array,
          promptsSegmentFilter: this.filtersConverted.segments?.array,
          promptsTagsFilter: this.filtersConverted.tags?.array,
          promptsDeviceFilter: this.filtersConverted.devices?.array,
          promptsPathTypeFilter: this.filtersConverted.prompts?.array,
          promptsInheritanceFilter: this.filtersConverted.inheritance?.array,
          guidesType: this.filtersConverted.sequence_type?.array,
          ...(this.date.preset.value !== "custom"
            ? { promptsDateRange: this.date.preset.value }
            : {}),
        });
      },
      refetch: debounce(async ({ next = false, tags = false } = { next: false, tags: false }) => {
        if (!next) {
          this.resetSelected();
          this.promptsStore.resetPage();
        }
        await this.getPrompts({ tags, next });
      }, debounceTime),
    };
  },
  computed: {
    ...mapState({
      pathGroups: state => state.apps.pathGroups,
      currApp: state => state.apps.currApp,
      segments: state => state.apps.segments.filter(s => !s.pipeline_stage_id),
      currUser: state => state.apps.currUser,
    }),
    timezone() {
      return this.currApp.timezone_offset;
    },
    getAppDevices() {
      return getAllDevices(this.currApp.custom_devices, this.currApp.flags.custom_devices);
    },
    isGuidesActive() {
      return this.currApp?.feature_set?.includes("sequences");
    },
    isTagsSelected() {
      return !!Object.keys(this.filters?.tags || {})?.length;
    },
  },
  methods: {
    ...mapActions([
      "getPathGroups",
      "getSegments",
      "deletePath",
      "deletePathGroupAndPath",
      "clonePath",
      "createJob",
    ]),
    closePromoDeviceTypeDialog() {
      this.promoDeviceTypeDialog = false;
    },
    showPromoDeviceTypeDialog() {
      this.getPathGroups({ appId: this.currApp.id });
      this.promoDeviceTypeDialog = true;
    },
    submitStatusUpdate(prompts) {
      this.editPrompt(prompts)
        .then(() => {
          this.$refs.modals.closeStatusModal();
          this.toastsStore.create({ type: "success", body: "Status updated" });
        })
        .catch(_ => null);
    },
    updatePrompt(paths) {
      this.editPrompt(paths)
        .then(() => this.toastsStore.create({ type: "success", body: "Prompt updated" }))
        .catch(() => null);
    },
    editPrompt(paths) {
      this.resetSelected();
      return this.promptsStore.bulkUpdate(this.currApp.id, paths);
    },
    async submitDelete() {
      try {
        const body = `Prompt${this.deleteItem.length > 1 ? "s" : ""} deleted`;
        if (this.deleteItem.length > 1) {
          await this.promptsStore.bulkDelete(this.currApp.id, this.deleteItem);
        } else {
          if (isPlacement(this.deleteItem[0].path_type)) {
            await this.deletePath({
              appId: this.currApp.id,
              pathId: this.deleteItem[0].id,
              path: this.deleteItem[0],
            });
          } else {
            await this.deletePathGroupAndPath({
              appId: this.currApp.id,
              path: this.deleteItem[0],
            });
          }
        }
        this.$refs.modals.closeDeleteModal();
        this.resetSelected();

        const oldPage = this.promptsStore.table.page;
        const oldPerPage = this.promptsStore.table.perPage;
        this.promptsStore.table.page = 0;
        this.promptsStore.table.perPage = oldPage * oldPerPage;
        await this.refetch({ tags: true });
        this.toastsStore.create({ type: "success", body });
        this.promptsStore.table.page = oldPage;
        this.promptsStore.table.perPage = oldPerPage;
      } catch (e) {}
    },
    submitExport(type) {
      if (type === "summary") {
        this.exportMultiplePromptToCsv([...this.exportItem.values()], this.date.preset?.value);
      } else {
        const metricsPeriod = { period: this.date.preset?.value };

        if (this.date.preset.value === "custom") {
          metricsPeriod.date_range = [
            this.date.startDate.format("YYYY-MM-DD"),
            this.date.endDate.format("YYYY-MM-DD"),
          ];
        }

        this.createJob({
          appId: this.currApp.id,
          metrics_period: metricsPeriod,
          path_ids: this.exportItem.map(i => i.id),
        });
      }
      this.$refs.modals.closeExportModal();
    },
    submitClone(zone, app, defaultCompany) {
      const cloneItemName = this.cloneItem.name;
      this.clonePath({
        appId: this.currApp.id,
        pathId: this.cloneItem.id,
        pathGroupId: zone,
        targetAppId: app.value,
        defaultCompany,
      })
        .then(path => {
          if (this.currApp.id === app.value)
            path?.id && this.$router.push(getPromptLink(path.id, this.currApp.id));
          else
            this.toastsStore.create({
              type: "success",
              body: `Cloned ${cloneItemName} to ${app.text}`,
            });
        })
        .catch(error => {
          this.toastsStore.create({
            type: "error",
            body: error.message,
          });
        });
      this.$refs.modals.closeCloneModal();
      this.resetSelected();
    },
    selectByPreset(preset) {
      this.resetSelected();
      this.promptsStore.prompts.forEach(prompt => {
        SimplifyStatusesMapConstants[getPromptStatus(prompt, this.timezone).type].type === preset &&
          this.updateMap("set", prompt);
      });
    },
  },
  async mounted() {
    this.sort = this.getUserSetting("promptsSort");
    this.performanceCols = this.getUserSetting("promptsTableShowPerformance") ?? true;
    this.showGuidePrompts = this.getUserSetting("promptsInheritanceFilter") ?? false;

    await Promise.all([
      ...(this.segments.length ? [] : [this.getSegments({ appId: this.currApp.id })]),
      ...(this.tagsStore.tags?.length ? [] : [this.tagsStore.getTags(this.currApp.id)]),
    ]);

    this.filters = {
      statuses: Object.fromEntries(
        (this.getUserSetting("promptsStatusFilter") || []).map(el => [
          StatusesConstants[el].type,
          StatusesConstants[el].title,
        ]),
      ),
      devices: Object.fromEntries(
        (this.getUserSetting("promptsDeviceFilter") || []).map(el => [
          el,
          this.getAppDevices.find(({ value }) => value === el).text,
        ]),
      ),
      prompts: Object.fromEntries(
        (this.getUserSetting("promptsPathTypeFilter") || []).map(el => [
          el,
          getAllPrompts().find(({ key }) => key === el).value,
        ]),
      ),
      segments: Object.fromEntries(
        (this.getUserSetting("promptsSegmentFilter") || []).map(el => [
          el,
          this.segments.find(({ key }) => key === el).name,
        ]),
      ),
      tags: Object.fromEntries(
        (this.getUserSetting("promptsTagsFilter") || []).map(el => {
          return [el, this.tagsStore.tags.find(({ id }) => id === el).title];
        }),
      ),
    };
    this.$nextTick(() => {
      const preset = MetricsUtils.All_Periods.find(
        ({ value }) =>
          value !== "since_start_date" && value === this.getUserSetting("promptsDateRange"),
      )?.value;

      this.$refs.datePicker?.setTimeFromPreset(preset || "last_seven_days");
    });
  },
};
</script>
