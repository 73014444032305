import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { exceptionWrapper, useTableData } from "./piniaUtils";
import ApiTags from "@/apis/ApiTags";

export const useTagsStore = defineStore("tags", () => {
  const tags = ref();
  const { table, resetPage } = useTableData();
  const loading = ref(true);
  const error = ref(false);

  const renderLength = computed(() => {
    const dataLength = tags.value?.length || 0;
    return Math.min(dataLength, (table.page || 1) * table.perPage);
  });

  const getTags = appId =>
    exceptionWrapper(
      async () => {
        resetPage();
        table.page = 1;
        table.perPage = 50;
        table.totalCount = 50;
        tags.value = await ApiTags.getTags(appId);
        loadTable();
      },
      { loading, error, toast: true },
    );

  const loadTable = (next = false) => {
    if (next) return (table.page = table.page + 1);
    table.totalCount = tags.value.length;
    table.totalPages = ~~(table.totalCount / table.perPage);
  };

  const createTag = (appId, tag) =>
    exceptionWrapper(
      async () => {
        const tagRes = await ApiTags.createTag(appId, tag);
        tags.value = [tagRes, ...tags.value];
        loadTable();
        return tagRes;
      },
      { loading, error, toast: true },
    );

  const deleteTag = (appId, tagId) =>
    exceptionWrapper(
      async () => {
        ApiTags.deleteTag(appId, tagId);
        tags.value = tags.value.filter(el => el.id !== tagId);
        loadTable();
        if (table.page > table.totalPages) table.page = table.totalPages;
      },
      { loading, error, toast: true },
    );

  return { table, getTags, createTag, deleteTag, tags, loading, error, loadTable, renderLength };
});
