<template>
  <RfRenameModal
    ref="renameModal"
    title="Create Guide"
    max-width="640"
    :name="guide.name"
    :description="guide.description"
    :onSubmit="async v => await submitCallback({ ...v, fillModel: segments?.fillModel })"
    @close="emit('reset')"
  >
    <template #append="{ opened }">
      <template v-if="!guide.id">
        <div class="my-6 flex flex-col gap-2 border-b border-dashed border-[#ccc] pb-6">
          <div class="flex items-center gap-2">
            <div class="text-base font-medium">Type:</div>
            <v-radio-group v-model="guide.sequence_type" class="mt-0 pt-0" hide-details row dense>
              <v-radio
                v-for="guideModel in GUIDE_TYPES"
                :key="guideModel.value"
                v-bind="guideModel"
                :data-cy="`new-guide--type-${guideModel.value}`"
              />
            </v-radio-group>
          </div>
          <div class="">{{ GUIDE_TYPES[guide.sequence_type].description }}</div>
        </div>
        <RfLinkedSegments v-if="opened" :model="guide" ref="segments" />
      </template>
    </template>
  </RfRenameModal>
</template>

<script setup>
import RfLinkedSegments from "@/components/RfPathCreate/RfLinkedSegments.vue";
import RfRenameModal from "@/components/modals/RfRenameModal.vue";
import { GUIDE_TYPES } from "@/utils/constants/GuidesConstants";
import { ref } from "vue";

const segments = ref();
const renameModal = ref();

defineProps({
  guide: { type: Object, default: () => ({}) },
  submitCallback: { type: Function, default: () => () => null },
});
const emit = defineEmits(["reset"]);

const show = () => renameModal.value?.show?.();
const close = () => renameModal.value?.show?.();
defineExpose({ show, close });
</script>
