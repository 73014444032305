<template>
  <div class="segment-custom-opt-wrap">
    <v-select
      outlined
      dense
      hide-details
      :items="parentItems"
      item-text="name"
      :disabled="disabled"
      item-value="label"
      v-model="parentModel"
      @change="updateParent"
      placeholder="Select configuration"
      class="segment-builder--subtypes _subtypes"
      background-color="#ffffff"
      style="min-width: 180px"
      :menu-props="{ maxHeight: 410, offsetY: true, minWidth: 240 }"
    />
    <div class="segment-builder--item next-selection" v-if="parentModel">
      <v-icon>chevron_right</v-icon>
      <RfCustomControls
        :model="childModel"
        :values="childItems"
        :disabled="disabled"
        v-on:update="updateFilter"
        :key="upkey"
        :currentOptions="childOptions"
        :horizontal="true"
      />
    </div>
  </div>
</template>

<script>
import RfCustomControls from "@/components/RfCustomControls.vue";
import { getCustomFiltersData } from "@/utils/segmentsHelpers";
import { mapState } from "vuex";
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";

export default {
  name: "RfCustom",
  props: { filter: Object, disabled: [Boolean, String], isPinpoint: Boolean },
  components: { RfCustomControls },
  mixins: [CustomFieldsMixin],
  data: () => ({ parentModel: null, upkey: 0 }),
  computed: {
    ...mapState({ app: state => state.apps.currApp }),
    parentItems() {
      const customFields = getCustomFiltersData(this.appCustomFields);
      return this.isPinpoint
        ? customFields.filter(({ data_type }) => ["boolean", "string"].includes(data_type))
        : customFields;
    },
    childModel() {
      return this.parentItems.find(parent => parent.label === this.parentModel);
    },
    childItems() {
      return this.childFilter.values;
    },
    childOptions() {
      return this.childFilter.options;
    },
    childFilter() {
      return this.filter.custom[this.childModel.label] || {};
    },
    customKey() {
      return Object.keys(this.filter.custom)[0];
    },
  },
  methods: {
    updateParent() {
      this.incrKey();

      this.updateFilter([], {});
    },
    updateFilter(values, options) {
      const filter = { custom: { [this.childModel.label]: { values, options } } };

      this.$emit("update:model", filter);
    },
    prepareFilter() {
      this.parentModel = this.customKey;
    },
    incrKey() {
      this.upkey += Math.random();
    },
  },
  mounted() {
    this.prepareFilter();
  },
};
</script>
