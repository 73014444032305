<template>
  <div>
    <div class="crPr-row-2">
      <h3>Device type:</h3>
      <div class="crPr-device-selection">
        <div
          v-for="item in deviceTypeOptions"
          @click="setDeviceType(item.value)"
          :data-cy="`device-type-${item.value}`"
          :key="item.name"
        >
          <div :class="currDeviceType === item.value ? 'cp_d_inner selected' : 'cp_d_inner'">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="crPr-row-2"
      style="padding-top: 20px"
      v-if="customDevicesFlag && currDeviceType === 'custom_defined'"
    >
      <h3>Custom Devices:</h3>
      <template v-if="devicesAreLoaded">
        <v-chip-group
          class="crPr-device-selection"
          v-model="deviceIdxs"
          column
          mandatory
          multiple
          v-if="customDevicesOptions.length"
        >
          <v-chip
            v-for="item in customDevicesOptions"
            class="cp_d_inner"
            active-class="selected"
            :data-cy="`custom-device-${item.label}`"
            :key="item.value"
            filter
          >
            {{ item.text }}
          </v-chip>
        </v-chip-group>
        <span class="error--text" v-else>
          No custom devices found. In order to continue you need to
          <router-link :to="`/apps/${$route.params.aid}/settings/custom_devices`">
            create one.
          </router-link>
        </span>
      </template>
      <span v-else>Loading...</span>
    </div>
  </div>
</template>

<script>
import DeviceTypeMixin from "@/utils/DeviceTypeMixin";
import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash-es";

export default {
  name: "RfDeviceTypeSelection",
  mixins: [DeviceTypeMixin],
  data() {
    return {
      currDeviceType: "web",
      deviceIdxs: [],
      devicesAreLoaded: false,
    };
  },
  methods: {
    ...mapActions(["getCustomDevices"]),
    setDeviceType(type) {
      this.currDeviceType = type;
      this.$emit("setDeviceType", type, this.deviceTypeData);
    },
  },
  computed: {
    ...mapState({
      customDevicesFlag: state => state.apps.currApp.flags.custom_devices,
      customDevices: state => cloneDeep(state.apps.customDevices),
    }),
    customDevicesOptions() {
      return this.customDevices.map(el => ({ text: el.name, value: el.id, label: el.label }));
    },
    deviceTypeOptions() {
      if (this.webOnly) return this.deviceTypes.filter(el => el.value === "web");
      if (this.customDevicesFlag) return this.deviceTypes;
      return this.deviceTypes.filter(el => el.value !== "custom_defined");
    },
    deviceTypeData() {
      return this.deviceTypes.find(el => el.value === this.currDeviceType);
    },
    customDeviceIds() {
      if (this.currDeviceType !== "custom_defined") return [];
      return this.deviceIdxs.map(idx => this.customDevicesOptions[idx].value);
    },
  },
  watch: {
    customDeviceIds(to) {
      this.$emit("setCustomDeviceIds", to);
    },
  },
  mounted() {
    this.devicesAreLoaded = false;
    this.getCustomDevices(this.$route.params.aid).finally(() => {
      this.devicesAreLoaded = true;
    });
  },
};
</script>
