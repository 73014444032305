<template>
  <div style="padding: 1rem 2rem 2rem">
    <div class="add-user-header-div">
      <div class="add-user-title">
        <div class="action-provider-settings-title">
          <h4 style="width: 100%">Custom Metric</h4>
        </div>
      </div>
      <div style="width: 200px; text-align: right">
        <v-btn depressed color="success" @click="editMetric(-1)">
          <v-icon left size="18">add</v-icon>Add New Metric
        </v-btn>
      </div>
    </div>
    <v-simple-table class="rf-settings-table-data">
      <thead>
        <tr>
          <th>Metric Name</th>
          <th>Model Type (Segment/Path)</th>
          <th />
        </tr>
      </thead>
      <tbody v-if="customMetrics?.length">
        <tr v-for="(customMetric, index) in customMetrics" :key="index">
          <td>{{ customMetric.metric_key }}</td>
          <td>{{ customMetric.model_type }}</td>
          <td align="right">
            <v-icon size="18" left @click="editMetric(index)">edit</v-icon>
            <v-icon size="18" @click="deleteMetric(customMetric)">clear</v-icon>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td align="center" colspan="3">
            <div class="settings-no-data-message">Nothing to show</div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-model="addDialog" persistent width="700">
      <v-card class="rf-custom-web-card-wrapper">
        <v-card-title>Add New Metric</v-card-title>
        <v-card-text style="margin-top: 1rem">
          <v-text-field
            v-model="editing.metric_key"
            label="Metric Key"
            placeholder="e.g. failed_goals, timeout_users (impressions, users, uimpressions, goals not allowed)"
            outlined
            dense
          ></v-text-field>
          <v-select
            v-model="editing.model_type"
            label="Model Type"
            placeholder="None"
            :items="['segment', 'path', 'experiment', 'action_group', 'app']"
            chips
            outlined
            dense
            background-color="#ffffff"
            persistent-hint
          ></v-select>
          <p>Add json from Kibana here.</p>
          <PrismEditor
            v-model="editing.query"
            language="json"
            :emitEvents="false"
            v-if="addDialog"
          ></PrismEditor>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            style="width: 160px"
            depressed
            color="accent"
            @click="submitAction"
            :disabled="!submitEnabled"
            >{{ submitCTA }}</v-btn
          >
          <v-btn style="width: 120px" depressed class="cancel-btn" outlined @click="closeDialog"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PrismEditor from "vue-prism-editor";
import "prismjs";
import "prismjs/themes/prism.css";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  components: { PrismEditor },
  data: () => ({ addDialog: false, editing: {}, editingIndex: -1 }),
  setup: () => ({ toastsStore: useToastsStore() }),
  methods: {
    ...mapActions([
      "getCustomMetrics",
      "createCustomMetric",
      "updateCustomMetric",
      "deleteCustomMetric",
    ]),
    editMetric(index) {
      if (index >= 0) {
        this.editing = cloneDeep(this.customMetrics[index]);
      } else {
        this.editing = this.default();
      }
      this.editingIndex = index;
      this.addDialog = true;
    },
    async deleteMetric(customMetric) {
      await this.deleteCustomMetric(customMetric);
      this.toastsStore.create({ type: "success", body: "Custom metric deleted" });
    },
    closeDialog() {
      this.editingIndex = -1;
      this.addDialog = false;
      this.editing = this.default();
    },
    submitAction() {
      if (this.editingIndex >= 0) {
        this.updateCustomMetric(this.editing);
        this.toastsStore.create({ type: "success", body: "Custom metric updated" });
      } else {
        this.createCustomMetric(this.editing);
        this.toastsStore.create({ type: "success", body: "Custom metric created" });
      }
      this.closeDialog();
    },
    default() {
      return cloneDeep({ model_type: null, metric_key: null, query: null });
    },
  },
  computed: {
    ...mapState({
      customMetrics: state => state.apps.customMetrics,
    }),
    submitCTA() {
      return this.editingIndex >= 0 ? "Update" : "Create";
    },
    submitEnabled() {
      return !!(this.editing.model_type && this.editing.metric_key && this.editing.query);
    },
  },
  mounted() {
    this.getCustomMetrics();
  },
};
</script>
