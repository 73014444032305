export const baseUrlReplacer = (app, url) => {
  if (!app.flags.custom_domain) return url;
  const urlObj = new URL(url);
  const baseUrl = `${urlObj.protocol}//${urlObj.hostname}`;
  if (
    baseUrl !== `https://${app.id}.redfastlabs.com` &&
    baseUrl !== "https://assets.redfastlabs.com"
  ) {
    return url.replace(baseUrl, `https://${app.id}.redfastlabs.com`);
  }

  return url;
};
