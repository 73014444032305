import { PIE_CHART_COLOR_LIST } from "./constants/ChartColorsConstants";

export const createPieChart = (el, g) => el && new g.visualization.PieChart(el);
export const createComboChart = (el, g) => el && new g.visualization.ComboChart(el);
export const createColumnChart = (el, g) => el && new g.visualization.ColumnChart(el);

const defaultPieChartOptions = {
  width: "100%",
  height: 400,
  pieSliceTextStyle: { fontSize: 14 },
  colors: PIE_CHART_COLOR_LIST,
  enableInteractivity: true,
  fontSize: 14,
  bold: true,
  animation: { duration: 500, easing: "out" },
  legend: "none",
  chartArea: { left: 0, right: 0, top: "10%", bottom: "10%" },
};

export const getDefaultPieChartOptions = ({
  customTooltip = false,
  pieHole = 0,
  width = undefined,
  pieSliceText = "label",
} = {}) => ({
  ...defaultPieChartOptions,
  ...(customTooltip && { tooltip: { isHtml: true } }),
  ...(width && { width }),
  pieHole,
  pieSliceText,
});
