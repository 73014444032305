import UserSettingsMixin from "@/utils/UserSettingsMixin";
import { mapMutations, mapState } from "vuex";

export default {
  mixins: [UserSettingsMixin],
  computed: {
    ...mapState({
      subnavOpenStatus: state => state.apps.subnavOpenStatus,
    }),
  },
  methods: {
    ...mapMutations(["mutateSubnavOpenStatus"]),
    showHideSubnav() {
      const status = !this.subnavOpenStatus;
      this.mutateSubnavOpenStatus(status);
      this.setUserSetting("subnavOpenStatusStorage", status);
    },
  },
  mounted() {
    let status = true;
    if (typeof this.getUserSetting("subnavOpenStatusStorage") !== "undefined") {
      status = this.getUserSetting("subnavOpenStatusStorage");
    }
    this.mutateSubnavOpenStatus(status);
  },
};
