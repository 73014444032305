<template>
  <div class="rf-toast relative flex flex-col gap-5 rounded bg-white-1 px-6 py-4">
    <div class="flex gap-4" :class="{ 'items-center pb-1 pr-5': action }">
      <div
        aria-hidden="true"
        class="h-6 w-6 flex-shrink-0 flex-grow-0 overflow-hidden rounded-full"
      >
        <component :is="icon.component" class="!h-6 !w-6" :class="icon.class" />
      </div>

      <div v-if="action" class="text-body !text-grey-2">{{ body }}</div>
      <div v-else class="flex flex-grow flex-col gap-1">
        <div v-if="titleValue" class="rf-toast--title">{{ titleValue }}</div>
        <div class="text-body !text-grey-2">
          {{ body }}
        </div>
      </div>

      <RfButtonNew
        v-if="action"
        class="text-body flex-grow-0"
        type="secondary"
        :text="action.title"
        @click="action.handler(), $emit('close')"
      />
    </div>
    <button class="absolute right-2 top-2" @click="$emit('close')">
      <RfCloseIcon class="!h-6 !w-6" />
    </button>
  </div>
</template>

<script setup>
import RfCloseIcon from "@/components/icons/RfCloseIcon.vue";
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import { computed } from "vue";
import RfActionSuccess from "../statuses/RfActionSuccess.vue";
import RfActionError from "../statuses/RfActionError.vue";
import RfActionInfo from "../statuses/RfActionInfo.vue";

defineEmits(["close"]);

const props = defineProps({
  success: { type: Boolean, default: false },
  error: { type: Boolean, default: false },
  info: { type: Boolean, default: false },
  title: { type: String, default: null },
  body: { type: String, default: "Description" },
  action: { type: Object, default: null },
});

const icon = (() => {
  if (props.success) return { component: RfActionSuccess };
  if (props.error) return { component: RfActionError };
  if (props.info) return { component: RfActionInfo };
})();

const titleValue = computed(
  () =>
    props.title ??
    ((props.success && "Success") || (props.error && "Error") || (props.info && "Info") || ""),
);
</script>

<style lang="scss" scoped>
.rf-toast {
  min-width: 416px;
  min-height: 84px;
  box-shadow: 0px 4px 27px 0px rgba(170, 196, 198, 0.25);

  &--title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
</style>
