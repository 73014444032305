<template>
  <div class="promo-design-group">
    <div v-if="model.device_type === 'android_os'">
      <v-text-field
        outlined
        dense
        label="Product Id"
        hide-details
        placeholder="Enter product Id"
        v-model="actions.rf_settings_android_inapp_product_id"
      />
    </div>
    <div v-if="model.device_type === 'ios'">
      <v-select
        v-if="appleActive"
        outlined
        dense
        placeholder="Select product id"
        v-model="actions.rf_settings_apple_inapp_product_id"
        :items="appleProductIds"
        label="Product ID"
      />
      <div v-else>
        Apple connector is not active. Visit
        <router-link :to="actionsLink">connector settings</router-link> to activate.
      </div>
    </div>
    <div v-if="model.device_type === 'roku_os'" class="promo-timer-setting">
      <v-text-field
        outlined
        dense
        label="Product Id"
        hide-details
        placeholder="Enter product Id"
        v-model="actions.rf_settings_roku_product_id"
      />
      <v-select
        outlined
        dense
        placeholder="Select Operation"
        label="Product Operation"
        v-model="actions.rf_settings_roku_product_operation"
        :items="operations"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "RfRetentionActionsInApp",
  props: ["app", "actions", "model"],
  data: () => ({ operations: ["Upgrade", "Downgrade", { text: "None", value: "" }] }),
  computed: {
    appleActive() {
      return this.app.providers.apple.active;
    },
    appleProductIds() {
      return this.app.providers.apple.product_ids.map(item => {
        return { value: item.product_id, text: item.name };
      });
    },
    actionsLink() {
      return {
        path: `/apps/${this.app.id}/settings/connectors`,
      };
    },
  },
};
</script>
