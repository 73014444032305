<template>
  <RfAuth title="Set Password" :error="loginError">
    <template #inputs>
      <v-form v-model="isValid" ref="passwordForm">
        <v-text-field
          outlined
          label="Password"
          prepend-inner-icon="lock_outline"
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :rules="passwordRules()"
          v-on:keyup="resetError"
          @keyup.enter="onSetPassword"
          @input="$refs.passwordForm.validate()"
        ></v-text-field>
        <v-text-field
          outlined
          label="Confirm Password"
          prepend-inner-icon="lock_outline"
          :type="showConfirmPassword ? 'text' : 'password'"
          @click:append="showConfirmPassword = !showConfirmPassword"
          :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
          :rules="passwordConfirmRules(() => password)"
          v-model="confirmation"
          v-on:keyup="resetError"
          @keyup.enter="onSetPassword"
        ></v-text-field>
      </v-form>
      <v-checkbox v-model="policyCheck" hide-details class="mt-1 pt-0">
        <template #label>
          <div>
            By checking this box you are agreeing to our
            <a target="_blank" href="https://www.redfast.com/terms-of-use" @click.stop>
              terms of use
            </a>
            and
            <a target="_blank" href="https://www.redfast.com/privacy" @click.stop>
              privacy policy
            </a>
          </div>
        </template>
      </v-checkbox>
    </template>
    <template #footer>
      <v-btn
        class="login-button"
        width="100%"
        depressed
        :disabled="!policyCheck || !isValid"
        x-large
        color="accent"
        @click="onSetPassword"
        >Submit</v-btn
      >
    </template>
  </RfAuth>
</template>

<script setup>
import { computed, ref } from "vue";
import RfAuth from "@/blocks/RfAuth.vue";
import store from "@/store";
import { passwordConfirmRules, passwordRules } from "@/utils/PasswordUtils";

const props = defineProps(["onSubmit", "success"]);
const emit = defineEmits(["submit"]);

const password = ref(null);
const confirmation = ref(null);
const error = ref(null);
const showError = ref(false);
const policyCheck = ref(false);
const showPassword = ref(false);
const showConfirmPassword = ref(false);
const isValid = ref(false);

const displayError = err => {
  if (!err) return "";
  const message = err
    .toString()
    .replace(/[_\[]/g, " ")
    .replace(/^Error:/, "")
    .replace(/[\]"{}:]/g, "");
  return `Error: ${message}`;
};

const loginError = computed(() => {
  const errorString = error.value || displayError(store.state.lastError);
  return showError.value && errorString !== "Error:  You must login to continue"
    ? errorString
    : null;
});

const passwordError = computed(() => {
  if (confirmation.value !== password.value) {
    return "Passwords must match!";
  }
  if (!password.value || !confirmation.value) {
    return "Passwords must not be empty!";
  }
  return "";
});

const onSetPassword = () => {
  showError.value = true;
  if (!policyCheck.value) return;
  if (passwordError.value) return (error.value = passwordError.value);

  try {
    emit("submit", { password: password.value, password_confirmation: confirmation.value });
  } catch (e) {
    error.value = e;
    showError.value = true;
  }
};
const resetError = () => {
  error.value = null;
  showError.value = false;
};
</script>
