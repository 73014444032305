<template>
  <Transition mode="out-in">
    <div v-if="!skeleton && hasData" key="chart" class="flex flex-col gap-3">
      <div class="rf-prompt-chart--legend h-5">
        <div class="rf-prompt-chart--legend-container"></div>
      </div>
      <canvas ref="chart" style="max-height: 107px; height: 107px"></canvas>
    </div>
    <template v-else-if="!props.skeleton && !props.hasData">
      <div v-if="props.alterNoData" key="empty--alter" class="-mt-2 flex justify-center">
        <RfNoChartDataPlaceholderAlter />
      </div>
      <div v-else key="empty" class="mb-px mt-0.5 box-content flex h-36 py-2">
        <span class="text-action-buttons m-auto font-bold"> There is no data. </span>
      </div>
    </template>
    <div key="skeleton" v-else class="flex flex-col gap-3">
      <div class="h-5"></div>
      <div style="max-height: 107px; height: 107px"></div>
    </div>
  </Transition>
</template>

<script setup>
import { ref, computed, watchEffect, onUnmounted } from "vue";
import { useBarChart } from "@/utils/composables/useBarChart";
import { newChartHasData } from "@/utils/metricsHelpers";
import RfNoChartDataPlaceholderAlter from "@/components/placeholder/RfNoChartDataPlaceholderAlter.vue";

const props = defineProps({
  skeleton: { type: Boolean, default: false },
  alterNoData: { type: Boolean, default: false },
  data: { type: Array, default: () => [] },
});

const chart = ref(null);
const destroyChart = ref(() => null);

const hasData = computed(() => newChartHasData(props.data));

watchEffect(() => {
  if (!props.skeleton && hasData.value && chart.value)
    destroyChart.value = useBarChart(chart, props.data, "horizontal");
});

onUnmounted(destroyChart?.value);

defineExpose({ hasData });
</script>
