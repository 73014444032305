<template>
  <v-card style="padding: 1rem">
    <v-simple-table class="rf-chart-view-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Interval</th>
            <th class="text-left">Total</th>
            <th class="text-left">High</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in currMetrics" :key="index">
            <td class="table-col-1">{{ item.name }}</td>
            <td class="table-col-2">{{ toLocaleNumberString(getTotal(item)) }}</td>
            <td class="table-col-3">{{ toLocaleNumberString(getHigh(item)) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { toLocaleNumberString } from "@/utils/stringHelpers";

export default {
  name: "RfSegmentStatisticsTable",
  props: ["metrics"],
  setup: () => ({ toLocaleNumberString }),
  data: () => ({ currentTimePeriod: "today" }),
  computed: {
    currMetrics() {
      if (!this.metrics || !Object.keys(this.metrics).length) return [];
      return Object.entries(this.metrics).reduce(
        (acc, [k, v]) => (k !== "tz_offset" && v.name !== "Last 7 to 14 Days" ? [...acc, v] : acc),
        [],
      );
    },
  },
  methods: {
    getAvg(item) {
      const filteredData = item.data.filter(el => el.count !== null);
      const sum = filteredData.reduce(
        (accumulator, entry) => (entry.count ? entry.count : accumulator),
        0,
      );
      return Math.round(sum / filteredData.length);
    },
    getTotal(item) {
      const filteredData = item.data.filter(el => el.count !== null);
      if (filteredData) {
        const sum = filteredData.reduce((accumulator, entry) => accumulator + entry.count, 0);
        return sum;
      }
      return 0;
    },
    getHigh(item) {
      return Math.max(...item.data.map(el => el.count));
    },
  },
};
</script>
