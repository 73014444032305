var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mweb-widget-container"},[_c('div',{staticClass:"mweb-phone-kit-bg relative"},[_c('div',{staticClass:"mweb-canvas-bound relative"},[(_vm.actions.rf_settings_close_button_enabled === 'true')?_c('span',{staticClass:"rfmodal-close inline-flex flex-row-reverse"},[_c('v-icon',{style:({ color: _vm.actions.rf_settings_fill_color }),attrs:{"id":"rfmodal-close-icon my-auto"}},[_vm._v("close")]),_c('RfPromptTimeout',{staticClass:"my-auto mr-2",attrs:{"actions":_vm.actions,"newVariant":""}})],1):_vm._e(),_c('div',{staticClass:"h-full w-full bg-blue-1",style:(_vm.containerMobileWebBackgroundImage)},[_c('div',{staticClass:"flex h-7/10"},[_c('div',{staticClass:"my-auto text-center"},[_c('div',{style:(_vm.titleStyle)},[_vm._v(" "+_vm._s(_vm.actions[_vm.isNewIosEditor ? "rf_retention_title" : "rf_mobile_title"])+" ")]),_c('div',{style:(_vm.messageStyle)},[_vm._v(" "+_vm._s(_vm.actions[_vm.isNewIosEditor ? "rf_retention_message" : "rf_mobile_message"])+" ")])])]),_c('div',{staticClass:"flex h-3/10 flex-col"},[_c('div',{ref:"buttonContainer",staticClass:"mweb-btn-container flex flex-shrink-0 flex-grow flex-col"},[(!_vm.isNewDeviceEditor || _vm.actions.rf_retention_button1_text)?_c('button',{staticClass:"mx-8 rounded-full text-lg",style:({
                height: `${_vm.buttonHeight}px`,
                marginTop: `${_vm.buttonPadding}px`,
                ..._vm.acceptButtonStyle,
              }),domProps:{"innerHTML":_vm._s(_vm.actions.rf_retention_button1_text)}}):_vm._e(),(
                _vm.isNewDeviceEditor
                  ? _vm.actions.rf_settings_confirm_button_2_enabled &&
                    _vm.actions.rf_retention_button2_text
                  : _vm.actions.rf_settings_confirm_button_2_enabled
              )?_c('button',{staticClass:"mx-8 rounded-full text-lg",style:({
                height: `${_vm.buttonHeight}px`,
                marginTop: `${_vm.buttonPadding}px`,
                ..._vm.secondaryButtonStyle,
              }),domProps:{"innerHTML":_vm._s(_vm.actions.rf_retention_button2_text)}}):_vm._e(),(
                _vm.isNewDeviceEditor
                  ? _vm.actions.rf_settings_cancel_button_enabled && _vm.actions.rf_retention_button3_text
                  : _vm.actions.rf_settings_cancel_button_enabled
              )?_c('button',{staticClass:"mx-8 rounded-full text-lg",style:({
                height: `${_vm.buttonHeight}px`,
                marginTop: `${_vm.buttonPadding}px`,
                ..._vm.cancelButtonStyle,
              }),domProps:{"innerHTML":_vm._s(_vm.actions.rf_retention_button3_text)}}):_vm._e()]),_c('RfRetentionLinksPreview',{staticClass:"legal",attrs:{"actions":_vm.actions}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }