<template>
  <div>
    <RfSimpleCard class="mb-5">
      <div class="px-7 py-5">
        <p class="mb-0">
          You can import additional user information here. From your data warehouse, you may create
          a one time or recurring export in comma delimited format of any additional information
          that may be useful for targeting Guides or Prompts. For example, the user's lifetime value
          may be used to determine the amount of a save offer. Refer to the
          <a target="_blank" href="https://help.redfast.com/docs/user-traits">documentation</a> for
          additional information.
        </p>
      </div>
    </RfSimpleCard>
    <RfSimpleCard class="rf-retention-form">
      <v-dialog v-model="showEditDialog" persistent max-width="700">
        <v-card tag="form" @submit.prevent="onSubmit">
          <v-card-title class="headline">Update trait</v-card-title>
          <v-card-text>
            <div class="input-wrapper">
              <RfTextInput
                outlined
                dense
                title="Label"
                placeholder="Label"
                v-model="custom_field.label"
                :disabled="custom_field.connector_type === 'aws_s3' || isReadOnlyRoleMixin"
              />
              <RfTextInput
                outlined
                dense
                title="Description"
                placeholder="Description"
                v-model="custom_field.description"
                :disabled="isReadOnlyRoleMixin"
              />
              <RfSelect
                outlined
                dense
                :items="Object.keys(dataTypes)"
                title="Type"
                placeholder="Type"
                v-model="custom_field.data_type"
                :disabled="typesDisabled"
                return-object
              />
              <RfSelect
                outlined
                dense
                :items="Object.keys(displayTypes)"
                title="Display"
                placeholder="Display"
                v-model="custom_field.display_type"
                :disabled="typesDisabled"
                name="trait_value"
                id="trait-value"
              />
            </div>
            <v-checkbox
              v-if="canNormalize"
              v-model="custom_field.is_normalized"
              label="Normalize data into 0-10 scale"
              style="margin-top: 0"
              :disabled="typesDisabled"
            ></v-checkbox>
            <div v-if="typesDisabled">
              <v-icon size="16" left color="warning">warning</v-icon>Contact support to change these
              values.
            </div>
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn
              depressed
              class="cancel-btn"
              outlined
              @click="showEditDialog = false"
              style="width: 100px"
              >Cancel</v-btn
            >
            <RfButton
              icon="update"
              button-text="Update"
              color="success"
              type="submit"
              class="px-7"
              :disabled="isDisabledRoleMixin"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <RfAwsCredentialsModal
        ref="awsCredentialsModal"
        :awsUserName="app.aws_creds?.user_name"
        :awsPath="app.aws_creds?.aws_path"
        :awsAccessKey="currAppAwsCredentials?.access_key_id || app?.aws_creds?.access_key_id"
        :awsSecretKey="
          currAppAwsCredentials?.secret_access_key || app?.aws_creds?.secret_access_key
        "
        @update:showCredentials="
          value =>
            value ? this.getAppAwsCredentials(app.id) : this.mutateCurrAppAwsCredentials({})
        "
      />
      <div class="px-7 py-5">
        <div class="rf-settings-section-title d-flex justify-space-between align-center">
          <div class="d-flex flex-column">
            <h4 class="mb-3">Upload to your Redfast AWS S3 area</h4>
            <span>
              <RfButton
                button-text="Click here for AWS S3 credentials"
                link
                @click="awsCredentialsModal?.show()"
                :disabled="isDisabledRoleMixin"
              />
            </span>
          </div>
          <RfButton
            button-text="Upload CSV"
            icon="add"
            color="success"
            @click="$refs.file.click()"
            :disabled="isDisabledRoleMixin"
          >
            <input ref="file" class="d-none" type="file" name="upload_csv" @change="onUploadCSV" />
          </RfButton>
        </div>
        <v-alert outlined type="info" class="mb-7">
          Once the CSV has been uploaded, it may take up to 30 minutes before traits are available
          here.
        </v-alert>
        <v-expansion-panels v-model="customTraitsPanel" multiple>
          <v-expansion-panel v-for="(fields, key) in fields_by_visible_connector_type" :key="key">
            <v-expansion-panel-header>{{
              getConnectorName(fields.label)
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table class="rf-settings-table-data mt-5">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Trait</th>
                      <th class="text-left">Type</th>
                      <th class="text-left">Display</th>
                      <th class="text-left" v-if="fields.label === 'redfast'">Value</th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody v-if="filteredValues(fields) && filteredValues(fields).length > 0">
                    <tr v-for="(custom, index) in filteredValues(fields)" :key="index">
                      <td>{{ custom.label }}</td>
                      <td>{{ custom.data_type }}</td>
                      <td>{{ custom.display_type }}</td>
                      <td v-if="fields.label === 'redfast'">
                        {{ custom.values ? custom.values[0] : "" }}
                      </td>
                      <td>
                        <RfButton
                          v-if="!['minutes', 'visits'].includes(custom.label)"
                          icon="edit"
                          color="accent"
                          @click="onEdit(custom)"
                        />
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5">
                        <div class="settings-no-data-message">Nothing to show.</div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </RfSimpleCard>
  </div>
</template>

<script>
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import RoleMixin from "@/utils/RoleMixin";
import { useToastsStore } from "@/pinia/toastsStore";
import RfAwsCredentialsModal from "@/blocks/RfModals/RfAwsCredentialsModal.vue";
import { ref } from "vue";

export default {
  name: "RfSetupCustomTraits",
  setup: () => ({ toastsStore: useToastsStore(), awsCredentialsModal: ref() }),
  props: { app: Object },
  mixins: [RoleMixin],
  components: { RfSimpleCard, RfTextInput, RfSelect, RfButton, RfAwsCredentialsModal },
  data() {
    return {
      custom_field: {
        label: "",
        description: "",
        data_type: "",
        display_type: "",
        is_normalized: false,
        name: "",
        is_usage: false,
        values: [],
      },
      showCredentials: false,
      showEditDialog: false,
      customTraitsPanel: [0],
      typesDisabled: false,
      editableFieldIds: {},
      fieldType: "custom",
    };
  },
  methods: {
    ...mapActions([
      "createAppCustomField",
      "updateAppCustomField",
      "getAppAwsCredentials",
      "getAppCustomFields",
    ]),
    ...mapMutations(["mutateCurrAppAwsCredentials"]),
    filteredValues(fields) {
      const { label, values } = fields;
      if (label === "aws_s3") {
        return values.filter(
          item =>
            !item.data_type || ["boolean", "string", "number", "date"].indexOf(item.data_type) > -1,
        );
      }
      return values;
    },
    getConnectorName(key) {
      const items = {
        aws_s3: "AWS S3",
        redfast: "Redfast",
        segment: "Segment",
      };

      return items[key] || key;
    },
    resetForm() {
      this.custom_field.label = "";
      this.custom_field.description = "";
      this.custom_field.data_type = "";
      this.custom_field.display_type = "";
      this.custom_field.is_normalized = false;
      this.custom_field.is_usage = false;
      this.custom_field.name = "";
      this.custom_field.values = [];
    },
    onUploadCSV() {
      fetch(this.app.uploadable_csv_path, {
        method: "PUT",
        headers: {
          "Content-Type": "text/csv",
        },
        body: this.$refs.file.files[0],
      })
        .then(() =>
          this.toastsStore.create({ type: "success", body: "CSV was uploaded successfully" }),
        )
        .catch(() =>
          this.toastsStore.create({ type: "error", body: "CSV upload failed. Please try again" }),
        );
    },
    async onSubmit() {
      if (this.custom_field.id) {
        await this.updateAppCustomField({
          appId: this.app.id,
          fieldId: this.custom_field.id,
          model: this.custom_field,
          fieldType: this.fieldType,
        });
        this.resetForm();
      }
      this.showEditDialog = false;
    },
    onEdit(custom) {
      this.showEditDialog = true;
      custom.values = custom.values || [];
      this.custom_field = { ...custom };
      this.typesDisabled =
        this.isEditFieldDisabled(this.custom_field) || !!this.isDisabledRoleMixin;
    },
    isEditFieldDisabled(field) {
      const ids = this.editableFieldIds[field.connector_type];
      if (ids) {
        return ids.indexOf(field.id) < 0;
      }
      return true;
    },
    fillModel() {
      this.setEditableFieldIds(); // after save, don't allow edit
    },
    setEditableFieldIds() {
      const connectorTypes = {};
      this.fields_by_connector_type.forEach(typeFields => {
        typeFields.values.forEach(field => {
          if (!field.display_type && !field.data_type) {
            if (connectorTypes[typeFields.label]) {
              connectorTypes[typeFields.label].push(field.id);
            } else {
              connectorTypes[typeFields.label] = [field.id];
            }
          }
        });
      });
      this.editableFieldIds = connectorTypes;
    },
  },
  computed: {
    ...mapState({
      currAppAwsCredentials: state => state.apps.currAppAwsCredentials,
      segmentCustomFields: state => state.apps.segmentCustomFields || [],
    }),
    custom_fields() {
      return this.segmentCustomFields;
    },
    hasAllData() {
      return (
        this.custom_field.label.length &&
        this.custom_field.display_type.length &&
        this.custom_field.data_type.length
      );
    },
    fields_by_connector_type() {
      const values = this.custom_fields.reduce((total, item) => {
        const type = item.connector_type;
        if (!total[type]) total[type] = [];
        total[type].push(item);
        return total;
      }, {});

      return ["redfast", "segment", "aws_s3", "redfast_system"].map(item => {
        return {
          label: item,
          values: values[item] || [],
        };
      });
    },
    fields_by_visible_connector_type() {
      return this.fields_by_connector_type.filter(item => item.label === "aws_s3");
    },
    dataTypes() {
      return {
        boolean: "Boolean",
        string: "String",
        number: "Number",
        date: "Date",
      };
    },
    displayTypes() {
      const { data_type } = this.custom_field;
      if (data_type === "boolean") {
        return {
          multi_select: "Multi Select",
        };
      }
      if (data_type === "number") {
        return {
          slider: "Slider",
          range_select: "Range Select",
          multi_select: "Multi Select",
          text_input: "Text",
        };
      }
      if (data_type === "string") {
        return {
          multi_select: "Multi Select",
          text_input: "Text",
        };
      }
      if (data_type === "date") {
        return {
          date_range: "Date Range",
        };
      }
      return {};
    },
    canNormalize() {
      if (this.custom_field.display_type === "slider") {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    if (!this.custom_fields.length) {
      await this.getAppCustomFields({ appId: this.app.id, fieldType: this.fieldType });
    }
    this.setEditableFieldIds();
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  gap: 0px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(300px, 45%), 1fr));
  grid-template-rows: min-content;
}
</style>
