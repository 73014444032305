import { mapState } from "vuex";
import moment from "moment-timezone";
import timezones from "./constants/TimezonesConstants";

export default {
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
  },
  methods: {
    promoStatus(item) {
      let label;
      let color;
      const timezone = timezones[this.currApp.timezone_offset] || "UTC";
      const startDate = moment(item.start_date).utc();
      const date = moment().tz(timezone).utc(true);
      const startFuture = startDate.isAfter(date);
      const endPast = item.is_expired;
      if (endPast) {
        label = "Ended";
        color = "#cccccc";
      } else if (
        this.promoCurrLimitError(item) ||
        this.promoCurrBudgetError(item) ||
        this.promoCurrUserLimitError(item) ||
        this.promoCurrDeliveryError(item)
      ) {
        label = "Limited";
        color = "error";
      } else if (item.is_enabled) {
        if (startFuture) {
          label = "Scheduled";
          color = "#cccccc";
        } else if (!startFuture && !endPast) {
          label = "Running";
          color = "success";
        }
      } else if (startFuture) {
        label = "Pending";
        color = "#cccccc";
      } else if (!startFuture && !endPast) {
        label = "Paused";
        color = "#cccccc";
      }
      return { label, color };
    },
    promoCurrLimitError(item) {
      if (!item.filter.traffic.is_limited) return null;
      const currLimit = item.filter.traffic.limit - (item.curr_limit || 0);
      return currLimit < 1;
    },
    promoCurrBudgetError(item) {
      if (!item.filter.supply.is_budgeted) return null;
      const currBudget = item.filter.supply.budget - (item.curr_budget || 0);
      return currBudget < 1;
    },
    promoCurrUserLimitError(item) {
      if (!item.filter.users.is_limited) return null;
      const currLimit = item.filter.users.limit - (item.curr_users || 0);
      return currLimit < 1;
    },
    promoCurrDeliveryError(item) {
      if (!item.filter.deliveries.is_limited) return null;
      const currLimit = item.filter.deliveries.limit - (item.curr_deliveries || 0);
      return currLimit < 1;
    },
    hasConnectorErrors(item) {
      return item.connector_errors && item.connector_errors.length;
    },
    promoPublishClassName(item) {
      const status = this.promoStatus(item).label;
      const result = ["promo-status-light"];
      if (status === "Running") {
        result.push("green-color");
      } else if (status === "Limited") {
        result.push("red-color");
      }
      return result;
    },
  },
};
