export const Statuses = {
  true: { type: true, title: "Enabled" },
  false: { type: false, title: "Disabled" },
};

export const RangeTypesBase = {
  equal_to: { value: "equal_to", text: "Equal to" },
  less_than_or_equal_to: { value: "less_than_or_equal_to", text: "Less than or equal to" },
  greater_than_or_equal_to: { value: "greater_than_or_equal_to", text: "Greater than or equal to" },
  range: { value: "range", text: "From To" },
};

export const RangeTypes = {
  none: { value: "none", text: "None" },
  at_least_once: { value: "at_least_once", text: "At least one" },
  ...RangeTypesBase,
  trending: { value: "trending", text: "Trending" },
};

export const RawUsageTimePeriods = {
  null: { value: null, text: "all time" },
  1: { value: 1, text: "1 day" },
  7: { value: 7, text: "7 days" },
  30: { value: 30, text: "30 days" },
};

export const TrendDirection = {
  trending_up: { value: "trending_up", text: "Trending Up" },
  trending_down: { value: "trending_down", text: "Trending Down" },
  any: { value: "any", text: "Any Direction" },
};

export const TimeDuration = {
  monthly: { value: "monthly", text: "Month over month" },
  weekly: { value: "weekly", text: "Week over week" },
  daily: { value: "daily", text: "Day over day" },
};

export const UserFilterItems = {
  ltv_range: { value: "ltv_range", text: "Lifetime Value", field: "ltv" },
  css_range: { value: "css_range", text: "Customer Satisfaction Score", field: "css" },
  fraud_score_range: { value: "fraud_score_range", text: "Fraud Score", field: "fraud_score" },
  churn_score_range: { value: "churn_score_range", text: "Churn Score", field: null },
};

export const UserBucketItems = {
  values: { value: "values", text: "User Id" },
  range: { value: "range", text: "Bucket/block" },
};

export const PinpointChannelItems = {
  GCM: { value: "GCM", text: "Google/Firebase cloud manager" },
  APNS: { value: "APNS", text: "Apple push notification service" },
  ADM: { value: "ADM", text: "Amazon device messaging" },
};

export const MatchTypeItems = {
  false: { text: "Include", value: false },
  true: { text: "Exclude", value: true },
};

export const MatchTypeItemsAlter = {
  true: { text: "has", value: true },
  false: { text: "has not", value: false },
};

export const InteractionTypeItems = {
  seen: { text: "seen", value: "seen" },
  accepted: { text: "accepted (primary)", value: "accepted" },
  accepted2: { text: "accepted (secondary)", value: "accepted2" },
  declined: { text: "declined", value: "declined" },
  dismissed: { text: "dismissed", value: "dismissed" },
};
