<template>
  <div class="text-body !text-black-2">
    <template v-if="inherited">
      Inherited from <RouterLink :to="inherited.link">{{ inherited.name }}</RouterLink>
    </template>
    <div v-else-if="schedule.startDate && schedule.endDate" class="flex flex-col">
      <div>
        {{ dayjs(schedule.startDate).utc().format(`${dateOrder()}/YY: h:mma`) }} -
        {{ dayjs(schedule.endDate).utc().format(`${dateOrder()}/YY: h:mma`) }}
      </div>

      <div v-if="schedule.daypart?.length" class="flex flex-col">
        Daypart:
        <div v-for="(item, index) in schedule.daypart" :key="index">
          {{ WeekDay[item[0]] }}, {{ StringUtils.hhmm2IntervalIndex(item[1]) }} -
          {{ StringUtils.hhmm2IntervalIndex(item[2]) }}
        </div>
      </div>
    </div>
    <span v-else>-</span>
  </div>
</template>

<script setup>
import { dateOrder } from "@/utils/DateDisplayUtils";
import StringUtils from "@/utils/StringUtils";
import dayjs from "dayjs";

const WeekDay = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
defineProps({
  inherited: { type: Object, default: null },
  schedule: { type: Object, default: () => ({}) },
});
</script>
