<template>
  <div class="promo-language-container">
    <v-btn
      depressed
      color="accent"
      class="upload-image-btn"
      @click="triggerUploadCSV"
      :disabled="imageUploaded"
    >
      <v-icon size="14" left>fas fa-upload</v-icon>Upload CSV
    </v-btn>
    <input type="file" style="display: none" ref="csvInput" accept=".csv" @change="uploadCSV" />
    <div v-if="fileName">
      <span>Current File: </span> <span>{{ fileName }}</span>
    </div>

    <div class="upload-bgimg-wrapper" v-if="translationsLink && model.path_type !== 'text'">
      <v-btn
        depressed
        color="accent"
        class="upload-image-btn"
        @click="triggerUploadBgImageFromInput"
        :disabled="!!fileName"
      >
        <v-icon size="14" left>fas fa-upload</v-icon> {{ uploadLabel }}
      </v-btn>
      <input
        type="file"
        style="display: none"
        ref="bgImageInput"
        accept="image/*"
        @change="uploadBgImageFromInput"
      />
    </div>

    <v-btn
      depressed
      v-if="translationsLink"
      color="accent"
      class="upload-image-btn"
      target="_blank"
      :href="translationsLink"
    >
      <v-icon size="14" left>fas fa-download</v-icon>Download Current Translations
    </v-btn>

    <p style="margin: 0">
      Refer to Multi Language Support within the
      <a :href="docsUrl" target="_blank">documentation</a> for additional information and sample
      CSVs.
    </p>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { cloneDeep } from "lodash-es";

export default {
  name: "RfPromoLanguages",
  props: ["model", "actionGroup", "viewTypeTab"],
  data() {
    return {
      fileName: null,
      record: this.actionGroup || this.model,
      imageUploaded: false,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),

    triggerUploadCSV() {
      this.$refs.csvInput.click();
    },
    uploadCSV() {
      this.setLoading(true);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.record.translations = reader.result;
          this.setLoading(false);
        },
        false,
      );
      const inputRef = this.$refs.csvInput;
      if (inputRef && inputRef.files[0]) {
        reader.readAsDataURL(inputRef.files[0]);
        this.fileName = inputRef.files[0].name;
      } else {
        this.setLoading(false);
      }
    },
    triggerUploadBgImageFromInput() {
      this.$refs.bgImageInput.click();
    },
    uploadBgImageFromInput() {
      this.setLoading(true);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          const key =
            this.viewTypeTab === "mobilewebViewTab"
              ? "rf_settings_mobile_bg_image"
              : "rf_settings_bg_image";

          const languages = (this.record.translations && this.record.translations.images) || {};
          const currImages = languages[this.currLanguage] || {};
          currImages[key] = reader.result;
          languages[this.currLanguage] = currImages;
          this.record.translations = { images: languages };

          const previewLanguage = this.record.parsed_translations[this.currLanguage] || {};
          previewLanguage[key] = reader.result;
          this.record.parsed_translations[this.currLanguage] = previewLanguage;
          // some issues with Vue reactivity, so force re-render
          this.$emit("forceParentRender");
          this.setLoading(false);
          this.imageUploaded = true;
        },
        false,
      );
      const inputRef = this.$refs.bgImageInput;

      if (inputRef && inputRef.files[0]) {
        reader.readAsDataURL(inputRef.files[0]);
      } else {
        this.setLoading(false);
      }
      this.$refs.bgImageInput.value = "";
    },
  },
  computed: {
    ...mapState({
      currPath: state => state.apps.currPath,
      currExperiment: state => cloneDeep(state.apps.currExperiment),
      currLanguage: state => state.apps.currLanguage,
      docsUrl: state => state.apps.docsUrl,
    }),
    hasTranslations() {
      const translations = this.record.parsed_translations;
      if (!translations) return false;

      return Object.keys(translations).length;
    },
    translationsLink() {
      return this.record.translations_csv;
    },
    uploadLabel() {
      const type = this.viewTypeTab === "mobilewebViewTab" ? "mobile" : "desktop";

      return `Upload ${type} background (${this.currLanguage})`;
    },
  },
  watch: {
    currPath() {
      if (this.actionGroup) return;
      this.fileName = null;
      this.imageUploaded = false;
      this.record = this.model;
    },
    currExperiment() {
      if (!this.actionGroup) return;
      this.fileName = null;
      this.imageUploaded = false;
      this.record = this.actionGroup;
    },
  },
};
</script>
