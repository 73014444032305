<template>
  <div class="rf-detail-content-wrapper" v-if="app" style="max-width: 700px">
    <div class="page-detail-header" style="padding-top: 1rem">
      <h1>Create a new app</h1>
    </div>
    <v-card>
      <RfAppInfo :modelApp="app" ref="appInfo" />
      <v-card-actions class="rf-retention-form-btn-div">
        <v-btn x-large color="primary" style="width: 300px; font-size: 18px" @click="submitCreate"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { cloneDeep } from "lodash-es";
import * as Sentry from "@sentry/browser";
import RfAppInfo from "@/components/RfAppCreate/RfAppInfo.vue";
import { mapActions, mapState } from "vuex";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfAppCreate",
  setup: () => ({ toastsStore: useToastsStore() }),
  components: { RfAppInfo },
  data: () => ({ updating: false }),
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      app: state => state.tmp.app,
      lastError: state => state.lastError,
    }),
  },
  methods: {
    async submitCreate() {
      try {
        const modelApp = cloneDeep(this.app);
        this.$refs.appInfo.fillModelSegment(modelApp);
        this.updating = true;
        await this.createApp(modelApp);
      } catch (error) {
        this.toastsStore.create({ type: "error", body: error.message });
        Sentry.captureException(error);
      }
    },
    ...mapActions(["createApp"]),
  },
  watch: {
    apps(apps) {
      if (this.updating) {
        this.toastsStore.create({ type: "success", body: "App created successfully" });
        const currApp = apps[apps.length - 1];
        this.$router.replace({ path: `/apps/${currApp.id}/segments` });
        this.updating = false;
      }
    },
    lastError(to) {
      if (to) {
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>

<style></style>
