import { serializeQuery } from "@/utils/UrlUtils";
import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiPathGroups {
  static createPathGroup(appId, pathGroup) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/path_groups`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(pathGroup),
    });
  }

  static getPathGroups(appId) {
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/path_groups?path_type=horizontal,vertical,tile,text`,
    );
  }

  static getPathGroup(appId, pathGroupId, range, filter) {
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/path_groups/${pathGroupId}?${this.getMetricPeriod(
        range,
        filter,
      )}`,
    );
  }

  static updatePathGroup(appId, pathGroup, range, filter) {
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/path_groups/${pathGroup.id}?${this.getMetricPeriod(
        range,
        filter,
      )}`,
      {
        method: "PUT",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pathGroup),
      },
    );
  }

  static getMetricPeriod(range, filter) {
    if (filter === "since_start_date") filter = "last_seven_days"; // hack to disallow since_start_date for path groups but retain same key with promos
    let params;
    if (filter && filter === "custom" && range) {
      params = { metric_periods: [{ period: "custom", date_range: range }] };
    } else {
      params = { metric_periods: [{ period: filter }] };
    }

    return serializeQuery(params);
  }

  static deletePathGroup(appId, pathGroupId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/path_groups/${pathGroupId}`, {
      method: "DELETE",
    }).then(_ => pathGroupId);
  }

  static setPriority(appId, pathGroups) {
    const prioritizedList = pathGroups.reduce((h, pg) => {
      return {
        ...h,
        [pg.id]: { priority: pg.priority },
      };
    }, {});
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/path_groups/set_priority`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ path_groups: prioritizedList }),
    });
  }

  static getActivities(appId, pathGroupId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/path_groups/${pathGroupId}/activity`, {
      method: "GET",
    });
  }

  static getPathGroupModel(appId, pathType, deviceType) {
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/path_groups/new?path_type=${pathType}&device_type=${deviceType}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      },
    );
  }
}
