<template>
  <div class="promo-design-group">
    <v-textarea
      hide-details
      outlined
      dense
      label="Terms of Service text"
      v-model.trim="currentActions.rf_settings_privacy_policy_text"
      :disabled="currentTranslations.hasOwnProperty('rf_settings_privacy_policy_text')"
      rows="3"
    ></v-textarea>

    <div class="input-insert-button">
      <a @click="showInsertLinkDialog = true">
        <v-icon left size="14" color="accent">add</v-icon>
        <span>Insert link</span>
      </a>
    </div>
    <v-dialog v-model="showInsertLinkDialog" width="550" max-width="550">
      <v-card>
        <v-card-title class="headline">Insert link into Terms of Service text</v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            dense
            label="Link text to display"
            v-model.trim="linkTextToInsert"
            placeholder="e.g.: Terms of Service"
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-text-field
            outlined
            dense
            label="Link URL"
            v-model.trim="linkURLToInsert"
            placeholder="e.g.: http://company.com/terms"
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <RfButton
            button-text="Cancel"
            class="cancel-btn px-5"
            outlined
            @click="closeInsertModal"
          />
          <RfButton color="primary" button-text="Insert link" class="px-7" @click="insertLink" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import RfButton from "@/components/buttons/RfButton.vue";

export default {
  name: "RfRetentionActionsLinks",
  props: ["actions", "model"],
  mixins: [LanguagePreviewMixin],
  components: { RfButton },
  data() {
    return {
      showInsertLinkDialog: false,
      linkURLToInsert: null,
      linkTextToInsert: null,
    };
  },
  computed: {
    currentActions() {
      return this.currentTranslations.hasOwnProperty("rf_settings_privacy_policy_text")
        ? this.previewActions
        : this.actions;
    },
  },
  methods: {
    insertLink() {
      const { rf_settings_fill_color, rf_settings_privacy_policy_text } = this.currentActions;
      const linkHTML =
        (rf_settings_privacy_policy_text.length ? " " : "") +
        `<a href="${this.linkURLToInsert}"` +
        ` style="color:${rf_settings_fill_color};text-decoration:underline"` +
        ` target="_blank">${this.linkTextToInsert}</a>`;

      this.currentActions.rf_settings_privacy_policy_text += linkHTML;
      this.closeInsertModal();
    },
    closeInsertModal() {
      this.showInsertLinkDialog = false;
      this.linkURLToInsert = this.linkTextToInsert = null;
    },
  },
};
</script>
