<template>
  <v-card v-if="model">
    <v-card-title class="headline">{{
      editAction ? "Update action" : "Add new action"
    }}</v-card-title>
    <v-card-text>
      <RfTextInput
        outlined
        dense
        hide-details
        :disabled="!!disabled"
        v-model="model.args.description"
        placeholder="Your API Action description"
        title="Action Description"
      />
      <div class="d-flex flex-column my-5">
        <span>
          To include dynamic values, embed a placeholder in the url, query string, headers, or
          payload.
        </span>
        <a @click.stop="availableDynamicValuesListDialog = true"
          >Available Dynamic Value Placeholders</a
        >
      </div>
      <v-dialog scrollable v-model="availableDynamicValuesListDialog" max-width="700px">
        <v-card>
          <v-card-title>
            <span>Available Dynamic Value Placeholders</span>
          </v-card-title>
          <v-card-text>
            <ul class="availableDynamicValuesList">
              <li>%user_id%</li>
              <li>%promo_inputs.promo_input_1_value%</li>
              <li v-for="value in availableDynamicValues" :key="value">{{ value }}</li>
            </ul>
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn
              depressed
              class="cancel-btn px-7"
              outlined
              @click="availableDynamicValuesListDialog = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-for="(input, index) in model.args.global_inputs" :key="index">
        <RfTextInput
          outlined
          dense
          v-if="input.input_type === 'text_input'"
          v-model="input.value"
          :disabled="!!disabled"
          :title="input.value_label"
          placeholder=" "
        />
        <RfSelect
          outlined
          dense
          v-if="input.input_type === 'select'"
          v-model="input.value"
          :disabled="!!disabled"
          :title="input.value_label"
          :items="input.possible_values"
        />
        <div v-if="input.input_type === 'nested' && canShowInput(input)">
          <v-dialog v-model="addKeyValueModal" max-width="550px" v-if="addKeyValueModal">
            <v-card>
              <v-card-title>
                <span>Add a key/value pair to {{ addKeyValueModal.value_description }}</span>
              </v-card-title>
              <v-card-text>
                <RfTextInput placeholder=" " outlined dense title="Key" v-model="newNested.key" />
                <RfTextInput
                  placeholder=" "
                  outlined
                  dense
                  title="Value"
                  hide-details
                  v-model="newNested.value"
                />
              </v-card-text>
              <v-card-actions class="modal-card-actions-div">
                <v-btn
                  width="100"
                  class="cancel-btn"
                  outlined
                  depressed
                  @click.stop="addKeyValueModal = null"
                  >Cancel</v-btn
                >
                <v-btn width="150" depressed color="primary" @click="addPair(addKeyValueModal)"
                  >Submit</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <div class="customKeysValuesDiv">
            <div class="customKeysValuesDivHeader">{{ input.value_label }}</div>
            <v-simple-table class="rf-settings-table-data" style>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Key</th>
                    <th>Value</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, key) in input.value" :key="key">
                    <td>{{ key }}</td>
                    <td>{{ value }}</td>
                    <td align="right">
                      <RfButton
                        icon="clear"
                        small
                        color="error"
                        :disabled="disabled"
                        @click="deletePair(input, key)"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="d-flex justify-end">
              <RfButton
                :button-text="`Add a key/value pair to ${input.value_description}`"
                small
                color="success"
                :disabled="disabled"
                @click.stop="addKeyValueModal = input"
              />
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="modal-card-actions-div">
      <v-btn width="100" depressed class="cancel-btn" outlined @click="cancel">
        {{ !disabled ? "Cancel" : "Close" }}</v-btn
      >
      <RfButton
        :button-text="submitCTA"
        class="px-7"
        color="primary"
        :disabled="disabled || !submitEnabled"
        @click="submitAction"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";

export default {
  name: "RfCustomConnectorActions",
  components: { RfTextInput, RfSelect, RfButton },
  props: ["app", "editAction", "isNew", "providerInputs", "disabled"],
  mixins: [CustomFieldsMixin],
  data() {
    return {
      newNested: {
        key: "",
        value: "",
      },
      editingNested: "",
      model: cloneDeep(this.editAction),
      requiredInputs: ["base_url", "method"],
      requiredArgs: ["description"],
      availableDynamicValuesListDialog: false,
      addKeyValueModal: null,
    };
  },
  methods: {
    ...mapActions(["newConnectorAction"]),
    cancel() {
      this.$emit("close");
    },
    submitAction() {
      // clear payload if get
      if (this.isGetRequest) {
        const payloadInput = this.model.args.global_inputs.find(
          input => input.value_description === "payload",
        );
        if (payloadInput) {
          payloadInput.value = {};
        }
      }
      if (this.isNew) {
        this.$emit("addCustomConnectorAction", cloneDeep(this.model));
      } else {
        this.$emit("updateAction", this.model);
      }
    },
    addPair(input) {
      const { key } = this.newNested;
      const { value } = this.newNested;
      if (input && key && value) {
        input.value[key] = value;
        this.newNested.key = "";
        this.newNested.value = "";
      }
      this.editingNested = "";
      this.addKeyValueModal = null;
    },
    openNewPairForm(input) {
      this.newNested.key = "";
      this.newNested.value = "";
      this.editingNested = input.value_description;
    },
    deletePair(input, key) {
      delete input.value[key];
      this.$forceUpdate();
    },
    canShowInput(input) {
      if (this.isGetRequest) {
        return input.value_description !== "payload";
      }
      return true;
    },
  },
  computed: {
    ...mapState({
      newAction: state => state.tmp.customConnectorAction,
    }),
    submitCTA() {
      return this.isNew ? "Create New Action" : "Update Action";
    },
    submitEnabled() {
      if (!this.model) return false;
      const blankArg = this.requiredArgs.find(arg => !this.model.args[arg]);
      if (blankArg) {
        return false;
      }
      const globals = this.model.args.global_inputs;
      const blankInput = this.requiredInputs.find(input => {
        const globalInput = globals.find(global => global.value_description === input);
        return !globalInput.value;
      });
      if (blankInput) {
        return false;
      }
      return true;
    },
    availableUserTraits() {
      return this.appCustomFields.map(field => `%user.${field.label}%`);
    },
    availableProviderTraits() {
      if (this.providerInputs) {
        return this.providerInputs.map(input => `%provider.${input}%`);
      }
      return [];
    },
    availableDynamicValues() {
      return this.availableUserTraits.concat(this.availableProviderTraits);
    },
    isGetRequest() {
      if (this.model && this.model.args) {
        const methodInput = this.model.args.global_inputs.find(
          input => input.value_description === "method",
        );
        if (methodInput) {
          return methodInput.value === "GET";
        }
      }
      return false;
    },
  },
  mounted() {
    this.newConnectorAction({ appId: this.app.id });
  },
  watch: {
    newAction(to) {
      if (to && !this.model) {
        this.model = cloneDeep(this.newAction);
      }
    },
    editAction(to) {
      if (to) {
        this.model = cloneDeep(to);
      } else {
        this.model = cloneDeep(this.newAction);
      }
    },
  },
};
</script>
