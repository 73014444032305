import { computed, onMounted, onUnmounted, ref } from "vue";
import { convertToDays } from "../DateUtils";

const timeClass = "rfmodal-body-countdown--time";
const periodClass = "rfmodal-body-countdown--period";
const delimiterClass = "rfmodal-body-countdown--delimiter";
export const useCountdownTimer = actions => {
  const countdownDiff = ref(0);
  const countdownInterval = ref(null);

  const calculateDiff = () => {
    if (!actions.value.rf_retention_countdown_enabled) return;
    countdownDiff.value =
      +new Date(actions.value.rf_retention_countdown_end_datetime) - +new Date();
  };

  const getClassString = (className, periodString) => `${className} ${className}-${periodString}`;
  const getPeriodString = (periodValue, periodClassString, periodString, delimiter) =>
    `<span class="${getClassString(timeClass, periodClassString)}">${periodValue}</span> <span class="${getClassString(periodClass, periodClassString)}">${periodString}</span>${
      delimiter
        ? ` <span class="${getClassString(delimiterClass, periodClassString)}">:</span> `
        : ""
    }`;

  const messageCountdownStyle = computed(() => {
    const fontSize = actions.value.rf_settings_message_font_size?.split("px")[0];
    return {
      "order": actions.value.rf_retention_countdown_position === "top" ? 0 : 1,
      "font-size": `${
        fontSize *
        (actions.value.rf_retention_countdown_size === "large"
          ? 1.25
          : actions.value.rf_retention_countdown_size === "small"
            ? 0.75
            : 1)
      }px`,
      "color": actions.value.rf_settings_fill_color,
    };
  });

  const countdownRender = computed(() => {
    const conv = convertToDays(countdownDiff.value);

    const days = `${conv.days ? getPeriodString(conv.days, "days", "days", true) : ""}`;
    const hours = `${conv.days || conv.hours ? getPeriodString(`${conv.hours}`.padStart(2, "0"), "hours", "hrs", true) : ""}`;
    const minutes = `${conv.days || conv.hours || conv.minutes ? getPeriodString(`${conv.minutes}`.padStart(2, "0"), "minutes", "mins", true) : ""}`;
    const seconds = `${getPeriodString(`${conv.seconds}`.padStart(2, "0"), "seconds", "secs")}`;
    return `${days}${hours}${minutes}${seconds}`;
  });

  onMounted(() => {
    calculateDiff();
    countdownInterval.value = setInterval(calculateDiff, 1000);
  });

  onUnmounted(() => clearInterval(countdownInterval.value));

  return { messageCountdownStyle, countdownRender };
};
