import { toValue } from "@vueuse/core";
import {
  Chart,
  BarController,
  LineController,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Filler,
} from "chart.js";
import dayjs from "dayjs";
import { ref } from "vue";
import { dateOrder } from "../DateDisplayUtils";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { toLocaleNumberString } from "../stringHelpers";

const getOrCreateLegendList = (chart, id, horizontal) => {
  const legend = chart.canvas.parentNode.parentNode.querySelector(
    "div.rf-prompt-chart--legend-container",
  );
  if (!legend) return null;
  let listContainer = legend.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexDirection = "row";
    listContainer.style.justifyContent = horizontal ? "flex-start" : "flex-end";
    listContainer.style.gap = horizontal ? "20px" : "24px";
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legend.appendChild(listContainer);
  }

  const legendContainer = chart.canvas.parentNode.parentNode.querySelector(
    "div.rf-prompt-chart--legend",
  );
  legendContainer.appendChild(legend);

  return listContainer;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const horizontal = chart.data.datasets[0]?.props?.type === "horizontal";
    const ul = getOrCreateLegendList(chart, options.containerID, horizontal);

    if (!ul) return null;
    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);
    chart.data.datasets.forEach(({ label }) => {
      const item = items.find(el => el.text === label);
      const li = document.createElement("li");
      li.style.alignItems = "center";
      li.style.display = "flex";
      li.style.flexDirection = "row";
      li.style.gap = "8px";

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + "px";
      boxSpan.style.display = "inline-block";
      boxSpan.style.flexShrink = 0;
      if (horizontal) {
        boxSpan.style.height = "16px";
        boxSpan.style.width = "16px";
      } else {
        boxSpan.style.height = "24px";
        boxSpan.style.width = "24px";
      }
      boxSpan.style.borderRadius = "4px";

      // Text
      const textContainer = document.createElement("p");
      textContainer.style.color = "#212121";
      textContainer.style.fontFamily = "Roboto";
      textContainer.style.fontSize = "14px";
      textContainer.style.lineHeight = "20px";
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? "line-through" : "";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div#tooltip");

  if (tooltipEl) return tooltipEl;

  tooltipEl = document.createElement("div");
  tooltipEl.setAttribute("id", "tooltip");
  tooltipEl.style.background = "#ffffff";
  tooltipEl.style.borderRadius = "4px";
  tooltipEl.style.color = "#212121";
  tooltipEl.style.fontFamily = "Roboto";
  tooltipEl.style.fontSize = "16px";
  tooltipEl.style.lineHeight = "24px";
  tooltipEl.style.padding = "16px 32px";
  tooltipEl.style.opacity = 1;
  tooltipEl.style.pointerEvents = "none";
  tooltipEl.style.position = "absolute";
  tooltipEl.style.transform = "translate(-50%, 0)";
  tooltipEl.style.transition = "all .1s ease";
  tooltipEl.style.boxShadow = "0px 4px 30px 0px rgba(59, 80, 117, 0.20)";

  const table = document.createElement("table");
  table.style.margin = "0px";
  table.style.position = "relative";

  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", 17);
  svg.setAttribute("height", 15);
  svg.setAttribute("fill", "none");
  svg.setAttribute("viewBox", "0 0 17 15");
  svg.style.position = "absolute";
  svg.style.bottom = "-12px";
  svg.style.left = "50%";
  svg.style.zIndex = "-1";
  svg.style.transform = "translate(-50%, 0)";
  const arrow = document.createElementNS("http://www.w3.org/2000/svg", "path");
  arrow.setAttribute("d", "M8.5 15L0.272758 0.75L16.7272 0.750002L8.5 15Z");
  arrow.style.fill = "#ffffff";

  svg.appendChild(arrow);
  tooltipEl.appendChild(svg);
  tooltipEl.appendChild(table);
  chart.canvas.parentNode.appendChild(tooltipEl);

  return tooltipEl;
};

const externalTooltipHandler = ({ chart, tooltip }) => {
  // Tooltip Element
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const tableBody = document.createElement("tbody");

    const label = tooltip?.dataPoints?.[0]?.label;
    if (label && label !== "0") {
      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;
      tr.style.height = "32px";

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const data = document.createElement("span");
      data.style.borderWidth = 0;
      data.style.fontWeight = "600";
      data.innerText = `${dayjs(label).format(label.length > 8 ? "hh:mm" : dateOrder())}`;
      td.appendChild(data);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    }

    const bodyLines = tooltip.body.map(b => b.lines);
    bodyLines.forEach(body => {
      const [titleValue, bodyValue] = body[0].split(":");
      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;

      const data = document.createElement("span");
      data.style.borderWidth = 0;
      data.style.whiteSpace = "nowrap";

      const title = document.createElement("span");
      title.style.borderWidth = 0;
      title.style.color = "#252C38";
      title.style.fontWeight = "400";
      title.innerText = `${titleValue}:`;

      const value = document.createElement("span");
      value.style.borderWidth = 0;
      value.style.fontWeight = "600";
      if (tooltip.dataPoints?.[0]?.dataset?.props?.type === "percentage") {
        const percentageValue =
          tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex].value.label;
        value.innerText = ` ${percentageValue}%`;
      } else if (tooltip.dataPoints?.[0]?.dataset?.props?.type === "horizontal") {
        const dataValue = tooltip.dataPoints[0].dataset.data[tooltip.dataPoints[0].dataIndex];
        value.innerText = ` ${(dataValue.raw * 100).toFixed(2)}% (${toLocaleNumberString(dataValue.label)})`;
      } else {
        value.innerText = bodyValue;
      }

      data.appendChild(title);
      data.appendChild(value);
      td.appendChild(data);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.style.opacity = 1;

  if (tooltip.dataPoints?.[0]?.dataset?.props?.type === "horizontal") {
    tooltipEl.style.left = `${positionX + tooltip.dataPoints[0].element.x - tooltip.dataPoints[0].element.width / 2}px`;
    tooltipEl.style.top = `${positionY - 62}px`;
  } else {
    tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
    tooltipEl.style.top = `${positionY + tooltip.caretY - 110 - (tooltip.body.length - 1) * (tooltipEl.clientHeight - 88)}px`;
  }
  tooltipEl.style.font = tooltip.options.bodyFont.string;
};

export const useBarChart = (canvasRef, datasets = [], type = undefined) => {
  const canvas = toValue(canvasRef);
  if (!canvas) return;
  const allDatasetsPercentage = datasets.every(el => el.props?.type === "percentage");
  const retriggerDraw = ref(false);
  Chart.register([
    BarController,
    LineController,
    BarElement,
    LineElement,
    PointElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    Filler,
  ]);

  const destroy = () => {
    if (Chart.getChart(toValue(canvas))) Chart.getChart(toValue(canvas)).destroy();
  };

  destroy();
  new Chart(canvas, {
    type: "bar",
    plugins: [
      ChartDataLabels,
      htmlLegendPlugin,
      {
        id: "percentagePlugin",
        beforeDatasetsDraw: chart => {
          const indexes = chart.data.datasets.reduce((acc, el, i) => {
            if (el?.props?.type === "percentage") acc.push(i);
            return acc;
          }, []);

          if (!indexes.length) return;
          indexes.map(datasetIndex => {
            const length = chart.data.datasets?.[datasetIndex]?.data?.length || 0;

            const maxValue = chart.data.datasets[datasetIndex].data.reduce(
              (acc, el) => Math.max(acc, el.value.label),
              0,
            );
            const localMax =
              chart.data.datasets.length === 1 || maxValue === 100
                ? 100
                : 100 - (4 - (Math.floor(maxValue / 25) + 1)) * 25;
            for (let i = 0; i < length ? length - 1 || 1 : 0; i++) {
              const max = chart.data.datasets.length === 1 ? 1 : chart.scales.y.max;
              const value =
                (max * chart.data.datasets[datasetIndex].data[i].value.label) / localMax;
              chart.data.datasets[datasetIndex].data[i].value.raw = Math.max(
                Math.min(value, localMax),
                0,
              );
            }
          });

          if (!retriggerDraw.value) {
            retriggerDraw.value = true;
            chart.update();
            return false;
          }
        },
      },
    ],
    options: {
      ...(type === "horizontal" && { indexAxis: "y" }),
      scales: {
        x: {
          ...(type === "horizontal" && { stacked: true, max: 1 }),
          afterFit: a => {
            if (type === undefined)
              a.ticks = a.ticks.map(el => ({
                ...el,
                label: dayjs(el.label).format(el.label.length > 8 ? "hh:mm" : dateOrder()),
              }));
            if (type === "horizontal")
              a.ticks = a.ticks.map(el => ({ ...el, label: `${el.label * 100}%` }));
          },
          ticks: {
            maxTicksLimit: type === "horizontal" ? 6 : 20,
            align: "inner",
            color: "#57595C",
            font: { family: "Roboto", size: "16px", lineHeight: "20px" },
          },
          border: {
            ...(type === "horizontal" && { display: false }),
            ...(type === "horizontal" && {
              dash: ({ tick }) => (tick.value > 0 ? [6, 63] : 0),
              dashOffset: -2.5,
            }),
          },
          grid: {
            ...(type === undefined && { display: false }),
            ...(type === "horizontal" && {
              color: ({ tick }) => (tick.value > 0 ? "#C9DDF9" : "#57595C"),
              lineWidth: ({ tick }) => (tick.value > 0 ? 1 : 2),
              drawTicks: false,
            }),
          },
        },
        y: {
          ...(type === "horizontal" && { stacked: true }),
          beforeFit: a => {
            if (type === undefined && allDatasetsPercentage)
              a.ticks = a.ticks.map(el => ({ ...el, label: `${el.value * 100}%` }));
          },
          ...(!allDatasetsPercentage && { suggestedMax: 5 }),
          ticks: {
            ...(type === "horizontal" && { display: false }),
            align: "inner",
            color: "#57595C",
            font: { family: "Roboto", size: "16px", lineHeight: "20px" },
            padding: 20,
            labelOffset: -5,
            stepSize: allDatasetsPercentage ? 0.2 : 1,
            beginAtZero: true,
            maxTicksLimit: 6,
          },
          border: {
            ...(type === undefined && { dash: ({ tick }) => (tick.value > 0 ? [5, 5] : false) }),
            display: false,
          },
          grid: {
            ...(type === "horizontal" && { display: false }),
            ...(type === undefined && {
              color: ({ tick }) => (tick.value > 0 ? "#C9DDF9" : "#57595C"),
              lineWidth: ({ tick }) => (tick.value > 0 ? 1 : 2),
            }),
            drawTicks: false,
          },
        },
      },
      responsive: true,
      ...(type === "horizontal" && {
        layout: { padding: { top: 0, bottom: 0, left: 0, right: 0 } },
      }),
      ...(type === undefined && {
        layout: { padding: { top: -20, bottom: 0, left: -20, right: 0 } },
        datasets: { bar: { stack: false, borderWidth: { bottom: 10 } } },
        maintainAspectRatio: false,
      }),
      animation: false,
      plugins: {
        legend: {
          display: datasets.length < 1,
          labels: {
            boxHeight: 24,
            boxWidth: 24,
            padding: 24,
            font: { size: 14, family: "Roboto", lineHeight: 20 },
            borderRadius: 4,
            useBorderRadius: true,
          },
        },
        tooltip: { enabled: false, external: externalTooltipHandler },
        htmlLegend: { containerID: "legend-container" },
        datalabels: {
          display: type === "horizontal",
          formatter: value => `${(value.raw * 100).toFixed(2)}%`,
          font: { weight: "bold" },
          color: v => {
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
              v.dataset.backgroundColor,
            );

            return parseInt(result[1], 16) * 0.299 +
              parseInt(result[2], 16) * 0.587 +
              parseInt(result[3], 16) * 0.114 >
              186
              ? "#57595C"
              : "#F6F9FD";
          },
        },
      },
    },
    data: {
      datasets: datasets.map(el => ({
        ...(el.props?.type === "percentage" && {
          borderDash: [10, 5],
          borderWidth: 2,
          pointBorderColor: "#ffffff",
          pointBorderWidth: 2.5,
          pointHoverBorderWidth: 2.5,
          pointRadius: 7,
          pointHoverRadius: 7,
        }),
        ...el,
      })),
    },
  });

  return destroy;
};
