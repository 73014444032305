<template>
  <div class="promo-deeplink">
    <div class="deeplink-roku" v-if="model.device_type === 'roku_os'">
      <v-select
        outlined
        dense
        background-color="#fff"
        placeholder="Select product id"
        v-model="getMediaType"
        :items="mediaTypes"
        label="Media Types"
        hide-details
      ></v-select>
      <v-text-field
        outlined
        dense
        hide-details
        label="Content ID"
        class="roku-content-id"
        v-model.trim="getContentId"
        name="content_id"
        style="padding: 5px 0 2px"
      ></v-text-field>
    </div>
    <div v-else>
      <v-text-field
        outlined
        dense
        hide-details
        label="Deep Link"
        v-model.trim="getDeeplink"
        name="deeplink"
        style="padding: 5px 0 2px"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "RfRetentionActionsDeeplink",
  props: ["app", "actions", "model"],
  data() {
    return {
      contentId: "",
      mediaType: null,
      deeplinkHash: null,
      deeplink: "",
      mediaTypes: [
        { value: "", text: "None Selected" },
        { value: "movie", text: "Movie" },
        { value: "episode", text: "Episode" },
        { value: "season", text: "Season" },
        { value: "series", text: "Series" },
        { value: "shortFormVideo", text: "Short Form Video" },
        { value: "special", text: "Special" },
        { value: "live", text: "Live" },
      ],
    };
  },
  computed: {
    deeplinkToHash() {
      if (this.deeplinkHash) return this.deeplinkHash;
      return this.initDeepLinkHash();
    },
    getMediaType: {
      get() {
        if (this.mediaType) return this.mediaType;
        return this.deeplinkToHash.mediaType || "";
      },
      set(value) {
        this.mediaType = value;
      },
    },
    getContentId: {
      get() {
        if (this.contentId) return this.contentId;
        return this.deeplinkToHash.contentId || "";
      },
      set(value) {
        this.contentId = value;
        if (!value.length) {
          // watching content id did not react when highlighting the whole value and pressing delete, so just move logic here
          this.$delete(this.deeplinkHash, "contentId");
        } else {
          this.deeplinkHash = {
            ...this.deeplinkToHash,
            contentId: value,
          };
        }
      },
    },
    getDeeplink: {
      get() {
        if (this.deeplink) return this.deeplink;
        const deeplink = this.actions.rf_settings_deeplink;
        if (!deeplink || !deeplink.length) return null;
        return decodeURIComponent(deeplink);
      },
      set(value) {
        this.deeplink = value;
        if (!value) this.actions.rf_settings_deeplink = value; // blank values not being picked up by watcher
      },
    },
  },
  watch: {
    mediaType(mediaType) {
      this.deeplinkHash = { ...this.deeplinkToHash, mediaType };
      if (!mediaType) {
        this.$delete(this.deeplinkHash, "mediaType");
      } else {
        this.deeplinkHash = { ...this.deeplinkToHash, mediaType };
      }
    },
    deeplinkHash(to) {
      const deeplink = Object.entries(to)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join("&");
      this.actions.rf_settings_deeplink = deeplink;
    },
    deeplink(to) {
      const [url, params] = to.split("?");
      if (params) {
        const encodedParams = params
          .split("&")
          .map(item => {
            const [k, v] = item.split("=");
            if (k && v) {
              const encodedKey = encodeURIComponent(k);
              const encodedVal = encodeURIComponent(v);
              return `${encodedKey}=${encodedVal}`;
            }
            return "";
          })
          .join("&");
        this.actions.rf_settings_deeplink = `${url}?${encodedParams}`;
      } else {
        this.actions.rf_settings_deeplink = url;
      }
    },
  },
  methods: {
    initDeepLinkHash() {
      const deeplink = this.actions.rf_settings_deeplink;
      if (!deeplink?.length) return {};
      const deeplinkItems = decodeURIComponent(deeplink).split("&");
      const queryToHash = deeplinkItems.reduce((h, item) => {
        const [k, v] = item.split("=");
        if (k && v) {
          h[k] = v;
        }
        return h;
      }, {});
      return queryToHash;
    },
  },
  mounted() {
    const hash = this.initDeepLinkHash(); // have to init this value
    if (Object.entries(hash).length) this.deeplinkHash = hash; // only use the hash if there are values in query format
  },
};
</script>
<style lang="scss" scoped>
.deeplink-roku {
  display: flex;
  flex-direction: row;
  .roku-content-id {
    padding: 0 !important;
  }
  > div.v-input {
    flex: 1;
    padding-right: 16px;
  }
  > div.v-input:last-child {
    flex: 1;
    padding-right: 0px;
  }
}
</style>
