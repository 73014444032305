import { mapActions, mapState } from "vuex";
import RoleMixin from "@/utils/RoleMixin";

export default {
  mixins: [RoleMixin],
  computed: {
    ...mapState({
      user: state => state.apps.currUser,
      app: state => state.apps.currApp,
    }),
    demoFlagOn() {
      if (this.app && this.app.flags) {
        return !!this.app.flags.demo;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["updateRecentPathActivity"]),
    updatePathActivity(appId, pathId) {
      if (!this.user) return;
      if (this.isSuperAdminRoleMixin && !this.demoFlagOn) return;

      const timestamp = parseInt(new Date() / 1000); // epoch time in seconds
      this.updateRecentPathActivity({
        appId,
        pathId,
        userId: this.user.id,
        timestamp,
      });
    },
  },
};
