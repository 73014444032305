import Vue from "vue";
import MetricsUtils from "@/utils/MetricsUtils";
import { cloneDeep, merge } from "lodash-es";
import ApiApps from "@/apis/ApiApps";
import ApiSegments from "@/apis/ApiSegments";
import ApiActions from "@/apis/ApiActions";
import ApiCompanies from "@/apis/ApiCompanies";
import ApiOrgs from "@/apis/ApiOrgs";
import ApiJobs from "@/apis/ApiJobs";
import ApiUsers from "@/apis/ApiUsers";
import ApiMetrics from "@/apis/ApiMetrics";
import ApiCustomMetrics from "@/apis/ApiCustomMetrics";
import ApiPaths from "@/apis/ApiPaths";
import ApiPathGroups from "@/apis/ApiPathGroups";
import ApiExperiments from "@/apis/ApiExperiments";
import ApiSequences from "@/apis/ApiSequences";
import ApiPipelines from "@/apis/ApiPipelines";
import VuexUtils from "@/utils/VuexUtils";
import ConduitApiUsers from "@/apis/ConduitApiUsers";
import ApiTriggers from "@/apis/ApiTriggers";
import ApiCustomDevices from "@/apis/ApiCustomDevices";
import ApiDataSources from "@/apis/ApiDataSources";
import ApiClientActions from "@/apis/ApiClientActions";
import ApiConnectorActions from "@/apis/ApiConnectorActions";
import ApiCustomFields from "@/apis/ApiCustomFields";
import { catchable } from "@/pinia/piniaUtils";
import { useToastsStore } from "@/pinia/toastsStore";

function getUserFilterSettings(state, appId) {
  const userSettings = state.currUser.settings || {};
  const appSettings = userSettings[appId] || {};
  const filterItems = appSettings.rf_filter_settings || {};
  const filter = filterItems.promotionsDateRange || "last_seven_days";
  const range = filterItems.promotionsCustomDateRange;
  return { range, filter };
}

function getCustomFieldType(fieldType) {
  const fieldTypes = {
    custom: "segmentCustomFields",
    system: "segmentSystemFields",
  };
  return fieldTypes[fieldType];
}

export default {
  state: {
    // please keep in alphabetical order
    actionGroupsLoading: {},
    actions: [],
    apps: [],
    orgs: [],
    jobs: [],
    clientActions: [],
    companies: [],
    connectorActions: [],
    currUser: null,
    currApp: null,
    currAppActivities: [],
    currAppAwsCredentials: {},
    currEndUserActions: {},
    currentEndUserResults: [],
    currExperiment: null,
    currLanguage: null,
    currPath: null,
    currPathActivities: [],
    currPathCustomMetrics: {},
    currExperimentCustomMetrics: {},
    currPathGroup: null,
    currPathGroupActivities: [],
    currPathMetrics: {},
    currPathMetricsId: null,
    currPathActionGroupMetrics: {},
    currPipeline: null,
    currPipelineMetrics: {},
    currSegment: null,
    currSegmentActivities: [],
    currSegmentMetrics: {},
    currSegmentMetricsUsage: "users",
    currSequence: null,
    currTrigger: null,
    customDevices: [],
    customGoalInteraction: "accepted",
    customMetrics: [],
    currAppConnectors: {},
    dataSources: [],
    docsUrl: null,
    experiments: [],
    experimentsIsExtended: false,
    idToken: null,
    isImpersonating: false,
    isUserLoggedIn: false,
    pathGroups: [],
    pathsCohortMetrics: {},
    pathExperimentMetrics: {},
    pathsLoading: {},
    pathMetrics: {},
    pipelines: [],
    pushEventOptions: [],
    recentPromos: [],
    retentions: [],
    retentionExperimentMetrics: {},
    retentionMetrics: {},
    dashboardRetentionMetrics: {},
    segments: [],
    segmentsIsExtended: false,
    segmentMetrics: {},
    segmentSystemFields: [],
    segmentCustomFields: [],
    sequenceMetrics: {},
    sequences: [],
    subnavOpenStatus: true,
    triggers: [],
    users: [],
  },
  getters: {
    isCurrExperimentRunning: state => state.currExperiment?.is_active,
    promotionMetrics(state) {
      return Object.entries(state.retentionMetrics).reduce((metricsKeys, [key, value]) => {
        const data = value.data.filter(
          item => ["video", "retention_modal"].indexOf(item.path_type) > -1,
        );
        metricsKeys[key] = { ...value, data };
        return metricsKeys;
      }, {});
    },
    promotions(state) {
      return state.retentions.filter(
        item => ["video", "retention_modal"].indexOf(item.path_type) > -1,
      );
    },
    placementMetrics(state) {
      return Object.entries(state.retentionMetrics).reduce((metricsKeys, [key, value]) => {
        metricsKeys[key] = value;
        return metricsKeys;
      }, {});
    },
    canManageCompany(state) {
      return state.currUser?.role === "super_admin";
    },
    currOrg(state) {
      if (state.currApp && state.currApp.id) {
        if (state.currUser && state.currUser.settings[state.currApp.id]) {
          const key = state.currUser.settings[state.currApp.id].org_key;
          const org = (state.orgs || []).find(item => item.key === key);
          if (org) return org;
        }
      }
      return { key: null, id: null, name: null };
    },
    currAppUserOrg(state) {
      if (state.currApp && state.currApp.id) {
        if (state.currUser && state.currUser.apps.length) {
          const app = state.currUser.apps.find(({ id }) => id === state.currApp.id);
          if (app) {
            const org = state.orgs.find(({ id }) => id === app.org_id);
            if (org) return org;
          }
        }
      }
      return { key: null, id: null, name: null };
    },
    editableOrgs(state, getters) {
      if (getters.canManageCompany) {
        return state.orgs.map(org => org.id);
      }
      if (getters.currAppUserOrg && getters.currAppUserOrg.id) {
        return [getters.currAppUserOrg.id];
      }
      return [];
    },
    currPathCustomDevices: state => state.currPath?.custom_devices || [],
    activeJobs: state =>
      state.jobs.filter(job => job.status === "pending" || job.status === "processing"),
  },
  mutations: {
    mutateCreateCompany(state, company) {
      state.companies.push(company);
    },
    mutateCompanies(state, companies) {
      state.companies = companies;
    },
    mutateCreateOrg(state, org) {
      state.orgs = [...state.orgs, org];
    },
    mutateUpdateOrg(state, org) {
      state.orgs = state.orgs.map(item => {
        if (item.id === org.id) return org;
        return item;
      });
    },
    mutateOrgs(state, orgs) {
      state.orgs = orgs;
    },
    mutateDeleteOrg(state, orgId) {
      const index = state.orgs.findIndex(item => {
        return item.id === orgId;
      });
      if (index > -1) {
        state.orgs.splice(index, 1);
      }
    },

    mutateJobs(state, jobs) {
      state.jobs = jobs;
    },
    mutateCustomMetrics(state, metrics) {
      state.customMetrics = metrics;
    },
    mutateInviteUser(state, user) {
      state.users.push(user);
    },
    mutateDeleteUser(state, userId) {
      const index = state.users.findIndex(item => {
        return item.id === userId;
      });
      if (index > -1) {
        state.users.splice(index, 1);
      }
    },
    mutateUsers(state, users) {
      state.users = users;
    },
    mutateUpdateUserAuth(state, user) {
      const currUser = user || (user && user.length) ? user : { id_token: "" };
      state.currUser = currUser;
      state.idToken = currUser.id_token;
      if (currUser.impersonator) {
        state.isImpersonating = true;
        localStorage.setItem("impersonator", currUser.impersonator);
      } else {
        state.isImpersonating = false;
        localStorage.removeItem("impersonator");
      }
      localStorage.setItem("id_token", currUser.id_token);
      if (user) {
        state.isUserLoggedIn = true;
      }
    },
    mutateUpdateUserRules(state, user) {
      if (user) {
        if (localStorage.getItem("impersonator")) {
          state.isImpersonating = true;
        }
        if (localStorage.getItem("id_token")) {
          state.idToken = localStorage.getItem("id_token");
        }
        state.isUserLoggedIn = true;
        state.currUser = user;
      }
    },
    mutateDestroyUserAuth(state) {
      state.idToken = null;
      localStorage.removeItem("id_token");
      state.isImpersonating = false;
      localStorage.removeItem("impersonator");
    },
    mutateUpdateUser(state, user) {
      const index = state.users.findIndex(item => {
        return item.id === user.id;
      });
      Vue.set(state.users, index, user);
    },
    mutateUpdateCurrUser(state, user) {
      state.currUser = user;
    },
    mutateApps(state, apps) {
      state.apps = apps;
    },
    mutateCurrAppActivities(state, activities) {
      state.currAppActivities = activities;
    },
    mutateCurrAppAwsCredentials(state, credentials) {
      state.currAppAwsCredentials = credentials;
    },
    mutateSegments(state, segments) {
      state.segments = segments;
      state.segmentsIsExtended = false;
    },
    mutateSegmentsExtended(state, segments) {
      const idsWithData = segments.reduce((hash, item) => {
        hash[item.id] = item.data;
        return hash;
      }, {});
      state.segments = state.segments.map(item => {
        const data = Object.entries(item.data).reduce((h, [key, value]) => {
          h[key] = {
            ...value,
            ...idsWithData[item.id][key],
          };
          return h;
        }, {});

        item.data = data;
        return item;
      });
      state.segmentsIsExtended = true;
    },
    mutateSegmentMetrics: (state, { value, extended }) =>
      extended
        ? (state.segmentMetrics = { ...state.segmentMetrics, ...value })
        : (state.segmentMetrics = value),
    mutateSequenceMetrics: (state, { value, extended }) =>
      extended
        ? (state.sequenceMetrics = { ...state.sequenceMetrics, ...value })
        : (state.sequenceMetrics = value),
    mutateRetentionMetrics: (state, { value, extended }) =>
      extended
        ? (state.retentionMetrics = { ...state.retentionMetrics, ...value })
        : (state.retentionMetrics = value),
    mutateRetentionExperimentMetrics: (state, { value, extended }) =>
      extended
        ? (state.retentionExperimentMetrics = { ...state.retentionExperimentMetrics, ...value })
        : (state.retentionExperimentMetrics = value),
    mutateDashboardRetentionMetrics: (state, v) => (state.dashboardRetentionMetrics = v),
    mutateActions(state, actions) {
      state.actions = actions;
    },
    mutateCurrentSegment(state, { segment, activities, metrics }) {
      state.currSegment = segment;
      state.currSegmentActivities = activities;
      if (metrics) {
        state.currSegmentMetrics = metrics;
      }
    },
    mutateCurrentSegmentMetrics(state, { metrics }) {
      state.currSegmentMetrics = VuexUtils.combineMetrics(state.currSegmentMetrics, metrics);
    },
    mutateCurrSegmentMetricsUsage(state, usageLabel) {
      state.currSegmentMetricsUsage = usageLabel;
    },
    mutateUpdateSegment(state, { segment, activities }) {
      state.currSegment = segment;
      state.currSegmentActivities = activities;
      state.segments = state.segments.map(seg => {
        if (seg.id === state.currSegment.id) {
          state.currSegment.data = seg.data;
          return state.currSegment;
        }
        return seg;
      });
    },
    mutateCreateSegment(state, { segment, activities, metrics }) {
      state.currSegment = segment;
      state.currSegmentActivities = activities;
      if (metrics) {
        state.currSegmentMetrics = metrics;
      }
      state.segments = [...state.segments, segment];
    },
    mutateDeleteSegment(state, segId) {
      state.currSegment = null;
      state.segments = state.segments.filter(seg => seg.id !== segId);
    },
    mutateCreateApp(state, app) {
      state.apps = [...state.apps, app];
      state.currApp = app;
    },
    mutateClearApp(state) {
      state.currApp = null;
    },
    mutateUpdateApp(state, app) {
      const index = state.apps.findIndex(item => {
        return item.id === app.id;
      });

      state.currApp = app;
      state.docsUrl = app.docs;
      Vue.set(state.apps, index, app);
    },
    mutateDeleteApp(state, appId) {
      state.apps = state.apps.filter(app => app.id !== appId);
    },
    mutateDeleteAppCustomField(state, { fieldId, fieldType }) {
      const key = getCustomFieldType(fieldType);
      const fields = state[key];
      if (fields === undefined || fields === null) return;
      state[key] = fields.filter(item => item.id !== fieldId);
    },
    mutateUpdateAppCustomField(state, { customField, fieldType }) {
      const key = getCustomFieldType(fieldType);

      const fields = state[key];
      if (fields === undefined || fields === null) return;
      const fieldIndex = fields.findIndex(item => item.id === customField.id);

      if (fieldIndex > -1) {
        const copyArray = [...fields];
        copyArray[fieldIndex] = customField;
        state[key] = copyArray;
      } else {
        state[key].push(customField);
      }
    },
    mutateAppCustomFields(state, { customFields, fieldType }) {
      const key = getCustomFieldType(fieldType);
      state[key] = customFields;
    },
    mutatePathMetrics(state, pathMetrics) {
      state.pathMetrics = pathMetrics;
    },
    mutatePathMetricsExtended(state, pathMetrics) {
      state.pathMetrics = { ...state.pathMetrics, ...pathMetrics };
    },
    mutatePathExperimentMetrics(state, pathExperimentMetrics) {
      state.pathExperimentMetrics = pathExperimentMetrics;
    },
    mutatePathExperimentMetricsExtended(state, pathExperimentMetrics) {
      state.pathExperimentMetrics = {
        ...state.pathExperimentMetrics,
        ...pathExperimentMetrics,
      };
    },
    mutatePathGroups(state, pathGroups) {
      state.pathGroups = pathGroups || [];
    },
    mutateUpdatePathGroup(state, { pathGroup, activities }) {
      state.currPathGroup = pathGroup;
      state.currPathGroupActivities = activities;
      state.pathGroups = state.pathGroups.map(pg => {
        if (pg.id === pathGroup.id) return pathGroup;
        return pg;
      });
    },
    mutateUpdatePathGroupPath(state, { path }) {
      if (state.currPathGroup?.paths) {
        const paths = state.currPathGroup.paths.map(p => (p.id === path.id ? path : p));
        state.currPathGroup = { ...state.currPathGroup, paths };
      }
      state.pathGroups = state.pathGroups.map(pg => {
        pg.paths = pg.paths.map(p => (p.id === path.id ? path : p));
        return pg;
      });
    },

    mutateRetentions(state, retentions) {
      state.retentions = retentions;
      if (state.currPath) {
        const newItem = state.retentions.find(item => item.id === state.currPath.id);
        if (newItem) state.currPath = newItem;
      }
    },
    mutateRetentionsExtended(state, retentions) {
      state.retentions = VuexUtils.combineModelListMetrics(state.retentions, retentions);
      if (state.currPath) {
        const newItem = state.retentions.find(item => item.id === state.currPath.id);
        if (newItem) state.currPath = newItem;
      }
    },
    mutateCurrentPath(state, { path, activities }) {
      state.currPath = path;
      if (state.currPath) {
        state.currPathGroup = state.pathGroups.find(
          item => item.id === state.currPath.path_group.id,
        );
      }
      state.currPathActivities = activities;

      if (state.retentions.length > 0) {
        const hasPath = state.retentions.find(p => p.id === path.id);
        if (!hasPath) {
          state.retentions = [...state.retentions, path];
        }
      }
    },
    mutateCurrentPathMetrics(state, { metrics, id }) {
      state.currPathMetricsId = id;
      state.currPathMetrics = VuexUtils.combineMetrics(state.currPathMetrics, metrics);
    },
    mutateCurrentPathCustomMetrics(state, { customMetrics }) {
      state.currPathCustomMetrics = VuexUtils.combineMetrics(
        state.currPathCustomMetrics,
        customMetrics,
      );
    },
    mutateCurrentExperimentCustomMetrics(state, { customMetrics }) {
      state.currExperimentCustomMetrics = VuexUtils.combineMetrics(
        state.currExperimentCustomMetrics,
        customMetrics,
      );
    },
    mutatePathsCohortMetrics(state, { cohortMetrics, id, actionGroupId }) {
      const newMetrics = {};
      if (actionGroupId) {
        newMetrics[`${id}:${actionGroupId}`] = cohortMetrics;
      } else {
        newMetrics[id] = cohortMetrics;
      }

      state.pathsCohortMetrics = merge({}, state.pathsCohortMetrics, newMetrics);
    },
    mutateUpdateActionGroupMetrics(state, actionGroupMetrics) {
      state.currPathActionGroupMetrics = actionGroupMetrics;
    },
    mutateUpdateCurrentActionGroupMetrics(state, actionGroupMetrics) {
      state.currPathActionGroupMetrics = {
        ...state.currPathActionGroupMetrics,
        ...actionGroupMetrics,
      };
    },
    mutateUpdatePath(state, { path, activities, overwriteData }) {
      state.currPath = path;
      state.currPathActivities = activities;
      state.retentions = state.retentions.map(pa => {
        if (pa.id === path.id) {
          if (!overwriteData) path.data = pa.data; // keep existing metrics data
          return path;
        }
        return pa;
      });
      state.recentPromos = state.recentPromos.map(item => {
        if (item.id === path.id) return path;
        return item;
      });
    },
    mutateDeletePath(state, pathId) {
      state.currPath = null;
      state.retentions = state.retentions.filter(pa => pa.id !== pathId);
    },
    mutateResetPath(state) {
      state.currPath = null;
    },
    mutateDeletePathGroup(state, pathGroupId) {
      state.currPathGroup = null;
      state.pathGroups = state.pathGroups.filter(pa => pa.id !== pathGroupId);
      state.retentions = state.retentions.filter(
        retention => retention.path_group_id !== pathGroupId,
      );
    },
    mutateDeletePathGroupPath(state, pathId) {
      if (state.currPathGroup?.paths) {
        const paths = state.currPathGroup.paths.filter(p => p.id !== pathId);
        state.currPathGroup = { ...state.currPathGroup, paths };
      }
      state.pathGroups = state.pathGroups.map(pg => {
        pg.paths = pg.paths.filter(p => p.id !== pathId);
        return pg;
      });
    },
    mutateCreatePathGroup(state, { pathGroup, activities }) {
      state.currPathGroup = pathGroup;
      state.currPathGroupActivities = activities;
      state.pathGroups = [...state.pathGroups, pathGroup];
      state.retentions = [...state.retentions, ...pathGroup.paths];
    },
    mutateCurrentPathGroup(state, { pathGroup, activities }) {
      if (state.currPathGroup?.id === pathGroup?.id) {
        const paths = VuexUtils.combineModelListMetrics(state.currPathGroup.paths, pathGroup.paths);
        pathGroup.paths = paths;
      }
      state.currPathGroup = pathGroup;
      state.currPathGroupActivities = activities;
    },
    mutateCurrExperiment(state, experiment) {
      state.currExperiment = experiment;
    },
    mutateExperiments(state, experiments = []) {
      state.experiments = experiments;
      state.currExperiment = null;
      const runningExperiment = experiments.find(({ is_completed }) => !is_completed);
      if (runningExperiment) {
        state.currExperiment = runningExperiment;
      }
    },
    mutateSetCurrentExperimentRunning(state, isRunning) {
      if (!isRunning) {
        state.currExperiment = null;
      }
    },
    mutateCurrentEndUsersResults(state, users) {
      state.currentEndUserResults = users;
    },
    mutateCurrEndUserActions(state, actions) {
      state.currEndUserActions = actions;
    },
    mutateSequences(state, sequences) {
      state.sequences = sequences;
    },
    mutateCreateSequence(state, sequence) {
      state.currSequence = sequence;
    },
    mutateCurrentSequence(state, { sequence }) {
      state.currSequence = sequence;
      state.sequences = state.sequences.map(existingSequence => {
        if (existingSequence.id === sequence.id) return sequence;
        return existingSequence;
      });
    },
    mutatePathTriggers(state, { triggers }) {
      if (state.currPath) {
        state.currPath.triggers = triggers;
      }

      // update retentions to include trigger changes in currPath
      state.retentions = state.retentions.map(pa => {
        if (state.currPath && pa.id === state.currPath.id) {
          return state.currPath;
        }
        return pa;
      });
    },
    mutatePipelines(state, { pipelines }) {
      state.pipelines = pipelines;
    },
    mutateCurrentPipeline(state, { pipeline }) {
      state.currPipeline = pipeline;
      state.pipelines = state.pipelines.map(existingPipe => {
        if (existingPipe.id === pipeline.id) return pipeline;
        return existingPipe;
      });
    },
    mutateCurrentPipelineMetrics(state, { metrics }) {
      state.currPipelineMetrics = metrics;
    },
    mutateCreatePipeline(state, { pipeline }) {
      state.currPipeline = pipeline;
      state.pipelines = [...state.pipelines, pipeline];
    },
    mutateDeletePipeline(state, { pipelineId }) {
      state.pipelines = state.pipelines.filter(pipeline => pipeline.id !== pipelineId);
      [state.currPipeline] = state.pipelines;
    },
    mutateUpdateTriggers(state, { triggers }) {
      state.triggers = triggers;
    },
    mutateUpdateTrigger(state, { trigger }) {
      state.currTrigger = trigger;
      const triggerIndex = state.triggers.map(t => t.id).indexOf(trigger.id);
      if (triggerIndex >= 0) {
        state.triggers[triggerIndex] = trigger;
      } else {
        state.triggers.push(trigger);
      }
    },
    mutateDeleteTrigger(state, { triggerId }) {
      state.triggers = state.triggers.filter(trigger => trigger.id !== triggerId);
    },
    mutateRefreshPathGroups(state) {
      state.pathGroups = cloneDeep(state.pathGroups);
    },
    mutateDeleteSequence(state, { sequenceId }) {
      state.currSequence = null;
      state.sequences = state.sequences.filter(sequence => sequence.id !== sequenceId);
    },
    mutateClonePath(state, { path, pathGroupId }) {
      if (pathGroupId) {
        state.pathGroups = state.pathGroups.map(pathGroup => {
          if (pathGroup.id === pathGroupId) {
            pathGroup.paths.push(path);
          }
          return pathGroup;
        });
      }
      state.retentions = [...state.retentions, path];
    },
    mutateCloneSequence(state, { sequence }) {
      state.sequences = [...state.sequences, sequence];
    },
    mutateRecentPromos(state, promos) {
      state.recentPromos = promos;
    },
    mutateSubnavOpenStatus(state, status) {
      state.subnavOpenStatus = status;
    },
    mutateCustomGoalInteraction(state, interaction) {
      state.customGoalInteraction = interaction;
    },
    mutateDeleteExperiment(state, experimentId) {
      state.currExperiment = null;
      state.experiments = state.experiments.filter(exp => exp.id !== experimentId);
    },
    mutatePinpointEmail(state, email) {
      if (state.currApp) state.currApp.pinpoint_sender_email = email;
    },
    mutateAppConnectors(state, connectors) {
      state.currAppConnectors = connectors;
    },
    mutateSdkActive(state, sdk) {
      state.currApp.flags.sdk_active = sdk;
    },
    mutateCurrLanguage(state, language) {
      state.currLanguage = language;
    },
    mutateCurrentCustomDevices(state, devices) {
      state.customDevices = devices;
    },
    mutateDataSources(state, dataSources) {
      state.dataSources = dataSources;
    },
    mutateCreateDataSource(state, dataSource) {
      state.dataSources.push(dataSource);
    },
    mutateDeleteDataSource(state, dataSourceId) {
      state.dataSources = state.dataSources.filter(ds => ds && ds.id !== dataSourceId);
    },
    mutateUpdateDataSource(state, dataSource) {
      state.dataSources = state.dataSources.map(item => {
        if (item && item.id === dataSource.id) {
          return dataSource;
        }
        return item;
      });
    },
    mutateClientActions(state, actions) {
      state.clientActions = actions;
    },
    mutateDeleteClientAction(state, actionId) {
      state.clientActions = state.clientActions.filter(action => action.id !== actionId);
    },
    mutateUpdateClientAction(state, action) {
      const actionIndex = state.clientActions.findIndex(ca => ca.id === action.id);
      if (actionIndex >= 0) {
        state.clientActions[actionIndex] = action;
      } else {
        state.clientActions.push(action);
      }
    },
    mutateConnectorActions(state, actions) {
      state.connectorActions = actions;
    },
    mutateDeleteConnectorAction(state, actionId) {
      state.connectorActions = state.connectorActions.filter(action => action.id !== actionId);
    },
    mutateUpdateConnectorAction(state, action) {
      const actionIndex = state.connectorActions.findIndex(ca => ca.id === action.id);
      if (actionIndex >= 0) {
        state.connectorActions[actionIndex] = action;
      } else {
        state.connectorActions.push(action);
      }
    },
    mutatePushEventOptions: (state, v) => (state.pushEventOptions = v || []),
  },
  actions: {
    refreshPathGroups({ commit }) {
      commit("mutateRefreshPathGroups");
    },
    async inviteUser({ commit, dispatch }, { user, companyId }) {
      try {
        commit("mutateInviteUser", await ApiUsers.inviteUser({ user, companyId }));
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async updateUser({ commit, dispatch }, { user, companyId, updatePermissions }) {
      try {
        commit(
          "mutateUpdateUser",
          await ApiUsers.updateUser({
            user,
            companyId,
            updatePermissions,
          }),
        );
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async updateCurrUser({ commit, dispatch }, { user }) {
      try {
        commit("mutateUpdateCurrUser", await ApiUsers.updateUser({ user }));
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async register({ dispatch }, params) {
      try {
        return await ApiUsers.register(params);
      } catch (error) {
        dispatch("sendError", error);
      }
      return null;
    },
    async confirmInvitedUser({ dispatch }, { invitation_token, password, password_confirmation }) {
      try {
        return await ApiUsers.confirmInvitedUser({
          invitation_token,
          password,
          password_confirmation,
        });
      } catch (error) {
        dispatch("sendError", error);
      }
      return null;
    },
    loginUser: async ({ commit }, { email, password }) =>
      commit("mutateUpdateUserAuth", await ApiUsers.loginUser({ email, password })),
    async getUser({ commit, dispatch }) {
      try {
        commit("mutateUpdateUserRules", await ApiUsers.getUser());
      } catch (error) {
        dispatch("sendError", await error);
      }
    },
    async logoutUser({ commit, dispatch }, { redirect = "/login" } = {}) {
      try {
        await ApiUsers.logoutUser();
        commit("mutateDestroyUserAuth");
        redirect && (location.href = redirect);
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async resetPasswordUser(
      { dispatch },
      { reset_password_token, password, password_confirmation },
    ) {
      try {
        return await ApiUsers.resetPasswordUser({
          reset_password_token,
          password,
          password_confirmation,
        });
      } catch (error) {
        dispatch("sendError", error);
      }
      return null;
    },
    async deleteUser({ commit, dispatch }, { userId, isAdminingCompany, appId }) {
      try {
        commit("mutateDeleteUser", await ApiUsers.deleteUser({ userId, isAdminingCompany, appId }));
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async impersonateUser({ commit, dispatch }, { userId }) {
      try {
        commit("mutateUpdateUserAuth", await ApiUsers.impersonate({ userId }));
        window.location = "/";
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async getUsers({ commit, dispatch }, companyId) {
      try {
        commit("mutateUsers", await ApiUsers.getUsers(companyId));
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async createCompany(
      { commit, dispatch },
      { companyName, awsMarketplaceCustomerId, awsMarketplaceEnabled },
    ) {
      try {
        commit(
          "mutateCreateCompany",
          await ApiCompanies.createCompany(
            companyName,
            awsMarketplaceCustomerId,
            awsMarketplaceEnabled,
          ),
        );
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async getCompanies({ commit, dispatch }) {
      try {
        commit("mutateCompanies", await ApiCompanies.getCompanies());
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async createOrg({ commit, dispatch }, { appId, name }) {
      try {
        commit("mutateCreateOrg", await ApiOrgs.createOrg(appId, { name }));
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async updateOrg({ commit, dispatch }, { appId, org }) {
      try {
        commit("mutateUpdateOrg", await ApiOrgs.updateOrg(appId, org));
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async getOrgs({ commit, dispatch }, appId) {
      try {
        commit("mutateOrgs", await ApiOrgs.getOrgs(appId));
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async deleteOrg({ commit, dispatch }, { appId, orgId, newOrgId }) {
      dispatch("setLoading", true);
      try {
        await ApiOrgs.deleteOrg(appId, orgId, newOrgId);
        commit("mutateDeleteOrg", orgId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async createJob({ commit, dispatch }, { appId, metrics_period, path_ids }) {
      try {
        await ApiJobs.createJob(appId, { ...metrics_period, path_ids });
        commit("mutateJobs", await ApiJobs.getJobs(appId));
        useToastsStore().create({
          type: "success",
          body: `Export in progress, please visit Settings > Download Data to access. May take up to 15 minutes.`,
          timeout: 5000,
          bodyTransform: null,
        });
      } catch (error) {
        useToastsStore().create({
          type: "error",
          body: `${error.message}. Visit Settings > Downloads to access.`,
          timeout: 5000,
          bodyTransform: null,
        });
      }
    },
    async getJobs({ commit, dispatch }, { appId }) {
      try {
        commit("mutateJobs", await ApiJobs.getJobs(appId));
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async createCustomMetric({ dispatch }, customMetric) {
      dispatch("setLoading", true);
      try {
        await ApiCustomMetrics.createCustomMetric(customMetric);
        dispatch("getCustomMetrics");
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async updateCustomMetric({ dispatch }, customMetric) {
      dispatch("setLoading", true);
      try {
        await ApiCustomMetrics.updateCustomMetric(customMetric);
        dispatch("getCustomMetrics");
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async deleteCustomMetric({ dispatch }, customMetric) {
      dispatch("setLoading", true);
      try {
        await ApiCustomMetrics.deleteCustomMetric(customMetric);
        dispatch("getCustomMetrics");
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getCustomMetrics({ commit, dispatch }) {
      dispatch("setLoading", true);
      try {
        const metrics = await ApiCustomMetrics.getCustomMetrics();
        commit("mutateCustomMetrics", metrics);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getApps({ commit, dispatch, getters }, currAppId) {
      dispatch("setLoading", true);
      try {
        const apps = await ApiApps.getApps();
        commit("mutateApps", apps);
        if (apps && apps.length > 0) {
          const appId = currAppId || apps[0].id;
          const [app, appActivities] = await Promise.all([
            ApiApps.getApp(appId),
            ApiApps.getActivities(appId),
          ]);
          commit("mutateUpdateApp", app);
          commit("mutateCurrAppActivities", appActivities);
          commit("mutateSegments", []);
          commit("mutateRetentions", []);

          dispatch("getSegmentModel", appId);
          if (getters.canManageCompany) {
            dispatch("getAppModel");
          }
        }
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getAppDashboard({ commit, dispatch }, { appId, elasticMetrics, params }) {
      dispatch("setLoading", true);
      try {
        if (!appId) return;
        const timePeriod = params.metric_periods[0].period;
        const segmentParams =
          timePeriod === "custom"
            ? params
            : {
                metric_periods: [
                  { period: timePeriod },
                  { period: MetricsUtils.Comparisons[timePeriod] },
                ],
              };

        const promptsParams = {
          ...params,
          path_type:
            "retention_modal,video,horizontal,vertical,tile,text,widget,interstitial,email,bottom_banner",
          ...(elasticMetrics && { limit: 5, metric_periods: [params.metric_periods[0]] }),
        };
        const promptsWithoutCompare = {
          ...promptsParams,
          metric_periods: [params.metric_periods[0]],
          ...(elasticMetrics && { limit: 5 }),
        };

        const [
          dashboardRetentionMetrics,
          retentionMetrics,
          retentionExperimentMetrics,
          segmentMetrics,
          sequenceMetrics,
        ] = await Promise.all([
          ApiMetrics.getAppDashboard(appId, params),
          ApiMetrics.getRetentions(appId, promptsParams),
          ApiMetrics.getRetentionExperiments(appId, promptsWithoutCompare),
          ApiSegments.getSegment(appId, "all_users", segmentParams),
          ApiMetrics.getSequences(appId, promptsWithoutCompare),
        ]);

        const extended = params?.metric_periods;

        commit("mutateSegmentMetrics", {
          value: segmentMetrics.data.users,
          extended,
        });

        commit("mutateSequenceMetrics", { value: sequenceMetrics, extended });
        commit(
          "mutateDashboardRetentionMetrics",
          dashboardRetentionMetrics["raw-impressions-goals"],
        );
        commit("mutateRetentionMetrics", { value: retentionMetrics, extended });
        commit("mutateRetentionExperimentMetrics", { value: retentionExperimentMetrics, extended });
      } catch (error) {
        dispatch("sendError", error);
        useToastsStore().create({ type: "error", body: error.message });
      }
      dispatch("setLoading", false);
    },
    async getAppAwsCredentials({ commit, dispatch }, currAppId) {
      dispatch("setLoading", true);
      try {
        commit("mutateCurrAppAwsCredentials", await ApiApps.getAwsCredentials(currAppId));
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getSegments({ commit, dispatch }, { appId, params }) {
      dispatch("setLoading", true);
      try {
        if (params && params.metric_periods) {
          const [_, segmentMetrics, segments] = await Promise.all([
            ApiApps.getCustomMetricsStatus(appId, { data_type: "segments", ...params }),
            ApiMetrics.getSegments(appId, params),
            ApiSegments.getSegments(appId, params),
          ]);

          commit("mutateSegmentMetrics", { value: segmentMetrics, extended: true });
          commit("mutateSegmentsExtended", segments);
          commit("mutateSegments", segments);
          dispatch("getSegmentModel", appId);
        } else {
          commit("mutateSegments", await ApiSegments.getSegments(appId, params));
        }
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getActions({ commit, dispatch }, modId) {
      dispatch("setLoading", true);
      try {
        commit("mutateActions", await ApiActions.getActions(modId));
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getSegment({ commit, dispatch }, { appId, segId }) {
      dispatch("setLoading", true);
      try {
        let segment = ApiSegments.getSegment(appId, segId);
        let activities = ApiSegments.getActivities(appId, segId);
        segment = await segment;
        activities = await activities;
        commit("mutateCurrentSegment", { segment, activities });
        dispatch("setMlEnabled", segment.is_ml_enabled);
        dispatch("setMlTrait", segment.ml_target_trait);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getSegmentMetrics({ commit, dispatch }, { appId, id, params }) {
      // dispatch("setLoading", true);
      try {
        const metrics = await ApiMetrics.getOneSegment(appId, id, params);
        commit("mutateCurrentSegmentMetrics", { metrics });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async updateSegment({ commit, dispatch }, { appId, segId, modelSegment }) {
      await catchable({
        t: async () => {
          const segment = await ApiSegments.updateSegment(appId, segId, modelSegment);
          const activities = await ApiSegments.getActivities(appId, segId);
          commit("mutateUpdateSegment", { segment, activities });
          dispatch("setMlEnabled", segment.is_ml_enabled);
          dispatch("setMlTrait", segment.ml_target_trait);
          useToastsStore().create({ type: "success", body: "Segment updated successfully" });
        },
        c: e => useToastsStore().create({ type: "error", body: e.message }),
        loadable: true,
        throwable: true,
      });
    },
    async createSegment({ commit, dispatch }, { appId, modelSegment }) {
      return await catchable({
        t: async () => {
          const segment = await ApiSegments.createSegment(appId, modelSegment);
          const activities = await ApiSegments.getActivities(appId, segment.id);
          const metrics = await ApiMetrics.getOneSegment(appId, segment.id);
          const segmentMetrics = await ApiMetrics.getSegments(appId);
          commit("mutateCreateSegment", { segment, activities, metrics });
          commit("mutateSegmentMetrics", segmentMetrics);
          dispatch("setMlEnabled", segment.is_ml_enabled);
          dispatch("setMlTrait", segment.ml_target_trait);
          return segment;
        },
        c: e => useToastsStore().create({ type: "error", body: e.message }),
        loadable: true,
        throwable: true,
      });
    },
    async deleteSegment({ commit, dispatch }, { appId, segId }) {
      dispatch("setLoading", true);
      try {
        commit("mutateDeleteSegment", await ApiSegments.deleteSegment(appId, segId));
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async setPrioritySegments({ commit, dispatch }, { appId, segments }) {
      dispatch("setLoading", true);
      try {
        commit("mutateSegments", await ApiSegments.setPrioritySegments(appId, segments));
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async selectApp({ commit, dispatch }, appId) {
      dispatch("setLoading", true);
      try {
        const [app, appActivities, segments, retentions] = await Promise.all([
          ApiApps.getApp(appId),
          ApiApps.getActivities(appId),
          ApiSegments.getSegments(appId),
          ApiPaths.getRetentions(appId),
        ]);
        commit("mutateUpdateApp", app);
        commit("mutateCurrAppActivities", appActivities);
        commit("mutateSegments", segments);
        commit("mutateRetentions", retentions);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async createApp({ commit, dispatch }, modelApp) {
      dispatch("setLoading", true);
      try {
        const app = await ApiApps.createApp(modelApp);
        const appId = app.id;
        commit("mutateCreateApp", app);
        const [appActivities, segments, retentions] = await Promise.all([
          ApiApps.getActivities(appId),
          ApiSegments.getSegments(appId),
          ApiPaths.getRetentions(appId),
        ]);
        commit("mutateCurrAppActivities", appActivities);
        commit("mutateSegments", segments);
        commit("mutateRetentions", retentions);
        dispatch("getUser");
      } catch (error) {
        dispatch("sendError", error);
        dispatch("setLoading", false);
      }
      dispatch("setLoading", false);
    },
    async updateApp({ commit, dispatch }, { appObj, includeProviders }) {
      dispatch("setLoading", true);
      try {
        if (!includeProviders) {
          delete appObj.providers;
        }
        const app = await ApiApps.updateApp(appObj);
        commit("mutateUpdateApp", app);
        dispatch("getUser");
      } catch (error) {
        dispatch("sendError", error);
        dispatch("setLoading", false);
      }
      dispatch("setLoading", false);
    },
    async deleteApp({ commit, dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.deleteApp(appId);
        commit("mutateDeleteApp", appId);
        dispatch("getApps");
        dispatch("getUser");
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async syncSfmc({ dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.syncSfmc(appId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async updateAwsMarketplace({ dispatch }, { appId, clicks }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.updateAwsMarketplace(appId, clicks);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async imagewizUpdateCfDistro({ dispatch }, { appId, all = false }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.imagewizUpdateCfDistro(appId, all);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async purgeSfmc({ dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.purgeSfmc(appId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async publishSdk({ dispatch }, { appId, type = "javascript" }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.publishSdk(appId, type);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async generateApiKey({ dispatch, commit }, { appId }) {
      dispatch("setLoading", true);
      try {
        commit("mutateUpdateApp", await ApiApps.generateApiKey(appId));
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getAppCustomFields({ commit, dispatch }, { appId, fieldType }) {
      dispatch("setLoading", true);
      try {
        commit("mutateAppCustomFields", {
          customFields: await ApiCustomFields.getCustomFields(appId, fieldType),
          fieldType,
        });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async deleteAppCustomField({ commit }, { appId, fieldId, fieldType }) {
      await catchable({
        t: async () =>
          commit("mutateDeleteAppCustomField", {
            fieldId: await ApiCustomFields.deleteCustomField(appId, fieldId),
            fieldType,
          }),
        loadable: true,
        throwable: true,
      });
    },
    async updateAppCustomField({ commit, dispatch }, { appId, fieldId, model, fieldType }) {
      dispatch("setLoading", true);
      try {
        commit("mutateUpdateAppCustomField", {
          customField: await ApiCustomFields.updateCustomField(appId, fieldId, model),
          fieldType,
        });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async createAppCustomField({ commit, dispatch }, { appId, model, fieldType }) {
      dispatch("setLoading", true);
      try {
        commit("mutateUpdateAppCustomField", {
          customField: await ApiCustomFields.createCustomField(appId, model),
          fieldType,
        });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async createPathGroup({ commit }, { appId, model }) {
      await catchable({
        t: async () => {
          const { paths } = model;
          delete model.paths;
          const pathGroup = await ApiPathGroups.createPathGroup(appId, model);
          const createdPaths = paths.map(item => {
            const { custom_devices, ...newItem } = item;
            newItem.custom_device_ids = custom_devices.map(el => el.id);
            return ApiPaths.createPath(appId, {
              ...newItem,
              path_group_id: pathGroup.id,
            });
          });

          pathGroup.paths = await Promise.all(createdPaths);

          const activities = await ApiPathGroups.getActivities(appId, pathGroup.id);

          commit("mutateCreatePathGroup", { pathGroup, activities });
        },
        loadable: true,
        throwable: true,
      });
    },
    async getPathGroups({ commit }, { appId }) {
      await catchable({
        t: async () => commit("mutatePathGroups", await ApiPathGroups.getPathGroups(appId)),
        loadable: true,
      });
    },
    async updatePathGroup({ commit, state }, { appId, model, isLoading }) {
      await catchable({
        t: async () => {
          const { paths } = model;
          if (paths) {
            const updatedPaths = paths.map(item => {
              if (item.id) {
                return ApiPaths.updatePath(appId, item.id, item);
              }
              return ApiPaths.createPath(appId, {
                ...item,
                path_group_id: model.id,
              });
            });
            const updatedPathItems = await Promise.all(updatedPaths);
            if (isLoading) {
              updatedPathItems.forEach(p => {
                const modelPath = paths.find(mp => mp.id === p.id);
                if (!modelPath) {
                  // new item, update image
                  commit("mutateUpdatePath", { path: p });
                }
              });
            }
            delete model.paths;
          }
          const { range, filter } = getUserFilterSettings(state, appId);
          const pathGroup = await ApiPathGroups.updatePathGroup(appId, model, range, filter);
          const activities = await ApiPathGroups.getActivities(appId, model.id);
          commit("mutateUpdatePathGroup", { pathGroup, activities });
          commit("mutatePathTriggers", { triggers: pathGroup.triggers });
        },
        loadable: true,
        throwable: true,
      });
    },
    async getPathGroup({ commit, state }, { appId, pathGroupId }) {
      await catchable({
        t: async () => {
          const settings = getUserFilterSettings(state, appId);
          const pathGroup = await ApiPathGroups.getPathGroup(
            appId,
            pathGroupId,
            settings.range,
            settings.filter,
          );
          const activities = await ApiPathGroups.getActivities(appId, pathGroupId);
          commit("mutateCurrentPathGroup", { pathGroup, activities });
        },
        loadable: true,
      });
    },
    async deletePathGroup({ commit }, { appId, pathGroupId }) {
      await catchable({
        t: async () =>
          commit("mutateDeletePathGroup", await ApiPathGroups.deletePathGroup(appId, pathGroupId)),
        loadable: true,
        throwable: true,
      });
    },
    async deletePathGroupAndPath({ commit, dispatch }, { appId, path }) {
      commit("mutateResetPath");
      await dispatch("deletePathGroup", { appId, pathGroupId: path.path_group_id });
    },
    async getRetentions({ commit, dispatch }, { appId, params }) {
      dispatch("setLoading", true);
      try {
        if (params && params.metric_periods) {
          await ApiApps.getCustomMetricsStatus(appId, { data_type: "paths", ...params });
          const retentions = await ApiPaths.getRetentions(appId, params);
          commit("mutateRetentionsExtended", retentions);
        } else {
          const origRetentions = await ApiPaths.getRetentions(appId, params);
          commit("mutateRetentions", origRetentions);
        }
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getRetentionMetrics({ commit }, { appId, params }) {
      await catchable({
        t: async () =>
          commit("mutateRetentionMetrics", {
            value: await ApiMetrics.getRetentions(appId, params),
            extended: true,
          }),
        loadable: true,
      });
    },
    async getPathCohortMetrics({ commit, dispatch }, { appId, id, params }) {
      try {
        const cohortMetrics = await ApiMetrics.getOnePathCohort(appId, id, params);
        const actionGroupId = params.action_group_id;
        commit("mutatePathsCohortMetrics", { cohortMetrics, id, actionGroupId });
      } catch (error) {
        dispatch("sendError", error);
      }
    },
    async getPathMetrics({ commit, dispatch }, { appId, id, params }) {
      dispatch("setLoading", true);
      try {
        const metrics = await ApiMetrics.getOnePath(appId, id, params);
        commit("mutateCurrentPathMetrics", { metrics, id });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getPathCustomMetrics({ commit, dispatch }, { appId, id, params }) {
      dispatch("setLoading", true);
      try {
        const customMetrics = await ApiMetrics.getOnePathCustom(appId, id, params);
        commit("mutateCurrentPathCustomMetrics", { customMetrics });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getExperimentCustomMetrics({ commit, dispatch }, { pathId, id, params }) {
      dispatch("setLoading", true);
      try {
        const customMetrics = await ApiMetrics.getExperimentCustomMetrics(pathId, id, params);
        commit("mutateCurrentExperimentCustomMetrics", { customMetrics });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async clonePath(
      { commit, dispatch },
      { appId, pathId, pathGroupId, targetAppId, defaultCompany },
    ) {
      try {
        dispatch("setLoading", true);
        let path;
        if (targetAppId && appId !== targetAppId) {
          path = await ApiPaths.clonePathToApp(appId, pathId, targetAppId, defaultCompany);
        } else {
          path = await ApiPaths.clonePath(appId, pathId, pathGroupId);
          commit("mutateClonePath", { path, pathGroupId });
          dispatch("getPath", { appId, pathId: path.id });
          if (!pathGroupId) dispatch("getPathGroups", { appId }); // have to refresh path groups when cloning standalone item
        }
        return path;
      } catch (error) {
        dispatch("sendError", error);
        throw error;
      } finally {
        dispatch("setLoading", false);
      }
    },
    async cloneRfLitePaths({ dispatch }, { appId, path_ids }) {
      dispatch("setLoading", true);
      let paths = [];
      try {
        paths = await ApiPaths.cloneRfLitePaths(appId, { path_ids });
        dispatch("getRetentions", { appId });
        dispatch("getPathGroups", { appId });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
      return paths;
    },
    async getPath({ commit, dispatch }, { appId, pathId }) {
      dispatch("setLoading", true);
      try {
        const path = await ApiPaths.getPath(appId, pathId);
        const activities = await ApiPaths.getActivities(appId, pathId);
        commit("mutateCurrentPath", { path, activities });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async deletePath({ commit }, { appId, pathId }) {
      await catchable({
        t: async () => {
          commit("mutateDeletePath", await ApiPaths.deletePath(appId, pathId));
          commit("mutateDeletePathGroupPath", pathId);
        },
        loadable: true,
        throwable: true,
      });
    },
    async updatePath({ commit }, { appId, pathId, modelPath }) {
      await catchable({
        t: async () => {
          if (modelPath.custom_devices)
            modelPath.custom_device_ids = modelPath.custom_devices.map(({ id }) => id);
          const path = await ApiPaths.updatePath(appId, pathId, modelPath);
          const activities = await ApiPaths.getActivities(appId, pathId);
          commit("mutateUpdatePath", { path, activities });
          commit("mutateUpdatePathGroupPath", { path });
        },
        loadable: true,
        throwable: true,
      });
    },
    async setPriorityRetentions({ commit, dispatch }, { appId, pathGroups }) {
      dispatch("setLoading", true);
      try {
        commit("mutateRetentions", await ApiPaths.setPriorityRetentions(appId, pathGroups));
      } catch (error) {
        dispatch("sendError", error);
        useToastsStore().create({ type: "error", body: `${error}. Please try again.` });
      }
      dispatch("setLoading", false);
    },
    async setOrderRetentions({ commit }, { appId, paths }) {
      await catchable({
        t: async () => commit("mutateRetentions", await ApiPaths.setOrderRetentions(appId, paths)),
        loadable: true,
        throwable: true,
      });
    },
    async createExperiment({ commit, dispatch }, { pathId, modelExperiment }) {
      try {
        dispatch("setLoading", true);
        const experiment = await ApiExperiments.createExperiment(pathId, modelExperiment);
        commit("mutateCurrExperiment", experiment);
        return experiment;
      } catch (error) {
        dispatch("sendError", error);
      } finally {
        dispatch("setLoading", false);
      }
    },
    async cloneExperiment({ dispatch }, { pathId, experimentId }) {
      dispatch("setLoading", true);
      try {
        const result = await ApiExperiments.cloneExperiment(pathId, experimentId);
        dispatch("setLoading", false);
        return result;
      } catch (error) {
        dispatch("sendError", error);
        dispatch("setLoading", false);
      }
    },
    async updateExperiment({ commit, dispatch }, { pathId, expId, modelExperiment, loadingIndex }) {
      dispatch("setLoading", true);
      try {
        const experiment = await ApiExperiments.setExperiment(pathId, expId, modelExperiment);
        commit("mutateCurrExperiment", experiment);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getExperimentMetrics({ commit, dispatch }, { appId, pathId, params }) {
      dispatch("setLoading", true);
      try {
        const metrics = await ApiMetrics.getOnePathActionGroups(appId, pathId, params);
        commit("mutateUpdateCurrentActionGroupMetrics", metrics);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getExperiment({ commit, dispatch }, { pathId, expId }) {
      dispatch("setLoading", true);
      try {
        commit("mutateCurrExperiment", await ApiExperiments.getExperiment(pathId, expId));
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getExperiments({ commit, dispatch }, { pathId }) {
      try {
        dispatch("setLoading", true);
        dispatch("getExperimentModel", pathId);
        const experiments = await ApiExperiments.getExperiments(pathId);
        commit("mutateExperiments", experiments);
        return experiments;
      } catch (error) {
        dispatch("sendError", error);
      } finally {
        dispatch("setLoading", false);
      }
    },
    async runExperiment({ commit, dispatch }, { pathId, expId, isStart }) {
      dispatch("setLoading", true);
      try {
        if (isStart) await ApiExperiments.startExperiment(pathId, expId);
        else await ApiExperiments.stopExperiment(pathId, expId);
        commit("mutateSetCurrentExperimentRunning", isStart);
        await dispatch("getExperiments", { pathId });
        commit("mutateCurrExperiment", await ApiExperiments.getExperiment(pathId, expId));
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async runExperimentWithVariant({ commit, dispatch }, { appId, pathId, expId, variation }) {
      dispatch("setLoading", true);
      try {
        await ApiExperiments.stopExperimentWithWinner(pathId, expId, variation);
        commit("mutateSetCurrentExperimentRunning", false);
        await dispatch("getExperiments", { pathId });
        commit(
          "mutateUpdateActionGroupMetrics",
          await ApiMetrics.getOnePathActionGroups(appId, pathId),
        );
        commit("mutateCurrExperiment", await ApiExperiments.getExperiment(pathId, expId));
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async searchEndUsers({ commit, dispatch }, { appId, query }) {
      dispatch("setLoading", true);
      try {
        const endUsers = await ApiApps.searchEndUsers(appId, query);
        commit("mutateCurrentEndUsersResults", endUsers);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async deleteEndUser({ dispatch }, { appId, endUserId }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.deleteEndUser(appId, endUserId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async createEndUser({ dispatch }, { appId, traits }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.createEndUser(appId, traits);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async invalidateCache({ dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.invalidateCache(appId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async retryAndDeleteConnectorError({ dispatch }, { appId, pathId, connectorError }) {
      dispatch("setLoading", true);
      try {
        await ApiPaths.retryAndDeleteConnectorError(appId, pathId, connectorError);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async deleteConnectorError({ dispatch }, { appId, pathId, connectorError }) {
      dispatch("setLoading", true);
      try {
        await ApiPaths.deleteConnectorError(appId, pathId, connectorError);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getEndUserActions({ dispatch, commit }, { appId, userId }) {
      dispatch("setLoading", true);
      try {
        let actions = await ConduitApiUsers.ping(appId, userId);

        let count = 0;
        while (actions.paths && !actions.paths.length && count < 3) {
          actions = await ConduitApiUsers.ping(appId, userId);
          await new Promise(resolve => setTimeout(resolve, 3000));
          count++;
        }

        commit("mutateCurrEndUserActions", actions);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async createPinpointApp({ dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.createPinpointApp(appId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async createSampleData({ dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.createSampleData(appId);
        await new Promise(resolve => setTimeout(resolve, 100000));
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async resetPathGoals({ dispatch }, { appId, pathId }) {
      dispatch("setLoading", true);
      try {
        await ApiPaths.resetGoals(appId, pathId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },

    async deleteExperiment({ commit, dispatch }, { pathId, experimentId }) {
      dispatch("setLoading", true);
      try {
        await ApiExperiments.deleteExperiment(pathId, experimentId);
        commit("mutateDeleteExperiment", experimentId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },

    async getSequences({ commit }, { appId, params }) {
      await catchable({
        t: async () => commit("mutateSequences", await ApiSequences.getSequences(appId, params)),
        loadable: true,
      });
    },

    createSequence: async ({ commit, state }, { appId, newSequence }) =>
      await catchable({
        t: async () => {
          commit("mutateCreateSequence", await ApiSequences.createSequence(appId, newSequence));
          return state.currSequence;
        },
        loadable: true,
        throwable: true,
      }),

    async getSequence({ commit }, { appId, sequenceId, params }) {
      await catchable({
        t: async () =>
          commit("mutateCurrentSequence", {
            sequence: await ApiSequences.getSequence(appId, sequenceId, params),
          }),
        loadable: true,
      });
    },

    async deleteSequence({ commit }, { appId, sequenceId }) {
      await catchable({
        t: async () => {
          await ApiSequences.deleteSequence(appId, sequenceId);
          commit("mutateDeleteSequence", { sequenceId });
        },
        loadable: true,
        throwable: true,
      });
    },

    async updateSequence({ commit, state }, { appId, sequenceId, modelSequence }) {
      await catchable({
        t: async () => {
          const sequence = await ApiSequences.updateSequence(appId, sequenceId, modelSequence);
          const paths = sequence.paths.map(el =>
            state.currSequence?.paths.find(({ id }) => id === el.id),
          );
          commit("mutateCurrentSequence", { sequence: { ...sequence, ...(paths && { paths }) } });
        },
        loadable: true,
        throwable: true,
      });
    },
    async clearApp({ commit }) {
      commit("mutateClearApp");
    },
    async getPipelines({ commit }, { appId }) {
      await catchable({
        t: async () =>
          commit("mutatePipelines", { pipelines: await ApiPipelines.getPipelines(appId) }),
        loadable: true,
      });
    },
    async updatePipeline({ commit }, { appId, pipelineId, model }) {
      await catchable({
        t: async () =>
          commit("mutateCurrentPipeline", {
            pipeline: await ApiPipelines.updatePipeline(appId, pipelineId, model),
          }),
        loadable: true,
        throwable: true,
      });
    },
    async getPipeline({ commit }, { appId, pipelineId, params }) {
      await catchable({
        t: async () =>
          commit("mutateCurrentPipeline", {
            pipeline: await ApiPipelines.getPipeline(appId, pipelineId, params),
          }),
        loadable: true,
      });
    },
    async getPipelineMetrics({ commit }, { appId, pipelineId, params }) {
      await catchable({
        t: async () =>
          commit("mutateCurrentPipelineMetrics", {
            metrics: {
              ...(await ApiMetrics.getOnePipeline(appId, pipelineId, params)),
              pipelineId,
            },
          }),
        loadable: true,
      });
    },
    async createDefaultPipelines(_, { appId }) {
      await catchable({ t: () => ApiApps.createDefaultPipelines(appId), loadable: true });
    },
    async createDefaultSequences(_, { appId }) {
      await catchable({ t: () => ApiApps.createDefaultSequences(appId), loadable: true });
    },
    async createPipeline({ commit }, { appId, newPipeline }) {
      await catchable({
        t: async () =>
          commit("mutateCreatePipeline", {
            pipeline: await ApiPipelines.createPipeline(appId, newPipeline),
          }),
        loadable: true,
        throwable: true,
      });
    },
    async deletePipeline({ commit }, { appId, pipelineId }) {
      await catchable({
        t: async () =>
          commit("mutateDeletePipeline", {
            pipelineId: await ApiPipelines.deletePipeline(appId, pipelineId),
          }),
        loadable: true,
        throwable: true,
      });
    },
    async getTriggers({ commit, dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        commit("mutateUpdateTriggers", {
          triggers: await ApiTriggers.getTriggers(appId),
        });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async updateTrigger({ commit, dispatch }, { appId, triggerId, model }) {
      dispatch("setLoading", true);
      try {
        const trigger = await ApiTriggers.updateTrigger(appId, triggerId, model);
        commit("mutateUpdateTrigger", { trigger });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },

    async createTrigger({ commit, dispatch }, { appId, newTrigger }) {
      dispatch("setLoading", true);
      try {
        const trigger = await ApiTriggers.createTrigger(appId, newTrigger);
        commit("mutateUpdateTrigger", { trigger });
      } catch (error) {
        dispatch("setLoading", false);
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },

    async restartPipeline(_, { appId, pipelineId }) {
      await catchable({
        t: async () => await ApiPipelines.restartPipeline(appId, pipelineId),
        loadable: true,
        throwable: true,
      });
    },

    async deleteTrigger({ commit }, { appId, triggerId }) {
      await catchable({
        t: async () =>
          commit("mutateDeleteTrigger", {
            triggerId: await ApiTriggers.deleteTrigger(appId, triggerId),
          }),
        loadable: true,
        throwable: true,
      });
    },

    async getVideoUploadUrl({ dispatch }, { appId, pathId }) {
      try {
        return await ApiPaths.getVideoUploadUrl(appId, pathId);
      } catch (error) {
        dispatch("sendError", error);
      }
      return null;
    },

    async updateRecentPathActivity({ dispatch }, { appId, pathId, userId, timestamp }) {
      try {
        return await ApiPaths.updateRecentActivity(appId, pathId, userId, timestamp);
      } catch (error) {
        dispatch("sendError", error);
      }
      return null;
    },
    async getRecentPromos({ commit, dispatch }, { appId, params }) {
      dispatch("setLoading", true);
      try {
        commit("mutateRecentPromos", await ApiPaths.getRecentPromos(appId, params));
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async verifyPinpointEmail({ commit, dispatch }, { appId, email }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.verifyPinpointEmail(appId, email);
        commit("mutatePinpointEmail", email);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async enablePinpointEmails({ dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        await ApiApps.enablePinpointEmails(appId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async sendTestEmail({ dispatch }, { appId, pathId, recipient, actionGroupId }) {
      dispatch("setLoading", true);
      try {
        await ApiPaths.sendTestEmail(appId, pathId, recipient, actionGroupId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },

    async getAppConnectors({ commit, dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        const connectors = await ApiApps.connectors(appId);
        commit("mutateAppConnectors", connectors);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },

    async checkSdkActive({ commit, dispatch }, { appId }) {
      dispatch("setLoading", true);
      try {
        const sdkActive = await ApiApps.sdkActive(appId);
        commit("mutateSdkActive", sdkActive.success);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    // CUSTOM_DEVICES
    async getCustomDevices({ commit, dispatch }, appId) {
      try {
        dispatch("setLoading", true);
        const customDevices = await ApiCustomDevices.getCustomDevices(appId);

        commit("mutateCurrentCustomDevices", customDevices);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async updateCustomDevice({ dispatch }, { appId, deviceId, modelDevice }) {
      try {
        dispatch("setLoading", true);
        await ApiCustomDevices.updateCustomDevice(appId, deviceId, modelDevice);

        await dispatch("getCustomDevices", appId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async createCustomDevice({ dispatch }, { appId, modelDevice }) {
      try {
        dispatch("setLoading", true);
        await ApiCustomDevices.createCustomDevice(appId, modelDevice);

        await dispatch("getCustomDevices", appId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async deleteCustomDevice({ dispatch }, { appId, deviceId }) {
      try {
        dispatch("setLoading", true);
        await ApiCustomDevices.deleteCustomDevice(appId, deviceId);

        await dispatch("getCustomDevices", appId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async createDataSource({ dispatch, commit }, { appId, model }) {
      try {
        dispatch("setLoading", true);
        const dataSource = await ApiDataSources.createDataSource(appId, model);
        commit("mutateCreateDataSource", dataSource);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async getDataSources({ dispatch, commit }, { appId }) {
      try {
        dispatch("setLoading", true);
        const dataSources = await ApiDataSources.getDataSources(appId);
        commit("mutateDataSources", dataSources);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async upsertDataSourceCSV({ dispatch, commit }, { appId, dataSourceId, csv }) {
      try {
        dispatch("setLoading", true);
        const dataSource = await ApiDataSources.upsertFromCSV(appId, dataSourceId, csv);
        commit("mutateUpdateDataSource", dataSource);
        useToastsStore().create({ type: "success", body: "Successfully uploaded CSV" });
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async deleteDataSource({ commit, dispatch }, { appId, dataSourceId }) {
      dispatch("setLoading", true);
      try {
        await ApiDataSources.deleteDataSource(appId, dataSourceId);
        commit("mutateDeleteDataSource", dataSourceId);
      } catch (error) {
        dispatch("sendError", error);
      }
      dispatch("setLoading", false);
    },
    async editDataSource({ commit, dispatch }, { appId, model }) {
      try {
        dispatch("setLoading", true);
        const dataSource = await ApiDataSources.editDataSource(appId, model);
        commit("mutateUpdateDataSource", dataSource);
        useToastsStore().create({ type: "success", body: "Successfully updated data source" });
      } catch (error) {
        dispatch("sendError", error);
      } finally {
        dispatch("setLoading", false);
      }
    },
    async cloneSequence({ commit, dispatch }, { appId, sequenceId }) {
      try {
        dispatch("setLoading", true);
        const sequence = await ApiSequences.cloneSequence(appId, sequenceId);
        commit("mutateCloneSequence", { sequence });
        dispatch("getSequence", { appId, sequenceId: sequence.id });
        return sequence;
      } catch (error) {
        dispatch("sendError", error);
      } finally {
        dispatch("setLoading", false);
      }
    },
    async getConnectorActions({ commit, dispatch }, { appId }) {
      try {
        dispatch("setLoading", true);
        const actions = await ApiConnectorActions.getActions(appId);
        commit("mutateConnectorActions", actions);
      } catch (error) {
        dispatch("sendError", error);
      } finally {
        dispatch("setLoading", false);
      }
    },
    async createConnectorAction({ commit, dispatch }, { appId, newAction }) {
      try {
        dispatch("setLoading", true);
        const action = await ApiConnectorActions.createAction(appId, newAction);
        commit("mutateUpdateConnectorAction", action);
        useToastsStore().create({ type: "success", body: "Successfully created action" });
      } catch (error) {
        dispatch("sendError", error);
      } finally {
        dispatch("setLoading", false);
      }
    },
    async updateConnectorAction({ commit, dispatch }, { appId, actionId, model }) {
      try {
        dispatch("setLoading", true);
        const action = await ApiConnectorActions.updateAction(appId, actionId, model);
        commit("mutateUpdateConnectorAction", action);
        useToastsStore().create({ type: "success", body: "Successfully updated action" });
      } catch (error) {
        dispatch("sendError", error);
      } finally {
        dispatch("setLoading", false);
      }
    },
    async deleteConnectorAction({ commit }, { appId, actionId }) {
      await catchable({
        t: async () =>
          commit(
            "mutateDeleteConnectorAction",
            await ApiConnectorActions.deleteAction(appId, actionId),
          ),
        loadable: true,
        throwable: true,
      });
    },
    async getClientActions({ commit, dispatch }, { appId }) {
      try {
        dispatch("setLoading", true);
        const actions = await ApiClientActions.getActions(appId);
        commit("mutateClientActions", actions);
      } catch (error) {
        dispatch("sendError", error);
      } finally {
        dispatch("setLoading", false);
      }
    },
    async createClientAction({ commit, dispatch }, { appId, newAction }) {
      try {
        dispatch("setLoading", true);
        const action = await ApiClientActions.createAction(appId, newAction);
        commit("mutateUpdateClientAction", action);
        useToastsStore().create({ type: "success", body: "Successfully created" });
      } catch (error) {
        dispatch("sendError", error);
      } finally {
        dispatch("setLoading", false);
      }
    },
    async updateClientAction({ commit, dispatch }, { appId, actionId, model }) {
      try {
        dispatch("setLoading", true);
        const action = await ApiClientActions.updateAction(appId, actionId, model);
        commit("mutateUpdateClientAction", action);
        useToastsStore().create({ type: "success", body: "Successfully updated" });
      } catch (error) {
        dispatch("sendError", error);
      } finally {
        dispatch("setLoading", false);
      }
    },
    async deleteClientAction({ commit }, { appId, actionId }) {
      await catchable({
        t: async () =>
          commit("mutateDeleteClientAction", await ApiClientActions.deleteAction(appId, actionId)),
        loadable: true,
        throwable: true,
      });
    },
    async getTriggerCustomEvents({ commit }, { appId }) {
      await catchable({
        t: async () => commit("mutatePushEventOptions", await ApiTriggers.customEvents(appId)),
        loadable: true,
        throwable: true,
      });
    },
  },
};
