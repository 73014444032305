import { mapActions, mapState } from "vuex";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";

export default {
  mixins: [LanguagePreviewMixin],
  computed: {
    ...mapState({
      currAppConnectors: state => state.apps.currAppConnectors,
      connectorActions: state => state.apps.connectorActions || [],
    }),
    thirdPartyData() {
      const providers = {
        custom_provider: {
          name: "Custom_provider",
          actions: [],
        },
        redfast: {
          name: "Redfast",
          actions: [],
        },
        sendgrid: {
          name: "Sendgrid",
          actions: [],
        },
        salesforce: {
          name: "Salesforce",
          actions: [],
        },
        braze: {
          name: "Braze",
          actions: [],
        },
        stripe: {
          name: "Stripe",
          actions: [],
        },
        vindicia: {
          name: "Vindicia",
          actions: [],
        },
        braintree: {
          name: "Braintree",
          actions: [],
        },
        zendesk: {
          name: "Zendesk",
          actions: [],
        },
        shopify: {
          name: "Shopify",
          actions: [],
        },
        active_campaign: {
          name: "ActiveCampaign",
          actions: [],
        },
      }; // preserve order of providers
      if (!this.app) {
        return providers;
      }
      this.connectorActions.forEach(connectorAction => {
        const providerName = connectorAction.provider;
        const existingProvider = providers[providerName];

        const item = this.createThirdPartyItem(connectorAction);
        if (existingProvider) {
          existingProvider.actions.push(item);
        } else {
          const actions = [item];
          providers[providerName] = {
            name: this.capitalize(providerName),
            actions,
          };
        }
      });
      for (const provider in providers) {
        if (!providers[provider].actions.length) {
          delete providers[provider];
        }
      }
      return providers;
    },
    thirdPartyOptions() {
      const options = [];
      for (const provider in this.thirdParty) {
        const data = this.thirdParty[provider];
        const option = {
          text: data.name,
          provider,
          value: data,
        };
        if (this.isEditing) {
          if (provider === this.editIntegration.provider) {
            options.push(option);
          }
        } else if (
          provider === "redfast" ||
          (this.app.providers[provider] && this.app.providers[provider].active)
        ) {
          options.push(option);
        }
      }
      return options;
    },
  },
  methods: {
    ...mapActions(["getAppConnectors", "getConnectorActions"]),
    capitalize(string) {
      if (string) {
        return string.replace(/^\w/, c => c.toUpperCase());
      }
      return "";
    },
    fetchValues(input) {
      const values = input.possible_values;
      if (input.input_type !== "select") return null;

      if (input.values_included) {
        if (input.dynamic_value_name) {
          const dynamicValues = [];
          values.forEach(item => {
            const value = { id: item.id };
            const [source, key] = item.name.split(".");
            if (source === "actions") {
              const action = this.readOnlyActions[key];
              if (action) {
                value.name = action;
                dynamicValues.push(value);
              }
            }
          });
          return dynamicValues;
        }
        return values;
      }
      const [provider, property] = values.split(".");
      const providerValues = this.currAppConnectors[provider] || {};
      return providerValues[property];
    },
    createThirdPartyItem(connectorAction) {
      // if (connectorAction.provider === "stripe") console.log(connectorAction);
      const providerName = connectorAction.provider;
      const { args } = connectorAction;
      return {
        provider: providerName,
        operation: connectorAction.operation,
        description: args.description,
        disabled: this.isActionDisabled(args),
        inputs: args.inputs.map(input => {
          return {
            valueDescription: input.value_description,
            type: input.input_type,
            hideId: input.hide_id,
            values: this.fetchValues(input),
            valueLabel: input.value_label,
            counter: input.character_counter,
            defaultValue: input.default_value,
            placeholder: input.value,
            isRequired: !input.optional,
          };
        }),
      };
    },
  },
  mounted() {
    const appId = this.$route.params.aid;
    this.getAppConnectors({ appId });
    if (!this.connectorActions.length) {
      this.getConnectorActions({ appId });
    }
  },
};
