export const displayCustomInterval = (trigger, excludeTriggerType = "", eventInterval = 7200) => {
  if (
    trigger?.custom_trigger_id &&
    (!excludeTriggerType || trigger.trigger_type !== excludeTriggerType)
  ) {
    if (trigger.custom_trigger_seconds === eventInterval) return "Event";
    return trigger.custom_trigger_seconds;
  }
  return null;
};

export const regexDisplay = trigger => {
  if (trigger?.trigger_type === "web") {
    return trigger.use_regex ? "Yes" : "No";
  }
  return "N/A";
};

export const displayPage = trigger => {
  let page = trigger?.url_path || "";
  [trigger?.query_params, trigger?.url_hash].forEach(el => {
    if (el) {
      if (page) page += "; ";
      page += el;
    }
  });
  return page;
};

export const displayCustomTrigger = (trigger, customTriggers, excludeTriggerType = "") => {
  if (
    trigger &&
    customTriggers &&
    (!excludeTriggerType || trigger.trigger_type !== excludeTriggerType)
  ) {
    const custom = customTriggers.find(({ id }) => id === trigger.custom_trigger_id);
    if (custom) return custom.name;
  }
  return null;
};
