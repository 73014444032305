import colors from "vuetify/lib/util/colors";

export const PIE_CHART_COLOR_LIST = [
  colors.cyan.darken1,
  colors.teal.base,
  colors.green.base,
  colors.lime.darken1,
  colors.orange.base,
  colors.deepOrange.base,
  colors.pink.darken3,
  colors.deepPurple.lighten1,
  colors.indigo.base,
  colors.blue.base,
];
