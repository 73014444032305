import { onClickOutside, toValue } from "@vueuse/core";
import { ref } from "vue";

export const usePreviewToggle = (target, imagePreview, ignoreRefs = []) => {
  const stop = ref(null);

  const clickPreview = () => {
    if (!imagePreview.value) {
      imagePreview.value = true;
      stop.value = onClickOutside(toValue(target), clickPreview, { ignore: ignoreRefs || [] });
    } else {
      stop.value?.();
      imagePreview.value = false;
    }
  };

  return clickPreview;
};
