<template>
  <div class="settings-actions-list-items">
    <h5>Available Actions</h5>
    <ul>
      <li v-for="(value, index) in actions" :key="index">{{ value }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["actions"],
  name: "RfConnectorActionsTable",
};
</script>
