import { render, staticRenderFns } from "./RfTableHeader.vue?vue&type=template&id=7653679a&scoped=true"
import script from "./RfTableHeader.vue?vue&type=script&setup=true&lang=js"
export * from "./RfTableHeader.vue?vue&type=script&setup=true&lang=js"
import style0 from "./RfTableHeader.vue?vue&type=style&index=0&id=7653679a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7653679a",
  null
  
)

export default component.exports