<template>
  <div>
    <v-select
      outlined
      dense
      hide-details
      :disabled="disabled"
      :items="parentItems"
      item-text="name"
      item-value="value"
      v-model="parentModel"
      @change="updateParent"
      placeholder="Select configuration"
      class="segment-builder--subtypes _subtypes"
      height="32px"
      background-color="#ffffff"
      style="min-width: 180px"
      :menu-props="{ maxHeight: 410, offsetY: true }"
    />
    <div class="segment-builder--item next-selection" v-if="parentModel">
      <v-icon>chevron_right</v-icon>
      <v-select
        outlined
        dense
        hide-details
        height="32px"
        v-model="negativeMatch"
        :disabled="disabled"
        :items="matchTypeItems"
        placeholder="Match Type"
        background-color="#ffffff"
        @change="updateFilter"
      />
    </div>
    <div class="segment-builder--item next-selection" v-if="negativeMatchSelected">
      <v-icon>chevron_right</v-icon>
      <component
        :is="childComponent"
        v-bind="childProps"
        v-model="childModel"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import { startCase, camelCase } from "lodash-es";
import { mapState } from "vuex";
import CountryList from "country-list";
import RfAutoCompletionWithChips from "@/components/RfAutoCompletionWithChips.vue";
import RfGooglePlaceAutoCompletion from "@/components/RfGooglePlaceAutoCompletion.vue";
import { LocationTypes, USStates } from "@/utils/constants/LocationsConstants";
import { MatchTypeItems } from "@/utils/constants/SegmentsConstants";

export default {
  name: "RfLocation",
  components: {
    cities: RfGooglePlaceAutoCompletion,
    states: RfAutoCompletionWithChips,
    countries: RfAutoCompletionWithChips,
    metro_codes: RfAutoCompletionWithChips,
    postal_codes: RfGooglePlaceAutoCompletion,
  },
  props: ["filter", "disabled"],
  setup: () => ({
    states: Object.keys(USStates),
    matchTypeItems: Object.values(MatchTypeItems),
    parentItems: Object.values(LocationTypes),
  }),
  data: () => ({ parentModel: null, childModel: null, negativeMatch: null }),
  computed: {
    ...mapState({ segment: state => state.apps.currSegment }),
    cities() {
      return "cities";
    },
    countries() {
      const names = CountryList.getNames();
      return names.map(country => {
        return { text: country, value: CountryList.getCode(country) };
      });
    },
    childComponent() {
      return this.parentModel;
    },
    parent() {
      return LocationTypes[this.parentModel];
    },
    childProps() {
      const varname = camelCase(startCase(this.parent.value));
      return { [this.parent.prop]: this[varname] };
    },
    locationKey() {
      return Object.keys(this.filter.location)[0];
    },
    values() {
      if (!this.parent) return [];

      return this.filter.location[this.parent.value];
    },
    negativeMatchSelected() {
      return this.negativeMatch !== null;
    },
  },
  watch: {
    childModel() {
      this.updateFilter();
    },
  },
  methods: {
    updateParent() {
      this.childModel = [];
      this.updateFilter();
    },
    updateFilter() {
      const filter = {
        location: { [this.parentModel]: this.childModel },
        location_configs: { [this.parentModel]: { negative_match: this.negativeMatch } },
      };
      this.$emit("update:model", filter);
    },
    prepareFilter() {
      this.parentModel = this.locationKey;
      this.childModel = this.values;
      let configs;
      if (this.filter.location_configs) {
        configs = this.filter.location_configs;
      } else if (this.segment.filter.location_configs) {
        configs = this.segment.filter.location_configs;
      }
      if (configs[this.parentModel]) {
        this.negativeMatch = configs[this.parentModel].negative_match;
      }
    },
  },
  mounted() {
    this.prepareFilter();
  },
};
</script>
