// used by the parent component of the filters component, the parent renders the promos
import { mapState, mapActions } from "vuex";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import { cloneDeep, debounce, orderBy } from "lodash-es";

export default {
  data() {
    return {
      deviceFilter: [],
      filterPlacementsSegment: [],
      placementPathType: [],
      promoPublishStatus: [],
      groupFilter: null,
      expirationStatus: [],
      resetPlacementFilters: 0,
      searchQuery: "",
      sortType: "",
      sortTypeItems: [
        { text: "A-Z", value: "alpha_asc" },
        { text: "Z-A", value: "alpha_desc" },
        { text: "Most Recently Updated", value: "updated_desc" },
        { text: "Least Recently Updated", value: "updated_asc" },
      ],
    };
  },
  mixins: [UserSettingsMixin],
  computed: {
    groupFilterFunction() {
      if (this.groupFilter === "grouped") {
        // zone
        return item => {
          return !this.isStandAlone(item);
        };
      }
      return () => true;
    },
    ...mapState({
      retentions: state => cloneDeep(state.apps.retentions),
      pathGroups: state => state.apps.pathGroups,
      sequences: state => state.apps.sequences,
      currApp: state => state.apps.currApp,
    }),
    hasPathGroups() {
      return this.pathGroups && this.pathGroups.length > 0;
    },
    hasActivePromo() {
      return this.$route.params.pid && this.hasPathGroups;
    },
    searchQueryDebounced: {
      get() {
        return this.searchQuery;
      },
      set: debounce(function setSearch(newValue) {
        this.searchQuery = newValue;
      }, 100),
    },
    filteredParents() {
      if (this.groupFilter === "grouped") return this.filteredPathGroups;
      if (this.groupFilter === "sequence") return this.filteredSequences;
      if (this.groupFilter === "pipeline") return this.filteredPipelines;
      return [];
    },
    filteredSequences() {
      let sequences = cloneDeep(this.sequences);
      sequences.forEach(sequence => {
        this.getRetentionsWithData(sequence);
        sequence.paths.forEach(path => this.setSequenceAttributesOnPath(path, sequence));
        this.filterPaths(sequence);
      });

      sequences = sequences.filter(this.parentComboFilter);
      return orderBy(
        sequences,
        [
          item => {
            if (this.sortType.indexOf("alpha") >= 0) {
              return item.name.toLowerCase();
            }
            if (this.sortType.indexOf("updated") >= 0) {
              return new Date(item.updated_at);
            }
            return null;
          },
        ],
        [this.sortOrder],
      );
    },
    pathsByPipeline() {
      const pipelines = [];
      this.retentions
        .filter(path => !!path.pipeline_stage_id)
        .forEach(path => {
          const pipeline = pipelines.find(pipe => pipe.id === path.pipeline_id);
          if (pipeline) {
            pipeline.paths.push(path);
          } else {
            pipelines.push({
              name: path.pipeline_name,
              id: path.pipeline_id,
              paths: [path],
            });
          }
        });
      return pipelines;
    },
    filteredPipelines() {
      let pipelines = cloneDeep(this.pathsByPipeline);
      pipelines.forEach(pipeline => {
        pipeline.paths.forEach(path => {
          if (path.sequence_id) {
            const sequence = this.sequences.find(seq => seq.id === path.sequence_id);
            this.setSequenceAttributesOnPath(path, sequence);
          }
          this.filterPaths(pipeline);
        });
      });
      pipelines = pipelines.filter(this.parentComboFilter);
      return orderBy(
        pipelines,
        [
          item => {
            if (this.sortType.indexOf("alpha") >= 0) {
              return item.name.toLowerCase();
            }
            if (this.sortType.indexOf("updated") >= 0) {
              return new Date(item.paths[0].updated_at);
            }
            return null;
          },
        ],
        [this.sortOrder],
      );
    },
    comboPathsFilter() {
      return item => {
        if (!this.compoundFilter(item)) return false;
        if (this.searchQueryDebounced.length && !this.keywordMatch(item.name || "")) return false;
        return true;
      };
    },
    filteredPathGroups() {
      const pathGroups = cloneDeep(this.pathGroups.filter(this.groupFilterFunction));

      pathGroups.forEach(pathGroup => {
        if (pathGroup.paths) {
          this.getRetentionsWithData(pathGroup);
          pathGroup.paths.forEach(path => {
            if (path.sequence_id) {
              const sequence = this.sequences.find(seq => seq.id === path.sequence_id);
              this.setSequenceAttributesOnPath(path, sequence);
            }
          });
          this.filterPaths(pathGroup);
        }
      });

      const unsorted = cloneDeep(pathGroups).filter(this.parentComboFilter);
      return orderBy(
        unsorted,
        [
          item => {
            if (this.sortType.indexOf("alpha") >= 0) {
              return this.getItemName(item).toLowerCase();
            }
            if (this.sortType.indexOf("updated") >= 0) {
              return this.getItemUpdatedAt(item);
            }
            return null;
          },
        ],
        [this.sortOrder],
      );
    },
    sortOrder() {
      return this.sortType.indexOf("asc") >= 0 ? "asc" : "desc";
    },
    childPathComboFilter() {
      return item => {
        return this.compoundFilter(item);
      };
    },
    parentComboFilter() {
      return item => {
        if (!item.paths.length) return false;
        if (this.searchQueryDebounced.length) {
          const parentMatch = this.keywordMatch(item.name || "");
          const pathMatch = item.paths.find(path => this.keywordMatch(path.name || ""));
          if (!parentMatch && !pathMatch) return false;
        }
        return true;
      };
    },
  },
  methods: {
    ...mapActions(["getPathGroups", "getSequences"]),
    compoundFilter(item) {
      const presentAmongCustomDevices = elem =>
        item.custom_devices.map(d => d.label).indexOf(elem) > -1;
      if (
        this.deviceFilter.length &&
        !(
          this.deviceFilter.indexOf(item.device_type) > -1 ||
          this.deviceFilter.some(presentAmongCustomDevices)
        )
      )
        return false;

      if (this.placementPathType.length && this.placementPathType.indexOf(item.path_type) < 0)
        return false;
      if (this.filterPlacementsSegment.length && !this.segmentMatch(item)) return false;

      const isFilteringByPromptState =
        this.expirationStatus.length !== 0 || this.promoPublishStatus.length !== 0;
      const matchesPromptStatus =
        this.promoPublishStatus.indexOf(this.promoStatus(item).label) > -1;
      const matchesExpirationStatus = this.expirationStatusMatch(item.end_date);

      if (isFilteringByPromptState && !(matchesPromptStatus || matchesExpirationStatus))
        return false;

      return true;
    },
    getItemName(pathGroup) {
      if (this.groupFilter === "ungrouped" || this.isStandAlone(pathGroup))
        return pathGroup.paths[0].name;
      return pathGroup.name || "";
    },
    getItemUpdatedAt(pathGroup) {
      if (this.groupFilter === "ungrouped" || this.isStandAlone(pathGroup))
        return new Date(pathGroup.paths[0].updated_at);
      return new Date(pathGroup.updated_at);
    },
    isStandAlone(pathGroup) {
      return (
        [
          "retention_modal",
          "video",
          "invisible",
          "widget",
          "interstitial",
          "email",
          "bottom_banner",
        ].indexOf(pathGroup.path_type) > -1
      );
    },
    setDeviceTypes(types) {
      this.deviceFilter = types;
    },
    setPathTypes(types) {
      this.placementPathType = types;
    },
    setSegmentIds(ids) {
      this.filterPlacementsSegment = ids;
    },
    setStatuses(statuses) {
      this.promoPublishStatus = statuses;
    },
    groupFilterUpdate(filter) {
      this.groupFilter = filter;
    },
    setExpirationStatus(expirationStatus) {
      this.expirationStatus = expirationStatus;
    },
    showAllPromos() {
      this.deviceFilter = [];

      this.filterPlacementsSegment = [];

      this.placementPathType = [];

      this.promoPublishStatus = [];

      this.groupFilter = "ungrouped";

      this.resetPlacementFilters += 1;
    },
    setSequenceAttributesOnPath(path, sequence) {
      if (sequence) {
        path.segments = sequence.segments;
        path.start_date = sequence.start_date;
        path.end_date = sequence.end_date;
        path.is_enabled = sequence.is_enabled;
        path.filter = sequence.filter;
        path.curr_limit = sequence.curr_limit;
        path.curr_budget = sequence.curr_budget;
        path.curr_deliveries = sequence.curr_deliveries;
      }
    },
    filterPaths(parent) {
      parent.paths = this.locatePaths(parent);
    },
    locatePaths(parent) {
      return parent.paths.filter(this.childPathComboFilter);
    },
    segmentMatch(item) {
      return item.segments.findIndex(c => this.filterPlacementsSegment.indexOf(c.id) >= 0) >= 0;
    },
    keywordMatch(name) {
      return name.toLowerCase().indexOf(this.searchQueryDebounced.toLowerCase()) > -1;
    },
    expirationStatusMatch(endDate) {
      if (!endDate) return false;

      const daysFromToday = date => Math.floor((new Date() - new Date(date)) / (24 * 3600 * 1000));

      const expirationComparators = {
        expires_soon: date => daysFromToday(date) >= -6 && daysFromToday(date) <= 0,
        expired_recently: date => daysFromToday(date) > 0 && daysFromToday(date) <= 7,
      };

      return this.expirationStatus.reduce(
        (prev, curr) => prev || expirationComparators[curr](endDate),
        false,
      );
    },
    getRetentionsWithData(parent) {
      const pathsWithData = [];
      parent.paths.forEach(path => {
        const retention = this.retentions.find(item => path.id === item.id);
        if (retention) pathsWithData.push(cloneDeep(retention));
      });
      parent.paths = pathsWithData;
    },
  },
  watch: {
    sortType(to) {
      this.setUserSetting("placementsSortType", to);
    },
  },
  mounted() {
    const appId = this.$route.params.aid;
    if (!this.sequences || !this.sequences.length) this.getSequences({ appId });
    if (!this.pathGroups || !this.pathGroups.length) this.getPathGroups({ appId });
    this.deviceFilter = this.getUserSetting("placementsDeviceFilter") || [];
    this.filterPlacementsSegment = this.getUserSetting("placementsSegmentFilter") || [];
    this.placementPathType = this.getUserSetting("placementsPathTypeFilter") || [];
    this.promoPublishStatus = this.getUserSetting("placementsStatusFilter") || [
      "Running",
      "Pending",
      "Paused",
    ]; // running and pending
    this.groupFilter = this.getUserSetting("placementsGroupFilter") || "ungrouped";
    this.expirationStatus = this.getUserSetting("placementsExpirationStatus") || [];
  },
};
