import { render, staticRenderFns } from "./RfInput.vue?vue&type=template&id=1f8d0663&scoped=true"
import script from "./RfInput.vue?vue&type=script&setup=true&lang=js"
export * from "./RfInput.vue?vue&type=script&setup=true&lang=js"
import style0 from "./RfInput.vue?vue&type=style&index=0&id=1f8d0663&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f8d0663",
  null
  
)

export default component.exports