<template>
  <div>
    <div class="promo-design-group" v-if="model.device_type === DefaultDeviceOptions.web.value">
      <div class="promo-window-size-inputs">
        <v-select
          :items="windowWidthOptions"
          label="Window width"
          v-model="actions.rf_settings_window_max_width"
          name="window-max-width"
          outlined
          required
          dense
          hide-details
        ></v-select>
        <v-text-field
          hide-details
          outlined
          dense
          label="Message Width"
          v-model.trim="actions.rf_settings_text_container_max_width"
          required
          v-validate="'required'"
        ></v-text-field>
      </div>
    </div>
    <div class="promo-design-group" v-if="model.device_type !== DefaultDeviceOptions.web.value">
      <div class="promo-window-size-inputs">
        <v-select
          v-if="showPopUpSize"
          :items="windowWidthOptions"
          item-value="textValue"
          label="Pop up size"
          v-model="
            actions[isNewAndroidTv ? 'rf_settings_tv_pop_up_size' : 'rf_settings_pop_up_size']
          "
          name="window-max-width"
          outlined
          required
          dense
          hide-details
        ></v-select>
        <v-text-field
          hide-details
          outlined
          dense
          label="Message Width"
          v-model.trim="actions.rf_settings_text_container_max_width"
          required
          v-validate="'required'"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DefaultDeviceOptions } from "@/utils/constants/DevicesConstants";
import { onMounted } from "vue";

const props = defineProps({
  actions: { type: Object, default: null },
  model: { type: Object, default: null },
  showPopUpSize: { type: Boolean, default: false },
  isNewAndroidTv: { type: Boolean, default: false },
});

const windowWidthOptions = [
  { text: "Large", textValue: "large", value: "1200px" },
  { text: "Medium", textValue: "medium", value: "960px" },
  { text: "Small", textValue: "small", value: "750px" },
];
</script>
