<template>
  <div style="width: 100%">
    <div style="display: flex">
      <v-tabs
        v-model="viewTabModal"
        slider-color="666666"
        dark
        centered
        icons-and-text
        height="50"
        background-color="#000"
      >
        <v-tabs-slider style="color: #2196f3"></v-tabs-slider>
        <v-tab href="#desktopViewTab" @click="$emit('clickTab', 'desktopViewTab')">
          {{ currApp?.flags?.new_android_editor ? "TV" : "TV & Tablet" }}</v-tab
        >
        <v-tab href="#mobilewebViewTab" @click="$emit('clickTab', 'mobilewebViewTab')">
          {{ currApp?.flags?.new_android_editor ? "Mobile & Tablet" : "Phone" }}
        </v-tab>
      </v-tabs>
      <RfLanguagePreview :model="model" :actions="actions" />
    </div>
    <v-tabs-items v-model="viewTabModal">
      <v-tab-item
        :transition="false"
        :class="{ '!min-h-full': currApp?.flags?.new_android_editor }"
        :reverse-transition="false"
        value="desktopViewTab"
      >
        <RfRetentionModal
          :model="model"
          :class="{ '!h-full !min-h-full': currApp?.flags?.new_android_editor }"
          :actions="previewActions"
          :zoomStyle="zoomStyle"
          :isNewDeviceEditor="currApp?.flags?.new_android_editor"
          :deviceType="currApp?.flags?.new_android_editor ? 'androidTV' : 'androidLandscape'"
          ref="android_os_tv"
        />
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" value="mobilewebViewTab">
        <RfRetentionModal
          :model="model"
          isMobile
          :actions="previewActions"
          :isNewDeviceEditor="currApp?.flags?.new_android_editor"
          :deviceType="currApp?.flags?.new_android_editor ? 'androidSquare' : 'mobileWeb'"
          ref="android_os_phone"
        />
      </v-tab-item>
    </v-tabs-items>
    <div class="preview-control-buttons" v-if="viewTabModal === 'desktopViewTab'">
      <v-btn small color="#f3f3f3" @click="zoomIn">+</v-btn>
      <v-btn class="reset-zoom-btn" small color="#f3f3f3" @click="resetZoom">{{
        `${zoomValue * 100}%`
      }}</v-btn>
      <v-btn small color="#f3f3f3" @click="zoomOut">-</v-btn>
    </div>
    <component :is="'style'" v-if="actions.rf_settings_custom_css">{{
      actions.rf_settings_custom_css
    }}</component>
  </div>
</template>
<script>
import RfRetentionModal from "@/components/RfPathCreate/RfRetentionActions/RfRetentionModal.vue";
import ZoomPreviewMixin from "@/utils/ZoomPreviewMixin";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import RfLanguagePreview from "@/components/RfPathCreate/RfRetentionDevices/RfLanguagePreview.vue";
import { mapState } from "vuex";
import CompositeImageSaver from "@/utils/CompositeImageSaver";

export default {
  name: "RfRetentionDeviceAndroidOs",
  props: ["actions", "viewTypeTab", "model"],
  components: { RfRetentionModal, RfLanguagePreview },
  mixins: [ZoomPreviewMixin, LanguagePreviewMixin],
  data() {
    return {
      viewTabModal: this.viewTypeTab,
    };
  },
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
  },
  methods: {
    async fillModel(model) {
      if (!model.device_type) {
        model.device_type = this.model.device_type;
      }
      await CompositeImageSaver.fill(model, this.$refs, this.currApp);
    },
  },
};
</script>
