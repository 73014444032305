<template>
  <RfSimpleCard style="min-height: 400px" class="mb-7 py-7 px-7">
    <div>
      <div class="settings-usage-tracking-desc">
        <div>
          <h4>Custom Devices Manager</h4>
          <p class="title-blurb">
            Manage custom devices. Only devices with no assigned prompts may be deleted.
          </p>
        </div>
        <RfButton
          icon="add"
          button-text="New Custom Device"
          data-cy="custom-devices--create-new"
          color="success"
          :disabled="isDisabledRoleMixin"
          @click="newCustomDevice"
        />
      </div>
    </div>
    <v-simple-table data-cy="custom-devices--table" class="rf-settings-table-data">
      <thead>
        <th class="device-table-col">Name</th>
        <th class="device-table-col">Label</th>
        <th class="device-table-col">Description</th>
        <th class="device-table-col"></th>
      </thead>
      <tbody v-if="customDevices.length > 0">
        <tr v-for="device in customDevices" :key="device.id">
          <td align="center">{{ device.name }}</td>
          <td align="center">{{ device.label }}</td>
          <td>{{ device.description }}</td>
          <td>
            <div class="settings-button-wrapper">
              <RfButton
                :icon="!!isDisabledRoleMixin ? 'mdi-information-variant' : 'edit'"
                small
                data-cy="custom-devices--edit"
                color="accent"
                @click="openEditCustomDevice(device)"
              />
              <RfButton
                icon="clear"
                small
                data-cy="custom-devices--delete"
                color="error"
                :disabled="isDisabledRoleMixin"
                @click="openDeleteCustomDevice(device)"
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="4">
            <div class="settings-no-data-message">Nothing to show</div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- modals -->
    <v-dialog
      persistent
      v-model="createEditModal"
      width="640"
      content-class="create-device-dialog"
      overlay-opacity="0.85"
    >
      <v-card flat>
        <v-card-title class="headline">
          {{ device.id ? "Edit custom device" : "Create new custom device" }}
        </v-card-title>
        <v-card-text>
          <RfTextInput
            dense
            outlined
            persistent-hint
            autofocus
            data-cy="custom-device-modal--input-name"
            placeholder="Name"
            :disabled="isReadOnlyRoleMixin"
            title="Name"
            hint="e.g., Android (All)"
            v-model.trim="device.name"
          />
          <RfTextInput
            dense
            outlined
            persistent-hint
            data-cy="custom-device-modal--input-label"
            placeholder="Label"
            :disabled="isReadOnlyRoleMixin"
            title="Label"
            hint="e.g., android_all"
            v-model.trim="device.label"
          />
          <RfTextInput
            dense
            outlined
            persistent-hint
            data-cy="custom-device-modal--input-description"
            placeholder="Description"
            :disabled="isReadOnlyRoleMixin"
            title="Description"
            hint="e.g., Used for all android-based devices"
            v-model.trim="device.description"
          />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed class="cancel-btn" outlined width="100px" @click="closeModals">
            {{ !isDisabledRoleMixin ? "Cancel" : "Close" }}
          </v-btn>
          <RfButton
            icon="save"
            :button-text="device.id ? 'Update Custom Device' : 'Create new custom device'"
            color="success"
            data-cy="custom-device-modal--submit"
            large
            :loading="loading"
            width="260px"
            :disabled="isDisabledRoleMixin || loading"
            @click="submitCustomDevice"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteModal" persistent width="500">
      <v-card>
        <v-card-title class="headline">
          <span>
            Are you sure you want to delete <b>{{ device.name }}</b
            >?
          </span>
        </v-card-title>
        <v-card-text>
          Deleting this custom device removes all information about this custom device. This cannot
          be undone.
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn depressed class="cancel-btn px-7" outlined @click="closeModals"> Cancel </v-btn>
          <v-btn
            depressed
            :loading="loading"
            :disabled="loading"
            color="error"
            data-cy="custom-device-delete-modal--confirm"
            @click="submitDeleteCustomDevice"
            class="px-10"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </RfSimpleCard>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RoleMixin from "@/utils/RoleMixin";
import RfButton from "@/components/buttons/RfButton.vue";
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfCustomDevices",
  setup: () => ({ toastsStore: useToastsStore() }),
  components: { RfSimpleCard, RfTextInput, RfButton },
  mixins: [RoleMixin],
  data() {
    return {
      createEditModal: false,
      deleteModal: false,
      device: { id: null, name: null, description: null, label: null },
      loading: false,
    };
  },
  computed: {
    ...mapState({ customDevices: state => state.apps.customDevices }),
  },
  methods: {
    ...mapActions([
      "createCustomDevice",
      "getCustomDevices",
      "updateCustomDevice",
      "deleteCustomDevice",
    ]),
    resetModal() {
      this.device = { id: null, name: null, description: null, label: null };
    },
    newCustomDevice() {
      this.createEditModal = true;
      this.resetModal();
    },
    openEditCustomDevice({ id, name, label, description }) {
      this.createEditModal = true;
      this.device = { id, name, label, description };
    },
    openDeleteCustomDevice({ id, name, label, description }) {
      this.device = { id, name, label, description };
      this.deleteModal = true;
    },
    closeModals() {
      this.createEditModal = false;
      this.deleteModal = false;
      setTimeout(this.resetModal, 330);
    },
    async submitCustomDevice() {
      try {
        this.loading = true;
        const appId = this.$route.params.aid;
        const { id: deviceId, name, description, label } = { ...this.device };
        const modelDevice = { name, description, label };

        if (deviceId) {
          await this.updateCustomDevice({ appId, deviceId, modelDevice });
        } else {
          await this.createCustomDevice({ appId, modelDevice });
        }
        this.closeModals();
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      } finally {
        this.loading = false;
      }
    },
    async submitDeleteCustomDevice() {
      try {
        this.loading = true;
        const appId = this.$route.params.aid;
        await this.deleteCustomDevice({ appId, deviceId: this.device.id });
        this.closeModals();
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getCustomDevices(this.$route.params.aid);
  },
};
</script>
