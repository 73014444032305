import moment from "moment";
import { nonUniqueTypes } from "./constants/MetricsConstants";
import { toLocaleNumberString } from "./stringHelpers";
import { isAccept2Enabled } from "./prompts/promptHelpers";

export const tally = (data, metricsKey, statType) => {
  if (!data?.[statType]?.[metricsKey]?.data) return 0;

  const chart = data[statType][metricsKey];

  // don't have uniques for impressions
  if (nonUniqueTypes.includes(statType) || chart.uniques == null)
    return chart.data.reduce((acc, { count }) => acc + count, 0);

  return chart.uniques || 0;
};

export const percentage = (numerator, denominator, format = false) => {
  if (denominator === 0)
    return format === "new" ? { value: 0, label: `${toLocaleNumberString(0, 2)}%` } : 0;

  const decimal = numerator / (denominator || 1);
  const wholeNumber = decimal * 100;

  if (format === "new")
    return {
      value: +wholeNumber.toFixed(2),
      label: `${toLocaleNumberString(+wholeNumber, 2)}%`,
    };

  if (format) return wholeNumber ? `${toLocaleNumberString(+wholeNumber.toFixed(2))}%` : 0;
  else return parseFloat(Math.round(100 * wholeNumber) / 100) || 0;
};

export const statsAggregator = (items, metricsKey) =>
  items.reduce((aggData, path) => {
    if (!path) return aggData;

    Object.entries(path.data).forEach(([dataType, periodData]) => {
      periodData = periodData || {};

      const pathData =
        periodData[metricsKey] &&
        Array.isArray(periodData[metricsKey].data) &&
        periodData[metricsKey].data.length;
      if (!pathData) return;

      if (!aggData[dataType]) aggData[dataType] = {};
      if (!aggData[dataType][metricsKey]) {
        aggData[dataType][metricsKey] = {
          tz_offset: periodData.tz_offset,
          ...periodData[metricsKey],
        };
      } else if (
        periodData[metricsKey] &&
        Array.isArray(periodData[metricsKey].data) &&
        aggData[dataType][metricsKey].data
      ) {
        aggData[dataType][metricsKey].data = aggData[dataType][metricsKey].data.map(
          (dateWithCount, idx) => {
            let count = dateWithCount && dateWithCount.count;
            if (count !== null) {
              const period = periodData[metricsKey].data[idx];
              if (period && period.count) count += period.count;
            }
            return { ...dateWithCount, count };
          },
        );
        const uniques = aggData[dataType][metricsKey].uniques + periodData[metricsKey].uniques;
        aggData[dataType][metricsKey] = {
          ...aggData[dataType][metricsKey],
          uniques,
        };
      }
    });

    return aggData;
  }, {});

export const getTotals = (metrics, type, period) => {
  const typeData = metrics?.[type]?.[period];
  if (!typeData) return 0;
  if (type !== "impressions") return typeData.uniques;
  // don't have uniques for impressions
  return (typeData.data || [])
    .filter(item => item.count !== null)
    .reduce((acc, { count }) => acc + count, 0);
};

export const getSurveyInputs = path => {
  const totalOptionsCount = path?.actions?.rf_retention_survey_options_total;
  const res = { labels: [], values: [] };
  if (!totalOptionsCount) return res;

  const base = `rf_retention_survey_option`;

  for (let i = 1; i <= totalOptionsCount; i++) {
    const label = `${base}_${i}_label`;
    const value = `${base}_${i}_value`;
    if (path.actions[label] && path.actions[value]) {
      res.labels.push(path.actions[label]);
      res.values.push(path.actions[value]);
    }
  }
  return res;
};

export const getPeriod = (item, statType, period) => {
  const tempStat = item.data[statType];
  if (tempStat && tempStat[period]) {
    const chart = tempStat[period];
    if (chart) {
      return chart.data.map(el => el.count);
    }
  }
  return [];
};

export const getHigh = (item, period) => {
  const chart = item.data.users[period];
  if (chart) {
    return Math.max(...chart.data.map(el => el.count));
  }
  return 0;
};

export const getPeriodHeader = (item, statType, currChartSrc) => {
  let chart;
  const tempStat = item.data[statType];
  if (tempStat) {
    chart = tempStat[currChartSrc];
  } else {
    const tempStatType = Object.keys(item.data)[0];
    if (!tempStatType) return [];
    chart = item.data[tempStatType][currChartSrc];
  }
  return chart.data.map(dataItem => {
    const tz_offset = 0;
    const symbol = tz_offset < 0 ? "-" : "+";
    let paddedTz = tz_offset.toString().padStart(2, "0");
    paddedTz = `${symbol}${paddedTz}00`;
    const text = moment.utc(`${dataItem.date}${paddedTz}`, "YYYYMMDDHHmmZZ");
    if (currChartSrc === "today" || currChartSrc === "yesterday") return text.format("hA");

    return text.format("MM-DD-YYYY");
  });
};

export const DISPLAYED_METRICS = {
  "goals": "Accept",
  "goals2": "Secondary Accept",
  "dismissals": "Dismiss",
  "declines": "Decline",
  "timeouts": "Timeout",
  "unique-endpoints-grouped-by-date": "Endpoints",
  "attempted-deliveries-grouped-by-date": "Attempted Deliveries",
  "unique-deliveries-grouped-by-date": "Deliveries",
};

export const CUSTOM_DISPLAYED_METRICS = {
  custom_goals_accepted: "after Accept",
  custom_goals_accepted2: "after Secondary Accept",
  custom_goals_declined: "after Decline",
  custom_goals_dismissed: "after Dismiss/Timeout",
};

export const getDisplayMetricsText = (model, key) => {
  if (
    model?.actions?.rf_settings_tile_interaction === "container" &&
    key === "rf_retention_button1_text"
  )
    return "Click";
  if (!model?.filter?.is_translated) return model?.actions?.[key];
  return (
    Object.values(model?.parsed_translations || {}).find(el => el?.default === "TRUE")?.[key] ||
    "Click"
  );
};

export const getDisplayMetrics = prompt => ({
  "goals": getDisplayMetricsText(prompt, "rf_retention_button1_text") || DISPLAYED_METRICS.goals,
  ...(isAccept2Enabled(prompt) && {
    goals2: getDisplayMetricsText(prompt, "rf_retention_button2_text") || DISPLAYED_METRICS.goals2,
  }),
  "declines":
    getDisplayMetricsText(prompt, "rf_retention_button3_text") || DISPLAYED_METRICS.declines,
  "dismissals": DISPLAYED_METRICS.dismissals,
  "timeouts": DISPLAYED_METRICS.timeouts,
  "unique-endpoints-grouped-by-date": DISPLAYED_METRICS["unique-endpoints-grouped-by-date"],
  "attempted-deliveries-grouped-by-date": DISPLAYED_METRICS["attempted-deliveries-grouped-by-date"],
  "unique-deliveries-grouped-by-date": DISPLAYED_METRICS["unique-deliveries-grouped-by-date"],
});

export const getDisplayCustomMetrics = (prompt, displayMetrics = getDisplayMetrics(prompt)) => {
  return {
    custom_goals_accepted: `after ${displayMetrics.goals}`,
    custom_goals_declined: `after ${displayMetrics.declines}`,
    custom_goals_dismissed: CUSTOM_DISPLAYED_METRICS.custom_goals_dismissed,
  };
};

export const newChartHasData = data => {
  if (!data || !data?.length || !Array.isArray(data)) return false;

  return !!data.find(
    mt =>
      !!mt &&
      !!Object.values(mt.data || {}).find(el => !!(typeof el === "object" ? el?.label : el)),
  );
};
