import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      currSequence: state => state.apps.currSequence,
      currPipeline: state => state.apps.currPipeline,
    }),
    nonOverlayTypes() {
      return ["text", "horizontal", "vertical", "tile", "email", "invisible"];
    },
    sequenceId() {
      return this.$route.query.sequence_id;
    },
    pipelineId() {
      return this.$route.query.pipeline_id;
    },
    pipelineStageId() {
      return this.$route.query.pipeline_stage_id;
    },
    overlayOnly() {
      return this.overlayOnlyModal || this.$route.query.overlay_only === "true";
    },
  },
  methods: {
    ...mapActions(["getSequence", "getPipeline"]),
  },
  mounted() {
    const appId = this.$route.params.aid;

    if (this.sequenceId) {
      if (!this.currSequence || this.currSequence.id !== this.sequenceId) {
        this.getSequence({ appId, sequenceId: this.sequenceId });
      }
    }
    if (this.pipelineId && this.pipelineStageId) {
      if (!this.currPipeline || this.currPipeline.id !== this.pipelineId) {
        this.getPipeline({ appId, pipelineId: this.pipelineId });
      }
    }
  },
};
