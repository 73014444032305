export const ApiBaseUrl = process.env.VUE_APP_CONDUIT_ENDPOINT;
export const authenticatedFetch = (url, options) => {
  const idToken = localStorage.getItem("id_token");
  const hasIdToken = !!(idToken && idToken.length);
  const opts = options || {};
  if (!opts.headers) opts.headers = {};
  if (hasIdToken) opts.headers.Authorization = `Bearer ${idToken}`;
  return fetch(url, opts).then(res => {
    if (res.ok) {
      const contentType = res.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) return res.json();
      return Promise.resolve({ body: res.text() });
    }

    if (res.status === 401) return Promise.reject(new Error("You must login to continue"));
    if (res.status === 403) return Promise.reject(new Error("Access denied"));
    if (res.status === 404) return Promise.reject(new Error("Page could not be found"));
    if (res.status === 500)
      return Promise.reject(new Error("A problem occurred please refresh and try again"));

    return res.text().then(text => Promise.reject(new Error(text)));
  });
};
