var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isNewDeviceEditor || _vm.actions.rf_retention_button1_text)?_c('button',{staticClass:"rfmodal-button-yes primary-btn-p",style:(_vm.acceptButtonStyle),attrs:{"text":""},domProps:{"innerHTML":_vm._s(_vm.actions.rf_retention_button1_text)}}):_vm._e(),(
      _vm.isNewDeviceEditor
        ? _vm.actions.rf_settings_confirm_button_2_enabled && _vm.actions.rf_retention_button2_text
        : _vm.actions.rf_settings_confirm_button_2_enabled
    )?_c('button',{staticClass:"rfmodal-button-yes secondary-btn-p",style:(_vm.secondaryButtonStyle),attrs:{"text":""},domProps:{"innerHTML":_vm._s(_vm.actions.rf_retention_button2_text)}}):_vm._e(),(
      _vm.isNewDeviceEditor
        ? _vm.actions.rf_settings_cancel_button_enabled && _vm.actions.rf_retention_button3_text
        : _vm.actions.rf_settings_cancel_button_enabled
    )?_c('button',{staticClass:"rfmodal-button-no",style:(_vm.cancelButtonStyle),attrs:{"text":""},domProps:{"innerHTML":_vm._s(_vm.actions.rf_retention_button3_text)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }