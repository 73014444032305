export default {
  system: { title: "Application", docs: "https://help.redfast.com/docs/setup-your-app" },
  app_messages: "App Messages",
  segment_traits: "User Traits",
  usage_tracking: "Usage Tracking",
  tags: { title: "Tags", action: "Create new tag" },
  triggers: { title: "Triggers", docs: "https://help.redfast.com/docs/triggers-1" },
  connectors: "Integrations",
  zones: { title: "Zones", docs: "https://help.redfast.com/docs/zones" },
  pipelines: "Pipelines",
  users: { title: "Users", docs: "https://help.redfast.com/docs/pulse-users" },
  custom_web_snippet: {
    title: "Custom JS Snippet",
    docs: "https://help.redfast.com/docs/custom-js-snippet",
  },
  download_csv: "Download Data",
  admin: "Admin",
  data_sources: { title: "Data Sources", docs: "https://help.redfast.com/docs/data-sources" },
  aws_marketplace: "Aws Marketplace Settings",
};
