import { serializeQuery } from "@/utils/UrlUtils";
import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiApps {
  static getApps(companyId) {
    let query = "";
    if (companyId && companyId.length) {
      query = `?company_id=${companyId}`;
    }
    return authenticatedFetch(`${ApiBaseUrl}apps${query}`);
  }

  static getApp(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}`);
  }

  static createApp(modelApp) {
    return authenticatedFetch(`${ApiBaseUrl}apps`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ app: modelApp }),
    });
  }

  static updateApp(app) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${app.id}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ app }),
    });
  }

  static deleteApp(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}`, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  static updateAwsMarketplace(appId, clicks) {
    return authenticatedFetch(`${ApiBaseUrl}aws_marketplace/${appId}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ clicks }),
    });
  }

  static imagewizUpdateCfDistro(appId, all) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/imagewiz_update_cf_distro`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ all }),
    });
  }

  static syncSfmc(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/sfmc_sync`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  static purgeSfmc(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/sfmc_purge`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  static publishSdk(appId, sdk_type) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/publish_sdk`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sdk_type }),
    });
  }

  static generateApiKey(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/generate_api_key`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  static getActivities(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/activity`);
  }

  static getAwsCredentials(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/aws_creds`);
  }

  static getAppModel() {
    return authenticatedFetch(`${ApiBaseUrl}apps/new`, {
      method: "GET",
      headers: { Accept: "application/json" },
    });
  }

  static searchEndUsers(appId, query) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/end_users?${query}`, {
      method: "GET",
      headers: { Accept: "application/json" },
    });
  }

  static deleteEndUser(appId, endUserId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/end_users/${endUserId}`, {
      method: "DELETE",
      headers: { Accept: "application/json" },
    });
  }

  static createEndUser(appId, traits) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/end_users`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        end_user: traits,
      }),
    });
  }

  static async getCustomMetricsStatus(appId, params) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;

    if (appId) {
      if (
        params &&
        params.metric_periods &&
        params.metric_periods.find(item => item.period === "custom")
      ) {
        let metricsStatus = {};
        const startTime = +new Date();
        let currTime = 0;
        const timeoutSeconds = 180000;
        while (true) {
          metricsStatus = await authenticatedFetch(
            `${ApiBaseUrl}apps/${appId}/custom_metrics_status${query}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
              },
            },
          );
          currTime = +new Date();

          if (metricsStatus.status === "PROCESSED" || currTime - startTime > timeoutSeconds) {
            break;
          }
          await new Promise(resolve => {
            setTimeout(resolve, 3000);
          });
        }
      }
    }
  }

  static invalidateCache(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/invalidate_cache`, {
      method: "DELETE",
      headers: { Accept: "application/json" },
    });
  }

  static newConnectorAction(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/connector_actions/new`, {
      headers: { Accept: "application/json" },
    });
  }

  static createPinpointApp(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/create_pinpoint_app`, {
      method: "POST",
      headers: { Accept: "application/json" },
    });
  }

  static createSampleData(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/create_sample_data`, {
      method: "POST",
      headers: { Accept: "application/json" },
    });
  }

  static createDefaultPipelines(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/create_default_pipelines`, {
      method: "POST",
      headers: { Accept: "application/json" },
    });
  }
  static createDefaultSequences(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/create_default_sequences`, {
      method: "POST",
      headers: { Accept: "application/json" },
    });
  }

  static verifyPinpointEmail(appId, email) {
    const query = serializeQuery({ email });
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/verify_pinpoint_email?${query}`, {
      method: "POST",
      headers: { Accept: "application/json" },
    });
  }

  static enablePinpointEmails(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/enable_pinpoint_emails`, {
      method: "POST",
      headers: { Accept: "application/json" },
    });
  }

  static connectors(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/connectors`, {
      method: "GET",
      headers: { Accept: "application/json" },
    });
  }

  static sdkActive(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/sdk`, {
      method: "POST",
      headers: { Accept: "application/json" },
    });
  }

  static enablePushChannel(appId, channel) {
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/enable_push_channel?${serializeQuery({ channel })}`,
      { method: "POST", headers: { Accept: "application/json" } },
    );
  }

  static getLiveData(appId, params) {
    let query = serializeQuery(params);
    if (query) query = `?${query}`;

    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/live_feed${query}`, {
      method: "GET",
      headers: { Accept: "application/json" },
    });
  }
}
