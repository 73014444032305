<template>
  <tr class="text-body h-full border-t border-solid border-strokes !text-black-2">
    <td class="py-2 pl-0 pr-4">
      <span class="flex items-center justify-start">
        <RfMenu sameWidth :disabled="props.disableStatusChange || experiment.is_completed">
          <template #activator>
            <span class="inline-flex items-center gap-2 pl-0">
              <RfStatus
                :isActive="experiment.is_active"
                class="flex-shrink-0"
                :isCompleted="experiment.is_completed"
              />
              <span class="text-body !text-black-2">
                {{
                  getExperimentStatusText({
                    isActive: experiment.is_active,
                    isCompleted: experiment.is_completed,
                  })
                }}
              </span>
            </span>
          </template>
          <template #menu>
            <button
              v-if="!experiment.is_active && !experiment.is_completed"
              class="flex w-full items-center gap-3 px-4 py-2 first:!pt-4 last:!pb-4"
              @click="emit('status', { experiment, running: false })"
            >
              <RfStatus :isActive="true" :isCompleted="false" />
              Run
            </button>
            <button
              v-if="experiment.is_active && !experiment.is_completed"
              class="flex w-full items-center gap-3 px-4 py-2 first:!pt-4 last:!pb-4"
              @click="emit('status', { experiment, running: true })"
            >
              <RfStatus :isActive="false" :isCompleted="true" />
              Stop
            </button>
          </template>
        </RfMenu>
      </span>
    </td>
    <td class="px-4 py-2">
      <RouterLink
        :to="getExperimentLink(experiment.id, props.promptId, props.appId)"
        class="text-heading-2 line-clamp-1 overflow-hidden !text-blue-1"
      >
        {{ experiment.name }}
      </RouterLink>
    </td>
    <td class="px-4 py-2">{{ dayjs(experiment.created_date).format(`${dateOrder()}/YY`) }}</td>
    <td class="px-4 py-2">{{ experiment.variations.length }}</td>
    <td class="px-4 py-2 text-right">{{ toLocaleNumberString(users) }}</td>
    <template v-if="!isInvisible">
      <td class="px-4 py-2 text-right">{{ toLocaleNumberString(impressions) }}</td>
      <td class="px-4 py-2 text-right">{{ toLocaleNumberString(clicks) }}</td>
    </template>
    <td class="px-4 py-2 text-right">{{ ctr }}</td>
  </tr>
</template>

<script setup>
import dayjs from "dayjs";
import RfMenu from "@/components/menus/RfMenu.vue";
import { RouterLink } from "vue-router";
import { getExperimentLink } from "@/utils/getLink";
import StatusesConstants from "@/utils/constants/PromptStatusesConstants";
import RfStatus from "@/blocks/RfExperiments/RfStatus.vue";
import { computed } from "vue";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import { interactionDenominators } from "@/utils/constants/PromoInteractionConstants";
import { percentage } from "@/utils/metricsHelpers";
import StringUtils from "@/utils/StringUtils";
import { dateOrder } from "@/utils/DateDisplayUtils";

const emit = defineEmits(["status"]);
const props = defineProps({
  disableStatusChange: { type: Boolean, default: false },
  experiment: { type: Object, default: () => ({}) },
  promptId: { type: String, default: "" },
  appId: { type: String, default: "" },
  isInvisible: { type: Boolean, default: false },
  hasCustomGoal: { type: Boolean, default: false },
  customGoalInteraction: { type: String, default: interactionDenominators.seen },
});

const getExperimentStatusText = ({ isActive, isCompleted }) => {
  if (isActive && !isCompleted) return StatusesConstants.running.title;
  if (!isActive && !isCompleted) return "Not started";
  if (!isActive && isCompleted) return StatusesConstants.ended.title;
  return "";
};

const totalData = (field, skipHoldout, additional) => {
  const count = props.experiment.variations.reduce(
    (acc, el) => (skipHoldout && el.is_control ? acc : acc + el.stats[field]),
    0,
  );
  return additional ? count + additional : count;
};

const clicks = computed(() => {
  if (!props.hasCustomGoal) return totalData("goals", true);
  return totalData(interactionDenominators[props.customGoalInteraction], true);
});

const users = computed(() => {
  if (props.isInvisible) return clicks.value;

  return props.experiment.variations.reduce(
    (acc, el) => acc + el.stats[el.is_control ? "uholdouts" : "uimpressions"],
    0,
  );
});

const impressions = computed(() => {
  const count = props.experiment.variations
    .filter(item => item.is_visible || !item.is_control)
    .reduce((acc, el) => acc + el.stats.impressions, 0);

  if (count >= 0) return count;

  return props.experiment.variations.reduce((acc, el) => acc + el.stats.impressions, 0);
});

const ctr = computed(() =>
  StringUtils.formatStatFixed(
    percentage(clicks.value, props.isInvisible ? users.value : impressions.value),
  ),
);
</script>
