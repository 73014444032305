import { ApiBaseUrl, authenticatedFetch } from "./conduitApi";

export default class ConduitApiActions {
  static getOverrideActions({ actionGroupId, sequenceId }) {
    const variable = sequenceId
      ? `sequence_id=${sequenceId}`
      : actionGroupId
      ? `action_group_id=${actionGroupId}`
      : null;
    if (!variable) throw Error(`sequence_id or action_group_id is required`);
    return authenticatedFetch(`${ApiBaseUrl}actions/rf_override?use_defaults=true&${variable}`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });
  }
}
