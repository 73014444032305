import {
  getDisplayCustomMetrics,
  getDisplayMetrics,
  percentage,
  tally,
} from "@/utils/metricsHelpers";
import { saveToCsvFile } from "@/utils/CsvUtils";

const onlyDisplayed =
  displayed =>
  ([key, _]) =>
    Object.keys(displayed).includes(key);
const namingMapper = displayed => key => displayed[key] || key;

export default {
  computed: {
    onlyDisplayedMetrics() {
      return onlyDisplayed(this.DISPLAYED_METRICS);
    },
    namingMapperMetrics() {
      return namingMapper(this.DISPLAYED_METRICS);
    },
    onlyDisplayedMetricsCustom() {
      return onlyDisplayed(this.CUSTOM_DISPLAYED_METRICS);
    },
    namingMapperMetricsCustom() {
      return namingMapper(this.CUSTOM_DISPLAYED_METRICS);
    },
    DISPLAYED_METRICS() {
      return getDisplayMetrics(this.model || this.path);
    },
    CUSTOM_DISPLAYED_METRICS() {
      return getDisplayCustomMetrics(this.model || this.path, this.DISPLAYED_METRICS);
    },
  },
  methods: {
    exportPipelineToCsv(pipeline, currChartSrc) {
      const contents = [];
      pipeline.pipeline_stages.forEach(stage => {
        const row = [`"${stage.name}"`];
        stage.segments.forEach(segment => {
          const count = this.segmentUsersCount(stage.id, segment.id);
          row.push(`"${segment.name}"`, count);
        });
        contents.push(row);
      });
      const header = [
        "stage",
        "trending up",
        "trending up count",
        "trending down",
        "trending down count",
      ];

      contents.unshift(header);
      contents.push("\u2028", "\u2028", "stage transitions");

      const contents2 = [];
      pipeline.pipeline_stages.forEach(fromStage => {
        pipeline.pipeline_stages.forEach(toStage => {
          const promos = this.getTransitionPromos(fromStage.id, toStage.id);
          if (promos.length > 0) {
            promos.forEach(item => {
              const users = tally(item.data, currChartSrc, "users");
              const goals = tally(item.data, currChartSrc, "goals");
              const convRate = percentage(goals, users, true);
              contents.push([fromStage.name, toStage.name, users, goals, convRate]);
            });
          }
        });
      });

      const header2 = ["from stage", "to stage", "users", "goals", "conv. rate"];
      contents2.unshift(header2);

      saveToCsvFile([...contents, ...contents2], `pipeline_${currChartSrc}`);
    },
  },
};
