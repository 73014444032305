<template>
  <div
    v-if="(showInteraction || showCustomGoalInteraction || showSurvey) && mainChartHasData"
    class="flex flex-col items-center gap-4 lg:flex-row"
  >
    <template
      v-for="chart in [
        {
          vif: showInteraction,
          alterNoData: true,
          title: 'Interactions',
          total: { value: metricsGoalsFormatted.goals.total, text: 'click' },
          data: goalsMetricsForCharts,
        },
        {
          vif: showCustomGoalInteraction,
          title: 'Custom Goal Interactions',
          total: { value: metricsGoalsFormatted.customGoals.total, text: 'click' },
          data: customGoalsMetricsForCharts,
        },
        {
          vif: showSurvey,
          title: 'Survey Interactions',
          total: { value: metricsSurveyFormatted.total, text: 'click' },
          data: surveyMetricsForCharts,
        },
      ]"
    >
      <div v-if="chart.vif" class="flex w-full flex-1 flex-col gap-3 rounded bg-white-1 p-5">
        <Transition mode="out-in">
          <div key="skeleton" v-if="skeleton" class="flex flex-col gap-1">
            <RfBaseSkeleton height="29" width="189" />
            <RfBaseSkeleton height="20" width="100" />
          </div>
          <div key="data" v-else class="flex flex-col gap-1">
            <h2 class="text-heading-1 !text-black-1">{{ chart.title }}</h2>
            <span v-if="chart.total.value" class="text-body">
              {{
                `(${toLocaleNumberString(chart.total.value)} ${StringUtils.pluralize(chart.total.text, +chart.total.value)})`
              }}
            </span>
          </div>
        </Transition>
        <RfChartAdditional
          :data="chart.data"
          :skeleton="skeleton"
          :alterNoData="chart.alterNoData"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
import RfBaseSkeleton from "@/components/skeletons/RfBaseSkeleton.vue";
import { computed } from "vue";
import { chartColors } from "@/utils/constants/ColorsConstants";
import RfChartAdditional from "../RfCharts/RfChartAdditional.vue";
import StringUtils from "@/utils/StringUtils";
import { toLocaleNumberString } from "@/utils/stringHelpers";

const props = defineProps({
  skeleton: { type: Boolean, default: false },
  showInteraction: { type: Boolean, default: false },
  mainChartHasData: { type: Boolean, default: false },
  showCustomGoalInteraction: { type: Boolean, default: false },
  mainChartHasData: { type: Boolean, default: false },
  showSurvey: { type: Boolean, default: false },
  metrics: { type: Object, default: () => ({}) },
  surveyMetrics: { type: Object, default: () => ({}) },
  surveyInputs: { type: Object, default: () => ({}) },
  displayMetrics: { type: Object, default: () => ({}) },
  displayMetricsCustom: { type: Object, default: () => ({}) },
  preset: { type: String, default: "last_seven_days" },
});

const showInteraction = computed(() => props.showInteraction);
const showCustomGoalInteraction = computed(
  () => props.showCustomGoalInteraction && !!metricsGoalsFormatted.value?.goals?.total,
);
const showSurvey = computed(() => props.showSurvey && !!metricsGoalsFormatted.value?.goals?.total);

const metricsGoalsFormatted = computed(() => {
  if (!props.metrics || Object.keys(props.metrics).length === 0)
    return { goals: { metrics: [], total: 0 }, customGoals: { metrics: [], total: 0 } };

  const data = Object.entries(props.metrics).reduce(
    (acc, [key, value]) => {
      if (props.displayMetrics?.[key])
        acc.goals.push({
          label: props.displayMetrics[key],
          value: value[props.preset]?.uniques || value[props.preset]?.total || 0,
        });
      else if (props.displayMetricsCustom?.[key])
        acc.customGoals.push({
          label: props.displayMetricsCustom[key],
          value: value[props.preset]?.uniques || value[props.preset]?.total || 0,
        });

      return acc;
    },
    { goals: [], customGoals: [] },
  );

  const totalGoals = data.goals.reduce((acc, { value }) => acc + value, 0);
  const totalCustomGoals = data.customGoals.reduce((acc, { value }) => acc + value, 0);

  return {
    goals: { metrics: data.goals, total: totalGoals },
    customGoals: { metrics: data.customGoals, total: totalCustomGoals },
  };
});

const goalsMetricsForCharts = computed(() => {
  if (!metricsGoalsFormatted.value.goals.total) return [];
  const colors = [...chartColors];

  return metricsGoalsFormatted.value.goals.metrics.map(({ label, value }) => ({
    label,
    data: [{ key: 1, label: value, raw: value / metricsGoalsFormatted.value.goals.total }],
    backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
    order: 1,
    parsing: { xAxisKey: "raw", yAxisKey: "key" },
    props: { type: "horizontal" },
  }));
});

const customGoalsMetricsForCharts = computed(() => {
  if (!metricsGoalsFormatted.value.customGoals.total) return [];
  const colors = [...chartColors];

  return metricsGoalsFormatted.value.customGoals.metrics.map(({ label, value }) => ({
    label,
    data: [{ key: 1, label: value, raw: value / metricsGoalsFormatted.value.customGoals.total }],
    backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
    order: 1,
    parsing: { xAxisKey: "raw", yAxisKey: "key" },
    props: { type: "horizontal" },
  }));
});

const metricsSurveyFormatted = computed(() => {
  if (!props.metrics || Object.keys(props.metrics).length === 0) return { metrics: [], total: 0 };

  const data = [];

  let i = 0;
  for (const surveyOpt in props.surveyMetrics) {
    data.push({
      label: props.surveyInputs.labels[i],
      value: props.surveyMetrics[surveyOpt] || 0,
    });
    i++;
  }

  const totalSurvey = data.reduce((acc, { value }) => acc + value, 0);
  return { metrics: data, total: totalSurvey };
});

const surveyMetricsForCharts = computed(() => {
  if (!metricsSurveyFormatted.value.total) return [];
  const colors = [...chartColors];

  return metricsSurveyFormatted.value.metrics.map(({ label, value }) => ({
    label,
    data: [{ key: 1, label: value, raw: value / metricsSurveyFormatted.value.total }],
    backgroundColor: colors.splice(~~(Math.random() * colors.length - 1), 1)[0],
    order: 1,
    parsing: { xAxisKey: "raw", yAxisKey: "key" },
    props: { type: "horizontal" },
  }));
});
</script>
