<template>
  <div class="rf-rc-settings-wrapper rf-rc-settings-wrapper-row2">
    <div class="rf-rc-settingss-row2 rf-rc-col1">
      <v-dialog
        persistent
        v-model="connectorsDialog"
        :width="selectedActionTab === 'connectors' ? '960' : '900'"
      >
        <v-card flat>
          <v-card-title class="headline">{{ modalMainTitle }}</v-card-title>
          <v-card-text :class="isEditingRedirect ? 'editing-redirect' : ''">
            <div
              :class="
                isEditing
                  ? 'modal-actions-title edit-header-ttl'
                  : 'modal-actions-title add-header-ttl'
              "
            >
              <span>
                <v-icon v-if="isEditing" size="20" left color="#ffffff">edit</v-icon>
                {{ modalTitle }}
              </span>
              <v-select
                outlined
                hide-details
                dense
                required
                v-model="interactionType"
                :disabled="isEditingRedirect"
                :items="interactionTypes"
                height="34"
                style="margin-right: 10px; max-width: 240px"
              />
              <span v-if="showSurveyOptions"> if selected option is </span>
              <v-select
                v-if="showSurveyOptions"
                outlined
                hide-details
                persistent-placeholder
                dense
                required
                v-model="surveyOptionSelected"
                :disabled="selectedWebsiteAction === 'rf_settings_redirect_location'"
                :items="surveyOptions"
                height="34"
                style="max-width: 240px"
              />
            </div>
            <RfRetentionConnectors
              :model="model"
              :interactionType="interactionType"
              :surveyOptionSelected="surveyOptionSelected"
              v-on:addIntegration="addIntegration"
              v-on:closeConnectorDialog="closeConnectorDialog"
              v-on:selectedWAChanged="selectedWAChanged"
              :thirdParty="thirdPartyData"
              :editIntegration="editingIntegrationTemplate"
              :editIntegrationValue="editingIntegrationValue"
              :clientActions="clientActions"
              :selectedActionTab="selectedActionTab"
              v-if="connectorsDialog"
              :isEditing="isEditing"
              :actions="actions"
              :actionGroupClientActions="actionGroupClientActions"
              v-on:setActionGroupClientActions="setActionGroupClientActions"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <div :class="[`settings-actions-tabs type_${selectedActionTab}`]">
        <div class="external-api-tab" :class="{ selectedTab: selectedActionTab === 'connectors' }">
          <button @click="selectedActionTab = 'connectors'">External & API Actions</button>
        </div>
        <div
          v-if="
            (app?.flags?.custom_devices_js_sdk &&
              model.device_type === DeviceTypeWording.custom_defined.value) ||
            model.device_type === DeviceTypeWording.web.value
          "
          class="website-actions-tab"
          :class="{ selectedTab: selectedActionTab === 'client_actions' }"
        >
          <button @click="selectedActionTab = 'client_actions'">Website Actions</button>
        </div>
      </div>

      <div class="promo-design-group promotion-actions-table">
        <div class="promotion-actions-table-header">
          <div>
            <span v-if="selectedActionTab === 'connectors'"
              >Actions will be executed in order. Drag and drop to change order</span
            >
          </div>
          <RfButton
            v-if="!disabled"
            icon="add"
            button-text="Add action"
            color="accent"
            :disabled="isDisabled || (isControl && selectedActionTab === 'connectors')"
            class="add-connector-action-button"
            @click.stop="connectorsDialog = true"
          />
        </div>

        <v-simple-table v-if="selectedActionTab === 'connectors'">
          <thead>
            <tr>
              <th></th>
              <th class="text-left">Priority</th>
              <th class="text-left">Status</th>
              <th class="text-left">Interaction</th>
              <th class="text-left">Provider</th>
              <th class="text-left">Description</th>
              <th class="text-left">Value</th>
              <th></th>
            </tr>
          </thead>
          <draggable
            v-model="actionGroupConnectorActions"
            v-bind="dragOptions"
            :disabled="!!isDisabled"
            tag="tbody"
            v-if="actionGroupConnectorActions.length"
          >
            <tr v-for="(value, index) in actionGroupConnectorActions" :key="index">
              <td style="width: 30px">
                <v-icon v-if="!isDisabled" left small>drag_indicator</v-icon>
              </td>
              <td style="width: 70px; text-align: center">{{ index + 1 }}</td>
              <td style="width: 70px" align="center">
                <v-icon
                  size="20"
                  color="error"
                  v-if="isActionDisabled(value.connector_action.args, value.interaction_type)"
                  >error</v-icon
                >
                <v-icon size="20" color="success" v-else>check_circle</v-icon>
              </td>
              <td>{{ displayInteractionType(model, value.interaction_type) }}</td>
              <td>{{ capitalize(value.connector_action.provider) }}</td>
              <td>{{ value.connector_action.args.description }}</td>

              <td class="promotion-actions-table-action-value" style="width: 300px">
                <div
                  v-if="value.type !== 'checkbox' && !disabled"
                  @click="editIntegration(value, index)"
                  style="cursor: pointer"
                >
                  <ul>
                    <li v-for="(inputValue, inputKey) in value.args" :key="inputKey">
                      {{
                        displayConnectorAction(
                          inputKey,
                          inputValue,
                          value.connector_action.args.inputs,
                        )
                      }}
                    </li>
                  </ul>
                </div>
                <span v-else>{{ value.args }}</span>
              </td>
              <td style="text-align: right; width: 90px" v-if="!disabled">
                <div class="d-flex align-center">
                  <RfButton
                    v-if="value.type !== 'checkbox'"
                    icon="edit"
                    small
                    color="accent"
                    :disabled="isDisabled"
                    @click="editIntegration(value, index)"
                  />
                  <RfButton
                    icon="clear"
                    small
                    color="error"
                    class="delete-connector-action-button ml-2"
                    :disabled="isDisabled"
                    @click="deleteIntegration(value)"
                  />
                </div>
              </td>
            </tr>
          </draggable>

          <tr v-else>
            <td colspan="8">
              <div class="no-connectors-msg" style="text-align: center">Nothing to show</div>
            </td>
          </tr>
        </v-simple-table>

        <v-simple-table v-else>
          <thead>
            <tr>
              <th class="text-left">Status</th>
              <th class="text-left">Interaction</th>
              <th class="text-left">Provider</th>
              <th class="text-left">Description</th>
              <th class="text-left">Value</th>
              <th class="text-left">Arguments</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="promo-website-actions-tbody" v-if="activeClientActions.length">
            <tr v-for="item in activeClientActions" :key="item.id + previewKey">
              <td style="width: 70px" align="center">
                <v-icon size="20" color="error" v-if="isWebsiteActionDisabled(item)">error</v-icon>
                <v-icon size="20" color="success" v-else>check_circle</v-icon>
              </td>
              <td class="text-left">{{ displayInteractionType(model, item.interaction) }}</td>
              <td class="text-left">Redfast SDK</td>
              <td class="text-left">
                {{ isRedirectAction(item.actionName) ? "Redirect" : "Trigger website action" }}
              </td>
              <td class="text-left">
                <div>
                  <ul>
                    <li>
                      {{ isRedirectAction(item.actionName) ? actions[item.actionName] : item.name }}
                    </li>
                  </ul>
                </div>
              </td>
              <td class="text-left">
                <div class="setting-empty-val" v-if="isRedirectAction(item.actionName)">N/A</div>
                <div v-else>{{ objectToString(item.args) }}</div>
              </td>
              <td style="text-align: right; width: 90px">
                <div class="d-flex align-center">
                  <RfButton
                    v-if="!isRedirectAction(item.actionName)"
                    icon="edit"
                    small
                    color="accent"
                    :disabled="isDisabled"
                    @click="editArgs(item)"
                  />
                  <RfButton
                    icon="clear"
                    small
                    color="error"
                    class="delete-connector-action-button ml-2"
                    :disabled="isDisabled"
                    @click="deleteWebsiteAction(item)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
          <tbody class="promo-website-actions-tbody" v-else>
            <tr>
              <td colspan="8">
                <div class="no-connectors-msg" style="text-align: center">Nothing to show</div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
    <RfBaseModal
      ref="advancedArgsModal"
      title="Advanced Arguments"
      width="550"
      :disabledPrimary="advancedArgsBody?.hasError"
      primaryText="Done"
      @primary="submitArgs"
    >
      <template #body>
        <RfAdvancedArguments ref="advancedArgsBody" />
      </template>
    </RfBaseModal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RfRetentionConnectors from "@/components/RfPathCreate/RfRetentionActions/RfRetentionConnectors.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import draggable from "vuedraggable";
import PromoActionMixin from "@/utils/PromoActionMixin";
import ConnectorsMixin from "@/utils/ConnectorsMixin";
import SurveyMixin from "@/utils/SurveyMixin";
import { cloneDeep } from "lodash-es";
import RfSelect from "@/components/inputs/RfSelect.vue";
import { displayInteractionType, getButtonTitle } from "@/utils/prompts/promptHelpers";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import RfAdvancedArguments from "@/blocks/RfAdvancedArguments.vue";
import { DeviceTypeWording } from "@/utils/constants/DevicesConstants";
import { ref } from "vue";
import ObjectUtils from "@/utils/ObjectUtils";

export default {
  components: {
    RfRetentionConnectors,
    draggable,
    RfButton,
    RfSelect,
    RfBaseModal,
    RfAdvancedArguments,
  },
  props: [
    "actions",
    "model",
    "disabled",
    "action_group_connector_actions",
    "action_type",
    "isDisabled",
    "isControl",
  ],
  mixins: [PromoActionMixin, ConnectorsMixin, SurveyMixin],
  setup: () => {
    const advancedArgsBody = ref();
    return {
      DeviceTypeWording,
      advancedArgsBody,
      displayInteractionType,
      objectToString: ObjectUtils.objectToString,
    };
  },
  data() {
    return {
      connectorsDialog: false,
      actionGroupConnectorActions:
        this.action_group_connector_actions.filter(
          item => item.connectable_type === "ConnectorAction",
        ) || [],
      actionGroupClientActions:
        this.action_group_connector_actions.filter(
          item => item.connectable_type === "ClientAction",
        ) || [],
      editingIntegrationTemplate: {},
      editingIntegrationValue: {},
      isEditing: false,
      dragOptions: {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      selectedActionTab: this.action_type,
      editingIndex: -1,
      interactionType: "accept",
      surveyOptionSelected: "any",
      selectedWebsiteAction: null,
      editArgsTypeDialog: "default",
      editArgsIndex: null,
      previewKey: 0,
    };
  },
  methods: {
    ...mapActions(["getClientActions", "getConnectorActions"]),
    fillModel(model) {
      model.action_group_connector_actions = this.actionGroupConnectorActions.concat(
        this.actionGroupClientActions,
      );
      return { success: true };
    },
    selectedWAChanged(value) {
      this.selectedWebsiteAction = value;
    },
    editWebsiteAction(item) {
      this.interactionType = item.interaction;
      this.isEditing = true;
      this.connectorsDialog = true;
      this.editingIntegrationTemplate = {
        provider: "WebsiteActions",
        description: "Trigger website action",
      };
      this.editingIntegrationValue = {
        value: item.value,
        actionName: item.actionName,
        survey_option_selected: item.survey_option_selected || "any",
      };
      this.editingIndex = -1;
    },
    deleteWebsiteAction(value) {
      if (this.isRedirectAction(value.actionName)) {
        this.actions[value.actionName] = null;
      } else {
        const index = this.actionGroupClientActions.findIndex(
          item =>
            item.interaction_type === value.interaction &&
            item.args?.survey_option_selected === value.args?.survey_option_selected,
        );
        if (index >= 0) {
          this.actionGroupClientActions.splice(index, 1);
        }
      }
    },
    editIntegration(actionData, index) {
      this.interactionType = actionData.interaction_type;
      this.editingIntegrationTemplate = this.createThirdPartyItem(actionData.connector_action);
      this.editingIntegrationValue = cloneDeep(actionData.args);
      this.connectorsDialog = true;
      this.isEditing = true;
      this.editingIndex = index;
    },
    addIntegration(integrationValue, selectedIntegration) {
      if (this.surveyOptionSelected !== "any") {
        integrationValue = {
          ...integrationValue,
          survey_option_selected: this.surveyOptionSelected,
        };
      }

      if (this.editingIndex >= 0) {
        this.actionGroupConnectorActions[this.editingIndex].args = integrationValue;
        this.actionGroupConnectorActions[this.editingIndex].interaction_type = this.interactionType;
      } else {
        const connector = this.appConnectorActions.find(action => {
          return (
            selectedIntegration.operation === action.operation &&
            selectedIntegration.provider === action.provider &&
            selectedIntegration.description === action.args.description
          );
        });
        const action = {
          args: integrationValue,
          connector_action: connector,
          interaction_type: this.interactionType,
        };
        this.actionGroupConnectorActions.push(action);
      }
      this.interactionType = "accept";
      this.surveyOptionSelected = "any";
      this.connectorsDialog = false;
      this.editingIntegrationValue = {};
      this.editingIntegrationTemplate = {};
      this.editingIndex = -1;
      this.isEditing = false;
      this.$emit("forceRender");
    },
    closeConnectorDialog() {
      this.connectorsDialog = false;
      this.editingIntegrationTemplate = {};
      this.editingIntegrationValue = {};
      this.editingIndex = -1;
      this.isEditing = false;
    },
    integrationDisplay(actionData) {
      const { provider, description, value } = actionData;
      if (value) {
        return `${provider} ${description}: ${value}`;
      }
      return "";
    },
    deleteIntegration(actionData) {
      const index = this.actionGroupConnectorActions.indexOf(actionData);
      if (index !== -1) this.actionGroupConnectorActions.splice(index, 1);
      this.$emit("forceRender");
    },
    displayConnectorAction(key, value, inputs) {
      let displayKey = key;
      let displayValue = value;
      const displayData = inputs.find(input => input.value_description === key);
      if (displayData) {
        displayKey = displayData.value_label;
        if (displayData.hide_id) {
          const possibleValues = this.fetchValues(displayData);
          if (possibleValues) {
            const name = possibleValues.find(possibleValue => possibleValue.id === value);
            if (name) {
              displayValue = name.name;
            }
          }
        }
      }
      return `${displayKey}: ${displayValue}`;
    },
    isActionDisabled(args, interaction) {
      const dependencies = args.dependencies || {};
      const appDependencies = args.app_dependencies;
      const providerDependencies = appDependencies && appDependencies.providers;
      interaction = interaction || this.interactionType;

      let dependenciesMet = true;
      let appDependenciesMet = true;
      const interactionEnabled = this.interactionTypes.find(
        type => type === interaction || type.value === interaction,
      );
      if (dependencies.promo_inputs?.length === 1 && dependencies.promo_inputs[0] === "all") {
        dependenciesMet =
          this.promoInputsEnabled && (interaction === "accept" || interaction === "accept2");
      }

      if (providerDependencies && providerDependencies.length) {
        const { providers } = this.app;

        for (let i = 0; i < providerDependencies.length; i++) {
          const dep = providerDependencies[i];
          const [provider, key] = dep.split(".");

          const value = providers[provider] && providers[provider][key];

          if (!value) {
            appDependenciesMet = false;
            break;
          }
        }
      }
      return !(dependenciesMet && appDependenciesMet && interactionEnabled);
    },
    isWebsiteActionDisabled(item) {
      return !!item.error;
    },
    editArgs(item) {
      this.editArgsIndex = this.actionGroupClientActions.findIndex(
        action =>
          action.connector_action.id === item.value &&
          action.interaction_type === item.interaction &&
          action.args?.survey_option_selected === item.args?.survey_option_selected,
      );
      if (this.actionGroupClientActions === -1) return;

      const fieldData = this.clientActions.find(
        ({ id }) => id === this.actionGroupClientActions[this.editArgsIndex].connector_action.id,
      )?.arg_metadata;

      this.$refs.advancedArgsModal.show();
      this.$nextTick(() =>
        this.$refs.advancedArgsBody.init(
          fieldData && !Object.keys(item.args || []).length ? [""] : item.args,
          fieldData,
        ),
      );
    },

    submitArgs() {
      this.triggers.splice(this.editArgsTriggerIndex, 1, {
        ...this.triggers[this.editArgsTriggerIndex],
        custom_trigger_args: this.$refs.advancedArgsBody.get(),
      });
      this.$refs.advancedArgsModal.close();
    },
    submitArgs() {
      this.actionGroupClientActions.splice(this.editArgsIndex, 1, {
        ...this.actionGroupClientActions[this.editArgsIndex],
        args: this.$refs.advancedArgsBody.get(),
      });
      this.$refs.advancedArgsModal.close();
    },
    setActionGroupClientActions(value) {
      this.actionGroupClientActions = value;
      this.previewKey++;
    },
  },
  computed: {
    ...mapState({
      app: state => cloneDeep(state.apps.currApp),
      appClientActions: state => state.apps.clientActions || [],
      appConnectorActions: state => state.apps.connectorActions || [],
    }),
    showSurveyOptions() {
      return (
        this.selectedActionTab === "client_actions" &&
        this.interactionType === "accept" &&
        this.surveySelected
      );
    },
    isEditingRedirect() {
      return (
        this.editingIntegrationValue &&
        this.isRedirectAction(this.editingIntegrationValue.actionName)
      );
    },

    clientActions() {
      if (this.app) {
        const actions = this.appClientActions
          .filter(action => action.action_type === "website_action")
          .map(action => {
            return { text: action.name, value: action.id };
          });
        return actions;
      }
      return [];
    },

    activeClientActions() {
      this.previewKey;
      return this.getActiveActions().map(([key, item, args]) => {
        if (this.isRedirectAction(item)) {
          return {
            name: "Redirect",
            value: key,
            interaction: key.replace("_redirect", ""),
            actionName: item,
          };
        }
        const agca =
          this.actionGroupClientActions.find(action => {
            const interactionMatch =
              action.interaction_type == item && action.connectable_type === "ClientAction";

            return (
              interactionMatch &&
              args?.survey_option_selected === action.args?.survey_option_selected
            );
          }) || {};
        const clientAction = this.clientActions.find(
          action => action.value === agca.connector_action.id,
        ) || {
          text: "WEBSITE ACTION DELETED; PLEASE REMOVE",
          value: null,
          error: true,
        };
        return {
          name: clientAction.text,
          value: clientAction.value,
          interaction: key,
          actionName: item,
          error: clientAction.error,
          args: agca.args,
          id: agca.id,
        };
      });
    },
    modalTitle() {
      if (this.isEditing) {
        return "Edit action upon ";
      }
      return "Add an action upon";
    },
    modalMainTitle() {
      if (this.selectedActionTab === "connectors") {
        return "External & API Actions";
      }
      return "Website Actions";
    },
    providerStatus() {
      if (this.app && this.app.providers) {
        const status = {};
        for (const provider in this.app.providers) {
          status[provider] = this.app.providers[provider].active;
        }
        status.redfast = true;
        return status;
      }
      return {};
    },
    promoInputsEnabled() {
      return !!(
        this.actions.rf_retention_promo_input_1_type ||
        this.actions.rf_retention_promo_input_2_type ||
        this.actions.rf_retention_promo_input_3_type
      );
    },
  },
  watch: {
    action_group_connector_actions(to) {
      if (to) {
        this.actionGroupConnectorActions = to.filter(
          item => item.connectable_type === "ConnectorAction",
        );
        this.actionGroupClientActions = to.filter(item => item.connectable_type === "ClientAction");
      }
    },
    action_type(to) {
      if (to) {
        this.selectedActionTab = this.action_type;
      }
    },
  },
  mounted() {
    if (!this.appClientActions.length) this.getClientActions({ appId: this.app.id });
    if (!this.appConnectorActions.length) this.getConnectorActions({ appId: this.app.id });
  },
};
</script>
<style lang="scss" scoped>
.settings-actions-tabs {
  margin: 0 0 10px 0;
}
</style>
