import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiDataSources {
  static createDataSource(appId, model) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/data_sources`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data_source: model }),
    });
  }

  static getDataSources(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/data_sources`);
  }

  static upsertFromCSV(appId, dataSourceId, csv) {
    return authenticatedFetch(
      `${ApiBaseUrl}apps/${appId}/data_sources/${dataSourceId}/upsert_from_csv`,
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ csv_file: csv }),
      },
    );
  }

  static deleteDataSource(appId, dataSourceId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/data_sources/${dataSourceId}`, {
      method: "DELETE",
    });
  }

  static editDataSource(appId, model) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/data_sources/${model.id}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data_source: model }),
    });
  }
}
