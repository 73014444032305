<template>
  <v-breadcrumbs :items="breadcrumbItems" class="rf-breadcrumbs">
    <template #divider>
      <v-icon>chevron_right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  props: ["pathName", "breadcrumbKey", "sequence", "pipeline"],
  computed: {
    baseUrl() {
      return `/apps/${this.$route.params.aid}`;
    },
    path() {
      return { id: this.$route.params.pid, name: this.pathName };
    },
    breadcrumbItems() {
      const base = [
        {
          text: "Dashboard",
          disabled: false,
          to: `${this.baseUrl}/dashboard`,
        },
      ];

      if (this.breadcrumbKey === "segment-create") {
        return [
          ...base,
          {
            text: "Segments",
            disabled: false,
            exact: true,
            to: `${this.baseUrl}/segments`,
          },
          {
            text: "Create new segment",
            disabled: true,
          },
        ];
      }

      if (this.breadcrumbKey === "retention-creative")
        return [
          ...base,
          ...this.getRetentionCreativeMiddleBreadcrumbs(),
          {
            text: this.path.name,
            disabled: false,
            exact: true,
            to: `${this.baseUrl}/retentions/${this.path.id}`,
          },
          {
            text: "Creative",
            disabled: true,
          },
        ];

      return base;
    },
  },
  methods: {
    getRetentionCreativeMiddleBreadcrumbs() {
      if (this.sequence.id)
        return [
          {
            text: "Guides",
            exact: true,
            disabled: false,
            to: `${this.baseUrl}/experiences`,
          },
          {
            text: this.sequence.name,
            exact: true,
            disabled: false,
            to: `${this.baseUrl}/experiences/${this.sequence.id}`,
          },
        ];

      if (this.pipeline.id)
        return [
          {
            text: "Pipelines",
            exact: true,
            disabled: false,
            to: `${this.baseUrl}/pipelines`,
          },
          {
            text: this.pipeline.name,
            exact: true,
            disabled: false,
            to: `${this.baseUrl}/pipelines/${this.pipeline.id}`,
          },
        ];

      return [
        {
          text: "Prompts",
          exact: true,
          disabled: false,
          to: `${this.baseUrl}/prompts`,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.rf-breadcrumbs {
  padding: 0px 0px 10px;
  li {
    font-size: 13px;
  }
  li:nth-child(even) {
    padding: 0 8px;
  }
}
</style>
