<template>
  <div class="rfmodal-footer-mobileweb" v-if="mobileWeb">
    <RfRetentionButtonsInner :actions="actions" :isNewDeviceEditor="isNewDeviceEditor" />
    <RfPromptTimeout v-if="!isNewDeviceEditor" :actions="actions" mobileWeb />
  </div>
  <div class="rf-button-wrapper" v-else>
    <div class="rf-buttons-inner">
      <RfRetentionButtonsInner :actions="actions" :isNewDeviceEditor="isNewDeviceEditor" />
    </div>
    <RfPromptTimeout v-if="!isNewDeviceEditor" :actions="actions" />
  </div>
</template>

<script>
import RfRetentionButtonsInner from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionButtonsInner.vue";
import RfPromptTimeout from "./RfPromptTimeout.vue";

export default {
  name: "RfRetentionMessageButton",
  props: {
    actions: { type: Object, default: null },
    mobileWeb: { type: Boolean, default: false },
    isNewDeviceEditor: { type: Boolean, default: false },
  },
  components: { RfRetentionButtonsInner, RfPromptTimeout },
};
</script>
