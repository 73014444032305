export const parseJsonSilently = jsonString => {
  try {
    return JSON.parse(jsonString) || {};
  } catch (e) {
    return {};
  }
};

export const stringifyJsonSilently = obj => {
  try {
    return JSON.stringify(obj);
  } catch (e) {
    return "";
  }
};
