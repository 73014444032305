<template>
  <div class="trigger-configuration-wrapper">
    <div class="add-user-header-div">
      <div class="add-user-title">
        <div class="action-provider-settings-title"></div>
      </div>
      <div>
        <RfButton
          icon="add"
          button-text="Add New Trigger"
          class="px-10"
          color="success"
          :disabled="isDisabledRoleMixin"
          @click="showAddNewTriggerDialog"
        />
        <RfButton
          icon="bolt"
          button-text="Add New Live Trigger"
          class="ml-4 px-10"
          color="success"
          :disabled="isDisabledRoleMixin"
          @click.stop="clickLive"
        />
      </div>
    </div>
    <v-dialog scrollable persistent v-model="addNewTriggerDialog" max-width="960" width="960">
      <RfEditTrigger
        :editTrigger="editingTrigger"
        :key="editTriggerKey"
        :creating="creating"
        v-on:closeNewTriggerDialog="closeNewTriggerDialog"
        :app="currApp"
      />
    </v-dialog>
    <v-dialog scrollable v-model="deleteDialog" persistent width="500">
      <v-card>
        <v-card-title class="headline">Delete Trigger</v-card-title>
        <v-card-text>
          Are you sure you want to delete? This cannot be undone.
          <span v-if="deleteTriggerItems.length"> The following prompts would be affected: </span>
          <ul class="list-deleted-trigger">
            <li v-for="item in deleteTriggerItems" :key="item.id">
              <router-link :to="getPromptLink(item.id, $route.params.aid)">{{
                item.name
              }}</router-link>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            large
            depressed
            class="cancel-btn"
            outlined
            @click="closeDeleteDialog"
            style="width: 100px"
            >Cancel</v-btn
          >
          <v-btn large depressed color="error" @click="submitDelete" style="width: 150px"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="all-triggers-options">
      <v-simple-table class="rf-settings-table-data">
        <thead>
          <tr>
            <th class="tbl-col-tr">Name</th>
            <th class="tbl-col-tr">Type</th>
            <th class="tbl-col-4">Prompts</th>
            <th class="tbl-col-tr">Details</th>
            <template v-if="currApp?.flags?.scheduled_push">
              <th class="tbl-col-tr">Event name</th>
              <th class="tbl-col-tr">Event attributes</th>
            </template>
            <th class="tbl-col-4"></th>
          </tr>
        </thead>
        <tbody class="settings-triggers-tbl-ct" v-if="triggers.length">
          <RfTriggerSettingsRow
            v-for="trigger in triggerDisplay"
            :trigger="trigger"
            :isPushEnabled="currApp?.flags?.scheduled_push"
            :key="trigger.id"
            :customTriggers="customTriggers"
            :retentions="retentions"
            @edit="editTrigger(trigger)"
            @remove="openDeleteDialog(trigger.id)"
          />
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="11">
              <div class="settings-triggers-tbl-novalue">
                You don't have any triggers yet. Add a new trigger.
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <RfDomainChooserWrapper
      :app="currApp"
      :visible="livePreviewShow"
      v-on:open="openLivePreview"
      v-on:close="closeLivePreview"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RfDomainChooserWrapper from "@/components/RfCommonCards/RfDomainChooserWrapper.vue";
import LiveMixin from "@/utils/LiveMixin";
import RfEditTrigger from "@/components/RfCommonCards/RfEditTrigger.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { getPromptLink } from "@/utils/getLink";
import RfTriggerSettingsRow from "./RfTriggerSettingsRow.vue";

export default {
  name: "RfTriggerSettings",
  props: ["currApp"],
  components: { RfDomainChooserWrapper, RfEditTrigger, RfButton, RfTriggerSettingsRow },
  mixins: [LiveMixin, RoleMixin],
  setup: () => ({ toastsStore: useToastsStore(), getPromptLink }),
  data() {
    return {
      addNewTriggerDialog: false,
      computeKey: 0,
      deleteDialog: false,
      deleteId: null,
      renderKey: 0,
      updating: false,
      creating: false,
      editingTrigger: null,
      editTriggerKey: 0,
      livePreviewShow: false,
    };
  },
  watch: {
    lastError(to) {
      if (to) {
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
    currTrigger() {
      if (this.updating || this.creating) {
        const action = this.updating ? "updated" : "created";
        this.computeKey++;
        this.toastsStore.create({ type: "success", body: `Trigger ${action} successfully` });
        this.updating = false;
        this.creating = false;
      }
    },
  },
  methods: {
    ...mapActions([
      "getTriggers",
      "deleteTrigger",
      "updateApp",
      "getRetentions",
      "getClientActions",
    ]),
    clickLive() {
      this.livePreviewShow = true;
    },
    showAddNewTriggerDialog() {
      this.editingTrigger = null;
      this.editTriggerKey++;
      this.creating = true;
      this.updating = false;
      this.addNewTriggerDialog = true;
    },

    submitDelete() {
      if (!this.deleteId) retrun;
      this.deleteTrigger({ appId: this.currApp.id, triggerId: this.deleteId })
        .then(() => {
          this.closeDeleteDialog();
          this.toastsStore.create({ type: "success", body: "Trigger Deleted" });
        })
        .catch(() => null);
    },
    editTrigger(trigger) {
      this.editingTrigger = cloneDeep(trigger);
      this.editTriggerKey++;
      this.updating = true;
      this.creating = false;
      this.addNewTriggerDialog = true;
    },
    openDeleteDialog(triggerId) {
      this.deleteDialog = true;
      this.deleteId = triggerId;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.deleteId = null;
    },
    closeNewTriggerDialog() {
      this.addNewTriggerDialog = false;
    },
    openLivePreview(domain) {
      this.openLiveUrl(null, domain, { focus: "triggers" });
      this.closeLivePreview();
    },
    closeLivePreview() {
      this.livePreviewShow = false;
    },
  },
  computed: {
    ...mapState({
      triggers: state => state.apps.triggers,
      lastError: state => state.lastError,
      currTrigger: state => state.apps.currTrigger,
      retentions: state => state.apps.retentions,
      currUser: state => cloneDeep(state.apps.currUser),
      clientActions: state => state.apps.clientActions || [],
    }),
    triggerDisplay() {
      this.computeKey;
      return this.triggers;
    },
    customTriggers() {
      if (this.currApp) {
        const blank = [{ text: "None", value: "" }];
        const actions = this.clientActions.filter(
          action => action.action_type === "custom_trigger",
        );
        return blank.concat(actions);
      }
      return [];
    },
    deleteTriggerItems() {
      if (this.deleteId) {
        return this.retentions.filter(
          retention => !!retention.triggers.find(trigger => trigger.id === this.deleteId),
        );
      }
      return [];
    },
  },
  mounted() {
    this.getTriggers({ appId: this.currApp.id });
    if (!this.retentions.length) this.getRetentions({ appId: this.currApp.id });
    if (!this.clientActions.length) this.getClientActions({ appId: this.currApp.id });
  },
};
</script>
<style scoped>
.config-trigger-specific-pg {
  margin: 6px 0 0 2rem;
}
</style>
