import dayjs from "dayjs";

export const returnFutureEndDate = (endDateOriginal, endDateFuture) =>
  dayjs(endDateOriginal).isAfter(dayjs()) ? endDateOriginal : endDateFuture;

export const getTimeFromPreset = (preset, sinceStartDayRef) => {
  if (preset === "today") return { startDate: dayjs(), endDate: dayjs(), preset: "today" };
  if (preset === "this_week")
    return { startDate: dayjs().startOf("week"), endDate: dayjs(), preset: "this_week" };
  if (preset === "this_month")
    return { startDate: dayjs().startOf("month"), endDate: dayjs(), preset: "this_month" };

  if (preset === "yesterday")
    return {
      startDate: dayjs().subtract(1, "days"),
      endDate: dayjs().subtract(1, "days"),
      preset: "yesterday",
    };
  if (preset === "last_seven_days")
    return { startDate: dayjs().subtract(6, "days"), endDate: dayjs(), preset: "last_seven_days" };
  if (preset === "last_seven_to_fourteen_days")
    return {
      startDate: dayjs().subtract(13, "days"),
      endDate: dayjs().subtract(6, "days"),
      preset: "last_seven_to_fourteen_days",
    };

  if (preset === "last_week") {
    const lastWeek = dayjs().subtract(1, "week");
    return {
      startDate: lastWeek.startOf("week"),
      endDate: lastWeek.endOf("week"),
      preset: "last_week",
    };
  }
  if (preset === "last_month") {
    const lastMonth = dayjs().subtract(1, "month");
    return {
      startDate: lastMonth.startOf("month"),
      endDate: lastMonth.endOf("month"),
      preset: "last_month",
    };
  }

  if (preset === "since_start_date")
    return { startDate: dayjs(sinceStartDayRef), endDate: dayjs(), preset: "since_start_date" };

  return { startDate: dayjs().subtract(6, "days"), endDate: dayjs(), preset: "last_seven_days" };
};
