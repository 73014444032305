<template>
  <div class="segment-builder--wrapper" :class="{ 'show-mode': segmentEditMode }" v-if="notLocked">
    <RfModalLoading :showDialog="isUpdating" />
    <div class="segment-builder-wrapper-content">
      <div>
        <div class="segment-builder-row" v-for="(filter, row) in filters" :key="row + upkey">
          <div class="segment-builder--item _row">
            <v-select
              dense
              outlined
              hide-details
              :items="types"
              item-text="name"
              item-value="value"
              :value="type(filter)"
              @input="changeType($event, row)"
              id="type"
              class="segment-builder--type _type"
              height="32px"
              background-color="#ffffff"
              placeholder="Select configuration"
              :menu-props="{ maxHeight: 410, offsetY: true }"
              :disabled="isReadOnlyRoleMixin"
            />
          </div>
          <div class="segment-builder--item next-selection" v-if="type(filter)">
            <v-icon>chevron_right</v-icon>
            <component
              class="segment-builder--item next-selection _subtypes"
              :is="type(filter)"
              :isPinpoint="isPinpoint"
              :disabled="isReadOnlyRoleMixin"
              v-bind:filter="filter"
              v-bind:model.sync="filters[row]"
            />
          </div>
          <div class="segment-builder-buttons-div">
            <RfButton
              v-if="type(filter) === 'promos'"
              icon="mdi-content-copy"
              :disabled="isDisabledRoleMixin"
              @click="copyRow(row)"
            />

            <RfButton
              v-if="isLastRow(row)"
              icon="add_circle"
              color="primary"
              class="_add"
              :disabled="isDisabledRoleMixin"
              @click="addRow"
            />

            <RfButton
              icon="close"
              color="error"
              class="_remove"
              :disabled="isDisabledRoleMixin"
              @click="removeRow(row)"
            />
          </div>
        </div>
        <div class="segment-builder-save-row">
          <RfButton
            icon="save"
            button-text="Save"
            color="success"
            :disabled="isDisabledRoleMixin || !updating"
            style="width: 170px"
            @click="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEmpty, union, defaultTo } from "lodash-es";
import { mapActions, mapState } from "vuex";
import RfUser from "@/components/RfSegments/RfUser.vue";
import RfUsage from "@/components/RfSegments/RfUsage.vue";
import RfCustom from "@/components/RfSegments/RfCustom.vue";
import RfDevice from "@/components/RfSegments/RfDevice.vue";
import RfLocation from "@/components/RfSegments/RfLocation.vue";
import RfPromoInteractions from "@/components/RfSegments/RfPromoInteractions.vue";
import RfModalLoading from "@/views/RfModalLoading.vue";
import RoleMixin from "@/utils/RoleMixin";
import RfButton from "../buttons/RfButton.vue";
import {
  getUsageFilterData,
  customOrUsageFilters,
  getCustomFiltersData,
  isUserFilterDisabled,
} from "@/utils/segmentsHelpers";
import { UserFilterItems } from "@/utils/constants/SegmentsConstants";
import { getSegmentTypeDevices } from "@/utils/constants/DevicesConstants";
import RfUserBucket from "./RfUserBucket.vue";
import RfPinpointChannels from "./RfPinpointChannels.vue";
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";

const pinpointAllowedFilters = ["custom", "channels"];
const fraudCheckAllowedFilter = ["user"];
const customSegmentAllowedFilters = [
  "usage",
  "user_bucket",
  "device",
  "location",
  "promos",
  "custom",
];

const segmentFilters = [
  { name: "Usage", value: "usage" },
  { name: "Users", value: "user" },
  { name: "User", value: "user_bucket" },
  { name: "Device", value: "device" },
  { name: "Location", value: "location" },
  { name: "Interactions", value: "promos" },
  { name: "Channel type", value: "channels" },
  { name: "Custom", value: "custom" },
];
export default {
  name: "RfSegmentBuilder",
  mixins: [RoleMixin, CustomFieldsMixin],
  props: ["segmentEditMode"],
  // key needs to match value in types to load comp dynamically
  components: {
    usage: RfUsage,
    user: RfUser,
    user_bucket: RfUserBucket,
    device: RfDevice,
    location: RfLocation,
    custom: RfCustom,
    channels: RfPinpointChannels,
    promos: RfPromoInteractions,
    RfModalLoading,
    RfButton,
  },
  data: () => ({
    upkey: 0,
    filters: [],
    updating: false,
    loading: true,
    isUpdating: false,
    panel: null,
  }),
  computed: {
    ...mapState({ app: state => state.apps.currApp, segment: state => state.apps.currSegment }),
    isPinpoint() {
      return this.segment?.segment_type === "pinpoint";
    },
    types() {
      return segmentFilters.filter(el =>
        this.isPinpoint
          ? pinpointAllowedFilters.includes(el.value)
          : (!this.isFraudCheckOn
              ? customSegmentAllowedFilters
              : customSegmentAllowedFilters.concat(fraudCheckAllowedFilter)
            ).includes(el.value),
      );
    },
    notLocked() {
      return !this.segment.is_locked;
    },

    isFraudCheckOn() {
      return this.app.flags.fraud_check;
    },

    deviceFilters() {
      return this.segment.filter.device;
    },

    locationFilters() {
      return this.segment.filter.location;
    },

    userBucketFilters() {
      return this.segment.filter.user_bucket;
    },

    channelTypesFilter() {
      return this.segment.filter.connectors?.pinpoint?.channel_types;
    },

    userFilters() {
      return this.segment.filter.user;
    },

    userIdFilters() {
      return this.segment.filter.user_id;
    },

    promosFilters() {
      const promos = this.segment.custom_filter.promo_interaction || {};
      const values = promos.values || [];

      return values.reduce((h, filter) => {
        return {
          ...h,
          [filter.promo_slug]: filter,
        };
      }, {});
    },
  },

  watch: {
    segment() {
      this.loading = true;
      this.prepareFilters();
    },
    filters(newVal, oldVal) {
      this.updating = !isEmpty(oldVal) && !this.loading;
    },
  },

  methods: {
    ...mapActions(["updateSegment"]),

    prepareFilters() {
      if (!this.segment) {
        this.filters = [{}];
        return;
      }

      this.filters = [];

      this.addFilters(
        "usage",
        customOrUsageFilters(getUsageFilterData(this.appCustomFields), this.segment.custom_filter),
      );
      this.addFilters("user", this.userFilters);
      this.addFilters("device", this.deviceFilters);
      this.addFilters("location", this.locationFilters);
      this.addFilters(
        "custom",
        customOrUsageFilters(
          getCustomFiltersData(this.appCustomFields),
          this.segment.custom_filter,
        ),
      );
      this.addFilters("promos", this.promosFilters);
      this.addFilters("user_bucket", this.userBucketFilters);
      this.addFilters("user_id", this.userIdFilters);
      this.addFilters("channels", this.channelTypesFilter);

      if (!this.filters?.length) this.filters = [{}];
      this.incrKey();
    },

    validDeviceFilter(data) {
      return Object.keys(getSegmentTypeDevices(this.app?.flags?.custom_devices)).includes(data);
    },

    validUsageFilter(data) {
      if (isEmpty(data)) return false;

      // usage struct is {options: {}, values: []}
      let options = data.options || {};
      options = [
        options.trend,
        options.frequency,
        options.range_type,
        options.time_period_days,
      ].some(Boolean);

      const values = !isEmpty(data.values);

      return options && values;
    },

    validUserBucketFilter(data) {
      if (isEmpty(data)) return false;
      if (Array.isArray(data) && data.filter(el => [null, "-INF", ""].includes(el)).length)
        return false;
      return true;
    },

    validCustomFilter(data) {
      return !isEmpty((data || {}).values);
    },

    validUserFilter(data) {
      const filter = UserFilterItems[data] || {};

      return this.isFraudCheckOn && !isUserFilterDisabled(filter.field, this.appSystemFields);
    },

    validPromoFilter(data) {
      const promo = data || {};

      const isValid = [
        promo.promo_slug,
        promo.matchTypeModel !== null, // needs to return true for true/false
        (promo.interaction_types || []).length,
      ];

      return isValid.every(Boolean);
    },

    // parse and add filter from db to filters array
    addFilters(type, conditions) {
      if (type === "user_bucket") {
        if (!this.validUserBucketFilter(conditions?.range)) return;
        this.filters.push({
          user_bucket: {
            user_bucket: { range: [...conditions.range], range_type: conditions.range_type },
          },
        });
      } else if (type === "user_id") {
        if (isEmpty(conditions?.values)) return;
        this.filters.push({
          user_bucket: {
            user_id: { values: [...conditions.values], negative_match: conditions.negative_match },
          },
        });
      } else if (type === "channels") {
        if (isEmpty(conditions)) return;
        this.filters.push({ channels: [...conditions] });
      } else {
        Object.keys(conditions || []).forEach(criteria => {
          const values = conditions[criteria];

          if (["location", "device", "user", "promos"].includes(type) && isEmpty(values)) return;
          if (type === "device" && !this.validDeviceFilter(criteria)) return;
          if (type === "custom" && !this.validCustomFilter(values)) return;
          if (type === "usage" && !this.validUsageFilter(values)) return;
          if (type === "user" && !this.validUserFilter(criteria)) return;

          const item = { [type]: { [criteria]: values } };

          this.filters.push(item);
        });
      }
    },

    async save() {
      const segment = cloneDeep(this.segment);

      const data = {};
      const promos = new Set();
      let channels = [];

      this.filters.forEach(filter => {
        const type = this.keyName(filter);
        if (isEmpty(type)) return;

        const criteria = this.keyName(filter[type]);
        let values = filter[type][criteria];

        // merge existing values
        if (["location", "device"].includes(type)) {
          const currentValues = defaultTo(data[type], {})[criteria];
          values = union(defaultTo(currentValues, []), values);
          values = values.filter(Boolean);
        }

        if (!data[type]) data[type] = {};

        if (type === "promos") {
          if (this.validPromoFilter(values)) promos.add(values);

          return;
        }
        if (filter.location_configs && filter.location_configs[criteria]) {
          if (!data.location_configs) data.location_configs = {};
          data.location_configs[criteria] = filter.location_configs[criteria];
        }

        if (type === "channels") {
          if (isEmpty(filter[type])) return;
          return (channels = filter[type]);
        }
        if (type === "user_bucket") {
          if (!data[criteria]) data[criteria] = {};
          if (criteria === "user_bucket") {
            data[criteria].range_type = filter[type][criteria].range_type;
            data[criteria].range = filter[type][criteria].range;
          }
          if (criteria === "user_id") {
            data[criteria].negative_match = filter[type][criteria].negative_match;
            data[criteria].values = filter[type][criteria].values;
          }
        } else {
          data[type][criteria] = values;
        }
      });

      if (promos.size) {
        data.promos = { promo_interaction: { values: [...promos] } };
      }

      segment.filter.device = data.device;
      segment.filter.location = data.location;
      segment.filter.user = data.user;
      segment.filter.user_id = data.user_id;
      segment.filter.user_bucket = data.user_bucket;
      segment.filter.location_configs = data.location_configs;
      segment.filter.connectors = {
        ...segment.filter?.connectors,
        pinpoint: { ...segment.filter.connectors?.pinpoint, channel_types: channels },
      };

      segment.custom_filter = { ...data.usage, ...data.custom, ...data.promos };
      this.isUpdating = true;
      await this.updateSegment({
        appId: this.$route.params.aid,
        segId: this.$route.params.sid,
        modelSegment: segment,
      })
        .then(() => (this.isUpdating = this.updating = false))
        .catch(() => null);
    },

    type(filter) {
      return this.keyName(filter);
    },

    keyName(dict) {
      return Object.keys(dict)[0];
    },

    addRow() {
      this.filters.push({});
      this.incrKey();
    },

    copyRow(row) {
      this.filters.splice(this.filters.length - 1, 0, { ...this.filters[row] });
    },

    removeRow(row) {
      if (this.filters.length > 1) {
        this.filters.splice(row, 1);
      } else {
        this.filters = [{}];
      }

      this.incrKey();
    },

    changeType(type, row) {
      this.filters[row] = { [type]: {} };
      this.incrKey();
    },

    isLastRow(row) {
      return row + 1 === this.filters.length;
    },

    incrKey() {
      this.upkey += Math.random();
    },
  },

  async mounted() {
    const app = this.app || this.currApp || this.mixinApp;
    if (!this.appCustomFields.length)
      await Promise.all([
        this.getAppCustomFields({ appId: app.id, fieldType: "custom" }),
        this.getAppCustomFields({ appId: app.id, fieldType: "system" }),
      ]);
    this.prepareFilters();
  },

  updated() {
    this.loading = false;
  },
};
</script>
