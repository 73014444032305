<template>
  <div class="rf-selections-chips">
    <v-autocomplete
      class="sgmt-selections-drpdwn"
      :items="[...selectors].sort((a, b) => `${a.name}`.localeCompare(`${b.name}`))"
      :search-input.sync="search"
      chips
      label="Available Segments"
      :multiple="multiple"
      outlined
      single-line
      item-text="name"
      item-value="id"
      :value="
        multiple ? [...value].sort((a, b) => `${a.name}`.localeCompare(`${b.name}`)) : value?.[0]
      "
      validate-on-blur
      return-object
      :rules="[rules.required]"
      :menu-props="{ maxHeight: 410, offsetY: true }"
      hide-details
      deletable-chips
      @input="addItem"
    />
  </div>
</template>

<script>
export default {
  name: "RfSelectionWithChips",
  props: {
    title: String,
    selectors: Array,
    value: Array,
    singleline: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
  },
  data() {
    return {
      search: "",
      rules: { required: value => !!value.length || "Select one or more segments." },
    };
  },
  methods: {
    addItem(value) {
      if (!value) return;

      if (this.multiple) {
        const index = this.value.findIndex(el => el.id === value.id);
        if (index !== 1) this.$emit("input", value);
      } else {
        this.$emit("input", [value]);
      }

      this.search = "";
    },
  },
};
</script>
