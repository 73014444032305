<template>
  <div>
    <RfPromoDetailsInfoRow title="Identifier">
      <template v-slot:promodetail>
        <div v-if="path.zone">
          {{ renderIdentifier }}
        </div>
      </template>
    </RfPromoDetailsInfoRow>
  </div>
</template>
<script>
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";

export default {
  name: "RfPromoZone",
  props: ["path", "pathGroupDisplay"],
  components: { RfPromoDetailsInfoRow },
  data() {
    return {
      zone: this.path.zone,
    };
  },
  computed: {
    renderIdentifier() {
      if (this.path.device_type === "web") {
        return `<div data-rf-zone="${this.path.zone}"></div>`;
      }
      return this.path.zone;
    },
  },
  methods: {},
  watch: {
    path(to) {
      this.zone = to.zone;
    },
  },
};
</script>
