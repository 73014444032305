<template>
  <v-card class="rf-segment-detail-live-content">
    <div class="segment-overview-header segment-stat-h">
      <h4>USERS</h4>
      <RfButton
        v-if="canEditSegment && canEdit"
        :button-text="segmentEditMode ? '' : 'Configuration'"
        small
        text
        tile
        iconRight
        class="rounded"
        :icon="segmentEditMode ? 'expand_less' : 'expand_more'"
        @click="showHideForm"
      />
    </div>
    <div class="segment-builder-div-wrapper">
      <RfSegmentBuilder :segmentEditMode="segmentEditMode" v-on:showHideForm="showHideForm" />
    </div>
    <div class="segment-overview-stat-row">
      <div class="segment-performance-stats">
        <div>
          <div class="performance-stats-label">Total Users</div>
          <div class="performance-stats-value">{{ totalUsersText }}</div>
        </div>
      </div>
      <div class="chart-filter-wrapper">
        <div class="chart-filter-date-select">
          <div class="chart-filter-label">Date range:</div>
          <RfMetricsDateRangeSelector
            parentPage="segments"
            :key="model.id"
            v-on:fetchMetrics="fetchMetrics"
            v-on:fetchMetricsCustom="fetchMetricsCustom"
            :sinceStartDate="false"
          />
        </div>
        <div class="compare-chart-opt" v-if="isValidComparison(currChartSrc)">
          <span class="compare-chart-label">Compare with:</span>
          <v-checkbox
            v-model="compareChartSrc"
            :true-value="getCompareChartVal"
            :false-value="null"
            v-if="getCompareChartVal"
            :label="getCompareChartLabel"
            hide-details
          ></v-checkbox>
        </div>
      </div>
    </div>
    <v-divider></v-divider>

    <div class="rf-chart-wrapper">
      <v-row>
        <RfNoChartDataPlaceholder
          v-if="
            !getMetrics?.[currChartSrc]?.data?.reduce((acc, el) => acc + el.count, 0) && isPinpoint
          "
          class="mx-auto py-5"
          body="Segment Estimates are coming soon"
        />
        <v-col v-else-if="getMetrics" cols="12">
          <RfSegmentChart
            :usage_type="usage_type"
            :metrics="getMetrics"
            :metricsKey="currChartSrc"
            :compareMetricsKey="compareChartSrc"
            :singleDayCustom="singleDayCustom"
          />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfSegmentChart from "@/components/RfSegmentChart.vue";
import MetricsUtils from "@/utils/MetricsUtils";
import MetricsMixin from "@/utils/MetricsMixin";
import RfMetricsDateRangeSelector from "@/components/RfCommonCards/RfMetricsDateRangeSelector.vue";
import RfSegmentBuilder from "@/components/RfSegments/RfSegmentBuilder.vue";
import UserSettingsMixin from "@/utils/UserSettingsMixin.js";
import RfButton from "@/components/buttons/RfButton.vue";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import RfNoChartDataPlaceholder from "../placeholder/RfNoChartDataPlaceholder.vue";
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";

export default {
  name: "RfLiveStatistics",
  props: ["metrics", "model", "usage_type", "currChartSrc"],
  mixins: [MetricsMixin, UserSettingsMixin, CustomFieldsMixin],
  components: {
    RfSegmentChart,
    RfMetricsDateRangeSelector,
    RfSegmentBuilder,
    RfButton,
    RfNoChartDataPlaceholder,
  },
  data() {
    return {
      compareChartSrc: null,
      compareKey: `segmentsCompare`,
      usageKey: `segmentsUsage`,
      singleDayCustom: false,
      segmentEditMode: true,
    };
  },
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
    isPinpoint() {
      return this.model?.segment_type === "pinpoint";
    },
    canEditSegment() {
      return [
        "custom",
        "pipeline_usage_increasing",
        "pipeline_usage_decreasing",
        "pipeline_usage_consistent",
      ].includes(this.model.segment_type);
    },
    canEdit() {
      return !this.model.is_locked;
    },
    getCompareChartVal() {
      return MetricsUtils.Comparisons[this.currChartSrc];
    },
    getCompareChartLabel() {
      const result = MetricsUtils.All_Periods.find(item => item.value === this.getCompareChartVal);
      return result?.name || null;
    },
    totalUsers() {
      return this.metrics?.users?.[this.currChartSrc]?.uniques || 0;
    },
    totalUsersText() {
      return this.totalUsers ? toLocaleNumberString(this.totalUsers) : "N/A";
    },
    getMetrics() {
      if (!this.metrics || Object.keys(this.metrics).length === 0) return [];
      return this.metrics[this.usage_type];
    },
  },
  methods: {
    ...mapActions(["getSegmentMetrics"]),
    showHideForm() {
      this.segmentEditMode = !this.segmentEditMode;
    },
    isValidComparison(metric) {
      return !!MetricsUtils.Comparisons[metric];
    },
    fetchMetrics(timePeriod) {
      this.singleDayCustom = false;
      if (timePeriod === "since_start_date" && this.model.start_date) {
        const now = new Date();
        const datestring = `${now.getFullYear()}-${`0${now.getMonth() + 1}`.slice(
          -2,
        )}-${`0${now.getDate()}`.slice(-2)}`;
        this.singleDayCustom = datestring === this.model.start_date;
      }

      if (this.compareChartSrc) {
        this.compareChartSrc = MetricsUtils.Comparisons[timePeriod];
      }
      if (!this.metrics[timePeriod]) {
        const metric_periods = [
          { period: timePeriod },
          ...(MetricsUtils.Comparisons[timePeriod]
            ? [{ period: MetricsUtils.Comparisons[timePeriod] }]
            : []),
        ];

        const custom_field_id = this.isValidUsage(this.getUserSetting(this.usageKey));
        this.getSegmentMetrics({
          appId: this.$route.params.aid,
          id: this.$route.params.sid,
          params: { metric_periods, custom_field_id },
        });
      }
      this.$emit("update:curr-chart-src", timePeriod);
      this.$emit("update:date-range", null);
    },
    fetchMetricsCustom(timePeriod) {
      if (timePeriod.length) {
        this.singleDayCustom = timePeriod[0] === timePeriod[1];

        const custom_field_id = this.isValidUsage(this.getUserSetting(this.usageKey));
        this.getSegmentMetrics({
          appId: this.$route.params.aid,
          id: this.$route.params.sid,
          params: {
            metric_periods: [{ period: "custom", date_range: timePeriod }],
            custom_field_id,
          },
        });
      }
      this.$emit("update:curr-chart-src", "custom");
      this.$emit("update:date-range", timePeriod);
    },
    isValidUsage(customFieldId) {
      if (!this.currApp || !customFieldId) return null;
      const field = this.appCustomFields
        .filter(item => item.connector_type === "redfast" && item.is_usage)
        .find(item => item.id === customFieldId);
      if (field) return field.id;
      return null;
    },
  },
  mounted() {
    const filter = this.getUserSetting(this.compareKey);
    if (filter) {
      this.compareChartSrc = filter;
    }
  },
  watch: {
    compareChartSrc(to, from) {
      if (to !== from) {
        this.setUserSetting(this.compareKey, to);
      }
    },
  },
};
</script>
