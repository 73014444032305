<template>
  <RfBaseModal
    ref="modalRef"
    title="Create new tag"
    width="459"
    secondaryText="Create"
    closeText="Cancel"
    :disabledPrimary="
      (!tag.color && 'Color is required') || (!tag.title && 'Name field is required')
    "
    @close="tag = { title: defaultTitle, description: defaultDescr, color: defaultColor }"
    @secondary="emit('create', tag) && close()"
  >
    <template #body>
      <div class="flex flex-col">
        <RfTextInput
          dense
          outlined
          persistent-hint
          class="pb-1"
          placeholder="Tag 1"
          counter="15"
          maxlength="15"
          title="Name"
          :rules="[value => !!value || 'Name field is required.']"
          v-model.trim="tag.title"
        ></RfTextInput>
        <RfTextInput
          dense
          outlined
          persistent-hint
          data-cy="element-info--name"
          placeholder="A tag for..."
          title="Description (optional)"
          class="pb-1"
          v-model.trim="tag.description"
        ></RfTextInput>
        <div class="flex flex-col gap-1">
          <div>Color</div>
          <div class="flex gap-1">
            <RfColorTag
              v-for="color in tagsColors"
              :backgroundColor="color"
              class="h-10 w-10"
              :selected="tag.color === color"
              @click="tag.color = color"
            />
          </div>
        </div>
      </div>
    </template>
  </RfBaseModal>
</template>

<script setup>
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import RfColorTag from "@/components/tags/RfColorTag.vue";
import { tagsColors } from "@/utils/constants/ColorsConstants";
import { ref } from "vue";

const emit = defineEmits(["create"]);

const defaultTitle = "";
const defaultDescr = "";
const defaultColor = tagsColors[0];
const modalRef = ref();
const tag = ref({ title: defaultTitle, description: defaultDescr, color: defaultColor });

const show = () => modalRef.value?.show();
const close = () => modalRef.value?.close();

defineExpose({ show, close });
</script>
