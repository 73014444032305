/**
 * Description
 * @param {Number} value
 * @returns {String}
 */
export const toLocaleNumberString = (value, fraction = 0) => {
  if (typeof value !== "number" && !(value instanceof Number)) value = +value;
  return Number.isNaN(value)
    ? "0"
    : value.toLocaleString(undefined, {
        minimumFractionDigits: fraction,
        maximumFractionDigits: fraction,
      });
};
