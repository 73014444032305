<template>
  <div class="chart-filter-customgoal-select">
    <div class="chart-filter-label" v-if="!persistLabel">
      {{ capitalize(goalsTitle) }} Interaction:
    </div>
    <v-select
      v-if="customGoalInteractionItems.length > 1"
      hide-details
      dense
      outlined
      :value="customGoalInteraction"
      :items="customGoalInteractionItems"
      height="32"
      style="max-width: 200px"
      :label="persistLabel ? `${goalsTitle} Interaction` : null"
      :menu-props="{ offsetY: true }"
      @input="mutateCustomGoalInteraction"
    />
  </div>
</template>

<script>
import CustomGoalInteractionMixin from "@/utils/CustomGoalInteractionMixin";
import MetricsMixin from "@/utils/MetricsMixin";
import StringUtils from "@/utils/StringUtils";
import { mapMutations } from "vuex";

export default {
  name: "RfCustomGoalInteractionSelector",
  setup: () => ({ capitalize: StringUtils.capitalize }),
  props: ["model", "persistLabel"],
  mixins: [MetricsMixin, CustomGoalInteractionMixin],
  computed: {
    actions() {
      return this.model.actions || {};
    },
    customGoalInteractionItems() {
      const items = [];

      if (this.model.path_type !== "invisible") {
        items.push({ value: "seen", text: this.interactionText.seen });
      }

      items.push({ value: "accepted", text: this.interactionText.accepted });

      if (this.actions.rf_settings_confirm_button_2_enabled) {
        items.push({ value: "accepted2", text: this.interactionText.accepted2 });
      }
      if (this.actions.rf_settings_cancel_button_enabled === "true") {
        items.push({ value: "declined", text: this.interactionText.declined });
      }
      if (this.dismissEnabled) {
        items.push({ value: "dismissed", text: this.interactionText.dismissed });
      }
      return items;
    },
    dismissEnabled() {
      return (
        this.actions.rf_settings_click_outside_close_enabled === "true" ||
        this.actions.rf_settings_close_button_enabled === "true"
      );
    },
  },
  mounted() {
    this.mutateCustomGoalInteraction(this.customGoalInteractionValue());
  },
  destroyed() {
    this.mutateCustomGoalInteraction("accepted");
  },
  methods: {
    ...mapMutations(["mutateCustomGoalInteraction"]),
    customGoalInteractionValue() {
      if (this.model.path_type === "invisible") return "accepted";
      return "seen";
    },
  },
};
</script>
<style scoped>
.chart-filter-customgoal-select {
  display: flex;
  align-items: center;
}
.chart-filter-label {
  padding-right: 10px;
}
</style>
