<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <span>
        <div>
          {{ dataSourceLocal.name }}
          <RfButton
            icon="edit"
            color="accent"
            icon-size="16"
            class="ml-2 mt-n1"
            x-small
            :disabled="isDisabledRoleMixin || dataSourceLocal.is_locked"
            @click.stop="$refs.renameModal.show()"
          >
            <RfRenameModal
              ref="renameModal"
              :title="`Edit datasource ${dataSourceLocal.name}`"
              :name="dataSourceLocal.name"
              :description="dataSourceLocal.description"
              :onSubmit="v => onEdit(v)"
            />
          </RfButton>
        </div>
        <div v-if="dataSourceLocal.description" class="text-caption text--secondary text-none">
          {{ dataSourceLocal.description }}
        </div>
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content eager>
      <div class="d-flex flex-column">
        <div class="mr-0 ml-auto d-flex align-center my-5">
          <RfButton
            color="accent"
            icon="upload"
            class="mr-2"
            :disabled="isDisabledRoleMixin"
            button-text="Upload CSV"
            @click="$refs.csvInput.click()"
          >
            <input
              type="file"
              class="d-none"
              :disabled="!!isDisabledRoleMixin"
              ref="csvInput"
              accept=".csv"
              @change="v => $emit('uploadCSV', v, dataSourceLocal.id)"
            />
          </RfButton>
          <RfButton
            color="error"
            icon="clear"
            :disabled="isDisabledRoleMixin"
            button-text="Delete"
            @click="$emit('remove', dataSourceLocal)"
          >
          </RfButton>
        </div>
        <v-simple-table class="rf-settings-table-data">
          <thead>
            <th>Name</th>
            <th>Settings</th>
          </thead>
          <tbody v-if="Object.keys(dataSourceLocal.properties).length">
            <tr v-for="(settings, field) in dataSourceLocal.properties" :key="field">
              <td>{{ field }}</td>
              <td>
                <v-checkbox
                  v-for="(value, key) in settings"
                  :label="capitalize(getPrettyPropertyName(key))"
                  class="mt-0 pt-0"
                  :key="key"
                  :disabled="
                    isDisabledRoleMixin ||
                    isAllowedToUseAsDisplayName(field, key, dataSourceLocal.properties)
                  "
                  hide-details
                  :input-value="value"
                  @change="
                    v => {
                      dataSourceLocal.properties[field][key] = v;
                      editDataSource();
                    }
                  "
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <div class="settings-no-data-message">Nothing to show</div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { cloneDeep, debounce } from "lodash-es";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import RfRenameModal from "@/components/modals/RfRenameModal.vue";
import StringUtils from "@/utils/StringUtils";

export default {
  name: "RfDataSourceSettings",
  components: { RfButton, RfRenameModal },
  mixins: [RoleMixin],
  props: ["dataSource"],
  setup: () => ({ capitalize: StringUtils.capitalize }),
  data() {
    return {
      dataSourceLocal: cloneDeep(this.dataSource),
      editDataSource: debounce(function () {
        this.$emit("edit", this.dataSourceLocal);
      }, 2000),
    };
  },
  methods: {
    onEdit(v) {
      this.$emit("edit", { ...this.dataSourceLocal, ...v });
    },
    getPrettyPropertyName(key) {
      if (key === "use_as_display_name") return "Use as display name";
      return key;
    },
    isAllowedToUseAsDisplayName(field, key, properties) {
      if (key !== "use_as_display_name") return false;
      if (Object.entries(properties || {}).find(([f, v]) => f !== field && v.use_as_display_name))
        return true;
      return false;
    },
  },
  watch: {
    dataSource: {
      handler(v) {
        this.dataSourceLocal = cloneDeep(v);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.rf-settings-table-data th {
  width: 50%;
}
</style>
