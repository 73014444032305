export default class VuexUtils {
  static combineMetrics(currMetrics, newMetrics) {
    if (Object.keys(currMetrics).length === 0) {
      return newMetrics;
    }
    const items = Object.entries(currMetrics).reduce((combinedMetricsHash, [key, value]) => {
      combinedMetricsHash[key] = {
        ...value,
        ...newMetrics[key],
      };
      return combinedMetricsHash;
    }, {});
    return { ...newMetrics, ...items };
  }

  static combineModelListMetrics(modelList, newModelList) {
    const idsWithData = modelList.reduce((modelsByIds, item) => {
      modelsByIds[item.id] = item.data;
      return modelsByIds;
    }, {});
    const items = newModelList.map(item => {
      const data = Object.entries(item.data).reduce((combinedMetricsHash, [key, value]) => {
        const originalValues = (idsWithData[item.id] || {})[key] || {};
        combinedMetricsHash[key] = {
          ...value,
          ...originalValues,
        };
        return combinedMetricsHash;
      }, {});

      item.data = data;
      return item;
    });
    return items;
  }
}
