export const USStates = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "California": "CA",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Delaware": "DE",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "Virginia": "VA",
  "Washington": "WA",
  "West Virginia": "WV",
  "Wisconsin": "WI",
  "Wyoming": "WY",
  "Washington DC": "D.C.",
};

export const DMAs = {
  662: "Abilene - Sweetwater",
  532: "Albany - Schenectady - Troy",
  525: "Albany, GA",
  790: "Albuquerque - Santa Fe",
  644: "Alexandria, LA",
  583: "Alpena",
  634: "Amarillo",
  743: "Anchorage",
  524: "Atlanta",
  520: "Augusta",
  635: "Austin, TX",
  800: "Bakersfield",
  512: "Baltimore",
  537: "Bangor",
  716: "Baton Rouge",
  692: "Beaumont - Port Arthur",
  821: "Bend, OR",
  756: "Billings",
  746: "Biloxi - Gulfport",
  502: "Binghamton",
  630: "Birmingham (Anniston and Tuscaloosa)",
  559: "Bluefield - Beckley - Oak Hill",
  757: "Boise",
  506: "Boston (Manchester)",
  736: "Bowling Green",
  514: "Buffalo",
  523: "Burlington - Plattsburgh",
  754: "Butte - Bozeman",
  767: "Casper - Riverton",
  637: "Cedar Rapids - Waterloo & Dubuque",
  648: "Champaign & Springfield - Decatur",
  564: "Charleston-Huntington",
  519: "Charleston, SC",
  517: "Charlotte",
  584: "Charlottesville",
  575: "Chattanooga",
  759: "Cheyenne - Scottsbluff",
  602: "Chicago",
  868: "Chico - Redding",
  515: "Cincinnati",
  598: "Clarksburg - Weston",
  510: "Cleveland",
  604: "Columbia - Jefferson City",
  546: "Columbia, SC",
  673: "Columbus - Tupelo - West Point",
  522: "Columbus, GA",
  535: "Columbus, OH",
  600: "Corpus Christi",
  623: "Dallas - Fort Worth",
  682: "Davenport - Rock Island - Moline",
  542: "Dayton",
  751: "Denver",
  679: "Des Moines - Ames",
  505: "Detroit",
  606: "Dothan",
  676: "Duluth - Superior",
  765: "El Paso",
  565: "Elmira",
  516: "Erie",
  801: "Eugene",
  802: "Eureka",
  649: "Evansville",
  745: "Fairbanks",
  724: "Fargo - Valley City",
  513: "Flint - Saginaw - Bay City",
  570: "Florence - Myrtle Beach",
  571: "Fort Myers - Naples",
  670: "Fort Smith - Fayetteville - Springdale - Rogers",
  509: "Fort Wayne",
  866: "Fresno - Visalia",
  592: "Gainesville",
  798: "Glendive",
  773: "Grand Junction - Montrose",
  563: "Grand Rapids - Kalamazoo - Battle Creek",
  755: "Great Falls",
  658: "Green Bay - Appleton",
  518: "Greensboro - High Point - Winston-Salem",
  545: "Greenville - New Bern - Washington",
  567: "Greenville - Spartansburg - Asheville - Anderson",
  647: "Greenwood - Greenville",
  636: "Harlingen - Weslaco - Brownsville - McAllen",
  566: "Harrisburg - Lancaster - Lebanon - York",
  569: "Harrisonburg",
  533: "Hartford & New Haven",
  710: "Hattiesburg - Laurel",
  766: "Helena",
  744: "Honolulu",
  618: "Houston",
  691: "Huntsville - Decatur (Florence)",
  758: "Idaho Falls - Pocatello",
  527: "Indianapolis",
  718: "Jackson, MS",
  639: "Jackson, TN",
  561: "Jacksonville, Brunswick",
  574: "Johnstown - Altoona",
  734: "Jonesboro",
  603: "Joplin - Pittsburg",
  747: "Juneau",
  616: "Kansas City",
  557: "Knoxville",
  702: "La Crosse - Eau Claire",
  582: "Lafayette, IN",
  642: "Lafayette, LA",
  643: "Lake Charles",
  551: "Lansing",
  749: "Laredo",
  839: "Las Vegas",
  541: "Lexington",
  558: "Lima",
  722: "Lincoln & Hastings - Kearney",
  693: "Little Rock - Pine Bluff",
  803: "Los Angeles",
  529: "Louisville",
  651: "Lubbock",
  503: "Macon",
  669: "Madison",
  737: "Mankato",
  553: "Marquette",
  813: "Medford - Klamath Falls",
  640: "Memphis",
  711: "Meridian",
  528: "Miami - Fort Lauderdale",
  617: "Milwaukee",
  613: "Minneapolis - Saint Paul",
  687: "Minot - Bismarck - Dickinson",
  762: "Missoula",
  686: "Mobile - Pensacola (Fort Walton Beach)",
  628: "Monroe - El Dorado",
  828: "Monterey - Salinas",
  698: "Montgomery (Selma)",
  659: "Nashville",
  622: "New Orleans",
  501: "New York",
  544: "Norfolk - Portsmouth - Newport News",
  740: "North Platte",
  633: "Odessa - Midland",
  650: "Oklahoma City",
  652: "Omaha",
  534: "Orlando - Daytona Beach - Melbourne",
  631: "Ottumwa - Kirksville",
  632: "Paducah - Cape Girardeau - Harrisburg - Mt Vernon",
  804: "Palm Springs",
  656: "Panama City",
  597: "Parkersburg",
  675: "Peoria - Bloomington",
  504: "Philadelphia",
  753: "Phoenix",
  508: "Pittsburgh",
  500: "Portland - Auburn",
  820: "Portland, OR",
  552: "Presque Isle",
  521: "Providence - New Bedford",
  717: "Quincy - Hannibal - Keokuk",
  560: "Raleigh - Durham (Fayetteville)",
  764: "Rapid City",
  811: "Reno",
  556: "Richmond - Petersburg",
  573: "Roanoke - Lynchburg",
  611: "Rochester - Mason City - Austin",
  538: "Rochester, NY",
  610: "Rockford",
  862: "Sacramento - Stockton - Modesto",
  638: "Saint Joseph",
  609: "Saint Louis",
  576: "Salisbury",
  770: "Salt Lake City",
  661: "San Angelo",
  641: "San Antonio",
  825: "San Diego",
  807: "San Francisco - Oakland - San Jose",
  855: "Santa Barbara - Santa Maria - San Luis Obispo",
  507: "Savannah",
  819: "Seattle - Tacoma",
  657: "Sherman, TX - Ada, OK",
  612: "Shreveport",
  624: "Sioux City",
  725: "Sioux Falls (Mitchell)",
  588: "South Bend - Elkhart",
  881: "Spokane",
  543: "Springfield - Holyoke",
  619: "Springfield, MO",
  555: "Syracuse",
  530: "Tallahassee - Thomasville",
  539: "Tampa - Saint Petersburg (Sarasota)",
  581: "Terre Haute",
  547: "Toledo",
  605: "Topeka",
  540: "Traverse City - Cadillac",
  531: "Tri-Cities, TN-VA",
  789: "Tucson (Sierra Vista)",
  671: "Tulsa",
  760: "Twin Falls",
  709: "Tyler - Longview (Lufkin & Nacogdoches)",
  526: "Utica",
  626: "Victoria",
  625: "Waco - Temple - Bryan",
  511: "Washington DC (Hagerstown)",
  549: "Watertown",
  705: "Wausau - Rhinelander",
  548: "West Palm Beach - Fort Pierce",
  554: "Wheeling - Steubenville",
  678: "Wichita - Hutchinson",
  627: "Wichita Falls & Lawton",
  577: "Wilkes Barre - Scranton",
  550: "Wilmington",
  810: "Yakima - Pasco - Richland - Kennewick",
  536: "Youngstown",
  771: "Yuma - El Centro",
  596: "Zanesville",
};

export const LocationTypes = {
  cities: { name: "Cities", value: "cities", prop: "geoType" },
  states: { name: "States", value: "states", prop: "options" },
  countries: { name: "Countries", value: "countries", prop: "options" },
  metro_codes: { name: "Designated Market Areas (US Only)", value: "metro_codes", prop: "options" },
  postal_codes: { name: "Zip Codes", value: "postal_codes", prop: "geoType" },
};
