<template>
  <span class="contents">
    <tr class="text-body h-0 border-t border-solid border-strokes">
      <td class="text-heading-2 !p-2 text-left !text-blue-1">
        <span ref="tooltipAnchorEl" class="relative line-clamp-1 text-ellipsis">
          {{ name }}
          <RouterLink class="absolute inset-0" :title="name" :to="link">
            <button
              class="block h-full w-full"
              @focus="() => tooltipEl.show()"
              @mouseenter="() => tooltipEl.show()"
              @blur="() => tooltipEl.hide()"
              @mouseleave="() => tooltipEl.hide()"
            ></button>
          </RouterLink>
        </span>
      </td>
      <td class="!p-2 text-right text-black-2">{{ toLocaleNumberString(users) }}</td>
      <td v-if="!hideImpressions" class="!p-2 text-right text-black-2">
        {{ toLocaleNumberString(impressions) }}
      </td>
      <td class="!p-2 text-right text-black-2">{{ toLocaleNumberString(goals) }}</td>
      <td class="!p-2 text-right text-black-2">{{ ctr }}</td>
    </tr>
    <RfTooltip ref="tooltipEl" :anchor="tooltipAnchorEl">
      <template #tooltip>
        <div class="flex w-auto items-center gap-5">
          <span class="relative block h-24 w-32 flex-shrink-0 bg-grey-1">
            <div
              class="promo-preview-image !min-w-0"
              :class="(thumbnail && previewClass[path_type]) || previewClass.invisible"
            >
              <div v-if="thumbnail"><img :src="thumbnail" loading="lazy" /></div>
            </div>
            <span
              v-if="type === types.guide"
              aria-hidden="true"
              class="text-body !text-white-1 absolute -bottom-1 -right-1 z-10 grid h-6 w-6 place-content-center rounded-full bg-grey-1 p-1"
            >
              {{ paths_count }}
            </span>
          </span>
          <div class="flex flex-col gap-1">
            <span
              class="text-action-buttons line-clamp-1 text-ellipsis text-left !font-bold !text-blue-1"
              :title="name"
            >
              {{ name }}
            </span>
            <span v-if="type === types.prompt" class="text-body">{{
              getPromptDeviceType({ path_type, device_type, custom_devices }, customDevicesEnabled)
            }}</span>
            <span v-if="type === types.guide" class="text-body">
              <span class="text-body">
                Show prompts: {{ GUIDE_TYPES[sequence_type].label || GUIDE_TYPES.survey.label }}
              </span></span
            >
            <span v-if="type === types.prompt" class="text-body">
              Creator:
              {{ creator || "N/A" }}
            </span>
          </div>
        </div>
      </template>
    </RfTooltip>
  </span>
</template>

<script setup>
import RfTooltip from "@/components/tooltip/RfTooltip.vue";
import { GUIDE_TYPES } from "@/utils/constants/GuidesConstants";
import { previewClass } from "@/utils/constants/PreviewClassConstants";
import { getPromptDeviceType } from "@/utils/getDeviceName";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import { ref } from "vue";

const types = { prompt: "prompt", guide: "guide", experiment: "experiment" };

const tooltipEl = ref();
const tooltipAnchorEl = ref();

defineProps({
  thumbnail: { type: String, default: null },
  id: { type: String, required: true },
  name: { type: String, required: true },
  link: { type: String, required: true },
  users: { type: [Number, String], required: true },
  impressions: { type: [Number, String], required: false },
  goals: { type: [Number, String], required: true },
  ctr: { type: [Number, String], required: true },
  path_type: { type: String, default: null },
  device_type: { type: String, default: null },
  sequence_type: { type: String, default: null },
  paths_count: { type: Number, default: null },
  creator: { type: String, default: null },
  custom_devices: { type: Array, default: () => [] },
  type: { type: String, default: null },
  customDevicesEnabled: { type: Boolean, default: false },
  hideImpressions: { type: Boolean, default: false },
});
</script>
