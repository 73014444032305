<template>
  <div>
    <div class="experiment-pg-module experiment-details-mod">
      <h4 style="padding-top: 10px">Details</h4>
      <div class="experiment-details-wrapper-col">
        <div class="promo-hero-col-wrapper" style="padding: 10px 0 10px 0">
          <div class="promo-hero-col left-col">
            <RfPromoDetailsInfoRow title="Status">
              <template v-slot:promodetail>
                <div
                  class="experiment-detail-value"
                  v-if="selectedExperiment.is_active && !selectedExperiment.is_completed"
                >
                  <v-icon color="success" left size="18">fiber_manual_record</v-icon>Active
                </div>
                <div
                  class="experiment-detail-value"
                  v-if="!selectedExperiment.is_active && !selectedExperiment.is_completed"
                >
                  not started
                </div>
                <div
                  class="experiment-detail-value"
                  v-if="!selectedExperiment.is_active && selectedExperiment.is_completed"
                >
                  <v-icon color="#666666" left size="18">fiber_manual_record</v-icon>Completed
                </div>
              </template>
            </RfPromoDetailsInfoRow>
            <RfPromoDetailsInfoRow title="Traffic Distribution">
              <template v-slot:promodetail>
                <div
                  class="experiment-detail-value traffic-distr-val"
                  :style="{ cursor: isReadOnlyRoleMixin ? 'not-allowed' : 'pointer' }"
                  @click="isReadOnlyRoleMixin ? null : $emit('showTrafficDetail')"
                >
                  {{ trafficDistribution }}%
                  <span class="error-exp-traffic" v-if="trafficError">
                    <v-icon size="16" color="error" left>error</v-icon>
                    <span> Must add up to 100% </span>
                  </span>
                  <v-icon size="16" color="success" right v-else>fas fa-check</v-icon>
                </div>
              </template>
            </RfPromoDetailsInfoRow>
            <RfPromoDetailsInfoRow title="Control">
              <template v-slot:promodetail>
                <div
                  class="experiment-detail-value"
                  :style="{ cursor: isReadOnlyRoleMixin ? 'not-allowed' : 'pointer' }"
                  @click="isReadOnlyRoleMixin ? null : $emit('showTrafficDetail')"
                >
                  {{ holdoutPercentage }}%
                </div>
              </template>
            </RfPromoDetailsInfoRow>
          </div>
          <div class="promo-hero-col right-col">
            <RfPromoDetailsInfoRow title="Dates">
              <template v-slot:promodetail>
                <div class="experiment-detail-value">
                  Created: {{ renderDate(selectedExperiment.created_at) }}
                  <span class="exp-last-updated-date">Last updated:</span>
                  {{ renderDate(selectedExperiment.updated_at) }}
                </div>
              </template>
            </RfPromoDetailsInfoRow>
            <RfPromoDetailsInfoRow title="Start date">
              <template v-slot:promodetail>
                <div class="experiment-detail-value" v-if="selectedExperiment.start_date === null">
                  N/A
                </div>
                <div class="experiment-detail-value" v-else>
                  {{ renderDate(selectedExperiment.start_date) }}
                </div>
              </template>
            </RfPromoDetailsInfoRow>
            <RfPromoDetailsInfoRow title="End date">
              <template v-slot:promodetail>
                <div class="experiment-detail-value" v-if="selectedExperiment.end_date === null">
                  N/A
                </div>
                <div class="experiment-detail-value" v-else>
                  {{ renderDate(selectedExperiment.end_date) }}
                </div>
              </template>
            </RfPromoDetailsInfoRow>
          </div>
        </div>
      </div>
      <v-dialog v-model="experimentStartDialog" width="700">
        <v-card flat>
          <v-card-title class="headline">
            <span v-if="isCurrExperimentRunning">End experiment confirmation</span>
            <span v-else>Start experiment confirmation</span>
          </v-card-title>
          <v-card-text>
            <div v-if="isCurrExperimentRunning">Are you sure you want to stop this experiment?</div>
            <div v-else-if="!path.is_enabled">
              Your prompt will be published. Are you sure you want to start this experiment?
            </div>
            <div v-else>Are you sure you want to start this experiment?</div>
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn
              large
              depressed
              class="cancel-btn"
              outlined
              width="100px"
              @click.stop="experimentStartDialog = false"
              >Close</v-btn
            >
            <div class="exp-running-buttons" v-if="isCurrExperimentRunning">
              <v-btn
                large
                depressed
                color="success"
                class="expriment-winner-button"
                @click="submitExperimentBtn(false)"
                >End experiment</v-btn
              >
              <v-btn
                large
                depressed
                color="accent"
                class="expriment-winner-button"
                @click="submitExperimentBtn(true)"
                >End experiment & pause prompt</v-btn
              >
            </div>
            <v-btn
              v-else
              large
              depressed
              color="success"
              width="150px"
              @click="submitExperimentBtn(false)"
              >Start</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="experiment-action-btns" v-if="!isExperimentCompleted">
      <RfButton
        :icon="!isCurrExperimentRunning ? 'fas fa-play-circle' : 'fas fa-stop-circle'"
        :button-text="!isCurrExperimentRunning ? 'Start experiment' : 'End experiment'"
        large
        color="success"
        :disabled="isDisabledRoleMixin"
        @click="callExperimentDialog(isCurrExperimentRunning)"
      />
      <span style="padding-left: 1rem" v-if="!isCurrExperimentRunning"
        >Once the experiment is started, add variation is no longer available.</span
      >
    </div>
  </div>
</template>

<script>
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import { mapGetters } from "vuex";
import RfButton from "@/components/buttons/RfButton.vue";
import moment from "moment-timezone";
import RoleMixin from "@/utils/RoleMixin";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfRetentionExperimentDetail",
  props: ["selectedExperiment", "path"],
  components: { RfPromoDetailsInfoRow, RfButton },
  mixins: [RoleMixin],
  setup: () => ({ toastsStore: useToastsStore() }),
  data: () => ({ experimentStartDialog: false }),
  computed: {
    ...mapGetters(["isCurrExperimentRunning"]),
    isExperimentCompleted() {
      return this.selectedExperiment.is_completed;
    },
    trafficDistribution() {
      return this.selectedExperiment.variations.reduce(function (total, item) {
        return total + item.traffic_percent;
      }, 0);
    },
    holdoutPercentage() {
      const holdout = this.selectedExperiment.variations.find(item => item.is_control);
      if (holdout) return holdout.traffic_percent;
      return 0;
    },
    trafficError() {
      return this.trafficDistribution !== 100;
    },
  },
  methods: {
    callExperimentDialog(isExperimentRunning) {
      if (isExperimentRunning) {
        this.experimentStartDialog = true;
      } else if (
        this.selectedExperiment.variations.reduce(
          (total, item) => total + item.traffic_percent,
          0,
        ) !== 100
      ) {
        this.toastsStore.create({
          type: "error",
          body: "Your traffic percentages must add up to 100%",
        });
      } else {
        this.experimentStartDialog = true;
      }
    },
    submitExperimentBtn(pausePromo) {
      this.$emit("onStart", pausePromo);
      this.experimentStartDialog = false;
    },
    renderDate(date) {
      return moment(date).format("MMM Do YYYY");
    },
  },
};
</script>
<style>
.exp-running-buttons .v-btn {
  margin-left: 8px;
}
.error-exp-traffic {
  padding-left: 10px;
  color: #c00;
  display: flex;
  align-items: center;
  line-height: normal;
}
.traffic-distr-val {
  display: flex;
  align-items: center;
}
.exp-last-updated-date {
  padding-left: 15px;
}
</style>
