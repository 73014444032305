<template>
  <RfSimpleCard class="mb-7 py-7 px-7">
    <div class="d-flex flex-column">
      <div class="settings-usage-tracking-desc mt-0">
        <div>
          <h4>Data Sources</h4>
          <p class="title-blurb"></p>
        </div>
        <RfButton
          icon="add"
          button-text="New Data Source"
          color="success"
          :disabled="isDisabledRoleMixin"
          @click="$refs.newModal.show()"
        />
      </div>

      <v-expansion-panels v-model="panel" multiple accordion>
        <RfDataSourceEntry
          v-for="dataSource in dataSources"
          :key="dataSource.id"
          :dataSource="dataSource"
          @remove="removeDataSource"
          @uploadCSV="uploadCSV"
          @edit="model => editDataSource({ appId, model })"
        />
      </v-expansion-panels>

      <v-dialog v-model="deleteModal.value" width="500">
        <v-card>
          <v-card-title class="headline">
            Are you sure you want to delete data source '{{ deleteModal.name }}'?
          </v-card-title>
          <v-card-text>
            Deleting this data source removes all information about this data source and all of its
            properties. This cannot be undone.
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn depressed class="cancel-btn px-7" outlined @click="closeDeleteDialog">
              Cancel
            </v-btn>
            <v-btn depressed color="error" @click="submitDelete" class="px-10">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <RfRenameModal ref="newModal" title="Create new data source" :onSubmit="submitCreate" />
    </div>
  </RfSimpleCard>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import RfRenameModal from "@/components/modals/RfRenameModal.vue";
import RfDataSourceEntry from "./RfDataSourceEntry.vue";

export default {
  name: "RfDataSourceSettings",
  components: { RfSimpleCard, RfButton, RfRenameModal, RfDataSourceEntry },
  mixins: [RoleMixin],
  data: () => ({
    panel: [0],
    deleteModal: { value: false, name: "", id: null },
  }),
  computed: {
    ...mapState({ dataSources: state => state.apps.dataSources }),
    appId() {
      return this.$route.params.aid;
    },
  },
  methods: {
    ...mapActions([
      "createDataSource",
      "getDataSources",
      "upsertDataSourceCSV",
      "deleteDataSource",
      "editDataSource",
    ]),
    uploadCSV({ target: { files } }, dataSourceId) {
      const file = files && files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.addEventListener(
        "load",
        ({ target: { result: csv } }) =>
          this.upsertDataSourceCSV({ dataSourceId, csv, appId: this.appId }),
        false,
      );
      reader.readAsDataURL(file);
    },
    submitCreate({ name, description }) {
      const model = { name, description };
      this.createDataSource({ appId: this.appId, model });
      this.$refs.newModal.close();
    },
    removeDataSource(dataSource) {
      this.deleteModal.value = true;
      this.deleteModal.name = dataSource.name;
      this.deleteModal.id = dataSource.id;
    },
    closeDeleteDialog() {
      this.deleteModal.value = false;
      this.deleteModal.name = "";
      this.deleteModal.id = null;
    },
    submitDelete() {
      this.deleteDataSource({ appId: this.appId, dataSourceId: this.deleteModal.id });
      this.closeDeleteDialog();
    },
  },
  mounted() {
    if (!this.dataSources || !this.dataSources.length) this.getDataSources({ appId: this.appId });
  },
};
</script>
