<template>
  <div class="impress impress--container">
    <v-checkbox
      v-model="checkBox"
      label="Global Impressions Limit"
      :disabled="isReadOnlyRoleMixin"
      hide-details
      class="impress--checkbox"
      @change="v => (this.value.is_limited = v)"
    />
    <div v-if="checkBox" class="impress--input-wrapper">
      <RfTextInput
        v-model.trim="value.hard_limit"
        outlined
        title="Limit to"
        dense
        hide-details
        :readonly="isReadOnlyRoleMixin"
      />
      <RfTextInput
        v-model.trim="value.soft_limit"
        outlined
        title="Show Warning at"
        dense
        hide-details
        :readonly="isReadOnlyRoleMixin"
      />
    </div>
  </div>
</template>

<script>
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RoleMixin from "@/utils/RoleMixin";

export default {
  name: "ImpressionLimits",
  props: ["value"],
  mixins: [RoleMixin],
  data() {
    return { checkBox: this.value.is_limited };
  },
  components: { RfTextInput },
};
</script>

<style lang="scss" scoped>
.impress--container {
  display: inline-flex;
  flex-direction: column;
  gap: 16px;

  .impress--input-wrapper {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 500;
    font-size: 14px;
  }
}
</style>
