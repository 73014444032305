var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rf-rc-settingss-left rf-rc-col1"},[_c('div',{staticClass:"promo-design-group flex flex-col gap-4"},[_c('div',{staticClass:"flex flex-col"},[_c('v-textarea',{attrs:{"outlined":"","rows":2,"label":"Title","name":"title","hide-details":"","disabled":_vm.currentTranslations.hasOwnProperty('rf_retention_title')},model:{value:(
          _vm.currentTranslations.hasOwnProperty('rf_retention_title')
            ? _vm.previewActions.rf_retention_title
            : _vm.actions.rf_retention_title
        ),callback:function ($$v) {_vm.$set(_vm.currentTranslations.hasOwnProperty('rf_retention_title')
            ? _vm.previewActions.rf_retention_title
            : _vm.actions, "rf_retention_title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n          currentTranslations.hasOwnProperty('rf_retention_title')\n            ? previewActions.rf_retention_title\n            : actions.rf_retention_title\n        "}}),(!_vm.currentTranslations.hasOwnProperty('rf_retention_title'))?_c('RfInsertUserTrait',{attrs:{"app":_vm.app,"actionName":"rf_retention_title","title":"title","actions":_vm.actions}}):_vm._e()],1),(!_vm.noStyle)?_c('div',{staticClass:"promo-fonts-size-input"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","dense":"","label":"Title Font Size","name":"title-font-size","required":"","hide-details":""},model:{value:(_vm.actions.rf_settings_title_font_size),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_title_font_size", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_title_font_size"}}),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","dense":"","label":"Font Color","name":"fill_color","required":"","hide-details":""},model:{value:(_vm.actions.rf_settings_fill_color),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_fill_color", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_fill_color"}})],1):_vm._e(),_c('div',{staticClass:"flex flex-col"},[_c('v-textarea',{attrs:{"outlined":"","rows":_vm.rowsNumberMsg,"label":"Message","disabled":_vm.currentTranslations.hasOwnProperty('rf_retention_message'),"name":"message","hide-details":""},model:{value:(
          _vm.currentTranslations.hasOwnProperty('rf_retention_message')
            ? _vm.previewActions.rf_retention_message
            : _vm.actions.rf_retention_message
        ),callback:function ($$v) {_vm.$set(_vm.currentTranslations.hasOwnProperty('rf_retention_message')
            ? _vm.previewActions.rf_retention_message
            : _vm.actions, "rf_retention_message", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n          currentTranslations.hasOwnProperty('rf_retention_message')\n            ? previewActions.rf_retention_message\n            : actions.rf_retention_message\n        "}}),(!_vm.currentTranslations.hasOwnProperty('rf_retention_message'))?_c('RfInsertUserTrait',{attrs:{"app":_vm.app,"actionName":"rf_retention_message","title":"message","actions":_vm.actions}}):_vm._e()],1),(!_vm.noStyle)?_c('div',{staticClass:"promo-fonts-size-input"},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","dense":"","label":"Font Size","name":"message-font-size","required":"","hide-details":""},model:{value:(_vm.actions.rf_settings_message_font_size),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_message_font_size", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_message_font_size"}}),_c('v-select',{attrs:{"outlined":"","hide-details":"","dense":"","label":"Align","items":_vm.messageAlignOptions},model:{value:(_vm.actions.rf_retention_align),callback:function ($$v) {_vm.$set(_vm.actions, "rf_retention_align", $$v)},expression:"actions.rf_retention_align"}}),(_vm.model.path_type !== 'email')?_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","label":"Top Padding","name":"top_padding"},model:{value:(_vm.actions.rf_settings_title_padding_top),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_title_padding_top", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_title_padding_top"}}):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }