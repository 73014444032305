<template>
  <div class="promo-interaction-opt-wrap">
    <div class="interaction-label">User</div>
    <v-select
      outlined
      dense
      hide-details
      style="width: 100px"
      placeholder="Condition"
      :disabled="disabled"
      v-model="matchTypeModel"
      :items="matchTypeItems"
      @change="updateFilter"
      :menu-props="{ maxHeight: 310, offsetY: true }"
      height="32px"
      background-color="#ffffff"
    />
    <v-select
      v-if="hasMatchType"
      chips
      multiple
      outlined
      dense
      hide-details
      :disabled="disabled"
      v-model="interactionTypeModel"
      :items="interactionTypeItems"
      @change="updateFilter"
      placeholder="Event"
      :menu-props="{ maxHeight: 310, offsetY: true, minWidth: 240 }"
      class="pr-interaction-checklist"
      background-color="#ffffff"
    />
    <v-select
      v-if="interactionTypeModel"
      v-model="promoModel"
      :items="promoItems"
      @change="updateFilter"
      placeholder="Prompt"
      outlined
      dense
      hide-details
      :disabled="disabled"
      style="max-width: 380px"
      background-color="#ffffff"
      :menu-props="{ maxHeight: 310, offsetY: true, minWidth: 240, maxWidth: 520 }"
    />
  </div>
</template>

<script>
import { InteractionTypeItems, MatchTypeItemsAlter } from "@/utils/constants/SegmentsConstants";
import { mapState, mapActions } from "vuex";

export default {
  name: "RfPromoInteractions",
  props: ["filter", "disabled"],
  setup: () => ({
    matchTypeItems: Object.values(MatchTypeItemsAlter),
    interactionTypeItems: Object.values(InteractionTypeItems),
  }),
  data() {
    return {
      matchTypeModel: null,
      interactionTypeModel: null,
      promoModel: null,
    };
  },
  computed: {
    ...mapState({ retentions: state => state.apps.retentions }),
    promoInteraction() {
      return this.filter.promotion_interactions;
    },
    promoInteractionKey() {
      return Object.keys(this.promoInteraction)[0];
    },
    promoItems() {
      return [...this.retentions]
        .sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : 0,
        )
        .map(promo => ({ text: promo.name, value: promo.id }));
    },
    promosKey() {
      return Object.keys(this.filter.promos)[0];
    },
    promo() {
      return this.filter.promos[this.promosKey];
    },
    hasMatchType() {
      return this.matchTypeModel !== null;
    },
  },
  methods: {
    ...mapActions(["getRetentions"]),
    updateFilter() {
      const values = {
        promo_slug: this.promoModel,
        positive_match: this.matchTypeModel,
        interaction_types: this.interactionTypeModel,
      };

      const filter = { promos: { promotion_interaction: values } };

      this.$emit("update:model", filter);
    },
    prepareFilter() {
      if (!this.promo) return;

      this.matchTypeModel = this.promo.positive_match;
      this.interactionTypeModel = this.promo.interaction_types;
      this.promoModel = this.promo.promo_slug;
    },
    loadPromos() {
      if (this.retentions.length) return;

      this.getRetentions({ appId: this.$route.params.aid });
    },
  },
  mounted() {
    this.loadPromos();
    this.prepareFilter();
  },
};
</script>
