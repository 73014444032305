export default {
  "Pacific Time (US & Canada)": "America/Los_Angeles",
  "Mountain Time (US & Canada)": "America/Denver",
  "Central Time (US & Canada)": "America/Chicago",
  "Eastern Time (US & Canada)": "America/New_York",
  "Alaska": "America/Anchorage",
  "Hawaii": "Pacific/Honolulu",
  "Europe/Athens": "Europe/Athens",
  "Europe/Berlin": "Europe/Berlin",
  "Europe/Dublin": "Europe/Dublin",
  "Europe/London": "Europe/London",
  "Europe/Madrid": "Europe/Madrid",
  "Europe/Paris": "Europe/Paris",
  "Europe/Zurich": "Europe/Zurich",
  "Asia/Kolkata": "Asia/Kolkata",
  "Asia/Shanghai": "Asia/Shanghai",
  "Australia/Sydney": "Australia/Sydney",
  "Pacific/Auckland": "Pacific/Auckland",
  "America/Buenos Aires": "America/Buenos_Aires",
  "America/Mexico City": "America/Mexico_City",
  "America/Sao Paulo": "America/Sao_Paulo",
};
