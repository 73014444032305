import { mapState, mapActions } from "vuex";
import { cloneDeep } from "lodash-es";
import { PromptTypes } from "./constants/PromoTypesConstants";

export default {
  computed: {
    ...mapState({
      segments: state => state.apps.segments,
    }),
    testUserSegment() {
      return this.segments.find(seg => seg.segment_type === "system_test_users") || {};
    },
    segmentListWithHeaders() {
      if (this.pipelineStageId) {
        const items = (this.segments || [])
          .filter(segment => segment.pipeline_stage_id === this.pipelineStageId)
          .map(segment => {
            return { name: segment.name, id: segment.id };
          });

        if (this.testUserSegment)
          items.push({ name: this.testUserSegment.name, id: this.testUserSegment.id });
        return items;
      }

      const items = this.segments
        ? this.segments
            .filter(segment => !segment.pipeline_stage_id)
            .filter(
              segment =>
                this.model?.path_type !== PromptTypes.scheduled_push.value ||
                segment.segment_type === "pinpoint",
            )
            .map(segment => {
              return { name: segment.name, id: segment.id };
            })
        : [];

      if (this.showAllSegments) {
        const segmentsByPipeline = {};
        const pipelineNames = {};
        if (this.segments) {
          this.segments
            .filter(segment => !segment.pipeline_hidden)
            .forEach(segment => {
              if (segment.pipeline_id) {
                const id = segment.pipeline_id;
                const existingSegments = cloneDeep(segmentsByPipeline[id]) || [];
                existingSegments.push(segment);
                segmentsByPipeline[id] = existingSegments;
                pipelineNames[id] = segment.pipeline_name;
              }
            });
        }
        Object.keys(pipelineNames).forEach(id => {
          items.push({ header: pipelineNames[id] });
          segmentsByPipeline[id].forEach(segment => {
            items.push({
              name: `${segment.pipeline_stage_name} - ${segment.name}`,
              id: segment.id,
            });
          });
        });
      }
      return items;
    },
  },
  methods: {
    ...mapActions(["getSegments"]),
  },
  mounted() {
    if (!this.segments.length) {
      this.getSegments({ appId: this.$route.params.aid });
    }
  },
};
