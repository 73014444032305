<template>
  <div class="regform-ctr">
    <rf-modal-loading :showDialog="showLoading > 0" />
    <RfRegister v-on:submit="onRegister" />
    <v-dialog persistent v-model="accountSuccessDialog" width="600" overlay-opacity="0.85">
      <v-card flat>
        <v-card-title class="headline">Success!</v-card-title>
        <v-card-text class="create-new-promo-dialog">
          <span class="success-cre-msg">
            Your account has been successfully created. Please click on the verification link in
            your email to finish signing up.
          </span>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn x-large depressed color="success" width="170px" @click="continueToLogin"
            >Continue</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import RfRegister from "@/components/RfRegister.vue";
import RfModalLoading from "./RfModalLoading.vue";

export default {
  components: {
    RfRegister,
    RfModalLoading,
  },
  data() {
    return {
      accountSuccessDialog: false,
      showLoading: false,
    };
  },
  methods: {
    continueToLogin() {
      this.$router.push({ path: "/login" });
    },
    async onRegister(values) {
      this.showLoading = true;
      const response = await this.register({
        ...values,
      });

      this.showLoading = false;
      if (response) {
        this.accountSuccessDialog = true;
      }
    },
    ...mapActions(["register"]),
  },
  created() {
    this.invitation_token = this.$route.query.invitation_token;
  },
};
</script>
<style scoped>
.success-cre-msg {
  font-size: 18px;
  padding: 10px 0 20px;
  display: block;
}
</style>
