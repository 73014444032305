export default {
  computed: {
    message() {
      return this.actions.rf_retention_message;
    },
    mobileMessage() {
      return this.actions.rf_mobile_message;
    },
    fillColor() {
      return this.actions.rf_settings_fill_color;
    },
    acceptButtonStyle() {
      return {
        "color": this.actions.rf_retention_button1_color,
        "background-color": this.actions.rf_settings_background_color,
      };
    },
    secondaryButtonStyle() {
      return {
        "color": this.actions.rf_retention_button2_text_color,
        "background-color": this.actions.rf_settings_accept2_button_background_color,
      };
    },
    cancelButtonStyle() {
      return {
        color: this.actions.rf_retention_button3_color,
      };
    },
    closeButtonEnabled() {
      return this.actions.rf_settings_close_button_enabled === "true";
    },
  },
};
