<template>
  <v-dialog
    persistent
    v-model="showCreateModal"
    width="640"
    content-class="create-placement-dialog"
    overlay-opacity="0.85"
  >
    <v-card flat>
      <v-card-title class="headline">Create new placement zone</v-card-title>
      <v-card-text>
        <RfZoneId :model="createModel" ref="zoneDetails" :key="resetKey" :showNameDetails="true" />
      </v-card-text>
      <v-card-actions class="modal-card-actions-div">
        <v-btn large depressed class="cancel-btn" outlined width="100px" @click="closeDialog"
          >Close</v-btn
        >
        <v-btn
          large
          width="260px"
          color="success"
          :disabled="!isZoneIsValid"
          depressed
          @click="createNewPathGroup"
        >
          <v-icon small left>save</v-icon>Create new placement
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfZoneId from "@/components/RfCommonCards/RfZoneId.vue";
import {
  DEFAULT_PROMO_DIMENSIONS,
  getDefaultPromoRatio,
  defaultPromoType,
} from "@/utils/PromoDimUtils";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { ref } from "vue";

export default {
  name: "RfCreateNewPlacement",
  props: ["showCreateModal"],
  setup: () => ({ toastsStore: useToastsStore(), zoneDetails: ref() }),
  components: { RfZoneId },
  data() {
    return {
      createModel: this.getDefaultModel(),
      updating: false,
      resetKey: 0,
    };
  },
  computed: {
    ...mapState({
      newPathGroup: state => cloneDeep(state.tmp.pathGroup),
      currPathGroup: state => cloneDeep(state.apps.currPathGroup),
    }),
    isZoneIsValid() {
      return this.zoneDetails?.isValid;
    },
  },

  methods: {
    ...mapActions(["getPathGroupModel", "createPathGroup"]),
    closeDialog() {
      this.resetKey++;
      this.$emit("closeDialog");
    },
    async createNewPathGroup() {
      try {
        this.$emit("closeDialog");

        const pathType = this.$refs.zoneDetails.placementType;
        const { deviceType } = this.$refs.zoneDetails;
        await this.getPathGroupModel({
          appId: this.$route.params.aid,
          pathType,
          deviceType,
        });
        const model = cloneDeep(this.newPathGroup);
        this.$refs.zoneDetails.fillModel(model);
        this.updating = true;
        await this.createPathGroup({ appId: this.$route.params.aid, model });
        this.createModel = this.getDefaultModel();
        this.resetKey++;
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
        this.updating = false;
      }
    },
    getDefaultModel() {
      return {
        name: null,
        description: null,
        zone: null,
        path_type: "horizontal",
        device_type: "web",
        displayType: "stack",
        settings: {
          ...DEFAULT_PROMO_DIMENSIONS[defaultPromoType][getDefaultPromoRatio(defaultPromoType)],
        },
        zone_type: "live",
        paths: [],
      };
    },
  },
  watch: {
    currPathGroup(to) {
      if (to && this.updating) {
        this.toastsStore.create({ type: "success", body: "Zone created successfully" });
        this.updating = false;
      }
    },
  },
};
</script>
