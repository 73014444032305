<template>
  <div class="rf-category-pg-wrapper prompt-flag-two-enabled">
    <RfModalLoading :showDialog="loading" />
    <div>
      <div class="all-segments-list-rightcol">
        <div class="rf-detail-content-wrapper segment-detail-pg" v-if="segment">
          <div class="promo-detail-pg-title-btns">
            <div class="promo-dtlpg-title-left">
              <RfSegmentName :item="segment" :locked="segment.is_locked" />
            </div>
            <div class="promo-dtlpg-title-right">
              <RfDetailHeader
                v-on:submitDelete="submitDelete"
                v-on:clone="clone"
                :locked="segment.is_locked"
                :showClone="true"
                objectType="Segment"
                :showPriority="false"
                :deleteOff="!!segment.pipeline_stage_id"
                :showEnableDisable="shouldShowEnableDisable"
                :item="segment"
                v-on:enableItem="enableSegment"
              />
            </div>
          </div>
          <RfLiveStatistics
            :usage_type="usage_type"
            :metrics="metrics"
            :model="segment"
            :curr-chart-src.sync="currChartSrc"
            :date-range.sync="dateRange"
          />
          <div class="rf-segment-detail-sub-content">
            <v-row>
              <v-col class="rf-sub-col left-col">
                <RfUserHistory :activities="activities" />
              </v-col>
              <v-col class="rf-sub-col right-col">
                <RfSegmentStatisticsTable :metrics="metrics && metrics[usage_type]" />
                <div class="segment-updated-time">
                  Last updated at
                  {{ dayjs(segment.updated_at).format("MMM Do YYYY, h:mm a") }}
                </div>
              </v-col>
            </v-row>
          </div>
          <div
            v-if="segment.paths.length || segment.sequences.length"
            class="prompts-list--wrapper mt-5"
          >
            <div class="flex items-center justify-between">
              <h3 class="text-h6">
                Active In the following
                <template v-if="segment.paths.length"> Prompts </template>
              </h3>
              <v-btn
                depressed
                class="mr-2"
                text
                color="primary"
                @click="
                  exportMultiplePromptToCsv(
                    $refs.segmentPrompts.map(el => el.prompt),
                    currChartSrc,
                  )
                "
              >
                Export All to CSV
              </v-btn>
            </div>
            <template v-if="segment.paths.length">
              <RfSegmentPrompt
                v-for="prompt in segment.paths"
                ref="segmentPrompts"
                :key="prompt.id"
                :prompt-id="prompt.id"
                :curr-chart-src="currChartSrc"
                :date-range="dateRange"
              />
            </template>
            <template v-if="segment.sequences.length">
              <h4 class="text-h6 mt-3">Guides</h4>
              <RfSegmentSequence
                v-for="sequence in segment.sequences"
                :key="sequence.id"
                :sequence-id="sequence.id"
                :curr-chart-src="currChartSrc"
                :date-range="dateRange"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { cloneDeep } from "lodash-es";
import RfUserHistory from "@/components/RfUserHistory.vue";
import RfLiveStatistics from "@/components/RfCommonCards/RfLiveStatistics.vue";
import RfSegmentName from "@/components/RfSegments/RfSegmentName.vue";
import RfSegmentStatisticsTable from "@/components/RfCommonCards/RfSegmentStatisticsTable.vue";
import RfDetailHeader from "@/components/RfDetailHeader.vue";
import RfModalLoading from "@/views/RfModalLoading.vue";
import dayjs from "dayjs";
import RfSegmentPrompt from "@/components/RfSegments/RfSegmentPrompt.vue";
import RfSegmentSequence from "@/components/RfSegments/RfSegmentSequence.vue";
import { useToastsStore } from "@/pinia/toastsStore";
import { exportMultiplePromptToCsv } from "@/utils/CsvUtils";

export default {
  name: "RfSegmentView",
  setup: () => ({ dayjs, toastsStore: useToastsStore(), exportMultiplePromptToCsv }),
  components: {
    RfUserHistory,
    RfLiveStatistics,
    RfSegmentName,
    RfSegmentStatisticsTable,
    RfDetailHeader,
    RfModalLoading,
    RfSegmentPrompt,
    RfSegmentSequence,
  },
  data() {
    return {
      deleting: false,
      timerId: null,
      loading: false,
      currChartSrc: null,
      dateRange: null,
    };
  },
  computed: {
    ...mapState({
      app: state => state.apps.currApp,
      segment: state => state.apps.currSegment,
      activities: state => {
        const copy = [...state.apps.currSegmentActivities];
        return copy;
      },
      metrics: state => state.apps.currSegmentMetrics,
      usage_type: state => state.apps.currSegmentMetricsUsage,
      lastError: state => state.lastError,
    }),
    shouldShowEnableDisable() {
      return (
        !this.segment.pipeline_stage_id &&
        !/^system_/.test(this.segment.segment_type) &&
        this.segment.segment_type !== "pinpoint"
      );
    },
  },
  methods: {
    ...mapActions(["getSegment", "deleteSegment", "createSegment", "updateSegment"]),
    submitDelete() {
      this.deleting = true;
      this.loading = true;
      this.deleteSegment({
        appId: this.$route.params.aid,
        segId: this.$route.params.sid,
      });
    },
    clearTimer() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
    },
    loadSegment() {
      const appId = this.$route.params.aid;
      const segmentId = this.$route.params.sid;
      if (segmentId && (!this.segment || this.segment.id !== segmentId)) {
        this.getSegment({
          appId,
          segId: segmentId,
        });
      }
    },
    async enableSegment(enable) {
      this.loading = true;
      const newSegment = cloneDeep(this.segment);
      newSegment.is_enabled = enable;
      await this.updateSegment({
        appId: this.$route.params.aid,
        segId: newSegment.id,
        modelSegment: newSegment,
      })
        .then(() => (this.loading = false))
        .catch(() => null);
    },
    async clone() {
      const segment = cloneDeep(this.segment);
      segment.id = null;
      segment.is_locked = false;
      segment.pipeline_stage_id = null;
      segment.name = `${segment.name} copy`;

      await this.createSegment({
        appId: this.$route.params.aid,
        modelSegment: segment,
      })
        .then(() => {
          this.toastsStore.create({ type: "success", body: "Segment created successfully" });
          const path = `/apps/${this.$route.params.aid}/segments/${this.segment.id}`;
          this.$router.push({ path });
        })
        .catch(() => null);
    },
    async executeMount() {
      const appId = this.$route.params.aid;
      const segmentId = this.$route.params.sid;
      if (this.segment && this.segment.id === segmentId) return null;
      if (segmentId) {
        return this.loadSegment();
      }
    },
  },
  mounted() {
    this.executeMount();
  },
  watch: {
    async $route(to) {
      this.clearTimer();
      this.loadSegment();
    },
    segment() {
      if (this.deleting) {
        this.toastsStore.create({ type: "success", body: "Segment deleted successfully" });
        this.timerId = setTimeout(() => {
          this.$router.replace({
            path: `/apps/${this.$route.params.aid}/segments`,
          });
        }, 1000);
        this.loading = false;
        this.deleting = false;
      }
    },
    async lastError(to) {
      if (!to) return;
      if (to.cause === 404) {
        this.$router.push({
          path: `/apps/${this.$route.params.aid}/segments`,
        });
        this.executeMount();
      } else {
        this.clearTimer();
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>
