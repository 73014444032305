<template>
  <div>
    <v-dialog scrollable persistent overlay-opacity="0.85" v-model="editVariantDialog" width="95%">
      <v-card class="experiment-create-variant-dialog" v-if="model.actions">
        <v-card-title class="headline experiment-create-variant flex justify-between">
          {{ title }}
          <RfTestEmailSender :path="path" v-if="isEmail" :actionGroupId="variantDetail.id" />
          <v-btn
            v-else-if="isStandaloneWeb"
            style="width: 150px"
            color="#efefef"
            depressed
            @click="livePreviewShow = true"
          >
            <v-icon left>open_in_new</v-icon>Live Preview
          </v-btn>
        </v-card-title>
        <v-card-text style="padding: 0">
          <div
            class="rf-detail-content-wrapper abtest-variant-modal-preview"
            style="margin: 0; padding: 0 10px 10px"
          >
            <div class="create-variant-dialog-header">
              <label>Variation name:</label>
              <div class="variant-name">
                <v-text-field
                  outlined
                  dense
                  solo
                  flat
                  hide-details
                  label="Variation Name"
                  v-model.trim="model.name"
                  required
                  :disabled="isReadOnlyRoleMixin || isControl"
                  hint="Name the variation. This path variation will be displayed as Test Path - Variation Name."
                  v-validate="'required'"
                  persistent-hint
                  placeholder="e.g. option-1"
                  :rules="[rules.required]"
                ></v-text-field>
              </div>
            </div>
            <div style="display: flex; flex-direction: row">
              <div class="retention-editor-leftcol" :class="disabledClass">
                <RfRetentionActionsInputs
                  :actions="model.actions"
                  :model="path"
                  :backgroundImageEnabled="backgroundImageEnabled"
                  v-on:forceRender="forceRender"
                  :viewTypeTab="viewTypeTab"
                  :actionGroup="model"
                />
              </div>
              <div class="retention-editor-rightcol">
                <div
                  class="rf-retention-modal-preview retention-tile-preview"
                  style="height: 100%; min-height: 700px; overflow: hidden"
                  v-if="isHorizontalVerticalTile"
                >
                  <RfRetentionTile
                    :actions="model.actions"
                    :model="path"
                    :key="previewKey"
                    ref="tile"
                    :viewTypeTab="viewTypeTab"
                    v-on:clickTab="clickTab"
                  />
                </div>
                <div
                  class="rf-retention-modal-preview retention-text-preview"
                  style="height: 100%; min-height: 700px; overflow: hidden"
                  v-if="path.path_type === 'text'"
                >
                  <RfRetentionTileText
                    :actions="model.actions"
                    :model="path"
                    :key="previewKey"
                    ref="tile"
                    :viewTypeTab="viewTypeTab"
                    v-on:clickTab="clickTab"
                  />
                </div>
                <div class="rf-retention-modal-preview" v-if="path.path_type === 'retention_modal'">
                  <RfRetentionDeviceWeb
                    :model="path"
                    :actions="model.actions"
                    :actionGroupId="model.id"
                    :viewTypeTab="viewTypeTab"
                    :key="previewKey"
                    v-on:clickTab="clickTab"
                    v-if="path.device_type === 'web'"
                    ref="web"
                  />
                  <RfRetentionDeviceIos
                    :model="path"
                    :actions="model.actions"
                    :viewTypeTab="viewTypeTab"
                    :key="previewKey"
                    v-on:clickTab="clickTab"
                    v-if="path.device_type === 'ios'"
                    ref="ios"
                  />
                  <RfRetentionDeviceTvOs
                    :model="path"
                    :actions="model.actions"
                    :viewTypeTab="viewTypeTab"
                    :key="previewKey"
                    v-on:clickTab="clickTab"
                    v-if="
                      path.device_type === 'tv_os' ||
                      path.device_type === 'roku_os' ||
                      path.device_type === 'custom_defined'
                    "
                    ref="tv"
                  />
                  <RfRetentionDeviceAndroidOs
                    :model="path"
                    :actions="model.actions"
                    :viewTypeTab="viewTypeTab"
                    :key="previewKey"
                    v-on:clickTab="clickTab"
                    v-if="path.device_type === 'android_os'"
                    ref="android_os"
                  />
                </div>
                <div
                  class="rf-retention-modal-preview retention-video-preview"
                  v-if="path.path_type === 'video'"
                >
                  <RfRetentionDeviceWeb
                    :model="path"
                    :actions="model.actions"
                    :actionGroupId="model.id"
                    :viewTypeTab="viewTypeTab"
                    :key="previewKey"
                    v-on:clickTab="clickTab"
                    v-if="path.device_type === 'web'"
                    :retentionTypeVideo="true"
                    ref="web"
                  />
                </div>
                <div class="rf-retention-modal-preview" v-if="isWidgetOrInterstitial">
                  <RfRetentionBanner
                    :model="path"
                    :actionGroupId="model.id"
                    :actions="model.actions"
                    :viewTypeTab="viewTypeTab"
                    :key="previewKey"
                    v-on:clickTab="clickTab"
                    ref="web"
                    v-if="path.path_type === 'bottom_banner'"
                  />
                  <RfRetentionInterstitial
                    :model="path"
                    :actionGroupId="model.id"
                    :actions="model.actions"
                    :viewTypeTab="viewTypeTab"
                    :key="previewKey"
                    v-on:clickTab="clickTab"
                    ref="web"
                    v-else
                  />
                </div>
                <div v-if="isEmail">
                  <RfRetentionEmail
                    :actions="model.actions"
                    :key="previewKey"
                    ref="email"
                    :viewTypeTab="viewTypeTab"
                    v-on:clickTab="clickTab"
                    :model="path"
                  />
                </div>
              </div>
            </div>
            <div class="variant-third-party-actions" :class="disabledClass" style="padding: 0">
              <v-expansion-panels v-model="getPromoThirdPartyActions" multiple focusable>
                <v-expansion-panel>
                  <v-expansion-panel-header>ACTIONS</v-expansion-panel-header>
                  <v-expansion-panel-content class="retention-config-segments">
                    <div class="api-website-action-div">
                      <div style="padding-left: 4px">
                        <RfRetentionActionsThirdParty
                          :actions="model.actions"
                          :model="path"
                          :action_group_connector_actions="model.action_group_connector_actions"
                          :disabled="false"
                          ref="thirdParty"
                          action_type="connectors"
                          :isControl="isControl"
                        />
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="isStandalone">
                  <v-expansion-panel-header>Triggers</v-expansion-panel-header>
                  <v-expansion-panel-content style="padding: 10px 20px; background: #f4f9fd">
                    <RfTrigger
                      :isDisabled="isDisabled || isControl"
                      :model="modelWithPathInfo"
                      :isEditBtnDisabled="isControl"
                      ref="trigger"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            x-large
            depressed
            class="cancel-btn"
            outlined
            width="100px"
            @click="$emit('closeEditVariantModal')"
            >{{ isDisabled ? "Close" : "Cancel" }}</v-btn
          >
          <RfButton
            icon="save"
            button-text="Save changes"
            x-large
            color="primary"
            width="200px"
            :disabled="isDisabled"
            @click="saveExperiment"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RfDomainChooserWrapper
      :app="currApp"
      :visible="livePreviewShow"
      v-on:open="openLivePreview"
      v-on:close="closeLivePreview"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import RfRetentionTile from "@/components/RfPathCreate/RfRetentionActions/RfRetentionTile.vue";
import RfRetentionTileText from "@/components/RfPathCreate/RfRetentionActions/RfRetentionTileText.vue";
import RfRetentionActionsInputs from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsInputs.vue";
import RfRetentionActionsThirdParty from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsThirdParty.vue";
import RfRetentionDeviceWeb from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionDeviceWeb.vue";
import RfRetentionDeviceIos from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionDeviceIos.vue";
import RfRetentionDeviceTvOs from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionDeviceTvOs.vue";
import RfRetentionDeviceAndroidOs from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionDeviceAndroidOs.vue";
import RfRetentionInterstitial from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionInterstitial.vue";
import RfDomainChooserWrapper from "@/components/RfCommonCards/RfDomainChooserWrapper.vue";
import RfTrigger from "@/components/RfPathCreate/RfTrigger.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import LiveMixin from "@/utils/LiveMixin";
import RoleMixin from "@/utils/RoleMixin";
import RfRetentionEmail from "@/components/RfPathCreate/RfRetentionActions/RfRetentionEmail.vue";
import RfTestEmailSender from "@/components/RfPromotions/RfTestEmailSender.vue";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { isEmail, isStandalone, isStandaloneWeb } from "@/utils/prompts/promptHelpers";
import RfRetentionBanner from "@/components/RfPathCreate/RfRetentionDevices/RfRetentionBanner.vue";

export default {
  name: "RfEditAbVariant",
  setup: () => ({ toastsStore: useToastsStore() }),
  components: {
    RfRetentionTile,
    RfRetentionTileText,
    RfRetentionActionsInputs,
    RfRetentionActionsThirdParty,
    RfRetentionDeviceWeb,
    RfRetentionDeviceIos,
    RfRetentionDeviceTvOs,
    RfRetentionDeviceAndroidOs,
    RfRetentionInterstitial,
    RfDomainChooserWrapper,
    RfTrigger,
    RfRetentionEmail,
    RfTestEmailSender,
    RfButton,
    RfRetentionBanner,
  },
  props: [
    "path",
    "editVariantDialog",
    "currExperiment",
    "variantDetail",
    "variantName",
    "isNew",
    "disabled",
    "title",
  ],
  mixins: [LiveMixin, RoleMixin],
  data() {
    return {
      getPromoThirdPartyActions: [0],
      actionTypeTab: "1",
      viewTypeTab: "desktopViewTab",
      isUpdating: false,
      previewKey: 0,
      actions: {},
      rules: {
        required: value => !!value || "Name field is required.",
      },
      backgroundImageEnabled: null,
      newVariant: {
        name: null,
        traffic_percent: 0,
        actions: this.path.actions,
        action_group_connector_actions: this.path.action_group_connector_actions,
        triggers: this.path.triggers,
      },
      model: {},
      livePreviewShow: false,
    };
  },
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      currApp: state => state.apps.currApp,
      appClientActions: state => state.apps.clientActions || [],
    }),
    isDisabled() {
      return this.isDisabledRoleMixin || this.disabled;
    },
    isWidgetOrInterstitial() {
      const pathType = this.path.path_type;
      return pathType === "interstitial" || pathType === "widget" || pathType === "bottom_banner";
    },
    isControl() {
      return this.model.is_control;
    },
    isStandaloneWeb() {
      return isStandaloneWeb(this.path);
    },
    isStandalone() {
      return isStandalone(this.path);
    },
    isHorizontalVerticalTile() {
      const pathType = this.path.path_type;
      return pathType === "horizontal" || pathType === "vertical" || pathType === "tile";
    },

    clientActions() {
      const blank = [{ text: "None", value: "" }];
      const actions = this.appClientActions
        .filter(action => action.action_type === "website_action")
        .map(action => {
          return { text: action.name, value: action.id };
        });
      return blank.concat(actions);
    },
    disabledClass() {
      if (this.isDisabled) {
        return "variant-detail-actions inputs-disabled";
      }
      return "";
    },
    modelWithPathInfo() {
      const result = cloneDeep(this.model);
      result.path_type = this.path.path_type;
      result.device_type = this.path.device_type;
      return result;
    },
    isEmail() {
      return isEmail(this.path);
    },
  },
  methods: {
    ...mapActions(["updateExperiment", "getClientActions"]),
    forceRender() {
      this.previewKey += 1;
    },
    async saveExperiment() {
      try {
        if (!this.model.name) throw new Error("Variant name can't be empty");
        if (Object.keys(this.model.actions) == 0)
          throw new Error("At least one action needs to be added");

        const actionPayload = {
          actions: this.model.actions,
          device_type: this.path.device_type,
          path_type: this.path.path_type,
        };

        for (const os of ["web", "ios", "tv", "android_os"]) {
          if (this.$refs[os]) {
            await this.$refs[os].fillModel(actionPayload);
          }
        }
        if (this.$refs.tile) {
          await this.$refs.tile.fillModel(actionPayload);
        }

        if (this.$refs.text) {
          await this.$refs.text.fillModel(actionPayload);
        }
        let loadingIndex = -1;
        if (this.isNew) {
          const newItem = {
            name: this.model.name,
            traffic_percent: 0,
            actions: {
              ...actionPayload.actions,
            },
            triggers: this.path.triggers,
            translations: this.model.translations,
          };
          this.$refs.thirdParty.fillModel(newItem);
          if (this.$refs.trigger) this.$refs.trigger.fillModel(newItem);

          this.currExperiment.variations = [...this.currExperiment.variations, newItem];
          loadingIndex = this.currExperiment.variations.length - 1;
        } else {
          const currVariantIndex = this.currExperiment.variations.findIndex(
            item => item.id === this.model.id,
          );

          this.currExperiment.variations[currVariantIndex] = {
            ...this.currExperiment.variations[currVariantIndex],
            name: this.model.name,
            actions: {
              ...actionPayload.actions,
            },
            translations: this.model.translations,
          };
          this.$refs.thirdParty.fillModel(this.currExperiment.variations[currVariantIndex]);
          if (this.$refs.trigger)
            this.$refs.trigger.fillModel(this.currExperiment.variations[currVariantIndex]);
          loadingIndex = currVariantIndex;
        }

        this.updateExperiment({
          pathId: this.path.id,
          expId: this.currExperiment.id,
          modelExperiment: this.currExperiment,
          loadingIndex,
        });

        this.$emit("closeEditVariantModal");
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    clickTab(tabType) {
      this.viewTypeTab = tabType;
    },
    openLivePreview(domain) {
      this.viewPreview(domain, { actionGroupId: this.model.id });
      this.closeLivePreview();
    },
    closeLivePreview() {
      this.livePreviewShow = false;
    },
  },
  mounted() {
    if (this.variantDetail) {
      this.model = cloneDeep(this.variantDetail);
    } else {
      this.model = cloneDeep(this.newVariant);
    }

    if (this.isNew) {
      this.backgroundImageEnabled = this.path.id ? !!this.path.actions.rf_settings_bg_image : true;
    } else {
      this.backgroundImageEnabled = this.model.id
        ? !!this.model.actions.rf_settings_bg_image
        : true;
    }
    if (!this.appClientActions.length) this.getClientActions({ appId: this.currApp.id });
  },
};
</script>

<style lang="scss">
.inputs-disabled {
  &:not(.variant-third-party-actions) :not(.inputs-disabled--ignore).v-expansion-panel-content,
  &.variant-third-party-actions .promo-design-group.promotion-actions-table {
    pointer-events: none;
    opacity: 0.5;
    margin: 0 10px;
  }
}
</style>
