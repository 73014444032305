<template>
  <div class="promo-integration-content-wrapper">
    <div :class="['promo-integration-modal-wrapper', `tabs-content-editing-${isEditing}`]">
      <div
        :class="[`promo-integration-tab-content editing-${isEditing}`]"
        v-for="(item, index) in thirdPartyOptions"
        :key="index"
        v-show="item.provider === currentTab"
      >
        <div v-if="selectedIntegration && isEditing">
          <div class="promoint-slctd-name">
            <span class="slctd-int-provider">{{ selectedIntegration.provider }}</span>
            <v-icon left right>chevron_right</v-icon>
            {{ selectedIntegration.description }}
          </div>
        </div>
        <v-card-text v-else>
          <v-radio-group
            hide-details
            class="thirdparty-radio"
            v-model="selectedIntegration"
            style="margin-top: 10px; padding-top: 0"
          >
            <div
              class="thirdparty-radio-item"
              v-for="(action, index) in item.value.actions"
              :key="index"
            >
              <v-radio
                :label="action.description"
                :value="action"
                :disabled="action.disabled"
                v-if="!action.disabled"
              ></v-radio>
              <div class="thirdparty-input-error-div" v-else>
                <v-icon color="#cccccc">radio_button_unchecked</v-icon>
                <div>
                  <div>{{ action.description }}</div>
                  <div class="thirdparty-input-error">
                    <v-icon size="16" left color="warning">warning</v-icon>Requirements not met.
                  </div>
                </div>
              </div>
            </div>
          </v-radio-group>
        </v-card-text>
        <div class="promo-intergration-tab-next-value">
          <div
            class="promo-add-integration-thirdparty-value"
            v-for="(input, index) in selectedIntegration.inputs"
            :key="'input' + index"
          >
            <v-text-field
              outlined
              dense
              hide-details
              :label="input.valueLabel"
              v-model="integrationValue[input.valueDescription]"
              required
              :placeholder="input.placeholder"
              v-if="input.type === 'text_input'"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="integrationValue[input.valueDescription]"
              :label="input.valueLabel"
              :placeholder="input.placeholder"
              type="number"
              required
              v-if="input.type === 'number_input'"
            />
            <v-select
              outlined
              hide-details
              dense
              required
              v-model="integrationValue[input.valueDescription]"
              :label="input.valueLabel"
              :items="dropdownOptions(input.values)"
              v-if="input.type === 'select'"
            ></v-select>
            <v-textarea
              outlined
              hide-details
              dense
              required
              rows="3"
              :label="input.valueLabel"
              v-model="integrationValue[input.valueDescription]"
              v-if="input.type === 'text_field'"
            ></v-textarea>
            <div v-if="input.type === 'json'">
              <p>{{ input.valueLabel }}</p>
              <PrismEditor
                v-model="integrationValue[input.valueDescription]"
                language="json"
              ></PrismEditor>
            </div>
            <p v-if="input.counter && integrationValue[input.valueDescription]">
              Character Count: {{ integrationValue[input.valueDescription].length }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="promo-add-integration-buttons" style="padding-top: 1rem; text-align: right">
      <v-btn
        large
        depressed
        style="margin-right: 1rem"
        outlined
        @click="close"
        class="promo-add-integration cancel-btn"
        >Cancel</v-btn
      >
      <v-btn
        large
        depressed
        color="success"
        @click="submitAdd(integrationValue, selectedIntegration)"
        :disabled="!submitEnabled"
        class="promo-add-integration"
        style="width: 200px"
      >
        <v-icon left>add</v-icon>
        {{ buttonCTA }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { cloneDeep } from "lodash-es";
import PrismEditor from "vue-prism-editor";

export default {
  name: "RfRetentionConnectorThirdParties",
  components: { PrismEditor },
  props: [
    "model",
    "editIntegration",
    "editIntegrationValue",
    "isEditing",
    "thirdPartyOptions",
    "currentTab",
  ],
  data() {
    return {
      selectedIntegration: this.editIntegration,
      integrationValue: this.editIntegrationValue,
    };
  },
  methods: {
    close() {
      this.resetSelectedIntegration();
      this.$emit("closeConnectorDialog");
    },
    resetSelectedIntegration() {
      this.integrationValue = {};
      this.selectedIntegration = {};
    },
    submitAdd(value, integration) {
      this.$emit("addIntegration", value, integration);
      this.resetSelectedIntegration();
    },
    dropdownOptions(options) {
      if (!options.map) return [];
      return options.map(option => {
        if (typeof option === "object") {
          return {
            text: option.name,
            value: option.id,
          };
        }
        return option;
      });
    },
  },
  computed: {
    ...mapState({ app: state => cloneDeep(state.apps.currApp) }),
    buttonCTA() {
      if (this.isEditing) {
        return "Update Action";
      }
      return "Add Action";
    },
    submitEnabled() {
      if (this.selectedIntegration.inputs) {
        const blankValue = this.selectedIntegration.inputs.find(
          input => !this.integrationValue[input.valueDescription] && input.isRequired,
        );
        return !blankValue;
      }
      return false;
    },
  },
  watch: {
    integrationValue(to) {
      this.$emit("integrationValue", to);
    },
    selectedIntegration(to) {
      this.$emit("selectedIntegration", to);

      if (to.inputs) {
        const inputsWithDefaults = to.inputs.filter(input => input.defaultValue);
        inputsWithDefaults.forEach(
          input => (this.integrationValue[input.valueDescription] = input.defaultValue),
        );
      }
    },
  },
};
</script>
