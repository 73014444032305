<template>
  <div class="promo-triggers-list">
    <div v-if="showTruncate" class="promo-triggers-row">
      <RfTriggerDetailInfo :trigger="triggers[0]" v-if="triggers.length" />
      <div class="show-more-triggers" v-if="allTriggersCount > 1">
        <a>Show all {{ allTriggersCount }} triggers</a>
      </div>
    </div>
    <template v-else>
      <div
        class="promo-triggers-row"
        v-for="(trigger, index) in [...triggers].sort((a, b) =>
          `${a.name}`.localeCompare(`${b.name}`),
        )"
        :key="index"
      >
        <RfTriggerDetailInfo :trigger="trigger" />
      </div>
    </template>
  </div>
</template>

<script>
import RfTriggerDetailInfo from "@/components/RfTriggerDetailInfo.vue";

export default {
  name: "RfCardTriggerInfo",
  components: { RfTriggerDetailInfo },
  props: {
    triggers: { type: Array, default: () => [] },
    showTruncate: { type: Boolean, default: false },
  },
  computed: {
    allTriggersCount() {
      return this.triggers.length;
    },
  },
};
</script>
