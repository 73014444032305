import moment from "moment-timezone";
import { METRICS_PERIODS } from "./constants/MetricsConstants";
import { dateOrder } from "./DateDisplayUtils";

export default class MetricsUtils {
  static getPeriods() {
    return MetricsUtils.All_Periods.map(item => item); // js implementation of clone, so you don't accidentally mutate
  }

  static getComparablePeriods() {
    return MetricsUtils.All_Periods.filter(
      item => MetricsUtils.Comparisons[item.value] !== undefined,
    );
  }

  static metricDateTimeFormatter(metricsKey, date, tz_offset, format, singleDay) {
    let text = null;
    const symbol = (tz_offset || 0) < 0 ? "-" : "+";
    let paddedTz = (tz_offset || 0).toString().padStart(2, "0");
    paddedTz = `${symbol}${paddedTz}00`;
    text = moment.utc(`${date}${paddedTz}`, "YYYYMMDDHHmmZZ");
    if (metricsKey === "this_week" || metricsKey === "last_week") {
      return text.format(format || "dd");
    }
    if (metricsKey === "today" || metricsKey === "yesterday" || singleDay) {
      return text.format(format || "hA");
    }
    return text.format(dateOrder());
  }
}

MetricsUtils.Comparisons = {
  this_month: "last_month",
  this_week: "last_week",
  last_seven_days: "last_seven_to_fourteen_days",
  today: "yesterday",
};

MetricsUtils.All_Periods = Object.values(METRICS_PERIODS);

MetricsUtils.CohortPeriods = [
  { value: 7, name: "Last 7 days" },
  { value: 14, name: "Last 14 days" },
  { value: 30, name: "Last 30 days" },
  { value: 60, name: "Last 60 days" },
  { value: 90, name: "Last 90 days" },
];
