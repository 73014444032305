<template>
  <div v-if="filteredInputNumbers.length" class="rfmodal-input-wrapper">
    <div v-for="number in filteredInputNumbers" :key="number" style="padding-bottom: 10px">
      <div class="rfmodal-input-label">
        <label :style="inputLabelColor">{{ inputLabel(number) }}</label>
      </div>
      <div class="rfmodal-input-inputfield">
        <input
          :placeholder="placeholderText(number)"
          :type="inputType(number)"
          :style="inputStyle(number)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RfPromoInputsPreview",
  props: ["actions", "isInline"],
  data() {
    return {
      inputNumbers: [1, 2, 3],
    };
  },
  methods: {
    shouldShow(inputNumber) {
      return this.inputType(inputNumber) && this.inputLabel(inputNumber);
    },
    inputType(inputNumber) {
      return this.actions[`rf_retention_promo_input_${inputNumber}_type`];
    },
    inputLabel(inputNumber) {
      return this.actions[`rf_retention_promo_input_${inputNumber}_label`];
    },
    inputPlaceholder(inputNumber) {
      return this.actions[`rf_retention_promo_input_${inputNumber}_placeholder`];
    },
    placeholderText(inputNumber) {
      const placeholder = this.inputPlaceholder(inputNumber);
      if (placeholder) return placeholder;
      const type = this.inputType(inputNumber);
      if (type === "email") {
        return "e.g.: name@email.com";
      }
      if (type === "number") {
        if (this.isInline) return "eg: 2125552121";
        return "+1 (212) 555-2121";
      }
      if (type === "address") {
        return "e.g.: 123 Main Street";
      }
      return "e.g.: John Doe";
    },
    inputStyle(inputNumber) {
      return {
        "width": this.actions[`rf_retention_promo_input_${inputNumber}_width`] || "60%",
        "background-color": "white",
      };
    },
  },
  computed: {
    filteredInputNumbers() {
      return this.inputNumbers.filter(number => this.shouldShow(number));
    },
    inputLabelColor() {
      return {
        color: this.actions.rf_settings_fill_color,
      };
    },
  },
};
</script>
