<template>
  <div>
    <div class="input-insert-button" v-if="hasUserTraits">
      <a @click="insertVariableMsg">
        <v-icon left size="14" color="accent">add</v-icon>
        <span>Insert variable</span>
      </a>
    </div>
    <v-dialog v-model="newDynamicValueDialog" width="550" max-width="550">
      <v-card>
        <v-card-title class="headline">{{ modalVariableTitle }}</v-card-title>
        <v-card-text>
          <RfSelect
            v-model="selectedVariableGroup"
            :items="[
              { text: 'Data Source variables', value: 'data-sources' },
              { text: 'Dynamic variables', value: 'dynamic' },
            ]"
            title="Group"
            :hide-details="!selectedVariableGroup"
            outlined
            dense
            placeholder="Select group"
          />
          <RfSelect
            v-if="selectedVariableGroup === 'dynamic'"
            v-model="selectedDynamicTrait"
            :items="availableUserTraits"
            title="Dynamic Variable"
            outlined
            hide-details
            dense
            placeholder="Select variable"
          />
          <template v-if="selectedVariableGroup === 'data-sources'">
            <RfSelect
              v-model="selectedDataSource.value"
              :items="dataSourcesList"
              title="Source"
              outlined
              dense
              placeholder="Select source"
              @input="
                selectedDataSource.value && (selectedDataSource.item = selectedDynamicTrait = null)
              "
            />
            <RfSelect
              v-model="selectedDataSource.item"
              :items="dataSourcesItemsList"
              title="Item"
              outlined
              dense
              placeholder="Select item"
              @input="selectedDataSource.item && (selectedDynamicTrait = null)"
            />
            <RfSelect
              v-model="selectedDynamicTrait"
              :items="dataSourceItemDataList"
              title="Variable"
              outlined
              hide-details
              dense
              placeholder="Select variable"
            />
          </template>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <RfButton
            button-text="Cancel"
            class="cancel-btn px-5"
            outlined
            @click="closeInsertModal"
          />
          <RfButton
            color="primary"
            button-text="Insert variable"
            class="px-7"
            :disabled="!selectedDynamicTrait || !selectedVariableGroup"
            @click="addDynamicTrait"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";

export default {
  name: "RfInsertUserTrait",
  props: ["app", "actionName", "title", "actions", "noSpace"],
  components: { RfSelect, RfButton },
  mixins: [CustomFieldsMixin],
  data() {
    return {
      selectedVariableGroup: null,
      selectedDynamicTrait: null,
      selectedDataSource: { value: null, item: null },
      newDynamicValueDialog: false,
    };
  },
  computed: {
    ...mapState({ dataSources: state => state.apps.dataSources }),
    dataSourcesList() {
      if (!this.dataSources) return [];
      return this.dataSources.map(el => ({ text: el.name, value: el }));
    },
    dataSourcesItemsList() {
      if (!this.selectedDataSource.value) return [];
      const displayName = Object.entries(this.selectedDataSource.value.properties).find(
        ([_, v]) => v.use_as_display_name,
      );
      return this.selectedDataSource.value.data_source_items.map(el => ({
        text: this.dataSourceDisplay(el, displayName),
        value: el,
      }));
    },
    dataSourceItemDataList() {
      if (!this.selectedDataSource.item) return [];

      return Object.keys(this.selectedDataSource.item.data).reduce(
        (acc, el) =>
          this.selectedDataSource.value.properties[el].visible
            ? [
                ...acc,
                {
                  text: el,
                  value: `[[${this.selectedDataSource.value.name}.${this.selectedDataSource.item.key}.${el}]]`,
                },
              ]
            : acc,
        [],
      );
    },
    availableUserTraits() {
      if (!this.app) return [];
      return this.appCustomFields.map(({ label }) => ({
        text: label,
        value: `[[user.${label}]]`,
      }));
    },
    hasUserTraits() {
      return this.availableUserTraits && this.availableUserTraits.length > 0;
    },
    modalVariableTitle() {
      return `Insert variable into ${this.title}`;
    },
  },
  methods: {
    ...mapActions(["getDataSources"]),
    dataSourceDisplay(el, displayName) {
      if (el.data[displayName?.[0]]) {
        return `${el.data[displayName[0]]} (${el.key})`;
      } else {
        return el.key;
      }
    },
    insertVariableMsg() {
      this.newDynamicValueDialog = true;
    },
    addDynamicTrait() {
      let action = this.actions[this.actionName];
      if (this.noSpace) {
        action = action.concat(this.selectedDynamicTrait);
      } else {
        action = action.concat(` ${this.selectedDynamicTrait} `);
      }
      this.actions[this.actionName] = action;
      this.closeInsertModal();
    },
    closeInsertModal() {
      this.newDynamicValueDialog = false;
      this.selectedDataSource.item =
        this.selectedDataSource.variable =
        this.selectedDynamicTrait =
          null;
    },
  },
  watch: {
    newDynamicValueDialog(v) {
      if (v && (!this.dataSources || !this.dataSources.length))
        this.getDataSources({ appId: this.$route.params.aid });
    },
  },
};
</script>
