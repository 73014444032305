<template>
  <div class="segment-location-all">
    <div class="sgmnt-inner-option">
      <RfGooglePlaceAutoCompletion
        title="Choose Cities"
        help="Choose the cities to limit this segment to. Ex. San Francisco."
        v-model="cities"
        geoType="cities"
      />
    </div>
    <div class="sgmnt-inner-option">
      <RfAutoCompletionWithChips
        :options="Object.keys(USStates)"
        v-model="states"
        title="Choose States"
        help="Choose the states to limit this segment to. Ex. CA."
      />
    </div>
    <div class="sgmnt-inner-option">
      <RfAutoCompletionWithChips
        v-model="countries"
        :options="countryList.getNames()"
        title="Country"
        help="Choose the country to limit this segment to. Ex. United States."
      />
    </div>
    <div class="sgmnt-inner-option">
      <RfAutoCompletionWithChips
        :options="Object.values(DMAs).sort()"
        v-model="metro_codes"
        title="Choose DMA"
        help="Choose Designated Market Area (US Only)"
      />
    </div>
    <div class="sgmnt-inner-option">
      <RfGooglePlaceAutoCompletion
        title="Choose Zip Codes"
        help="Choose the postal codes to limit this segment to. Ex. 90210. (US Only)"
        v-model="postal_codes"
        geoType="regions"
      />
    </div>
  </div>
</template>

<script>
import CountryList from "country-list";
import RfAutoCompletionWithChips from "../RfAutoCompletionWithChips.vue";
import RfGooglePlaceAutoCompletion from "../RfGooglePlaceAutoCompletion.vue";
import { DMAs, USStates } from "@/utils/constants/LocationsConstants";

export default {
  name: "RfLocation",
  components: { RfAutoCompletionWithChips, RfGooglePlaceAutoCompletion },
  props: ["model"],
  setup: () => ({ USStates, DMAs }),
  data() {
    const { location } = this.model.filter || {};
    return {
      cities: location.cities,
      states: location.states,
      countries: this.translateCountry(location.countries),
      postal_codes: location.postal_codes,
      metro_codes: location.metro_codes.map(dma => DMAs[dma]),
    };
  },
  watch: {
    model(to) {
      const { location } = to.filter;
      this.cities = location.cities;
      this.states = location.states;
      this.countries = this.translateCountry(location.countries);
      this.postal_codes = location.postal_codes;
      this.metro_codes = location.metro_codes.map(dma => DMAs[dma]);
    },
  },
  methods: {
    translateCountry(countries) {
      return countries.map(country => CountryList.getName(country));
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    fillModel(model) {
      const { location } = model.filter;
      location.cities = this.cities;
      location.states = this.states;
      location.countries = this.countries.map(country => CountryList.getCode(country));
      location.postal_codes = this.postal_codes;
      location.metro_codes = this.metro_codes.map(dma => this.getKeyByValue(DMAs, dma));
    },
  },
  computed: {
    countryList() {
      return CountryList;
    },
  },
};
</script>

<style scoped></style>
