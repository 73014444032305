<template>
  <RfBaseModal
    ref="modal"
    content-class="show-promo-details-actions"
    width="580"
    v-bind="$attrs"
    v-on="$listeners"
    @primary="submit"
  >
    <template #body="{ opened }">
      <div class="d-flex flex-column">
        <slot name="prepend"></slot>
        <RfTextInput
          dense
          outlined
          persistent-hint
          autofocus
          data-cy="element-info--name"
          placeholder="Name"
          title="Name"
          hint="e.g., New users acquisition"
          :rules="[value => !!value || 'Name field is required.']"
          v-model.trim="nameLocal"
        ></RfTextInput>
        <RfTextInput
          dense
          outlined
          hide-details
          data-cy="element-info--description"
          placeholder="Description"
          title="Description"
          hint="e.g., User acquisition designed around generating new registrations"
          v-model.trim="descriptionLocal"
        ></RfTextInput>
        <slot name="append" :opened="opened"></slot>
      </div>
    </template>
  </RfBaseModal>
</template>

<script>
import * as Sentry from "@sentry/browser";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  components: { RfBaseModal, RfTextInput },
  props: ["name", "description", "onSubmit", "type"],
  setup: () => ({ toastsStore: useToastsStore() }),
  data: () => ({ nameLocal: null, descriptionLocal: null, loading: false }),
  computed: {
    disabled() {
      return !this.nameLocal;
    },
  },
  methods: {
    show() {
      this.$refs.modal.show();
      this.nameLocal = this.name;
      this.descriptionLocal = this.description;
    },
    close() {
      this.$refs.modal?.close();
      this.$emit("close");
      setTimeout(() => (this.nameLocal = this.descriptionLocal = null), 330);
    },
    async submit() {
      try {
        if (this.disabled) return;
        this.loading = true;
        await this.onSubmit({ name: this.nameLocal, description: this.descriptionLocal });
        this.close();
        this.type &&
          this.toastsStore.create({ type: "success", body: `${this.type} updated successfully` });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
        Sentry.captureException(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
