<template>
  <div>
    <RfPromoDetailsInfoRow v-if="path" title="Dimensions">
      <template v-slot:promodetail>
        <div>
          Web: {{ settings.width }} x {{ settings.height }}
          <br />
          Mobile web: {{ settings.m_width }} x {{ settings.m_height }}
        </div>
        <div class="pd-info-edit">
          <RfButton
            icon-size="16"
            title="edit"
            icon="edit"
            color="accent"
            x-small
            :disabled="editDisabled"
            @click="showDimensionDialog"
          />
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <v-dialog
      v-model="showDimensionInfoDialog"
      width="700"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-title class="headline">Dimensions</v-card-title>
        <v-card-text>
          <RfZoneId :model="path" ref="placementZone" :showNameDetails="false" />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed class="cancel-btn" outlined width="100px" @click="closeDialog"
            >Cancel</v-btn
          >
          <v-btn
            large
            @click="submitConfiguration(path)"
            :disabled="!isZoneIsValid"
            width="200px"
            color="primary"
            depressed
          >
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfZoneId from "@/components/RfCommonCards/RfZoneId.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { ref } from "vue";

export default {
  name: "RfZoneDimension",
  setup: () => ({ placementZone: ref(), toastsStore: useToastsStore() }),
  props: ["path", "editDisabled"],
  components: { RfPromoDetailsInfoRow, RfZoneId, RfButton },
  data: () => ({ showDimensionInfoDialog: false, updating: false }),
  computed: {
    ...mapState({ pathGroups: state => cloneDeep(state.apps.pathGroups) }),
    settings() {
      if (this.path) return this.path.settings;
      return {};
    },
    isZoneIsValid() {
      return this.placementZone?.isValid;
    },
  },
  methods: {
    ...mapActions(["updatePathGroup"]),
    closeDialog() {
      this.showDimensionInfoDialog = false;
    },
    showDimensionDialog() {
      this.showDimensionInfoDialog = true;
    },
    async submitConfiguration(item) {
      try {
        const newPath = cloneDeep(item);
        if (this.$refs.placementZone) {
          this.$refs.placementZone.fillModel(newPath);
        }
        this.updating = true;
        this.showDimensionInfoDialog = false;
        await this.updatePathGroup({ appId: this.$route.params.aid, model: newPath });
      } catch (e) {
        this.updating = false;
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
  },
  watch: {
    pathGroups() {
      if (this.updating) {
        this.toastsStore.create({ type: "success", body: "Zone updated successfully" });
        this.updating = false;
      }
    },
  },
};
</script>
