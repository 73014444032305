<template>
  <div class="rf-retention-create-col1 retention-info-card">
    <v-text-field
      dense
      outlined
      persistent-hint
      autofocus
      placeholder="Name"
      data-cy="element-info--name"
      label="Name"
      hint="e.g., New users acquisition"
      :rules="[rules.required]"
      v-model.trim="name"
      hide-details
      class="mb-5"
    ></v-text-field>
    <v-text-field
      dense
      outlined
      hide-details
      placeholder="Description"
      data-cy="element-info--description"
      label="Description"
      hint="e.g., User acquisition designed around generating new registrations"
      v-model.trim="description"
      class="name-desc--field mb-5"
    ></v-text-field>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";

const rules = { required: value => !!value || "Name field is required." };

const props = defineProps({
  model: { type: Object, default: () => ({ name: "", description: "" }) },
});

const name = ref(props.model.name);
const description = ref(props.model.description);

watchEffect(() => {
  name.value = props.model.name;
  description.value = props.model.description;
});

const fillModel = model => {
  if (!name.value) throw new Error("Name field can't be empty");
  model.name = name.value;
  model.description = description.value;
};

defineExpose({ fillModel, name });
</script>
