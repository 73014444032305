<template>
  <span :class="closeBtnClassName">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      :style="{ fill: closeBtnColor }"
      id="rfmodal-close-icon"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "RfCloseButtonPreview",
  props: ["actions", "inline"],
  computed: {
    closeBtnColor() {
      return this.actions.rf_settings_fill_color || "#fff";
    },
    closeBtnClassName() {
      if (this.inline) {
        return "rfmodal-close inline-promo-close-span";
      }
      return "rfmodal-close";
    },
  },
};
</script>
