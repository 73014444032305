<template>
  <div :class="assignClassName">
    <div class="all-promo-info-name" v-if="showPromoName">
      <h3>
        {{ item.name }}
        <RfButton
          v-if="promotionView"
          icon="edit"
          color="accent"
          icon-size="16"
          class="ml-1 all-promo-info-name--edit"
          x-small
          :tooltip="{ bottom: true }"
          :disabled="isDisabledRoleMixin || editDisabled"
          @click="showPromoDetailDialog"
        />
      </h3>
      <span>{{ item.description }}</span>
    </div>
    <div class="list-promo-row-one-right" v-if="!pathGroupDisplay && showPromoError">
      <div class="promo-pipeline-links" v-if="item.pipeline_id">
        <span class="promo-name-pipeline-icon"></span>
        <span
          >Pipeline: <router-link :to="pipelineLink">{{ item.pipeline_name }}</router-link>
        </span>
      </div>
      <div class="sequence-pipeline-links" v-if="item.sequence_id">
        <v-icon color="#666" left size="14">fas fa-project-diagram</v-icon> Guide:
        <router-link :to="sequenceLink">{{ item.sequence_name }}</router-link>
      </div>
      <div class="connector-errors-ctr" v-else>
        <div class="connector-errors-txt" v-if="hasConnectorErrors(item)">
          <span>Connector errors</span>
          <v-icon style="margin-right: 6px" size="12" color="error"
            >fas fa-exclamation-triangle</v-icon
          >
        </div>
        <div class="connector-errors-txt" v-if="promoCurrBudgetError(item)">
          <span>Budget exceeded</span>
          <v-icon style="margin-right: 6px" size="12" color="error"
            >fas fa-exclamation-triangle</v-icon
          >
        </div>
        <div class="connector-errors-txt" v-if="promoCurrLimitError(item)">
          <span>Limit exceeded</span>
          <v-icon style="margin-right: 6px" size="12" color="error"
            >fas fa-exclamation-triangle</v-icon
          >
        </div>
        <RfPromoPublishStatus :item="item" />
      </div>
    </div>
    <v-dialog
      v-model="showPromotionInfoDialog"
      width="580"
      content-class="show-promo-details-actions"
      persistent
      v-if="promotionView"
    >
      <v-card flat>
        <v-card-title class="headline">Prompt's Name and Description</v-card-title>
        <v-card-text>
          <RfInfo :model="item" ref="systemInfo" />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed class="cancel-btn" outlined width="100px" @click="closeDialog"
            >Close</v-btn
          >
          <v-btn
            large
            @click="submitConfiguration"
            width="200px"
            color="primary"
            depressed
            :disabled="editDisabled"
          >
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RfInfo from "@/components/RfCommonCards/RfInfo.vue";
import RfPromoPublishStatus from "@/components/RfPromotions/RfPromoPublishStatus.vue";
import PromoStatusMixin from "@/utils/PromoStatusMixin";
import RoleMixin from "@/utils/RoleMixin";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfPromoNameInfo",
  setup: () => ({ toastsStore: useToastsStore() }),
  components: { RfPromoPublishStatus, RfInfo, RfButton },
  props: [
    "item",
    "promotionView",
    "editDisabled",
    "pathGroupDisplay",
    "showPromoName",
    "showPromoError",
  ],
  mixins: [PromoStatusMixin, RoleMixin],
  data() {
    return {
      showPromotionInfoDialog: false,
    };
  },
  computed: {
    sequenceLink() {
      return { path: `/apps/${this.$route.params.aid}/experiences/${this.item.sequence_id}` };
    },
    pipelineLink() {
      return { path: `/apps/${this.$route.params.aid}/pipelines/${this.item.pipeline_id}` };
    },
    assignClassName() {
      if (this.showPromoName && !this.showPromoError) {
        return "list-promo-row-one with-promo-title";
      }
      if (!this.showPromoName && this.showPromoError) {
        return "list-promo-row-one error-msg-only";
      }
      if (this.showPromoName && this.showPromoError) {
        return "list-promo-row-one all-msgs";
      }
      return "";
    },
  },
  methods: {
    showPromoDetailDialog() {
      this.showPromotionInfoDialog = true;
    },
    closeDialog() {
      this.showPromotionInfoDialog = false;
    },
    async submitConfiguration() {
      try {
        const newPath = cloneDeep(this.item);
        const appId = this.$route.params.aid;
        const pathId = this.$route.params.pid;
        if (this.$refs.systemInfo) {
          this.$refs.systemInfo.fillModel(newPath);
        }
        if (!this.pathGroupDisplay) {
          await this.updatePath({ appId, pathId, modelPath: newPath });
        } else {
          await this.updatePathGroup({ appId, model: newPath });
        }
        this.showPromotionInfoDialog = false;
        this.toastsStore.create({ type: "success", body: "Prompt updated successfully" });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    ...mapActions(["updatePath", "updatePathGroup"]),
  },
};
</script>
