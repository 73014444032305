<template>
  <div>
    <v-dialog persistent v-model="segmentCreateDialog" width="640" overlay-opacity="0.85">
      <v-card flat>
        <v-card-title class="headline">Create new segment</v-card-title>
        <v-card-text class="create-new-promo-dialog">
          <RfInfo :model="modelInfo" ref="systemInfo" />
          <v-checkbox
            v-if="currApp?.flags?.scheduled_push"
            v-model="modelInfo.segment_type"
            true-value="pinpoint"
            false-value="custom"
            label="Push notification type"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            large
            depressed
            class="cancel-btn"
            outlined
            width="100px"
            @click="$emit('closeCreateDialog')"
            >Close</v-btn
          >
          <v-btn
            data-cy="create-segment--submit"
            large
            depressed
            color="success"
            style="min-width: 200px"
            @click="submitCreate"
            ><v-icon left>save</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RfModalLoading :showDialog="loading" />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import RfInfo from "@/components/RfCommonCards/RfInfo.vue";
import RfModalLoading from "@/views/RfModalLoading.vue";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfSegmentCreateModal",
  setup: () => ({ toastsStore: useToastsStore() }),
  components: { RfInfo, RfModalLoading },
  props: ["segmentCreateDialog"],
  data: () => ({ modelInfo: { segment_type: "custom" }, loading: false }),
  computed: mapState({ currApp: state => state.apps.currApp }),
  methods: {
    async submitCreate() {
      this.loading = true;
      const newSegment = { ...this.modelInfo };
      this.$refs.systemInfo.fillModel(newSegment);
      this.$emit("closeCreateDialog");
      this.modelInfo = { segment_type: "custom" };
      await this.createSegment({
        appId: this.$route.params.aid,
        modelSegment: newSegment,
      })
        .then(segment => {
          this.toastsStore.create({ type: "success", body: "Segment created successfully" });
          this.$router.push({ path: `/apps/${this.currApp.id}/segments/${segment.id}` });
        })
        .catch(() => null)
        .finally(() => (this.loading = false));
    },
    ...mapActions(["createSegment"]),
  },
};
</script>
