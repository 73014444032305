import Vue from "vue";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

import Vuetify, {
  VAlert,
  VApp,
  VAutocomplete,
  VBreadcrumbs,
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCheckbox,
  VChip,
  VChipGroup,
  VCol,
  VColorPicker,
  VContainer,
  VDatePicker,
  VDialog,
  VDivider,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VFlex,
  VForm,
  VHover,
  VIcon,
  VLayout,
  VList,
  VListItem,
  VListItemAvatar,
  VListItemContent,
  VListItemAction,
  VListItemSubtitle,
  VListItemTitle,
  VMenu,
  VOverlay,
  VProgressCircular,
  VProgressLinear,
  VRadio,
  VRadioGroup,
  VRangeSlider,
  VRow,
  VSelect,
  VSimpleTable,
  VSpacer,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VSwitch,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTabsSlider,
  VTextarea,
  VTextField,
  VToolbar,
  VToolbarTitle,
  VTooltip,
} from "vuetify/lib";

Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAutocomplete,
    VBreadcrumbs,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VCheckbox,
    VChip,
    VChipGroup,
    VCol,
    VColorPicker,
    VContainer,
    VDatePicker,
    VDialog,
    VDivider,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VFlex,
    VForm,
    VHover,
    VIcon,
    VLayout,
    VList,
    VListItem,
    VListItemAvatar,
    VListItemContent,
    VListItemAction,
    VListItemSubtitle,
    VListItemTitle,
    VMenu,
    VOverlay,
    VProgressCircular,
    VProgressLinear,
    VRadio,
    VRadioGroup,
    VRangeSlider,
    VRow,
    VSelect,
    VSimpleTable,
    VSpacer,
    VStepper,
    VStepperContent,
    VStepperHeader,
    VStepperItems,
    VStepperStep,
    VSwitch,
    VTab,
    VTabItem,
    VTabs,
    VTabsItems,
    VTabsSlider,
    VTextarea,
    VTextField,
    VToolbar,
    VToolbarTitle,
    VTooltip,
  },
});

const opts = {
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#3096ED",
        secondary: colors.grey.darken4, // #212121
        accent: "#1565c0",
        error: "#eb5660",
        warning: "#fc8c09",
        info: "#607d8b",
        success: "#4caf50",
      },
    },
  },
};

export default new Vuetify(opts);
