<template>
  <div :class="['promo-integration-modal-wrapper', `tabs-content-editing-${isEditing}`]">
    <RfRetentionConnectorTabs
      v-model="currentTab"
      :thirdPartyOptions="thirdPartyOptions"
      v-if="!isEditing && selectedActionTab === 'connectors'"
    />
    <RfRetentionConnectorWebsiteActions
      v-if="currentTab === 'WebsiteActions' || editIntegration.provider === 'WebsiteActions'"
      :model="model"
      :isEditing="isEditing"
      :actions="actions"
      :key="interactionType"
      :interactionType="interactionType"
      :surveyOptionSelected="surveyOptionSelected"
      :editIntegration="editIntegration"
      :editIntegrationValue="editIntegrationValue"
      :clientActions="clientActions"
      :newBehaviour="newBehaviour"
      :actionGroupClientActions="actionGroupClientActions"
      @closeConnectorDialog="closeConnectorDialog"
      @selectedWAChanged="selectedWAChanged"
      @setActionGroupClientActions="setActionGroupClientActions"
    />
    <RfRetentionConnectorThirdParties
      v-else
      :model="model"
      :thirdPartyOptions="thirdPartyOptions"
      :editIntegration="editIntegration"
      :editIntegrationValue="editIntegrationValue"
      :isEditing="isEditing"
      :currentTab="currentTab"
      @addIntegration="addIntegration"
      @closeConnectorDialog="closeConnectorDialog"
    />
  </div>
</template>

<script>
import RfRetentionConnectorTabs from "@/components/RfPathCreate/RfRetentionActions/RfRetentionConnectorTabs.vue";
import RfRetentionConnectorThirdParties from "@/components/RfPathCreate/RfRetentionActions/RfRetentionConnectorThirdParties.vue";
import RfRetentionConnectorWebsiteActions from "@/components/RfPathCreate/RfRetentionActions/RfRetentionConnectorWebsiteActions.vue";
import ConnectorsMixin from "@/utils/ConnectorsMixin";
import { cloneDeep } from "lodash-es";
import { mapState } from "vuex";

export default {
  name: "RfRetentionConnectors",
  components: {
    RfRetentionConnectorTabs,
    RfRetentionConnectorThirdParties,
    RfRetentionConnectorWebsiteActions,
  },
  props: {
    model: Object,
    thirdParty: Object,
    editIntegration: Object,
    editIntegrationValue: Object,
    isEditing: Boolean,
    actions: Object,
    interactionType: String,
    surveyOptionSelected: String,
    clientActions: Array,
    selectedActionTab: String,
    actionGroupClientActions: Array,
    newBehaviour: { type: Boolean, default: false },
  },
  mixins: [ConnectorsMixin],
  data() {
    return {
      currentTab: this.getCurrentTab(),
    };
  },
  methods: {
    addIntegration(value, integration) {
      this.$emit("addIntegration", value, integration);
    },
    closeConnectorDialog() {
      this.$emit("closeConnectorDialog");
    },
    setActionGroupClientActions(value) {
      this.$emit("setActionGroupClientActions", value);
    },
    selectedWAChanged(value) {
      this.$emit("selectedWAChanged", value);
    },
    getCurrentTab() {
      return this.selectedActionTab === "connectors"
        ? this.editIntegration.provider || "redfast"
        : "WebsiteActions";
    },
  },
  computed: {
    ...mapState({
      app: state => cloneDeep(state.apps.currApp),
    }),
  },
  watch: {
    selectedActionTab() {
      this.currentTab = this.getCurrentTab();
    },
  },
};
</script>
