<template>
  <v-card flat>
    <v-card-title class="headline">{{ testerLabel }}</v-card-title>
    <v-card-text>
      <div style="padding: 0.5rem 0; color: #333">Your {{ testType }} Expression:</div>
      <div style="background: #f4f9fd; padding: 0.5rem; margin-bottom: 1.25rem">
        {{ expression }}
      </div>
      <v-radio-group hide-details v-model="selectedExpression" row>
        <v-radio
          v-for="(settings, expressionType) in expressionTypes"
          :key="expressionType"
          :value="expressionType"
          :label="settings.label"
        />
      </v-radio-group>
      <v-text-field
        outlined
        dense
        :label="`Your Test ${expressionTypes[selectedExpression].label}`"
        :placeholder="expressionTypes[selectedExpression].label"
        v-model.trim="testString"
        style="margin-top: 16px"
      />
      <div style="height: 24px" class="msg-note">
        <div v-if="matchResult">
          <v-icon color="success" left>fas fa-check-circle</v-icon>
          <span class="success-msg-note">Success</span>
        </div>
        <div v-else>
          <v-icon color="warning" left>warning</v-icon>
          <span class="warning-msg-note">No Match</span>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="modal-card-actions-div">
      <v-btn depressed color="#dddddd" width="100px" @click="$emit('close')">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "RfRegexTester",
  props: ["testType", "urlPath", "useRegex", "queryParams", "urlHash"],
  data() {
    return {
      testString: "",
      selectedExpression: "urlPath",
      expressionTypes: {
        urlPath: { label: "Url Path", placeholder: "/settings" },
        queryParams: { label: "Query Parameters", placeholder: "version=2020" },
        urlHash: { label: "Url Hash", placeholder: "#profile" },
      },
    };
  },
  computed: {
    expression() {
      return this[this.selectedExpression];
    },
    testerLabel() {
      return `Test your ${this.testType} expression`;
    },
    matchResult() {
      let path = this.expression;
      if (!path) return null;
      try {
        if (!this.useRegex) {
          path = path.replace(/\*/g, ".*");
          path = `^${path}$`;
        }
        const regexp = new RegExp(path, "i");
        return regexp.exec(this.testString);
      } catch (e) {
        console.log(e);
      }
      return null;
    },
  },
};
</script>
