<template>
  <div class="segment-device-all" v-if="segment">
    <div class="segment-device-single sgmnt-inner-option">
      <v-select
        v-model="segment.device_family"
        :items="deviceType"
        item-text="name"
        item-value="value"
        chips
        label="Choose Device Type"
        hint="Choose the type of device to limit this segment to. Ex. Tablet."
        persistent-hint
        multiple
        outlined
        dense
        placeholder="All"
        background-color="#fff"
      ></v-select>
    </div>
    <div class="segment-device-single sgmnt-inner-option">
      <v-select
        v-model="segment.os_family"
        :items="deviceOs"
        item-text="name"
        item-value="value"
        chips
        label="Choose Device OS"
        hint="Choose the type of OS to limit this segment to. Avoid conflicts with device type selection."
        persistent-hint
        multiple
        outlined
        dense
        placeholder="All"
        background-color="#fff"
      ></v-select>
    </div>
    <div class="segment-device-single sgmnt-inner-option">
      <v-select
        v-model="segment.device_target"
        :items="devices"
        item-text="name"
        item-value="value"
        chips
        label="Choose Device Target"
        hint="Choose the device target to limit this segment to. Ex. Roku 4k."
        persistent-hint
        multiple
        outlined
        dense
        placeholder="All"
        background-color="#fff"
      ></v-select>
    </div>
    <div class="segment-device-single sgmnt-inner-option">
      <v-select
        v-model="segment.browser_family"
        :items="browsers"
        item-text="name"
        item-value="value"
        chips
        label="Browsers"
        hint="Choose browsers to limit this segment to."
        persistent-hint
        multiple
        outlined
        dense
        placeholder="All"
        background-color="#fff"
      ></v-select>
    </div>
  </div>
</template>

<script>
import ObjectUtils from "@/utils/ObjectUtils";
import { Browsers, DeviceOSes, DeviceTypes, Devices } from "@/utils/constants/DevicesConstants";

export default {
  name: "RfDevice",
  props: ["modelSegment", "app"],
  setup: () => ({
    devices: Devices,
    deviceType: Object.values(DeviceTypes),
    deviceOs: Object.values(DeviceOSes),
    browsers: Object.values(Browsers),
  }),
  data() {
    return {
      segment: this.initModel(this.modelSegment),
    };
  },
  methods: {
    initModel(modelSegment) {
      const data = {
        device_family: [],
        os_family: [],
        browser_family: [],
        device_target: [],
      };
      if (modelSegment && modelSegment.filter && modelSegment.filter.device) {
        ObjectUtils.mergeObjectByKeys(data, modelSegment.filter.device, Object.keys(data));
      }
      return data;
    },
    fillModelSegment(modelSegment) {
      ObjectUtils.mergeObjectByKeys(
        modelSegment.filter.device,
        this.segment,
        Object.keys(this.segment),
      );
    },
  },
  computed: {
    MockVersions() {
      return [
        { name: "1.0.1", value: "1.0.1" },
        { name: "1.1", value: "1.1" },
        { name: "1.2.0", value: "1.2.0" },
        { name: "1.2.1", value: "1.2.1" },
        { name: "1.3.0", value: "1.3.0" },
        { name: "1.4.0", value: "1.4.0" },
        { name: "2.2.2", value: "2.2.2" },
      ];
    },
    enableLocation() {
      if (this.app) {
        return this.app.flags.geo_lookup;
      }
      return true;
    },
  },
};
</script>

<style scoped></style>
