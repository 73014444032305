<template>
  <div class="restriction-cbox-div-row">
    <div class="restriction-cbox-text">
      <div v-if="title === 'continue'">
        <v-icon left> {{ isLastPath ? "refresh" : "arrow_downward" }} </v-icon>
        <h4>{{ isLastPath ? "Loops" : "Continues" }} when</h4>
      </div>
      <div v-else>
        <v-icon left>exit_to_app</v-icon>
        <h4>Exits when</h4>
      </div>
    </div>
    <div class="restriction-cbox-div">
      <div class="restriction-cbox-row">
        <v-checkbox
          hide-details
          v-model="interactions"
          value="seen"
          label="Seen"
          :disabled="isDisabled('seen')"
          v-if="!webClient"
        />
      </div>
      <div class="restriction-cbox-row">
        <v-checkbox
          hide-details
          v-model="interactions"
          value="accepted"
          :label="getButtonTitle(path, 'rf_retention_button1_text') || 'Accepted (1st)'"
          class="restriction-cbox--accepted"
          :disabled="isDisabled('accepted')"
        />
        <v-checkbox
          hide-details
          v-model="interactions"
          value="accepted2"
          :label="getButtonTitle(path, 'rf_retention_button2_text') || 'Accepted (2nd)'"
          class="restriction-cbox--accepted2"
          :disabled="isDisabled('accepted2')"
          v-if="accept2Enabled"
        />
        <v-checkbox
          hide-details
          v-model="interactions"
          value="custom_goal"
          :label="customGoalName"
          v-if="hasCustomGoal"
          :disabled="isDisabled('custom_goal')"
        />
      </div>
      <div class="restriction-cbox-row">
        <v-checkbox
          hide-details
          v-model="interactions"
          value="declined"
          :label="getButtonTitle(path, 'rf_retention_button3_text') || 'Declined'"
          class="restriction-cbox--decline"
          :disabled="isDisabled('declined')"
          v-if="declineEnabled"
        />
        <v-checkbox
          hide-details
          v-model="interactions"
          value="dismissed"
          label="Dismissed"
          :disabled="isDisabled('dismissed')"
          v-if="dismissEnabled"
        />
        <v-checkbox
          hide-details
          v-model="interactions"
          value="timeout"
          label="Timeout"
          :disabled="isDisabled('timeout')"
          v-if="timeoutEnabled && webClient"
        />
      </div>
      <div
        v-if="title === 'continue' && !webClient"
        style="display: flex; padding-top: 16px; border-top: 1px solid #ddd"
      >
        <v-select
          v-model="intervalType"
          :items="intervalTypeItems"
          outlined
          dense
          hide-details
          style="margin-right: 9%; max-width: 33%"
        />
        <v-text-field
          outlined
          dense
          hide-details
          type="number"
          v-model="continueInterval"
          v-if="intervalType === 'time'"
          style="margin-right: 9%; max-width: 15%"
        />
        <v-select
          outlined
          dense
          hide-details
          v-model="intervalUnit"
          :items="unitItems"
          v-if="intervalType === 'time'"
          style="max-width: 33%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getButtonTitle, isAccept2Enabled } from "@/utils/prompts/promptHelpers";

export default {
  name: "RfSequenceAddPathConditions",
  props: [
    "title",
    "editInteractions",
    "disallowedInteractions",
    "path",
    "interval",
    "isLastPath",
    "webClient",
  ],
  setup: () => ({ getButtonTitle }),
  data() {
    return {
      interactions: this.editInteractions,
      intervalType: null,
      intervalTypeItems: [
        { text: "Immediately", value: "immediate" },
        { text: "On Next Visit", value: "visit" },
        { text: "Amount of Time", value: "time" },
      ],
      continueInterval: null,
      intervalUnit: null,
      unitItems: [
        { text: "Days", value: "days" },
        { text: "Hours", value: "hours" },
        { text: "Minutes", value: "minutes" },
      ],
    };
  },
  watch: {
    interactions(to) {
      this.$emit("setInteractions", to);
    },
    newInterval(to) {
      this.$emit("setInterval", to);
    },
    intervalType(to) {
      if (to === "visit") {
        this.continueInterval = "VISIT";
      } else {
        this.intervalUnit = this.intervalUnit || "minutes";
        if (this.continueInterval === "VISIT") {
          this.continueInterval = 0;
        }
      }
    },
  },
  methods: {
    isDisabled(interaction) {
      return this.disallowedInteractions.indexOf(interaction) >= 0;
    },
  },
  computed: {
    accept2Enabled() {
      return isAccept2Enabled(this.path);
    },
    declineEnabled() {
      return this.path.actions.rf_settings_cancel_button_enabled === "true";
    },
    dismissEnabled() {
      return (
        this.path.actions.rf_settings_close_button_enabled === "true" ||
        this.path.actions.rf_settings_click_outside_close_enabled === "true"
      );
    },
    timeoutEnabled() {
      return !!this.path.actions.rf_settings_close_seconds;
    },
    hasCustomGoal() {
      if (this.webClient) return false;
      return Object.keys(this.path.custom_filter).length > 0;
    },
    customGoalName() {
      if (this.hasCustomGoal) {
        const goalName = Object.keys(this.path.custom_filter)[0];
        return `Custom Goal: ${goalName}`;
      }
    },
    newInterval() {
      if (this.intervalType === "visit") {
        return "VISIT";
      }
      if (this.intervalType === "immedidate") {
        return 0;
      }
      if (this.intervalUnit === "days") {
        return parseInt(this.continueInterval) * 1440;
      }
      if (this.intervalUnit === "hours") {
        return parseInt(this.continueInterval) * 60;
      }
      return parseInt(this.continueInterval);
    },
  },
  mounted() {
    if (this.title === "continue") {
      if (this.interval === "VISIT") {
        this.intervalType = "visit";
      } else if (this.interval == 0 || !this.interval) {
        this.intervalType = "immediate";
      } else {
        this.intervalType = "time";
        if (this.interval % 1440 === 0) {
          this.intervalUnit = "days";
          this.continueInterval = this.interval / 1440;
        } else if (this.interval % 60 === 0) {
          this.intervalUnit = "hours";
          this.continueInterval = this.interval / 60;
        } else {
          this.intervalUnit = "minutes";
          this.continueInterval = this.interval;
        }
      }
    }
  },
};
</script>
