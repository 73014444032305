<template>
  <v-select
    hide-details
    outlined
    :label="label"
    :dense="dense"
    :disabled="disabled"
    background-color="#ffffff"
    v-model="time"
    @change="changeHandler"
    name="time"
    :items="items"
  />
</template>

<script>
import StringUtils from "@/utils/StringUtils";

export default {
  name: "RfTimePicker",
  data() {
    return {
      time: 0,
      items: Array.from(Array(48).keys())
        .map(index => {
          return {
            text: this.formatHour(index),
            value: index,
          };
        })
        .concat([{ text: "23:59", value: 48 }]),
    };
  },
  props: ["label", "error", "dense", "updateTime", "initTime", "disabled"],
  created() {
    this.time = this.initTime;
  },
  methods: {
    formatHour(index) {
      return StringUtils.hhmmBy30MinIntervalIndex(index);
    },
    changeHandler(_event) {
      this.$emit("updateTime", this.time);
    },
  },
};
</script>
