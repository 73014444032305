import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiOrgs {
  static getOrgs(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/orgs`);
  }

  static getOrg(appId, orgId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/orgs/${orgId}`);
  }

  static createOrg(appId, modelOrg) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/orgs`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ org: modelOrg }),
    });
  }

  static updateOrg(appId, org) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/orgs/${org.id}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ org }),
    });
  }

  static deleteOrg(appId, orgId, newOrgId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/orgs/${orgId}?new_org_id=${newOrgId}`, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });
  }
}
