<template>
  <div class="text-body inline-flex items-center gap-1 !text-black-2">
    <template v-if="display"> Show every page load </template>
    <template v-else-if="interval === IntervalConstants.INF.value">
      {{ `Don't show prompt again after accept` }}
    </template>
    <template v-else>
      {{ `Show prompt again after accept` }}
      <span class="font-bold">
        <template v-if="interval === IntervalConstants.VISIT.value">
          {{ IntervalConstants[interval].text.toLowerCase() }}
        </template>
        <template v-else> in {{ getInterval(interval) }}</template>
      </span>
    </template>
  </div>
</template>

<script setup>
defineProps({
  display: { type: Boolean, default: false },
  interval: { type: String, default: "" },
});

const IntervalConstants = {
  INF: { text: "Never", value: "INF" },
  VISIT: { text: "On next visit", value: "VISIT" },
  TIME: { text: "Amount of time", value: "TIME" },
};

const getInterval = interval => {
  const time = parseInt(interval);
  if (time) {
    if (time % 1440 === 0) return `${time / 1440} days`;
    if (time % 60 === 0) return `${time / 60} hours`;
  }
  return `${time || 0} minutes`;
};
</script>
