<template>
  <RfBaseModal ref="awsLambdaModal" title="AWS S3 Credentials" width="700" persistent>
    <template #body>
      <div class="input-wrapper">
        <RfTextInput
          dense
          readonly
          outlined
          title="AWS Bucket ID"
          placeholder="AWS Bucket ID"
          :value="props.awsUserName"
        />
        <RfTextInput
          dense
          readonly
          outlined
          title="Upload Location"
          placeholder="Upload Location"
          :value="props.awsPath"
        />
        <RfTextInput
          dense
          readonly
          outlined
          title="Access Key"
          placeholder="Access Key"
          :value="props.awsAccessKey"
        />
        <RfTextInput
          hide-details
          dense
          readonly
          outlined
          title="Secret Key"
          placeholder="Secret Key"
          :value="props.awsSecretKey"
        />
      </div>
      <v-checkbox
        v-model="showCredentials"
        class="rf-credentials mt-0"
        label="Show Credentials"
      ></v-checkbox>
    </template>
  </RfBaseModal>
</template>

<script setup>
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import { computed, ref } from "vue";

const emit = defineEmits(["update:showCredentials"]);
const props = defineProps({
  awsUserName: { type: String, default: "" },
  awsPath: { type: String, default: "" },
  awsAccessKey: { type: String, default: "" },
  awsSecretKey: { type: String, default: "" },
});

const awsLambdaModal = ref();
const showCredentialsValue = ref(false);

const showCredentials = computed({
  get: () => showCredentialsValue.value,
  set: v => {
    showCredentialsValue.value = v;
    emit("update:showCredentials", v);
  },
});

const show = () => awsLambdaModal.value?.show();
const close = () => awsLambdaModal.value?.close();

defineExpose({ show, close });
</script>
