import SegmentListMixin from "@/utils/SegmentListMixin";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import { mapState } from "vuex";

export default {
  mixins: [SegmentListMixin, UserSettingsMixin],
  props: ["resetFilters"],
  data() {
    return {
      filterPlacementsSegment: [],
      placementPathType: [],
      promoPublishStatusList: ["Pending", "Scheduled", "Running", "Paused", "Ended", "Limited"],
      promoPublishStatus: [],
      deviceTypeFilter: [],
      groupFilter: null,
      groupOptionsList: [
        { text: "All", value: "ungrouped" },
        { text: "Zone", value: "grouped" }, // grouped is legacy for zone
        { text: "Guide", value: "sequence" },
        { text: "Pipeline", value: "pipeline" },
      ],
      showAllSegments: true,
      expirationStatusList: [
        { text: "Expiring Soon", value: "expires_soon" },
        { text: "Expired Recently", value: "expired_recently" },
      ],
      promoState: [],
    };
  },
  computed: {
    ...mapState({
      currApp: state => state.apps.currApp,
    }),
    pathTypes() {
      const types = [
        { text: "Horizontal", value: "horizontal" },
        { text: "Vertical", value: "vertical" },
        { text: "Tile", value: "tile" },
        { text: "Text", value: "text" },
        { text: "Invisible", value: "invisible" },
        { text: "Interstitial", value: "interstitial" },
        { text: "Pop Up", value: "retention_modal" },
        { text: "Video", value: "video" },
        { text: "Notification", value: "widget" },
        { text: "Bottom Banner", value: "bottom_banner" },
      ];
      if (this.currApp && this.currApp.flags.email_path_enabled) {
        types.push({ text: "Email", value: "email" });
      }
      return types;
    },
    promoStateList() {
      return this.promoPublishStatusList
        .map(item => ({ text: item, value: item }))
        .concat(this.expirationStatusList);
    },
    expirationStatus() {
      return this.promoState.filter(
        state => this.expirationStatusList.map(el => el.value).indexOf(state) > -1,
      );
    },
    promoPublishStatusV2() {
      // should behave as `promoPublishStatus` but is a subset of `promoState`
      return this.promoState.filter(state => this.promoPublishStatusList.indexOf(state) > -1);
    },
  },
  watch: {
    placementPathType(to, from) {
      if (to !== from) {
        this.setUserSetting("placementsPathTypeFilter", to);
      }
      this.$emit("setPathTypes", to);
    },
    filterPlacementsSegment(to, from) {
      if (to !== from) {
        this.setUserSetting("placementsSegmentFilter", to);
      }
      this.$emit("setSegmentIds", to);
    },
    promoPublishStatus(to, from) {
      if (to !== from) {
        this.setUserSetting("placementsStatusFilter", to);
      }
      this.$emit("setStatuses", to);
    },
    deviceTypeFilter(to, from) {
      if (to !== from) {
        this.setUserSetting("placementsDeviceFilter", to);
      }
      this.$emit("setDeviceTypes", to);
    },
    groupFilter(to, from) {
      if (to !== from) {
        this.setUserSetting("placementsGroupFilter", to);
      }
      this.$emit("groupFilterUpdate", to);
    },
    expirationStatus(to) {

      this.$emit("setExpirationStatus", to);
    },
    promoPublishStatusV2(to) {
      // triggers the same event as the old version
      this.$emit("setStatuses", to);
    },
    promoState(to, from) {

      if (to !== from) {
        this.setUserSetting("placementsStateFilter", to);
      }
      // no change emitters, each subfilter triggers its change method individually
    },
    async resetFilters() {
      await this.setUserSettings({
        placementsDeviceFilter: [],
        placementsSegmentFilter: [],
        placementsPathTypeFilter: [],
        placementsStatusFilter: [],
        placementsGroupFilter: "ungrouped",
        placementsStateFilter: [],
      });
      this.filterPlacementsSegment = [];
      this.placementPathType = [];
      this.promoPublishStatus = [];
      this.deviceTypeFilter = [];
      this.groupFilter = "ungrouped";
      this.promoState = [];
    },
  },
  mounted() {
    this.filterPlacementsSegment = this.getUserSetting("placementsSegmentFilter") || [];
    this.placementPathType = this.getUserSetting("placementsPathTypeFilter") || [];
    this.promoPublishStatus = this.getUserSetting("placementsStatusFilter") || [
      "Running",
      "Pending",
      "Paused",
    ];
    this.deviceTypeFilter = this.getUserSetting("placementsDeviceFilter") || [];
    this.groupFilter = this.getUserSetting("placementsGroupFilter") || "ungrouped";
    this.promoState = this.getUserSetting("placementsStateFilter") || [];
  },
};
