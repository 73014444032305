<template>
  <v-overlay z-index="500" :value="showDialog">
    <v-progress-circular color="#ffffff" indeterminate size="80" width="5"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "RfModalLoading",
  props: {
    showDialog: Boolean,
  },
};
</script>
