<template>
  <div class="flex gap-2">
    <component v-bind="action" class="!h-6 !w-6 rounded-full" />
    <div class="flex flex-col gap-1">
      <span class="text-action-buttons !text-black-2">
        {{ objectType }}
        <component
          :is="objectLink ? RouterLink : 'span'"
          :to="objectLink"
          class="text-heading-2 !text-blue-1"
        >
          {{ object_name }}
        </component>
        has been {{ action.title }} by {{ name }}.
      </span>
      <span class="text-body !text-grey-2">
        {{ dayjs(time).format("MMM Do YYYY, h:mm a") }}
      </span>
    </div>
  </div>
</template>

<script setup>
import RfActionError from "@/components/statuses/RfActionError.vue";
import RfActionInfo from "@/components/statuses/RfActionInfo.vue";
import RfActionSuccess from "@/components/statuses/RfActionSuccess.vue";
import {
  getPromptLink,
  getZoneLink,
  getGuideLink,
  getSegmentLink,
  getPipelineLink,
} from "@/utils/getLink";
import dayjs from "dayjs";
import { computed } from "vue";
import { RouterLink } from "vue-router";

const props = defineProps({
  action: { type: String, default: "update" },
  object_name: { type: String, default: "Unnamed" },
  object_id: { type: String, default: "" },
  appId: { type: String, default: "" },
  object_type: { type: String, default: "" },
  name: { type: String, default: "N/A" },
  updated_fields: { type: Array, default: () => [] },
  time: { type: String, default: new Date() },
});

const action = computed(() => {
  if (props.action === "create") return { is: RfActionSuccess, title: "created" };
  if (props.action === "destroy") return { is: RfActionError, title: "deleted" };
  return { is: RfActionInfo, title: "updated" };
});

const objectType = computed(() => {
  if (props.object_type === "Path") return "Prompt";
  if (props.object_type === "PathGroup") return "Zone";
  if (props.object_type === "Sequence") return "Guide";
  if (props.object_type === "Segment") return "Segment";
  if (props.object_type === "Pipeline") return "Pipeline";
  if (props.object_type === "App") return "App";
  return "";
});

const objectLink = computed(() => {
  try {
    if (props.object_type === "Path") return getPromptLink(props.object_id, props.appId);
    if (props.object_type === "PathGroup") return getZoneLink(props.object_id, props.appId);
    if (props.object_type === "Sequence") return getGuideLink(props.object_id, props.appId);
    if (props.object_type === "Segment") return getSegmentLink(props.object_id, props.appId);
    if (props.object_type === "Pipeline") return getPipelineLink(props.object_id, props.appId);
    if (props.object_type === "App") return getAppLink(props.object_id);
  } catch (error) {}
  return "";
});
</script>
