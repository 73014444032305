<template>
  <div class="promo-design-group">
    <v-checkbox
      v-model="actions.rf_retention_countdown_enabled"
      value="true"
      label="Enable countdown timer"
      hide-details
    />
    <div class="mt-4 flex flex-col gap-4">
      <div class="promo-fonts-size-input">
        <v-menu
          v-model="menuEndDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          :disabled="disabled"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              append-icon="event"
              readonly
              v-on="on"
              :disabled="disabled"
              outlined
              dense
              :value="dayjs(endDate).format('MMM Do, YYYY')"
              label="End on"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            v-model="endDate"
            @input="menuEndDate = false"
            :min="dayjs().format('YYYY-MM-DD')"
          ></v-date-picker>
        </v-menu>
        <RfTimePicker
          @updateTime="time => (endTime = StringUtils.hhmmBy30MinIntervalIndex(time))"
          ref="endTimePicker"
          :initTime="endTime"
          :disabled="disabled"
          dense
        />
      </div>

      <v-text-field
        outlined
        dense
        label="Caption"
        v-model.trim="
          currentTranslations.hasOwnProperty('rf_retention_countdown_start_caption')
            ? previewActions.rf_retention_countdown_start_caption
            : actions.rf_retention_countdown_start_caption
        "
        maxlength="50"
        counter="50"
        :disabled="currentTranslations.hasOwnProperty('rf_retention_countdown_start_caption')"
        placeholder="Offer ends in"
      ></v-text-field>
      <div class="promo-window-size-inputs -mt-4">
        <v-select
          :items="[
            { value: 'small', text: 'Small' },
            { value: 'medium', text: 'Medium' },
            { value: 'large', text: 'Large' },
          ]"
          label="Size"
          v-model="actions.rf_retention_countdown_size"
          outlined
          required
          dense
          hide-details
        ></v-select>
        <v-select
          :items="[
            { value: 'top', text: 'Top' },
            { value: 'bottom', text: 'Bottom' },
          ]"
          label="Position"
          v-model="actions.rf_retention_countdown_position"
          outlined
          required
          dense
          hide-details
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import RfTimePicker from "@/components/RfTimePicker.vue";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import StringUtils from "@/utils/StringUtils";
import dayjs from "dayjs";
import { ref } from "vue";

export default {
  name: "RfRetentionActionsMetadata",
  props: ["app", "actions", "model", "disabled"],
  mixins: [LanguagePreviewMixin],
  components: { RfTimePicker },
  setup: () => ({ dayjs, StringUtils, endTimePicker: ref() }),
  data() {
    return {
      menuEndDate: false,
      endDate: null,
      endTime: null,
    };
  },
  computed: {
    endDateTime() {
      if (!this.endDate || !this.endTime) return null;
      return dayjs(`${this.endDate} ${this.endTime}`, "YYYY-MM-DD hh:mm").toISOString();
    },
  },
  methods: {
    roundToNearestMinutes(time, roundValue = 30) {
      const mod = time.minute() % roundValue;
      const timeCopy =
        mod < roundValue / 2
          ? time.subtract(mod, "minutes")
          : time.add(roundValue - mod, "minutes");
      return timeCopy.startOf("minute");
    },
  },
  mounted() {
    const now = this.roundToNearestMinutes(dayjs());
    if (!this.actions.rf_retention_countdown_end_datetime) {
      const future = now.add(48, "hours");
      this.endDate = future.format("YYYY-MM-DD");
      this.endTime = future.format("hh:mm");
    } else {
      const date = dayjs(this.actions.rf_retention_countdown_end_datetime);
      this.endDate = date.format("YYYY-MM-DD");
      this.endTime = date.format("hh:mm");
    }
    this.endTimePicker.time = StringUtils.intervalIndexByHhmm(this.endTime);
  },
  watch: {
    endDateTime(v) {
      this.actions.rf_retention_countdown_end_datetime = v;
    },
  },
};
</script>

<style lang="scss" scoped></style>
