var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobileweb-promo-setting"},[_c('div',{staticClass:"promo-design-group"},[_c('div',{staticStyle:{"margin-bottom":"16px"}},[_c('v-textarea',{attrs:{"outlined":"","rows":_vm.rowsNumber,"label":"Title","disabled":_vm.currentTranslations.hasOwnProperty('rf_mobile_title'),"name":"title","hide-details":""},model:{value:(
          _vm.currentTranslations.hasOwnProperty('rf_mobile_title')
            ? _vm.previewActions.rf_mobile_title
            : _vm.actions.rf_mobile_title
        ),callback:function ($$v) {_vm.$set(_vm.currentTranslations.hasOwnProperty('rf_mobile_title')
            ? _vm.previewActions.rf_mobile_title
            : _vm.actions, "rf_mobile_title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n          currentTranslations.hasOwnProperty('rf_mobile_title')\n            ? previewActions.rf_mobile_title\n            : actions.rf_mobile_title\n        "}}),(!_vm.currentTranslations.hasOwnProperty('rf_mobile_title'))?_c('RfInsertUserTrait',{attrs:{"app":_vm.app,"actionName":"rf_mobile_title","title":"title","actions":_vm.actions}}):_vm._e()],1),_c('div',{staticClass:"div-flex-layout",staticStyle:{"margin-bottom":"22px"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","dense":"","hide-details":"","label":"Title Font Size","name":"title-font-size","required":""},model:{value:(_vm.actions.rf_settings_mobile_title_font_size),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_mobile_title_font_size", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_mobile_title_font_size"}}),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"outlined":"","dense":"","hide-details":"","label":"Message Font Size","name":"message-font-size","required":""},model:{value:(_vm.actions.rf_settings_mobile_message_font_size),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_mobile_message_font_size", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_mobile_message_font_size"}})],1),_c('div',{staticStyle:{"margin-bottom":"16px"}},[_c('v-textarea',{attrs:{"outlined":"","rows":_vm.rowsNumberMsg,"label":"Message","disabled":_vm.currentTranslations.hasOwnProperty('rf_mobile_message'),"name":"message","hide-details":""},model:{value:(
          _vm.currentTranslations.hasOwnProperty('rf_mobile_message')
            ? _vm.previewActions.rf_mobile_message
            : _vm.actions.rf_mobile_message
        ),callback:function ($$v) {_vm.$set(_vm.currentTranslations.hasOwnProperty('rf_mobile_message')
            ? _vm.previewActions.rf_mobile_message
            : _vm.actions, "rf_mobile_message", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"\n          currentTranslations.hasOwnProperty('rf_mobile_message')\n            ? previewActions.rf_mobile_message\n            : actions.rf_mobile_message\n        "}}),(!_vm.currentTranslations.hasOwnProperty('rf_mobile_message'))?_c('RfInsertUserTrait',{attrs:{"app":_vm.app,"actionName":"rf_mobile_message","title":"message","actions":_vm.actions}}):_vm._e()],1),(_vm.model.path_type !== 'widget')?_c('div',{staticClass:"div-flex-layout",staticStyle:{"margin-bottom":"22px"}},[(_vm.model.path_type !== 'text')?_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"Message Top Padding","name":"hero-unit-height"},model:{value:(_vm.actions.rf_settings_mobile_title_padding_top),callback:function ($$v) {_vm.$set(_vm.actions, "rf_settings_mobile_title_padding_top", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"actions.rf_settings_mobile_title_padding_top"}}):_vm._e()],1):_vm._e()]),(_vm.backgroundImageEnabled)?_c('div',{staticStyle:{"padding-top":"10px"}},[_c('h4',[_vm._v("Background")]),_c('div',{staticClass:"upload-bgimg-wrapper"},[_c('v-btn',{staticClass:"upload-image-btn",attrs:{"depressed":"","color":"accent","disabled":_vm.uploadDisabled},on:{"click":function($event){return _vm.triggerUploadBgImageFromInput('bgImageMobileWebInput')}}},[_c('v-icon',{attrs:{"size":"14","left":""}},[_vm._v("fas fa-upload")]),_vm._v("Upload "),(!_vm.isNewAndroidPrompt)?[_vm._v("mobile web")]:_vm._e(),_vm._v(" background ")],2),_c('input',{ref:"bgImageMobileWebInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.uploadBgImageFromInput('mobileWeb')}}}),(_vm.model.path_type === 'retention_modal')?_c('div',{staticClass:"upload-img-helper"},[(_vm.isNewAndroidPrompt)?_c('span',[_vm._v("1024 x 1024")]):_c('span',[_vm._v("500 x 800")]),_vm._v(" px recommended ")]):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }