<template>
  <div class="promo-triggers-cell-value">
    <p>Name: {{ trigger.name }}</p>
    <div v-if="trigger.click_id">
      Shown when an element is clicked {{ trigger.click_count_threshold }} time(s).<br />
      <span>Query Selector:</span>
      <b>{{ trigger.click_id }}</b>
      <br />
      <span>Page:</span>
      <b>{{ trigger.url_path }}</b
      ><br />
      <span v-if="trigger.custom_trigger_id"
        >Advanced: {{ displayCustomTrigger(trigger, customTriggers) || "None" }}</span
      >
    </div>
    <div v-else>
      Shown when page loads after {{ trigger.delay_seconds }}
      {{ pluralize("second", trigger.delay_seconds) }} <br />
      <span>Page url:</span><b>{{ displayPage(trigger) }}</b>
    </div>
    <div v-if="trigger.trigger_type === 'easy_button'">(Added via Real Time)</div>
  </div>
</template>

<script>
import StringUtils from "@/utils/StringUtils";
import { displayCustomTrigger, displayPage } from "@/utils/triggerHelpers";
import { mapState, mapActions } from "vuex";

export default {
  name: "RfTriggerDetailInfo",
  props: ["trigger"],
  setup: () => ({ pluralize: StringUtils.pluralize, displayPage, displayCustomTrigger }),
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      currApp: state => state.apps.currApp,
      clientActions: state => state.apps.clientActions || [],
    }),
    customTriggers() {
      return this.clientActions.filter(action => action.action_type === "custom_trigger");
    },
    allTriggersCount() {
      return this.triggers.length;
    },
  },
  methods: {
    ...mapActions(["getClientActions"]),
  },
  mounted() {
    if (!this.clientActions.length) this.getClientActions({ appId: this.currApp.id });
  },
};
</script>
