<template>
  <div class="awsMp-main-wrapper">
    <v-card class="awsMp-main-wrapper">
      <div class="awsMp-header">
        <div class="awsMp-logo redfast-logo">
          <img alt="Redfast Logo" src="https://assets.redfastlabs.com/images/logo/redfast.png" />
        </div>
        <div class="awsMp-logo aws-logo">
          <img
            alt="AWS Logo"
            src="https://assets.redfastlabs.com/images/logo/aws_marketplace.png"
          />
        </div>
      </div>
      <div class="awsMp-response-msg awsMp-error-msg" v-if="errorFormSubmission">{{ status }}</div>
      <div class="awsMp-response-msg awsMp-success-msg" v-if="formSubmitted">
        <span v-html="status"></span>
      </div>
      <div v-else>
        <div class="awsMp-signup-form">
          <form>
            <div class="awsMp-signup-form-input">
              <v-text-field
                outlined
                hide-details
                v-model="name"
                label="Name"
                name="name"
                autofocus
                placeholder="e.g.: Paul Smith"
                v-validate="'required'"
                required
              ></v-text-field>
              <span class="awsMp-form-error" v-if="errors.has('name')">{{
                errors.first("name")
              }}</span>
            </div>
            <div class="awsMp-signup-form-input">
              <v-text-field
                outlined
                hide-details
                v-model="email"
                label="Email address"
                name="email"
                type="email"
                placeholder="e.g: paul@company.com"
                v-validate="'required|email'"
                required
              ></v-text-field>
              <span class="awsMp-form-error" v-if="errors.has('email')">{{
                errors.first("email")
              }}</span>
            </div>
            <div class="awsMp-signup-form-input">
              <v-text-field
                outlined
                hide-details
                v-model="company"
                name="company"
                label="Company name"
                placeholder="e.g: Company Inc."
                v-validate="'required'"
                required
              ></v-text-field>
              <span class="awsMp-form-error" v-if="errors.has('company')">{{
                errors.first("company")
              }}</span>
            </div>
          </form>
        </div>
        <div class="awsMp-signup-btn">
          <v-btn x-large depressed color="success" @click="newAccount"> Setup account </v-btn>
        </div>
      </div>
    </v-card>
    <div class="need-help-div">
      Need help? <a href="mailto:info@redfast.com">info@redfast.com</a>
    </div>
  </div>
</template>

<script>
import * as Sentry from "@sentry/browser";
import { ApiBaseUrl } from "../apis/api";

export default {
  data() {
    return {
      status: null,
      name: null,
      email: null,
      company: null,
      formSubmitted: false,
      errorFormSubmission: false,
    };
  },
  methods: {
    async newAccount() {
      const validForm = await this.$validator.validateAll();
      if (!validForm) return;

      const body = {
        token: this.$route.params.token,
        name: this.name,
        email: this.email,
        company: this.company,
      };

      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };

      fetch(`${ApiBaseUrl}aws_marketplace`, params)
        .then(res => {
          if (res.ok) {
            this.formSubmitted = true;
            this.errorFormSubmission = false;
            this.status =
              "<h3>Thank you!</h3> We've received your information and will get back to you as soon as possible.";
            return;
          }
          this.errorFormSubmission = true;
          this.status = "Something went wrong.";
        })
        .catch(e => {
          Sentry.captureException(e);
        });
    },
  },
};
</script>
