import { serializeQuery } from "@/utils/UrlUtils";
import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiPipelines {
  static getPipelines(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/pipelines`);
  }

  static updatePipeline(appId, pipelineId, pipeline) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/pipelines/${pipelineId}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pipeline }),
    });
  }

  static getPipeline(appId, pipelineId, params) {
    const query = serializeQuery(params);

    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/pipelines/${pipelineId}?${query}`);
  }

  static createPipeline(appId, pipeline) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/pipelines`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pipeline }),
    });
  }

  static async deletePipeline(appId, pipelineId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/pipelines/${pipelineId}`, {
      method: "DELETE",
    }).then(_ => pipelineId);
  }

  static getPipelineModel(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/pipelines/new`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
  }

  static getPipelineStageModel(appId, pipelineId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/pipelines/${pipelineId}/new_stage`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
  }

  static restartPipeline(appId, pipelineId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/pipelines/${pipelineId}/restart`, {
      method: "PUT",
    });
  }
}
