<template>
  <div class="sgc-chips-wrap">
    <div class="sgc-chips-ctr">
      <v-chip
        class="autocmp-chip"
        small
        v-for="(item, index) in value"
        :key="index"
        :disabled="disabled"
        @click:close="removeOption(index)"
        close
        >{{ item }}</v-chip
      >
    </div>
    <v-autocomplete
      clearable
      hide-details
      hide-no-data
      autofocus
      ref="list"
      :disabled="disabled"
      v-model="tmp"
      :items="options"
      :search-input="tmp"
      @change="addOption"
      class="sgc-autocomplete-location"
      :placeholder="title"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "RfAutoCompletionWithChips",
  props: ["options", "title", "help", "value", "disabled"],
  data() {
    return {
      tmp: "",
    };
  },
  methods: {
    addOption(option) {
      if (option) {
        this.$emit("input", Array.from(new Set([...this.value, option])));
      }
      this.tmp = "";
    },
    removeOption(index) {
      this.$emit(
        "input",
        this.value.filter((_, i) => i !== index),
      );
    },
  },
};
</script>
