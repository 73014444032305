export const HorizontalPositions = {
  left: { value: "left", text: "Left" },
  center: { value: "center", text: "Center" },
  right: { value: "right", text: "Right" },
};

export const VerticalPositions = {
  top: { value: "top", text: "Top" },
  bottom: { value: "bottom", text: "Bottom" },
};
