<template>
  <abbr :title="value" v-if="value >= NUMBER_FORMAT_LIMIT">
    {{ metricsNumberFormat(value) }}
  </abbr>
  <span v-else> {{ `${value}`.toLocaleString() }} </span>
</template>

<script>
import StringUtils from "@/utils/StringUtils";

export default {
  name: "RfNumberFormatter",
  props: ["value"],
  setup: () => ({ metricsNumberFormat: StringUtils.metricsNumberFormat }),
  data: () => ({ NUMBER_FORMAT_LIMIT: 1000 }),
};
</script>
