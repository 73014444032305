import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      currUserRoleMixin: state => state.apps.currUser,
      appsRoleMixin: state => state.apps.apps,
      currAppRoleMixin: state => state.apps.currApp,
    }),
    appRoles: () => ({ member: "Member", admin: "Admin", read_only: "Read only" }),
    currAppUserDataRoleMixin() {
      return this.currUserRoleMixin?.apps.find(({ id }) => id === this.currAppRoleMixin.id);
    },
    isSalesRoleMixin() {
      return this.currUserRoleMixin?.departments?.includes("sales");
    },
    isSuperAdminRoleMixin() {
      return this.currUserRoleMixin?.role === "super_admin";
    },
    isCompanyAdminMixin() {
      return this.currUserRoleMixin?.role === "admin";
    },
    isAppAdminRoleMixin() {
      return this.currAppUserDataRoleMixin?.role === "admin";
    },
    isAdminRoleMixin() {
      return this.isSuperAdminRoleMixin || this.isCompanyAdminMixin || this.isAppAdminRoleMixin;
    },
    isReadOnlyRoleMixin() {
      if (!this.currUserRoleMixin?.apps || !this.currAppRoleMixin?.id) return false;
      return this.currAppUserDataRoleMixin?.role === "read_only";
    },
    isDisabledRoleMixin() {
      return this.isReadOnlyRoleMixin && "Insufficient Access";
    },
    isDisabledExtendedRoleMixin() {
      return !this.isAdminRoleMixin && "Insufficient Access";
    },
    allAppsWithWriteAccessRoleMixin() {
      if (this.isSuperAdminRoleMixin || this.isCompanyAdminMixin) return this.appsRoleMixin;
      return this.currUserRoleMixin?.apps.filter(app => app.role !== "read_only") || [];
    },
  },
  methods: {
    canModifyUserRoleMixin(userRole) {
      return (
        this.isSuperAdminRoleMixin ||
        this.isCompanyAdminMixin ||
        (this.isAppAdminRoleMixin && userRole === "member")
      );
    },
  },
};
