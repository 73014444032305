<template>
  <div class="promo-design-group">
    <div class="promo-all-buttons-inputs" v-if="!isHorizontal">
      <div class="promo-button-widalig">
        <h4>Width:</h4>
        <v-select
          :items="buttonWidth"
          v-model="actions.rf_settings_text_container_max_width"
          outlined
          required
          dense
          hide-details
          background-color="#ffffff"
        ></v-select>
      </div>
      <div class="promo-button-widalig">
        <h4>Position:</h4>
        <v-select
          :items="buttonPosition"
          v-model="actions.rf_settings_bar_position"
          outlined
          required
          dense
          hide-details
          background-color="#ffffff"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RfRetentionButtonsAlignment",
  props: ["actions", "model"],
  data() {
    return {
      buttonWidth: [
        {
          text: "Normal",
          value: "normal",
        },
        {
          text: "Full",
          value: "full",
        },
      ],
      buttonPosition: [
        {
          text: "Top",
          value: "top",
        },
        {
          text: "Bottom",
          value: "bottom",
        },
      ],
    };
  },
  computed: {
    isHorizontal() {
      return this.model.path_type === "horizontal";
    },
  },
  watch: {},
};
</script>
