<template>
  <div>
    <div class="iap-header-div">
      <h4>IAP product ids</h4>
      <div class="iap-add-new-div">
        <v-btn depressed color="accent" :disabled="disabled" @click.stop="openNewProductDialog()">
          <v-icon left>add</v-icon>Add product id
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="productsDialog" width="500" persistent>
      <v-card>
        <v-card-title>Add product ID</v-card-title>
        <v-card-text>
          <RfTextInput
            outlined
            dense
            v-model="editingProduct.name"
            title="Name"
            placeholder="Name"
          />
          <RfTextInput
            outlined
            dense
            v-model="editingProduct.product_id"
            title="Product ID"
            placeholder="Product ID"
            hide-details
          />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn class="cancel-btn px-7" outlined depressed @click.stop="productsDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            class="px-10"
            depressed
            @click="submitProduct"
            :disabled="!submitProductEnabled"
            >{{ submitProductCTA }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-simple-table class="rf-settings-table-data">
      <thead>
        <tr>
          <th width="41%">Name</th>
          <th width="41%">Product Id</th>
          <th width></th>
        </tr>
      </thead>
      <tbody v-if="productsDisplay.length">
        <tr v-for="(product, index) in productsDisplay" :key="index">
          <td>{{ product.name }}</td>
          <td>{{ product.product_id }}</td>
          <td>
            <div class="settings-button-wrapper">
              <v-btn small icon @click="editProduct(index)" :disabled="disabled">
                <v-icon color="accent" size="24">edit</v-icon>
              </v-btn>
              <v-btn icon small @click="deleteProduct(index)" :disabled="disabled">
                <v-icon color="error" size="24">clear</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td>
            <div>No product ids configured. Add a product id.</div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "RfAppleProductIds",
  props: ["app", "disabled"],
  components: { RfTextInput },
  data() {
    return {
      productsDialog: false,
      newProduct: {
        product_id: "",
        name: "",
      },
      editProductIndex: -1,
      editingProduct: {},
      computeKey: 0,
    };
  },
  methods: {
    editProduct(index) {
      this.editingProduct = cloneDeep(this.app.providers.apple.product_ids[index]);
      this.editProductIndex = index;
      this.productsDialog = true;
    },
    deleteProduct(index) {
      this.app.providers.apple.product_ids.splice(index, 1);
      this.computeKey++;
    },
    openNewProductDialog() {
      this.editingProduct = cloneDeep(this.newProduct);
      this.productsDialog = true;
    },
    submitProduct() {
      if (this.editProductIndex >= 0) {
        this.app.providers.apple.product_ids[this.editProductIndex] = this.editingProduct;
      } else {
        this.app.providers.apple.product_ids.push(this.editingProduct);
      }
      this.editingProductIndex = -1;
      this.productsDialog = false;
    },
  },
  computed: {
    submitProductCTA() {
      if (this.editProductIndex >= 0) {
        return "Update Product ID";
      }
      return "Add Product ID";
    },
    submitProductEnabled() {
      return !!(this.editingProduct.name && this.editingProduct.product_id);
    },
    productsDisplay() {
      this.computeKey;
      return this.app.providers.apple.product_ids;
    },
  },
};
</script>
<style scoped>
.iap-header-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
}
.iap-header-div > h4 {
  font-size: 1.3em;
  color: #383938;
}
.iap-header-div > h4,
.iap-header-div > div {
  flex: 1;
}
.iap-header-div > div {
  text-align: right;
}
</style>
