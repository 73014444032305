<template>
  <div v-if="visibleCustomFields.length">
    <div class="custom-all-content">
      <div
        v-for="(item, index) in visibleCustomFields"
        :key="index"
        class="custom-control-single-div sgmnt-inner-option"
      >
        <rf-custom-controls
          :model="item"
          :values="getData(item).values"
          :currentOptions="getData(item).options"
          :ref="item.label"
          :disabled="isDisabled(item)"
        />
      </div>
    </div>
  </div>
  <v-alert dense outlined type="error" v-else style="max-width: 500px">
    No items were found. Data for those items is either missing or invalid. Please see
    <router-link :to="integrationsLink()" style="text-decoration: underline"
      >User Traits</router-link
    >
    on how to correct this.
  </v-alert>
</template>

<script>
import { mapState } from "vuex";
import PipelineMixin from "@/utils/PipelineMixin";
import { sortBy } from "lodash-es";
import RfCustomControls from "../RfCustomControls.vue";
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";

export default {
  name: "RfCustom",
  props: ["model", "objectType", "pipeline"],
  components: {
    RfCustomControls,
  },
  mixins: [PipelineMixin, CustomFieldsMixin],
  data() {
    return {
      modelPipeline: this.pipeline,
    };
  },
  methods: {
    getData(field) {
      if (this.model && this.model.custom_filter && this.model.custom_filter[field.label]) {
        return this.model.custom_filter[field.label];
      }

      return {};
    },

    fillModel(model) {
      const custom_filter = {};
      if (this.visibleCustomFields.length) {
        this.visibleCustomFields.forEach(field => {
          const widget = this.$refs[field.label][0];
          custom_filter[field.label] = {
            values: widget.valueSet || [],
            options: widget.options || {},
          };
        });
        model.custom_filter = { ...model.custom_filter, ...custom_filter };
      }
    },
    isDisabled(item) {
      const { customFields } = this;
      const matched = customFields.find(({ name }) => name === item.name);
      if (matched) {
        return matched.is_disabled;
      }
      return false;
    },
    integrationsLink() {
      return `/apps/${this.currApp.id}/settings/segment_traits`;
    },
  },
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      currApp: state => state.apps.currApp,
    }),
    visibleCustomFields() {
      const availableCustomFields = this.customFields.filter(
        field =>
          field.display_type &&
          field.display_type !== "compound" &&
          field.data_type &&
          !this.parentOverrides(field),
      );

      return sortBy(availableCustomFields, [
        o => {
          return o.name;
        },
      ]);
    },
    customFields() {
      if (this.apps.length === 0) return [];
      const app = this.currApp;
      const fields = this.appCustomFields;
      const filteredFields = fields.filter(field => field.connector_type === "aws_s3");
      return filteredFields;
    },
  },
};
</script>

<style scoped></style>
