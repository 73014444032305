<template>
  <Transition mode="out-in">
    <div class="flex flex-col gap-2" key="empty" v-if="!hasData && !skeleton">
      <div
        class="!-mb-px !mt-0.5 flex flex-col gap-2 self-center !pb-14 !pt-14 lg:!mb-0 lg:!mt-0 lg:!pb-0 lg:!pt-0"
      >
        <RfNoChartDataPlaceholder
          v-if="!alterNoData"
          class="pt-10"
          body="There is no data yet. Data is displayed when a prompt(s) has been running for at least two hours."
          style="height: 364px"
        />
        <RfNoChartDataPlaceholderAlter v-else class="mb-1 pt-10" style="height: 364px" />
      </div>
      <div class="h-5 pt-2"></div>
    </div>
    <div v-else class="flex flex-col gap-3" key="chart">
      <div
        v-if="skeleton"
        class="mb-0.5 mt-px grid grid-cols-3 gap-2 gap-y-6 border-none px-4 py-3 lg:grid-cols-6"
      >
        <div v-for="i in 6" :key="i" class="flex flex-col gap-3">
          <RfBaseSkeleton height="16" class="!w-5/6" width="146" />
          <RfBaseSkeleton height="25" class="!w-2/3" width="106" />
          <!-- <div class="flex items-center gap-1.5">
            <RfBaseSkeleton height="24" width="24" radius="9999" />
            <RfBaseSkeleton height="16" class="!w-1/6" width="29" />
          </div> -->
        </div>
      </div>
      <div
        v-else
        ref="statsButtonsContainer"
        class="grid-max-cols-3 lg:grid-max-cols-6 auto-stretch grid grid-cols-auto-stretch border-y border-solid border-strokes px-0 py-0"
      >
        <button
          v-for="({ totals, label }, index) in data"
          class="grid place-items-start border-b-2 border-solid border-transparent px-4 py-2"
          :disabled="+totals.value === 0"
          :class="{
            '!border-blue-1 bg-strokes': selectedChartKey === index + 1,
            'hover:bg-strokes': +totals.value > 0,
          }"
          @click="selectChartKey(index + 1)"
        >
          <RfStatsCard
            :title="totals.label || label"
            class="bg-opacity-0 !p-0 text-start"
            :value="totals.text || totals.value"
            :grow="0"
            :timePeriod="''"
            :timePeriodName="''"
          />
        </button>
      </div>
      <RfChart
        :noLegend="noLegend"
        :data="data"
        :skeleton="skeleton"
        :hasData="hasData"
        :selectedChartKey="selectedChartKey"
      >
        <slot></slot
      ></RfChart>
    </div>
  </Transition>
</template>

<script setup>
import { newChartHasData } from "@/utils/metricsHelpers";
import RfChart from "./RfChart.vue";
import RfStatsCard from "@/components/cards/RfStatsCard.vue";
import RfNoChartDataPlaceholderAlter from "@/components/placeholder/RfNoChartDataPlaceholderAlter.vue";
import RfBaseSkeleton from "@/components/skeletons/RfBaseSkeleton.vue";
import { computed, onUnmounted, ref, watchEffect } from "vue";
import { onClickOutside } from "@vueuse/core";
import RfNoChartDataPlaceholder from "@/components/placeholder/RfNoChartDataPlaceholder.vue";

const props = defineProps({
  skeleton: { type: Boolean, default: false },
  alterNoData: { type: Boolean, default: false },
  data: { type: Array, default: () => [] },
  noLegend: { type: Boolean, default: false },
  hasData: { type: [Boolean, undefined], default: undefined },
});

const statsButtonsContainer = ref(null);
const selectedChartKey = ref(null);
const hasData = computed(() =>
  typeof props.hasData === "boolean" ? props.hasData : newChartHasData(props.data),
);

const selectChartKey = key => {
  if (key === selectedChartKey.value) return (selectedChartKey.value = null);
  return (selectedChartKey.value = key);
};

watchEffect(() => {
  if (!props.skeleton && hasData.value) {
    stop.value = onClickOutside(statsButtonsContainer.value, () => (selectedChartKey.value = null));
  } else {
    stop.value?.();
  }
});

onUnmounted(stop?.value);

defineExpose({ hasData });
</script>
