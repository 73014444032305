<template>
  <div class="promo-details-info-row">
    <div class="pd-info-leftcol">
      {{ title }}
    </div>
    <div class="pd-info-rightcol">
      <slot name="promodetail"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "RfPromoDetailsInfoRow",
  props: ["title"],
};
</script>

<style lang="scss">
.promo-details-info-row {
  display: flex;
  flex-direction: row;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #ddd;
  > div {
    padding: 12px;
  }
  word-break: break-word;
}

.pd-info-leftcol {
  background-color: #f6f8fa;
  min-width: 140px;
}
.pd-info-rightcol {
  flex-grow: 1;
  position: relative;
}
</style>
