const promoCurrLimitError = (trafficFilter, currentLimit = 0) =>
  trafficFilter.is_limited && trafficFilter.limit - currentLimit < 1;
const promoCurrBudgetError = (supplyFilter, currentBudget = 0) =>
  supplyFilter.is_budgeted && supplyFilter.budget - currentBudget < 1;
const promoCurrUserLimitError = (usersFilter, currentUsers = 0) =>
  usersFilter.is_limited && usersFilter.limit - currentUsers < 1;
const promoCurrDeliveryError = (deliveriesFilter, currentDeliveries = 0) =>
  deliveriesFilter.is_limited && deliveriesFilter.limit - currentDeliveries < 1;

const isPromptLimited = prompt =>
  prompt.filter &&
  (promoCurrLimitError(prompt.filter.traffic, prompt.curr_limit) ||
    promoCurrBudgetError(prompt.filter.supply, prompt.curr_budget) ||
    promoCurrUserLimitError(prompt.filter.users, prompt.curr_users) ||
    promoCurrDeliveryError(prompt.filter.deliveries, prompt.curr_deliveries));

export default isPromptLimited;
export {
  promoCurrLimitError,
  promoCurrBudgetError,
  promoCurrUserLimitError,
  promoCurrDeliveryError,
};
