export default {
  data() {
    return {
      zoomValue: 1,
      zoomValues: [0.1, 0.25, 0.5, 0.75, 1, 1.25],
    };
  },
  methods: {
    zoomIn() {
      if (this.zoomValue === this.zoomValues[this.zoomValues.length - 1]) return;
      this.zoomValue = this.zoomValues[this.zoomValues.findIndex(el => el === this.zoomValue) + 1];
    },
    zoomOut() {
      if (this.zoomValue === this.zoomValues[0]) return;
      this.zoomValue = this.zoomValues[this.zoomValues.findIndex(el => el === this.zoomValue) - 1];
    },
    resetZoom() {
      this.zoomValue = 1;
    },
  },
  computed: {
    isDefaultZooming() {
      return this.zoomValue === 1;
    },
    zoomStyle() {
      return {
        "transform": `scale(${this.zoomValue})`,
        "transform-origin": "center top",
        "transition": "transform .5s ease",
      };
    },
  },
};
