<template>
  <v-dialog
    scrollable
    persistent
    :value="priorityListDialog"
    max-width="800"
    @input="v => (v ? show() : close())"
  >
    <v-card>
      <v-card-title class="headline">
        {{ capitalize(title) }}
      </v-card-title>
      <v-card-text class="promotion-priority-div">
        <div class="mb-10">
          Prompt that sits higher on the list will be given priority over the ones below.
        </div>

        <div class="list-group-header">
          <div class="promotion-priority-col-0">
            <span></span>
          </div>
          <div class="promotion-priority-col-1">
            <span>Position</span>
          </div>
          <div class="promotion-priority-col-2 flex justify-between">
            <span>Prompt Name</span>
            <v-checkbox
              class="mr-0 mt-0 pt-0"
              v-model="removeEnded"
              label="Active prompts only"
              dark
              dense
              hide-details
            ></v-checkbox>
          </div>
        </div>
        <draggable
          tag="div"
          v-model="prioritizedPaths"
          v-bind="dragOptions"
          @start="isDragging = true"
          @end="isDragging = false"
          class="priority-list-wrapper"
        >
          <transition-group tag="div" type="transition" name="flip-list">
            <template v-for="(element, index) in prioritizedPaths">
              <span
                v-if="!removeEnded || (element.is_enabled && !isPromptExpired(element.end_date))"
                :class="
                  element.id === currRetention.id
                    ? 'current-retention list-group-item'
                    : 'list-group-item'
                "
                :key="element.id"
              >
                <span class="promotion-priority-col-0">
                  <v-icon small>drag_indicator</v-icon>
                </span>
                <span class="promotion-priority-col-1">
                  <span>{{ index + 1 }}</span>
                </span>
                <span class="promotion-priority-col-2">
                  <span @click="showRetention(element)">{{ element.name }}</span>
                </span>
                <span class="promotion-priority-col-3">
                  <v-icon v-if="element.id === currRetention.id" color="primary" size="16"
                    >star</v-icon
                  >
                </span>
              </span>
            </template>
          </transition-group>
        </draggable>
      </v-card-text>
      <v-card-actions class="modal-card-actions-div">
        <v-btn large depressed class="cancel-btn" outlined @click="close()" style="width: 100px"
          >Close</v-btn
        >
        <v-btn
          large
          depressed
          color="primary"
          style="width: 170px"
          @click="updatePriorityListClick"
        >
          <v-icon left>save</v-icon>Save changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import { cloneDeep } from "lodash-es";
import { mapActions } from "vuex";
import StringUtils from "@/utils/StringUtils";

export default {
  name: "RfRetentionPriority",
  props: ["retentions", "currRetention"],
  components: { draggable },
  setup: () => ({ capitalize: StringUtils.capitalize }),
  data: () => ({ currentRetentions: [], priorityListDialog: false, removeEnded: false }),
  computed: {
    prioritizedPaths: {
      get() {
        return this.removeEnded
          ? [...this.currentRetentions].sort((a, b) => {
              const aEnabled = a.is_enabled && !this.isPromptExpired(a.end_date);
              const bEnabled = b.is_enabled && !this.isPromptExpired(b.end_date);
              if (aEnabled === bEnabled) return 0;
              return aEnabled ? -1 : 1;
            })
          : this.currentRetentions;
      },
      set(value) {
        this.currentRetentions = value.map((item, index) => ({
          ...item,
          path_group: { ...item.path_group, priority: index },
        }));
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    title() {
      if (!this.currRetention) return "";
      if (["video", "retention_modal"].indexOf(this.currRetention.path_type) > -1) {
        return "Popup and Video Priority";
      }
      return `${this.currRetention.path_type} Priority`;
    },
  },
  methods: {
    ...mapActions(["getRetentions"]),
    async show() {
      if (!this.retentions?.length) await this.getRetentions({ appId: this.$route.params.aid });
      this.currentRetentions = (cloneDeep(this.retentions) || []).sort(
        (a, b) =>
          (a.path_group ? a.path_group.priority : 0) - (b.path_group ? b.path_group.priority : 0),
      );
      this.priorityListDialog = true;
    },
    close() {
      this.priorityListDialog = false;
    },
    showRetention(retention) {
      this.close();
      const appId = this.$route.params.aid;
      this.$router.push({ path: `/apps/${appId}/retentions/${retention.id}` });
    },
    async updatePriorityListClick() {
      const appId = this.$route.params.aid;
      const pathGroups = this.currentRetentions.map(item => item.path_group);
      this.$store.dispatch("setPriorityRetentions", { appId, pathGroups });
      this.close();
    },
    isPromptExpired: endDate => +new Date(endDate) < +new Date(),
  },
};
</script>
