<template>
  <RfSimpleCard>
    <div class="px-7 pt-5">
      <v-card flat class="rf-custom-web-card-wrapper">
        <PrismEditor v-model="code" language="js"></PrismEditor>
      </v-card>
    </div>
  </RfSimpleCard>
</template>

<script>
import "prismjs";
import "prismjs/themes/prism.css";
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import PrismEditor from "vue-prism-editor";

export default {
  name: "RfCssStyles",
  components: {
    RfSimpleCard,
    PrismEditor,
  },
  props: ["app"],
  data() {
    return {
      success: false,
      code: this.getCssStyles(),
    };
  },
  methods: {
    fillModel(model) {
      model.styles = JSON.parse(this.code);
    },
    getCssStyles() {
      return JSON.stringify(this.app.styles, null, "\t");
    },
  },
};
</script>
