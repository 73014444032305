<template>
  <div style="width: 100%">
    <!-- note- ^^this unlabeled div does not exist in brig-->
    <span class="rfmodal-close" v-if="!hideCloseButton && closeButtonEnabled">
      <v-icon id="rfmodal-close-icon" :style="{ color: fillColor }">close</v-icon>
    </span>
    <div class="video-volume-control" v-if="isVideo">
      <span class="video-volume-up">
        <v-icon size="24" :style="{ color: fillColor }" v-if="actions.rf_settings_video_muted"
          >volume_off</v-icon
        >
        <v-icon size="24" class="video-volume-up" :style="{ color: fillColor }" v-else
          >volume_up</v-icon
        >
      </span>
    </div>
    <div class="rfmodal-inner-wrapper">
      <div :class="backgroundContentAlignmentClass">
        <div
          :class="textContainerClasses"
          :style="{ width: actions.rf_settings_text_container_max_width }"
        >
          <div>
            <div>
              <!-- extra div layers to match brig ^^ -->
              <div class="rfmodal-header">
                <h1 v-html="actions.rf_retention_title" :style="titleStyle"></h1>
              </div>
              <div class="rfmodal-body">
                <RfBodyCountdown :actions="actions" />
                <div v-html="message" class="rfmodal-message" :style="messageStyle"></div>
              </div>
            </div>
          </div>
          <RfPromoInputsPreview :actions="actions" />
          <div class="rfmodal-footer">
            <RfRetentionMessageButton :actions="actions" />
          </div>
        </div>
        <div :style="columnspacerWidth" class="rfmodal-colm-spacer" v-if="!isVideo"></div>
      </div>
    </div>
  </div>
</template>
<script>
import PromoPreviewMixin from "@/utils/PromoPreviewMixin";
import RfRetentionMessageButton from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionMessageButton.vue";
import RfPromoInputsPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfPromoInputsPreview.vue";
import RfBodyCountdown from "../RfBodyCountdown.vue";

export default {
  name: "RfRetentionBanner",
  props: ["actions", "isVideo", "hideCloseButton"],
  mixins: [PromoPreviewMixin],
  components: { RfRetentionMessageButton, RfPromoInputsPreview, RfBodyCountdown },
  computed: {
    messageStyle() {
      return {
        "font-size": this.actions.rf_settings_message_font_size,
        "color": this.fillColor,
      };
    },
    titleStyle() {
      return {
        "font-size": this.actions.rf_settings_title_font_size,
        "color": this.fillColor,
        "margin-top": this.actions.rf_settings_title_padding_top,
      };
    },
    textContainerClasses() {
      return [
        {
          "rfmodal-text-container": true,
        },
        {
          "rf-banner-text-horizontal": this.actions.rf_banner_display === "horizontal",
          "rf-banner-text-vertical": this.actions.rf_banner_display === "vertical",
          "rf-banner-text-reversed": this.actions.rf_banner_position_reverse === "true",
        },
        {
          "left-align-mode": this.actions.rf_retention_align === "left",
          "center-align-mode": this.actions.rf_retention_align === "center",
          "right-align-mode": this.actions.rf_retention_align === "right",
        },
        {},
      ];
    },
    backgroundContentAlignmentClass() {
      if (this.actions.rf_retention_img_align === "left") {
        return "rfmodal-content-wrapper b-left-align";
      }
      return "rfmodal-content-wrapper b-right-align";
    },
    columnspacerWidth() {
      return {
        width: `calc(100% - ${this.actions.rf_settings_text_container_max_width})`,
        height: "4px",
      };
    },
  },
};
</script>
<style>
.video-volume-control {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>
