<template>
  <div
    class="rfmodal-body-countdown"
    v-if="actions.rf_retention_countdown_enabled"
    :style="messageCountdownStyle"
  >
    <template v-if="actions.rf_retention_countdown_start_caption">
      <span
        class="rfmodal-body-countdown--caption"
        v-html="actions.rf_retention_countdown_start_caption"
      >
      </span
      >&nbsp;</template
    ><span class="rfmodal-body-countdown--timer" v-html="countdownRender"></span>
  </div>
</template>

<script setup>
import { useCountdownTimer } from "@/utils/composables/useCountdownTimer";
import { computed } from "vue";

const props = defineProps({ actions: { type: Object, default: () => ({}) } });
const { messageCountdownStyle, countdownRender } = useCountdownTimer(computed(() => props.actions));
</script>
