<template>
  <div v-if="usageFields">
    <div class="usage-all-content" v-for="k in validFieldKeys" :key="k">
      <h3 class="usage-header-title">{{ capitalize(k) }}</h3>
      <div class="segment-create-section-wrapper">
        <div
          class="segment-create-section sgmnt-inner-option"
          v-for="item in usageFields[k]"
          :key="item.id"
        >
          <div>
            <div class="segment-create-title" style="margin-bottom: 1rem">
              <h4>{{ item.label }}</h4>
              <div class="segment-cr-desc">{{ item.description }}</div>
            </div>
            <v-select
              :items="fetchRangeTypes(item)"
              v-model="item.filter.options.range_type"
              hide-details
              label="Value"
              outlined
              dense
              background-color="#ffffff"
              @change="setValuesByRangeType(item)"
              :menu-props="{ maxHeight: 604 }"
            />
            <div class="usage-all-opts-wrapper">
              <div
                class="usage-trending-opt-div"
                v-if="item.filter.options.range_type === 'trending'"
              >
                <div class="segment-create-slider">
                  <div class="slider-label">Range:</div>
                  <RfRangeSlider
                    v-model="item.filter.values"
                    :max="10"
                    :min="0"
                    :step="1"
                    :isNormalized="true"
                    style="margin-left: 0px"
                  />
                </div>
                <div class="range-selector-opt">
                  <v-select
                    v-model="item.filter.options.trend"
                    :items="trendDirection"
                    label="Choose Trend Direction"
                    hint="Choose trend direction for users"
                    persistent-hint
                    outlined
                    dense
                    background-color="#ffffff"
                  ></v-select>
                  <v-select
                    v-model="item.filter.options.frequency"
                    :items="timeDuration"
                    label="Choose Time period"
                    hint="Choose the time period to compare users against"
                    persistent-hint
                    outlined
                    dense
                    background-color="#ffffff"
                  ></v-select>
                </div>
              </div>
              <div
                class="usage-range-opt-div"
                v-else-if="item.filter.options.range_type === 'range'"
              >
                <!-- two inputs -->
                <v-text-field
                  hide-details
                  v-model="item.filter.values[0]"
                  outlined
                  dense
                  label="From"
                  background-color="#ffffff"
                />
                <span>-</span>
                <v-text-field
                  hide-details
                  v-model="item.filter.values[1]"
                  outlined
                  dense
                  label="To"
                  background-color="#ffffff"
                />
              </div>
              <div
                class="less-than-eq-div"
                v-else-if="singleInputRangeTypes.indexOf(item.filter.options.range_type) >= 0"
              >
                <!-- one input -->
                <v-text-field
                  v-model="item.filter.values[1]"
                  outlined
                  dense
                  label="Value"
                  v-if="item.filter.options.range_type === 'less_than_or_equal_to'"
                  background-color="#ffffff"
                  hide-details
                />
                <v-text-field
                  background-color="#ffffff"
                  v-model="item.filter.values[0]"
                  outlined
                  dense
                  label="Value"
                  hide-details
                  v-else
                />
              </div>
              <div
                class="over-the-last-div"
                v-if="rawUsageTimePeriodsRangeTypes.indexOf(item.filter.options.range_type) >= 0"
              >
                <div class="over-the-last-txt">Over the last</div>
                <v-select
                  hide-details
                  :items="rawUsageTimePeriods"
                  v-model="item.filter.options.time_period_days"
                  dense
                  outlined
                  background-color="#ffffff"
                  placeholder="number of days"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RfRangeSlider from "@/components/RfRangeSlider.vue";
import PipelineMixin from "@/utils/PipelineMixin";
import { cloneDeep } from "lodash-es";
import StringUtils from "@/utils/StringUtils";
import {
  RangeTypes,
  RawUsageTimePeriods,
  TrendDirection,
  TimeDuration,
} from "@/utils/constants/SegmentsConstants";
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";

export default {
  name: "RfUsage",
  components: { RfRangeSlider },
  mixins: [PipelineMixin, CustomFieldsMixin],
  props: ["model", "app", "objectType", "pipeline"],
  setup: () => ({ capitalize: StringUtils.capitalize }),
  data() {
    return {
      usageFields: null,
      trendDirection: Object.values(TrendDirection),
      timeDuration: Object.values(TimeDuration),
      rangeTypes: Object.values(RangeTypes),
      rangeTypeValues: {
        any: [],
        none: ["0", "0"],
        at_least_once: ["1", "INF"],
      },
      singleInputRangeTypes: ["equal_to", "greater_than_or_equal_to", "less_than_or_equal_to"],
      rawUsageTimePeriodsRangeTypes: [
        "equal_to",
        "greater_than_or_equal_to",
        "less_than_or_equal_to",
        "range",
        "at_least_once",
        "none",
      ],
      rawUsageTimePeriods: Object.values(RawUsageTimePeriods),
      pipelineStage: null,
      modelPipeline: this.pipeline,
    };
  },
  computed: {
    validFieldKeys() {
      const data = Object.keys(this.usageFields).filter(item => {
        return ["redfast", "aws_s3"].indexOf(item) < 0;
      });

      // sort so redfast is always first
      return ["redfast"].concat(data);
    },
  },
  methods: {
    // have to do these shenanigans because rf-range-slider doesn't handle a nested attribute very well
    initModel(app) {
      const usageFields = cloneDeep(this.appCustomFields.filter(field => field.is_usage));
      const existingFilter = cloneDeep(this.model.custom_filter);
      const self = this;
      const usageByConnectorType = usageFields.reduce((h, item) => {
        if (!h[item.connector_type]) h[item.connector_type] = [];
        // for stages, return if pipeline has this trait
        // for segments, return if stage or pipeline has this trait

        const obj = {
          label: item.label,
          name: item.name,
          id: item.id,
          description: item.description,
          filter: {
            values: ["0", "10"],
            options: {
              frequency: null,
              trend: null,
              range_type: "any",
              time_period_days: null,
            },
          },
        };

        if (existingFilter[item.label]) {
          if (existingFilter[item.label].values) {
            obj.filter.values = cloneDeep(existingFilter[item.label].values);
          }
          if (existingFilter[item.label].options) {
            obj.filter.options = cloneDeep(existingFilter[item.label].options);
            if (!obj.filter.options.range_type) {
              let rangeType = "any";
              if (obj.filter.options.trend) {
                rangeType = "trending";
              }
              obj.filter.options.range_type = cloneDeep(rangeType);
            }
          }
        }

        if (obj.filter.values.length === 0) {
          obj.filter.values = ["0", "10"];
        }
        if (!self.parentOverrides(item)) {
          h[item.connector_type].push(obj);
        }
        return h;
      }, {});
      return usageByConnectorType;
    },
    setNullOptions(filter) {
      filter.options.trend = null;
      filter.options.frequency = null;
    },
    setValuesByRangeType(item) {
      if (item.filter.options.range_type === "trending") {
        item.filter.values = ["0", "10"];
      } else if (item.filter.options.range_type === "less_than_or_equal_to") {
        item.filter.values = ["0", "0"];
      }
    },
    fillModel(modelSegment) {
      Object.keys(this.usageFields).forEach(k => {
        this.usageFields[k].forEach(item => {
          const { filter } = item;
          if (+filter.values[0] === 0 && +filter.values[1] === 10) {
            filter.values = [];
          }

          const rangeType = filter.options.range_type;
          if (Object.keys(this.rangeTypeValues).indexOf(rangeType) >= 0) {
            filter.values = this.rangeTypeValues[rangeType];
            this.setNullOptions(filter);
          } else if (this.singleInputRangeTypes.indexOf(rangeType) >= 0) {
            const value = filter.values[0];
            if (rangeType === "less_than_or_equal_to") {
              filter.values = ["0", filter.values[1]];
            } else if (rangeType === "greater_than_or_equal_to") {
              filter.values = [value || "0", "INF"];
            } else if (rangeType === "equal_to") {
              filter.values = [value, value];
            }
            this.setNullOptions(filter);
          } else if (rangeType === "range") {
            this.setNullOptions(filter);
          }
          modelSegment.custom_filter[item.label] = filter;
        });
      });
    },
    fetchRangeTypes(item) {
      let types = cloneDeep(this.rangeTypes);
      const { label } = item;
      if (label === "minutes" || label === "visits") {
        types = types.filter(type => type.value !== "none");
      }
      return types;
    },
    initFields() {
      this.usageFields = this.initModel(this.app);
    },
  },
};
</script>

<style scoped>
.rf-radio-buttons {
  margin-left: -24px;
}
.rf-radio-card-container {
  padding-left: 0;
}
</style>
