<template>
  <div class="rf-metrics-simple-row" v-if="simpleDisplay">
    <div class="rf-msd-col" v-if="!isInvisible">
      <small>Users</small>
      <div class="rf-msd-stat-num">
        <RfNumberFormatter :value="currImpressions" />
      </div>
    </div>
    <div class="rf-msd-col">
      <small>{{ goalsTitle }}</small>
      <div class="rf-msd-stat-num">
        <RfNumberFormatter :value="preprocessedGoals ? data.totalGoals : currGoals" />
      </div>
    </div>
    <div class="rf-msd-col" v-if="!isInvisible">
      <small>Conv</small>
      <div class="rf-msd-stat-num">{{ currConversions }}</div>
    </div>
  </div>
  <div v-else class="rf-metrics-arrow" :style="rowStyle">
    <div>
      <div>
        <div class="rf-metrics-card-summary">
          <div class="rf-metrics-summary-wrapper">
            <div class="rf-metrics-summary-item">
              <div class="rf-metrics-summary-left-container">
                <div class="rf-metrics-summary-title">Users</div>
                <div>
                  <div class="rf-metrics-summary-number text-nowrap">
                    <RfNumberFormatter :value="isInvisible ? currUsers : currImpressions" />
                  </div>
                </div>
              </div>
            </div>
            <div class="rf-metrics-summary-item">
              <div class="rf-metrics-summary-mid-container">
                <div class="rf-metrics-summary-title">
                  <span v-if="isInvisible">Clicks</span>
                  <span v-else>
                    <abbr :title="goalsTitle" v-if="hasCustomGoalAndSinglePath">Goal</abbr>
                    <span v-else>{{ goalsTitle }}</span>
                  </span>
                </div>
                <div>
                  <div class="rf-metrics-summary-number text-nowrap">
                    <RfNumberFormatter :value="preprocessedGoals ? data.totalGoals : currGoals" />
                  </div>
                </div>
              </div>
            </div>
            <div class="rf-metrics-summary-item">
              <div class="rf-metrics-summary-end-container">
                <div class="rf-metrics-summary-title">Conversion</div>
                <div>
                  <div class="rf-metrics-summary-number text-nowrap">
                    {{ currConversions }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MetricsMixin from "@/utils/MetricsMixin";
import RfNumberFormatter from "../RfNumberFormatter.vue";
import { percentage, statsAggregator, tally } from "@/utils/metricsHelpers";
import { isInvisible } from "@/utils/prompts/promptHelpers";
import { computed } from "vue";

export default {
  name: "RfPlacementsStats",
  props: ["model", "metricsKey", "simpleDisplay", "preprocessedGoals", "autoHeight"],
  setup: props => ({ isInvisible: computed(() => isInvisible(props.model)) }),
  mixins: [MetricsMixin],
  components: { RfNumberFormatter },
  computed: {
    hasCustomGoalAndSinglePath() {
      if (!this.model?.custom_filter || Array.isArray(this.model)) return false;
      return Object.keys(this.model.custom_filter).length > 0;
    },
    goalsTitle() {
      if (this.hasCustomGoalAndSinglePath) {
        return Object.keys(this.model.custom_filter)[0];
      }
      return this.isInvisible ? "Users" : "Clicks";
    },
    data() {
      return Array.isArray(this.model)
        ? statsAggregator(this.model, this.metricsKey)
        : this.model.data;
    },
    currUsers() {
      return tally(this.data, this.metricsKey, "users");
    },
    currImpressions() {
      return tally(this.data, this.metricsKey, "uimpressions");
    },
    currGoals() {
      return tally(
        this.data,
        this.metricsKey,
        this.hasCustomGoalAndSinglePath ? "custom_goals_seen" : "goals",
      );
    },
    currConversions() {
      let numerator = this.currImpressions;
      if (this.isInvisible) {
        numerator = this.currUsers;
      }
      return percentage(
        this.preprocessedGoals ? this.data.totalGoals : this.currGoals,
        numerator,
        true,
      );
    },
    rowStyle() {
      if (!this.autoHeight) return { height: "45px" };
      return {};
    },
  },
};
</script>
