<template>
  <div>
    <RfPromoDetailsInfoRow title="Schedule">
      <template v-slot:promodetail>
        <div>
          <div v-if="item.sequence_id">
            Inherited from
            <router-link :to="getGuideLink(item.sequence_id, $route.params.aid)">{{
              item.sequence_name
            }}</router-link>
          </div>
          <div style="padding-right: 30px" v-else>
            Starts on
            <span class="font-bold">{{
              dateDisplay(item.start_date, { utc: true, time: true })
            }}</span>
            and ends on
            <span class="font-bold">{{
              dateDisplay(item.end_date, { utc: true, time: true })
            }}</span
            >.
            <div class="pd-info-edit">
              <RfButton
                icon-size="16"
                title="edit"
                icon="edit"
                color="accent"
                x-small
                :disabled="editDisabled"
                @click="showPromoDetailDialog('schedule')"
              />
            </div>
          </div>
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <RfPromoDetailsInfoRow v-if="!isPushNotification" title="Daypart">
      <template v-slot:promodetail>
        <div>
          <div v-if="item.sequence_id">
            Inherited from
            <router-link :to="getGuideLink(item.sequence_id, $route.params.aid)">{{
              item.sequence_name
            }}</router-link>
          </div>
          <div v-else>
            <div v-if="!item.filter.schedule.length">-</div>
            <div style="padding-right: 30px" class="pd-schedule-list" v-else>
              <div v-for="(item, index) in item.filter.schedule" :key="index">
                {{ WeekDay[item[0]] }}, {{ hhmmIntegerTohhmm(item[1]) }} -
                {{ hhmmIntegerTohhmm(item[2]) }}
              </div>
            </div>
            <div class="pd-info-edit">
              <RfButton
                icon-size="16"
                title="edit"
                icon="edit"
                color="accent"
                x-small
                :disabled="editDisabled"
                @click="showPromoDetailDialog('daypart')"
              />
            </div>
          </div>
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <v-dialog
      v-model="showPromotionInfoDialog"
      width="750"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-title class="headline">When should it be shown</v-card-title>
        <v-card-text>
          <RfSchedule
            :model="item"
            ref="schedule"
            :isPushNotification="isPushNotification"
            :scheduleType="scheduleType"
          />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed outlined class="cancel-btn" width="100px" @click="closeDialog"
            >Close</v-btn
          >
          <v-btn large @click="submitConfiguration" width="200px" color="primary" depressed>
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfSchedule from "@/components/RfPathCreate/RfSchedule.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import StringUtils from "@/utils/StringUtils";
import { cloneDeep } from "lodash-es";
import { dateDisplay } from "@/utils/DateDisplayUtils";
import { getGuideLink } from "@/utils/getLink";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfPromoScheduleInfo",
  setup: () => ({
    dateDisplay,
    getGuideLink,
    hhmmIntegerTohhmm: StringUtils.hhmm2IntervalIndex,
    toastsStore: useToastsStore(),
  }),
  components: { RfPromoDetailsInfoRow, RfSchedule, RfButton },
  props: {
    item: Object,
    editDisabled: Boolean,
    isPushNotification: { type: Boolean, default: false },
  },
  data() {
    return {
      WeekDay: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      showPromotionInfoDialog: false,
      scheduleType: "schedule",
    };
  },
  methods: {
    showPromoDetailDialog(type) {
      this.showPromotionInfoDialog = true;
      this.scheduleType = type;
    },
    closeDialog() {
      this.showPromotionInfoDialog = false;
    },
    async submitConfiguration() {
      try {
        const appId = this.$route.params.aid;
        const newPath = cloneDeep(this.item);
        this.$refs.schedule.fillModel(newPath);
        let title = "Prompt";
        if (this.item.subject_name === "Sequence") {
          title = "Guide";
          await this.updateSequence({
            appId,
            sequenceId: this.$route.params.sid,
            modelSequence: newPath,
          });
        } else {
          await this.updatePath({ appId, pathId: this.$route.params.pid, modelPath: newPath });
        }
        this.showPromotionInfoDialog = false;
        this.toastsStore.create({ type: "success", body: `${title} updated successfully` });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    ...mapActions(["updatePath", "updateSequence"]),
  },
};
</script>
