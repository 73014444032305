import { customRef } from "vue";

export const useTableSort = (
  availableSorts = ["default"],
  defaultSort = { type: "default", value: "asc" },
) => {
  const sort = customRef((track, trigger) => {
    let type, value;
    return {
      get() {
        track();
        return { type, value, string: `${type},${value}` };
      },
      set(v) {
        let newType, newValue;
        if (typeof v === "string") [newType, newValue] = v.split(",");
        else if (Array.isArray(v)) [newType, newValue] = v;
        else if (typeof v === "object") ({ type: newType, value: newValue } = v);
        else ({ type: newType, value: newValue } = defaultSort);

        if (!newValue || newValue === "null" || !availableSorts.includes(newType))
          ({ type: newType, value: newValue } = defaultSort);

        trigger();
        type = newType;
        value = newValue;
      },
    };
  });

  return sort;
};
