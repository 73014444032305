import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiConnectorActions {
  static getActions(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/connector_actions`);
  }

  static updateAction(appId, actionId, action) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/connector_actions/${actionId}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ connector_action: action }),
    });
  }

  static createAction(appId, action) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/connector_actions`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ connector_action: action }),
    });
  }

  static async deleteAction(appId, actionId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/connector_actions/${actionId}`, {
      method: "DELETE",
    }).then(_ => actionId);
  }
}
