<template>
  <tr>
    <td>{{ action.name }}</td>
    <td class="website-actions-table-col2 rf-custom-web-card-wrapper">
      <PrismEditor
        :code="displayCodeSummary(action)"
        :readonly="true"
        language="js"
        :emitEvents="false"
      ></PrismEditor>
    </td>
    <td v-if="isSuperAdminRoleMixin">
      <v-checkbox v-model="action.internal" class="mt-0" :value="true" hide-details />
    </td>
    <td v-if="!isTrigger">
      <ul v-if="actionPrompts.length" class="list-deleted-trigger">
        <li v-for="prompt in actionPrompts.slice(0, showPromptEntries)" :key="prompt.id">
          <router-link :to="getPromptLink(prompt.id, $route.params.aid)">{{
            prompt.name
          }}</router-link>
        </li>
        <template v-if="actionPrompts.length > 3">
          <template v-if="showPromptEntries < actionPrompts.length">
            <li>...</li>
            <button class="text-blue-1" @click="showPromptEntries = actionPrompts.length">
              Show more
            </button>
          </template>
          <template v-else-if="showPromptEntries === actionPrompts.length">
            <button class="text-blue-1" @click="showPromptEntries = 3">Show less</button>
          </template>
        </template>
      </ul>
      <span v-else>N/A</span>
    </td>
    <td v-if="!isWebsiteAction">
      <ul v-if="actionTriggers.length" class="list-deleted-trigger">
        <li v-for="trigger in actionTriggers.slice(0, showTriggerEntries)" :key="trigger.id">
          {{ trigger.name }}
        </li>
        <template v-if="actionTriggers.length > 3">
          <template v-if="showTriggerEntries < actionTriggers.length">
            <li>...</li>
            <button class="text-blue-1" @click="showTriggerEntries = actionTriggers.length">
              Show more
            </button>
          </template>
          <template v-else-if="showTriggerEntries === actionTriggers.length">
            <button class="text-blue-1" @click="showTriggerEntries = 3">Show less</button>
          </template>
        </template>
      </ul>
      <span v-else>N/A</span>
    </td>
    <td class="cc-colm">
      <div class="settings-triggers-tbl-btn">
        <RfButton
          :icon="!!isDisabledRoleMixin ? 'mdi-information-variant' : 'edit'"
          color="accent"
          @click="$emit('edit')"
        />
        <RfButton
          icon="clear"
          color="error"
          :disabled="isDisabledRoleMixin"
          @click="$emit('remove')"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import "prismjs";
import "prismjs/themes/prism.css";
import RoleMixin from "@/utils/RoleMixin";
import PrismEditor from "vue-prism-editor";
import RfButton from "../buttons/RfButton.vue";
import { getPromptLink } from "@/utils/getLink";

export default {
  props: [
    "action",
    "defaultCode",
    "actions",
    "retentions",
    "triggers",
    "isWebsiteAction",
    "isTrigger",
  ],
  components: { PrismEditor, RfButton },
  mixins: [RoleMixin],
  setup: () => ({ getPromptLink }),
  data: () => ({ showPromptEntries: 3, showTriggerEntries: 3 }),
  computed: {
    actionPrompts() {
      return this.retentions.filter(
        prompt =>
          !!prompt.action_group_connector_actions.find(
            agca => agca.connector_action?.id === this.action.id,
          ),
      );
    },
    actionTriggers() {
      return this.triggers.filter(trigger => trigger.custom_trigger_id === this.action.id);
    },
  },
  methods: {
    displayCodeSummary(action) {
      if (action && action.code) {
        const codeWithoutComments = action.code.replace(this.defaultCode, "");
        let truncatedLines = codeWithoutComments.split("\n");
        if (truncatedLines.length > 3) {
          truncatedLines = truncatedLines.slice(0, 2);
          truncatedLines.push("...");
        }
        return truncatedLines.join("\n");
      }
      return "";
    },
  },
};
</script>
