<template>
  <div class="promo-tab-bar">
    <div>
      <div
        v-for="(item, index) in thirdPartyOptions"
        :key="index"
        @click="setSelectionName(item.provider)"
        :class="setSelectedClasses(item.provider)"
        v-if="hasValidActions(item)"
      >
        <img
          :src="providers[item.provider].src"
          :width="providers[item.provider].width"
          v-if="providers[item.provider] && providers[item.provider].type === 'img'"
        />
        <p v-else>{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RfRetentionConnectorTabs",
  props: ["thirdPartyOptions", "value"],
  data() {
    return {
      providers: {
        zendesk: { type: "img", src: require("@/assets/images/logos/zendesk.png"), width: "70" },
        custom_provider: {
          type: "img",
          src: require("@/assets/images/logos/custom.png"),
          width: "58",
        },

        sendgrid: { type: "img", src: require("@/assets/images/logos/sendgrid.png"), width: "85" },
        stripe: { type: "img", src: require("@/assets/images/logos/stripe.png"), width: "50" },
        salesforce: {
          type: "img",
          src: require("@/assets/images/logos/salesforce.png"),
          width: "85",
        },
        braze: {
          type: "img",
          src: require("@/assets/images/logos/logo-braze.svg"),
          width: "50",
        },
        vindicia: { type: "img", src: require("@/assets/images/logos/vindicia.png"), width: "75" },
        braintree: {
          type: "img",
          src: require("@/assets/images/logos/braintree.gif"),
          width: "88",
        },
        shopify: {
          type: "img",
          src: require("@/assets/images/logos/shopify.png"),
          width: "88",
        },
        zuora: {
          type: "img",
          src: require("@/assets/images/logos/zuora-sm.png"),
          width: "60",
        },
        segment: {
          type: "img",
          src: require("@/assets/images/logos/segment_io.png"),
          width: "88",
        },
        redfast: { type: "img", src: require("@/assets/images/logos/redfast.png"), width: "80" },
        chargify: { type: "img", src: require("@/assets/images/logos/chargify.png"), width: "80" },
        freshdesk: {
          type: "img",
          src: require("@/assets/images/logos/freshdesk.png"),
          width: "80",
        },
        recurly: {
          type: "img",
          src: require("@/assets/images/logos/recurly.png"),
          width: "80",
        },
        active_campaign: {
          type: "img",
          src: require("@/assets/images/logos/activecampaign_logo.png"),
          width: "120",
        },
        iterable: {
          type: "img",
          src: require("@/assets/images/logos/iterable_logo.png"),
          width: "80",
        },
        cleeng: {
          type: "img",
          src: require("@/assets/images/logos/cleeng.svg"),
          width: "120",
        },
      },
    };
  },
  methods: {
    hasValidActions(item) {
      return item.value.actions.filter(action => action.operation).length;
    },
    setSelectedClasses(name) {
      return {
        "promo-tab": true,
        "promo-tab--active": this.value === name,
      };
    },
    setSelectionName(name) {
      if (name !== this.value) {
        this.$emit("input", name);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
