<template>
  <div class="flex flex-col gap-1">
    <label v-if="title" class="rf-text-input--title text-body" :for="`input${_uid}`">
      {{ title }}
    </label>
    <component
      :id="`input${_uid}`"
      :is="textarea ? 'v-textarea' : 'v-text-field'"
      v-bind="$attrs"
      :data-cy="$attrs['data-cy']"
      v-on="$listeners"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>
      <template #label></template>
    </component>
  </div>
</template>

<script>
export default {
  name: "PulseRfTextInput",
  inheritAttrs: false,
  props: {
    title: { type: [String, Boolean], default: false },
    textarea: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__append-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
::v-deep .v-text-field__details {
  padding: 0 0.125rem !important;
}
::v-deep
  .v-input:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: rgba(158, 158, 158, 0.47) !important;
}

::v-deep .v-messages__message {
  font-size: 0.9em;
}
</style>
