<template>
  <v-dialog
    :value="dialog"
    @input="v => (v ? show() : close())"
    v-model="dialog"
    width="500"
    persistent
    content-class="show-promo-details-actions"
  >
    <v-card flat>
      <v-card-title>Assign Custom Devices</v-card-title>
      <v-card-text>
        <div>
          These changes will only affect current prompt. You can configure custom devices
          <router-link :to="`/apps/${appId}/settings/custom_devices`">in settings</router-link>.
        </div>
        <RfSelect
          outlined
          autocomplete
          dense
          chips
          :item-disabled="
            ({ id }) =>
              currPathCustomDevicesLocal.find(v => v === id) &&
              currPathCustomDevicesLocal.length <= 1
          "
          multiple
          v-model="currPathCustomDevicesLocal"
          :items="customDevices"
          item-value="id"
          item-text="name"
          data-cy="custom-device-modal--select"
          class="mt-5"
          mandatory
          title="Custom Devices"
          placeholder="Select custom devices"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn large depressed outlined class="cancel-btn" width="100px" @click="close">
          Close
        </v-btn>
        <RfButton
          large
          icon="save"
          button-text="Save"
          data-cy="custom-device-modal--submit"
          :disabled="!isCustomDevicesEdited"
          width="200px"
          color="primary"
          @click="submitDevices"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfCustomDevicesModal",
  components: { RfSelect, RfButton },
  setup: () => ({ toastsStore: useToastsStore() }),
  data: () => ({ dialog: false, currPathCustomDevicesLocal: [] }),
  computed: {
    ...mapGetters(["currPathCustomDevices"]),
    ...mapState({ customDevices: state => state.apps.customDevices }),
    isCustomDevicesEdited() {
      return (
        this.currPathCustomDevices.length !== this.currPathCustomDevicesLocal.length ||
        !this.currPathCustomDevices.every(el =>
          this.currPathCustomDevicesLocal.find(id => id === el.id),
        )
      );
    },
    appId() {
      return this.$route.params.aid;
    },
  },
  methods: {
    ...mapActions(["updatePath", "getCustomDevices"]),
    async show() {
      this.dialog = true;
      if (!this.customDevices?.length) await this.getCustomDevices(this.appId);
      this.currPathCustomDevicesLocal = [...this.currPathCustomDevices];
    },
    close() {
      this.dialog = false;
      this.currPathCustomDevicesLocal = [];
    },
    async submitDevices() {
      try {
        const modelPath = cloneDeep(this.$store.state.apps.currPath);
        modelPath.custom_devices = this.currPathCustomDevicesLocal.map(id => ({ id }));
        await this.updatePath({ appId: this.appId, pathId: modelPath.id, modelPath });
        this.close();
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
  },
};
</script>
