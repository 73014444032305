import * as Sentry from "@sentry/browser";
import store from "../store";
import { useToastsStore } from "@/pinia/toastsStore";

export default async function copyToClipboard(
  input,
  successMsg = "Successfully copied to clipboard.",
  errorMsg = "Couldn't copy data to clipboard",
) {
  const toastsStore = useToastsStore();
  try {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(input);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = input;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      textArea.tabIndex = -1;
      textArea.classList.add("visually-hidden");
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand("copy");
      textArea.remove();
    }

    return toastsStore.create({ type: "success", body: successMsg });
  } catch (error) {
    Sentry.captureException(error);
    toastsStore.create({ type: "error", body: errorMsg });
  }
}
