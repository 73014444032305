<template>
  <v-btn
    ref="btn"
    v-bind="$attrs"
    :aria-disabled="disabledLocal"
    :icon="icon && !buttonText"
    :plain="link"
    :ripple="!link"
    elevation="0"
    :class="{
      'v-btn--disabled': disabledLocal,
      'v-btn--link px-0': link,
    }"
    v-on="{
      ...$listeners,
      click: e => (disabledLocal ? {} : ($emit('click', e), $listeners.click)),
    }"
    @hook:mounted="renderTooltip = true"
  >
    <span class="d-flex align-center" :class="{ 'flex-row-reverse': iconRight }">
      <v-icon
        v-if="icon"
        :size="iconSize || (buttonText ? 18 : 22)"
        :left="!!buttonText && !iconRight"
        :right="!!buttonText && iconRight"
      >
        {{ icon }}
      </v-icon>
      {{ buttonText }}
    </span>
    <slot />
    <v-tooltip v-if="renderTooltip && disabledLocal" :activator="$refs.btn" v-bind="tooltip">
      <span>{{ typeof disabled === "string" && !!disabled ? disabled : "Disabled" }}</span>
    </v-tooltip>
  </v-btn>
</template>

<script>
export default {
  props: {
    buttonText: { type: String, default: null },
    icon: { type: String, default: null },
    disabled: { type: [String, Boolean], default: false },
    link: { type: Boolean, default: false },
    iconSize: { type: [String, Number], default: null },
    tooltip: { type: Object, default: () => ({ top: true }) },
    iconRight: { type: Boolean, default: false },
  },
  data: () => ({ renderTooltip: false }),
  computed: {
    disabledLocal() {
      return (typeof this.disabled === "string" && this.disabled === "") || !!this.disabled;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-btn--disabled {
  pointer-events: auto;
  cursor: not-allowed;

  img {
    filter: opacity(40%) saturate(0%) brightness(120%);
  }
}
::v-deep.v-btn--link {
  height: auto !important;
  color: #3096ed !important;
  font-weight: normal !important;
  padding: 0;
  min-width: 0 !important;
}
</style>
