<template>
  <div class="custom-range-selector">
    <v-text-field
      hide-details
      v-model="minValue"
      outlined
      dense
      :disabled="disabled"
      :placeholder="minLabel"
      background-color="#ffffff"
    />
    <span>-</span>
    <v-text-field
      hide-details
      v-model="maxValue"
      outlined
      dense
      :disabled="disabled"
      :placeholder="maxLabel"
      background-color="#ffffff"
    />
  </div>
</template>
<script>
export default {
  name: "RfCustomRangeSelector",
  props: ["model", "value", "disabled", "minPlaceholder", "maxPlaceholder"],
  data() {
    return {
      min: (this.value && this.value[0]) || "-INF",
      max: (this.value && this.value[1]) || "INF",
    };
  },
  computed: {
    minValue: {
      get() {
        if (this.min === "-INF") {
          return "";
        }
        return this.min;
      },
      set(value) {
        if (!value.length) {
          this.min = "-INF";
        } else {
          this.min = value;
        }
      },
    },
    maxValue: {
      get() {
        if (this.max === "INF") {
          return "";
        }
        return this.max;
      },
      set(value) {
        if (!value.length) {
          this.max = "INF";
        } else {
          this.max = value;
        }
      },
    },
    minLabel() {
      if (this.minPlaceholder) return this.minPlaceholder;
      if (this.min === "-INF") return "Any";
      return "From";
    },
    maxLabel() {
      if (this.maxPlaceholder) return this.maxPlaceholder;
      if (this.max === "INF") return "Any";
      return "From";
    },
  },
  watch: {
    min(to) {
      this.$emit("input", [to, this.max]);
    },
    max(to) {
      this.$emit("input", [this.min, to]);
    },
  },
  methods: {},
};
</script>
