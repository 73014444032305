<template>
  <div class="rf-detail-content-wrapper" style="margin-top: 50px; max-width: 1280px" v-if="segment">
    <RfBreadcrumbs breadcrumb-key="segment-create" />
    <v-breadcrumbs :items="breadcrumbItems" class="rf-breadcrumbs"
      ><template v-slot:divider>
        <v-icon>chevron_right</v-icon>
      </template></v-breadcrumbs
    >
    <div class="page-detail-header" style="margin-bottom: 10px">
      <h1>Create a new segment</h1>
    </div>
    <v-stepper v-model="step" class="rf-create-promo-stepper">
      <v-stepper-header>
        <v-stepper-step
          editable
          :complete="step > stepMap['name']"
          :step="stepMap['name']"
          :rules="[() => showErrors]"
          >Name & Description</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step editable :complete="step > stepMap['usage']" :step="stepMap['usage']"
          >Usage</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step
          editable
          :complete="step > stepMap['users']"
          :step="stepMap['users']"
          v-if="enableUsers"
          >Users</v-stepper-step
        >
        <v-divider v-if="enableUsers"></v-divider>
        <v-stepper-step
          editable
          :complete="step > stepMap['device']"
          :step="stepMap['device']"
          v-if="enableDevice"
          >Device</v-stepper-step
        >
        <v-divider v-if="enableDevice"></v-divider>
        <v-stepper-step
          editable
          :complete="step > stepMap['location']"
          :step="stepMap['location']"
          v-if="enableLocation"
          >Location</v-stepper-step
        >
        <v-divider v-if="enableLocation"></v-divider>
        <v-stepper-step editable :complete="step > stepMap['promos']" :step="stepMap['promos']">
          Prompts</v-stepper-step
        >
        <v-divider />
        <v-stepper-step editable :complete="step > stepMap['custom']" :step="stepMap['custom']"
          >Custom</v-stepper-step
        >
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content :step="stepMap['name']">
          <div class="new-segment-panel-1">
            <RfInfo :model="segment" ref="systemInfo" />
          </div>
          <v-card-actions class="rf-retention-form-btn-div">
            <v-btn
              depressed
              x-large
              class="step-continue"
              color="primary"
              @click="step = stepMap['name'] + 1"
            >
              Continue
              <v-icon size="26" right>chevron_right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content :step="stepMap['usage']" class="segment-create-step-2">
          <rf-usage :app="currApp" :model="segment" ref="usage" objectType="segment" />
          <v-card-actions class="rf-retention-form-btn-div">
            <v-btn
              depressed
              x-large
              class="step-continue"
              color="primary"
              @click="step = stepMap['usage'] + 1"
            >
              Continue
              <v-icon size="26" right>chevron_right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content
          :step="stepMap['users']"
          class="segment-create-step-2"
          v-if="enableUsers"
        >
          <rf-user :modelSegment="segment" ref="user" />

          <v-card-actions class="rf-retention-form-btn-div">
            <v-btn
              depressed
              x-large
              class="step-continue"
              color="primary"
              @click="step = stepMap['users'] + 1"
            >
              Continue
              <v-icon size="26" right>chevron_right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content :step="stepMap['device']" v-if="enableDevice">
          <rf-device :modelSegment="segment" ref="device" />
          <v-card-actions class="rf-retention-form-btn-div">
            <v-btn
              depressed
              x-large
              class="step-continue"
              color="primary"
              @click="step = stepMap['device'] + 1"
            >
              Continue
              <v-icon size="26" right>chevron_right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content
          :step="stepMap['location']"
          class="segment-create-location"
          v-if="enableLocation"
        >
          <rf-location :model="segment" ref="location" />
          <v-card-actions class="rf-retention-form-btn-div">
            <v-btn
              depressed
              x-large
              class="step-continue"
              color="primary"
              @click="step = stepMap['location'] + 1"
            >
              Continue
              <v-icon size="26" right>chevron_right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content :step="stepMap['promos']">
          <RfPromoInteractions :model="segment" :app="currApp" ref="promos" />
          <v-card-actions class="rf-retention-form-btn-div">
            <v-btn
              depressed
              x-large
              class="step-continue"
              color="primary"
              @click="step = stepMap['promos'] + 1"
            >
              Continue
              <v-icon size="26" right>chevron_right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content :step="stepMap['custom']">
          <rf-custom :model="segment" ref="custom" objectType="segment" />
          <v-card-actions class="rf-retention-form-btn-div">
            <v-btn
              depressed
              x-large
              color="success"
              style="width: 300px; font-size: 18px"
              @click="submitCreate"
            >
              <v-icon left>save</v-icon>Create Segment
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { cloneDeep } from "lodash-es";
import * as Sentry from "@sentry/browser";
import { mapActions, mapState } from "vuex";
import RfInfo from "@/components/RfCommonCards/RfInfo.vue";
import RfLocation from "@/components/RfSegmentCreate/RfLocation.vue";
import RfDevice from "@/components/RfSegmentCreate/RfDevice.vue";
import RfUsage from "@/components/RfSegmentCreate/RfUsage.vue";
import RfCustom from "@/components/RfCommonCards/RfCustom.vue";
import RfUser from "@/components/RfSegmentCreate/RfUser.vue";
import RfPromoInteractions from "@/components/RfSegmentCreate/RfPromoInteractions.vue";
import RfBreadcrumbs from "@/components/RfBreadcrumbs.vue";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfSegmentCreate",
  setup: () => ({ toastsStore: useToastsStore() }),
  components: {
    RfInfo,
    RfUsage,
    RfCustom,
    RfDevice,
    RfLocation,
    RfUser,
    RfPromoInteractions,
    RfBreadcrumbs,
  },
  data: () => ({ step: 1, showErrors: true, colorIndex: 0 }),
  computed: {
    ...mapState({
      currSegment: state => state.apps.currSegment,
      segment: state => state.tmp.segment,
      lastError: state => state.lastError,
      currApp: state => state.apps.currApp,
      apps: state => state.apps.apps,
    }),
    enableLocation() {
      if (this.currApp) {
        return this.currApp.flags.geo_lookup;
      }
      return true;
    },
    enableDevice() {
      if (this.currApp) {
        return this.currApp.flags.device_lookup;
      }
      return true;
    },
    enableUsers() {
      if (this.currApp) {
        return this.currApp.flags.fraud_check;
      }
      return true;
    },
    stepMap() {
      const steps = {};
      let index = 1;
      steps.name = index;
      index++;
      steps.usage = index;
      index++;
      if (this.enableUsers) {
        steps.users = index;
        index++;
      }
      if (this.enableDevice) {
        steps.device = index;
        index++;
      }
      if (this.enableLocation) {
        steps.location = index;
        index++;
      }
      steps.promos = index;
      index++;
      steps.custom = index;
      return steps;
    },
  },
  methods: {
    async submitCreate() {
      try {
        const newSegment = cloneDeep(this.segment);
        if (this.$refs.location) {
          this.$refs.location.fillModel(newSegment);
        }
        if (this.$refs.device) {
          this.$refs.device.fillModelSegment(newSegment);
        }
        if (this.$refs.user) {
          this.$refs.user.fillModelSegment(newSegment);
        }
        this.$refs.promos.fillModel(newSegment);
        this.$refs.custom.fillModel(newSegment);
        this.$refs.usage.fillModel(newSegment);
        this.$refs.systemInfo.fillModel(newSegment);
        await this.createSegment({
          appId: this.$route.params.aid,
          modelSegment: newSegment,
        })
          .then(() => (this.loading = false))
          .catch(() => null);
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
        Sentry.captureException(e);
        return (this.showErrors = false);
      }
    },
    ...mapActions(["createSegment"]),
  },
  watch: {
    currSegment(to) {
      this.toastsStore.create({ type: "success", body: "Segment created successfully" });
      this.$router.replace({ path: `/apps/${this.$route.params.aid}/segments/${to.id}` });
    },
    lastError(to) {
      if (to) {
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>
