<template>
  <div class="rf-segments rf-main-content-wrapper">
    <div class="flex min-h-12 items-center justify-between gap-16">
      <div class="flex min-w-px flex-1 items-center gap-8">
        <h1 class="flex-shrink-0 text-xl font-medium uppercase not-italic">All segments</h1>
        <div
          class="flex flex-grow-0 items-center gap-4 overflow-y-hidden overflow-x-scroll pb-1 pt-2"
        >
          <template v-for="(filter, filterKey) in filters">
            <RfBaseTag
              v-for="(el, key) in filter"
              data-cy="segments-tag"
              @clear="removeFilter(filterKey, key), refetch()"
            >
              {{ el }}
            </RfBaseTag>
          </template>
          <button
            v-if="filtersLength"
            data-cy="segments-tag--clear-all"
            class="text-body flex-shrink-0"
            @click="(filters = {}), refetch()"
          >
            Clear all filters
          </button>
        </div>
      </div>
      <div class="flex flex-grow-0 items-center gap-4">
        <RfSegmentCreateModal
          :segmentCreateDialog="segmentCreateDialog"
          v-on:closeCreateDialog="closeSegmentCreateDialog"
        />
        <RfButtonInput
          v-model="searchInputs.inputs.value[SEGMENTS_SEARCH]"
          text="Search"
          data-cy="segments--search"
          placeholder="Search"
          :icon="RfSearchIcon"
          @input="refetch"
        />
        <RfButtonNew
          text="+ New Segment"
          type="main"
          data-cy="segments--create-segment"
          :disabled="isDisabledRoleMixin"
          @click="showSegmentCreateDialog"
        />
      </div>
    </div>
    <RfTable
      :tableData="segmentsStore.table"
      :loading="segmentsStore.loading"
      :rowSkeleton="RfTableRowSkeleton"
      type="segments"
      @loadMore="getSegments(true)"
    >
      <template #pretable>
        <span class="mb-0 mt-4 inline-flex items-stretch gap-4">
          <RfTableSelect
            :selected="selected"
            :tableData="segmentsStore.table"
            @delete="(deleteItem = valuesSelected), $nextTick(() => $refs.deleteModal.show())"
            @edit="(editItem = selectedValue), $nextTick(() => $refs.renameModal.show())"
            @clone="(cloneItem = selectedValue), $nextTick(() => $refs.cloneModal.show())"
            @export="exportMultipleSegmentsToCSV([...selected.values()], date.preset?.value)"
            @status="
              status => (
                (statusUpdate = { status, segments: valuesSelected }),
                $nextTick(() => $refs.statusModal.show())
              )
            "
            @selectAll="segmentsStore.segments.forEach(v => updateMap('set', v))"
            @deselectAll="segmentsStore.segments.forEach(v => updateMap('delete', v))"
            @selectByPreset="selectByPreset"
          />
        </span>
        <span class="my-4 inline-flex items-center gap-4">
          <RfDatePicker
            ref="datePicker"
            :include="['today', 'last_seven_days', 'this_week', 'this_month']"
            hideCustom
            @input="v => ((date = v), refetch())"
          />
        </span>
      </template>
      <template #thead>
        <RfTableHeader
          :filters.sync="filters"
          :sort="sort"
          @update:sort="v => ((sort = v), refetch())"
          @update:filters="refetch"
        />
      </template>
      <template #tbody>
        <RfTableRow
          v-for="segment in segmentsStore.segments"
          :key="segment.id"
          :segment="segment"
          :timePeriod="date.preset?.value"
          :disabled="isDisabledRoleMixin"
          :timezone="currApp.timezone_offset"
          :selected="selected.has(segment.id)"
          :filters="{
            usage: customOrUsageFilters(getUsageFilterData(appCustomFields), segment.custom_filter),
            user: segment.filter?.user,
            device: segment.filter.device,
            custom: customOrUsageFilters(customFilters, segment.custom_filter),
            customFilters: customFilters,
            location: segment.filter?.location,
            locationConfigs: segment.filter?.location_configs,
            promoInteraction: segment.custom_filter?.promo_interaction,
            userBucket: segment.filter?.user_bucket,
          }"
          :customDevices="currApp.custom_devices"
          @select="updateMap('set', segment)"
          @deselect="updateMap('delete', segment)"
          @clone="(cloneItem = segment), $nextTick(() => $refs.cloneModal.show())"
          @status="
            status => (
              (statusUpdate = { status, segments: [segment] }),
              $nextTick(() => $nextTick(() => $nextTick(() => $refs.statusModal.show())))
            )
          "
          @edit="(editItem = segment), $nextTick(() => $refs.renameModal.show())"
          @delete="(deleteItem = [segment]), $nextTick(() => $refs.deleteModal.show())"
        />
      </template>
    </RfTable>
    <RfBaseModal
      v-if="cloneItem"
      ref="cloneModal"
      width="580"
      message="Do you want to clone this segment?"
      secondary-text="Clone"
      @secondary="cloneSegment"
      @close="cloneItem = null"
    >
      <template #title>
        Are you sure you want to clone
        <span class="whitespace-pre font-bold"> {{ cloneItem.name }} </span> ?
      </template>
    </RfBaseModal>
    <RfRenameModal
      v-if="editItem"
      ref="renameModal"
      :title="`Edit segment ${editItem.name}`"
      :name="editItem.name"
      :description="editItem.description"
      :onSubmit="v => updateSegment([{ ...v, id: editItem.id }])"
      @close="editItem = null"
    />
    <RfBaseModal
      v-if="deleteItem"
      ref="deleteModal"
      :message="`${deleteDialogText.body} This cannot be undone.`"
      width="580"
      error-text="Delete"
      @error="submitDelete"
      @close="deleteItem = null"
    >
      <template #title>
        Are you sure you want to delete
        <span class="whitespace-pre font-bold"> {{ deleteDialogText.title }} </span> ?
      </template>
    </RfBaseModal>
    <RfBaseModal
      v-if="statusUpdate.segments"
      ref="statusModal"
      :title="`${capitalize(statusChangeDialogText.title)} Segment`"
      width="580"
      :secondary-text="capitalize(statusChangeDialogText.title)"
      @secondary="updateSegmentStatus"
      @close="statusUpdate = { status: null, segments: null }"
    >
      <template #body>
        <span v-if="statusUpdate.segments.length > 1">
          Are you sure you want to {{ statusChangeDialogText.title }}
          <b>segments({{ statusUpdate.segments.length }})</b>?
          <b>These segments({{ statusUpdate.segments.length }})</b> will
          {{ statusChangeDialogText.body }}
        </span>
        <span v-else>
          Are you sure you want to {{ statusChangeDialogText.title }} this segment? The segment will
          {{ statusChangeDialogText.body }}
        </span>
      </template>
    </RfBaseModal>
  </div>
</template>

<script>
import RfTable from "@/blocks/RfTable/RfTable.vue";
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import RfSearchIcon from "@/components/icons/RfSearchIcon.vue";
import RfDatePicker from "@/components/inputs/RfDatePicker.vue";
import RfTableHeader from "@/blocks/RfSegments/RfTableHeader.vue";
import RfTableRow from "@/blocks/RfSegments/RfTableRow.vue";
import RfButtonInput from "@/components/inputs/RfButtonInput.vue";
import RfSegmentCreateModal from "@/components/RfSegments/RfSegmentCreateModal.vue";
import RoleMixin from "@/utils/RoleMixin";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import MetricsUtils from "@/utils/MetricsUtils";
import { ref } from "vue";
import { debounce } from "@/utils/debounce";
import { useSegmentsStore } from "@/pinia/segmentsStore";
import { mapState } from "vuex";
import {
  customOrUsageFilters,
  getUsageFilterData,
  getCustomFiltersData,
} from "@/utils/segmentsHelpers";
import RfTableRowSkeleton from "@/blocks/RfSegments/RfTableRowSkeleton.vue";
import RfBaseTag from "@/components/tags/RfBaseTag.vue";
import { Statuses } from "@/utils/constants/SegmentsConstants";
import ApiSegments from "@/apis/ApiSegments";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import RfTableSelect from "@/blocks/RfSegments/RfTableSelect.vue";
import RfRenameModal from "@/components/modals/RfRenameModal.vue";
import { useTableFilter } from "@/utils/composables/useTableFilter";
import { useTableSelect } from "@/utils/composables/useTableSelect";
import { useTableSort } from "@/utils/composables/useTableSort";
import StringUtils from "@/utils/StringUtils";
import { useToastsStore } from "@/pinia/toastsStore";
import { exportMultipleSegmentsToCSV } from "@/utils/CsvUtils";
import CustomFieldsMixin from "@/utils/CustomFieldsMixin";
import { SEGMENTS_SEARCH, useSearctInputs } from "@/utils/composables/useSearctInputs";

export default {
  mixins: [RoleMixin, UserSettingsMixin, CustomFieldsMixin],
  components: {
    RfTable,
    RfButtonNew,
    RfDatePicker,
    RfTableHeader,
    RfButtonInput,
    RfSegmentCreateModal,
    RfTableRow,
    RfBaseTag,
    RfBaseModal,
    RfTableSelect,
    RfRenameModal,
  },
  setup() {
    const segmentsStore = useSegmentsStore();
    const date = ref({});
    const searchInputs = useSearctInputs();
    const segmentCreateDialog = ref(false);
    const closeSegmentCreateDialog = () => (segmentCreateDialog.value = false);
    const showSegmentCreateDialog = () => (segmentCreateDialog.value = true);
    const { filters, removeFilter, filtersLength, filtersConverted } = useTableFilter();
    const { selected, updateMap, resetSelected, selectedValue, valuesSelected } = useTableSelect();
    const sort = useTableSort(["name", "created_at"], { type: "name", value: "asc" });
    const deleteItem = ref(null);
    const editItem = ref(null);
    const cloneItem = ref(null);
    const statusUpdate = ref({ status: null, segments: null });

    return {
      segmentsStore,
      date,
      searchInputs,
      SEGMENTS_SEARCH,
      segmentCreateDialog,
      closeSegmentCreateDialog,
      showSegmentCreateDialog,
      RfSearchIcon,
      customOrUsageFilters,
      getUsageFilterData,
      getCustomFiltersData,
      RfTableRowSkeleton,
      filters,
      removeFilter,
      filtersLength,
      filtersConverted,
      selected,
      updateMap,
      resetSelected,
      selectedValue,
      valuesSelected,
      sort,
      deleteItem,
      editItem,
      cloneItem,
      statusUpdate,
      capitalize: StringUtils.capitalize,
      toastsStore: useToastsStore(),
      exportMultipleSegmentsToCSV,
    };
  },
  data() {
    return {
      getSegments: debounce(async (next = false) => {
        const statuses = this.filtersConverted.statuses?.string;
        this.searchInputs.write();

        await this.segmentsStore.getSegmentsPaged(
          this.currApp.id,
          {
            metric_periods: [
              {
                period: this.date.preset.value,
                ...(this.date.preset.value === "custom" && {
                  date_range: [
                    this.date.startDate.format("YYYY-MM-DD"),
                    this.date.endDate.format("YYYY-MM-DD"),
                  ],
                }),
              },
            ],
            ...(this.searchInputs.inputs.value[SEGMENTS_SEARCH] && {
              name_search: this.searchInputs.inputs.value[SEGMENTS_SEARCH],
            }),
            ...(statuses && { statuses }),
            ...(this.sort.string && { order: this.sort.string }),
          },
          next,
        );

        await this.setUserSettings({
          segmentsSort: this.sort.string,
          segmentsStatusFilter: statuses,
          ...(this.date.preset.value !== "custom"
            ? { segmentsDateRange: this.date.preset.value }
            : {}),
        });
      }, 1000),
    };
  },
  mounted() {
    this.sort = this.getUserSetting("segmentsSort");

    const statuses = this.getUserSetting("segmentsStatusFilter");
    this.filters = {
      statuses:
        statuses && statuses !== "undefined"
          ? Object.fromEntries(statuses.split(",").map(el => [el, Statuses[el].title]))
          : {},
    };

    this.$nextTick(() => {
      const preset = MetricsUtils.All_Periods.find(
        ({ value }) =>
          value !== "since_start_date" && value === this.getUserSetting("segmentsDateRange"),
      )?.value;

      this.$refs.datePicker?.setTimeFromPreset(preset || "last_seven_days");
    });
  },
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
    customFilters() {
      return getCustomFiltersData(this.appCustomFields);
    },
    deleteDialogText() {
      if (this.deleteItem.length > 1)
        return {
          title: `${this.deleteItem.length} segments`,
          body: `Deleting this segments(${this.deleteItem.length}) removes all information about these segments(${this.deleteItem.length}).`,
        };

      return {
        title: this.deleteItem[0].name,
        body: "Deleting this segment removes all information about this segment.",
      };
    },
    statusChangeDialogText() {
      if (this.statusUpdate.status === Statuses.true.type)
        return { title: "start", body: "run immediately." };
      else return { title: "stop", body: "stop running immediately." };
    },
  },
  methods: {
    refetch() {
      this.resetSelected();
      this.segmentsStore.resetPage();
      this.getSegments();
    },
    cloneSegment() {
      const newSegment = {
        ...this.cloneItem,
        id: null,
        is_locked: false,
        pipeline_stage_id: null,
        name: `${this.cloneItem.name} copy`,
      };

      ApiSegments.createSegment(this.currApp.id, newSegment)
        .then(({ id }) => this.$router.push({ path: `/apps/${this.currApp.id}/segments/${id}` }))
        .catch(() => null);
    },
    updateSegmentStatus() {
      const segments = Array.isArray(this.statusUpdate.segments)
        ? this.statusUpdate.segments
        : [this.statusUpdate.segments];
      const is_enabled = this.statusUpdate.status;
      const newSegments = segments.map(({ id }) => ({ id, is_enabled }));
      this.editSegments(newSegments)
        .then(() => {
          this.$refs.statusModal.close();
          this.toastsStore.create({ type: "success", body: "Status updated" });
        })
        .catch(() => null);
    },
    updateSegment(newSegments) {
      this.editSegments(newSegments)
        .then(() => this.toastsStore.create({ type: "success", body: "Segment updated" }))
        .catch(() => null);
    },
    editSegments(segments) {
      this.resetSelected();
      return this.segmentsStore.bulkUpdate(this.currApp.id, segments);
    },
    async submitDelete() {
      try {
        await this.segmentsStore.bulkDelete(this.currApp.id, this.deleteItem);
        const body = `Segment${this.deleteItem.length > 1 ? "s" : ""} deleted`;
        this.$refs.deleteModal.close();
        this.resetSelected();

        const oldPage = this.segmentsStore.table.page;
        const oldPerPage = this.segmentsStore.table.perPage;
        this.segmentsStore.table.page = 0;
        this.segmentsStore.table.perPage = oldPage * oldPerPage;
        await this.getSegments();
        this.toastsStore.create({ type: "success", body });
        this.segmentsStore.table.page = oldPage;
        this.segmentsStore.table.perPage = oldPerPage;
      } catch (e) {}
    },
    selectByPreset(preset) {
      this.resetSelected();
      this.segmentsStore.segments.forEach(
        segment => Statuses[segment.is_enabled].type === preset && this.updateMap("set", segment),
      );
    },
  },
};
</script>
