var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trigger-configuration-wrapper"},[_c('div',{staticClass:"add-user-header-div"},[_c('div',{staticClass:"add-user-title"},[_c('div',{staticClass:"action-provider-settings-title"},[_c('h4',[_vm._v("When should it be initiated")]),_c('div',{staticStyle:{"display":"block"},domProps:{"innerHTML":_vm._s(_vm.helpText)}})])]),_c('div',{staticStyle:{"text-align":"right"}},[_c('v-btn',{staticStyle:{"margin-right":"12px"},attrs:{"depressed":"","outlined":"","color":"success","disabled":_vm.isDisabled},on:{"click":_vm.showCreateTriggerDialog}},[_c('v-icon',{attrs:{"left":"","size":"15px"}},[_vm._v("edit")]),_c('span',[_vm._v("Create new trigger")])],1),_c('v-btn',{attrs:{"depressed":"","color":"success","disabled":_vm.isDisabled},on:{"click":_vm.showAddNewTriggerDialog}},[_c('v-icon',{attrs:{"left":"","size":"18px"}},[_vm._v("add")]),_c('span',[_vm._v("Select & Add trigger")])],1)],1)]),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"900","width":"800"},model:{value:(_vm.addNewTriggerDialog),callback:function ($$v) {_vm.addNewTriggerDialog=$$v},expression:"addNewTriggerDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Select a trigger")]),_c('v-card-text',[(_vm.hasInjectTrigger)?_c('div',{staticClass:"triggers-warning-div"},[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Warning: Adding a trigger will delete any trigger added via Real Time ")])],1):_vm._e(),(_vm.appTriggerItems.length > 0)?_c('div',{staticClass:"add-triggers-list-mod"},_vm._l(([..._vm.appTriggerItems].sort((a, b) =>
              `${a.text}`.localeCompare(`${b.text}`),
            )),function(trigger){return _c('v-checkbox',{key:trigger.id,attrs:{"label":trigger.text,"value":trigger.value,"hide-details":""},model:{value:(_vm.addedTriggers),callback:function ($$v) {_vm.addedTriggers=$$v},expression:"addedTriggers"}})}),1):_c('div',[_c('v-icon',[_vm._v("warning")]),_vm._v(" There are no existing triggers. "),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.redirectToTriggerPage.apply(null, arguments)}}},[_vm._v("Click here to add some.")])],1)]),_c('v-card-actions',{staticClass:"modal-card-actions-div"},[_c('v-btn',{staticClass:"cancel-btn",staticStyle:{"width":"100px"},attrs:{"large":"","depressed":"","outlined":""},on:{"click":function($event){_vm.addNewTriggerDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticStyle:{"width":"150px"},attrs:{"large":"","depressed":"","color":"success"},on:{"click":_vm.submitTrigger}},[_vm._v("Add")])],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"900","width":"800"},model:{value:(_vm.createTriggerDialog),callback:function ($$v) {_vm.createTriggerDialog=$$v},expression:"createTriggerDialog"}},[_c('RfEditTrigger',{key:_vm.computeKey,attrs:{"editTrigger":_vm.editingTrigger,"creating":_vm.creating,"app":_vm.currApp,"parentTriggerType":this.model.device_type === 'web'
          ? 'web'
          : this.model.path_type === 'scheduled_push'
            ? 'push_event'
            : 'device'},on:{"closeNewTriggerDialog":_vm.closeCreateTriggerDialog}})],1),_c('RfBaseModal',{ref:"advancedArgsModal",attrs:{"title":"Advanced Arguments","width":"550","primaryText":"Done"},on:{"close":() => (_vm.editArgsTriggerIndex = null),"primary":_vm.submitArgs},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('RfAdvancedArguments',{ref:"advancedArgsBody"})]},proxy:true}])}),_c('div',{staticClass:"all-triggers-options"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"tbl-col-1"},[_vm._v("Name")]),_c('th',{staticClass:"tbl-col-1"},[_vm._v(_vm._s(_vm.urlPathHeader))]),_c('th',{staticClass:"tbl-col-2"},[_vm._v("Regex")]),_c('th',{staticClass:"tbl-col-5"},[_vm._v("Delay")]),_c('th',{staticClass:"tbl-col-3"},[_vm._v(_vm._s(_vm.clickIdHeader))]),(_vm.triggerType === 'web')?_c('th',{staticClass:"tbl-col-4"},[_vm._v("Click count")]):_vm._e(),(
                _vm.triggerType === 'web' ||
                (_vm.triggerType === 'device' && _vm.currApp?.flags?.custom_devices_js_sdk)
              )?_c('th',{staticClass:"tbl-col-4"},[_vm._v(" Advanced ")]):_vm._e(),(_vm.triggerType === 'web')?_c('th',{staticClass:"tbl-col-4"},[_vm._v("Interval (seconds)")]):_vm._e(),(_vm.triggerType === 'web')?_c('th',{staticClass:"tbl-col-4"},[_vm._v("Arguments")]):_vm._e(),(!_vm.isStandalone)?_c('th',{staticClass:"tbl-col-4"},[_vm._v("Added via Real Time")]):_vm._e(),(_vm.currApp?.flags?.scheduled_push)?[_c('th',{staticClass:"tbl-col-tr"},[_vm._v("Event name")]),_c('th',{staticClass:"tbl-col-tr"},[_vm._v("Event attributes")])]:_vm._e(),_c('th',{staticClass:"tbl-col-4"})],2)]),(_vm.triggers.length)?_c('tbody',{staticClass:"settings-triggers-tbl-ct"},_vm._l((_vm.triggerDisplay),function(trigger,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(trigger.name))]),_c('td',[_vm._v(_vm._s(_vm.displayPage(trigger)))]),_c('td',[(_vm.regexDisplay(trigger))?_c('div',[_vm._v(_vm._s(_vm.regexDisplay(trigger)))]):_c('div',{staticClass:"setting-empty-val"},[_vm._v("N/A")])]),_c('td',[_vm._v(_vm._s(trigger.delay_seconds || "0"))]),_c('td',[_vm._v(_vm._s(trigger.click_id || "None"))]),(_vm.triggerType === 'web')?_c('td',[_vm._v(_vm._s(trigger.click_count_threshold || null))]):_vm._e(),(
                _vm.triggerType === 'web' ||
                (_vm.triggerType === 'device' && _vm.currApp?.flags?.custom_devices_js_sdk)
              )?_c('td',[_vm._v(" "+_vm._s(_vm.displayCustomTrigger(trigger, _vm.customTriggers) || "None")+" ")]):_vm._e(),(_vm.triggerType === 'web')?_c('td',[(_vm.displayCustomInterval(trigger, undefined, _vm.eventInterval))?_c('div',[_vm._v(" "+_vm._s(_vm.displayCustomInterval(trigger, undefined, _vm.eventInterval))+" ")]):_c('div',{staticClass:"setting-empty-val"},[_vm._v("N/A")])]):_vm._e(),(_vm.triggerType === 'web')?_c('td',[(trigger.custom_trigger_id)?_c('div',[_vm._v(" "+_vm._s(_vm.objectToString(trigger.custom_trigger_args))+" "),_c('v-icon',{attrs:{"size":"18"},on:{"click":function($event){return _vm.editTriggerArgs(index, trigger.custom_trigger_args)}}},[_vm._v(" settings ")])],1):_c('div',{staticClass:"setting-empty-val"},[_vm._v("N/A")])]):_vm._e(),(!_vm.isStandalone)?_c('td',[(trigger.trigger_type === 'easy_button')?_c('v-icon',{attrs:{"color":"success","size":"16"}},[_vm._v("fas fa-check")]):_c('v-icon',{attrs:{"size":"16","color":"#dddddd"}},[_vm._v("check_box_outline_blank")])],1):_vm._e(),(_vm.currApp?.flags?.scheduled_push)?[_c('td',{staticClass:"tbl-col-tr"},[_vm._v(_vm._s(trigger.event_name))]),_c('td',{staticClass:"tbl-col-tr"},[_vm._v(" "+_vm._s(_vm.objectToString(trigger.event_attributes))+" ")])]:_vm._e(),_c('td',{staticClass:"triggers_edit_column",attrs:{"align":"right"}},[_c('div',{staticClass:"settings-triggers-tbl-btn"},[_c('v-icon',{staticClass:"edit-trigger-button",staticStyle:{"margin-right":"10px"},attrs:{"color":"accent","size":"18","disabled":_vm.isEditBtnDisabled},on:{"click":function($event){return _vm.editTrigger(trigger)}}},[_vm._v("edit")]),_c('v-icon',{attrs:{"size":"18"},on:{"click":function($event){return _vm.deleteTrigger(trigger)}}},[_vm._v("clear")])],1)])],2)}),0):_c('tbody',[_c('tr',[_c('td',{attrs:{"align":"center","colspan":"9"}},[_c('div',{staticStyle:{"padding":"30px 0"}},[_vm._v(" You don't have any triggers yet. Add a new trigger. ")])])])])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }