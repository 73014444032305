import ApiPaths from "@/apis/ApiPaths";
import { defineStore } from "pinia";
import { ref } from "vue";
import { exceptionWrapper, useTableData } from "./piniaUtils";

export const usePromptsStore = defineStore("prompts", () => {
  const prompts = ref([]);
  const loading = ref(true);
  const error = ref(false);
  const { table, resetPage } = useTableData();

  const getPrompts = (appId, params) =>
    exceptionWrapper(
      async () => {
        prompts.value = await ApiPaths.getPrompts(appId, params);
        table.page = 0;
        table.totalCount = 0;
        table.totalPages = 1;
      },
      { loading, error, toast: true },
    );

  const getPromptsPaged = async (appId, params, next = false) =>
    await exceptionWrapper(
      async () => {
        if (!next) prompts.value = [];
        const { paths, pagination } = await ApiPaths.getPrompts(appId, {
          ...params,
          serialize: "short",
          page: table.page + 1,
          per_page: table.perPage,
        });

        if (next) {
          const promptsFiltered = paths.filter(el => !prompts.value.find(({ id }) => id === el.id));
          prompts.value = [...prompts.value, ...promptsFiltered];
        } else {
          prompts.value = paths;
        }

        table.page = pagination.page;
        table.totalCount = pagination.total_count;
        table.totalPages = pagination.total_pages;
      },
      { loading, error, toast: true },
    );

  const updateLocalPrompt = prompt => {
    if (!prompts.value.length) return;
    const promptIndex = prompts.value.findIndex(({ id }) => id === prompt.id);
    const promptsCopy = [...prompts.value];
    if (promptIndex === -1) return;
    promptsCopy[promptIndex] = { ...prompts.value[promptIndex], ...prompt };
    prompts.value = promptsCopy;
  };

  const bulkUpdate = async (appId, prompts = []) =>
    await exceptionWrapper(
      async () => {
        await ApiPaths.bulkUpdate(appId, prompts);
        prompts.forEach(updateLocalPrompt);
      },
      { toast: true, throw: true },
    );

  const bulkDelete = async (appId, prompts = []) =>
    await exceptionWrapper(
      async () =>
        await ApiPaths.bulkDelete(
          appId,
          prompts.map(({ id }) => ({ id })),
        ),
      { toast: true, throw: true },
    );

  return {
    prompts,
    loading,
    error,
    getPrompts,
    getPromptsPaged,
    table,
    resetPage,
    updateLocalPrompt,
    bulkUpdate,
    bulkDelete,
  };
});
