<template>
  <div>
    <RfSimpleCard title="Sales Admin" style="margin-bottom: 0; padding-bottom: 1rem">
      <div style="position: relative">
        <v-btn
          color="success"
          @click="triggerSampleData"
        >
          <v-icon left>add</v-icon>Generate Sample Data
        </v-btn>
      </div>
    </RfSimpleCard>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfSimpleCard from "@/components/RfSimpleCard.vue";

export default {
  name: "RfSalesAdmin",
  props: ["model"],
  components: {
    RfSimpleCard,
  },
  computed: {
    ...mapState({
      apps: state => state.apps.apps,
      currUser: state => state.apps.currUser,
    }),
  },
  methods: {
    ...mapActions(["createSampleData"]),
    triggerSampleData() {
      this.createSampleData({ appId: this.model.id });
    },
  },
  watch: {
    apps(to, _) {
      if (this.deletingApp) {
        if (to.length > 0) {
          this.$router.push({ path: `/apps/${to[0].id}/dashboard` });
        } else {
          this.$router.push({ path: "/" });
        }
      }
    },
  },
};
</script>

<style></style>
