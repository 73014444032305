<template>
  <v-dialog scrollable persistent v-model="orderListDialog" max-width="640">
    <v-card>
      <v-card-title class="headline"> Specify order of items within this placement </v-card-title>
      <v-card-text class="promotion-priority-div">
        <div class="list-group-header">
          <div class="promotion-priority-col-0">
            <span></span>
          </div>
          <div class="promotion-priority-col-1">
            <span>Order</span>
          </div>
          <div class="promotion-priority-col-2 flex justify-between">
            <span>Item</span>
            <v-checkbox
              class="mr-0 mt-0 pt-0"
              v-model="removeEnded"
              label="Active prompts only"
              dark
              dense
              hide-details
            ></v-checkbox>
          </div>
        </div>
        <draggable
          tag="div"
          v-model="orderedPaths"
          v-bind="dragOptions"
          @start="isDragging = true"
          @end="isDragging = false"
          class="priority-list-wrapper"
        >
          <transition-group tag="div" type="transition" name="flip-list">
            <template v-for="(element, index) in orderedPaths">
              <span
                v-if="!removeEnded || (element.is_enabled && !isPromptExpired(element.end_date))"
                class="list-group-item"
                :key="element.id"
              >
                <span class="promotion-priority-col-0">
                  <v-icon small>drag_indicator</v-icon>
                </span>
                <span class="promotion-priority-col-1">
                  <span>{{ index + 1 }}</span>
                </span>
                <span class="promotion-priority-col-2">
                  <span @click="showRetention(element)">{{ element.name }}</span>
                </span>
              </span>
            </template>
          </transition-group>
        </draggable>
      </v-card-text>
      <v-card-actions class="modal-card-actions-div">
        <v-btn
          large
          depressed
          class="cancel-btn"
          outlined
          @click="$emit('closeOrderListDialog')"
          style="width: 100px"
          >Close</v-btn
        >
        <v-btn large depressed color="primary" style="width: 170px" @click="updateOrderListClick">
          <v-icon left>save</v-icon>Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapState } from "vuex";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfPlacementsOrder",
  props: ["retentions", "orderListDialog", "currRetention"],
  setup: () => ({ toastsStore: useToastsStore() }),
  components: { draggable },
  data: () => ({ currentRetentions: [], removeEnded: false }),
  computed: {
    ...mapState({ currApp: state => cloneDeep(state.apps.currApp) }),
    orderedPaths: {
      get() {
        return this.removeEnded
          ? [...this.currentRetentions].sort((a, b) => {
              const aEnabled = a.is_enabled && !this.isPromptExpired(a.end_date);
              const bEnabled = b.is_enabled && !this.isPromptExpired(b.end_date);
              if (aEnabled === bEnabled) return 0;
              return aEnabled ? -1 : 1;
            })
          : this.currentRetentions;
      },
      set(value) {
        this.currentRetentions = value.map((item, index) => ({ ...item, order: index }));
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    showRetention(retention) {
      this.$emit("closeOrderListDialog");
      this.$router.push({
        path: `/apps/${this.$route.params.aid}/retentions/${retention.id}`,
      });
    },
    async updateOrderListClick() {
      try {
        await this.setOrderRetentions({
          appId: this.currApp.id,
          paths: this.currentRetentions,
        });
        this.$emit("closeOrderListDialog");
        this.$forceUpdate();
      } catch (error) {
        this.toastsStore.create({ type: "error", body: `${error}. Please try again.` });
      }
    },
    isPromptExpired: endDate => +new Date(endDate) < +new Date(),
    ...mapActions(["setOrderRetentions"]),
  },
  watch: {
    orderListDialog(v) {
      this.currentRetentions = v
        ? (cloneDeep(this.retentions) || []).sort((a, b) => a.order - b.order)
        : (this.currentRetentions = []);
    },
  },
};
</script>
