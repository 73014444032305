export const GUIDE_TYPES = {
  web_client: {
    value: "web_client",
    label: "Wizard",
    description: "A step-by-step guide where prompts are shown one after the other",
  },
  ordered: {
    value: "ordered",
    label: "Journey",
    description:
      "A guide where prompts are shown in order over time (ex. visits, events, or devices)",
  },
  unordered: {
    value: "unordered",
    label: "Triggered",
    description: "Prompt are shown in the order they are triggered",
  },
  survey: {
    value: "survey",
    label: "Survey",
    description: "A multiple choice prompt followed by other prompts based on the selection",
  },
};
