<template>
  <div
    class="modal-privacy-tos"
    v-html="actions.rf_settings_privacy_policy_text"
    :style="{ color: this.actions.rf_settings_fill_color }"
  ></div>
</template>

<script>
export default {
  name: "RfRetentionLinksPreview",
  props: ["actions"],
};
</script>
