<template>
  <div>
    <div>
      <v-checkbox
        v-model="actions.rf_settings_permanent_display"
        label="Always visible (does not dismiss)"
        value="true"
        class="promotion-closeBtn-checkbox"
      />
      <v-checkbox
        v-model="actions.rf_settings_close_button_enabled"
        label="Allow close"
        value="true"
        class="promotion-closeBtn-checkbox"
        v-if="!isSticky"
      />
    </div>
    <div v-if="actions.rf_settings_close_button_enabled">
      <div style="padding-bottom: 20px">
        <RfPromoInteractionInterval
          interactionType="dismiss"
          :action="actions.rf_settings_dismiss_interval"
          v-on:setIntervalAction="setIntervalAction"
          v-if="actions.rf_settings_close_button_enabled && !isSticky"
        />
      </div>
      <RfRetentionActionsBehavior v-if="!isSticky" :model="model" :actions="actions" displayOff />
    </div>
    <span class="gray-text inline-block pt-2">{{ stickyHelpText }}</span>
  </div>
</template>

<script>
import RfPromoInteractionInterval from "@/components/RfPromotions/RfPromoInteractionInterval.vue";
import RfRetentionActionsBehavior from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsBehavior.vue";

export default {
  name: "RfTileContainerOptions",
  props: ["model", "actions"],
  components: {
    RfPromoInteractionInterval,
    RfRetentionActionsBehavior,
  },
  methods: {
    setIntervalAction(value, type) {
      this.actions[`rf_settings_${type}_interval`] = value;
    },
  },
  computed: {
    isSticky() {
      return this.actions.rf_settings_permanent_display === "true";
    },
    stickyHelpText() {
      if (this.isSticky) {
        return "Only primary action is possible.";
      }
      return "Only primary, dismiss, and timeout actions are possible.";
    },
  },
};
</script>
