<template>
  <div class="rf-category-pg-wrapper prompts-list--wrapper">
    <div>
      <div class="all-segments-list-rightcol nodata-rightcol">
        <div class="rf-detail-content-wrapper retention-detail-container" v-if="sequence">
          <RfLimitWarning />
          <div class="detail-itempg-title-div">
            <div class="detail-itempg-title-div-left">
              <h3>
                {{ sequence.name }}
                <RfButton
                  icon="edit"
                  color="accent"
                  icon-size="16"
                  class="ml-1"
                  x-small
                  :tooltip="{ bottom: true }"
                  :disabled="isDisabledRoleMixin"
                  @click="showInfoEditor"
                />
              </h3>
              <span class="detail-itempg-title-desc">{{ sequence.description }}</span>
            </div>
            <div class="inline-flex">
              <RfButton
                v-if="sequence.sequence_type === GUIDE_TYPES.web_client.value"
                button-text="Live preview"
                icon="open_in_new"
                outlined
                small
                :disabled="isDisabledRoleMixin"
                :tooltip="{ bottom: true }"
                color="info"
                @click="livePreviewShow = true"
                class="mr-2"
              />
              <RfDomainChooserWrapper
                :app="app"
                :visible="livePreviewShow"
                v-on:open="openLivePreview"
                v-on:close="livePreviewShow = false"
              />
              <RfButton
                button-text="Clone"
                icon="content_copy"
                outlined
                small
                :disabled="isDisabledRoleMixin"
                :tooltip="{ bottom: true }"
                color="info"
                @click="submitClone"
                class="mr-2"
              />
              <v-dialog v-model="showDialog" persistent width="500">
                <template v-slot:activator="{ attrs, on }">
                  <RfButton
                    v-bind="attrs"
                    v-on="on"
                    icon="clear"
                    button-text="Delete"
                    outlined
                    small
                    :disabled="isDisabledRoleMixin"
                    :tooltip="{ bottom: true }"
                    color="error"
                  />
                </template>
                <v-card>
                  <v-card-title class="headline">Are you sure you want to delete?</v-card-title>
                  <v-card-text
                    >Deleting this Guide removes all information about this Guide. This cannot be
                    undone. Deleting a guide will also delete all of its items.
                  </v-card-text>
                  <v-card-actions class="modal-card-actions-div">
                    <v-btn
                      depressed
                      class="cancel-btn"
                      outlined
                      @click="showDialog = false"
                      style="width: 100px"
                      >Cancel</v-btn
                    >
                    <v-btn depressed color="error" @click="submitDelete" style="width: 150px"
                      >Delete</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <v-card class="promo-hero-div">
            <div class="seq-view-hero-div">
              <div class="promo-hero-col-wrapper seq-detail-info-display" v-if="sequence">
                <div class="promo-hero-col left-col">
                  <RfPromoDetailsInfoRow title="Show Prompts">
                    <template v-slot:promodetail>
                      <div>
                        {{ displaySequenceType }}
                      </div>
                    </template>
                  </RfPromoDetailsInfoRow>
                  <RfPromoSegmentsInfo :path="sequence" :editDisabled="isDisabledRoleMixin" />
                  <RfPromoLimitsInfo :item="sequence" :editDisabled="isDisabledRoleMixin" />
                  <RfPromoDetailsInfoRow
                    v-if="sequence.sequence_type === GUIDE_TYPES.ordered.value"
                    title="Loop"
                  >
                    <template #promodetail>
                      <v-checkbox
                        v-model="loop"
                        label="Loop"
                        hide-details
                        class="promo-hero-col--loop"
                        dense
                      />
                    </template>
                  </RfPromoDetailsInfoRow>
                  <RfPromoDetailsInfoRow title="Triggers" v-if="isWebClient && firstPrompt">
                    <template #promodetail>
                      Inherited from
                      <router-link :to="firstPromptLink">{{ firstPrompt.name }}</router-link>
                    </template>
                  </RfPromoDetailsInfoRow>
                </div>
                <div class="promo-hero-col right-col-col-1">
                  <RfStateButtons :item="sequence" :editDisabled="isDisabledRoleMixin" />
                  <RfPromoScheduleInfo :item="sequence" :editDisabled="isDisabledRoleMixin" />
                </div>
              </div>
              <div class="placement-stats-full-width" v-if="sequence">
                <div class="chart-filter-date-select">
                  <div class="chart-filter-label">Date range:</div>
                  <RfMetricsDateRangeSelector
                    parentPage="sequences"
                    v-on:fetchMetrics="fetchMetrics"
                    v-on:fetchMetricsCustom="fetchMetricsCustom"
                    :key="sequence.id"
                  />
                </div>
                <RfSingleSequenceStats
                  v-if="
                    [
                      GUIDE_TYPES.web_client.value,
                      GUIDE_TYPES.ordered.value,
                      GUIDE_TYPES.survey.value,
                    ].includes(sequence?.sequence_type)
                  "
                  :model="sequence.paths"
                  :metricsKey="currChartSrc"
                  :singleDayCustom="singleDayCustom"
                  :isSurvey="sequence.sequence_type === GUIDE_TYPES.survey.value"
                />
                <RfSinglePromoStats
                  v-else
                  :model="sequence.paths"
                  :metricsKey="currChartSrc"
                  :singleDayCustom="singleDayCustom"
                />
              </div>
            </div>
          </v-card>
          <div class="seq-promos-header all-promotions-header">
            <h2>Items</h2>
            <div class="seq-add-promo-div" v-if="paths.length">
              <v-btn
                depressed
                class="mr-2"
                text
                color="primary"
                @click="exportMultiplePromptToCsv(paths, currChartSrc)"
              >
                Export All to CSV
              </v-btn>
              <RfButton
                icon="import_export"
                :button-text="orderCTA"
                :disabled="isDisabledRoleMixin || !paths.length"
                color="primary"
                @click="showPathsEditor"
              />
              <RfButton
                class="seqall-add-item seq-add-items-button"
                icon="add"
                button-text="Add Item"
                :disabled="isDisabledRoleMixin"
                color="success"
                @click="showPromoDeviceTypeDialog"
              />
            </div>
          </div>
          <div v-if="sequence">
            <div class="sq-promos-list" v-if="sequence.paths.length">
              <div
                :class="itemsListClassName"
                v-for="(item, index) in sequence.paths"
                :key="item.id"
              >
                <span class="seq-item-number" v-if="isOrdered">{{ index + 1 }}</span>

                <div class="sq-promos-item-wrapper">
                  <div class="sq-promos-item-inner promo-list-view-wrapper">
                    <RfPromotionsListViewItem
                      v-if="getPathObject(item)"
                      :item="getPathObject(item, true)"
                      :currChartSrc="currChartSrc"
                      preprocessedGoals
                      hideSegment
                      hideSchedule
                      hideSequence
                      hideStatus
                      fromSequence
                    />
                  </div>
                  <div class="sq-promos-item-wrapper-ft">
                    <div class="sq-promos-item--ft-left">
                      <div class="pr-ctnd-cond" v-if="canContinue(index)">
                        <label>
                          <v-icon color="success" left>
                            {{ index === sequence.paths.length - 1 ? "refresh" : "arrow_downward" }}
                          </v-icon>
                          {{ index === sequence.paths.length - 1 ? "Loops" : "Continues" }}
                          <span class="interval-display">{{ intervalDisplay(item) }}</span> when:
                        </label>
                        <RfSequencePathsDisplayCell
                          :interactionTypes="item.continue_interaction_types"
                          :isContinue="true"
                          :path="getPathObject(item)"
                        />
                        <RfButton
                          icon="edit"
                          color="accent"
                          icon-size="16"
                          x-small
                          :disabled="isDisabledRoleMixin"
                          class="edit-path-sequence-button"
                          @click="editPath(index)"
                        />
                      </div>
                      <div class="pr-exit-cond">
                        <label><v-icon color="#3096ED" left>exit_to_app</v-icon>Exits when:</label>
                        <RfSequencePathsDisplayCell
                          :interactionTypes="item.interaction_types"
                          :path="getPathObject(item)"
                        />
                        <RfButton
                          icon="edit"
                          color="accent"
                          icon-size="16"
                          x-small
                          :disabled="isDisabledRoleMixin"
                          class="edit-path-sequence-button"
                          @click="editPath(index)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="no-item-placement-view">
                <div class="no-msg">
                  <span class="no-msg-copy">No items in this guide</span>
                  <RfButton
                    class="seq-add-items-button"
                    icon="add"
                    button-text="Add Item"
                    large
                    :disabled="isDisabledRoleMixin"
                    color="success"
                    @click="showPromoDeviceTypeDialog"
                  />
                </div>
              </div>
            </div>
          </div>
          <v-dialog v-model="infoEditor" width="500">
            <v-card>
              <v-card-title>Guide Name and Description</v-card-title>
              <v-card-text>
                <RfInfo :model="sequence" ref="info" />
              </v-card-text>
              <v-card-actions class="modal-card-actions-div">
                <v-btn
                  large
                  depressed
                  class="cancel-btn"
                  outlined
                  width="100px"
                  @click="closeInfoEditor"
                  >Close</v-btn
                >
                <v-btn large @click="saveInfo" width="200px" color="primary" depressed>
                  <v-icon small left>save</v-icon>Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="pathsEditor" max-width="900" persistent>
            <v-card>
              <v-card-title>Manage Items</v-card-title>
              <v-card-text v-if="pathsEditor">
                <div style="margin-bottom: 16px" v-if="isOrdered">
                  <span>Drag and drop to change order</span>
                </div>
                <RfSequencePaths :sequence="sequence" ref="paths" />
              </v-card-text>
              <v-card-actions class="modal-card-actions-div">
                <v-btn
                  large
                  depressed
                  class="cancel-btn"
                  outlined
                  width="100px"
                  @click="closePathsEditor"
                  >Close</v-btn
                >
                <v-btn large @click="savePaths" width="200px" color="success" depressed>
                  <v-icon small left>save</v-icon>Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <RfSequenceAddPath
            :sequence="sequence"
            ref="conditionEditor"
            :addPromoModule="addPromoModule"
            v-on:hideAddPromoModule="hideAddPromoModule"
            v-on:addPath="addPath"
            :newPathSequenceId="newPathSequenceId"
            :newPathInteractions="newPathInteractions"
            :newPathContinueInteractions="newPathContinueInteractions"
            :newPathContinueInterval="newPathContinueInterval"
            :editIndex="editIndex"
            :isEditing="true"
            v-if="addPromoModule"
          />
          <RfPromotionCreator
            :pathGroups="pathGroups"
            :promoDeviceTypeDialog="promoDeviceTypeDialog"
            :sequenceId="sequence.id"
            v-on:closePromoDeviceTypeDialog="closePromoDeviceTypeDialog"
            :sequenceOrder="sequence.paths.length"
            :overlayOnlyModal="isWebClient"
            :webOnly="isWebClient"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RfInfo from "@/components/RfCommonCards/RfInfo.vue";
import RfSequencePaths from "@/components/RfSequences/RfSequencePaths.vue";
import RfSinglePromoStats from "@/components/RfPromotions/RfSinglePromoStats.vue";
import RfSingleSequenceStats from "@/components/RfPromotions/RfSingleSequenceStats.vue";
import RfPromoSegmentsInfo from "@/components/RfPromotions/RfPromoSegmentsInfo.vue";
import RfPromoLimitsInfo from "@/components/RfPromotions/RfPromoLimitsInfo.vue";
import RfPromoScheduleInfo from "@/components/RfPromotions/RfPromoScheduleInfo.vue";
import RfStateButtons from "@/components/RfPromotions/RfStateButtons.vue";
import RfSequencePathsDisplayCell from "@/components/RfSequences/RfSequencePathsDisplayCell.vue";
import RfSequenceAddPath from "@/components/RfSequences/RfSequenceAddPath.vue";
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfPromotionCreator from "@/components/RfPromotions/RfPromotionCreator.vue";
import PromoTypeMixin from "@/utils/PromoTypeMixin";
import SequenceMixin from "@/utils/SequenceMixin";
import MetricsMixin from "@/utils/MetricsMixin";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import RfLimitWarning from "@/components/RfCommonCards/RfLimitWarning.vue";
import RfPromotionsListViewItem from "@/components/RfPromotions/RfPromotionsListViewItem.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import { cloneDeep } from "lodash-es";
import RfMetricsDateRangeSelector from "@/components/RfCommonCards/RfMetricsDateRangeSelector.vue";
import LiveMixin from "@/utils/LiveMixin";
import RfDomainChooserWrapper from "@/components/RfCommonCards/RfDomainChooserWrapper.vue";
import { GUIDE_TYPES } from "@/utils/constants/GuidesConstants";
import { tally } from "@/utils/metricsHelpers";
import { useToastsStore } from "@/pinia/toastsStore";
import { exportMultiplePromptToCsv } from "@/utils/CsvUtils";

export default {
  name: "RfSequenceView",
  setup: () => ({ toastsStore: useToastsStore(), GUIDE_TYPES, exportMultiplePromptToCsv }),
  components: {
    RfInfo,
    RfSequencePaths,
    RfMetricsDateRangeSelector,
    RfSinglePromoStats,
    RfPromoSegmentsInfo,
    RfPromoLimitsInfo,
    RfPromoScheduleInfo,
    RfStateButtons,
    RfSequencePathsDisplayCell,
    RfSequenceAddPath,
    RfPromoDetailsInfoRow,
    RfPromotionCreator,
    RfLimitWarning,
    RfPromotionsListViewItem,
    RfButton,
    RfDomainChooserWrapper,
    RfSingleSequenceStats,
  },
  mixins: [SequenceMixin, PromoTypeMixin, MetricsMixin, UserSettingsMixin, RoleMixin, LiveMixin],
  data() {
    return {
      showDialog: false,
      infoEditor: false,
      singleDayCustom: false,
      pathsEditor: false,
      paths: [],
      directSave: true,
      sequence: null,
      currChartSrc: null,
      sequenceListKey: 0,
      promoDeviceTypeDialog: false,
      newSequence: {
        name: "",
        description: "",
        paths: [],
        segments: [],
      },
      newSequencePaths: [],
      livePreviewShow: false,
    };
  },
  methods: {
    ...mapActions([
      "getSequence",
      "deleteSequence",
      "updateSequence",
      "getPathGroups",
      "cloneSequence",
    ]),
    async fetchMetrics(timePeriod, force) {
      this.singleDayCustom = false;
      const metric_periods = [{ period: timePeriod }];
      if (
        force ||
        !this.sequence ||
        !this.sequence.paths.find(
          retention => retention.data.impressions && retention.data.impressions[timePeriod],
        )
      ) {
        await this.getSequence({
          appId: this.currApp.id,
          sequenceId: this.sequenceId,
          params: {
            metric_periods,
          },
        });
      }
      this.currChartSrc = timePeriod;
    },
    fetchMetricsCustom(timePeriod) {
      this.singleDayCustom = timePeriod[0] === timePeriod[1];
      this.getSequence({
        appId: this.currApp.id,
        sequenceId: this.sequenceId,
        params: {
          metric_periods: [{ period: "custom", date_range: timePeriod }],
        },
      });
      this.currChartSrc = "custom";
    },
    submitDelete() {
      this.deleteSequence({ appId: this.appId, sequenceId: this.sequenceId })
        .then(() => {
          this.$router.replace({ path: `/apps/${this.appId}/experiences` });
          this.showDialog = false;
        })
        .catch(() => null);
    },
    showInfoEditor() {
      this.infoEditor = true;
    },
    closeInfoEditor() {
      this.infoEditor = false;
    },
    showPathsEditor() {
      this.pathsEditor = true;
    },
    closePathsEditor() {
      this.pathsEditor = false;
    },
    async savePaths() {
      const model = cloneDeep(this.sequence);
      this.$refs.paths.fillModel(model);
      await this.saveSequence(model);
      this.closePathsEditor();
      this.computeKey++;
    },
    async saveInfo() {
      const model = cloneDeep(this.sequence);
      this.$refs.info.fillModel(model);
      await this.saveSequence(model);
      this.closeInfoEditor();
    },
    saveConditions() {
      const model = cloneDeep(this.sequence);
      model.paths = this.paths;
      this.saveSequence(model);
    },
    async saveSequence(model) {
      await this.updateSequence({
        appId: this.appId,
        sequenceId: this.sequence.id,
        modelSequence: model,
      })
        .then(() =>
          this.toastsStore.create({ type: "success", body: "Guide updated successfully" }),
        )
        .catch(() => null);
    },
    getPathObject(item, sumGoals) {
      if (!sumGoals || !item.custom_filter) return item;

      if (Object.keys(item.custom_filter).length > 0) {
        item.data.totalGoals = tally(item.data, this.currChartSrc, "custom_goals_seen");
      } else {
        item.data.totalGoals = tally(item.data, this.currChartSrc, "goals");
      }

      return item;
    },
    async showPromoDeviceTypeDialog() {
      if (!this.pathGroups.length) await this.getPathGroups({ appId: this.appId });
      this.promoDeviceTypeDialog = true;
    },
    closePromoDeviceTypeDialog() {
      this.promoDeviceTypeDialog = false;
    },

    async executeMount() {
      let sequenceId = this.sequenceId;

      const path = `/apps/${this.appId}/experiences/${sequenceId}`;
      if (this.$route.path === path) return this.getSequence({ appId: this.appId, sequenceId });

      return this.$router.replace({ path: `/apps/${this.appId}/experiences/${sequenceId}` });
    },
    intervalDisplay(item) {
      let interval = item.continue_interval_minutes;
      if (interval === "VISIT") {
        return "on next visit";
      }
      interval = parseInt(interval);
      if (!interval) return "immediately";

      let unit = "minutes";
      let number = interval;
      if (interval % 1440 === 0) {
        unit = "days";
        number = interval / 1440;
      } else if (interval % 60 === 0) {
        unit = "hours";
        number = interval / 60;
      }
      return `after ${number} ${unit}`;
    },
    canContinue(index) {
      if (this.isOrdered && index < this.sequence.paths.length - 1) return true;
      if (this.loop) return true;
    },
    submitClone() {
      this.cloning = true;
      this.cloneSequence({
        appId: this.$route.params.aid,
        sequenceId: this.sequence.id,
      });
    },
    openLivePreview(domain) {
      this.viewPreview(domain, { sequenceId: this.sequence.id });
      this.livePreviewShow = false;
    },
  },
  computed: {
    ...mapState({
      app: state => state.apps.currApp,
      currSequence: state => state.apps.currSequence,
      lastError: state => state.lastError,
      pathGroups: state => state.apps.pathGroups,
    }),
    isOrdered() {
      return [GUIDE_TYPES.web_client.value, GUIDE_TYPES.ordered.value].includes(this.sequenceType);
    },
    loop: {
      get() {
        return !!this.sequence?.filter?.loop;
      },
      set(value) {
        const model = cloneDeep(this.sequence);
        model.filter.loop = value;
        this.saveSequence(model);
      },
    },
    itemsListClassName() {
      return this.isOrdered ? "sq-promos-list-item" : "sq-promos-list-item unordered--list";
    },
    orderCTA() {
      return this.isOrdered ? "Order/Delete" : "Delete Items";
    },
    appId() {
      return this.$route.params.aid;
    },
    sequenceId() {
      return this.$route.params.sid;
    },
    sequenceType() {
      return this.sequence?.sequence_type;
    },
    isWebClient() {
      return this.sequenceType === GUIDE_TYPES.web_client.value;
    },
    displaySequenceType() {
      return GUIDE_TYPES[this.sequenceType]?.label || GUIDE_TYPES.survey.label;
    },
    firstPrompt() {
      return this.currSequence?.paths?.[0];
    },
    firstPromptLink() {
      if (!this.firstPrompt) return {};
      return {
        path: `/apps/${this.$route.params.aid}/experiences/${this.currSequence.id}/promotions/${this.firstPrompt.id}`,
      };
    },
  },
  mounted() {
    this.executeMount();
  },
  watch: {
    async $route(to) {
      const appId = to.params.aid;
      const sequenceId = to.params.sid;
      if (sequenceId === undefined) {
        this.sequence = null;
        this.sequenceListKey++;
      } else {
        this.getSequence({ appId, sequenceId });
      }
    },
    currSequence(to) {
      if (!to) return;
      if (this.cloning) {
        this.$router.replace({
          path: `/apps/${this.$route.params.aid}/experiences/${to.id}`,
        });
        this.cloning = false;
      } else {
        this.sequence = to;
        if (to.paths) this.paths = to.paths;
      }
    },
    async lastError(to) {
      if (!to) return;
      if (to.cause === 404) {
        this.$router.push({ path: `/apps/${this.appId}/experiences` });
      } else {
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.promo-hero-col--loop {
  padding-top: 0px !important;
  margin-top: 0px !important;
  .v-label {
    color: #000;
    font-size: 14px;
  }
}
</style>
