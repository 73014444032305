export const BreakpointsConstants = {
  "sm": { raw: 640, px: "640px", key: "sm" },
  "md": { raw: 768, px: "768px", key: "md" },
  "lg": { raw: 1024, px: "1024px", key: "lg" },
  "xl": { raw: 1280, px: "1280px", key: "xl" },
  "2xl": { raw: 1400, px: "1400px", key: "2xl" },
  "3xl": { raw: 1600, px: "1600px", key: "3xl" },
  "4xl": { raw: 1700, px: "1700px", key: "4xl" },
  "5xl": { raw: 1800, px: "1800px", key: "5xl" },
};

export const RawBreakpoints = Object.fromEntries(
  Object.entries(BreakpointsConstants).map(([key, value]) => [key, value.raw]),
);
export const PxBreakpoints = Object.fromEntries(
  Object.entries(BreakpointsConstants).map(([key, value]) => [key, value.px]),
);
