export const AppColors = [
  "#1E5AAE",
  "#4887E0",
  "#146BB2",
  "#4D4D4D",
  "#666666",
  "#A56186",
  "#B57D9C",
  "#508747",
  "#67AD5B",
];

export const chartColors = [
  "#0F2D57",
  "#164383",
  "#1E5AAE",
  "#4887E0",
  "#7CA9E9",
  "#A8C6F0",
  "#D3E2F8",
  "#1A1A1A",
  "#333333",
  "#4D4D4D",
  "#666666",
  "#999999",
  "#CCCCCC",
  "#E6E6E6",
  "#412534",
  "#61384F",
  "#A56186",
  "#B57D9C",
  "#C79EB5",
  "#DABECD",
  "#ECDFE6",
];

export const tagsColors = [
  "#F2F6F9",
  "#FFFFFF",
  "#E7F3FE",
  "#D0E2FA",
  "#A3C3EF",
  "#FDF6EB",
  "#FFDDEF",
];
