<template>
  <div :style="customPlaceholderStyle">
    <Transition mode="out-in">
      <GChart
        v-if="formattedMetricsData"
        :data="formattedMetricsData"
        :options="customFormatOptions || formatComboChartOptions"
        :create-chart="createComboChart"
      />
      <div v-else class="rf-segment-chart-loader">
        <div class="rf-segment-chart-loader--chart">
          <div
            v-for="i in 7"
            :key="i"
            class="rf-segment-chart-loader--bar"
            :style="{ 'animation-delay': `${Math.round(Math.random() * 1000 - 500)}ms` }"
          ></div>
        </div>
        <div class="rf-segment-chart-loader--text">loading</div>
      </div>
    </Transition>
  </div>
</template>

<script>
import MetricsUtils from "@/utils/MetricsUtils";
import { createComboChart } from "@/utils/vueGoogleChartUtils";

export default {
  name: "RfSegmentChart",
  props: [
    "metrics",
    "metricsKey",
    "compareMetricsKey",
    "usage_type",
    "singleDayCustom",
    "customFormatOptions",
  ],
  setup: () => ({ createComboChart }),
  computed: {
    metricName() {
      if (this.hasMetricsData) {
        return this.metrics[this.metricsKey].name;
      }
      return "";
    },
    hasMetricsData() {
      if (!this.metrics || !this.metricsKey || !this.metrics[this.metricsKey]) {
        return false;
      }
      return true;
    },

    hasMetricValues() {
      return this.metrics[this.metricsKey].data.find(item => item.count !== null && item.count > 0);
    },

    formattedMetricsData() {
      if (!this.hasMetricsData) return null;
      return this.formattedWeeklyComboChart;
    },
    isValidComparison() {
      if (
        MetricsUtils.Comparisons[this.metricsKey] &&
        this.compareMetricsKey === MetricsUtils.Comparisons[this.metricsKey]
      )
        return true;

      return false;
    },
    getMaxChartValue() {
      let compareMetrics = null;
      let compareMetricValues = [];
      if (this.isValidComparison) {
        compareMetrics = this.metrics[this.compareMetricsKey];
        compareMetricValues = compareMetrics.data.map(item => item.count);
      }
      const currentMetric = this.metrics[this.metricsKey];
      const currentMetricValues = currentMetric.data.map(item => item.count);

      const chartCeilingRounder = 10;
      let value = Math.max(...currentMetricValues, ...compareMetricValues, chartCeilingRounder);
      if (this.usage_type === "minutes") {
        value /= 60;
      }
      return Math.ceil(value / chartCeilingRounder) * chartCeilingRounder;
    },
    formattedWeeklyComboChart() {
      const currentMetric = this.metrics[this.metricsKey];
      const timezone = 0; // this.metrics.tz_offset;
      const maxChartValue = this.getMaxChartValue;
      let horizontalLabels = currentMetric.data.map(item =>
        MetricsUtils.metricDateTimeFormatter(
          this.metricsKey,
          item.date,
          timezone,
          null,
          this.singleDayCustom,
        ),
      );

      if (this.metricsKey === "today" || this.metricsKey === "yesterday" || this.singleDayCustom) {
        horizontalLabels = horizontalLabels.map((item, index) => {
          const isTimeShown = (index + 1) % 4 === 1;
          return isTimeShown ? item : "";
        });
      }
      let denom = 1;
      if (this.usage_type === "minutes") {
        denom = 60;
      }
      let data = currentMetric.data.map(item => item.count / denom);
      if (data.every(cell => cell === null)) {
        return null;
      }
      let compareData = [];
      if (this.isValidComparison) {
        const compareMetric = this.metrics[this.compareMetricsKey];
        compareData = compareMetric.data.map(item => item.count / denom);
      }

      data = this.standardData(data, horizontalLabels);

      if (this.metricsKey === "today" && this.hasMetricValues) {
        data = this.equalizer(data, maxChartValue);
      }

      if (this.isValidComparison) {
        data = this.addCompareData(data, compareData);
      }
      return data;
    },
    formatComboChartOptions() {
      if (!this.hasMetricsData) return {};

      const opts = {
        width: "100%",
        height: 350,
        tooltip: {
          isHtml: true,
          textStyle: {
            bold: false,
            fontSize: 13,
          },
        },
        animation: {
          duration: 1000,
          easing: "inAndOut",
          startup: true,
        },
        backgroundColor: { fill: "transparent" },
        colors: ["#70B9F7"],
        lineDashStyle: [4, 4],
        pointSize: 10,
        vAxis: {
          title: `Number of ${this.usage_type}`,
          format: "short",
          viewWindow: {
            min: 0,
            max: this.getMaxChartValue,
          },
          baselineColor: "#cccccc",
          gridlines: {
            color: "#eee",
            count: 4,
          },
          minorGridlines: {
            color: "#eeeeee",
            count: 0,
          },
        },
        bar: { groupWidth: "80%" },
        seriesType: "bars",
        series: {},
        isStacked: true,
        chartArea: {
          left: 70,
          top: 25,
          width: "97%",
          height: "82%",
        },
      };

      if (this.metricsKey === "this_month") {
        opts.hAxis = { maxAlternation: 1, showTextEvery: 2 };
      }

      if (this.isValidComparison) {
        if (this.metricsKey === "today" && this.hasMetricValues) {
          const metricsData = this.formattedWeeklyComboChart;
          const lineDataColumn = metricsData[0].length / 2 - 1;
          opts.series[lineDataColumn] = { type: "line" };
        } else {
          opts.series[1] = { type: "line" };
        }

        opts.colors.push("#333");
      }
      return opts;
    },
    width() {
      if (this.customFormatOptions && this.customFormatOptions.width)
        return this.customFormatOptions.width;
      if (this.formatComboChartOptions && this.formatComboChartOptions.width)
        return this.formatComboChartOptions.width;
      return "100%";
    },
    height() {
      if (this.customFormatOptions && this.customFormatOptions.height)
        return this.customFormatOptions.height;
      if (this.formatComboChartOptions && this.formatComboChartOptions.height)
        return this.formatComboChartOptions.height;
      return 350;
    },
    customPlaceholderStyle() {
      const style = {};
      if (Number.isNaN(this.width)) {
        style.width = this.width;
      } else {
        style.width = `${this.width}px`;
      }
      if (Number.isNaN(this.height)) {
        style.height = this.height;
      } else {
        style.height = `${this.height}px`;
      }
      return style;
    },
  },
  methods: {
    addCompareData(data, compareData) {
      return data.map((item, index) => {
        if (index === 0) return [...item, ""];
        return [...item, compareData[index - 1]];
      });
    },
    standardData(data, horizontalLabels) {
      const tempArr = data.map((item, index) => {
        const tempItem = [item];
        if (horizontalLabels) {
          tempItem.unshift(horizontalLabels[index]);
        }
        return tempItem;
      });
      const labels = tempArr[0].map(() => "");
      tempArr.unshift(labels);

      return tempArr;
    },
    equalizer(data, chartHeight) {
      const columnBars = chartHeight / 40;
      const barWidth = columnBars;
      const blankBarWidth = columnBars / 2;

      let equalizeColumnIndex = -1;

      for (let i = data.length - 1; i > 0; i--) {
        if (data[i][1] > 0) {
          if (data[i][1] > chartHeight / 100) {
            equalizeColumnIndex = i;
          }
          break;
        }
      }

      const equalizedData = data.map((item, index) => {
        if (equalizeColumnIndex === index) {
          let tempCount = item[1];
          const tempArr = [item[0]];

          while (tempCount > 0) {
            tempCount -= barWidth + blankBarWidth;
            const fillColor = "fill-color:#107fcd";
            tempArr.push(barWidth, fillColor);
            if (tempCount > 0) {
              tempArr.push(blankBarWidth, "fill-color: #ffffff");
            }
          }
          return tempArr;
        }
        return [...item, index === 0 ? { role: "style" } : "fill-color:#107fcd"];
      });

      const maxLenColumn = Math.max(...equalizedData.map(item => item.length));

      const standardizedData = equalizedData.map((row, index) => {
        const emptyColumns = maxLenColumn - row.length;
        let arr = [];
        if (index === 0) {
          arr = new Array(emptyColumns)
            .fill("")
            .map((_, i) => (i % 2 === 0 ? "" : { role: "style" }));
        } else {
          arr = new Array(emptyColumns).fill("").map((_, i) => (i % 2 === 0 ? null : ""));
        }
        return [...row, ...arr];
      });
      return standardizedData;
    },
  },
};
</script>

<style lang="scss">
.google-visualization-tooltip-item {
  white-space: nowrap;
}

.rf-segment-chart-loader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px 0;

  &--chart {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 0 2px;
  }
  &--bar {
    width: 20px;
    height: 50px;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: #afbabf;
      animation: chartLoading 1s ease-in-out infinite alternate forwards;
      animation-delay: inherit;
      transform: translateY(100%);
    }
  }
  &--text {
    color: #333;
    font-size: 0.75rem;
  }
}

@keyframes chartLoading {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
