<template>
  <div class="promo-limits-div">
    <v-alert width="100%" dense outlined type="warning">{{ warningText }}</v-alert>
    <div class="promotion-limit-section-wrapper">
      <div class="promotion-limit-section">
        <div>
          <v-checkbox v-model="is_limited" hide-details label="Impression Limit"></v-checkbox>
        </div>
        <div class="limit-budget-wrapper" v-if="is_limited">
          <div class="limit-content-opt">
            <div class="budget-type-name">Max number of impressions:</div>
            <v-text-field
              v-model="limit"
              :label="currLimit"
              name="limit"
              :disabled="!is_limited"
              outlined
              dense
              hint="Specify the maximum allowed impressions."
              persistent-hint
              background-color="#fff"
            ></v-text-field>
          </div>
          <div v-if="model.id && is_limited" class="create-promo-limit-wrapper">
            <v-progress-linear
              color="light-blue"
              height="10"
              :value="currLimitPercent"
              striped
            ></v-progress-linear>
            <strong>{{ currLimitPercent }}%</strong>
          </div>
        </div>
      </div>
      <div class="promotion-limit-section frequency-cap">
        <div>
          <v-checkbox v-model="is_capped" hide-details label="Frequency Cap"></v-checkbox>
        </div>
        <div class="limit-budget-wrapper" v-if="is_capped">
          <div class="limit-content-opt">
            <div class="budget-type-name">Type:</div>
            <v-select
              v-model="frequencyCapType"
              outlined
              dense
              :items="[VISIT, DAYS]"
              name="cap_type"
              :disabled="!is_capped"
              hint="Select the type of limit"
              persistent-hint
              background-color="#fff"
            ></v-select>
          </div>
          <div class="limit-content-opt">
            <div class="budget-type-name">Number of times:</div>
            <v-text-field
              v-model="cap"
              name="cap"
              :disabled="!is_capped"
              outlined
              dense
              :hint="freqCapHint"
              persistent-hint
              background-color="#fff"
            ></v-text-field>
          </div>
          <div class="limit-content-opt">
            <div class="budget-type-name" v-if="frequencyCapType === DAYS.value">
              Days to reset:
            </div>
            <v-select
              v-if="frequencyCapType === DAYS.value"
              outlined
              dense
              :items="capSecondsInDaysItems"
              v-model="cap_seconds"
              name="cap_seconds"
              :disabled="!is_capped"
              hint="Specify the number of days to reset the limit."
              persistent-hint
              background-color="#fff"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="promotion-limit-section" v-if="!isSequence">
        <v-checkbox v-model="is_budgeted" hide-details label="Set a Budget"></v-checkbox>
        <div class="limit-budget-wrapper" v-if="is_budgeted">
          <div class="limit-content-opt">
            <div class="budget-type-name">Goal occurrences</div>
            <v-text-field
              v-model="budget"
              :label="currBudget"
              name="limit"
              :disabled="!is_budgeted"
              outlined
              dense
              hint="Specify the max number of goal occurrences for this prompt"
              persistent-hint
              background-color="#fff"
            ></v-text-field>
          </div>
          <div class="limit-content-opt">
            <div class="budget-type-name">Deduction per goal:</div>
            <v-text-field
              v-if="is_budgeted"
              v-model="budget_decrementer"
              name="limit"
              :disabled="!is_budgeted"
              outlined
              dense
              hint="Specify the amount to deduct each successful goal."
              persistent-hint
              background-color="#fff"
            ></v-text-field>
          </div>
          <div v-if="model.id && is_budgeted" class="create-promo-limit-wrapper">
            <v-progress-linear
              color="light-blue"
              height="10"
              :value="currBudgetPercent"
              striped
            ></v-progress-linear>
            <strong>{{ currBudgetPercent }}%</strong>
          </div>
        </div>
      </div>
      <div class="promotion-limit-section">
        <v-checkbox v-model="hasUserLimit" hide-details label="User limit" />
        <div class="limit-budget-wrapper" v-if="hasUserLimit">
          <div class="limit-content-opt">
            <div class="budget-type-name">Max number of users:</div>
            <v-text-field
              v-model="userLimit"
              :label="userLimitText"
              :disabled="!hasUserLimit"
              outlined
              dense
              hint="Specify the maximum allowed users."
              persistent-hint
              background-color="#fff"
            />
          </div>
          <div v-if="model.id && hasUserLimit" class="create-promo-limit-wrapper">
            <v-progress-linear
              color="light-blue"
              height="10"
              :value="userLimitPercent"
              striped
            ></v-progress-linear>
            <strong>{{ userLimitPercent }}%</strong>
          </div>
        </div>
      </div>
      <div class="promotion-limit-section">
        <v-checkbox v-model="hasDeliveryLimit" hide-details label="Delivery limit" />
        <div class="limit-budget-wrapper" v-if="hasDeliveryLimit">
          <div class="limit-content-opt">
            <div class="budget-type-name">Max number of deliveries:</div>
            <v-text-field
              v-model="deliveryLimit"
              :label="deliveryLimitText"
              :disabled="!hasDeliveryLimit"
              outlined
              dense
              hint="Specify the maximum allowed unique deliveries."
              persistent-hint
              background-color="#fff"
            />
          </div>
          <div v-if="model.id && hasDeliveryLimit" class="create-promo-limit-wrapper">
            <v-progress-linear
              color="light-blue"
              height="10"
              :value="deliveryLimitPercent"
              striped
            ></v-progress-linear>
            <strong>{{ deliveryLimitPercent }}%</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DAYS, VISIT } from "@/utils/constants/FrequencyCapConstants";
import { GUIDE_TYPES } from "@/utils/constants/GuidesConstants";

export default {
  name: "RfTraffic",
  props: ["model"],
  setup: () => ({ DAYS, VISIT }),
  data() {
    return {
      limit: this.model.filter.traffic.limit,
      is_limited: this.model.filter.traffic.is_limited,
      curr_limit: this.model.curr_limit,
      curr_budget: this.model.curr_budget,
      budget_decrementer: this.model.filter.supply.budget_decrementer,
      budget: this.model.filter.supply.budget,
      is_budgeted: this.model.filter.supply.is_budgeted,
      cap: this.model.filter.frequency_cap.cap,
      is_capped: this.model.filter.frequency_cap.is_capped,
      cap_seconds: this.model.filter.frequency_cap.cap_seconds,
      is_disabled: !!this.model.last_published_date,
      hasUserLimit: this.model.filter.users.is_limited,
      userLimit: this.model.filter.users.limit,
      currUsers: this.model.curr_users,
      hasDeliveryLimit: this.model.filter.deliveries.is_limited,
      deliveryLimit: this.model.filter.deliveries.limit,
      currDeliveries: this.model.curr_deliveries,
      frequencyCapType:
        this.model.filter.frequency_cap.cap_seconds === VISIT.value ? VISIT.value : DAYS.value,
    };
  },
  computed: {
    warningText() {
      let text = "Modifying limits after a prompt or guide is started may impact performance.";
      if (this.isWebClientSequence)
        text += ` For ${GUIDE_TYPES.web_client.label} guides, limits are enforced for the first prompt only.`;
      return text;
    },
    isSequence() {
      return !!this.model.sequence_type;
    },
    isWebClientSequence() {
      return this.model.sequence_type === "web_client";
    },
    capSecondsInDaysItems() {
      const items = new Array(90).fill().map((_, index) => {
        return { text: `${index + 1}`, value: (index + 1) * 60 * 60 * 24 };
      });
      return items;
    },
    currBudget() {
      if (!this.is_budgeted) return null;
      return `(Remaining Available: ${this.budget - (this.curr_budget || 0)})`;
    },
    currBudgetPercent() {
      const budget = parseInt(this.budget);
      const current = parseInt(this.curr_budget);
      return this.calculatePercent(budget, current);
    },
    currLimit() {
      if (!this.is_limited) return null;
      return `(Remaining Available: ${this.limit - (this.curr_limit || 0)})`;
    },
    currLimitPercent() {
      const limit = parseInt(this.limit);
      const current = parseInt(this.curr_limit);
      return this.calculatePercent(limit, current);
    },
    freqCapHint() {
      if (this.model.subject_name === "Sequence" && this.frequencyCapType === VISIT.value) {
        return "Specify the max number of times a user can be shown all prompts in this guide per visit.";
      } else if (this.model.subject_name === "Sequence") {
        return "Specify the max number of times a user can be shown all prompts in this guide.";
      } else if (this.frequencyCapType === VISIT.value) {
        return "Specify the max number of times a user can be shown this prompt per visit.";
      } else {
        return "Specify the max number of times a user can be shown this prompt.";
      }
    },
    userLimitText() {
      if (!this.hasUserLimit) return null;
      return `(Remaining Available: ${this.userLimit - (this.currUsers || 0)})`;
    },
    deliveryLimitText() {
      if (!this.hasDeliveryLimit) return null;
      return `(Remaining Available: ${this.deliveryLimit - (this.currDeliveries || 0)})`;
    },
    userLimitPercent() {
      const limit = parseInt(this.userLimit);
      const current = parseInt(this.currUsers);
      return this.calculatePercent(limit, current);
    },
    deliveryLimitPercent() {
      const limit = parseInt(this.deliveryLimit);
      const current = parseInt(this.currDeliveries);
      return this.calculatePercent(limit, current);
    },
  },
  watch: {
    model(to) {
      this.limit = to.filter.traffic.limit;
      this.is_limited = to.filter.traffic.is_limited;
      this.curr_limit = to.curr_limit;
      this.budget = to.filter.supply.budget;
      this.budget_decrementer = to.filter.supply.budget_decrementer;
      this.is_budgeted = to.filter.supply.is_budgeted;
      this.cap = to.filter.frequency_cap.cap;
      this.cap_seconds = to.filter.frequency_cap.cap_seconds;
      this.is_capped = to.filter.frequency_cap.is_capped;
      this.hasUserLimit = to.filter.users.is_limited;
      this.userLimit = to.filter.users.limit;
      this.currUsers = to.curr_users;
    },
    is_limited(value) {
      if (!value) {
        this.limit = 0;
      }
    },
    hasUserLimit(value) {
      if (!value) this.userLimit = 0;
    },
    frequencyCapType(newValue) {
      if (newValue === VISIT.value) {
        this.cap_seconds = VISIT.value;
      } else if (this.cap_seconds === VISIT.value) {
        this.cap_seconds = 2592000; // 30 days in seconds - the default value
      }
    },
  },
  methods: {
    calculatePercent(limit, current) {
      if (limit <= 0 || current <= 0) return 0;
      return parseInt((current / limit) * 100);
    },
    getColor(percent) {
      if (percent > 66) {
        return "red";
      }
      if (percent > 33) {
        return "orange";
      }
      return "green";
    },
    fillModel(model) {
      const limit = parseInt(this.limit);
      if (this.is_limited && (Number.isNaN(limit) || limit < 1)) {
        throw new Error("Please change your impression limit to a number greater than 0");
      }
      const cap = parseInt(this.cap);
      if (this.is_capped && (Number.isNaN(cap) || cap < 1)) {
        throw new Error("Please change your frequency cap to a number greater than 0");
      }
      const budget = parseInt(this.budget);
      if (this.is_budgeted && (Number.isNaN(budget) || budget < 1)) {
        throw new Error("Please change your budget occurences to a number greater than 0");
      }
      const budgetDecrementer = parseInt(this.budget_decrementer);
      if (this.is_budgeted && (Number.isNaN(budgetDecrementer) || budgetDecrementer < 1)) {
        throw new Error("Please change your budget occurences to a number greater than 0");
      }
      const userLimit = parseInt(this.userLimit);
      if (this.hasUserLimit && (Number.isNaN(userLimit) || userLimit < 1)) {
        throw new Error("Please change your user limit to a number greater than 0");
      }
      const deliveryLimit = parseInt(this.deliveryLimit);
      if (this.hasDeliveryLimit && (Number.isNaN(deliveryLimit) || deliveryLimit < 1)) {
        throw new Error("Please change your delivery limit to a number greater than 0");
      }
      model.filter.traffic.limit = limit;
      model.filter.traffic.is_limited = this.is_limited;
      model.filter.supply.budget = budget;
      model.filter.supply.budget_decrementer = budgetDecrementer;
      model.filter.supply.is_budgeted = this.is_budgeted;
      model.filter.frequency_cap.cap = cap;
      model.filter.frequency_cap.cap_seconds = this.cap_seconds;
      model.filter.frequency_cap.is_capped = this.is_capped;
      model.filter.users.is_limited = this.hasUserLimit;
      model.filter.users.limit = userLimit;
      model.filter.deliveries.is_limited = this.hasDeliveryLimit;
      model.filter.deliveries.limit = deliveryLimit;
    },
  },
};
</script>
<style>
.v-progress-linear__content {
  text-align: center;
}
</style>
