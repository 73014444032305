import { REDIRECTS, REDIRECTS_ACTION_OBJ } from "../constants/PromptActionsConstants";
import { isLocalized } from "./promptHelpers";

export const isRedirectAction = actionName => REDIRECTS.includes(actionName);

export const readOnlyActions = (prompt, currLanguage) => {
  return isLocalized(prompt)
    ? { ...prompt.actions, ...(prompt.parsed_translations[currLanguage] || {}) }
    : prompt.actions;
};

export const fetchValues = (input, currAppConnectors, prompt, currLanguage) => {
  const values = input.possible_values;
  if (input.input_type !== "select") return null;

  if (input.values_included) {
    if (input.dynamic_value_name) {
      const dynamicValues = [];
      values.forEach(item => {
        const value = { id: item.id };
        const [source, key] = item.name.split(".");
        if (source === "actions") {
          const action = readOnlyActions(prompt, currLanguage)[key];
          if (action) {
            value.name = action;
            dynamicValues.push(value);
          }
        }
      });
      return dynamicValues;
    }
    return values;
  }
  const [provider, property] = values.split(".");
  const providerValues = currAppConnectors[provider] || {};
  return providerValues[property];
};

export const displayConnectorAction = ({
  key,
  value,
  inputs,
  currAppConnectors,
  prompt,
  currLanguage,
}) => {
  let displayKey = key;
  let displayValue = value;
  const displayData = inputs.find(input => input.value_description === key);
  if (displayData) {
    displayKey = displayData.value_label;
    if (displayData.hide_id) {
      const possibleValues = fetchValues(displayData, currAppConnectors, prompt, currLanguage);
      if (possibleValues) {
        const name = possibleValues.find(possibleValue => possibleValue.id === value);
        if (name) {
          displayValue = name.name;
        }
      }
    }
  }
  return `${displayKey}: ${displayValue}`;
};

export const interactionTypes = prompt => {
  const types = ["accept"];
  if (prompt.path_type === "invisible") return types;
  if (
    prompt.actions.rf_settings_close_button_enabled ||
    prompt.actions.rf_settings_click_outside_close_enabled
  ) {
    types.push("dismiss");
  }

  if (prompt.actions.rf_settings_close_seconds) {
    types.push("timeout");
  }

  if (prompt.actions.rf_settings_confirm_button_2_enabled) {
    types.push("accept2");
  }

  if (prompt.actions.rf_settings_cancel_button_enabled) {
    types.push("decline");
  }

  return types;
};

export const promoInputsEnabled = prompt => {
  return !!(
    prompt.actions.rf_retention_promo_input_1_type ||
    prompt.actions.rf_retention_promo_input_2_type ||
    prompt.actions.rf_retention_promo_input_3_type
  );
};

export const isActionDisabled = (args, interaction, prompt, providers) => {
  const dependencies = args.dependencies || {};
  const appDependencies = args.app_dependencies;
  const providerDependencies = appDependencies && appDependencies.providers;

  let dependenciesMet = true;
  let appDependenciesMet = true;
  const interactionEnabled = interactionTypes(prompt).find(type => type === interaction);
  if (dependencies.promo_inputs?.length === 1 && dependencies.promo_inputs[0] === "all") {
    dependenciesMet =
      promoInputsEnabled(prompt) && (interaction === "accept" || interaction === "accept2");
  }

  if (providerDependencies && providerDependencies.length) {
    for (let i = 0; i < providerDependencies.length; i++) {
      const dep = providerDependencies[i];
      const [provider, key] = dep.split(".");

      const value = providers[provider] && providers[provider][key];

      if (!value) {
        appDependenciesMet = false;
        break;
      }
    }
  }
  return !(dependenciesMet && appDependenciesMet && interactionEnabled);
};

export const getActiveActions = (prompt, actionGroupClientActions) => {
  let validInteractionTypes = {
    accept_redirect: true,
    accept2_redirect: true,
    decline_redirect: true,
    dismiss_redirect: true,
    timeout_redirect: true,
    ...interactionTypes(prompt).reduce((hash, item) => {
      hash[item] = true;
      return hash;
    }, {}),
  };
  const redirects = Object.entries(REDIRECTS_ACTION_OBJ).filter(
    ([key, item]) => !!prompt.actions?.[item]?.length && validInteractionTypes[key],
  );

  const websiteActions = (actionGroupClientActions || []).map(item => [
    item.interaction_type,
    item.interaction_type,
    item.args,
  ]);

  return redirects.concat(websiteActions);
};
