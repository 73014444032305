import { ApiBaseUrl, authenticatedFetch } from "./api";

const baseURL = appId => `${ApiBaseUrl}apps/${appId}/custom_devices`;

const DEFAULT_REQUEST_HEADERS = {
  "Accept": "application/json",
  "Content-Type": "application/json",
};

export default class ApiCustomDevices {
  static getCustomDevices(appId) {
    return authenticatedFetch(baseURL(appId));
  }

  static getCustomDevice(appId, deviceId) {
    return authenticatedFetch(`${baseURL(appId)}/${deviceId}`);
  }

  static updateCustomDevice(appId, deviceId, modelDevice) {
    return authenticatedFetch(`${baseURL(appId)}/${deviceId}`, {
      method: "PATCH",
      headers: DEFAULT_REQUEST_HEADERS,
      body: JSON.stringify({ custom_device: modelDevice }),
    });
  }

  static createCustomDevice(appId, modelDevice) {
    return authenticatedFetch(`${baseURL(appId)}`, {
      method: "POST",
      headers: DEFAULT_REQUEST_HEADERS,
      body: JSON.stringify({ custom_device: modelDevice }),
    });
  }

  static deleteCustomDevice(appId, deviceId) {
    return authenticatedFetch(`${baseURL(appId)}/${deviceId}`, {
      method: "DELETE",
    });
  }
}
