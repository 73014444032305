import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiCustomFields {
  static getCustomFields(appId, fieldType) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/custom_fields?field_type=${fieldType}`);
  }

  static updateCustomField(appId, fieldId, field) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/custom_fields/${fieldId}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ custom_field: field }),
    });
  }

  static createCustomField(appId, field) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/custom_fields`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ custom_field: field }),
    });
  }

  static async deleteCustomField(appId, fieldId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/custom_fields/${fieldId}`, {
      method: "DELETE",
    }).then(_ => fieldId);
  }
}
