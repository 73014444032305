<template><span></span></template>

<script>
import { useToastsStore } from "@/pinia/toastsStore";

export default {
  name: "RfStripeConnect",
  setup: () => ({ toastsStore: useToastsStore() }),
  computed: {
    isConnected() {
      return /success/.test(this.$route.path);
    },
  },
  mounted() {
    const destination = this.$route.params.destination || "dashboard";

    this.$router.replace({ path: `/apps/${this.$route.params.aid}/${destination}` });

    this.toastsStore.create({
      type: this.isConnected ? "success" : "error",
      body: this.isConnected ? success : error,
      timeout: 5000,
    });
  },
};
</script>
