import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiCustomMetrics {
  static createCustomMetric(customMetric) {
    return authenticatedFetch(`${ApiBaseUrl}custom_metrics`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ custom_metric: customMetric }),
    });
  }

  static getCustomMetrics() {
    return authenticatedFetch(`${ApiBaseUrl}custom_metrics`);
  }

  static updateCustomMetric(customMetric) {
    return authenticatedFetch(`${ApiBaseUrl}custom_metrics/${customMetric.id}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ custom_metric: customMetric }),
    });
  }

  static deleteCustomMetric(customMetric) {
    return authenticatedFetch(`${ApiBaseUrl}custom_metrics/${customMetric.id}`, {
      method: "DELETE",
    }).then(_ => customMetric.id);
  }
}
