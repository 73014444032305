export const InlinePrompts = {
  horizontal: { label: "Horizontal", value: "horizontal" },
  vertical: { label: "Vertical", value: "vertical" },
  tile: { label: "Tile", value: "tile" },
  text: { label: "Text", value: "text" },
};

export const StandalonePrompts = {
  invisible: { label: "Invisible", value: "invisible" },
  retention_modal: { label: "Pop up", value: "retention_modal" },
  video: { label: "Video", value: "video" },
  interstitial: { label: "Interstitial", value: "interstitial" },
  widget: { label: "Notification", value: "widget" },
  email: { label: "Email", value: "email" },
  bottom_banner: { label: "Bottom Banner", value: "bottom_banner" },
  scheduled_push: { label: "Push", value: "scheduled_push" },
};

export const PromptTypes = { ...InlinePrompts, ...StandalonePrompts };
