<template>
  <div class="mobileweb-promo-setting">
    <div class="promo-design-group">
      <div style="margin-bottom: 16px">
        <v-textarea
          outlined
          :rows="rowsNumber"
          label="Title"
          v-model.trim="
            currentTranslations.hasOwnProperty('rf_mobile_title')
              ? previewActions.rf_mobile_title
              : actions.rf_mobile_title
          "
          :disabled="currentTranslations.hasOwnProperty('rf_mobile_title')"
          name="title"
          hide-details
        ></v-textarea>
        <RfInsertUserTrait
          :app="app"
          actionName="rf_mobile_title"
          title="title"
          :actions="actions"
          v-if="!currentTranslations.hasOwnProperty('rf_mobile_title')"
        />
      </div>
      <div class="div-flex-layout" style="margin-bottom: 22px">
        <v-text-field
          outlined
          dense
          hide-details
          label="Title Font Size"
          v-model.trim="actions.rf_settings_mobile_title_font_size"
          v-validate="'required'"
          name="title-font-size"
          required
        ></v-text-field>
        <v-text-field
          outlined
          dense
          hide-details
          label="Message Font Size"
          v-model.trim="actions.rf_settings_mobile_message_font_size"
          v-validate="'required'"
          name="message-font-size"
          required
        ></v-text-field>
      </div>
      <div style="margin-bottom: 16px">
        <v-textarea
          outlined
          :rows="rowsNumberMsg"
          label="Message"
          v-model.trim="
            currentTranslations.hasOwnProperty('rf_mobile_message')
              ? previewActions.rf_mobile_message
              : actions.rf_mobile_message
          "
          :disabled="currentTranslations.hasOwnProperty('rf_mobile_message')"
          name="message"
          hide-details
        ></v-textarea>
        <RfInsertUserTrait
          :app="app"
          actionName="rf_mobile_message"
          title="message"
          :actions="actions"
          v-if="!currentTranslations.hasOwnProperty('rf_mobile_message')"
        />
      </div>
      <div class="div-flex-layout" style="margin-bottom: 22px" v-if="model.path_type !== 'widget'">
        <v-text-field
          outlined
          dense
          hide-details
          label="Message Top Padding"
          v-model.trim="actions.rf_settings_mobile_title_padding_top"
          name="hero-unit-height"
          v-if="model.path_type !== 'text'"
        ></v-text-field>
      </div>
    </div>
    <div style="padding-top: 10px" v-if="backgroundImageEnabled">
      <h4>Background</h4>
      <div class="upload-bgimg-wrapper">
        <v-btn
          depressed
          color="accent"
          class="upload-image-btn"
          @click="triggerUploadBgImageFromInput('bgImageMobileWebInput')"
          :disabled="uploadDisabled"
        >
          <v-icon size="14" left>fas fa-upload</v-icon>Upload
          <template v-if="!isNewAndroidPrompt">mobile web</template> background
        </v-btn>
        <input
          type="file"
          style="display: none"
          ref="bgImageMobileWebInput"
          accept="image/*"
          @change="uploadBgImageFromInput('mobileWeb')"
        />
        <div class="upload-img-helper" v-if="model.path_type === 'retention_modal'">
          <span v-if="isNewAndroidPrompt">1024 x 1024</span>
          <span v-else>500 x 800</span>
          px recommended
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadImageMixin from "@/utils/UploadImageMixin";
import DesignMixin from "@/utils/DesignMixin";
import RfInsertUserTrait from "@/components/RfPathCreate/RfRetentionActions/RfInsertUserTrait.vue";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";

export default {
  name: "RfRetentionActionsMobileWeb",
  components: { RfInsertUserTrait },
  mixins: [UploadImageMixin, DesignMixin, LanguagePreviewMixin],
  props: ["actions", "model", "app"],
  computed: {
    isNewAndroidPrompt() {
      return (
        this.app?.flags?.new_android_editor &&
        this.model.path_type === "retention_modal" &&
        this.model.device_type === "android_os"
      );
    },
    isInlineOrText() {
      return ["tile", "horizontal", "vertical", "text"].indexOf(this.model.path_type) > -1;
    },
    backgroundImageEnabled() {
      if (!this.model.id) return true;
      if (this.actions.rf_settings_bg_image) return true;
      return Object.hasOwn(this.currentTranslations, "rf_settings_bg_image");
    },
    uploadDisabled() {
      if (!this.backgroundImageEnabled) return true;
      return Object.hasOwn(this.currentTranslations, "rf_settings_mobile_bg_image");
    },
  },
  methods: {
    triggerUploadBgImageFromInput(displayType) {
      if (displayType === "bgImageInput") {
        this.$refs.bgImageInput.click();
      } else {
        this.$refs.bgImageMobileWebInput.click();
      }
    },
  },
};
</script>
