<template>
  <div
    class="rf-settings-goal-tracking rf-custom-web-card-wrapper"
    v-if="getTabsByDeviceType.length && !is_disabled"
  >
    <v-tabs
      v-model="viewTab"
      slider-color="666666"
      dark
      left
      icons-and-text
      height="50"
      background-color="#000"
    >
      <v-tabs-slider style="color: #2196f3"></v-tabs-slider>
      <v-tab
        v-for="item in getTabsByDeviceType"
        :key="item.value"
        :href="'#' + item.value"
        @click="$emit('clickTab', item.value)"
        :disabled="is_disabled"
        >{{ item.name }}</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="viewTab">
      <v-tab-item
        v-for="item in getTabsByDeviceType"
        :key="item.value"
        :transition="false"
        :reverse-transition="false"
        :value="item.value"
        style="background: rgba(0, 0, 0, 0.6); padding: 30px 0"
        disabled
      >
        <PrismEditor
          :code="getCodeByLanguage(item.value)"
          :readonly="true"
          :language="item.value"
          :emitEvents="false"
          :class="is_disabled ? 'disabled' : ''"
        ></PrismEditor>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import "prismjs";
import "prismjs/themes/prism.css";
import PrismEditor from "vue-prism-editor";

export default {
  name: "RfSetupUsageTrackingCode",
  components: {
    PrismEditor,
  },
  props: ["custom_field", "is_disabled", "app"],
  data() {
    return {
      viewTab: "bash",
    };
  },
  computed: {
    getTabsByDeviceType() {
      if (
        this.custom_field &&
        this.custom_field.data_type &&
        this.custom_field.data_type === "custom"
      ) {
        return [
          { value: "bash", name: "cURL" },
          { value: "js", name: "Javascript" },
          { value: "js_third_party", name: "External Web Tracker" },
          { value: "html", name: "HTML" },
          { value: "swift", name: "Swift" },
          { value: "kotlin", name: "Kotlin" },
          { value: "brightscript", name: "Brightscript" },
        ];
      }
      return [];
    },
  },
  methods: {
    getCodeByLanguage(val) {
      let code;
      switch (val) {
        case "bash":
          code = `curl -H 'rf-app: ${this.app.id}' -H 'user-id: ENDUSER_ID' 'https://conduit.redfast.com/ping/?type=${this.custom_field.data_type}&custom_field_id=${this.custom_field.id}`;
          break;
        case "js":
          code = `Redfast.customTrack("${this.custom_field.id}");`;
          break;
        case "js_third_party":
          code = `
// Add rf_uid url to 3rd party redirect landing page 
// e.g. https://example.com?campaign_id=456&rf_uid=123
// The following code will save the user id into local storage
(function() {
  // update rf_user_id to your query selector
  const results = window.location.search.split("&").find(item => item.match(/rf_uid=/))
  const userId = results.split("=").slice(-1)[0];
  localStorage.setItem("rf_user_id", userId);
})();

// Add to conversion page
// The following code will take the saved user id and notify Redfast
(function() {
  if (localStorage.getItem("rf_uid")) {
    fetch("https://conduit.redfast.com/ping/?type=${this.custom_field.data_type}&custom_field_id=${this.custom_field.id}", {
      headers: {
        "USER-ID": localStorage.getItem("rf_uid"),
        "Rf-App": "${this.app.id}"
      }
    });
    localStorage.removeItem("rf_uid");
  }
})();
          `;
          break;
        case "html":
          code = `<img src="https://conduit.redfast.com/ping/?type=${this.custom_field.data_type}&custom_field_id=${this.custom_field.id}" />`;
          break;
        case "swift":
          code = `PromotionManager.customTrack("${this.custom_field.id}")`;
          break;
        case "kotlin":
          code = `PromotionManager.customTrack("${this.custom_field.id}")`;
          break;
        case "brightscript":
          code = `m.promoMgr.callFunc("customTrack", { custom_field_id: "${this.custom_field.id}" })`;
          break;
        default:
      }
      return code;
    },
  },
};
</script>
