export const nonUniqueTypes = [
  "impressions",
  "goal_stage_transitions",
  "custom_goals_accepted_stage_transitions",
  "custom_goals_accepted2_stage_transitions",
  "custom_goals_declined_stage_transitions",
  "custom_goals_dismissed_stage_transitions",
  "custom_goals_holdout_stage_transitions",
];

export const METRICS_PERIODS = {
  today: { value: "today", name: "Today" },
  yesterday: { value: "yesterday", name: "Yesterday" },
  last_seven_days: { value: "last_seven_days", name: "Last 7 days" },
  last_seven_to_fourteen_days: { value: "last_seven_to_fourteen_days", name: "Last 7 to 14 days" },
  this_week: { value: "this_week", name: "This Week" },
  last_week: { value: "last_week", name: "Last Week" },
  this_month: { value: "this_month", name: "This Month" },
  last_month: { value: "last_month", name: "Last Month" },
  since_start_date: { value: "since_start_date", name: "Since Start Date" },
  custom: { value: "custom", name: "Custom Dates" },
};
