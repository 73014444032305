<template>
  <div>
    <div class="all-promos-creative-card">
      <RfPromotionThumbnail
        :item="{ ...variant, path_type: path.path_type, device_type: path.device_type }"
      />
    </div>
    <div class="experiment-variant-item-info">
      <div class="exp-variant-title">
        <div class="exp-variant-name">{{ variant.name }}</div>
      </div>
      <div class="exp-variant-item-stats" v-if="variant.stats">
        <div class="exp-variant-stats-col" v-if="!isInvisible">
          <span>Impressions</span>
          <h5>{{ variant.stats.impressions || 0 }}</h5>
        </div>
        <div class="exp-variant-stats-col">
          <span>{{ goalsTitle }}</span>
          <h5>{{ variant.stats.goals || 0 }}</h5>
        </div>
        <div class="exp-variant-stats-col">
          <span>{{ conversionTitle }}</span>
          <h5>{{ conversionValue }}</h5>
        </div>
      </div>
    </div>
    <div class="variant-manage-div-detail">
      <div>
        <RfButton
          icon="fas fa-random"
          :button-text="`${variantTrafficDistribution(variant.traffic_percent)}%`"
          x-large
          text
          color="primary"
          :disabled="isDisabledRoleMixin"
          @click="$emit('showTrafficDetail')"
        />
      </div>
      <template v-if="currExperiment.is_completed || currExperiment.is_active">
        <template v-if="isSuperAdminRoleMixin && currExperiment.is_active">
          <div>
            <RfButton
              icon="edit"
              button-text="Edit"
              x-large
              text
              color="primary"
              :disabled="isDisabledRoleMixin"
              @click="$emit('showEditVariant')"
            />
          </div>
        </template>
        <template v-else>
          <div>
            <RfButton
              icon="fas fa-info-circle"
              button-text="Details"
              x-large
              text
              color="primary"
              @click="$emit('showVariantDetails')"
            />
          </div>
        </template>
      </template>
      <template v-else>
        <div>
          <RfButton
            icon="edit"
            button-text="Edit"
            x-large
            text
            color="primary"
            :disabled="isDisabledRoleMixin"
            @click="$emit('showEditVariant')"
          />
        </div>
        <div v-if="!variant.is_control && !variant.is_baseline">
          <RfButton
            icon="close"
            button-text="Delete"
            x-large
            text
            color="primary"
            :disabled="isDisabledRoleMixin"
            @click="$emit('showDeleteModal')"
          />
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import RfPromotionThumbnail from "@/components/RfPromotions/RfPromotionThumbnail.vue";
import MetricsMixin from "@/utils/MetricsMixin";
import RfButton from "@/components/buttons/RfButton.vue";
import RoleMixin from "@/utils/RoleMixin";
import { cloneDeep } from "lodash-es";
import { percentage } from "@/utils/metricsHelpers";
import CustomGoalInteractionMixin from "@/utils/CustomGoalInteractionMixin";
import { computed } from "vue";
import { isInvisible } from "@/utils/prompts/promptHelpers";

export default {
  name: "RfVariantCard",
  props: ["variant", "path"],
  components: { RfPromotionThumbnail, RfButton },
  setup: props => ({ percentage, isInvisible: computed(() => isInvisible(props.path)) }),
  mixins: [MetricsMixin, RoleMixin, CustomGoalInteractionMixin],
  computed: {
    ...mapState({
      currExperiment: state => cloneDeep(state.apps.currExperiment),
    }),
    goalsTitle() {
      return this.isInvisible ? "Users" : "Clicks";
    },
    conversionTitle() {
      return this.isInvisible ? "Conversion" : "CTR";
    },
    conversionValue() {
      if (this.isInvisible && !this.hasCustomGoal) {
        return "-";
      }
      const numerator = this.isInvisible
        ? this.variant.stats?.custom_goals_accepted
        : this.variant.stats?.goals;
      const denominator = this.isInvisible
        ? this.variant.stats?.goals
        : this.variant.stats?.impressions;
      return this.percentage(numerator, denominator, true);
    },
    model() {
      //alias
      return this.path;
    },
  },
  methods: {
    variantTrafficDistribution(percent) {
      return percent || 0;
    },
  },
};
</script>
