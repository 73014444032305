<template>
  <div class="error-page-msg">
    <img src="../assets/images/404-img.png" alt="404" />
  </div>
</template>

<script>
export default { name: "Rf404" };
</script>

<style scoped>
.error-page-msg {
  max-width: 1200px;
  margin: 0 auto;
}
.error-page-msg img {
  max-width: 100%;
}
</style>
