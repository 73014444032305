<template>
  <div>
    <div class="tile-promo-display-selections-div" style="margin-bottom: 0">
      <div class="tile-promo-display-selections-opt" v-if="model.device_type === 'web'">
        <a
          class="desktop-email-tab"
          @click="$emit('clickTab', 'desktopViewTab')"
          v-bind:class="[currActiveTab === 'desktopViewTab' ? 'active' : '']"
          >Desktop</a
        >
        <a
          @click="$emit('clickTab', 'mobilewebViewTab')"
          v-bind:class="[currActiveTab === 'mobilewebViewTab' ? 'active' : '']"
          >Mobile</a
        >
      </div>
    </div>
    <div class="pr-email-preview" :class="promoPreviewType" :style="bodyBgColor">
      <table
        :style="containerWrapperStyle"
        style="margin: 0 auto"
        width="100%"
        cellpadding="0"
        cellspacing="0"
      >
        <tr>
          <td :bgcolor="actions.rf_settings_bg_image_color" align="center">
            <div class="pr-email-logo" style="padding: 12px 0" :style="emailHeaderStyle">
              <img :src="actions.rf_settings_mobile_bg_image" :style="logoWidth" />
            </div>
          </td>
        </tr>
        <tr>
          <td :bgcolor="actions.rf_email_content_bgcolor" valign="top">
            <div class="pr-email-wrapper" :style="containerStyle">
              <div class="pr-email-container">
                <div class="pr-email-image">
                  <img :src="actions.rf_settings_bg_image" />
                </div>
                <div class="pr-email-msg" :style="messageAlignment">
                  <div
                    class="pr-email-msg-title"
                    :style="titleStyle"
                    v-html="actions.rf_retention_title"
                  ></div>
                  <div class="pr-email-msg-body">
                    <div :style="messageStyle">
                      {{
                          actions.rf_retention_message,
                      }}
                    </div>
                  </div>
                  <div class="pr-email-btn" :class="buttonFullWidth">
                    <RfRetentionTileButtons :actions="actions" />
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td align="center" style="font-size: 12px">
            <div class="pr-email-footer" :style="emailFooterStyle">
              <div>
                <a
                  :style="footerLinkStyle"
                  :href="actions.rf_settings_tos_link"
                  v-if="actions.rf_settings_tos_link"
                  >Unsubscribe</a
                >
                <span
                  style="padding: 0 5px"
                  v-if="actions.rf_settings_privacy_policy_link && actions.rf_settings_tos_link"
                  >-</span
                >
                <a
                  :style="footerLinkStyle"
                  :href="actions.rf_settings_privacy_policy_link"
                  v-if="actions.rf_settings_privacy_policy_link"
                  >Privacy Policy</a
                >
              </div>
              <div>{{ actions.rf_email_company_name }}</div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <component :is="'style'" v-if="actions.rf_settings_custom_css">{{
      actions.rf_settings_custom_css
    }}</component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InlineMixin from "@/utils/InlineMixin";
import RfRetentionTileButtons from "@/components/RfPathCreate/RfRetentionActions/RfRetentionTileButtons.vue";
import { baseUrlReplacer } from "@/utils/BaseUrlReplacer";

export default {
  name: "RfRetentionEmail",
  mixins: [InlineMixin],
  components: { RfRetentionTileButtons },
  props: ["actions", "viewTypeTab", "model"],
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
    currActiveTab() {
      return this.viewTypeTab;
    },
    promoPreviewType() {
      if (this.currActiveTab === "desktopViewTab") {
        return "desktop-viewport";
      }
      return "mobile-viewport";
    },
    bodyBgColor() {
      return {
        background: this.actions.rf_email_body_bgcolor,
        textAlign: "center",
      };
    },
    containerWrapperStyle() {
      return {
        width: "100%",
        maxWidth: this.actions.rf_widget_width,
        marginTop: this.actions.rf_settings_title_padding_top,
      };
    },
    logoWidth() {
      return {
        width: this.actions.rf_mobile_width,
      };
    },
    containerStyle() {
      return {
        width: "100%",
        maxWidth: this.actions.rf_widget_width,
        background: this.actions.rf_email_content_bgcolor,
      };
    },
    emailHeaderStyle() {
      return {
        width: "100%",
        maxWidth: this.actions.rf_widget_width,
        background: this.actions.rf_settings_bg_image_color,
      };
    },
    emailFooterStyle() {
      return {
        width: "100%",
        maxWidth: this.actions.rf_widget_width,
        color: this.actions.rf_email_footer_font_color,
        background: this.actions.rf_email_footer_bgcolor,
      };
    },
    footerLinkStyle() {
      return {
        color: this.actions.rf_email_footer_font_color,
        borderColor: this.actions.rf_email_unsubscribe_link,
      };
    },
    isClickDisabled() {
      return ["true", true].indexOf(this.actions.rf_settings_disable_click) > -1;
    },
    messageAlignment() {
      return {
        "text-align": "center",
      };
    },

    buttonFullWidth() {
      if (this.actions.rf_settings_text_container_max_width === "full") {
        return "full-width-btn";
      }
      return "";
    },
    mobileHeaderWrapperStyle() {
      return {
        "padding-top": this.actions.rf_settings_mobile_title_padding_top,
        "width": "100%",
        "max-width": "100%",
      };
    },
    cssVarsMobileWebSize() {
      const data = {
        "--font-size": this.actions.rf_settings_mobile_message_font_size,
        "--txt-color": this.fillColor,
        "width": this.actions.rf_mobile_width,
        "height": this.actions.rf_mobile_height,
      };
      return data;
    },
    messageStyle() {
      return {
        "font-size": this.actions.rf_settings_message_font_size,
        "color": this.fillColor,
      };
    },
    fillColor() {
      return this.actions.rf_settings_fill_color;
    },
    titleStyle() {
      return {
        "font-size": this.actions.rf_settings_title_font_size,
        "color": this.fillColor,
      };
    },
    containerBackgroundClass() {
      return `promo-tile-backgroundimage ${this.actions.rf_settings_tile_is_responsive}-mode`;
    },
    closeButtonEnabled() {
      return this.actions.rf_settings_close_button_enabled === "true" && !this.isSticky;
    },
    isSticky() {
      return (
        this.actions.rf_settings_tile_interaction === "container" &&
        this.actions.rf_settings_permanent_display === "true"
      );
    },
    containerMobileWebBackgroundClass() {
      return `rfmodal-backgroundimage ${this.actions.rf_settings_tile_is_responsive}-mode`;
    },
    containerMobileWebBackgroundImage() {
      const styles = {
        "background-image": "url(https://assets.redfastlabs.com/images/inline-bg-default.jpg)",
        "background-color": this.actions.rf_settings_bg_image_color,
      };
      if (this.actions.rf_settings_mobile_bg_image) {
        styles["background-image"] =
          `url(${baseUrlReplacer(this.currApp, this.actions.rf_settings_mobile_bg_image)})`;
      } else if (this.actions.rf_settings_bg_image) {
        styles["background-image"] =
          `url(${baseUrlReplacer(this.currApp, this.actions.rf_settings_bg_image)})`;
      } else {
        styles["background-image"] = "none";
      }
      return styles;
    },
    mobileWebTitleFontSize() {
      return {
        "font-size": this.actions.rf_settings_mobile_title_font_size,
        "color": this.fillColor,
      };
    },
    mobileWebBodyFontSize() {
      return {
        "font-size": this.actions.rf_settings_mobile_message_font_size,
        "color": this.fillColor,
      };
    },
    containerClassName() {
      if (this.model.device_type === "web") {
        return `retention-tile-mweb-preview ${this.model.path_type}_type`;
      }
      return `retention-tile-mweb-preview mobileapp-design ${this.model.path_type}_type`;
    },
  },
};
</script>
