<template>
  <div :class="tableClassName">
    <div class="list-group-header">
      <div class="promotion-priority-col-0" v-if="isOrdered">
        <span></span>
      </div>
      <div class="promotion-priority-col-1" v-if="isOrdered">
        <span>Order</span>
      </div>
      <div class="promotion-priority-col-2">
        <span>Item</span>
      </div>
    </div>
    <draggable
      tag="div"
      v-model="orderedPaths"
      v-bind="dragOptions"
      @start="isDragging = true"
      @end="isDragging = false"
      class="priority-list-wrapper"
    >
      <span class="list-group-item" v-for="(item, index) in orderedPaths" :key="item.id">
        <span class="promotion-priority-col-0" v-if="isOrdered">
          <v-icon small>drag_indicator</v-icon>
        </span>
        <span class="promotion-priority-col-1" v-if="isOrdered">
          <span>{{ index + 1 }}</span>
        </span>
        <span class="promotion-priority-col-2">
          <span>{{ item.name }}</span>
          <span class="seq-action-btns-div">
            <v-icon
              size="18"
              style="margin-left: 10px"
              @click="deletePathSequence(index)"
              class="delete-path-sequence-button"
              >clear</v-icon
            >
          </span>
        </span>
      </span>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "RfSequencePathsDisplayTable",
  props: ["paths", "sequence"],
  components: { draggable },
  data() {
    return {
      orderedPaths: this.paths,
    };
  },
  methods: {
    deletePathSequence(index) {
      this.$emit("delete", index);
    },
    editPathSequence(index) {
      this.$emit("edit", index);
    },
  },
  computed: {
    tableClassName() {
      if (this.isOrdered) {
        return "seq-table-ordered-ls";
      }
      return "seq-table-ordered-ls unordered-tbl";
    },
    isOrdered() {
      if (!this.sequence) return false;
      return (
        this.sequence.sequence_type === "ordered" || this.sequence.sequence_type === "web_client"
      );
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.isOrdered,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    orderedPaths(to) {
      this.$emit("updateOrder", to);
    },
  },
};
</script>
