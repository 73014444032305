<template>
  <v-app class="shortSidebar">
    <router-view></router-view>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState({
      currApp: state => state.apps.currApp,
      loading: state => state.loading,
    }),
  },
  methods: {
    ...mapActions(["getApps", "selectApp"]),
  },
  mounted() {
    this.getApps(this.$route.params.aid);
  },
  watch: {
    $route(to, from) {
      if (from.params.aid && to.params.aid && to.params.aid !== from.params.aid) {
        this.selectApp(this.$route.params.aid);
      }
    },
    currApp(app) {
      if (this.$route.name === "Home" && app)
        this.$router.replace({ path: `/apps/${app.id}/dashboard` });
    },
  },
};
</script>
