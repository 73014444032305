<template>
  <div class="seq-promo-restriction" v-if="path">
    <span class="seqp-rest-all" v-if="interactionTypes.length">
      <span
        v-for="item in interactionTypes"
        :key="item"
        :class="`sqp-restriction-item--${item}`"
        class="sqp-restriction-item"
      >
        {{ displayInteractionType(item) }}
      </span>
    </span>
    <span v-else> none </span>
  </div>
</template>

<script>
import { getButtonTitle } from "@/utils/prompts/promptHelpers";

export default {
  name: "RfSequencePathsDisplayCell",
  props: ["interactionTypes", "isContinue", "path"],
  methods: {
    displayInteractionType(type) {
      const displayItem = this.interactions.find(option => option.value === type);
      if (type === "custom_goal" && this.hasCustomGoal) {
        const goalName = Object.keys(this.path.custom_filter)[0];
        return `Custom Goal: ${goalName}`;
      }
      return displayItem?.text || type;
    },
    displayInteractionTypes(types) {
      const interactions = types.map(interaction => {
        const displayItem = this.interactions.find(option => option.value === interaction);
        if (interaction === "custom_goal") {
          if (this.hasCustomGoal) {
            const goalName = Object.keys(this.path.custom_filter)[0];
            return `Custom Goal: ${goalName}`;
          }
        }
        return displayItem?.text || interaction;
      });
      return interactions;
    },
  },
  computed: {
    hasCustomGoal() {
      return Object.keys(this.path.custom_filter).length > 0;
    },
    interactions() {
      return [
        { text: "Seen", value: "seen" },
        {
          text: getButtonTitle(this.path, "rf_retention_button1_text") || "Accepted (1st)",
          value: "accepted",
        },
        {
          text: getButtonTitle(this.path, "rf_retention_button2_text") || "Accepted (2nd)",
          value: "accepted2",
        },
        {
          text: getButtonTitle(this.path, "rf_retention_button3_text") || "Declined",
          value: "declined",
        },
        { text: "Dismissed", value: "dismissed" },
        { text: "Timeout", value: "timeout" },
      ];
    },
  },
};
</script>
