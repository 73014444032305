import Vue from "vue";
import Router from "vue-router";
import RfDashboard from "@/views/RfDashboard.vue";
import RfSegmentCreate from "@/views/RfSegmentCreate.vue";
import RfSegmentView from "@/views/RfSegmentView.vue";
import Rf404 from "@/views/Rf404.vue";
import RfSettings from "@/views/RfSettings.vue";
import RfSettingsCompany from "@/views/RfSettingsCompany.vue";
import RfSettingsGlobal from "@/views/RfSettingsGlobal.vue";
import RfLogin from "@/views/RfLogin.vue";
import RfLoginResetInvitation from "@/views/RfLoginResetInvitation.vue";
import RfRegister from "@/views/RfRegister.vue";
import RfAppCreate from "@/views/RfAppCreate.vue";
import RfAppDashboard from "@/views/RfDashboard/index.vue";
import RfRetentionView from "@/views/RfPrompt/index.vue";
import RfRetentionCreative from "@/views/RfRetentionCreative.vue";
import RfRetentionExperimentView from "@/views/RfRetentionExperimentView.vue";
import RfPlacementView from "@/views/RfPlacementView.vue";
import RfPrompts from "@/views/RfPrompts/index.vue";
import RfAwsMarketplace from "@/views/RfAwsMarketplace.vue";
import RfSequenceView from "@/views/RfSequenceView.vue";
import RfGuides from "@/views/RfGuides/index.vue";
import RfPipelines from "@/views/RfPipelines.vue";
import RfLiveView from "@/views/RfLiveView.vue";
import RfPipelineView from "@/views/RfPipelineView.vue";
import RfSegments from "@/views/RfSegments/index.vue";
import RfStripeConnect from "@/views/RfStripeConnect.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/invitation",
      component: RfLoginResetInvitation,
    },
    {
      path: "/register",
      component: RfRegister,
    },
    {
      path: "/password",
      component: RfLoginResetInvitation,
    },
    {
      path: "/login",
      component: RfLogin,
    },
    {
      path: "/aws_marketplace/:token",
      component: RfAwsMarketplace,
    },
    // TODO- make these restful
    {
      path: "/",
      name: "Home",
      component: RfDashboard,
      children: [
        {
          path: "apps/:aid/dashboard",
          name: "App Dashboard",
          component: RfAppDashboard,
        },
        {
          path: "apps/:aid/segments/new",
          name: "New Segment",
          component: RfSegmentCreate,
        },
        {
          path: "apps/:aid/segments",
          name: "All Segments",
          component: RfSegments,
        },
        {
          path: "apps/:aid/segments/:sid/(.*)*",
          name: "Segment Detail",
          component: RfSegmentView,
        },
        {
          path: "settings/company",
          name: "Company Settings",
          component: RfSettingsCompany,
        },
        {
          path: "settings/global",
          name: "Global Settings",
          component: RfSettingsGlobal,
        },
        {
          path: "apps/:aid/settings/(.*)*",
          name: "Settings",
          component: RfSettings,
        },
        {
          path: "newapp",
          name: "New App",
          component: RfAppCreate,
        },
        {
          path: "apps/:aid/retentions/:pid/creative",
          name: "Retention Creative",
          component: RfRetentionCreative,
        },
        {
          path: "apps/:aid/retentions/:pid/experiments/:eid",
          name: "Retention Experiment View",
          component: RfRetentionExperimentView,
        },
        // NOTE- can't use aliases for these b/c we need to access all path params
        ...[
          { path: "apps/:aid/retentions/:pid/:tslug(.*)*", place: "" },
          { path: "apps/:aid/pipelines/:plid/promotions/:pid/:tslug(.*)*", place: "Pipeline's " },
          { path: "apps/:aid/experiences/:sid/promotions/:pid/:tslug(.*)*", place: "Guide's " },
          {
            path: "apps/:aid/placements/:plid/promotions/:pid/:tslug(.*)*",
            place: "Placements's ",
          },
        ].map(({ path, place }) => ({
          path,
          name: `${place}Retention Detail`,
          component: RfRetentionView,
        })),
        {
          path: "apps/:aid/placements/:pid",
          name: "Placement Detail",
          component: RfPlacementView,
        },
        {
          path: "apps/:aid/experiences",
          name: "All Guides",
          component: RfGuides,
        },
        {
          path: "apps/:aid/experiences/:sid",
          name: "Guide Detail",
          component: RfSequenceView,
        },
        {
          path: "apps/:aid/prompts",
          name: "All Prompts",
          component: RfPrompts,
        },
        {
          path: "apps/:aid/live",
          name: "Live",
          component: RfLiveView,
        },
        {
          path: "apps/:aid/pipelines",
          name: "Pipelines",
          component: RfPipelines,
        },
        {
          path: "apps/:aid/pipelines/:pid",
          name: "Pipeline Detail",
          component: RfPipelineView,
        },
        {
          path: "apps/:aid/stripe-success/:destination?",
          name: "Stripe Connect Success",
          component: RfStripeConnect,
        },
        {
          path: "apps/:aid/stripe-error/:destination?",
          name: "Stripe Connect Error",
          component: RfStripeConnect,
        },
      ],
    },
    {
      path: "*",
      component: Rf404,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});
