<template>
  <v-dialog v-model="visible" width="500" persistent>
    <v-card>
      <v-card-title>Choose Domain</v-card-title>
      <v-card-text>
        <RfLiveDomainChooser :app="app" ref="domainChooser" />
      </v-card-text>
      <v-card-actions class="modal-card-actions-div">
        <v-btn large depressed class="cancel-btn" outlined @click="close">Close</v-btn>
        <v-btn large depressed color="success" @click="open" style="width: 120px">Open</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RfLiveDomainChooser from "@/components/RfCommonCards/RfLiveDomainChooser.vue";

export default {
  name: "RfDomainChooserWrapper",
  props: ["app", "visible"],
  components: {
    RfLiveDomainChooser,
  },
  methods: {
    open() {
      const domain = this.$refs.domainChooser.getDomain();
      this.$emit("open", domain);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
