import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiTriggers {
  static getTriggers(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/triggers`);
  }

  static updateTrigger(appId, triggerId, trigger) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/triggers/${triggerId}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ trigger }),
    });
  }

  static createTrigger(appId, trigger) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/triggers`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ trigger }),
    });
  }

  static async deleteTrigger(appId, triggerId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/triggers/${triggerId}`, {
      method: "DELETE",
    }).then(_ => triggerId);
  }

  static customEvents(appId) {
    return authenticatedFetch(`${ApiBaseUrl}apps/${appId}/custom_events`);
  }
}
