import { mapActions, mapState } from "vuex";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { isLatestExperimentActive } from "@/utils/prompts/promptHelpers";

export default {
  data: () => ({ actions: {} }),
  mounted() {
    this.actions = cloneDeep(this.path.actions);
  },
  computed: {
    ...mapState({
      experiments: state => state.apps.experiments,
      currApp: state => state.apps.currApp,
    }),
    latestExperimentActive() {
      return isLatestExperimentActive({ path: this.path, experiments: this.experiments });
    },
    isInvisibleType() {
      return this.path.path_type === "invisible";
    },
  },
  methods: {
    ...mapActions(["updatePath"]),
    showCreativeEditor() {
      this.$router.push({
        path: `/apps/${this.$route.params.aid}/retentions/${this.$route.params.pid}/creative`,
      });
    },
    setIntervalAction(value, type) {
      this.actions[`rf_settings_${type}_interval`] = value;
    },
    async updateActions() {
      const toastsStore = useToastsStore();
      try {
        const newPath = cloneDeep(this.path);
        newPath.actions = this.actions;

        await this.updatePath({
          appId: this.$route.params.aid,
          pathId: this.$route.params.pid,
          modelPath: newPath,
        });

        toastsStore.create({ type: "success", body: "Prompt updated successfully" });
      } catch (e) {
        toastsStore.create({ type: "error", body: e.message });
      }
    },
  },
};
