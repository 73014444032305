<template>
  <div>
    <RfPipelinesListView />
  </div>
</template>
<script>
import RfPipelinesListView from "@/views/RfPipelinesListView.vue";

export default {
  name: "RfPipelines",
  components: {
    RfPipelinesListView,
  },
};
</script>
