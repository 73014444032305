<template>
  <div class="all-segments-list-leftcol" data-cy="setting-list">
    <div class="subnav-row-one">
      <div class="leftnav-cat-title">
        <h1>Settings</h1>
      </div>
      <button class="subnav-menu-button" @click="showHideSubnav">
        <v-icon color="#000">{{ subnavOpenStatus ? "menu_open" : "menu" }}</v-icon>
      </button>
    </div>
    <div class="segment-subnav-content rf-settings-subnav-menu">
      <router-link :to="{ path: `/apps/${this.currApp.id}/settings/system` }"
        >Application</router-link
      >
      <router-link :to="{ path: `/apps/${this.currApp.id}/settings/usage_tracking` }"
        >Usage Tracking</router-link
      >
      <router-link :to="{ path: `/apps/${this.currApp.id}/settings/segment_traits` }"
        >User Traits</router-link
      >
      <router-link :to="{ path: `/apps/${this.currApp.id}/settings/tags` }">Tags</router-link>
      <router-link :to="{ path: `/apps/${this.currApp.id}/settings/triggers` }"
        >Triggers</router-link
      >
      <router-link :to="{ path: `/apps/${this.currApp.id}/settings/data_sources` }"
        >Data Sources</router-link
      >
      <router-link :to="{ path: `/apps/${this.currApp.id}/settings/connectors` }"
        >Integrations</router-link
      >
      <router-link
        data-cy="setting-list--zones"
        :to="{ path: `/apps/${this.currApp.id}/settings/zones` }"
        >Zones</router-link
      >
      <router-link
        v-if="currApp.flags.custom_devices"
        data-cy="setting-list--custom-devices"
        :to="{ path: `/apps/${this.currApp.id}/settings/custom_devices` }"
        >Custom Devices</router-link
      >
      <router-link
        v-if="currApp.feature_set.indexOf('pipelines') > -1 && isAdminRoleMixin"
        :to="{ path: `/apps/${this.currApp.id}/settings/pipelines` }"
        >Pipelines</router-link
      >
      <router-link :to="{ path: `/apps/${this.currApp.id}/settings/users` }">Users</router-link>
      <router-link :to="{ path: `/apps/${this.currApp.id}/settings/app_messages` }"
        >App Messages</router-link
      >
      <router-link
        v-if="isAdminRoleMixin"
        :to="{ path: `/apps/${this.currApp.id}/settings/custom_web_snippet` }"
        >Custom JS Snippet</router-link
      >
      <router-link
        v-if="isSuperAdminRoleMixin"
        :to="{ path: `/apps/${this.currApp.id}/settings/css_styles` }"
        >CSS Styles</router-link
      >
      <router-link :to="{ path: `/apps/${this.currApp.id}/settings/download_csv` }"
        >Download Data</router-link
      >
      <router-link
        v-if="isSuperAdminRoleMixin"
        data-cy="setting-list--admin"
        :to="{ path: `/apps/${this.currApp.id}/settings/admin` }"
        >Admin</router-link
      >
      <router-link
        v-if="isSalesRoleMixin || isSuperAdminRoleMixin"
        :to="{ path: `/apps/${this.currApp.id}/settings/sales_admin` }"
        >Sales Admin</router-link
      >
      <router-link
        v-if="isSuperAdminRoleMixin && this.isAwsMarketplaceCompany()"
        :to="{ path: `/apps/${this.currApp.id}/settings/aws_marketplace` }"
        >AWS Marketplace</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ShowHideSubnav from "@/utils/ShowHideSubnav";
import RoleMixin from "@/utils/RoleMixin";

export default {
  name: "RfSettingList",
  mixins: [ShowHideSubnav, RoleMixin],
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
  },
  methods: {
    isAwsMarketplaceCompany() {
      return this.currApp.aws_marketplace_enabled === true;
    },
  },
};
</script>
