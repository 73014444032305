<template>
  <div>
    <v-select
      outlined
      multiple
      dense
      hide-details
      :items="parentItems"
      v-model="parentModel"
      :disabled="disabled"
      placeholder="Select "
      class="segment-builder--subtypes _subtypes"
      height="32px"
      background-color="#ffffff"
      style="min-width: 180px"
      :menu-props="{ maxHeight: 410, offsetY: true }"
      @change="emit('update:model', { channels: [...parentModel] })"
    />
  </div>
</template>

<script setup>
import { PinpointChannelItems } from "@/utils/constants/SegmentsConstants";
import { onMounted, ref } from "vue";

const emit = defineEmits(["update:model"]);
const props = defineProps(["filter", "disabled"]);
const parentModel = ref([]);
const parentItems = Object.values(PinpointChannelItems);

const prepareFilter = () =>
  Array.isArray(props.filter?.channels) && (parentModel.value = props.filter.channels);

onMounted(prepareFilter);
</script>
