<template>
  <div :class="disabledDiv(disabled)">
    <v-select
      outlined
      dense
      :disabled="disabled"
      hide-details
      v-model="options.negative_match"
      :items="matchTypeItems"
      height="32px"
      placeholder="Match Type"
      background-color="#ffffff"
      @change="triggerUpdate"
      v-if="horizontal"
    />
    <v-icon v-if="horizontal && matchTypeSelected">chevron_right</v-icon>
    <div :class="disabledDiv(disabled)" v-if="model.display_type === 'slider' && matchTypeSelected">
      <h4>{{ model.name }}</h4>
      <span class="md-helper-text">{{ model.description }}</span>
      <RfCustomControlsSlider v-model="valueSet" :model="model" :disabled="disabled" />
    </div>
    <div
      :class="disabledDiv(disabled)"
      v-else-if="model.display_type === 'range_select' && matchTypeSelected"
    >
      <h4>{{ model.name }}</h4>
      <span class="md-helper-text">{{ model.description }}</span>
      <RfCustomRangeSelector v-model="valueSet" :model="model" :disabled="disabled" />
    </div>
    <div
      :class="disabledDiv(disabled)"
      v-else-if="model.display_type === 'multi_select' && matchTypeSelected"
    >
      <v-autocomplete
        outlined
        dense
        hide-details
        chips
        multiple
        v-model="valueSet"
        :disabled="disabled"
        :items="model.data_type === 'boolean' ? booleanValues : model.values"
        :placeholder="model.name"
        background-color="#ffffff"
        style="min-width: 150px"
        :value-comparator="vSelectMatch"
        :menu-props="{ maxHeight: 410, offsetY: true, minWidth: 200 }"
      >
        <template #item="{ item, attrs }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs: tAttrs }">
              <div class="d-flex align-center flex-grow-1" v-on="on" v-bind="{ ...tAttrs }">
                <v-list-item-action>
                  <v-checkbox hide-details class="ml-n2 mt-0 pt-0" v-model="attrs.inputValue" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item?.text || item }}</v-list-item-title>
                </v-list-item-content>
              </div>
            </template>
            <span>{{ item?.text || item }}</span>
          </v-tooltip>
        </template>
      </v-autocomplete>
    </div>
    <div
      :class="disabledDiv(disabled)"
      v-else-if="model.display_type === 'text_input' && matchTypeSelected"
    >
      <div :class="disabledDiv(disabled)" v-if="model.data_type === 'string'">
        <v-select
          v-model="options.matcher_type"
          outlined
          dense
          hide-details
          background-color="#ffffff"
          :items="matcherTypeOptions"
          @change="triggerUpdate"
          placeholder="Matcher Type"
        >
        </v-select>
      </div>
      <div v-if="matcherTypeSelected" :class="disabledDiv(disabled)">
        <v-icon v-if="horizontal && model.data_type == 'string'">chevron_right</v-icon>
        <v-text-field
          outlined
          dense
          hide-details
          :placeholder="model.name"
          :hint="model.description"
          v-model.trim="inputValueSet"
          :disabled="disabled"
          background-color="#ffffff"
          height="32px"
        ></v-text-field>
      </div>
    </div>
    <div
      :class="disabledDiv(disabled)"
      v-else-if="model.display_type === 'date_range' && matchTypeSelected"
    >
      <h4>{{ model.name }}</h4>
      <span class="md-helper-text">{{ model.description }}</span>
      <v-select
        @change="incrementOptionsKey"
        v-model="options.date_type"
        :items="dateTypeOptions"
        outlined
        :disabled="disabled"
        dense
        hide-details
        background-color="#ffffff"
      />
      <v-icon v-if="horizontal">chevron_right</v-icon>
      <div v-if="isDayRange" :style="dayPickerStyle">
        <v-select
          @change="incrementOptionsKey"
          v-model="options.range_type"
          :items="rangeTypeOptions"
          :disabled="disabled"
          outlined
          dense
          hide-details
          background-color="#ffffff"
          :style="dateStyles"
        />
        <v-text-field
          v-model="valueSet[0]"
          v-if="minValAvail"
          outlined
          :disabled="disabled"
          dense
          hide-details
          placeholder="Days"
          background-color="#ffffff"
          :style="dateStyles"
        />
        <span v-if="rangeType === 'range' && isDayRange" style="margin-right: 8px; padding-top: 8px"
          >to:
        </span>
        <v-text-field
          v-model="valueSet[1]"
          v-if="maxValAvail"
          outlined
          :disabled="disabled"
          dense
          hide-details
          placeholder="Days"
          background-color="#ffffff"
          :style="dateStyles"
        />
        <v-select
          @change="incrementOptionsKey"
          v-model="options.relative_date_type"
          :items="relativeDateTypeOptions"
          outlined
          dense
          :disabled="disabled"
          hide-details
          v-if="isDayRange"
          background-color="#ffffff"
          :style="dateStyles"
        />
      </div>
      <div :style="datePickerStyle">
        <RfStartStopDatePicker
          ref="dateRangePicker"
          :dateRange="dateRange"
          :disabled="disabled"
          @dateRangeUpdate="dateRangeUpdate"
          :showClearIcon="true"
          v-if="options.date_type === 'date_range'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RfCustomControlsSlider from "@/components/RfCustomControlsSlider.vue";
import RfCustomRangeSelector from "@/components/RfCustomRangeSelector.vue";
import RfStartStopDatePicker from "@/components/RfStartStopDatePicker.vue";
import { MatchTypeItems } from "@/utils/constants/SegmentsConstants";
import { cloneDeep } from "lodash-es";

export default {
  name: "RfCustomControls",
  components: { RfCustomControlsSlider, RfCustomRangeSelector, RfStartStopDatePicker },
  props: ["model", "values", "disabled", "currentOptions", "horizontal"],
  setup: () => ({ matchTypeItems: Object.values(MatchTypeItems) }),
  data() {
    let defaultOptions = {};
    if (this.model.display_type === "date_range") {
      defaultOptions = {
        range_type: null,
        date_type: "date_range",
        relative_date_type: null,
      };
    } else if (this.model.display_type === "text_input" && this.model.data_type === "string") {
      defaultOptions = {
        matcher_type: null,
      };
    }
    return {
      valueSet: cloneDeep(this.values) || [],
      booleanValues: [
        { text: "All", value: "null" },
        { text: "True", value: "1" },
        { text: "False", value: "0" },
      ],
      dateTypeOptions: [
        { text: "Day Range", value: "day_range" },
        { text: "Date Range", value: "date_range" },
      ],
      relativeDateTypeOptions: [
        { text: "Days Ago", value: "days_ago" },
        { text: "Days From Now", value: "days_from_now" },
      ],
      rangeTypeOptions: [
        { value: "less_than_or_equal_to", text: "Less than or equal to" },
        { value: "greater_than_or_equal_to", text: "Greater than or equal to" },
        { value: "range", text: "From To" },
      ],
      options: cloneDeep(this.currentOptions || defaultOptions),
      matcherTypeOptions: [
        { text: "Standard", value: null },
        { text: "Wildcard", value: "wildcard" },
        { text: "Regular Expression", value: "regex" },
      ],
      optionsKey: 0,
    };
  },
  computed: {
    inputValueSet: {
      get() {
        return (this.valueSet && this.valueSet[0]) || [];
      },
      set(value) {
        if (!value.length) {
          this.valueSet = [];
        } else {
          this.valueSet = [value];
        }
      },
    },
    dateRange() {
      return {
        startDate: this.valueSet && this.valueSet[0],
        endDate: this.valueSet && this.valueSet[1],
      };
    },
    isDayRange() {
      this.optionsKey;
      return this.options.date_type === "day_range";
    },
    minValAvail() {
      this.optionsKey;
      return (
        this.isDayRange &&
        (this.rangeType === "range" || this.rangeType === "greater_than_or_equal_to")
      );
    },
    maxValAvail() {
      this.optionsKey;
      return (
        this.isDayRange &&
        (this.rangeType === "range" || this.rangeType === "less_than_or_equal_to")
      );
    },
    rangeType() {
      this.optionsKey;
      return this.options.range_type;
    },
    dateType() {
      this.optionsKey;
      return this.options.date_type;
    },
    dateStyles() {
      if (this.horizontal) {
        return {
          "margin-right": "8px",
        };
      }
      return {
        "margin-bottom": "8px",
      };
    },
    datePickerStyle() {
      if (this.horizontal) return {};
      return { "margin-top": "8px" };
    },
    dayPickerStyle() {
      const styles = { display: "inherit" };
      if (!this.horizontal) styles["margin-top"] = "8px";
      return styles;
    },
    matchTypeSelected() {
      if (!this.horizontal) return true;
      this.optionsKey;
      return "negative_match" in this.options;
    },
    matcherTypeSelected() {
      if (!this.horizontal) return true;
      if (this.model.data_type !== "string" || this.model.display_type !== "text_input")
        return true;
      this.optionsKey;
      return "matcher_type" in this.options;
    },
  },
  methods: {
    vSelectMatch(value1, value2) {
      try {
        const escaped = value1.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
        const regexValueCaseInsensitive = new RegExp(`^${escaped}\$`, "i");
        return regexValueCaseInsensitive.test(value2);
      } catch {
        return value1 === value2;
      }
    },
    disabledDiv(val) {
      if (val) {
        return "disabled-custom-field";
      }
      return "ok-custom-field";
    },
    dateRangeUpdate(data) {
      this.valueSet = [data.startDate || "", data.endDate || ""];
    },
    triggerUpdate() {
      this.$emit("update", this.valueSet, this.options);
    },
    incrementOptionsKey() {
      this.optionsKey++;
    },
  },
  watch: {
    valueSet(to) {
      this.$emit("update", to, this.options);
    },
    options(to) {
      this.$emit("update", this.valueSet, to);
    },
    dateType(to) {
      if (to === "day_range") {
        this.options.range_type = "range";
        this.options.relative_date_type = "days_ago";
      } else {
        this.options.range_type = null;
        this.options.relative_date_type = null;
      }
      this.valueSet = [];
    },
    rangeType(to) {
      if (to === "less_than_or_equal_to") {
        this.valueSet[0] = 0;
        this.valueSet[1] = null;
      } else if (to === "greater_than_or_equal_to") {
        this.valueSet[1] = "INF";
        this.valueSet[0] = null;
      }
    },
  },
};
</script>
