import { defineStore } from "pinia";
import { ref } from "vue";
import StringUtils from "@/utils/StringUtils";

export const useToastsStore = defineStore("toasts", () => {
  const toasts = ref({});
  const id = ref(0);
  const limit = 5;

  /**
   * Create new toast.
   * @param {Object} toast
   * @param {string} [toast.title]
   * @param {number} [toast.timeout = 2000]
   * @param {string} toast.body
   * @param {string} [toast.bodyTransform = 'capitalize'] - Body text transform.
   * @param {Object} [toast.action] - Action that will be added to toast.
   * @param {string} toast.action.title - Title that will be applied to button.
   * @param {() => void} toast.action.handler - Action function.
   * @param {('success'|'error'|'info')} toast.type - The type of toast.
   */
  const create = ({ type, title, body, timeout = 2000, action, bodyTransform = "capitalize" }) => {
    const instance = {
      id: id.value,
      type,
      title,
      body: bodyTransform ? StringUtils[bodyTransform](body) : body,
      action,
    };
    if (timeout >= 0) instance.timeout = setTimeout(() => remove(instance.id), timeout);
    toasts.value = {
      ...Object.fromEntries(Object.entries(toasts.value).slice(-limit + 1)),
      [instance.id]: instance,
    };
    id.value += 1;
  };

  /**
   * Remove toast.
   * @param {number} id
   */
  const remove = id => {
    if (!toasts.value[id]) return;
    toasts.value[id].timeout && clearTimeout(toasts.value[id].timeout);
    const copyObj = { ...toasts.value };
    delete copyObj[id];
    toasts.value = { ...copyObj };
  };

  return { toasts, create, remove, limit };
});
