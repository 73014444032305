export default {
  data() {
    return {
      addPromoModule: false,
      newPathInteractions: [],
      newPathContinueInteractions: [],
      newPathSequenceId: null,
      editIndex: -1,
      computeKey: 0,
      newPathContinueInterval: 0,
    };
  },
  methods: {
    hideAddPromoModule() {
      this.addPromoModule = false;
      this.newPathInteractions = [];
      this.newPathContinueInteractions = [];
      this.newPathContinueInterval = 0;
      this.newPathSequenceId = null;
      this.editIndex = -1;
      this.computeKey++;
    },
    openAddPromoMenu() {
      this.addPromoModule = true;
    },
    addPath(newPath) {
      if (this.editIndex >= 0) {
        this.paths[this.editIndex] = { ...this.paths[this.editIndex], ...newPath };
      } else {
        this.paths.push(newPath);
      }
      this.hideAddPromoModule();
      if (this.directSave) {
        this.saveConditions();
      }
    },
    editPath(index) {
      this.newPathSequenceId = this.paths[index].id;
      this.newPathInteractions = this.paths[index].interaction_types;
      this.newPathContinueInteractions = this.paths[index].continue_interaction_types;
      this.newPathContinueInterval = this.paths[index].continue_interval_minutes;
      this.editIndex = index;
      this.openAddPromoMenu();
    },
  },
};
