import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiCompanies {
  static getCompanies() {
    return authenticatedFetch(`${ApiBaseUrl}companies`);
  }

  static createCompany(companyName, awsMarketplaceCustomerId, awsMarketplaceEnabled) {
    return authenticatedFetch(`${ApiBaseUrl}companies`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company: {
          name: companyName,
          aws_marketplace_customer_id: awsMarketplaceCustomerId,
          aws_marketplace_enabled: awsMarketplaceEnabled,
        },
      }),
    });
  }
}
