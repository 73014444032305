export const getUsageFilterData = segmentCustomFields =>
  !segmentCustomFields?.length
    ? []
    : segmentCustomFields
        .filter(f => f.is_usage)
        .map(f => ({
          ...f,
          name: f.connector_type === "segment" ? `segment.io - ${f.name}` : f.name,
        }));

export const customOrUsageFilters = (data, segmentCustomFilter) =>
  data.reduce((a, { label }) => ({ ...a, [label]: segmentCustomFilter[label] }), {});

export const getCustomFiltersData = segmentCustomFilter =>
  segmentCustomFilter
    .filter(
      field =>
        field.data_type &&
        field.display_type &&
        !field.is_disabled &&
        field.connector_type === "aws_s3" &&
        field.display_type !== "compound",
    )
    .sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : b.name.toLowerCase() > a.name.toLowerCase()
          ? -1
          : 0,
    );

export const isUserFilterDisabled = (fieldName, segmentSystemFields = []) =>
  (segmentSystemFields.find(({ label }) => label === fieldName) || {})?.is_disabled;
