<template>
  <span
    class="rf-tag text-body flex items-center gap-1 whitespace-nowrap rounded-2xl bg-white-1 px-3 py-1 text-grey-1"
  >
    <slot></slot>
    <button
      v-if="$listeners.clear"
      class="flex items-center justify-center"
      @click="$emit('clear')"
    >
      <RfCloseTagIcon class="!h-6 !w-6" />
    </button>
  </span>
</template>

<script>
import RfCloseTagIcon from "@/components/icons/RfCloseTagIcon.vue";

export default {
  props: { clear: { type: Boolean, default: false } },
  components: { RfCloseTagIcon },
};
</script>
