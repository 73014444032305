<template>
  <div class="promo-design-group">
    <div class="promo-timer-setting">
      <div class="div-flex-layout" style="margin-bottom: 15px">
        <v-text-field
          outlined
          dense
          label="Auto close in seconds"
          :error-messages="errors.first('close-seconds')"
          v-model.trim="actions.rf_settings_close_seconds"
          name="close-seconds"
          hide-details
          placeholder="e.g.: 20"
          background-color="#ffffff"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Auto close text"
          :error-messages="errors.first('close-seconds')"
          v-model.trim="
            currentTranslations.hasOwnProperty('rf_settings_close_seconds_text')
              ? previewActions.rf_settings_close_seconds_text
              : actions.rf_settings_close_seconds_text
          "
          :disabled="currentTranslations.hasOwnProperty('rf_settings_close_seconds_text')"
          name="close-seconds"
          hide-details
          placeholder="e.g.: 20"
          background-color="#ffffff"
          v-if="
            (model.path_type === 'retention_modal' ||
              model.path_type === 'video' ||
              model.path_type === 'interstitial' ||
              model.path_type === 'widget') &&
            !hideAutoCloseText
          "
        ></v-text-field>
      </div>
      <div class="div-flex-layout">
        <v-text-field
          outlined
          dense
          label="Timer font size"
          v-model.trim="actions.rf_settings_timer_font_size"
          name="close-seconds"
          hide-details
          placeholder="e.g.: 14px"
          v-if="!displayOff"
          background-color="#ffffff"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          label="Timer font color"
          v-model.trim="actions.rf_settings_timer_font_color"
          name="close-seconds"
          hide-details
          placeholder="e.g.: #ffffff"
          v-if="!displayOff"
          background-color="#ffffff"
        ></v-text-field>
      </div>
      <RfPromoInteractionInterval
        interactionType="timeout"
        :action="actions.rf_settings_timeout_interval"
        v-on:setIntervalAction="setIntervalAction"
        v-if="actions.rf_settings_close_seconds"
      />
      <div class="div-flex-layout" style="margin-bottom: 15px">
        <v-checkbox
          v-model="actions.rf_settings_hide_timer_text"
          value="true"
          label="Hide timer text"
          hide-details
        />
      </div>
    </div>
  </div>
</template>
<script>
import RfPromoInteractionInterval from "@/components/RfPromotions/RfPromoInteractionInterval.vue";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";

export default {
  name: "RfRetentionActionsBehavior",
  props: {
    actions: Object,
    model: Object,
    displayOff: { type: Boolean, default: false },
    hideAutoCloseText: { type: Boolean, default: false },
  },
  components: { RfPromoInteractionInterval },
  mixins: [LanguagePreviewMixin],
  methods: {
    setIntervalAction(value, type) {
      this.actions[`rf_settings_${type}_interval`] = value;
    },
  },
};
</script>

<style>
.countdown-text-div {
  padding: 0 0 10px 0;
  font-weight: 500;
}
.promo-timer-setting {
  display: flex;
  flex-direction: row;
}
.promo-timer-setting > div.v-input {
  flex: 1;
  padding-right: 16px;
}
.promo-timer-setting > div.v-input:last-child {
  flex: 1;
  padding-right: 0px;
}
</style>
