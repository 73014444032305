<template>
  <div>
    <div class="no-data-img">
      <img src="https://assets.redfastlabs.com/images/pulse/no_data.png" />
    </div>
    <div style="padding-bottom: 20px; text align:center">
      <h3>Nothing to show.</h3>
      <div>{{ message }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NothingToShow",
  props: ["message"],
};
</script>
