import { mapState, mapActions } from "vuex";

export default {
  data: () => ({ pipelineStage: null }),
  computed: { ...mapState({ pipelines: state => state.apps.pipelines }) },
  methods: {
    ...mapActions(["getPipelines"]),
    allValuesAllowed(item, values) {
      if (item.display_type === "slider") return +values[0] === 0 && +values[1] === 10;
    },
    parentShouldOverride(item, parent) {
      const parentFilter = parent.custom_filter;
      const parentSetting = parentFilter[item.label] || {};
      const values = parentSetting.values || [];
      if (parentSetting && values.length && !this.allValuesAllowed(item, values)) {
        return true;
      }
    },
    parentOverrides(item) {
      const modelPipeline = this.modelPipeline || this.pipeline;
      if (this.objectType === "pipeline_stage" && modelPipeline) {
        if (this.parentShouldOverride(item, modelPipeline)) return true;
      } else if (this.objectType === "segment" && this.model.pipeline_stage_id && this.pipelines) {
        if (!this.pipelineStage || !modelPipeline) {
          // only do the lookup once
          let stage;
          const pipeline = this.pipelines.find(
            p => !!p.pipeline_stages.find(s => s.id === this.model.pipeline_stage_id),
          );
          if (stage) this.pipelineStage = stage;
          if (pipeline) this.modelPipeline = pipeline;
        }
        if (this.modelPipeline && this.parentShouldOverride(item, this.modelPipeline)) return true;
        if (this.pipelineStage && this.parentShouldOverride(item, this.pipelineStage)) return true;
      }
    },
  },
  async mounted() {
    if (this.objectType === "segment" && this.model.pipeline_stage_id && !this.pipelines.length) {
      await this.getPipelines({ appId: this.$route.params.aid });
    }
    if (this.initFields) {
      this.initFields();
    }
  },
};
