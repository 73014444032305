<template>
  <div>
    <!-- extra div to match brig -->
    <div>
      <RfPromoInputsPreview :actions="actions" :isInline="true" />
      <button
        text
        class="promo-tile-wrapper-btn-accept"
        :style="acceptButtonStyle"
        v-html="actions.rf_retention_button1_text"
        v-if="actions.rf_retention_button1_text"
      />
      <button
        text
        class="promo-tile-wrapper-btn-accept btn-ac-2"
        :style="acceptButtonStyle2"
        v-html="actions.rf_retention_button2_text"
        v-if="actions.rf_settings_confirm_button_2_enabled && actions.rf_retention_button2_text"
      />
      <button
        text
        class="promo-tile-wrapper-btn-no"
        :style="cancelButtonStyle"
        v-html="actions.rf_retention_button3_text"
        v-if="
          actions.rf_settings_cancel_button_enabled === 'true' && actions.rf_retention_button3_text
        "
      />
    </div>
  </div>
</template>

<script>
import RfPromoInputsPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfPromoInputsPreview.vue";

export default {
  name: "RfRetentionTileButtons",
  components: { RfPromoInputsPreview },
  props: ["actions"],
  computed: {
    buttonWidth() {
      return this.actions.rf_settings_text_container_max_width === "full" ? "100%" : "auto";
    },
    acceptButtonStyle() {
      return {
        "color": this.actions.rf_retention_button1_color,
        "background-color": this.actions.rf_settings_background_color,
        "width": this.buttonWidth,
        "borderRadius": this.actions.rf_email_button_radius,
      };
    },
    acceptButtonStyle2() {
      return {
        "color": this.actions.rf_retention_button2_text_color,
        "background-color": this.actions.rf_settings_accept2_button_background_color,
        "width": this.buttonWidth,
        "borderRadius": this.actions.rf_email_button_radius,
      };
    },
    cancelButtonStyle() {
      return {
        width: this.buttonWidth,
        color: this.actions.rf_retention_button3_color,
        borderRadius: this.actions.rf_email_button_radius,
      };
    },
  },
};
</script>
