export default {
  props: ["webOnly"],
  data() {
    return {
      deviceTypes: [
        {
          value: "web",
          name: "Desktop & Mobile Web",
          description:
            "Works on Desktop and mobile browsers. Supports Safari, Chrome, Firefox, Edge, and Internet Explorer.",
          title: "Custom Prompt for Desktop and Mobile Web",
        },
        {
          value: "ios",
          name: "iPhone & iPad",
          description:
            "Works on Apple iOS iPhone and iPad native apps. Requires the addition of the Redfast iOS SDK to your app.",
          title: "Prompt type for iPhone and iPad",
        },
        {
          value: "tv_os",
          name: "Apple TV",
          description:
            "Works on Apple TV second generation or later running tvOS. Requires the addition of Redfast tvOS SDK to your app.",
          title: "Prompt type for Apple TV",
        },
        {
          value: "android_os",
          name: "Android & Fire TV",
          description:
            "Works on Android phone, tablet, TV devices and Fire TV. Requires the addition of the Redfast Android SDK to your app.",
          title: "Prompt type for Android Tablet, TV, and Fire TV",
        },
        {
          value: "roku_os",
          name: "Roku",
          description:
            "Works on Roku devices including Roku TV. Requires the addition of Redfast Roku SDK to your app.",
          title: "Prompt type for Roku",
        },
        {
          value: "custom_defined",
          name: "Custom",
          description: "Used for any other purposes other than default var",
          title: "Prompt type for custom devices",
        },
      ],
    };
  },
};
