<template>
  <div class="modal-overlay-style" :class="modalWrapperSize" :style="overlayBackgroundColor">
    <div
      class="rfmodal-content outer-modal rf-device-web rf-modal-content-wrapper center-modal"
      :style="modalWrapperStyle"
    >
      <div class="rfmodal-wrapper inner-modal" ref="rfmodal">
        <RfRetentionMessage
          :actions="actions"
          :isNewDeviceEditor="isNewDeviceEditor"
          :isMobile="isMobile"
        />
      </div>
      <div :style="containerBackgroundImage" class="rfmodal-backgroundimage"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RfRetentionMessage from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionMessage.vue";
import { bottomLeft, bottomRight, topLeft, topRight } from "@/utils/constants/PromptStyleConstants";
import { baseUrlReplacer } from "@/utils/BaseUrlReplacer";

export default {
  name: "RfRetentionModalContent",
  components: { RfRetentionMessage },
  props: {
    actions: Object,
    deviceType: String,
    model: Object,
    zoomStyle: Object,
    isNewDeviceEditor: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
    containerBackgroundImage() {
      const styles = {
        "background-image": "url(https://assets.redfastlabs.com/images/bg/9.jpg)",
        "background-color": this.actions.rf_settings_bg_image_color,
      };
      if (this.actions.rf_settings_bg_image) {
        styles["background-image"] =
          `url(${baseUrlReplacer(this.currApp, this.actions.rf_settings_bg_image)})`;
      } else {
        styles["background-image"] = "none";
      }
      return styles;
    },
    modalWrapperSize() {
      if (this.deviceType === "ipadLandscape") {
        return `retention-modal-preview-ipad${this.isNewDeviceEditor ? " new" : ""}`;
      }
      if (this.deviceType === "ipadPortrait") {
        return "retention-modal-preview-ipad-portrait";
      }
      if (this.deviceType === "tv_os") {
        return `retention-modal-preview-tvos${this.isNewDeviceEditor ? " new" : ""}`;
      }
      if (this.deviceType === "desktop") {
        return `div--${this.actions.rf_settings_window_max_width}`;
      }
      if (this.deviceType === "androidLandscape") {
        return "retention-modal-preview-android-tablet";
      }
      if (this.deviceType === "androidPortrait") {
        return "retention-modal-preview-android-tablet-portrait";
      }
      if (this.deviceType === "androidPhone") {
        return "retention-modal-preview-android-phone";
      }
      if (this.deviceType === "androidSquare") {
        return "retention-modal-preview-android-square";
      }
      if (this.deviceType === "androidTV") {
        return "retention-modal-preview-android-tv";
      }
    },
    getNewEditorWidth() {
      if (!this.isNewDeviceEditor) return {};
      const dimensions = this.deviceType === "androidSquare" ? "1024x1024" : "1024x768";
      const [width, height] = dimensions.split("x");
      return { width: `${width}px`, height: `${height}px`, aspectRatio: "unset" };
    },
    modalWrapperStyle() {
      return {
        width: this.actions.rf_settings_window_max_width,
        ...this.getNewEditorWidth,
        ...this.zoomStyle,
        ...(this.model.device_type === "web" && {
          ...Object.fromEntries(
            [topRight, topLeft, bottomRight, bottomLeft].map(({ value }) => [
              `border-${value}-radius`,
              this.actions[`rf_retention_border-${value}-radius`],
            ]),
          ),
          ["border-width"]: this.actions["rf_retention_border-width"],
          ["border-style"]: this.actions["rf_retention_border-style"],
          ["border-color"]: this.actions["rf_retention_border-color"],
          ["box-sizing"]: this.actions["rf_retention_box-sizing"],
          ["box-shadow"]: this.actions["rf_retention_box-shadow"],
        }),
      };
    },
    overlayBackgroundColor() {
      if (this.deviceType === "desktop")
        return {
          background: this.actions.rf_settings_overlay_background || "#252323B3",
        };
    },
  },
};
</script>
