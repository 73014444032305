<template>
  <div class="mweb-widget-container">
    <div class="mweb-phone-kit-bg relative">
      <div class="mweb-canvas-bound relative">
        <span
          class="rfmodal-close inline-flex flex-row-reverse"
          v-if="actions.rf_settings_close_button_enabled === 'true'"
        >
          <v-icon id="rfmodal-close-icon my-auto" :style="{ color: actions.rf_settings_fill_color }"
            >close</v-icon
          >
          <RfPromptTimeout :actions="actions" class="my-auto mr-2" newVariant />
        </span>
        <div class="h-full w-full bg-blue-1" :style="containerMobileWebBackgroundImage">
          <div class="flex h-7/10">
            <div class="my-auto text-center">
              <div :style="titleStyle">
                {{ actions[isNewIosEditor ? "rf_retention_title" : "rf_mobile_title"] }}
              </div>
              <div :style="messageStyle">
                {{ actions[isNewIosEditor ? "rf_retention_message" : "rf_mobile_message"] }}
              </div>
            </div>
          </div>
          <div class="flex h-3/10 flex-col">
            <div
              ref="buttonContainer"
              class="mweb-btn-container flex flex-shrink-0 flex-grow flex-col"
            >
              <button
                v-if="!isNewDeviceEditor || actions.rf_retention_button1_text"
                v-html="actions.rf_retention_button1_text"
                class="mx-8 rounded-full text-lg"
                :style="{
                  height: `${buttonHeight}px`,
                  marginTop: `${buttonPadding}px`,
                  ...acceptButtonStyle,
                }"
              ></button>
              <button
                v-if="
                  isNewDeviceEditor
                    ? actions.rf_settings_confirm_button_2_enabled &&
                      actions.rf_retention_button2_text
                    : actions.rf_settings_confirm_button_2_enabled
                "
                v-html="actions.rf_retention_button2_text"
                class="mx-8 rounded-full text-lg"
                :style="{
                  height: `${buttonHeight}px`,
                  marginTop: `${buttonPadding}px`,
                  ...secondaryButtonStyle,
                }"
              ></button>
              <button
                v-if="
                  isNewDeviceEditor
                    ? actions.rf_settings_cancel_button_enabled && actions.rf_retention_button3_text
                    : actions.rf_settings_cancel_button_enabled
                "
                v-html="actions.rf_retention_button3_text"
                class="mx-8 rounded-full text-lg"
                :style="{
                  height: `${buttonHeight}px`,
                  marginTop: `${buttonPadding}px`,
                  ...cancelButtonStyle,
                }"
              ></button>
            </div>
            <RfRetentionLinksPreview class="legal" :actions="actions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PromoPreviewMixin from "@/utils/PromoPreviewMixin";
import { computed, ref } from "vue";
import RfRetentionLinksPreview from "./components/RfRetentionLinksPreview.vue";
import RfPromptTimeout from "./components/RfPromptTimeout.vue";

export default {
  props: {
    model: Object,
    actions: Object,
    isNewDeviceEditor: { type: Boolean, default: false },
  },
  components: { RfRetentionLinksPreview, RfPromptTimeout },
  mixins: [PromoPreviewMixin],
  setup: props => {
    const buttonContainer = ref(null);

    const numberOfButtons = computed(() => {
      let n = 0;
      if (!props.isNewDeviceEditor || props.actions.rf_retention_button1_text) n++;
      if (
        props.isNewDeviceEditor
          ? props.actions.rf_settings_confirm_button_2_enabled &&
            props.actions.rf_retention_button2_text
          : props.actions.rf_settings_confirm_button_2_enabled
      )
        n++;
      if (
        props.isNewDeviceEditor
          ? props.actions.rf_settings_cancel_button_enabled &&
            props.actions.rf_retention_button3_text
          : props.actions.rf_settings_cancel_button_enabled
      )
        n++;
      return n;
    });

    const buttonHeight = computed(
      () => buttonContainer.value?.clientHeight / (numberOfButtons.value + 1),
    );

    const buttonPadding = computed(
      () =>
        (buttonContainer.value?.clientHeight - numberOfButtons.value * buttonHeight.value) /
        (numberOfButtons.value + 1),
    );

    const containerMobileWebBackgroundImage = computed(() => {
      const bgColor = { "background-color": props.actions.rf_settings_bg_image_color };
      if (props.actions.rf_settings_mobile_bg_image) {
        return {
          ...bgColor,
          "background-image": `url(${props.actions.rf_settings_mobile_bg_image})`,
        };
      }
      if (props.actions.rf_settings_bg_image) {
        return {
          ...bgColor,
          "background-image": `url(${props.actions.rf_settings_bg_image})`,
        };
      }
      return {
        ...bgColor,
        "background-image": "none",
      };
    });

    return { buttonContainer, buttonHeight, buttonPadding, containerMobileWebBackgroundImage };
  },
  computed: {
    isNewIosEditor() {
      return this.isNewDeviceEditor && this.model.device_type === "ios";
    },
    titleStyle() {
      return {
        "font-size": this.isNewIosEditor
          ? this.actions.rf_settings_title_font_size
          : this.actions.rf_settings_mobile_title_font_size,
        "color": this.actions.rf_settings_fill_color,
        "margin-top": this.isNewIosEditor
          ? this.actions.rf_settings_title_padding_top
          : this.actions.rf_settings_mobile_title_padding_top,
      };
    },
    messageStyle() {
      return {
        "font-size": this.isNewIosEditor
          ? this.actions.rf_settings_message_font_size
          : this.actions.rf_settings_mobile_message_font_size,
        "color": this.actions.rf_settings_fill_color,
      };
    },
  },
};
</script>

<style scoped>
::v-deep.legal .modal-privacy-tos {
  position: relative;
  left: 0;
  bottom: 0;
  padding-bottom: 8px;
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
