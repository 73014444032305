import { mapState } from "vuex";
import { DeviceTypeWording } from "./constants/DevicesConstants";
import { PromptTypes } from "./constants/PromoTypesConstants";

export default {
  methods: {
    getPromoDeviceTypeWording(type) {
      return DeviceTypeWording[type]?.text;
    },
    getPromoTypeWording(type) {
      return PromptTypes[type]?.label;
    },
    typeWithDevice(item) {
      const type = PromptTypes[item.path_type].label;
      let deviceType = DeviceTypeWording[item.device_type].text;
      if (this.customDevicesEnabled && deviceType === "Custom") {
        deviceType = item.custom_devices.map(el => el.name).join(", ");
      }

      return `${deviceType}: ${type}`;
    },
  },
  computed: {
    ...mapState({ currApp: state => state.apps.currApp }),
    customDevicesEnabled() {
      return this.currApp && this.currApp.flags && this.currApp.flags.custom_devices;
    },
  },
};
