<template>
  <v-card class="rf-setting-card">
    <div v-if="title" class="rf-category-header">
      <span class="rf-category-title">{{ title }}</span>
    </div>
    <div class="rf-category-content-div">
      <slot>
        <p>Place your card content here</p>
      </slot>
    </div>
  </v-card>
</template>

<script>
export default { name: "RfSimpleCard", props: ["title"] };
</script>
