<template>
  <div class="text-body flex items-center !text-black-2">
    <template v-if="props.promptTags.length">
      <RfColorTag
        v-for="tag in props.promptTags"
        pill
        class="!p-0 *:!py-1"
        disabled
        :backgroundColor="tag.color"
      >
        <span class="inline-flex items-center gap-1">
          <span>{{ tag.title }}</span>
          <RfButtonNew type="none" size="none" :disabled="disabled" @click="emit('removeTag', tag)">
            <RfCloseTagIcon class="!h-5 !w-5" />
          </RfButtonNew>
        </span>
      </RfColorTag>
    </template>

    <RfMenu ref="tagsMenu" hideIcon preset="datePicker" :disabled="disabled">
      <template #activator>
        <RfButtonNew
          type="none"
          size="none"
          class="text-body !px-2"
          text="+ Add tag"
          :disabled="disabled"
        />
      </template>
      <template #menu>
        <div class="flex w-64 flex-col gap-4 p-4">
          <RfTextInput
            dense
            outlined
            :hide-details="!!filteredTags.length"
            :persistent-hint="!filteredTags.length"
            :counter="filteredTags.length ? null : 15"
            :maxlength="filteredTags.length ? null : 15"
            :class="{ '-mb-4': !filteredTags.length }"
            placeholder="Tag name"
            v-model.trim="newTag.title"
          ></RfTextInput>
          <template v-if="filteredTags.length">
            <div class="flex max-h-28 flex-wrap items-start justify-start gap-2 overflow-auto">
              <RfColorTag
                v-for="tag in filteredTags"
                class="!p-0 *:!py-1"
                pill
                disabled
                :backgroundColor="tag.color"
              >
                <span class="inline-flex items-center gap-1">
                  <span>{{ tag.title }}</span>
                  <RfButtonNew
                    type="none"
                    size="none"
                    :disabled="disabled"
                    @click="emit('assignTag', tag)"
                  >
                    <RfPlusMiniIcon class="!h-5 !w-5" />
                  </RfButtonNew>
                </span>
              </RfColorTag>
            </div>
            <div class="h-px w-full bg-background"></div>
            <RouterLink
              :to="`/apps/${appId}/settings/tags`"
              class="text-buttons flex items-center justify-between !text-blue-1"
            >
              Open in settings <RfLinkIcon class="!h-6 !w-6 !stroke-blue-1" />
            </RouterLink>
          </template>
          <template v-else>
            <RfTextInput
              dense
              outlined
              hide-details
              data-cy="element-info--name"
              placeholder="A tag for..."
              title="Description (optional)"
              v-model.trim="newTag.description"
            ></RfTextInput>
            <div class="flex flex-col gap-1">
              <div>Color</div>
              <div class="flex flex-wrap gap-1">
                <RfColorTag
                  v-for="color in tagsColors"
                  :backgroundColor="color"
                  class="h-8 w-8 flex-shrink-0"
                  :selected="newTag.color === color"
                  @click="newTag.color = color"
                />
              </div>
            </div>
            <div class="h-px w-full bg-background"></div>
            <div class="flex justify-end">
              <RfButtonNew
                type="none"
                size="none"
                class="text-buttons inline-flex !text-blue-1"
                :disabled="disabled"
                @click="
                  emit('createTag', newTag),
                    (newTag.title = ''),
                    (newTag.description = ''),
                    (newTag.color = tagsColors[0]),
                    tagsMenu?.hide?.()
                "
              >
                Add new tag
              </RfButtonNew>
            </div>
          </template>
        </div>
      </template>
    </RfMenu>
  </div>
</template>

<script setup>
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import RfCloseTagIcon from "@/components/icons/RfCloseTagIcon.vue";
import RfLinkIcon from "@/components/icons/RfLinkIcon.vue";
import RfPlusMiniIcon from "@/components/icons/RfPlusMiniIcon.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfMenu from "@/components/menus/RfMenu.vue";
import RfColorTag from "@/components/tags/RfColorTag.vue";
import { tagsColors } from "@/utils/constants/ColorsConstants";
import { computed, reactive, ref } from "vue";
import { RouterLink } from "vue-router";

const props = defineProps({
  appId: { type: String, default: "" },
  appTags: { type: Array, default: () => [] },
  promptTags: { type: Array, default: () => [] },
  disabled: { type: Boolean, default: true },
});

const tagsMenu = ref();
const emit = defineEmits(["removeTag", "assignTag", "createTag"]);
const newTag = reactive({ title: "", description: "", color: tagsColors[0] });
const filteredTags = computed(() =>
  props.appTags.filter(
    ({ title, id }) =>
      title.toLowerCase().includes(newTag.title.toLowerCase()) &&
      props.promptTags.every(tag => tag.id !== id),
  ),
);
</script>
