<template>
  <div class="contents">
    <RfBaseModal ref="connectorModal" noButtons title="Add action" width="960">
      <template #body>
        <RfButtonTabs v-if="!isEditing" :active="tab" class="mb-4">
          <RfButtonNew
            v-for="({ value }, _, i) in actionTypes"
            type="none"
            class="m-0 box-border rounded-none border-0 px-4 py-2 !text-black-2"
            :class="{ '!font-bold': i === tab }"
            @click="tab = i"
          >
            {{ value }}
          </RfButtonNew>
        </RfButtonTabs>
        <div class="modal-actions-title">
          <span>Add an action upon</span>
          <v-select
            outlined
            hide-details
            dense
            required
            v-model="interactionType"
            :items="interactionTypes"
            height="34"
            class="mr-3 max-w-60"
          />
          <span v-if="showSurveyOptions"> if selected option is </span>
          <v-select
            v-if="showSurveyOptions"
            outlined
            hide-details
            persistent-placeholder
            dense
            required
            v-model="surveyOptionSelected"
            :disabled="selectedWebsiteAction === 'rf_settings_redirect_location'"
            :items="surveyOptions"
            height="34"
            class="max-w-60"
          />
        </div>
        <RfRetentionConnectors
          v-if="connectorsDialog"
          :model="prompt"
          :interactionType="interactionType"
          :surveyOptionSelected="surveyOptionSelectedValue"
          :editIntegration="editIntegrationTemplate"
          :editIntegrationValue="editIntegrationValue"
          :isEditing="isEditing"
          :actions="prompt.actions"
          :thirdParty="thirdPartyData"
          :clientActions="appClientActions"
          :selectedActionTab="actionType"
          :actionGroupClientActions="clientActions"
          newBehaviour
          @selectedWAChanged="v => (selectedWebsiteAction = v)"
          @closeConnectorDialog="close"
          @addIntegration="addIntegration"
          @setActionGroupClientActions="v => $emit('updateClientActions', v)"
        />
      </template>
    </RfBaseModal>
    <RfBaseModal
      ref="advancedArgsModal"
      title="Advanced Arguments"
      width="550"
      :disabledPrimary="advancedArgsBody?.hasError"
      primaryText="Done"
      @primary="submitArgs"
    >
      <template #body>
        <RfAdvancedArguments ref="advancedArgsBody" />
      </template>
    </RfBaseModal>
    <RfBaseModal
      v-if="actionToDelete"
      ref="statusExperimentModal"
      title="Delete action confirmation"
      message="Are you sure you want to delete this action?"
      width="700"
      errorText="Delete action"
      @error="$emit('deleteAction', actionToDelete)"
      @close="actionToDelete = null"
    >
    </RfBaseModal>
  </div>
</template>

<script>
import RfAdvancedArguments from "@/blocks/RfAdvancedArguments.vue";
import RfRetentionConnectors from "@/components/RfPathCreate/RfRetentionActions/RfRetentionConnectors.vue";
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import RfButtonTabs from "@/components/buttons/RfButtonTabs.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import PromoActionMixin from "@/utils/PromoActionMixin";
import StringUtils from "@/utils/StringUtils";
import { fetchValues, isActionDisabled } from "@/utils/prompts/promptActionHelpers";
import { isSurvey } from "@/utils/prompts/promptHelpers";
import { cloneDeep } from "lodash-es";
import { computed, ref } from "vue";
import { mapActions, mapState } from "vuex";

const actionTypes = {
  connectors: { value: "External & API Actions", key: "connectors" },
  client_actions: { value: "Website Actions", key: "client_actions" },
};

export default {
  name: "RfPromptActionsModals",
  mixins: [PromoActionMixin],
  components: {
    RfBaseModal,
    RfButtonNew,
    RfButtonTabs,
    RfRetentionConnectors,
    RfAdvancedArguments,
  },
  props: {
    app: { type: Object, default: () => ({}) },
    prompt: { type: Object, default: () => ({}) },
    currLanguage: { type: String, default: null },
    currAppConnectors: { type: Object, default: () => ({}) },
    appClientActions: { type: Array, default: () => [] },
    clientActions: { type: Array, default: () => [] },
    clientActionObjects: { type: Array, default: () => [] },
  },
  setup: props => {
    const connectorModal = ref();
    const interactionType = ref("accept");
    const surveyOptionSelected = ref("any");
    const selectedWebsiteAction = ref(null);
    const tab = ref(0);
    const connectorsDialog = ref(false);
    const advancedArgsModal = ref();
    const advancedArgsBody = ref();
    const statusExperimentModal = ref();
    const editArgsItem = ref({});
    const actionToDelete = ref({});
    const editIntegrationItem = ref({});
    const editIntegrationValue = ref({});
    const editIntegrationTemplate = ref({});
    const isEditing = ref(false);

    return {
      connectorModal,
      interactionType,
      surveyOptionSelected,
      selectedWebsiteAction,
      tab,
      connectorsDialog,
      actionTypes,
      advancedArgsModal,
      advancedArgsBody,
      editArgsItem,
      actionToDelete,
      statusExperimentModal,
      editIntegrationItem,
      isEditing,
      editIntegrationValue,
      editIntegrationTemplate,
      actionType: computed(
        () => (Object.values(actionTypes)?.[tab.value] || actionTypes.connectors).key,
      ),
      model: computed(() => props.prompt),
      actions: computed(() => props.prompt.actions),
    };
  },
  computed: {
    ...mapState({
      connectorActions: state => state.apps.connectorActions || [],
    }),
    surveyOptionSelectedValue() {
      return this.showSurveyOptions ? this.surveyOptionSelected : "any";
    },
    surveySelected() {
      return isSurvey(this.prompt);
    },
    surveyOptions() {
      const options = ["any"];
      const optionsTotal = +(this.prompt.actions.rf_retention_survey_options_total || 3);
      if (this.surveySelected) {
        for (let i = 1; i <= optionsTotal; i += 1) {
          options.push(this.prompt.actions[`rf_retention_survey_option_${i}_value`]);
        }
      }
      return options;
    },
    optionsTotal() {
      return +(this.prompt.actions?.rf_retention_survey_options_total || 3);
    },
    showSurveyOptions() {
      return (
        this.actionType === actionTypes.client_actions.key &&
        this.interactionType === "accept" &&
        this.surveySelected
      );
    },
    thirdPartyData() {
      const providers = {
        custom_provider: { name: "Custom_provider", actions: [] },
        redfast: { name: "Redfast", actions: [] },
        sendgrid: { name: "Sendgrid", actions: [] },
        salesforce: { name: "Salesforce", actions: [] },
        braze: { name: "Braze", actions: [] },
        stripe: { name: "Stripe", actions: [] },
        vindicia: { name: "Vindicia", actions: [] },
        braintree: { name: "Braintree", actions: [] },
        zendesk: { name: "Zendesk", actions: [] },
        shopify: { name: "Shopify", actions: [] },
        active_campaign: { name: "ActiveCampaign", actions: [] },
      }; // preserve order of providers
      if (!this.connectorActions.length) {
        return providers;
      }
      this.connectorActions.forEach(connectorAction => {
        const providerName = connectorAction.provider;
        const existingProvider = providers[providerName];
        const item = this.createThirdPartyItem(connectorAction);
        if (existingProvider) {
          existingProvider.actions.push(item);
        } else {
          const actions = [item];
          providers[providerName] = {
            name: StringUtils.capitalize(providerName),
            actions,
          };
        }
      });
      for (const provider in providers) {
        if (!providers[provider].actions.length) {
          delete providers[provider];
        }
      }
      return providers;
    },
  },
  mounted() {
    if (!this.connectorActions.length) {
      this.getConnectorActions({ appId: this.app.id });
    }
  },
  methods: {
    ...mapActions(["getConnectorActions"]),
    editIntegration(actionData) {
      this.interactionType = actionData.interaction_type;
      this.editIntegrationItem = actionData;
      this.editIntegrationTemplate = this.createThirdPartyItem(actionData.connector_action);
      this.editIntegrationValue = cloneDeep(actionData.args);
      this.isEditing = true;
      this.show();
    },
    closeDelete() {
      this.actionToDelete = null;
      this.$nextTick(() => this.statusExperimentModal.close?.());
    },
    deleteAction(value) {
      this.actionToDelete = value;
      this.$nextTick(() => this.statusExperimentModal.show?.());
    },
    editArgs(action) {
      this.editArgsItem = action;
      const fieldData = (this.clientActionObjects || []).find(
        ({ id }) => id === action.connector_action.id,
      )?.arg_metadata;

      this.advancedArgsModal.show?.();
      this.$nextTick(() =>
        this.advancedArgsBody.init?.(
          fieldData && !Object.keys(action.args || []).length ? [""] : action.args,
          fieldData,
        ),
      );
    },
    submitArgs() {
      this.$emit("updateClientActions", {
        ...this.editArgsItem,
        args: this.advancedArgsBody.get?.(),
      });
      this.advancedArgsModal.close?.();
      this.editArgsItem = {};
    },
    show() {
      this.connectorModal.show?.();
      this.connectorsDialog = true;
    },
    close() {
      this.connectorModal.close?.();
      this.connectorsDialog = false;
      this.isEditing = false;
      this.tab = 0;
    },
    addIntegration(integrationValue, selectedIntegration) {
      if (this.surveyOptionSelectedValue !== "any") {
        integrationValue = {
          ...integrationValue,
          survey_option_selected: this.surveyOptionSelectedValue,
        };
      }

      let action;

      if (this.isEditing) {
        action = {
          args: integrationValue,
          connector_action: this.editIntegrationItem.connector_action,
          interaction_type: this.interactionType,
          id: this.editIntegrationItem.id,
        };
      } else {
        const connector = this.connectorActions.find(
          action =>
            selectedIntegration.operation === action.operation &&
            selectedIntegration.provider === action.provider &&
            selectedIntegration.description === action.args.description,
        );
        action = {
          args: integrationValue,
          connector_action: connector,
          interaction_type: this.interactionType,
        };
      }

      this.$emit("updateClientActions", action);
      this.interactionType = "accept";
      this.surveyOptionSelected = "any";
      this.editIntegrationItem = {};
      this.editIntegrationValue = {};
      this.editIntegrationTemplate = {};
      this.close();
    },
    createThirdPartyItem(connectorAction) {
      const providerName = connectorAction.provider;
      const { args } = connectorAction;
      return {
        provider: providerName,
        operation: connectorAction.operation,
        description: args.description,
        disabled: isActionDisabled(args, this.interactionType, this.prompt, this.app.providers),
        inputs: args.inputs.map(input => {
          return {
            valueDescription: input.value_description,
            type: input.input_type,
            hideId: input.hide_id,
            values: fetchValues(input, this.currAppConnectors, this.prompt, this.currLanguage),
            valueLabel: input.value_label,
            counter: input.character_counter,
            defaultValue: input.default_value,
            placeholder: input.value,
            isRequired: !input.optional,
          };
        }),
      };
    },
  },
};
</script>
