<template>
  <div class="pipelines-promo-create-modal" v-if="pipelineStageId">
    <div class="pp-create-modal-header">
      <h3>Pipeline stages</h3>
      <div class="pp-create-modal-blurbs">Prompt will be shown in between the selected stages.</div>
    </div>
    <div class="pipeline-segments-slct-div">
      <div class="ppl-sgmnts-col-1" v-if="selectedSegments">
        <h4>{{ pipelineStageName }}</h4>
        <RfLinkedSegments
          :model="segmentModel"
          ref="segments"
          :pipelineStageId="segmentStageId"
          :key="pipelineStageId"
          pipelineSegment
        />
      </div>
      <div class="ppl-sgmnts-col-2"></div>
      <div class="ppl-sgmnts-col-3 pipeline-segments-stg-tr">
        <h4>Target stage</h4>
        <RfStageSelection
          :pipelineStageId="pipelineStageId"
          ref="stageTransition"
          :key="pipelineStageId"
          :segments="selectedSegments"
          :pipelineId="pipelineId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RfLinkedSegments from "@/components/RfPathCreate/RfLinkedSegments.vue";
import RfStageSelection from "@/components/RfPromotions/RfStageSelection.vue";

export default {
  name: "RfStageAndSegments",
  components: {
    RfLinkedSegments,
    RfStageSelection,
  },
  props: ["pipelineStageId", "selectedSegments", "pipelineId", "pipelineStageName"],
  computed: {
    segmentModel() {
      return { segments: this.selectedSegments };
    },
    segmentStageId() {
      if (this.selectedSegments && this.selectedSegments.length) {
        return this.selectedSegments[0].pipeline_stage_id;
      }
    },
  },
  methods: {
    fillStageTransitionModel(model) {
      if (this.$refs.stageTransition) {
        this.$refs.stageTransition.fillModel(model);
      }
    },
    fillSegmentModel(model) {
      if (this.$refs.segments) {
        this.$refs.segments.fillModel(model);
      }
    },
  },
};
</script>
