<template>
  <div v-if="!isDisabledRole">
    <RfBaseModal
      v-if="!isDisabledByActiveExperiment"
      ref="segmentsModal"
      title="Segments"
      width="720"
      @primary="updateSegments"
    >
      <template #body>
        <RfLinkedSegments
          :model="prompt"
          ref="linkedSegments"
          :key="prompt.id"
          :pipelineStageId="pipelineStageId"
        />
      </template>
    </RfBaseModal>

    <RfBaseModal
      v-if="!isDisabledByActiveExperiment"
      ref="triggersModal"
      title="Triggers"
      width="1200"
      @primary="updateTriggers"
    >
      <template #body>
        <RfTrigger :model="prompt" ref="triggers" :triggerType="triggerType" />
      </template>
    </RfBaseModal>

    <RfBaseModal
      v-if="!isDisabledByActiveExperiment"
      ref="limitsModal"
      title="Limit this prompt to:"
      width="600"
      @primary="updateLimits"
    >
      <template #body> <RfTraffic :model="prompt" ref="traffic" /> </template>
    </RfBaseModal>

    <RfBaseModal
      ref="scheduleModal"
      title="When should it be shown"
      width="750"
      @primary="updateSchedule"
    >
      <template #body>
        <div class="flex flex-col gap-3">
          <v-tabs v-show="scheduleTabs.length > 1" class="-mt-6" v-model="scheduleType" grow>
            <v-tab v-for="item in scheduleTabs" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
          <RfSchedule
            :model="prompt"
            ref="schedule"
            :isPushNotification="isPushNotification"
            :scheduleType="scheduleTabs[scheduleType]"
          />
        </div>
      </template>
    </RfBaseModal>

    <RfBaseModal
      v-if="!isDisabledByActiveExperiment"
      ref="intervalModal"
      title="Change interval"
      width="600"
      @primary="updateInterval"
      @show="
        () => {
          permanentDisplay = props.prompt.actions.rf_settings_permanent_display;
          acceptInterval = props.prompt.actions.rf_settings_accept_interval;
        }
      "
    >
      <template #body>
        <div class="d-flex flex-column">
          <v-radio-group v-model="permanentDisplay" class="mt-0 pt-0" hide-details row dense>
            <v-radio value="false" label="Show based on timer" />
            <v-radio value="true" label="Show Repeatedly" />
          </v-radio-group>
          <RfPromoInteractionInterval
            v-if="permanentDisplay === 'false'"
            interactionType="accept"
            :key="acceptInterval"
            :action="acceptInterval"
            @setIntervalAction="v => (acceptInterval = v)"
          />
        </div>
      </template>
    </RfBaseModal>

    <RfCustomDevicesModal ref="rfCustomDevicesModal" />
  </div>
</template>

<script setup>
import RfLinkedSegments from "@/components/RfPathCreate/RfLinkedSegments.vue";
import RfSchedule from "@/components/RfPathCreate/RfSchedule.vue";
import RfTraffic from "@/components/RfPathCreate/RfTraffic.vue";
import RfTrigger from "@/components/RfPathCreate/RfTrigger.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import { isModal } from "@/utils/prompts/promptHelpers";
import { cloneDeep } from "lodash-es";
import { computed, ref } from "vue";
import RfPromoInteractionInterval from "@/components/RfPromotions/RfPromoInteractionInterval.vue";
import RfCustomDevicesModal from "@/components/modals/RfCustomDevicesModal.vue";

const emit = defineEmits([
  "updateSegments",
  "updateTriggers",
  "updateLimits",
  "updateSchedule",
  "updateInterval",
]);
const props = defineProps({
  prompt: Object,
  isDisabledRole: [Boolean, String],
  isDisabledByActiveExperiment: [Boolean, String],
  triggerType: String,
  isPushNotification: { type: Boolean, default: false },
});

const segmentsModal = ref();
const linkedSegments = ref();
const triggersModal = ref();
const triggers = ref();
const limitsModal = ref();
const traffic = ref();
const scheduleModal = ref();
const intervalModal = ref();
const schedule = ref();
const rfCustomDevicesModal = ref();
const acceptInterval = ref(props.prompt.actions?.rf_settings_accept_interval);
const permanentDisplay = ref(props.prompt.actions?.rf_settings_permanent_display);
const scheduleType = ref("schedule");
const scheduleTabs = computed(() =>
  props.isPushNotification ? ["schedule"] : ["schedule", "daypart"],
);
const pipelineStageId = computed(() => {
  if (!props.prompt?.pipeline_stage_id) return null;
  return props.prompt?.segments?.find(s => !!s.pipeline_stage_id)?.pipeline_stage_id || null;
});

const updateSegments = () => {
  const newPath = cloneDeep(props.prompt);
  linkedSegments.value.fillModel(newPath);
  emit("updatePrompt", newPath);
};

const updateTriggers = () => {
  const isModalPrompt = isModal(props.prompt);
  const newPath = cloneDeep(isModalPrompt ? props.prompt : props.prompt.path_group);
  triggers.value.fillModel(newPath);
  emit("updateTriggers", { isModal: isModalPrompt, newPath });
};

const updateLimits = () => {
  const newPath = cloneDeep(props.prompt);
  traffic.value.fillModel(newPath);
  emit("updatePrompt", newPath);
};

const updateSchedule = () => {
  const newPath = cloneDeep(props.prompt);
  schedule.value.fillModel(newPath);
  emit("updatePrompt", newPath);
};

const updateInterval = () => {
  const newPath = cloneDeep(props.prompt);
  newPath.actions.rf_settings_permanent_display = permanentDisplay.value;
  newPath.actions.rf_settings_accept_interval = acceptInterval.value;
  emit("updatePrompt", newPath);
};

const showSegmentsModal = () => segmentsModal.value?.show();
const closeSegmentsModal = () => segmentsModal.value?.close();

const showTriggersModal = () => triggersModal.value?.show();
const closeTriggersModal = () => triggersModal.value?.close();

const showLimitsModal = () => limitsModal.value?.show();
const closeLimitsModal = () => limitsModal.value?.close();

const showScheduleModal = () => scheduleModal.value?.show();
const closeScheduleModal = () => scheduleModal.value?.close();

const showIntervalModal = () => intervalModal.value?.show();
const closeIntervalModal = () => intervalModal.value?.close();

const showCustomDevicesModal = () => rfCustomDevicesModal.value?.show();
const closeCustomDevicesModal = () => rfCustomDevicesModal.value?.close();

defineExpose({
  showSegmentsModal,
  closeSegmentsModal,
  showTriggersModal,
  closeTriggersModal,
  showLimitsModal,
  closeLimitsModal,
  showScheduleModal,
  closeScheduleModal,
  showIntervalModal,
  closeIntervalModal,
  showCustomDevicesModal,
  closeCustomDevicesModal,
});
</script>
