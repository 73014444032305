<template>
  <div
    :class="{
      [countdownClassName]: true,
      'rfmodal-countdown-new ;': newVariant,
    }"
    :style="timerFontStyle"
  >
    <div v-if="newVariant" class="flex h-full w-full">
      <span class="my-auto">{{ secondsRemainingDisplayNew }}</span>
    </div>
    <span v-else> {{ secondsRemainingDisplay }} </span>
  </div>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps({
  actions: { type: Object, default: () => ({}) },
  newVariant: { type: Boolean, default: false },
  mobileWeb: { type: Boolean, default: false },
});

const countdownClassName = computed(() =>
  props.mobileWeb ? "rfmodal-countdown-mobileweb" : "rfmodal-countdown",
);
const secondsRemainingDisplay = computed(() => {
  const seconds = props.actions.rf_settings_close_seconds;
  if (!seconds) return null;
  const text = props.actions.rf_settings_close_seconds_text;
  return `${seconds} ${text}`;
});

const secondsRemainingDisplayNew = computed(() => {
  const seconds = props.actions.rf_settings_close_seconds;
  return seconds ? `${seconds} s` : null;
});

const timerFontStyle = computed(() => ({
  "font-size": props.actions.rf_settings_timer_font_size,
  "color": props.actions.rf_settings_timer_font_color,
  "display": props.actions.rf_settings_hide_timer_text ? "none" : "block",
}));
</script>

<style scoped>
.rfmodal-countdown-new {
  background-color: #3a3a3abf;
  height: 21px;
}
</style>
