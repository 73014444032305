<template>
  <div class="list-view-filter-row">
    <v-select
      dense
      hide-details
      label="Prompt State"
      v-model.lazy="promoState"
      :items="promoStateList"
      :menu-props="{ offsetY: true }"
      placeholder="All"
      multiple
      class="list-view-filter-menu"
    >
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">{{ truncate(item.text, 12) }}</span>
        <span v-if="index === 1" class="caption">[+{{ promoState.length - 1 }}]</span>
      </template>
    </v-select>
    <v-select
      class="list-view-filter-menu"
      label="Segments"
      hide-details
      dense
      item-text="name"
      item-value="id"
      v-model="filterPlacementsSegment"
      :items="segmentListWithHeaders"
      :menu-props="{ offsetY: true }"
      placeholder="All"
      multiple
    >
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">{{ truncate(item.text, 12) }}</span>
        <span v-if="index === 1" class="caption">[+{{ filterPlacementsSegment.length - 1 }}]</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import FilterMixin from "@/utils/FilterMixin";
import StringUtils from "@/utils/StringUtils";

export default {
  name: "PlacementPromoFilters",
  setup: () => ({ truncate: StringUtils.truncate }),
  mixins: [FilterMixin],
};
</script>

<style></style>
