<template>
  <div>
    <div class="freq-and-holdout-div">
      <div class="freq-a-div">
        <div class="freq-cap-input-active">
          <v-checkbox
            v-model="isCapped"
            label="Global frequency cap"
            :disabled="isReadOnlyRoleMixin"
            class="freq-cap-input"
            hide-details
            @change="v => (v ? $refs.modal.show() : $refs.deleteModal.show())"
          ></v-checkbox>

          <div v-if="isCapped" class="freq-cap-input-desc">
            Show no more than
            <RfButton
              class="font-weight-bold"
              :button-text="pluralize(`${cap} prompt`, cap)"
              link
              @click="() => $refs.modal.show()"
              :disabled="isDisabledRoleMixin"
            />
            to a user over
            <RfButton
              class="font-weight-bold"
              :button-text="
                frequencyCapType === VISIT.value
                  ? 'per visit'
                  : `over ${pluralize(`${timeUnit.join(' ')}`, timeUnit[0])}`
              "
              link
              @click="() => $refs.modal.show()"
              :disabled="isDisabledRoleMixin"
            />
          </div>
        </div>
      </div>
      <div class="holdout-div-input" v-if="globalHoldoutPercentage > 0">
        Global holdout percentage: <b>{{ globalHoldoutPercentage }}%</b>
      </div>
    </div>
    <RfBaseModal
      ref="deleteModal"
      width="580"
      title="Disable Global Frequency Cap"
      message="Are you sure you want to disable the frequency cap?"
      errorText="Disable"
      @error="(isCapped = false), saveApp()"
      @close="resetState"
    />
    <RfBaseModal
      v-if="!isReadOnlyRoleMixin"
      ref="modal"
      width="580"
      title="Global Frequency Cap"
      @primary="(isCapped = true), saveApp()"
      @close="resetState"
    >
      <template #body>
        <div class="app-freq-cap-div">
          <div class="pt-4">
            Limit the number of prompts seen by a user.
            {{ freqCapHint }}
          </div>
          <div class="frequency-cap-val items-center gap-2">
            <RfSelect
              v-model="frequencyCapType"
              outlined
              dense
              :items="[VISIT, DAYS]"
              name="cap_type"
              hint="Select the type of limit"
              persistent-hint
              background-color="#fff"
            ></RfSelect>
          </div>
          <div class="frequency-cap-val">
            <RfTextInput
              v-model.trim="cap"
              outlined
              dense
              title="Limit count"
              hint="Max number of prompts that can be shown to a user."
              persistent-hint
            ></RfTextInput>
          </div>
          <div class="frequency-cap-val items-center gap-2">
            <RfSelect
              v-if="frequencyCapType === 'DAYS'"
              v-model="capSeconds"
              outlined
              dense
              :items="capValues"
              title="Reset after"
              hint="Time period before cap is reset."
              persistent-hint
            ></RfSelect>
          </div>
        </div>
      </template>
    </RfBaseModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RoleMixin from "@/utils/RoleMixin";
import RfButton from "@/components/buttons/RfButton.vue";
import * as Sentry from "@sentry/browser";
import StringUtils from "@/utils/StringUtils";
import { useToastsStore } from "@/pinia/toastsStore";
import dayjs from "dayjs";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import { DAYS, VISIT } from "@/utils/constants/FrequencyCapConstants";

export default {
  name: "FrequencyCap",
  props: ["currApp"],
  components: { RfTextInput, RfSelect, RfButton, RfBaseModal },
  mixins: [RoleMixin],
  setup: () => ({ pluralize: StringUtils.pluralize, toastsStore: useToastsStore(), DAYS, VISIT }),
  data() {
    return {
      isCapped: this.currApp.path_filter.frequency_cap.is_capped,
      cap: this.currApp.path_filter.frequency_cap.cap,
      capSeconds: this.currApp.path_filter.frequency_cap.cap_seconds,
      frequencyCapType:
        this.currApp.path_filter.frequency_cap.cap_seconds === VISIT.value
          ? VISIT.value
          : DAYS.value,
    };
  },
  watch: {
    frequencyCapType(newValue) {
      if (newValue === VISIT.value) {
        this.capSeconds = VISIT.value;
      } else if (this.capSeconds === VISIT.value) {
        this.capSeconds = 2592000; // 30 days in seconds - the default value
      }
    },
  },
  computed: {
    timeUnit() {
      const duration = dayjs.duration({ seconds: this.capSeconds });
      const days = duration.asDays();
      if (days >= 1) return [days, "day"];
      const hours = duration.asHours();
      if (hours >= 1) return [hours, "hour"];
      const minutes = duration.asMinutes();
      if (minutes >= 1) return [minutes, "minute"];
      return [duration.asSeconds(), "second"];
    },
    globalHoldoutPercentage() {
      return this.currApp.path_filter.global_holdout_percentage;
    },
    capValues: () => [
      ...[15, 30, 45].map(el => ({
        text: StringUtils.pluralize(`${el} minute`, el),
        value: el * 60,
      })),
      ...[1].map(el => ({ text: StringUtils.pluralize(`${el} hour`, el), value: el * 60 * 60 })),
      ...new Array(30).fill().map((_, i) => ({
        text: StringUtils.pluralize(`${i + 1} day`, i + 1),
        value: (i + 1) * 60 * 60 * 24,
      })),
    ],
    freqCapHint() {
      if (this.frequencyCapType === VISIT.value) {
        return "Specify the maximum number of prompts that should be shown to a user per visit before the limit is reset.";
      }

      return "Specify the maximum number of prompts that should be shown to a user and the number of days before the limit is reset.";
    },
  },
  methods: {
    ...mapActions(["updateApp"]),
    resetState() {
      this.isCapped = this.currApp.path_filter.frequency_cap.is_capped;
      this.cap = this.currApp.path_filter.frequency_cap.cap;
      this.capSeconds = this.currApp.path_filter.frequency_cap.cap_seconds;
    },
    async saveApp() {
      try {
        const appObj = {
          ...this.currApp,
          path_filter: {
            ...this.currApp.path_filter,
            frequency_cap: {
              ...this.currApp.path_filter.frequency_cap,
              is_capped: this.isCapped,
              cap: this.cap,
              cap_seconds: this.capSeconds,
            },
          },
        };
        await this.updateApp({ appObj, includeProviders: false });
        this.$refs.modal.close();
        this.$refs.deleteModal.close();
      } catch (error) {
        this.toastsStore.create({ type: "error", body: `${error}. Please try again.` });
        Sentry.captureException(error);
      }
    },
  },
};
</script>

<style lang="scss">
.freq-cap-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.freq-cap-input-desc {
  line-height: normal;
  align-items: center;
  display: flex;
  gap: 7px;
  padding: 10px 0 0 32px;
  font-size: 1.1em;
  font-weight: 500;
}
.frequency-cap-val {
  display: flex;
  flex-direction: row;
  padding-top: 10px;

  > div {
    flex: 1;
  }
}
.freq-and-holdout-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.holdout-div-input {
  text-align: right;
}
</style>
