var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return ((_setup.showInteraction || _setup.showCustomGoalInteraction || _setup.showSurvey) && _vm.mainChartHasData)?_c('div',{staticClass:"flex flex-col items-center gap-4 lg:flex-row"},[_vm._l(([
      {
        vif: _setup.showInteraction,
        alterNoData: true,
        title: 'Interactions',
        total: { value: _setup.metricsGoalsFormatted.goals.total, text: 'click' },
        data: _setup.goalsMetricsForCharts,
      },
      {
        vif: _setup.showCustomGoalInteraction,
        title: 'Custom Goal Interactions',
        total: { value: _setup.metricsGoalsFormatted.customGoals.total, text: 'click' },
        data: _setup.customGoalsMetricsForCharts,
      },
      {
        vif: _setup.showSurvey,
        title: 'Survey Interactions',
        total: { value: _setup.metricsSurveyFormatted.total, text: 'click' },
        data: _setup.surveyMetricsForCharts,
      },
    ]),function(chart){return [(chart.vif)?_c('div',{staticClass:"flex w-full flex-1 flex-col gap-3 rounded bg-white-1 p-5"},[_c('Transition',{attrs:{"mode":"out-in"}},[(_vm.skeleton)?_c('div',{key:"skeleton",staticClass:"flex flex-col gap-1"},[_c(_setup.RfBaseSkeleton,{attrs:{"height":"29","width":"189"}}),_c(_setup.RfBaseSkeleton,{attrs:{"height":"20","width":"100"}})],1):_c('div',{key:"data",staticClass:"flex flex-col gap-1"},[_c('h2',{staticClass:"text-heading-1 !text-black-1"},[_vm._v(_vm._s(chart.title))]),(chart.total.value)?_c('span',{staticClass:"text-body"},[_vm._v(" "+_vm._s(`(${_setup.toLocaleNumberString(chart.total.value)} ${_setup.StringUtils.pluralize(chart.total.text, +chart.total.value)})`)+" ")]):_vm._e()])]),_c(_setup.RfChartAdditional,{attrs:{"data":chart.data,"skeleton":_vm.skeleton,"alterNoData":chart.alterNoData}})],1):_vm._e()]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }