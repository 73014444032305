var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-selector"},[(_vm.languages.length > 1)?_c('v-select',{attrs:{"items":_vm.languages,"dark":"","width":"400px","append-icon":"","placeholder":"Lang","persistent-placeholder":"","menu-props":{
      'offset-y': true,
      'bottom': true,
      'content-class': 'tab-selector-content',
      'tile': true,
      'dark': true,
      'origin': 'bottom center',
      'z-index': 3,
    }},scopedSlots:_vm._u([{key:"selection",fn:function(){return [_c('span',[_vm._v("Language")])]},proxy:true},{key:"item",fn:function({ item }){return [_c('div',{staticClass:"tab-selector-item"},[_vm._v(_vm._s(item.text))])]}}],null,false,3712616092),model:{value:(_vm.previewLanguage),callback:function ($$v) {_vm.previewLanguage=$$v},expression:"previewLanguage"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }