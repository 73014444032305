var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',_vm._g(_vm._b({ref:"btn",class:{
    'v-btn--disabled': _vm.disabledLocal,
    'v-btn--link px-0': _vm.link,
  },attrs:{"aria-disabled":_vm.disabledLocal,"icon":_vm.icon && !_vm.buttonText,"plain":_vm.link,"ripple":!_vm.link,"elevation":"0"},on:{"hook:mounted":function($event){_vm.renderTooltip = true}}},'v-btn',_vm.$attrs,false),{
    ..._vm.$listeners,
    click: e => (_vm.disabledLocal ? {} : (_vm.$emit('click', e), _vm.$listeners.click)),
  }),[_c('span',{staticClass:"d-flex align-center",class:{ 'flex-row-reverse': _vm.iconRight }},[(_vm.icon)?_c('v-icon',{attrs:{"size":_vm.iconSize || (_vm.buttonText ? 18 : 22),"left":!!_vm.buttonText && !_vm.iconRight,"right":!!_vm.buttonText && _vm.iconRight}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1),_vm._t("default"),(_vm.renderTooltip && _vm.disabledLocal)?_c('v-tooltip',_vm._b({attrs:{"activator":_vm.$refs.btn}},'v-tooltip',_vm.tooltip,false),[_c('span',[_vm._v(_vm._s(typeof _vm.disabled === "string" && !!_vm.disabled ? _vm.disabled : "Disabled"))])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }