export default {
  data() {
    return {
      messageAlignOptions: [
        { value: "left", text: "Left" },
        { value: "center", text: "Center" },
        { value: "right", text: "Right" },
      ],
    };
  },
  computed: {
    rowsNumber() {
      return 2;
    },
    rowsNumberMsg() {
      return this.model.path_type === "retention_modal" ? 4 : 3;
    },
  },
};
